import React, { Fragment, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import IconButton from 'components/ui-components/buttons/IconButton/IconButton'

import { ReactComponent as LogoType } from 'static/LogoType.svg'
import { ReactComponent as InformationIcon } from 'static/InformationIcon.svg'
import { ReactComponent as LoginIcon } from 'static/icons/16x16/log-in-16.svg'
import { ReactComponent as LogoutIcon } from 'static/icons/16x16/log-out-16.svg'
import { ReactComponent as NotificationIcon } from 'static/icons/16x16/notifcation-icon-16.svg'

import './AppHeader.scss'

interface AppHeaderProps {
  logo: string
}

const AppHeader = ({ logo }: AppHeaderProps) => {
  let location = useLocation().pathname
  let history = useHistory()
  const [showNotificationBanner, setShowNotificationBanner] = useState(true)

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push('/')
      })
  }

  const renderLoginAndLogoutButton = () => {
    if (location.includes('dashboard')) {
      return (
        <Link to="">
          <IconButton
            tooltip="Logout"
            tooltipPosition="left"
            onClick={() => handleLogout()}
          >
            <LogoutIcon />
          </IconButton>
        </Link>
      )
    }
    return (
      <Link to="/login" title="Kanzlei-Login">
        <IconButton tooltip="Kanzlei-Login" tooltipPosition="left">
          <LoginIcon />
        </IconButton>
      </Link>
    )
  }

  const renderUserInformationButton = () => {
    if (location.includes('dashboard') || location.includes('consultant')) {
      return (
        <a
          href="https://www.fastdocs.de/changelog"
          title="Veröffentlichungen & Updates"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton tooltip="Neuigkeiten" tooltipPosition="right">
            <NotificationIcon />
          </IconButton>
        </a>
      )
    }
    return (
      <a
        href="https://www.fastdocs.de/nutzerinformation.html"
        title="Nuntzerinformation"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton tooltip="Nutzerinformation" tooltipPosition="right">
          <InformationIcon />
        </IconButton>
      </a>
    )
  }

  const renderLogo = () => {
    if (logo) {
      return <img src={logo} height="40px" alt="Logo der Kanzlei" />
    }
    return <LogoType />
  }

  const NotificationBanner = () => {
    return (
      <section id="notificationBanner" className="notificationBanner">
        <div className="bannerContent">
          <span className="pill">Geplante Wartungsarbeiten</span> Ab Freitag,
          8.4. um 18 Uhr bis voraussichtlich Sonntag, 10.4. um 18 Uhr wird unser
          Service nicht zur Verfügung stehen.
        </div>

        <button
          className="notificationButton"
          onClick={() => setShowNotificationBanner(false)}
        >
          Ausblenden
        </button>
      </section>
    )
  }

  const renderAppHeader = () => {
    if (location.includes('login') || location.includes('/integrations/')) {
      return <div />
    }

    return (
      <>
        <header className="AppHeader">
          {showNotificationBanner && <NotificationBanner />}

          <nav>
            {renderUserInformationButton()}
            <Link to="/" title="Startseite" className="logo">
              {renderLogo()}
            </Link>
            {renderLoginAndLogoutButton()}
          </nav>
        </header>
      </>
    )
  }

  return <Fragment>{renderAppHeader()}</Fragment>
}

export default AppHeader
