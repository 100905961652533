const data = {
  academicTitle: 'Keine',
  firstname: 'Alex',
  lastname: 'Schmitz',
  birthday: '29',
  birthmonth: '04',
  birthyear: '1980',
  denomination: 'rk - Römisch-Katholische Kirchensteuer',
  socialSecurityNumber: '60290480O505',
  contactPerson: 'Alex Steinhoff',
  annualFinancialReport: '2019',
  taxIdNumber: '86149736056',
  street: 'Zeppelinstraße',
  houseNumber: '1',
  zipCode: '42781',
  city: 'Haan',
  phoneNumber: '021295662226',
  email: 'daniel@fastdocs.de',
  placeOfBirth: 'Haan',
  pep: 'Nein',
  nationality: 'Deutsch',
  identityCardNumber: 'L7ZYH2710',
  issuingAuthority: 'Stadt Solingen',
  issuedDay: '22',
  issuedMonth: '11',
  issuedYear: '2013',
  validDay: '21',
  validMonth: '11',
  validYear: '2023',
  privateIban: 'DE44100110012625484636',
  privateBankAccountHolder: 'Alex Schmitz',
  privateBankName: 'N26 Bank',
  privateTaxOffice: 'Hilden',
  privateTaxNumber: '333/3457/08154',
  married: 'Ja',
  companionAcademicTitle: 'Keine',
  companionFirstname: 'Maxime',
  companionLastname: 'Schmitz',
  companionBirthday: '29',
  companionBirthmonth: '04',
  companionBirthyear: '1980',
  companionDenomination: 'rk - Römisch-Katholische Kirchensteuer',
  companionSocialSecurityNumber: '60290480O505',
  companionTaxIdNumber: '86149736056',
  companionStreet: 'Zeppelinstraße',
  companionHouseNumber: '1',
  companionZipCode: '42781',
  companionCity: 'Haan',
  companionPhoneNumber: '021295662226',
  companionEmail: 'daniel@fastdocs.de',
  companionTypeOfIdentification: 'Personalausweis',
  companionPlaceOfBirth: 'Haan',
  companionPep: 'Nein',
  companionNationality: 'Deutsch',
  companionIdentityCardNumber: 'L7ZYH2710',
  companionIssuingAuthority: 'Stadt Solingen',
  companionIssuedDay: '22',
  companionIssuedMonth: '11',
  companionIssuedYear: '2013',
  companionValidDay: '21',
  companionValidMonth: '11',
  companionValidYear: '2023',
  children: 'Ja',
  child1Firstname: 'Sandro',
  child1Lastname: 'Schmitz',
  child1Birthday: '12',
  child1Birthmonth: '03',
  child1Birthyear: '2000',
  child1TaxIdNumber: '86149736056',
  child1IsAdult: 'Nein',
  child1Status: 'SchülerIn',
  taxOfficeProxy: 'Ja',
  order: true,
  marriedDay: '26',
  marriedMonth: '6',
  marriedYear: '2010',
}
export default data
