import React, { Fragment } from 'react'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../../ui-components/buttons/HelperButton/HelperButton'
import SelectField from '../../../Selects/SelectField/SelectField'
import TextInputField from '../../../Inputs/TextInputField/TextInputField'
import DateInputField from '../../../Inputs/DateInputField/DateInputField'
import TwoColumns from '../../../ui-components/layouts/TwoColumns/TwoColumns'
import RadioInputField from '../../../Inputs/RadioInputField/RadioInputField'

import classes from './ChildrenForm.module.scss'
import { tinValidation } from 'components/utility/tinValidation'

interface Props {
  values: {
    children: string
    numberOfChildren: number
    child1Firstname: string
    child1Lastname: string
    child1Birthday: string
    child1Birthmonth: string
    child1Birthyear: string
    child1TaxIdNumber: string
    child1IsAdult: string
    child1Status: string
    child1Benefit: string
    child2Firstname: string
    child2Lastname: string
    child2Birthday: string
    child2Birthmonth: string
    child2Birthyear: string
    child2TaxIdNumber: string
    child2IsAdult: string
    child2Status: string
    child2Benefit: string
    child3Firstname: string
    child3Lastname: string
    child3Birthday: string
    child3Birthmonth: string
    child3Birthyear: string
    child3TaxIdNumber: string
    child3IsAdult: string
    child3Status: string
    child3Benefit: string
  }
  onHelperButtonClick: () => void
  onNextButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onBackButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onRemoveChildren: (e: React.MouseEvent<HTMLButtonElement>) => void
  onAddChildren: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ChildreForm = (props: Props) => {
  const {
    values: {
      children,
      numberOfChildren,
      child1Firstname,
      child1Lastname,
      child1Birthday,
      child1Birthmonth,
      child1Birthyear,
      child1TaxIdNumber,
      child1IsAdult,
      child1Status,
      child1Benefit,
      child2Firstname,
      child2Lastname,
      child2Birthday,
      child2Birthmonth,
      child2Birthyear,
      child2TaxIdNumber,
      child2IsAdult,
      child2Status,
      child2Benefit,
      child3Firstname,
      child3Lastname,
      child3Birthday,
      child3Birthmonth,
      child3Birthyear,
      child3TaxIdNumber,
      child3IsAdult,
      child3Status,
      child3Benefit,
    },
    onHelperButtonClick,
    onNextButtonClick,
    onChange,
    onBackButtonClick,
    onRemoveChildren,
    onAddChildren,
  } = props

  const childStatusOptions = [
    'Noch nicht schulpflichtig',
    'SchülerIn',
    'Studierend',
    'Berufstätig',
  ]

  const setTinLabelValidationLabel = (taxIdNumber: string) => {
    if (taxIdNumber.length <= 0) {
      return 'Persönliche Steuer-ID (optional)'
    }
    if (!tinValidation(taxIdNumber)) {
      return (
        <span className={classes.validateInfo}>
          Persönliche Steuer-ID (optional){' '}
          <span className={classes.warn}>Keine gültige Steuer-ID</span>
        </span>
      )
    }
    return 'Persönliche Steuer-ID (optional)'
  }

  const renderDeviderButton = (variant: 'add' | 'remove') => {
    if (variant === 'remove') {
      return (
        <div className={classes.deviderButton}>
          <button onClick={onRemoveChildren} className="danger">
            Kind entfernen
          </button>
          <div className={classes.devider}></div>
        </div>
      )
    }
    if (variant === 'add') {
      return (
        <div className={classes.deviderButton}>
          <button onClick={onAddChildren} className="tertiary">
            Kind hinzufügen
          </button>
          <div className={classes.devider}></div>
        </div>
      )
    }
  }

  const preventSubmit = (e: React.PointerEvent<HTMLButtonElement>) => {
    const taxIdNumbers = [
      child1TaxIdNumber,
      child2TaxIdNumber,
      child3TaxIdNumber,
    ]
    taxIdNumbers.forEach((taxId) => {
      if (taxId.length > 0 && !tinValidation(taxId)) {
        e.preventDefault()
      }
    })
  }

  return (
    <FormContainer
      className={classes.childrenForm}
      onSubmit={onNextButtonClick}
    >
      <HelperButton onClick={onHelperButtonClick} />
      <FormHeader headlineText="Angaben zu Kindern" />
      <FormBody>
        <TwoColumns layout="1-1">
          <RadioInputField
            label="Kinder"
            name="children"
            radioOptions={['Nein', 'Ja']}
            onChange={onChange}
            defaultChecked={children === 'Nein' ? 0 : 1}
          />
        </TwoColumns>

        {children === 'Ja' ? (
          <Fragment>
            <TwoColumns layout="1-1">
              <TextInputField
                label="Vorname"
                name="child1Firstname"
                onChange={onChange}
                required
                defaultValue={child1Firstname}
                placeholder="Alex"
              />
              <TextInputField
                label="Nachname"
                name="child1Lastname"
                onChange={onChange}
                required
                defaultValue={child1Lastname}
                placeholder="Schmitz"
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <DateInputField
                label="Geburtsdatum"
                names={[
                  'child1Birthday',
                  'child1Birthmonth',
                  'child1Birthyear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="\d"
                required
                defaultValue={[
                  child1Birthday,
                  child1Birthmonth,
                  child1Birthyear,
                ]}
              />
              <TextInputField
                defaultValue={child1TaxIdNumber}
                label={setTinLabelValidationLabel(child1TaxIdNumber)}
                name="child1TaxIdNumber"
                onChange={onChange}
                placeholder={'56345234987'}
                minLength={11}
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <SelectField
                label="Status"
                name="child1Status"
                defaultValue={child1Status}
                options={childStatusOptions}
                onChange={onChange}
                noDefault
              />
              <RadioInputField
                label="Über 18 Jahre alt?"
                name="child1IsAdult"
                radioOptions={['Nein', 'Ja']}
                onChange={onChange}
                defaultChecked={child1IsAdult === 'Nein' ? 0 : 1}
              />
            </TwoColumns>
            {child1IsAdult === 'Ja' ? (
              <TwoColumns layout="1-1">
                <RadioInputField
                  label="Kindergeld erhalten?"
                  name="child1Benefit"
                  radioOptions={['Ja', 'Nein']}
                  onChange={onChange}
                  defaultChecked={child1Benefit === 'Ja' ? 0 : 1}
                />
              </TwoColumns>
            ) : null}
            {numberOfChildren > 1 ? (
              <Fragment>
                {renderDeviderButton('remove')}
                <TwoColumns layout="1-1">
                  <TextInputField
                    label="Vorname"
                    name="child2Firstname"
                    onChange={onChange}
                    required
                    defaultValue={child2Firstname}
                    placeholder="Alex"
                  />
                  <TextInputField
                    label="Nachname"
                    name="child2Lastname"
                    onChange={onChange}
                    required
                    defaultValue={child2Lastname}
                    placeholder="Schmitz"
                  />
                </TwoColumns>
                <TwoColumns layout="1-1">
                  <DateInputField
                    label="Geburtsdatum"
                    names={[
                      'child2Birthday',
                      'child2Birthmonth',
                      'child2Birthyear',
                    ]}
                    onChange={onChange}
                    placeholder={['TT', 'MM', 'JJJJ']}
                    maxLength={['2', '2', '4']}
                    max={['31', '12', new Date().getFullYear()]}
                    min={['1', '1', '1900']}
                    pattern="\d"
                    required
                    defaultValue={[
                      child2Birthday,
                      child2Birthmonth,
                      child2Birthyear,
                    ]}
                  />
                  <TextInputField
                    defaultValue={child2TaxIdNumber}
                    label={setTinLabelValidationLabel(child2TaxIdNumber)}
                    name="child2TaxIdNumber"
                    onChange={onChange}
                    placeholder={'56345234987'}
                    minLength={11}
                  />
                </TwoColumns>
                <TwoColumns layout="1-1">
                  <SelectField
                    label="Status"
                    name="child2Status"
                    defaultValue={child2Status}
                    options={childStatusOptions}
                    onChange={onChange}
                    noDefault
                  />
                  <RadioInputField
                    label="Über 18 Jahre alt?"
                    name="child2IsAdult"
                    radioOptions={['Nein', 'Ja']}
                    onChange={onChange}
                    defaultChecked={child2IsAdult === 'Nein' ? 0 : 1}
                  />
                </TwoColumns>
                {child2IsAdult === 'Ja' ? (
                  <TwoColumns layout="1-1">
                    <RadioInputField
                      label="Kindergeld erhalten?"
                      name="child2Benefit"
                      radioOptions={['Ja', 'Nein']}
                      onChange={onChange}
                      defaultChecked={child2Benefit === 'Ja' ? 0 : 1}
                    />
                  </TwoColumns>
                ) : null}
              </Fragment>
            ) : null}
            {numberOfChildren > 2 ? (
              <Fragment>
                {renderDeviderButton('remove')}
                <TwoColumns layout="1-1">
                  <TextInputField
                    label="Vorname"
                    name="child3Firstname"
                    onChange={onChange}
                    required
                    defaultValue={child3Firstname}
                    placeholder="Alex"
                  />
                  <TextInputField
                    label="Nachname"
                    name="child3Lastname"
                    onChange={onChange}
                    required
                    defaultValue={child3Lastname}
                    placeholder="Schmitz"
                  />
                </TwoColumns>
                <TwoColumns layout="1-1">
                  <DateInputField
                    label="Geburtsdatum"
                    names={[
                      'child3Birthday',
                      'child3Birthmonth',
                      'child3Birthyear',
                    ]}
                    onChange={onChange}
                    placeholder={['TT', 'MM', 'JJJJ']}
                    maxLength={['2', '2', '4']}
                    max={['31', '12', new Date().getFullYear()]}
                    min={['1', '1', '1900']}
                    pattern="\d"
                    required
                    defaultValue={[
                      child3Birthday,
                      child3Birthmonth,
                      child3Birthyear,
                    ]}
                  />
                  <TextInputField
                    defaultValue={child3TaxIdNumber}
                    label={setTinLabelValidationLabel(child3TaxIdNumber)}
                    name="child3TaxIdNumber"
                    onChange={onChange}
                    placeholder={'56345234987'}
                    minLength={11}
                  />
                </TwoColumns>
                <TwoColumns layout="1-1">
                  <SelectField
                    label="Status"
                    name="child3Status"
                    defaultValue={child3Status}
                    options={childStatusOptions}
                    onChange={onChange}
                    noDefault
                  />
                  <RadioInputField
                    label="Über 18 Jahre alt?"
                    name="child3IsAdult"
                    radioOptions={['Nein', 'Ja']}
                    onChange={onChange}
                    defaultChecked={child3IsAdult === 'Nein' ? 0 : 1}
                  />
                </TwoColumns>
                {child3IsAdult === 'Ja' ? (
                  <TwoColumns layout="1-1">
                    <RadioInputField
                      label="Kindergeld erhalten?"
                      name="child3Benefit"
                      radioOptions={['Ja', 'Nein']}
                      onChange={onChange}
                      defaultChecked={child3Benefit === 'Ja' ? 0 : 1}
                    />
                  </TwoColumns>
                ) : null}
              </Fragment>
            ) : null}
            {numberOfChildren < 3 ? renderDeviderButton('add') : null}
          </Fragment>
        ) : null}
      </FormBody>
      <FormFooter
        onClick={preventSubmit}
        primaryButtonText="Weiter"
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={onBackButtonClick}
      />
    </FormContainer>
  )
}

export default ChildreForm
