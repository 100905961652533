import React, { Fragment, useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import moment from 'moment'

import clientFormValues from 'helpers/autofill/foreignEmployeeForm/clientFormValues'

import FormStage from 'components/Stages/FormStage/FormStage'
import CompanyForm from 'components/Forms/CompanyForm/CompanyForm'
import EmployeeForm from 'components/Forms/EmployeeForm/EmployeeForm'
import WorkingHours from 'components/Forms/WorkingHours/WorkingHours'
import SallaryForm from 'components/Forms/SallaryForm/SallaryForm'
import EmailForm from 'components/Forms/EmailForm/EmailForm'
import CapitalAccumulationBenefits from 'components/Forms/CapitalAccumulationBenefits/CapitalAccumulationBenefits'
import SuccsessForm from 'components/Forms/SuccessForm/SuccessForm'
import HelpOverForStaffForm from 'components/Overlays/HelpOver/fastdocs-staff/HelpOverForStaffForm/HelpOverForStaffForm'
import AdditionalBenefitsForm from 'components/Forms/AdditionalBenefitsForm/AdditionalBenefitsForm'
import SummaryFormClient from 'components/Forms/SummaryForm/SummaryFormClient'
import createFormId from 'views/utility/createFormId'
import {
  getFormStep,
  getProgressValue,
  getTaxConsultant,
} from 'views/utility/utility.jsx'
import AutofillButton from 'helpers/autofill/AutofillButton'
import useEdgeNotification from '../../../../hooks/useEdgeNotification'
import useSetProgressAsDocumentTitle from '../../../../hooks/useSetProgressAsDocumentTitle'
import { STAFF_FORMS } from 'constants/Formulars'

const database = firebase.database()

const ClientsViewForStaffForm = (): JSX.Element => {
  const showEdgeNotification = useEdgeNotification()
  const [state, setState] = useState({
    formId: createFormId(),
    formState: {
      formStep: 0,
      totalFormSteps: 8,
    },
    clerks: {},
    showClerks: false,
    taxConsultantUseFiveFIntegration: false,
    formValues: {
      capitalAccumulation: 'Nein',
      clientEmail: '',
      company: '',
      companyCar: false,
      companyPensionPlan: false,
      contractForm: '',
      contractLimitDay: '',
      contractLimitMonth: '',
      contractLimitYear: '',
      costCenter: '',
      costCenterQuerstion: false,
      employedAs: '',
      employeeEmail: '',
      employmentLocation: '',
      entryday: '',
      entrymonth: '',
      entryyear: '',
      firstname: '',
      formCreationDate: moment().format('DD.MM.YYYY'),
      formularKey: '101',
      formularName: STAFF_FORMS.FOREIGN_EMPLOYEE,
      friday: '',
      handycapped: 'Unbekannt',
      hollidays: '',
      jobTicket: false,
      taxFreeBenefit: false,
      internetGrant: false,
      lastname: '',
      monday: '',
      monthlyProportion: '',
      payingOption: 'Überweisung',
      sallary: 'Festes monatliches Gehalt',
      sallaryIncreaseAfterTrialPeriod: false,
      sallaryValue: '',
      saturday: '',
      selectedClerk: '',
      staffNumber: '',
      staffNumberQuerstion: false,
      statusIndicator: '',
      sunday: '',
      taxConsultant: getTaxConsultant(),
      taxConsultantEmail: '',
      taxConsultantImportSystem: '',
      thursday: '',
      travelAllowance: false,
      tuesday: '',
      typeOfEmployee: '',
      wednesday: '',
      otherInformation: '',
    },
    features: {
      costCenter: false,
      staffNumber: false,
    },
    helpOver: {
      isOpen: false,
      class: '',
    },
  })
  const [helpover, setHelpover] = useState(false)
  document.title = useSetProgressAsDocumentTitle(
    state.formState.formStep,
    state.formState.totalFormSteps,
    state.formValues.formularName
  )

  useEffect(() => {
    if (state.formValues.entryday.length === 1) {
      setState({
        ...state,
        formValues: {
          ...state.formValues,
          entryday: `0${state.formValues.entryday}`,
        },
      })
    }
    if (state.formValues.entrymonth.length === 1) {
      setState({
        ...state,
        formValues: {
          ...state.formValues,
          entrymonth: `0${state.formValues.entrymonth}`,
        },
      })
    }
    if (state.formValues.contractLimitDay.length === 1) {
      setState({
        ...state,
        formValues: {
          ...state.formValues,
          contractLimitDay: `0${state.formValues.contractLimitDay}`,
        },
      })
    }
    if (state.formValues.contractLimitMonth.length === 1) {
      setState({
        ...state,
        formValues: {
          ...state.formValues,
          contractLimitMonth: `0${state.formValues.contractLimitMonth}`,
        },
      })
    }

    // eslint-disable-next-line
  }, [
    state.formValues.entryday,
    state.formValues.entrymonth,
    state.formValues.contractLimitDay,
    state.formValues.contractLimitMonth,
    state.formState.formStep,
  ])

  useEffect(() => {
    database
      .ref(`taxConsultant/${state.formValues.taxConsultant}`)
      .once('value', (snapshot) => {
        const data = snapshot.val()
        const { importSystem, email, features, integrations, clerks } = data

        setState({
          ...state,
          formValues: {
            ...state.formValues,
            taxConsultantEmail: email,
            taxConsultantImportSystem: importSystem,
          },
          taxConsultantUseFiveFIntegration: integrations?.fiveFSoftware
            ? true
            : false,
          features: features ? { ...features } : null,
          clerks: clerks ? { ...clerks } : null,
          showClerks: clerks && Object.keys(clerks).length > 0 ? true : false,
        })
      })

    // eslint-disable-next-line
  }, [])

  const renderNextFormStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const increaseFormStep = state.formState.formStep + 1

    e.preventDefault()
    setState({
      ...state,
      formState: {
        ...state.formState,
        formStep: increaseFormStep,
      },
    })
  }

  const renderPreviousFormStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const decreaseFormStep = state.formState.formStep - 1

    e.preventDefault()
    setState({
      ...state,
      formState: {
        ...state.formState,
        formStep: decreaseFormStep,
      },
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      formValues: {
        ...state.formValues,
        [e.target.name]: e.target.value,
      },
    })
  }

  const handleCostCenterQuestionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === 'Ja') {
      setState({
        ...state,
        formValues: {
          ...state.formValues,
          costCenterQuerstion: true,
        },
      })
    } else {
      setState({
        ...state,
        formValues: {
          ...state.formValues,
          costCenterQuerstion: false,
        },
      })
    }
  }

  const handleStaffNumberQuestionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === 'Ja') {
      setState({
        ...state,
        formValues: {
          ...state.formValues,
          staffNumberQuerstion: true,
        },
      })
    } else {
      setState({
        ...state,
        formValues: {
          ...state.formValues,
          staffNumberQuerstion: false,
        },
      })
    }
  }

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    e.preventDefault()

    database
      .ref(`jobs/${state.formId}`)
      .set({
        clientData: {
          ...state.formValues,
        },
        sentJobCreationMailToClient: true,
        sentJobCreationMailToEmployee: true,
        sentJobCreationMailToConsultant: true,
        taxConsultantUseFiveFIntegration:
          state.taxConsultantUseFiveFIntegration,
      })
      .then(() => {
        renderNextFormStep(e)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const handleClerkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    Object.entries(state.clerks).forEach((clerk: any) => {
      if (e.target.value === clerk[1].name) {
        setState({
          ...state,
          formValues: {
            ...state.formValues,
            selectedClerk: clerk[1].email,
          },
        })
      }
      if (e.target.value === 'Unbekannt') {
        setState({
          ...state,
          formValues: {
            ...state.formValues,
            selectedClerk: state.formValues.taxConsultantEmail,
          },
        })
      }
    })
  }

  const getListOfClerks = () => {
    const clerksList = state.clerks
      ? Object.entries(state.clerks).map((entry: any) => {
          return `${entry[1].name}`
        })
      : []
    clerksList.sort()
    clerksList.unshift('Unbekannt')

    return clerksList
  }

  const handleSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      formValues: {
        ...state.formValues,
        [e.target.name]: e.target.checked,
      },
    })
  }

  const renderFormSteps = () => {
    switch (state.formState.formStep) {
      case 0:
        return (
          <CompanyForm
            onSubmit={renderNextFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            company={state.formValues.company}
            selectedClerk={state.formValues.selectedClerk}
            listOfClerks={getListOfClerks()}
            onClerkChange={handleClerkChange}
            showClerkSeletion={state.showClerks}
          />
        )
      case 1:
        return (
          <EmployeeForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            firstname={state.formValues.firstname}
            lastname={state.formValues.lastname}
            employedAs={state.formValues.employedAs}
            handycapped={state.formValues.handycapped}
            employmentLocation={state.formValues.employmentLocation}
            entryDate={[
              state.formValues.entryday,
              state.formValues.entrymonth,
              state.formValues.entryyear,
            ]}
            contractLimitationDate={[
              state.formValues.contractLimitDay,
              state.formValues.contractLimitMonth,
              state.formValues.contractLimitYear,
            ]}
            contractForm={state.formValues.contractForm}
            typeOfEmployee={state.formValues.typeOfEmployee}
            formularName={state.formValues.formularName}
            statusIndicator={state.formValues.statusIndicator}
            costCenter={state.formValues.costCenter}
            staffNumber={state.formValues.staffNumber}
            features={state.features}
            costCenterQuerstion={state.formValues.costCenterQuerstion}
            staffNumberQuerstion={state.formValues.staffNumberQuerstion}
            handleCostCenterQuestionChange={handleCostCenterQuestionChange}
            handleStaffNumberQuestionChange={handleStaffNumberQuestionChange}
          />
        )
      case 2:
        return (
          <SallaryForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            sallary={state.formValues.sallary}
            sallaryValue={state.formValues.sallaryValue}
            payingOption={state.formValues.payingOption}
            formularName={state.formValues.formularName}
            onHelperButtonClick={() => setHelpover(true)}
            employeeAge={undefined}
            entrydate={`${state.formValues.entryyear}-${state.formValues.entrymonth}-${state.formValues.entryday}`}
          />
        )
      case 3:
        return (
          <WorkingHours
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            monday={state.formValues.monday}
            tuesday={state.formValues.tuesday}
            wednesday={state.formValues.wednesday}
            thursday={state.formValues.thursday}
            friday={state.formValues.friday}
            saturday={state.formValues.saturday}
            sunday={state.formValues.sunday}
            sallary={state.formValues.sallary}
            sallaryValue={state.formValues.sallaryValue}
            formularName={state.formValues.formularName}
            hollidays={state.formValues.hollidays}
            entrydate={`${state.formValues.entryyear}-${state.formValues.entrymonth}-${state.formValues.entryday}`}
            employeeAge={undefined}
          />
        )
      case 4:
        return (
          <CapitalAccumulationBenefits
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            monthlyProportion={state.formValues.monthlyProportion}
            capitalAccumulation={state.formValues.capitalAccumulation}
            onHelperButtonClick={() => setHelpover(true)}
            sallaryValue={state.formValues.sallaryValue}
            formularName={state.formValues.formularName}
          />
        )
      case 5:
        return (
          <AdditionalBenefitsForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleSelection}
            onHelperButtonClick={() => setHelpover(true)}
            firstname={state.formValues.firstname}
            lastname={state.formValues.lastname}
            companyCar={state.formValues.companyCar}
            companyPensionPlan={state.formValues.companyPensionPlan}
            jobTicket={state.formValues.jobTicket}
            taxFreeBenefit={state.formValues.taxFreeBenefit}
            internetGrant={state.formValues.internetGrant}
            travelAllowance={state.formValues.travelAllowance}
            sallaryIncreaseAfterTrialPeriod={
              state.formValues.sallaryIncreaseAfterTrialPeriod
            }
          />
        )
      case 6:
        return (
          <EmailForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            clientEmail={state.formValues.clientEmail}
            employeeEmail={state.formValues.employeeEmail}
            taxConsultantEmail={state.formValues.taxConsultantEmail}
            taxConsultantImportSystem={
              state.formValues.taxConsultantImportSystem
            }
            formularName={state.formValues.formularName}
            otherInformation={state.formValues.otherInformation}
          />
        )
      case 7:
        return (
          <SummaryFormClient
            onSubmit={handleSubmit}
            onSecondaryButtonClick={renderPreviousFormStep}
            onHelperButtonClick={() => setHelpover(true)}
            company={state.formValues.company}
            capitalAccumulation={state.formValues.capitalAccumulation}
            clientEmail={state.formValues.clientEmail}
            employeeEmail={state.formValues.employeeEmail}
            employedAs={state.formValues.employedAs}
            firstname={state.formValues.firstname}
            formularName={state.formValues.formularName}
            friday={state.formValues.friday}
            handycapped={state.formValues.handycapped}
            employmentLocation={state.formValues.employmentLocation}
            lastname={state.formValues.lastname}
            monday={state.formValues.monday}
            monthlyProportion={state.formValues.monthlyProportion}
            payingOption={state.formValues.payingOption}
            sallary={state.formValues.sallary}
            sallaryValue={state.formValues.sallaryValue}
            saturday={state.formValues.saturday}
            sunday={state.formValues.sunday}
            thursday={state.formValues.thursday}
            tuesday={state.formValues.tuesday}
            wednesday={state.formValues.wednesday}
            entryday={state.formValues.entryday}
            entrymonth={state.formValues.entrymonth}
            entryyear={state.formValues.entryyear}
            hollidays={state.formValues.hollidays}
            companyCar={state.formValues.companyCar}
            companyPensionPlan={state.formValues.companyPensionPlan}
            jobTicket={state.formValues.jobTicket}
            travelAllowance={state.formValues.travelAllowance}
            sallaryIncreaseAfterTrialPeriod={
              state.formValues.sallaryIncreaseAfterTrialPeriod
            }
            otherInformation={state.formValues.otherInformation}
            staffNumber={state.formValues.staffNumber}
            costCenter={state.formValues.costCenter}
            taxFreeBenefit={state.formValues.taxFreeBenefit}
            internetGrant={state.formValues.internetGrant}
          />
        )
      default:
        return (
          <SuccsessForm
            successText="Personaldaten erfolgreich angelegt."
            formId={state.formId}
          >
            <p>
              {state.formValues.firstname} bekommt nun eine E-Mail von uns. Die
              E-Mail geht an die von dir eben eingetragene Adresse{' '}
              {state.formValues.employeeEmail}. Mit dieser E-Mail bekommt{' '}
              {state.formValues.firstname} einen Link von uns. Mit diesem Link
              werden wir die notwendigen Arbeitnehmerdaten abfragen.
            </p>
            <p>
              Sobald diese Daten hinterlegt sind und Deine Steuerkanzlei alles
              verarbeitet hat, bekommst Du automatisch ein PDF von uns für deine
              Unterlagen. Dieses PDF musst Du dir von{' '}
              {state.formValues.firstname} gegenzeichnen lassen.
            </p>
            <p>
              Auf Wiedersehen{' '}
              <span role="img" aria-label="Winkende-Hand">
                👋
              </span>
              .
            </p>
          </SuccsessForm>
        )
    }
  }

  const renderHelpOver = () => {
    const handleHelpOverClose = () => {
      setHelpover(false)
    }

    return (
      <HelpOverForStaffForm
        formStep={state.formState.formStep}
        onCloseButtonClick={handleHelpOverClose}
        className={helpover === true ? 'show' : ''}
        formId={state.formId}
      />
    )
  }

  return (
    <Fragment>
      <FormStage
        headlineText={STAFF_FORMS.FOREIGN_EMPLOYEE}
        totalSteps={state.formState.totalFormSteps}
        actualStep={getFormStep(
          state.formState.formStep,
          state.formState.totalFormSteps
        )}
        progress={getProgressValue(
          state.formState.formStep,
          state.formState.totalFormSteps
        )}
      />
      {renderHelpOver()}
      {renderFormSteps()}

      {showEdgeNotification}

      <AutofillButton
        onClick={() =>
          setState({
            ...state,
            formValues: {
              ...state.formValues,
              ...clientFormValues,
            },
          })
        }
      />
    </Fragment>
  )
}

export default ClientsViewForStaffForm
