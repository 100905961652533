import React, { Fragment } from 'react'
import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import './SummaryForm.scss'
import { STAFF_FORMS } from '../../../constants/Formulars'

const SummaryForm = (props) => {
  const renderPersonalInformation = () => {
    return (
      <Fragment>
        <ul>
          <li>
            <b>Persönliche Angaben</b>
          </li>
          <li>
            <span>Vorname</span> <span>{props.firstname}</span>
          </li>
          <li>
            <span>Nachname</span> <span>{props.lastname}</span>
          </li>
          <li>
            <span>Straße</span> <span>{props.street}</span>
          </li>
          <li>
            <span>Hausnummer</span> <span>{props.houseNumber}</span>
          </li>
          <li>
            <span>Postleitzahl</span> <span>{props.zipCode}</span>
          </li>
          <li>
            <span>Stadt</span> <span>{props.city}</span>
          </li>
          {props.country && (
            <li>
              <span>Land</span> <span>{props.country}</span>
            </li>
          )}
          <li>
            <span>Geburtsdatum</span>{' '}
            <span>
              {props.birthday}.{props.birthmonth}.{props.birthyear}
            </span>
          </li>
          <li>
            <span>Geschlecht</span> <span>{props.sex}</span>
          </li>
          <li>
            <span>Familienstand</span> <span>{props.mariatialStatus}</span>
          </li>
          {props.matriculation && (
            <li>
              <span>Immatrikulationsbescheinigung</span>{' '}
              <span>
                {props.matriculation === 'Ja' ? 'Vorhanden' : 'Nicht vorhanden'}
              </span>
            </li>
          )}
        </ul>
      </Fragment>
    )
  }

  const renderEducationInformation = () => {
    return (
      <Fragment>
        <ul>
          <li>
            <b>Ausbildung</b>
          </li>
          <li>
            <span>Höchster Schulabschluss</span> <span>{props.graduation}</span>
          </li>
          <li>
            <span>Höchste Berufsausbildung</span> <span>{props.education}</span>
          </li>
        </ul>
      </Fragment>
    )
  }

  const renderEmploymentInformation = () => {
    if (
      props.formularName === STAFF_FORMS.MINIJOB ||
      props.formularName === 'Minijobber bis 450€' || // TODO check and remove legacy
      props.formularName === STAFF_FORMS.STUDENT_MINIJOB
    ) {
      return (
        <Fragment>
          <ul>
            <li>
              <b>Infos zur Beschäftigung</b>
            </li>
            <li>
              <span>Status außerhalb des Minijobs</span>{' '}
              <span>{props.beginningStatus}</span>
            </li>
            <li>
              <span>Persönliche Steuer-ID</span>{' '}
              <span>{props.taxIdNumber}</span>
            </li>
          </ul>
        </Fragment>
      )
    }
    if (
      props.formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      props.formularName === STAFF_FORMS.FOREIGN_EMPLOYEE ||
      props.formularName === STAFF_FORMS.APPRENTICESHIP ||
      props.formularName === STAFF_FORMS.STUDENT
    ) {
      return (
        <Fragment>
          <ul>
            <li>
              <b>Infos zur Beschäftigung</b>
            </li>
            <li>
              <span>Tätigkeitsstatus</span> <span>{props.activityStatus}</span>
            </li>
            <li>
              <span>Persönliche Steuer-ID</span>{' '}
              <span>{props.taxIdNumber}</span>
            </li>
            <li>
              <span>Steuerklasse</span> <span>{props.taxClass}</span>
            </li>
            <li>
              <span>Konfession</span> <span>{props.denomination}</span>
            </li>
          </ul>
        </Fragment>
      )
    }
  }

  const renderInsuranceInformation = () => {
    return (
      <Fragment>
        <ul>
          <li>
            <b>Krankenversicherung</b>
          </li>
          <li>
            <span>Art der Krankenversicherung</span>{' '}
            <span>{props.insuranceType}</span>
          </li>
          <li>
            <span>Name der Krankenversicherung</span>{' '}
            <span>{props.insuranceCompanyName}</span>
          </li>
        </ul>
      </Fragment>
    )
  }

  const renderSocialSecurityInformation = () => {
    return (
      <Fragment>
        <ul>
          <li>
            <b>Angaben zu Versicherung</b>
          </li>
          {props.insuranceNumber === true ? (
            <li>
              <span>Sozialversicherungsnummer</span>{' '}
              <span>{props.socialSecurityNumber}</span>
            </li>
          ) : (
            <Fragment>
              <li>
                <span>Geburtsname</span> <span>{props.birthName}</span>
              </li>
              <li>
                <span>Geburtsort</span> <span>{props.placeOfBirth}</span>
              </li>
              <li>
                <span>Geburtsland</span> <span>{props.birthCountry}</span>
              </li>
              <li>
                <span>Staatsangehörigkeit</span>{' '}
                <span>{props.nationality}</span>
              </li>
            </Fragment>
          )}
        </ul>
      </Fragment>
    )
  }

  const renderPensionInsuranceInformation = () => {
    if (
      props.formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      props.formularName === STAFF_FORMS.FOREIGN_EMPLOYEE ||
      props.formularName === STAFF_FORMS.APPRENTICESHIP ||
      props.formularName === STAFF_FORMS.STUDENT
    ) {
      return (
        <Fragment>
          <ul>
            <li>
              <b>Rentenversicherung (Elterneigenschaft)</b>
            </li>
            <li>
              <span>Ein oder mehrere Kinder</span>{' '}
              <span>{props.parentsProperty}</span>
            </li>
            <li>
              <span>Anzahl der Kinderfreibeträge</span>{' '}
              <span>{props.amountOfChildren.replace('.', ',')}</span>
            </li>
            <li>
              <span>Alleinerziehend</span> <span>{props.singleParent}</span>
            </li>
          </ul>
        </Fragment>
      )
    }
    if (
      props.formularName === STAFF_FORMS.STUDENT_MINIJOB ||
      props.formularName === STAFF_FORMS.MINIJOB ||
      props.formularName === 'Minijobber bis 450€' // TODO check and remove legacy
    ) {
      return (
        <Fragment>
          <ul>
            <li>
              <b>Rentenversicherung</b>
            </li>
            <li>
              <span>
                Antrag auf Befreiung von der Rentenversicherungspflicht
              </span>
              <span>
                {props.payingPensionInsurance === 'Ja'
                  ? 'Antrag durch bestehenden Minijob bereits gestellt'
                  : props.pensionInsuranceRequest}
              </span>
            </li>
          </ul>
        </Fragment>
      )
    }
  }

  const renderBankInformation = () => {
    return (
      <Fragment>
        <ul>
          <li>
            <b>Private Bankverbindung</b>
          </li>
          <li>
            <span>Kontoinhaber/in</span> <span>{props.bankAccountHolder}</span>
          </li>
          <li>
            <span>IBAN</span> <span>{props.iban}</span>
          </li>
        </ul>
      </Fragment>
    )
  }

  const renderCapitalAccumulationBankInformation = () => {
    if (props.capitalAccumulationBankAccountHolder) {
      return (
        <Fragment>
          <ul>
            <li>
              <b>Bankverbindung für Vermögenswirksame Leistungen</b>
            </li>
            <li>
              <span>Name</span>
              <span>{props.capitalAccumulationBankAccountHolder}</span>
            </li>
            <li>
              <span>IBAN</span> <span>{props.capitalAccumulationIban}</span>
            </li>
            <li>
              <span>Vertragsnummer</span> <span>{props.contractNumber}</span>
            </li>
            <li>
              <span>Monatl. Spar-Betrag</span>
              <span>{props.capitalAccumulationValue.replace('.', ',')}€</span>
            </li>
          </ul>
        </Fragment>
      )
    }
  }

  const renderFurtherEmoploymentInformation = () => {
    if (
      props.formularName === STAFF_FORMS.MINIJOB ||
      props.formularName === 'Minijobber bis 450€' || // TODO check and remove legacy
      props.formularName === STAFF_FORMS.STUDENT_MINIJOB
    ) {
      if (props.furtherEmployments) {
        return (
          <Fragment>
            <ul>
              <li>
                <b>Weitere Beschäftigungen</b>
              </li>
              {props.typeOfEmployment === 'Beschäftigung über 450€' ? (
                <li>
                  <span>Art der Tätigkeit</span>{' '}
                  <span>{props.typeOfEmployment}</span>
                </li>
              ) : (
                <Fragment>
                  <li>
                    <span>Art der Tätigkeit</span>{' '}
                    <span>{props.typeOfEmployment}</span>
                  </li>
                  <li>
                    <span>Von der Rentenversicherung befreit</span>{' '}
                    <span>{props.payingPensionInsurance}</span>
                  </li>
                  <li>
                    <span>Arbeitgeber</span>{' '}
                    <span>{props.furtherEmploymentCompanyName}</span>
                  </li>
                  <li>
                    <span>Minjiob besteht seit</span>{' '}
                    <span>
                      {props.furtherMinijobEntryDay}.
                      {props.furtherMinijobEntryMonth}.
                      {props.furtherMinijobEntryYear}
                    </span>
                  </li>
                  <li>
                    <span>Gesamtverdienst höher als 450€</span>{' '}
                    <span>{props.furtherEmploymentsTotalSallary}</span>
                  </li>
                </Fragment>
              )}
            </ul>
          </Fragment>
        )
      }
    }
    if (
      props.formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      props.formularName === STAFF_FORMS.FOREIGN_EMPLOYEE ||
      props.formularName === STAFF_FORMS.APPRENTICESHIP ||
      props.formularName === STAFF_FORMS.STUDENT
    ) {
      if (props.activityStatus) {
        return (
          <Fragment>
            <ul>
              <li>
                <b>Weitere Beschäftigungen</b>
              </li>
              <li>
                <span>Bei anderen Unternehmen beschäftigt</span>
                <span>{props.furtherEmployments === true ? 'Ja' : 'Nein'}</span>
              </li>
              <li>
                <span>Art der Beschäftigung</span>{' '}
                <span>{props.typeOfEmployment}</span>
              </li>
              {renderWerkstudentWeeklyWorkingTimeInformation()}
            </ul>
          </Fragment>
        )
      }
    }
  }

  const renderWerkstudentWeeklyWorkingTimeInformation = () => {
    const renderOperationTime = () => {
      if (props.totalWeeklyWorkingTimeLimitForStudents === 'Gelegentlich')
        return (
          <li>
            <span>
              Finden die Einsätze meistens am Wochenende oder Abends/Nachts
              statt?
            </span>{' '}
            <span>{props.operationTimeForStudents}</span>
          </li>
        )
    }

    const renderOperationTimeLimit = () => {
      if (props.operationTimeForStudents === 'Nein')
        return (
          <li>
            <span>Sind die Einsätze auf die Semesterferien begrenzt?</span>{' '}
            <span>{props.operationTimeIsLimitedToSemesterBreaks}</span>
          </li>
        )
    }

    const renderOperationTimeExercised = () => {
      if (
        props.operationTimeForStudents === 'Ja' ||
        props.operationTimeIsLimitedToSemesterBreaks === 'Ja'
      )
        return (
          <li>
            <span>
              Werden die Einsätze an mehr als 26 Wochen in einem Jahr ausgeübt?
            </span>
            <span>
              {props.operationTimeIsExercisedForMoreThan26WeeksPerYear}
            </span>
          </li>
        )
    }

    if (props.formularName === STAFF_FORMS.STUDENT) {
      return (
        <React.Fragment>
          <>
            <li>
              <span>
                Liegt die wöchentliche Gesamtarbeitszeit über 20 Stunden?
              </span>
              <span>{props.totalWeeklyWorkingTimeLimitForStudents}</span>
            </li>
            {props.totalWeeklyWorkingTimeLimitForStudents ===
              'Gelegentlich' && (
              <>
                {renderOperationTime()}
                {renderOperationTimeLimit()}
                {renderOperationTimeExercised()}
              </>
            )}
          </>
        </React.Fragment>
      )
    }
    return null
  }

  return (
    <FormContainer className={'summary-form'} onSubmit={props.onSubmit}>
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Zusammenfassung'} />
      <FormBody>
        {renderPersonalInformation()}
        {renderEducationInformation()}
        {renderEmploymentInformation()}
        {renderInsuranceInformation()}
        {renderSocialSecurityInformation()}
        {renderBankInformation()}
        {renderCapitalAccumulationBankInformation()}
        {renderFurtherEmoploymentInformation()}
        {renderPensionInsuranceInformation()}
      </FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
      />
    </FormContainer>
  )
}

export default SummaryForm
