interface Props {
  monday: string
  tuesday: string
  wednesday: string
  thursday: string
  friday: string
  saturday: string
  sunday: string
}

const getTotalWeekHoursResult = ({
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
}: Props) => {
  const asNumber = Number(
    (
      Number(monday) +
      Number(tuesday) +
      Number(wednesday) +
      Number(thursday) +
      Number(friday) +
      Number(saturday) +
      Number(sunday)
    ).toFixed(2)
  )

  const asLocaleString = asNumber.toString().replace('.', ',')

  return {
    asNumber,
    asLocaleString,
  }
}

export default getTotalWeekHoursResult
