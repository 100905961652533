import React, { Fragment } from 'react'
import Iban from 'iban'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../../ui-components/buttons/HelperButton/HelperButton'
import TextInputField from '../../../Inputs/TextInputField/TextInputField'

import classes from './BankInformationForm.module.scss'
import RadioInputField from '../../../Inputs/RadioInputField/RadioInputField'

interface Props {
  type:
    | 'private-person'
    | 'single-company'
    | 'limited-company-and-partnership-kg'
    | 'limited-company-and-partnership-gmbh'
    | 'limited-company'
    | 'limited-entrepreneurial-company'
    | 'civil-law-company'
  values: {
    privateIban: string
    privateBankAccountHolder: string
    privateBankName: string
    allowSepaForPrivateIban: string
    businessIban: string
    businessBankAccountHolder: string
    businessBankName: string
    allowSepaForBusinessIban: string
    limitedCompanyBusinessBankAccountHolder: string
    limitedCompanyBusinessBankName: string
    limitedCompanyBusinessIban: string
    allowSepaForlimitedCompanyBusinessIban: string
  }
  features: {
    sepa: boolean
  }
  onHelperButtonClick: () => void
  onNextButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onBackButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const BankInformationForm = (props: Props) => {
  const {
    values: {
      privateIban,
      privateBankAccountHolder,
      privateBankName,
      allowSepaForPrivateIban,
      businessIban,
      businessBankAccountHolder,
      businessBankName,
      allowSepaForBusinessIban,
      limitedCompanyBusinessBankAccountHolder,
      limitedCompanyBusinessBankName,
      limitedCompanyBusinessIban,
      allowSepaForlimitedCompanyBusinessIban,
    },
    type,
    onHelperButtonClick,
    onNextButtonClick,
    onChange,
    onBackButtonClick,
  } = props

  const validatePrivateIban = () => {
    if (privateIban.length <= 0) {
      return 'IBAN'
    }
    if (Iban.isValid(privateIban) !== true) {
      return (
        <span className={classes.validateInfo}>
          IBAN <span className={classes.warn}>IBAN ist ungültig</span>
        </span>
      )
    }
    return 'IBAN'
  }

  const validateBusinessIban = () => {
    if (businessIban.length <= 0) {
      return 'IBAN'
    }
    if (Iban.isValid(businessIban) !== true) {
      return (
        <span className={classes.validateInfo}>
          IBAN <span className={classes.warn}>IBAN ist ungültig</span>
        </span>
      )
    }
    return 'IBAN'
  }

  const validateLimitedCompanyBusinessIban = () => {
    if (limitedCompanyBusinessIban.length <= 0) {
      return 'IBAN'
    }
    if (Iban.isValid(limitedCompanyBusinessIban) !== true) {
      return (
        <span className={classes.validateInfo}>
          IBAN <span className={classes.warn}>IBAN ist ungültig</span>
        </span>
      )
    }
    return 'IBAN'
  }

  const preventSubmiting = (e: React.FormEvent<HTMLFormElement>) => {
    if (businessIban.length !== 0 && Iban.isValid(businessIban) !== true) {
      e.preventDefault()
    }
    if (privateIban.length !== 0 && Iban.isValid(privateIban) !== true) {
      e.preventDefault()
    }
    if (
      limitedCompanyBusinessIban.length !== 0 &&
      Iban.isValid(limitedCompanyBusinessIban) !== true
    ) {
      e.preventDefault()
    }
  }

  const renderFormContent = () => {
    if (type === 'private-person') {
      return (
        <Fragment>
          <FormHeader
            headlineText="Private Bankdaten"
            sublineText="Bankdaten für die Steuererstattungen/Nachzahlungen"
          />
          <FormBody>
            <TextInputField
              label="KontoinhaberIn"
              name="privateBankAccountHolder"
              defaultValue={privateBankAccountHolder}
              placeholder="Alex Schmitz"
              onChange={onChange}
              required
            />
            <TextInputField
              label="Name der Bank"
              name="privateBankName"
              defaultValue={privateBankName}
              placeholder="Sparkasse"
              onChange={onChange}
              required
            />
            <TextInputField
              label={validatePrivateIban()}
              placeholder="DE19123412341234123412"
              onChange={onChange}
              defaultValue={privateIban}
              name="privateIban"
              required
              autoComplete="off"
            />
            {props.features?.sepa ? (
              <RadioInputField
                label="Rechnungen der Kanzlei via SEPA-Lastschrift von diesem Konto abbuchen?"
                name="allowSepaForPrivateIban"
                radioOptions={['Nein', 'Ja']}
                defaultChecked={allowSepaForPrivateIban === 'Nein' ? 0 : 1}
                onChange={onChange}
              />
            ) : null}
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'single-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Geschäftliche Bankdaten" />
          <FormBody>
            <TextInputField
              label="KontoinhaberIn"
              name="businessBankAccountHolder"
              defaultValue={businessBankAccountHolder}
              placeholder="Alex Schmitz"
              onChange={onChange}
              required
            />
            <TextInputField
              label="Name der Bank"
              name="businessBankName"
              defaultValue={businessBankName}
              placeholder="Sparkasse"
              onChange={onChange}
              required
            />
            <TextInputField
              label={validateBusinessIban()}
              placeholder="DE19123412341234123412"
              onChange={onChange}
              defaultValue={businessIban}
              name="businessIban"
              required
              autoComplete="off"
            />
            {props.features?.sepa ? (
              <RadioInputField
                label="Rechnungen der Kanzlei via SEPA-Lastschrift von diesem Konto abbuchen?"
                name="allowSepaForBusinessIban"
                radioOptions={['Nein', 'Ja']}
                defaultChecked={allowSepaForBusinessIban === 'Nein' ? 0 : 1}
                onChange={onChange}
              />
            ) : null}
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-company-and-partnership-kg') {
      return (
        <Fragment>
          <FormHeader headlineText="Betriebliche Bankdaten der KG (Kommanditgesellschaft)" />
          <FormBody>
            <TextInputField
              label="KontoinhaberIn"
              name="businessBankAccountHolder"
              defaultValue={businessBankAccountHolder}
              placeholder="Muster GmbH & Co. KG"
              onChange={onChange}
              required
            />
            <TextInputField
              label="Name der Bank"
              name="businessBankName"
              defaultValue={businessBankName}
              placeholder="Sparkasse"
              onChange={onChange}
              required
            />
            <TextInputField
              label={validateBusinessIban()}
              placeholder="DE19123412341234123412"
              onChange={onChange}
              defaultValue={businessIban}
              name="businessIban"
              required
              autoComplete="off"
            />
            {props.features?.sepa ? (
              <RadioInputField
                label="Rechnungen der Kanzlei via SEPA-Lastschrift von diesem Konto abbuchen?"
                name="allowSepaForBusinessIban"
                radioOptions={['Nein', 'Ja']}
                defaultChecked={allowSepaForBusinessIban === 'Nein' ? 0 : 1}
                onChange={onChange}
              />
            ) : null}
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-company-and-partnership-gmbh') {
      return (
        <Fragment>
          <FormHeader headlineText="Betriebliche Bankdaten des Vollhafters (GmbH)" />
          <FormBody>
            <Fragment>
              <TextInputField
                label="KontoinhaberIn"
                name="limitedCompanyBusinessBankAccountHolder"
                defaultValue={limitedCompanyBusinessBankAccountHolder}
                placeholder="Muster Verwaltungs GmbH"
                onChange={onChange}
                required
              />
              <TextInputField
                label="Name der Bank"
                name="limitedCompanyBusinessBankName"
                defaultValue={limitedCompanyBusinessBankName}
                placeholder="Sparkasse"
                onChange={onChange}
                required
              />
              <TextInputField
                label={validateLimitedCompanyBusinessIban()}
                placeholder="DE19123412341234123412"
                onChange={onChange}
                defaultValue={limitedCompanyBusinessIban}
                name="limitedCompanyBusinessIban"
                required
                autoComplete="off"
              />
              {props.features?.sepa ? (
                <RadioInputField
                  label="Rechnungen der Kanzlei via SEPA-Lastschrift von diesem Konto abbuchen?"
                  name="allowSepaForlimitedCompanyBusinessIban"
                  radioOptions={['Nein', 'Ja']}
                  defaultChecked={
                    allowSepaForlimitedCompanyBusinessIban === 'Nein' ? 0 : 1
                  }
                  onChange={onChange}
                />
              ) : null}
            </Fragment>
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Betriebliche Bankdaten" />
          <FormBody>
            <Fragment>
              <TextInputField
                label="KontoinhaberIn"
                name="limitedCompanyBusinessBankAccountHolder"
                defaultValue={limitedCompanyBusinessBankAccountHolder}
                placeholder="Muster GmbH"
                onChange={onChange}
                required
              />
              <TextInputField
                label="Name der Bank"
                name="limitedCompanyBusinessBankName"
                defaultValue={limitedCompanyBusinessBankName}
                placeholder="Sparkasse"
                onChange={onChange}
                required
              />
              <TextInputField
                label={validateLimitedCompanyBusinessIban()}
                placeholder="DE19123412341234123412"
                onChange={onChange}
                defaultValue={limitedCompanyBusinessIban}
                name="limitedCompanyBusinessIban"
                required
                autoComplete="off"
              />
              {props.features?.sepa ? (
                <RadioInputField
                  label="Rechnungen der Kanzlei via SEPA-Lastschrift von diesem Konto abbuchen?"
                  name="allowSepaForlimitedCompanyBusinessIban"
                  radioOptions={['Nein', 'Ja']}
                  defaultChecked={
                    allowSepaForlimitedCompanyBusinessIban === 'Nein' ? 0 : 1
                  }
                  onChange={onChange}
                />
              ) : null}
            </Fragment>
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-entrepreneurial-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Betriebliche Bankdaten" />
          <FormBody>
            <Fragment>
              <TextInputField
                label="KontoinhaberIn"
                name="limitedCompanyBusinessBankAccountHolder"
                defaultValue={limitedCompanyBusinessBankAccountHolder}
                placeholder="Muster UG"
                onChange={onChange}
                required
              />
              <TextInputField
                label="Name der Bank"
                name="limitedCompanyBusinessBankName"
                defaultValue={limitedCompanyBusinessBankName}
                placeholder="Sparkasse"
                onChange={onChange}
                required
              />
              <TextInputField
                label={validateLimitedCompanyBusinessIban()}
                placeholder="DE19123412341234123412"
                onChange={onChange}
                defaultValue={limitedCompanyBusinessIban}
                name="limitedCompanyBusinessIban"
                required
                autoComplete="off"
              />
              {props.features?.sepa ? (
                <RadioInputField
                  label="Rechnungen der Kanzlei via SEPA-Lastschrift von diesem Konto abbuchen?"
                  name="allowSepaForlimitedCompanyBusinessIban"
                  radioOptions={['Nein', 'Ja']}
                  defaultChecked={
                    allowSepaForlimitedCompanyBusinessIban === 'Nein' ? 0 : 1
                  }
                  onChange={onChange}
                />
              ) : null}
            </Fragment>
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'civil-law-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Geschäftliche Bankdaten" />
          <FormBody>
            <TextInputField
              label="KontoinhaberIn"
              name="businessBankAccountHolder"
              defaultValue={businessBankAccountHolder}
              placeholder="Muster GbR"
              onChange={onChange}
              required
            />
            <TextInputField
              label="Name der Bank"
              name="businessBankName"
              defaultValue={businessBankName}
              placeholder="Sparkasse"
              onChange={onChange}
              required
            />
            <TextInputField
              label={validateBusinessIban()}
              placeholder="DE19123412341234123412"
              onChange={onChange}
              defaultValue={businessIban}
              name="businessIban"
              required
              autoComplete="off"
            />
            {props.features?.sepa ? (
              <RadioInputField
                label="Rechnungen der Kanzlei via SEPA-Lastschrift von diesem Konto abbuchen?"
                name="allowSepaForBusinessIban"
                radioOptions={['Nein', 'Ja']}
                defaultChecked={allowSepaForBusinessIban === 'Nein' ? 0 : 1}
                onChange={onChange}
              />
            ) : null}
          </FormBody>
        </Fragment>
      )
    }
  }

  return (
    <FormContainer
      className={classes.bankInformationForm}
      onSubmit={onNextButtonClick}
    >
      <HelperButton onClick={onHelperButtonClick} />
      {renderFormContent()}
      <FormFooter
        primaryButtonText="Weiter"
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={onBackButtonClick}
        onClick={preventSubmiting}
      />
    </FormContainer>
  )
}

export default BankInformationForm
