const consultantFormValues = {
  clientNumber: '11500',
  consultantNumber: '260814',
  staffNumber: '1500',
  autosuggestSearchValue: '27223 - CAD-Designer/in',
  activityKey: '27223',
  jobTitle: 'CAD-Designer/in',
}

export default consultantFormValues
