const consultantFormValues = {
  clientNumber: '11500',
  consultantNumber: '260814',
  staffNumber: '1600',
  autosuggestSearchValue: '29301 - Aushilfskraft (Küche)',
  activityKey: '29301',
  jobTitle: 'Aushilfskraft (Küche)',
}

export default consultantFormValues
