const clientFormValues = {
  capitalAccumulation: 'Ja',
  company: '[TEST] BMW Museum',
  companyCar: 'Ja',
  companyPensionPlan: 'Ja',
  contractForm: 'Befristet Vollzeit',
  contractLimitDay: '01',
  contractLimitMonth: (new Date().getMonth() + 2).toString(),
  contractLimitYear: (new Date().getFullYear() + 1).toString(),
  employedAs: 'Fahren',
  employmentLocation: 'München',
  entryday: '01',
  entrymonth: (new Date().getMonth() + 2).toString(),
  entryyear: new Date().getFullYear().toString(),
  firstname: 'Marco',
  friday: '8',
  hollidays: '30',
  lastname: 'Wittmann',
  monday: '8',
  monthlyProportion: '40',
  sallaryValue: '4550',
  saturday: '0',
  statusIndicator: 'Nein',
  sunday: '0',
  thursday: '8',
  travelAllowance: 'Ja',
  tuesday: '8',
  typeOfEmployee: 'Angestellter',
  wednesday: '8',
  otherInformation:
    '- Arbeitet in der Abteilung X\n- Wird nach dem 20. ausgezahlt',
}

export default clientFormValues
