const employeeFormValues = {
  bankAccountHolder: 'Ed Therobot',
  bea: true,
  birthCountry: 'Deutschland',
  birthday: '29',
  birthmonth: '04',
  birthyear: '1980',
  birthName: 'Therobot',
  city: 'Haan',
  denomination: 'ev - Evangelische Kirchensteuer',
  firstname: 'Ed',
  graduation: 'Mittlere Reife/gleichwertiger Abschluss',
  houseNumber: '10',
  iban: 'DE44100110012625484636',
  insuranceCompanyName: 'Techniker Krankenkasse',
  insuranceNumber: false,
  insuranceType: 'Gesetzlich',
  lastname: 'Therobot',
  mariatialStatus: 'Ledig',
  nationality: 'Deutschland',
  placeOfBirth: 'Solingen',
  sex: 'Männlich',
  street: 'Zeppelinstraße',
  taxClass: 'I - Steuerklasse Eins',
  taxIdNumber: '86149736056',
  typeOfEmployment: '',
  zipCode: '42781',
}

export default employeeFormValues
