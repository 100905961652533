import React, { Fragment, useEffect, useState } from 'react'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../../ui-components/buttons/HelperButton/HelperButton'
import SelectField from '../../../Selects/SelectField/SelectField'
import TextInputField from '../../../Inputs/TextInputField/TextInputField'
import DateInputField from '../../../Inputs/DateInputField/DateInputField'
import TwoColumns from '../../../ui-components/layouts/TwoColumns/TwoColumns'
import RadioInputField from '../../../Inputs/RadioInputField/RadioInputField'
import { tinValidation } from 'components/utility/tinValidation'
import NumberInputField from '../../../Inputs/NumberInputField/NumberInputField'
import EmailInputField from '../../../Inputs/EmailInputField/EmailInputField'
import PrependNumberInputField from '../../../Inputs/PrependNumberInputField/PrependNumberInputField'
import nations from '../IdentifcationForm/countries.json'
import taxOffices from '../TaxOfficeForm/taxOffices.json'

import classes from './AdditionalShareholdersForm.module.scss'
import SuggestTextInputField from '../../../Inputs/SuggestTextInputField/SuggestTextInputField'

interface Props {
  values: {
    additionalShareholders: string
    numberOfShareholders: number
    additionalShareholder1AcademicTitle: string
    additionalShareholder1Firstname: string
    additionalShareholder1Lastname: string
    additionalShareholder1Birthday: string
    additionalShareholder1Birthmonth: string
    additionalShareholder1Birthyear: string
    additionalShareholder1Denomination: string
    additionalShareholder1TaxIdNumber: string
    additionalShareholder1Street: string
    additionalShareholder1HouseNumber: string
    additionalShareholder1ZipCode: string
    additionalShareholder1City: string
    additionalShareholder1PhoneNumber: string
    additionalShareholder1Email: string
    additionalShareholder1CapitalContribution: string
    additionalShareholder1TypeOfIdentification: string
    additionalShareholder1PlaceOfBirth: string
    additionalShareholder1Nationality: string
    additionalShareholder1IdentityCardNumber: string
    additionalShareholder1IssuingAuthority: string
    additionalShareholder1IssuedDay: string
    additionalShareholder1IssuedMonth: string
    additionalShareholder1IssuedYear: string
    additionalShareholder1ValidDay: string
    additionalShareholder1ValidMonth: string
    additionalShareholder1ValidYear: string
    additionalShareholder1Pep: string
    additionalShareholder1PrivateTaxOffice: string
    additionalShareholder1PrivateTaxNumber: string

    additionalShareholder2AcademicTitle: string
    additionalShareholder2Firstname: string
    additionalShareholder2Lastname: string
    additionalShareholder2Birthday: string
    additionalShareholder2Birthmonth: string
    additionalShareholder2Birthyear: string
    additionalShareholder2Denomination: string
    additionalShareholder2TaxIdNumber: string
    additionalShareholder2Street: string
    additionalShareholder2HouseNumber: string
    additionalShareholder2ZipCode: string
    additionalShareholder2City: string
    additionalShareholder2PhoneNumber: string
    additionalShareholder2Email: string
    additionalShareholder2CapitalContribution: string
    additionalShareholder2TypeOfIdentification: string
    additionalShareholder2PlaceOfBirth: string
    additionalShareholder2Nationality: string
    additionalShareholder2IdentityCardNumber: string
    additionalShareholder2IssuingAuthority: string
    additionalShareholder2IssuedDay: string
    additionalShareholder2IssuedMonth: string
    additionalShareholder2IssuedYear: string
    additionalShareholder2ValidDay: string
    additionalShareholder2ValidMonth: string
    additionalShareholder2ValidYear: string
    additionalShareholder2Pep: string
    additionalShareholder2PrivateTaxOffice: string
    additionalShareholder2PrivateTaxNumber: string

    additionalShareholder3AcademicTitle: string
    additionalShareholder3Firstname: string
    additionalShareholder3Lastname: string
    additionalShareholder3Birthday: string
    additionalShareholder3Birthmonth: string
    additionalShareholder3Birthyear: string
    additionalShareholder3Denomination: string
    additionalShareholder3TaxIdNumber: string
    additionalShareholder3Street: string
    additionalShareholder3HouseNumber: string
    additionalShareholder3ZipCode: string
    additionalShareholder3City: string
    additionalShareholder3PhoneNumber: string
    additionalShareholder3Email: string
    additionalShareholder3CapitalContribution: string
    additionalShareholder3TypeOfIdentification: string
    additionalShareholder3PlaceOfBirth: string
    additionalShareholder3Nationality: string
    additionalShareholder3IdentityCardNumber: string
    additionalShareholder3IssuingAuthority: string
    additionalShareholder3IssuedDay: string
    additionalShareholder3IssuedMonth: string
    additionalShareholder3IssuedYear: string
    additionalShareholder3ValidDay: string
    additionalShareholder3ValidMonth: string
    additionalShareholder3ValidYear: string
    additionalShareholder3Pep: string
    additionalShareholder3PrivateTaxOffice: string
    additionalShareholder3PrivateTaxNumber: string

    additionalShareholder4AcademicTitle: string
    additionalShareholder4Firstname: string
    additionalShareholder4Lastname: string
    additionalShareholder4Birthday: string
    additionalShareholder4Birthmonth: string
    additionalShareholder4Birthyear: string
    additionalShareholder4Denomination: string
    additionalShareholder4TaxIdNumber: string
    additionalShareholder4Street: string
    additionalShareholder4HouseNumber: string
    additionalShareholder4ZipCode: string
    additionalShareholder4City: string
    additionalShareholder4PhoneNumber: string
    additionalShareholder4Email: string
    additionalShareholder4CapitalContribution: string
    additionalShareholder4TypeOfIdentification: string
    additionalShareholder4PlaceOfBirth: string
    additionalShareholder4Nationality: string
    additionalShareholder4IdentityCardNumber: string
    additionalShareholder4IssuingAuthority: string
    additionalShareholder4IssuedDay: string
    additionalShareholder4IssuedMonth: string
    additionalShareholder4IssuedYear: string
    additionalShareholder4ValidDay: string
    additionalShareholder4ValidMonth: string
    additionalShareholder4ValidYear: string
    additionalShareholder4Pep: string
    additionalShareholder4PrivateTaxOffice: string
    additionalShareholder4PrivateTaxNumber: string

    additionalShareholder5AcademicTitle: string
    additionalShareholder5Firstname: string
    additionalShareholder5Lastname: string
    additionalShareholder5Birthday: string
    additionalShareholder5Birthmonth: string
    additionalShareholder5Birthyear: string
    additionalShareholder5Denomination: string
    additionalShareholder5TaxIdNumber: string
    additionalShareholder5Street: string
    additionalShareholder5HouseNumber: string
    additionalShareholder5ZipCode: string
    additionalShareholder5City: string
    additionalShareholder5PhoneNumber: string
    additionalShareholder5Email: string
    additionalShareholder5CapitalContribution: string
    additionalShareholder5TypeOfIdentification: string
    additionalShareholder5PlaceOfBirth: string
    additionalShareholder5Nationality: string
    additionalShareholder5IdentityCardNumber: string
    additionalShareholder5IssuingAuthority: string
    additionalShareholder5IssuedDay: string
    additionalShareholder5IssuedMonth: string
    additionalShareholder5IssuedYear: string
    additionalShareholder5ValidDay: string
    additionalShareholder5ValidMonth: string
    additionalShareholder5ValidYear: string
    additionalShareholder5Pep: string
    additionalShareholder5PrivateTaxOffice: string
    additionalShareholder5PrivateTaxNumber: string
  }
  onHelperButtonClick: () => void
  onNextButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onBackButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onRemoveShareholder: (e: React.MouseEvent<HTMLButtonElement>) => void
  onAddShareholder: (e: React.MouseEvent<HTMLButtonElement>) => void
  onSuggestionClick: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const AdditionalShareholdersForm = (props: Props) => {
  const {
    values: {
      additionalShareholders,
      numberOfShareholders,
      additionalShareholder1AcademicTitle,
      additionalShareholder1Firstname,
      additionalShareholder1Lastname,
      additionalShareholder1Birthday,
      additionalShareholder1Birthmonth,
      additionalShareholder1Birthyear,
      additionalShareholder1Denomination,
      additionalShareholder1TaxIdNumber,
      additionalShareholder1Street,
      additionalShareholder1HouseNumber,
      additionalShareholder1ZipCode,
      additionalShareholder1City,
      additionalShareholder1PhoneNumber,
      additionalShareholder1Email,
      additionalShareholder1CapitalContribution,
      additionalShareholder1TypeOfIdentification,
      additionalShareholder1PlaceOfBirth,
      additionalShareholder1Nationality,
      additionalShareholder1IdentityCardNumber,
      additionalShareholder1IssuingAuthority,
      additionalShareholder1IssuedDay,
      additionalShareholder1IssuedMonth,
      additionalShareholder1IssuedYear,
      additionalShareholder1ValidDay,
      additionalShareholder1ValidMonth,
      additionalShareholder1ValidYear,
      additionalShareholder1Pep,
      additionalShareholder1PrivateTaxOffice,
      additionalShareholder1PrivateTaxNumber,

      additionalShareholder2AcademicTitle,
      additionalShareholder2Firstname,
      additionalShareholder2Lastname,
      additionalShareholder2Birthday,
      additionalShareholder2Birthmonth,
      additionalShareholder2Birthyear,
      additionalShareholder2Denomination,
      additionalShareholder2TaxIdNumber,
      additionalShareholder2Street,
      additionalShareholder2HouseNumber,
      additionalShareholder2ZipCode,
      additionalShareholder2City,
      additionalShareholder2PhoneNumber,
      additionalShareholder2Email,
      additionalShareholder2CapitalContribution,
      additionalShareholder2TypeOfIdentification,
      additionalShareholder2PlaceOfBirth,
      additionalShareholder2Nationality,
      additionalShareholder2IdentityCardNumber,
      additionalShareholder2IssuingAuthority,
      additionalShareholder2IssuedDay,
      additionalShareholder2IssuedMonth,
      additionalShareholder2IssuedYear,
      additionalShareholder2ValidDay,
      additionalShareholder2ValidMonth,
      additionalShareholder2ValidYear,
      additionalShareholder2Pep,
      additionalShareholder2PrivateTaxOffice,
      additionalShareholder2PrivateTaxNumber,

      additionalShareholder3AcademicTitle,
      additionalShareholder3Firstname,
      additionalShareholder3Lastname,
      additionalShareholder3Birthday,
      additionalShareholder3Birthmonth,
      additionalShareholder3Birthyear,
      additionalShareholder3Denomination,
      additionalShareholder3TaxIdNumber,
      additionalShareholder3Street,
      additionalShareholder3HouseNumber,
      additionalShareholder3ZipCode,
      additionalShareholder3City,
      additionalShareholder3PhoneNumber,
      additionalShareholder3Email,
      additionalShareholder3CapitalContribution,
      additionalShareholder3TypeOfIdentification,
      additionalShareholder3PlaceOfBirth,
      additionalShareholder3Nationality,
      additionalShareholder3IdentityCardNumber,
      additionalShareholder3IssuingAuthority,
      additionalShareholder3IssuedDay,
      additionalShareholder3IssuedMonth,
      additionalShareholder3IssuedYear,
      additionalShareholder3ValidDay,
      additionalShareholder3ValidMonth,
      additionalShareholder3ValidYear,
      additionalShareholder3Pep,
      additionalShareholder3PrivateTaxOffice,
      additionalShareholder3PrivateTaxNumber,

      additionalShareholder4AcademicTitle,
      additionalShareholder4Firstname,
      additionalShareholder4Lastname,
      additionalShareholder4Birthday,
      additionalShareholder4Birthmonth,
      additionalShareholder4Birthyear,
      additionalShareholder4Denomination,
      additionalShareholder4TaxIdNumber,
      additionalShareholder4Street,
      additionalShareholder4HouseNumber,
      additionalShareholder4ZipCode,
      additionalShareholder4City,
      additionalShareholder4PhoneNumber,
      additionalShareholder4Email,
      additionalShareholder4CapitalContribution,
      additionalShareholder4TypeOfIdentification,
      additionalShareholder4PlaceOfBirth,
      additionalShareholder4Nationality,
      additionalShareholder4IdentityCardNumber,
      additionalShareholder4IssuingAuthority,
      additionalShareholder4IssuedDay,
      additionalShareholder4IssuedMonth,
      additionalShareholder4IssuedYear,
      additionalShareholder4ValidDay,
      additionalShareholder4ValidMonth,
      additionalShareholder4ValidYear,
      additionalShareholder4Pep,
      additionalShareholder4PrivateTaxOffice,
      additionalShareholder4PrivateTaxNumber,
      additionalShareholder5AcademicTitle,
      additionalShareholder5Firstname,
      additionalShareholder5Lastname,
      additionalShareholder5Birthday,
      additionalShareholder5Birthmonth,
      additionalShareholder5Birthyear,
      additionalShareholder5Denomination,
      additionalShareholder5TaxIdNumber,
      additionalShareholder5Street,
      additionalShareholder5HouseNumber,
      additionalShareholder5ZipCode,
      additionalShareholder5City,
      additionalShareholder5PhoneNumber,
      additionalShareholder5Email,
      additionalShareholder5CapitalContribution,
      additionalShareholder5TypeOfIdentification,
      additionalShareholder5PlaceOfBirth,
      additionalShareholder5Nationality,
      additionalShareholder5IdentityCardNumber,
      additionalShareholder5IssuingAuthority,
      additionalShareholder5IssuedDay,
      additionalShareholder5IssuedMonth,
      additionalShareholder5IssuedYear,
      additionalShareholder5ValidDay,
      additionalShareholder5ValidMonth,
      additionalShareholder5ValidYear,
      additionalShareholder5Pep,
      additionalShareholder5PrivateTaxOffice,
      additionalShareholder5PrivateTaxNumber,
    },
    onHelperButtonClick,
    onNextButtonClick,
    onChange,
    onBackButtonClick,
    onRemoveShareholder,
    onAddShareholder,
    onSuggestionClick,
  } = props

  const [
    additionalShareholders1DataIsCollapsed,
    setAdditionalShareholders1DataIsCollapsed,
  ] = useState(false)
  const [
    additionalShareholders2DataIsCollapsed,
    setAdditionalShareholders2DataIsCollapsed,
  ] = useState(false)
  const [
    additionalShareholders3DataIsCollapsed,
    setAdditionalShareholders3DataIsCollapsed,
  ] = useState(false)
  const [
    additionalShareholders4DataIsCollapsed,
    setAdditionalShareholders4DataIsCollapsed,
  ] = useState(false)
  const [
    additionalShareholders5DataIsCollapsed,
    setAdditionalShareholders5DataIsCollapsed,
  ] = useState(false)

  useEffect(() => {
    if (numberOfShareholders === 1) {
      setAdditionalShareholders1DataIsCollapsed(false)
    }
    if (numberOfShareholders > 1) {
      setAdditionalShareholders1DataIsCollapsed(true)
    }
    if (numberOfShareholders > 2) {
      setAdditionalShareholders2DataIsCollapsed(true)
    }
    if (numberOfShareholders > 3) {
      setAdditionalShareholders3DataIsCollapsed(true)
    }
    if (numberOfShareholders > 4) {
      setAdditionalShareholders4DataIsCollapsed(true)
    }
  }, [numberOfShareholders])

  const setTinLabelValidationLabel = (taxIdNumber: string) => {
    if (taxIdNumber.length <= 0) {
      return 'Persönliche Steuer-ID (optional)'
    }
    if (!tinValidation(taxIdNumber)) {
      return (
        <span className={classes.validateInfo}>
          Persönliche Steuer-ID (optional){' '}
          <span className={classes.warn}>Keine gültige Steuer-ID</span>
        </span>
      )
    }
    return 'Persönliche Steuer-ID (optional)'
  }

  const renderDeviderButton = (variant: 'add' | 'remove') => {
    if (variant === 'remove') {
      return (
        <div className={classes.deviderButton}>
          <button onClick={onRemoveShareholder} className="danger">
            GesellschafterIn entfernen
          </button>
          <div className={classes.devider}></div>
        </div>
      )
    }
    if (variant === 'add') {
      return (
        <div className={classes.deviderButton}>
          <button onClick={onAddShareholder} className="tertiary">
            GesellschafterIn hinzufügen
          </button>
          <div className={classes.devider}></div>
        </div>
      )
    }
  }

  const renderFormHeader = (
    part: number,
    stateValue: any,
    setStateValue: any
  ) => {
    if (numberOfShareholders > 1) {
      return (
        <header className={classes.shareholderHeader}>
          <h5>Angaben zu GesellschafterIn {part}</h5>
          <button
            className="button secondary small"
            onClick={(e) => {
              e.preventDefault()
              setStateValue(!stateValue)
            }}
          >
            {stateValue ? 'Alle Felder anzeigen' : 'Felder ausblenden'}
          </button>
        </header>
      )
    }
  }

  const preventSubmit = (e: React.PointerEvent<HTMLButtonElement>) => {
    const taxIdNumbers = [
      additionalShareholder1TaxIdNumber,
      additionalShareholder2TaxIdNumber,
      additionalShareholder3TaxIdNumber,
      additionalShareholder4TaxIdNumber,
      additionalShareholder5TaxIdNumber,
    ]

    taxIdNumbers.forEach((taxId) => {
      if (taxId.length > 0 && !tinValidation(taxId)) {
        e.preventDefault()
      }
    })

    setAdditionalShareholders1DataIsCollapsed(false)
    setAdditionalShareholders2DataIsCollapsed(false)
    setAdditionalShareholders3DataIsCollapsed(false)
    setAdditionalShareholders4DataIsCollapsed(false)
    setAdditionalShareholders5DataIsCollapsed(false)
  }

  const setLabel = () => {
    if (
      tinValidation(additionalShareholder1PrivateTaxNumber) ||
      tinValidation(additionalShareholder2PrivateTaxNumber) ||
      tinValidation(additionalShareholder3PrivateTaxNumber) ||
      tinValidation(additionalShareholder4PrivateTaxNumber) ||
      tinValidation(additionalShareholder5PrivateTaxNumber)
    ) {
      return (
        <span className={classes.validateInfo}>
          Steuernummer{' '}
          <span className={classes.warn}>Das ist eine Steuer-ID</span>
        </span>
      )
    }
    return 'Steuernummer'
  }

  const renderAdditionalShareholder1Form = () => {
    if (additionalShareholders === 'Ja') {
      return (
        <Fragment>
          {renderFormHeader(
            3,
            additionalShareholders1DataIsCollapsed,
            setAdditionalShareholders1DataIsCollapsed
          )}

          <div hidden={additionalShareholders1DataIsCollapsed}>
            <TwoColumns layout="1-1">
              <SelectField
                label="Akademischer Titel"
                name="additionalShareholder1AcademicTitle"
                options={['Keine', 'Dr.', 'Prof.', 'Dipl.-Ing.']}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder1AcademicTitle}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Vorname"
                name="additionalShareholder1Firstname"
                onChange={onChange}
                required
                defaultValue={additionalShareholder1Firstname}
                placeholder="Alex"
              />
              <TextInputField
                label="Nachname"
                name="additionalShareholder1Lastname"
                onChange={onChange}
                required
                defaultValue={additionalShareholder1Lastname}
                placeholder="Schmitz"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <DateInputField
                label="Geburtsdatum"
                names={[
                  'additionalShareholder1Birthday',
                  'additionalShareholder1Birthmonth',
                  'additionalShareholder1Birthyear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="\d"
                required
                defaultValue={[
                  additionalShareholder1Birthday,
                  additionalShareholder1Birthmonth,
                  additionalShareholder1Birthyear,
                ]}
              />
              <SelectField
                label="Konfession"
                name="additionalShareholder1Denomination"
                options={[
                  'Konfessionslos / Keine Kirchensteuerberechnung',
                  'ev - Evangelische Kirchensteuer',
                  'ib - Israelitische Religionsgemeinschaft Baden',
                  'ih - Jüdische Kultussteuer',
                  'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
                  'is - Israelitische / Jüdische Kultussteuer',
                  'iw - Israelitische Religionsgemeinschaft Württembergs',
                  'jd - Jüdische Kultussteuer',
                  'jh - Jüdische Kultussteuer',
                  'lt - Evangelisch lutherisch (bis 12/2015)',
                  'rf - Evangelisch reformiert (bis 12/2015)',
                  'rk - Römisch-Katholische Kirchensteuer',
                  'ak - Altkatholische Kirchensteuer',
                  'fa - Freie Religionsgemeinschaft Alzey',
                  'fb - Freireligiöse Landesgemeinde Baden',
                  'fg - Freireligiöse Landesgemeinde Pfalz',
                  'fm - Freireligiöse Gemeinde Mainz',
                  'fr - Französisch reformiert (bis 12/2015)',
                  'fs - Freireligiöse Gemeinde Offenbach/Main',
                ]}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder1Denomination}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                defaultValue={additionalShareholder1TaxIdNumber}
                label={setTinLabelValidationLabel(
                  additionalShareholder1TaxIdNumber
                )}
                name={'additionalShareholder1TaxIdNumber'}
                onChange={onChange}
                placeholder={'56345234987'}
                minLength={11}
                required
              />
              <TextInputField
                label="Straße"
                name="additionalShareholder1Street"
                onChange={onChange}
                required
                defaultValue={additionalShareholder1Street}
                placeholder="Musterstraße"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Hausnummer"
                name="additionalShareholder1HouseNumber"
                onChange={onChange}
                required
                defaultValue={additionalShareholder1HouseNumber}
                placeholder="123"
              />
              <TextInputField
                label="Postleitzahl"
                name="additionalShareholder1ZipCode"
                onChange={onChange}
                required
                defaultValue={additionalShareholder1ZipCode}
                placeholder="45678"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Stadt"
                name="additionalShareholder1City"
                onChange={onChange}
                required
                defaultValue={additionalShareholder1City}
                placeholder="Musterstadt"
              />
              <NumberInputField
                label="Telefonnummer"
                name="additionalShareholder1PhoneNumber"
                onChange={onChange}
                required
                defaultValue={additionalShareholder1PhoneNumber}
                placeholder="0212123456789"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <EmailInputField
                label="E-Mail"
                name="additionalShareholder1Email"
                onChange={onChange}
                required
                defaultValue={additionalShareholder1Email}
                placeholder="hallo@alexschmitz.de"
              />
              <PrependNumberInputField
                label="Gesellschaftsanteile"
                name="additionalShareholder1CapitalContribution"
                placeholder="50"
                prependText="%"
                defaultValue={additionalShareholder1CapitalContribution}
                required
                min={0}
                max={100}
                step={0.01}
                onChange={onChange}
              />
            </TwoColumns>

            <hr />

            <RadioInputField
              label="Identifizierungsart"
              name="additionalShareholder1TypeOfIdentification"
              radioOptions={['Personalausweis', 'Reisepass']}
              onChange={onChange}
              defaultChecked={
                additionalShareholder1TypeOfIdentification === 'Personalausweis'
                  ? 0
                  : 1
              }
            />
            <TwoColumns layout="1-1">
              <TextInputField
                label="Geburtsort"
                name="additionalShareholder1PlaceOfBirth"
                onChange={onChange}
                defaultValue={additionalShareholder1PlaceOfBirth}
                placeholder="Musterstadt"
                pattern=".*[\w].+"
              />
              <SelectField
                label="Staatsangehörigkeit"
                name="additionalShareholder1Nationality"
                options={nations}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder1Nationality}
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <TextInputField
                label="Ausweisnummer"
                name="additionalShareholder1IdentityCardNumber"
                onChange={onChange}
                defaultValue={additionalShareholder1IdentityCardNumber}
                placeholder="M6YXG21094"
                minLength={9}
                maxLength={10}
              />
              <TextInputField
                label="Ausstellende Behörde"
                name="additionalShareholder1IssuingAuthority"
                onChange={onChange}
                defaultValue={additionalShareholder1IssuingAuthority}
                placeholder="Musterstadt"
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <DateInputField
                label="Ausgestellt am"
                names={[
                  'additionalShareholder1IssuedDay',
                  'additionalShareholder1IssuedMonth',
                  'additionalShareholder1IssuedYear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                required
                defaultValue={[
                  additionalShareholder1IssuedDay,
                  additionalShareholder1IssuedMonth,
                  additionalShareholder1IssuedYear,
                ]}
              />
              <DateInputField
                label="Gültig bis"
                names={[
                  'additionalShareholder1ValidDay',
                  'additionalShareholder1ValidMonth',
                  'additionalShareholder1ValidYear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', '']}
                min={['1', '1', '']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                required
                defaultValue={[
                  additionalShareholder1ValidDay,
                  additionalShareholder1ValidMonth,
                  additionalShareholder1ValidYear,
                ]}
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <RadioInputField
                label="PeP-Status/Politisch exponierte Person"
                name="additionalShareholder1Pep"
                radioOptions={['Nein', 'Ja']}
                onChange={onChange}
                defaultChecked={additionalShareholder1Pep === 'Nein' ? 0 : 1}
              />
            </TwoColumns>

            <hr />

            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="additionalShareholder1PrivateTaxOffice"
              value={additionalShareholder1PrivateTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Finanzamt Hilden"
              required
              data={taxOffices}
            />
            <TextInputField
              label={setLabel()}
              name="additionalShareholder1PrivateTaxNumber"
              defaultValue={additionalShareholder1PrivateTaxNumber}
              placeholder="123/456/789"
              onChange={onChange}
              required
            />
          </div>

          {renderAdditionalShareholder2Form()}
          {renderAdditionalShareholder3Form()}
          {renderAdditionalShareholder4Form()}
          {renderAdditionalShareholder5Form()}

          {numberOfShareholders < 5 ? renderDeviderButton('add') : null}
        </Fragment>
      )
    }
  }

  const renderAdditionalShareholder2Form = () => {
    if (numberOfShareholders > 1) {
      return (
        <Fragment>
          {renderDeviderButton('remove')}
          {renderFormHeader(
            4,
            additionalShareholders2DataIsCollapsed,
            setAdditionalShareholders2DataIsCollapsed
          )}

          <div hidden={additionalShareholders2DataIsCollapsed}>
            <TwoColumns layout="1-1">
              <SelectField
                label="Akademischer Titel"
                name="additionalShareholder2AcademicTitle"
                options={['Keine', 'Dr.', 'Prof.', 'Dipl.-Ing.']}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder2AcademicTitle}
                autoFocus
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Vorname"
                name="additionalShareholder2Firstname"
                onChange={onChange}
                required
                defaultValue={additionalShareholder2Firstname}
                placeholder="Alex"
              />
              <TextInputField
                label="Nachname"
                name="additionalShareholder2Lastname"
                onChange={onChange}
                required
                defaultValue={additionalShareholder2Lastname}
                placeholder="Schmitz"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <DateInputField
                label="Geburtsdatum"
                names={[
                  'additionalShareholder2Birthday',
                  'additionalShareholder2Birthmonth',
                  'additionalShareholder2Birthyear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="\d"
                required
                defaultValue={[
                  additionalShareholder2Birthday,
                  additionalShareholder2Birthmonth,
                  additionalShareholder2Birthyear,
                ]}
              />
              <SelectField
                label="Konfession"
                name="additionalShareholder2Denomination"
                options={[
                  'Konfessionslos / Keine Kirchensteuerberechnung',
                  'ev - Evangelische Kirchensteuer',
                  'ib - Israelitische Religionsgemeinschaft Baden',
                  'ih - Jüdische Kultussteuer',
                  'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
                  'is - Israelitische / Jüdische Kultussteuer',
                  'iw - Israelitische Religionsgemeinschaft Württembergs',
                  'jd - Jüdische Kultussteuer',
                  'jh - Jüdische Kultussteuer',
                  'lt - Evangelisch lutherisch (bis 12/2015)',
                  'rf - Evangelisch reformiert (bis 12/2015)',
                  'rk - Römisch-Katholische Kirchensteuer',
                  'ak - Altkatholische Kirchensteuer',
                  'fa - Freie Religionsgemeinschaft Alzey',
                  'fb - Freireligiöse Landesgemeinde Baden',
                  'fg - Freireligiöse Landesgemeinde Pfalz',
                  'fm - Freireligiöse Gemeinde Mainz',
                  'fr - Französisch reformiert (bis 12/2015)',
                  'fs - Freireligiöse Gemeinde Offenbach/Main',
                ]}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder2Denomination}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                defaultValue={additionalShareholder2TaxIdNumber}
                label={setTinLabelValidationLabel(
                  additionalShareholder2TaxIdNumber
                )}
                name={'additionalShareholder2TaxIdNumber'}
                onChange={onChange}
                placeholder={'56345234987'}
                minLength={11}
                required
              />
              <TextInputField
                label="Straße"
                name="additionalShareholder2Street"
                onChange={onChange}
                required
                defaultValue={additionalShareholder2Street}
                placeholder="Musterstraße"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Hausnummer"
                name="additionalShareholder2HouseNumber"
                onChange={onChange}
                required
                defaultValue={additionalShareholder2HouseNumber}
                placeholder="123"
              />
              <TextInputField
                label="Postleitzahl"
                name="additionalShareholder2ZipCode"
                onChange={onChange}
                required
                defaultValue={additionalShareholder2ZipCode}
                placeholder="45678"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Stadt"
                name="additionalShareholder2City"
                onChange={onChange}
                required
                defaultValue={additionalShareholder2City}
                placeholder="Musterstadt"
              />
              <NumberInputField
                label="Telefonnummer"
                name="additionalShareholder2PhoneNumber"
                onChange={onChange}
                required
                defaultValue={additionalShareholder2PhoneNumber}
                placeholder="0212123456789"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <EmailInputField
                label="E-Mail"
                name="additionalShareholder2Email"
                onChange={onChange}
                required
                defaultValue={additionalShareholder2Email}
                placeholder="hallo@alexschmitz.de"
              />
              <PrependNumberInputField
                label="Gesellschaftsanteile"
                name="additionalShareholder2CapitalContribution"
                placeholder="50"
                prependText="%"
                defaultValue={additionalShareholder2CapitalContribution}
                required
                min={0}
                max={100}
                step={0.01}
                onChange={onChange}
              />
            </TwoColumns>

            <hr />

            <RadioInputField
              label="Identifizierungsart"
              name="additionalShareholder2TypeOfIdentification"
              radioOptions={['Personalausweis', 'Reisepass']}
              onChange={onChange}
              defaultChecked={
                additionalShareholder2TypeOfIdentification === 'Personalausweis'
                  ? 0
                  : 1
              }
            />
            <TwoColumns layout="1-1">
              <TextInputField
                label="Geburtsort"
                name="additionalShareholder2PlaceOfBirth"
                onChange={onChange}
                defaultValue={additionalShareholder2PlaceOfBirth}
                placeholder="Musterstadt"
                pattern=".*[\w].+"
              />
              <SelectField
                label="Staatsangehörigkeit"
                name="additionalShareholder2Nationality"
                options={nations}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder2Nationality}
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <TextInputField
                label="Ausweisnummer"
                name="additionalShareholder2IdentityCardNumber"
                onChange={onChange}
                defaultValue={additionalShareholder2IdentityCardNumber}
                placeholder="M6YXG21094"
                minLength={9}
                maxLength={10}
              />
              <TextInputField
                label="Ausstellende Behörde"
                name="additionalShareholder2IssuingAuthority"
                onChange={onChange}
                defaultValue={additionalShareholder2IssuingAuthority}
                placeholder="Musterstadt"
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <DateInputField
                label="Ausgestellt am"
                names={[
                  'additionalShareholder2IssuedDay',
                  'additionalShareholder2IssuedMonth',
                  'additionalShareholder2IssuedYear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                required
                defaultValue={[
                  additionalShareholder2IssuedDay,
                  additionalShareholder2IssuedMonth,
                  additionalShareholder2IssuedYear,
                ]}
              />
              <DateInputField
                label="Gültig bis"
                names={[
                  'additionalShareholder2ValidDay',
                  'additionalShareholder2ValidMonth',
                  'additionalShareholder2ValidYear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', '']}
                min={['1', '1', '']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                required
                defaultValue={[
                  additionalShareholder2ValidDay,
                  additionalShareholder2ValidMonth,
                  additionalShareholder2ValidYear,
                ]}
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <RadioInputField
                label="PeP-Status/Politisch exponierte Person"
                name="additionalShareholder2Pep"
                radioOptions={['Nein', 'Ja']}
                onChange={onChange}
                defaultChecked={additionalShareholder2Pep === 'Nein' ? 0 : 1}
              />
            </TwoColumns>

            <hr />

            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="additionalShareholder2PrivateTaxOffice"
              value={additionalShareholder2PrivateTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Finanzamt Hilden"
              required
              data={taxOffices}
            />
            <TextInputField
              label={setLabel()}
              name="additionalShareholder2PrivateTaxNumber"
              defaultValue={additionalShareholder2PrivateTaxNumber}
              placeholder="123/456/789"
              onChange={onChange}
              required
            />
          </div>
        </Fragment>
      )
    }
  }

  const renderAdditionalShareholder3Form = () => {
    if (numberOfShareholders > 2) {
      return (
        <Fragment>
          {renderDeviderButton('remove')}
          {renderFormHeader(
            5,
            additionalShareholders3DataIsCollapsed,
            setAdditionalShareholders3DataIsCollapsed
          )}

          <div hidden={additionalShareholders3DataIsCollapsed}>
            <TwoColumns layout="1-1">
              <SelectField
                label="Akademischer Titel"
                name="additionalShareholder3AcademicTitle"
                options={['Keine', 'Dr.', 'Prof.', 'Dipl.-Ing.']}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder3AcademicTitle}
                autoFocus
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Vorname"
                name="additionalShareholder3Firstname"
                onChange={onChange}
                required
                defaultValue={additionalShareholder3Firstname}
                placeholder="Alex"
              />
              <TextInputField
                label="Nachname"
                name="additionalShareholder3Lastname"
                onChange={onChange}
                required
                defaultValue={additionalShareholder3Lastname}
                placeholder="Schmitz"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <DateInputField
                label="Geburtsdatum"
                names={[
                  'additionalShareholder3Birthday',
                  'additionalShareholder3Birthmonth',
                  'additionalShareholder3Birthyear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="\d"
                required
                defaultValue={[
                  additionalShareholder3Birthday,
                  additionalShareholder3Birthmonth,
                  additionalShareholder3Birthyear,
                ]}
              />
              <SelectField
                label="Konfession"
                name="additionalShareholder3Denomination"
                options={[
                  'Konfessionslos / Keine Kirchensteuerberechnung',
                  'ev - Evangelische Kirchensteuer',
                  'ib - Israelitische Religionsgemeinschaft Baden',
                  'ih - Jüdische Kultussteuer',
                  'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
                  'is - Israelitische / Jüdische Kultussteuer',
                  'iw - Israelitische Religionsgemeinschaft Württembergs',
                  'jd - Jüdische Kultussteuer',
                  'jh - Jüdische Kultussteuer',
                  'lt - Evangelisch lutherisch (bis 12/2015)',
                  'rf - Evangelisch reformiert (bis 12/2015)',
                  'rk - Römisch-Katholische Kirchensteuer',
                  'ak - Altkatholische Kirchensteuer',
                  'fa - Freie Religionsgemeinschaft Alzey',
                  'fb - Freireligiöse Landesgemeinde Baden',
                  'fg - Freireligiöse Landesgemeinde Pfalz',
                  'fm - Freireligiöse Gemeinde Mainz',
                  'fr - Französisch reformiert (bis 12/2015)',
                  'fs - Freireligiöse Gemeinde Offenbach/Main',
                ]}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder3Denomination}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                defaultValue={additionalShareholder3TaxIdNumber}
                label={setTinLabelValidationLabel(
                  additionalShareholder3TaxIdNumber
                )}
                name={'additionalShareholder3TaxIdNumber'}
                onChange={onChange}
                placeholder={'56345234987'}
                minLength={11}
                required
              />
              <TextInputField
                label="Straße"
                name="additionalShareholder3Street"
                onChange={onChange}
                required
                defaultValue={additionalShareholder3Street}
                placeholder="Musterstraße"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Hausnummer"
                name="additionalShareholder3HouseNumber"
                onChange={onChange}
                required
                defaultValue={additionalShareholder3HouseNumber}
                placeholder="123"
              />
              <TextInputField
                label="Postleitzahl"
                name="additionalShareholder3ZipCode"
                onChange={onChange}
                required
                defaultValue={additionalShareholder3ZipCode}
                placeholder="45678"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Stadt"
                name="additionalShareholder3City"
                onChange={onChange}
                required
                defaultValue={additionalShareholder3City}
                placeholder="Musterstadt"
              />
              <NumberInputField
                label="Telefonnummer"
                name="additionalShareholder3PhoneNumber"
                onChange={onChange}
                required
                defaultValue={additionalShareholder3PhoneNumber}
                placeholder="0212123456789"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <EmailInputField
                label="E-Mail"
                name="additionalShareholder3Email"
                onChange={onChange}
                required
                defaultValue={additionalShareholder3Email}
                placeholder="hallo@alexschmitz.de"
              />
              <PrependNumberInputField
                label="Gesellschaftsanteile"
                name="additionalShareholder3CapitalContribution"
                placeholder="50"
                prependText="%"
                defaultValue={additionalShareholder3CapitalContribution}
                required
                min={0}
                max={100}
                step={0.01}
                onChange={onChange}
              />
            </TwoColumns>

            <hr />

            <RadioInputField
              label="Identifizierungsart"
              name="additionalShareholder3TypeOfIdentification"
              radioOptions={['Personalausweis', 'Reisepass']}
              onChange={onChange}
              defaultChecked={
                additionalShareholder3TypeOfIdentification === 'Personalausweis'
                  ? 0
                  : 1
              }
            />
            <TwoColumns layout="1-1">
              <TextInputField
                label="Geburtsort"
                name="additionalShareholder3PlaceOfBirth"
                onChange={onChange}
                defaultValue={additionalShareholder3PlaceOfBirth}
                placeholder="Musterstadt"
                pattern=".*[\w].+"
              />
              <SelectField
                label="Staatsangehörigkeit"
                name="additionalShareholder3Nationality"
                options={nations}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder3Nationality}
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <TextInputField
                label="Ausweisnummer"
                name="additionalShareholder3IdentityCardNumber"
                onChange={onChange}
                defaultValue={additionalShareholder3IdentityCardNumber}
                placeholder="M6YXG21094"
                minLength={9}
                maxLength={10}
              />
              <TextInputField
                label="Ausstellende Behörde"
                name="additionalShareholder3IssuingAuthority"
                onChange={onChange}
                defaultValue={additionalShareholder3IssuingAuthority}
                placeholder="Musterstadt"
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <DateInputField
                label="Ausgestellt am"
                names={[
                  'additionalShareholder3IssuedDay',
                  'additionalShareholder3IssuedMonth',
                  'additionalShareholder3IssuedYear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                required
                defaultValue={[
                  additionalShareholder3IssuedDay,
                  additionalShareholder3IssuedMonth,
                  additionalShareholder3IssuedYear,
                ]}
              />
              <DateInputField
                label="Gültig bis"
                names={[
                  'additionalShareholder3ValidDay',
                  'additionalShareholder3ValidMonth',
                  'additionalShareholder3ValidYear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', '']}
                min={['1', '1', '']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                required
                defaultValue={[
                  additionalShareholder3ValidDay,
                  additionalShareholder3ValidMonth,
                  additionalShareholder3ValidYear,
                ]}
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <RadioInputField
                label="PeP-Status/Politisch exponierte Person"
                name="additionalShareholder3Pep"
                radioOptions={['Nein', 'Ja']}
                onChange={onChange}
                defaultChecked={additionalShareholder3Pep === 'Nein' ? 0 : 1}
              />
            </TwoColumns>

            <hr />

            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="additionalShareholder3PrivateTaxOffice"
              value={additionalShareholder3PrivateTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Finanzamt Hilden"
              required
              data={taxOffices}
            />
            <TextInputField
              label={setLabel()}
              name="additionalShareholder3PrivateTaxNumber"
              defaultValue={additionalShareholder3PrivateTaxNumber}
              placeholder="123/456/789"
              onChange={onChange}
              required
            />
          </div>
        </Fragment>
      )
    }
  }

  const renderAdditionalShareholder4Form = () => {
    if (numberOfShareholders > 3) {
      return (
        <Fragment>
          {renderDeviderButton('remove')}
          {renderFormHeader(
            6,
            additionalShareholders4DataIsCollapsed,
            setAdditionalShareholders4DataIsCollapsed
          )}

          <div hidden={additionalShareholders4DataIsCollapsed}>
            <TwoColumns layout="1-1">
              <SelectField
                label="Akademischer Titel"
                name="additionalShareholder4AcademicTitle"
                options={['Keine', 'Dr.', 'Prof.', 'Dipl.-Ing.']}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder4AcademicTitle}
                autoFocus
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Vorname"
                name="additionalShareholder4Firstname"
                onChange={onChange}
                required
                defaultValue={additionalShareholder4Firstname}
                placeholder="Alex"
              />
              <TextInputField
                label="Nachname"
                name="additionalShareholder4Lastname"
                onChange={onChange}
                required
                defaultValue={additionalShareholder4Lastname}
                placeholder="Schmitz"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <DateInputField
                label="Geburtsdatum"
                names={[
                  'additionalShareholder4Birthday',
                  'additionalShareholder4Birthmonth',
                  'additionalShareholder4Birthyear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="\d"
                required
                defaultValue={[
                  additionalShareholder4Birthday,
                  additionalShareholder4Birthmonth,
                  additionalShareholder4Birthyear,
                ]}
              />
              <SelectField
                label="Konfession"
                name="additionalShareholder4Denomination"
                options={[
                  'Konfessionslos / Keine Kirchensteuerberechnung',
                  'ev - Evangelische Kirchensteuer',
                  'ib - Israelitische Religionsgemeinschaft Baden',
                  'ih - Jüdische Kultussteuer',
                  'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
                  'is - Israelitische / Jüdische Kultussteuer',
                  'iw - Israelitische Religionsgemeinschaft Württembergs',
                  'jd - Jüdische Kultussteuer',
                  'jh - Jüdische Kultussteuer',
                  'lt - Evangelisch lutherisch (bis 12/2015)',
                  'rf - Evangelisch reformiert (bis 12/2015)',
                  'rk - Römisch-Katholische Kirchensteuer',
                  'ak - Altkatholische Kirchensteuer',
                  'fa - Freie Religionsgemeinschaft Alzey',
                  'fb - Freireligiöse Landesgemeinde Baden',
                  'fg - Freireligiöse Landesgemeinde Pfalz',
                  'fm - Freireligiöse Gemeinde Mainz',
                  'fr - Französisch reformiert (bis 12/2015)',
                  'fs - Freireligiöse Gemeinde Offenbach/Main',
                ]}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder4Denomination}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                defaultValue={additionalShareholder4TaxIdNumber}
                label={setTinLabelValidationLabel(
                  additionalShareholder4TaxIdNumber
                )}
                name={'additionalShareholder4TaxIdNumber'}
                onChange={onChange}
                placeholder={'56345234987'}
                minLength={11}
                required
              />
              <TextInputField
                label="Straße"
                name="additionalShareholder4Street"
                onChange={onChange}
                required
                defaultValue={additionalShareholder4Street}
                placeholder="Musterstraße"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Hausnummer"
                name="additionalShareholder4HouseNumber"
                onChange={onChange}
                required
                defaultValue={additionalShareholder4HouseNumber}
                placeholder="123"
              />
              <TextInputField
                label="Postleitzahl"
                name="additionalShareholder4ZipCode"
                onChange={onChange}
                required
                defaultValue={additionalShareholder4ZipCode}
                placeholder="45678"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Stadt"
                name="additionalShareholder4City"
                onChange={onChange}
                required
                defaultValue={additionalShareholder4City}
                placeholder="Musterstadt"
              />
              <NumberInputField
                label="Telefonnummer"
                name="additionalShareholder4PhoneNumber"
                onChange={onChange}
                required
                defaultValue={additionalShareholder4PhoneNumber}
                placeholder="0212123456789"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <EmailInputField
                label="E-Mail"
                name="additionalShareholder4Email"
                onChange={onChange}
                required
                defaultValue={additionalShareholder4Email}
                placeholder="hallo@alexschmitz.de"
              />
              <PrependNumberInputField
                label="Gesellschaftsanteile"
                name="additionalShareholder4CapitalContribution"
                placeholder="50"
                prependText="%"
                defaultValue={additionalShareholder4CapitalContribution}
                required
                min={0}
                max={100}
                step={0.01}
                onChange={onChange}
              />
            </TwoColumns>

            <hr />

            <RadioInputField
              label="Identifizierungsart"
              name="additionalShareholder4TypeOfIdentification"
              radioOptions={['Personalausweis', 'Reisepass']}
              onChange={onChange}
              defaultChecked={
                additionalShareholder4TypeOfIdentification === 'Personalausweis'
                  ? 0
                  : 1
              }
            />
            <TwoColumns layout="1-1">
              <TextInputField
                label="Geburtsort"
                name="additionalShareholder4PlaceOfBirth"
                onChange={onChange}
                defaultValue={additionalShareholder4PlaceOfBirth}
                placeholder="Musterstadt"
                pattern=".*[\w].+"
              />
              <SelectField
                label="Staatsangehörigkeit"
                name="additionalShareholder4Nationality"
                options={nations}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder4Nationality}
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <TextInputField
                label="Ausweisnummer"
                name="additionalShareholder4IdentityCardNumber"
                onChange={onChange}
                defaultValue={additionalShareholder4IdentityCardNumber}
                placeholder="M6YXG21094"
                minLength={9}
                maxLength={10}
              />
              <TextInputField
                label="Ausstellende Behörde"
                name="additionalShareholder4IssuingAuthority"
                onChange={onChange}
                defaultValue={additionalShareholder4IssuingAuthority}
                placeholder="Musterstadt"
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <DateInputField
                label="Ausgestellt am"
                names={[
                  'additionalShareholder4IssuedDay',
                  'additionalShareholder4IssuedMonth',
                  'additionalShareholder4IssuedYear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                required
                defaultValue={[
                  additionalShareholder4IssuedDay,
                  additionalShareholder4IssuedMonth,
                  additionalShareholder4IssuedYear,
                ]}
              />
              <DateInputField
                label="Gültig bis"
                names={[
                  'additionalShareholder4ValidDay',
                  'additionalShareholder4ValidMonth',
                  'additionalShareholder4ValidYear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', '']}
                min={['1', '1', '']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                required
                defaultValue={[
                  additionalShareholder4ValidDay,
                  additionalShareholder4ValidMonth,
                  additionalShareholder4ValidYear,
                ]}
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <RadioInputField
                label="PeP-Status/Politisch exponierte Person"
                name="additionalShareholder4Pep"
                radioOptions={['Nein', 'Ja']}
                onChange={onChange}
                defaultChecked={additionalShareholder4Pep === 'Nein' ? 0 : 1}
              />
            </TwoColumns>

            <hr />

            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="additionalShareholder4PrivateTaxOffice"
              value={additionalShareholder4PrivateTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Finanzamt Hilden"
              required
              data={taxOffices}
            />
            <TextInputField
              label={setLabel()}
              name="additionalShareholder4PrivateTaxNumber"
              defaultValue={additionalShareholder4PrivateTaxNumber}
              placeholder="123/456/789"
              onChange={onChange}
              required
            />
          </div>
        </Fragment>
      )
    }
  }

  const renderAdditionalShareholder5Form = () => {
    if (numberOfShareholders > 4) {
      return (
        <Fragment>
          {renderDeviderButton('remove')}
          {renderFormHeader(
            7,
            additionalShareholders5DataIsCollapsed,
            setAdditionalShareholders5DataIsCollapsed
          )}

          <div hidden={additionalShareholders5DataIsCollapsed}>
            <TwoColumns layout="1-1">
              <SelectField
                label="Akademischer Titel"
                name="additionalShareholder5AcademicTitle"
                options={['Keine', 'Dr.', 'Prof.', 'Dipl.-Ing.']}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder5AcademicTitle}
                autoFocus
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Vorname"
                name="additionalShareholder5Firstname"
                onChange={onChange}
                required
                defaultValue={additionalShareholder5Firstname}
                placeholder="Alex"
              />
              <TextInputField
                label="Nachname"
                name="additionalShareholder5Lastname"
                onChange={onChange}
                required
                defaultValue={additionalShareholder5Lastname}
                placeholder="Schmitz"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <DateInputField
                label="Geburtsdatum"
                names={[
                  'additionalShareholder5Birthday',
                  'additionalShareholder5Birthmonth',
                  'additionalShareholder5Birthyear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="\d"
                required
                defaultValue={[
                  additionalShareholder5Birthday,
                  additionalShareholder5Birthmonth,
                  additionalShareholder5Birthyear,
                ]}
              />
              <SelectField
                label="Konfession"
                name="additionalShareholder5Denomination"
                options={[
                  'Konfessionslos / Keine Kirchensteuerberechnung',
                  'ev - Evangelische Kirchensteuer',
                  'ib - Israelitische Religionsgemeinschaft Baden',
                  'ih - Jüdische Kultussteuer',
                  'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
                  'is - Israelitische / Jüdische Kultussteuer',
                  'iw - Israelitische Religionsgemeinschaft Württembergs',
                  'jd - Jüdische Kultussteuer',
                  'jh - Jüdische Kultussteuer',
                  'lt - Evangelisch lutherisch (bis 12/2015)',
                  'rf - Evangelisch reformiert (bis 12/2015)',
                  'rk - Römisch-Katholische Kirchensteuer',
                  'ak - Altkatholische Kirchensteuer',
                  'fa - Freie Religionsgemeinschaft Alzey',
                  'fb - Freireligiöse Landesgemeinde Baden',
                  'fg - Freireligiöse Landesgemeinde Pfalz',
                  'fm - Freireligiöse Gemeinde Mainz',
                  'fr - Französisch reformiert (bis 12/2015)',
                  'fs - Freireligiöse Gemeinde Offenbach/Main',
                ]}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder5Denomination}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                defaultValue={additionalShareholder5TaxIdNumber}
                label={setTinLabelValidationLabel(
                  additionalShareholder5TaxIdNumber
                )}
                name={'additionalShareholder5TaxIdNumber'}
                onChange={onChange}
                placeholder={'56345234987'}
                minLength={11}
                required
              />
              <TextInputField
                label="Straße"
                name="additionalShareholder5Street"
                onChange={onChange}
                required
                defaultValue={additionalShareholder5Street}
                placeholder="Musterstraße"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Hausnummer"
                name="additionalShareholder5HouseNumber"
                onChange={onChange}
                required
                defaultValue={additionalShareholder5HouseNumber}
                placeholder="123"
              />
              <TextInputField
                label="Postleitzahl"
                name="additionalShareholder5ZipCode"
                onChange={onChange}
                required
                defaultValue={additionalShareholder5ZipCode}
                placeholder="45678"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Stadt"
                name="additionalShareholder5City"
                onChange={onChange}
                required
                defaultValue={additionalShareholder5City}
                placeholder="Musterstadt"
              />
              <NumberInputField
                label="Telefonnummer"
                name="additionalShareholder5PhoneNumber"
                onChange={onChange}
                required
                defaultValue={additionalShareholder5PhoneNumber}
                placeholder="0212123456789"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <EmailInputField
                label="E-Mail"
                name="additionalShareholder5Email"
                onChange={onChange}
                required
                defaultValue={additionalShareholder5Email}
                placeholder="hallo@alexschmitz.de"
              />
              <PrependNumberInputField
                label="Gesellschaftsanteile"
                name="additionalShareholder5CapitalContribution"
                placeholder="50"
                prependText="%"
                defaultValue={additionalShareholder5CapitalContribution}
                required
                min={0}
                max={100}
                step={0.01}
                onChange={onChange}
              />
            </TwoColumns>

            <hr />

            <RadioInputField
              label="Identifizierungsart"
              name="additionalShareholder5TypeOfIdentification"
              radioOptions={['Personalausweis', 'Reisepass']}
              onChange={onChange}
              defaultChecked={
                additionalShareholder5TypeOfIdentification === 'Personalausweis'
                  ? 0
                  : 1
              }
            />
            <TwoColumns layout="1-1">
              <TextInputField
                label="Geburtsort"
                name="additionalShareholder5PlaceOfBirth"
                onChange={onChange}
                defaultValue={additionalShareholder5PlaceOfBirth}
                placeholder="Musterstadt"
                pattern=".*[\w].+"
              />
              <SelectField
                label="Staatsangehörigkeit"
                name="additionalShareholder5Nationality"
                options={nations}
                noDefault
                onChange={onChange}
                required
                defaultValue={additionalShareholder5Nationality}
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <TextInputField
                label="Ausweisnummer"
                name="additionalShareholder5IdentityCardNumber"
                onChange={onChange}
                defaultValue={additionalShareholder5IdentityCardNumber}
                placeholder="M6YXG21094"
                minLength={9}
                maxLength={10}
              />
              <TextInputField
                label="Ausstellende Behörde"
                name="additionalShareholder5IssuingAuthority"
                onChange={onChange}
                defaultValue={additionalShareholder5IssuingAuthority}
                placeholder="Musterstadt"
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <DateInputField
                label="Ausgestellt am"
                names={[
                  'additionalShareholder5IssuedDay',
                  'additionalShareholder5IssuedMonth',
                  'additionalShareholder5IssuedYear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                required
                defaultValue={[
                  additionalShareholder5IssuedDay,
                  additionalShareholder5IssuedMonth,
                  additionalShareholder5IssuedYear,
                ]}
              />
              <DateInputField
                label="Gültig bis"
                names={[
                  'additionalShareholder5ValidDay',
                  'additionalShareholder5ValidMonth',
                  'additionalShareholder5ValidYear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', '']}
                min={['1', '1', '']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                required
                defaultValue={[
                  additionalShareholder5ValidDay,
                  additionalShareholder5ValidMonth,
                  additionalShareholder5ValidYear,
                ]}
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <RadioInputField
                label="PeP-Status/Politisch exponierte Person"
                name="additionalShareholder5Pep"
                radioOptions={['Nein', 'Ja']}
                onChange={onChange}
                defaultChecked={additionalShareholder5Pep === 'Nein' ? 0 : 1}
              />
            </TwoColumns>

            <hr />

            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="additionalShareholder5PrivateTaxOffice"
              value={additionalShareholder5PrivateTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Finanzamt Hilden"
              required
              data={taxOffices}
            />
            <TextInputField
              label={setLabel()}
              name="additionalShareholder5PrivateTaxNumber"
              defaultValue={additionalShareholder5PrivateTaxNumber}
              placeholder="123/456/789"
              onChange={onChange}
              required
            />
          </div>
        </Fragment>
      )
    }
  }

  return (
    <FormContainer
      className={classes.additionalShareholdersForm}
      onSubmit={onNextButtonClick}
    >
      <HelperButton onClick={onHelperButtonClick} />
      <FormHeader headlineText="Weitere GesellschafterInnen" />
      <FormBody>
        <TwoColumns layout="1-1">
          <RadioInputField
            label="Gibt es weitere GesellschafterInnen?"
            name="additionalShareholders"
            radioOptions={['Nein', 'Ja']}
            onChange={onChange}
            defaultChecked={additionalShareholders === 'Nein' ? 0 : 1}
          />
        </TwoColumns>

        {renderAdditionalShareholder1Form()}
      </FormBody>
      <FormFooter
        onClick={preventSubmit}
        primaryButtonText="Weiter"
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={onBackButtonClick}
      />
    </FormContainer>
  )
}

export default AdditionalShareholdersForm
