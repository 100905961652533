import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import SplashStage from '../../components/Stages/SplashStage/SplashStage'
import FormContainer from '../../components/ui-components/forms/FormContainer/FormContainer'
import FormBody from '../../components/ui-components/forms/FormBody/FormBody'
import FormHint from '../../components/ui-components/hints/FormHint'
import ProgressBar from '../../components/ui-components/ProgressBar/ProgressBar'
import { ReactComponent as CheckIcon } from '../../static/icons/16x16/check-icon-16.svg'
import { ReactComponent as XIcon } from '../../static/icons/16x16/x-icon-16.svg'
import LoadingIndicator from '../../components/ui-components/LoadingIndicator/LoadingIndicator'

import classes from './FormStatusView.module.scss'
import { STAFF_FORMS } from '../../constants/Formulars'

const FormStatusView = () => {
  const database = firebase.database()

  const isMounted = useRef(false)

  const { formId } = useParams<any>()
  const [isLoading, setIsLoading] = useState(true)
  const [formIdExist, setFormIdExist] = useState(false)
  const [employeeDataIsSet, setEmployeeDataIsSet] = useState(false)
  const [consultantDataIsSet, setConsultantDataIsSet] = useState(false)
  const [formCreationDate, setFormCreationDate] = useState()
  const [formIsFinished, setFormIsFinished] = useState(false)
  const [formDeletionDate, setFormDeletionDate] = useState()
  const [formType, setFormType] = useState(0)
  const [progress, setProgress] = useState(0)

  const getData = async () => {
    if (isMounted.current) {
      try {
        const formularName = (
          await database
            .ref(`jobs/${formId}/clientData/formularName`)
            .once('value')
        ).val()
        const employeeData = (
          await database
            .ref(`jobs/${formId}/employeeData/formWasSubmittedBefore`)
            .once('value')
        ).val()
        const consultantData = (
          await database
            .ref(`jobs/${formId}/consultantData/formWasSubmittedBefore`)
            .once('value')
        ).val()
        const formCreationDate = (
          await database
            .ref(`jobs/${formId}/clientData/formCreationDate`)
            .once('value')
        ).val()
        const finishedJob = (
          await database.ref(`jobs/${formId}/finishedJob`).once('value')
        ).val()
        const finishedJobOn = (
          await database.ref(`jobs/${formId}/finishedJobOn`).once('value')
        ).val()

        if (formularName) {
          setFormIdExist(true)
          setFormCreationDate(formCreationDate)
        }
        if (formularName === STAFF_FORMS.IMMEDIATE_REPORT) setFormType(1)
        if (employeeData) setEmployeeDataIsSet(true)
        if (consultantData) setConsultantDataIsSet(true)
        if (finishedJob) {
          setFormIsFinished(true)
          setFormDeletionDate(finishedJobOn[0])
        }

        return setIsLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    return
  }

  useEffect(() => {
    isMounted.current = true
    getData()

    return () => {
      isMounted.current = false
      getData()
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setProgressValue()

    // eslint-disable-next-line
  }, [formIsFinished, employeeDataIsSet, consultantDataIsSet, formType])

  function setProgressValue() {
    if (formIsFinished) return setProgress(100)
    if (formType === 1) return setProgress(50)
    if (employeeDataIsSet || consultantDataIsSet) return setProgress(66)
    return setProgress(33)
  }

  function setDeletionDate() {
    if (formIsFinished) {
      return moment(formDeletionDate).add(14, 'days').format('D.M.YYYY')
    }
    return moment(formCreationDate, 'DD.MM.YYYY')
      .add(1, 'month')
      .format('D.M.YYYY')
  }

  function setIcon(condition: boolean) {
    if (condition) return <CheckIcon />
    return <XIcon />
  }

  function renderViews() {
    if (isLoading) {
      return <LoadingIndicator size="small" />
    }
    if (!formIdExist) {
      return (
        <>
          <h5>
            Leider gibt es keine weiteren Informationen zu diesem Vorgang.
          </h5>
          <FormHint>
            <p>
              Unsere Datenschutzbestimmunge sehen vor, die erfassten Daten nicht
              länger als notwendig zu speichern.
            </p>
            <p>
              Unvollständige Vorgänge, die angelegt und nicht fertiggestellt
              werden, bleiben ab dem Zeitpunkt der Anlage maximal für einen
              Monat im System.
            </p>
            <p>
              Abgeschlossene Vorgänge sind ab dem Zeitpunkt der Fertigstellung
              maximal noch zwei Wochen verfügbar.
            </p>
          </FormHint>

          <a
            href={`mailto:support@fastdocs.de?subject=Formular-ID: ${formId}`}
            className="button secondary ghost center"
          >
            Support kontaktieren
          </a>
        </>
      )
    }

    return (
      <>
        <div className={classes.progressSection}>
          <label>Aktueller Fortschritt</label>
          <label>{progress}%</label>
        </div>
        <ProgressBar progress={progress} />

        <ul className={classes.statusTable}>
          <li>
            Arbeitskraft wurde durch Arbeitgeber:in am: {formCreationDate}{' '}
            angelegt <CheckIcon />
          </li>
          <li>
            Arbeitskraft wurde informiert <CheckIcon />
          </li>
          <li>
            Steuerberatungskanzlei wurde über die Neuanstellung informiert{' '}
            <CheckIcon />
          </li>
          {formType === 0 ? (
            <li>
              Personaldaten wurden durch Arbeitnehmer:in ergänzt{' '}
              {setIcon(employeeDataIsSet)}
            </li>
          ) : null}
          <li>
            Vorgang wurde durch Sachbearbeiter:in der Kanzlei bearbeitet{' '}
            {setIcon(consultantDataIsSet)}
          </li>
          <li>
            PDF-Dokumentation an Arbeitgeber:in und Arbeitskraft versendet{' '}
            {setIcon(formIsFinished)}
          </li>
          <li>Vorgang wurde abgeschlossen {setIcon(formIsFinished)}</li>
        </ul>

        <FormHint
          hintText={`Dieser Vorgang  wird zum ${setDeletionDate()} automatisch gelöscht.`}
        />

        <a
          href={`mailto:support@fastdocs.de?subject=Formular-ID: ${formId}`}
          className="button secondary ghost center"
        >
          Support kontaktieren
        </a>
      </>
    )
  }

  return (
    <>
      <SplashStage headlineText={`Formular-ID: ${formId}`} />
      <FormContainer className={classes.FormStatusView}>
        <FormBody>{renderViews()}</FormBody>
      </FormContainer>
    </>
  )
}

export default FormStatusView
