import React, { ChangeEvent } from 'react'
import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import SelectField from '../../Selects/SelectField/SelectField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import './EducationForm.scss'

interface Props {
  graduation: string
  education: string
  onSubmit: (e: ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: ChangeEvent<HTMLInputElement>) => void
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onSecondaryButtonClick: (e: ChangeEvent<HTMLInputElement>) => void
}

const EducationForm = (props: Props) => {
  return (
    <FormContainer className={'education-form'} onSubmit={props.onSubmit}>
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Ausbildung'} />
      <FormBody>
        <SelectField
          autoFocus
          defaultValue={props.graduation}
          label={'Höchster Schulabschluss'}
          name={'graduation'}
          onChange={props.onChange}
          options={[
            'ohne Schulabschluss',
            'Haupt-/Volksschulabschluss',
            'Mittlere Reife/gleichwertiger Abschluss',
            'Abitur/Fachabitur',
          ]}
          required
          noDefault
        />
        <SelectField
          defaultValue={props.education}
          label={'Höchste Berufsausbildung'}
          name={'education'}
          onChange={props.onChange}
          options={[
            'ohne beruflichen Ausbildungabschluss',
            'Anerkannte Berufsausbildung',
            'Meister/Techniker/gleichwertiger Abschluss',
            'Bachelor',
            'Diplom/Magister/Master/Staatsexamen',
            'Promotion',
          ]}
          required
          noDefault
        />
      </FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
      />
    </FormContainer>
  )
}

export default EducationForm
