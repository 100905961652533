import React, { Fragment, useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import IconButton from '../../ui-components/buttons/IconButton/IconButton'
import { ReactComponent as CrossIcon } from '../../../static/CrossIcon.svg'
import { ReactComponent as AlertIcon } from '../../../static/icons/48x48/alert-triangle-48.svg'

import './ClientJobDetailsDialog.scss'
import ActionButton from '../../ui-components/buttons/ActionButton/ActionButton'

interface Props {
  headline: string
  formularName: string
  formCreationDate: string
  client: string
  deletionDay: string
  formId: string
  containerAnimation: string
  contentAnimation: string
  handleIconButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleDeleteFormClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ClientJobDetailsDialog = (props: Props) => {
  const [alert, setAlert] = useState(false)
  const [documentationButtonState, setDocumentationButtonState] =
    useState('initial')

  const {
    headline,
    formularName,
    formCreationDate,
    client,
    deletionDay,
    formId,
    containerAnimation,
    contentAnimation,
    handleIconButtonClick,
    handleDeleteFormClick,
  } = props

  const resendDocumentation = () => {
    setDocumentationButtonState('active')

    setTimeout(() => {
      firebase
        .database()
        .ref(`clientJobs/${formId}`)
        .update({
          finishedJob: false,
        })
        .then(() => {
          setDocumentationButtonState('finished')

          setTimeout(() => {
            setDocumentationButtonState('initial')
          }, 1000)
        })
    }, 1000)
  }

  const renderButtons = () => {
    return (
      <Fragment>
        <ActionButton
          buttonState={documentationButtonState}
          content={{
            initial: 'PDF-Dokumentation erneut senden',
            finished: 'Gesendet',
          }}
          onClick={() => resendDocumentation()}
          disabled={documentationButtonState !== 'initial' ? true : false}
        />
        <button
          className="danger"
          onClick={() => {
            setAlert(true)
          }}
        >
          Vorgang löschen
        </button>
      </Fragment>
    )
  }

  const renderAlertContent = () => {
    if (alert) {
      return (
        <div className="client-job-details-container--alert">
          <main>
            <AlertIcon />
            <h5>Soll der Vorgang wirklich gelöscht werden?</h5>

            <button
              className="secondary full-width"
              onClick={handleDeleteFormClick}
            >
              Ja, löschen
            </button>

            <button
              className="danger full-width"
              onClick={() => {
                setAlert(false)
              }}
            >
              Nein, nicht löschen
            </button>

            <p>Gelöschte Daten können nicht wiederhergestellt werden.</p>
          </main>
        </div>
      )
    }
  }

  const renderDialogContent = () => {
    return (
      <Fragment>
        <div className="client-job-details-container--header">
          <p>Formular-ID: {headline}</p>
          <IconButton onClick={handleIconButtonClick}>
            <CrossIcon />
          </IconButton>
        </div>

        <div className="client-job-details-container--details">
          <header>
            <p>Details zum Vorgang</p>
          </header>
          <main>
            <div>
              <label>
                Formular
                <span>{formularName}</span>
              </label>

              <label>
                Mandat
                <span>{client}</span>
              </label>

              <label>
                Ausgestellt am
                <span>{formCreationDate}</span>
              </label>

              <label>
                Wird Gelöscht zum
                <span>{deletionDay}</span>
              </label>
            </div>
          </main>
        </div>

        <div className="client-job-details-container--options">
          {renderButtons()}
        </div>

        {renderAlertContent()}
      </Fragment>
    )
  }

  return (
    <div className={`client-job-details-container ${containerAnimation}`}>
      <div className="client-job-details-container--wrapper">
        <div
          className={`client-job-details-container--content ${contentAnimation}`}
        >
          {renderDialogContent()}
        </div>
      </div>
    </div>
  )
}

export default ClientJobDetailsDialog
