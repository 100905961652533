const useSetProgressAsDocumentTitle = (
  formStep: number,
  totalFormSteps: number,
  formularName: string
): string => {
  const actualStep = formStep === totalFormSteps ? formStep : formStep + 1
  const title = `Schritt ${actualStep} von ${totalFormSteps} - ${formularName}`

  return title
}

export default useSetProgressAsDocumentTitle
