import React, { Fragment } from 'react'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../../ui-components/buttons/HelperButton/HelperButton'
import TextInputField from '../../../Inputs/TextInputField/TextInputField'
import DateInputField from '../../../Inputs/DateInputField/DateInputField'
import TwoColumns from '../../../ui-components/layouts/TwoColumns/TwoColumns'
import RadioInputField from '../../../Inputs/RadioInputField/RadioInputField'

import classes from './ExternalManagingDirectorForm.module.scss'
interface Props {
  values: {
    externalManagingDirector: string
    externalManagingDirectorFirstname: string
    externalManagingDirectorLastname: string
    externalManagingDirectorBirthday: string
    externalManagingDirectorBirthmonth: string
    externalManagingDirectorBirthyear: string
    externalManagingDirectorStreet: string
    externalManagingDirectorHouseNumber: string
    externalManagingDirectorZipCode: string
    externalManagingDirectorCity: string
    is_companion_a_natural_person: string
    naturalPerson: string
  }
  onHelperButtonClick: () => void
  onNextButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBackButtonClick?: (e: React.FormEvent<HTMLFormElement>) => void
}

const ExternalManagingDirectorForm = (props: Props) => {
  const {
    values: {
      externalManagingDirector,
      externalManagingDirectorFirstname,
      externalManagingDirectorLastname,
      externalManagingDirectorBirthday,
      externalManagingDirectorBirthmonth,
      externalManagingDirectorBirthyear,
      externalManagingDirectorStreet,
      externalManagingDirectorHouseNumber,
      externalManagingDirectorZipCode,
      externalManagingDirectorCity,
      is_companion_a_natural_person,
      naturalPerson,
    },
    onHelperButtonClick,
    onNextButtonClick,
    onChange,
    onBackButtonClick,
  } = props

  const renderFormFields = () => {
    if (
      (is_companion_a_natural_person === 'Nein' && naturalPerson === 'Nein') ||
      externalManagingDirector === 'Ja'
    ) {
      return (
        <Fragment>
          <TwoColumns layout="1-1">
            <TextInputField
              label="Vorname"
              name="externalManagingDirectorFirstname"
              defaultValue={externalManagingDirectorFirstname}
              placeholder="John"
              onChange={onChange}
            />
            <TextInputField
              label="Nachname"
              name="externalManagingDirectorLastname"
              defaultValue={externalManagingDirectorLastname}
              placeholder="Doe"
              onChange={onChange}
            />
          </TwoColumns>
          <TwoColumns layout="1-1">
            <DateInputField
              label="Geburtsdatum"
              names={[
                'externalManagingDirectorBirthday',
                'externalManagingDirectorBirthmonth',
                'externalManagingDirectorBirthyear',
              ]}
              onChange={onChange}
              placeholder={['TT', 'MM', 'JJJJ']}
              maxLength={['2', '2', '4']}
              max={['31', '12', new Date().getFullYear()]}
              min={['1', '1', '1900']}
              pattern="\d"
              required
              defaultValue={[
                externalManagingDirectorBirthday,
                externalManagingDirectorBirthmonth,
                externalManagingDirectorBirthyear,
              ]}
            />
          </TwoColumns>
          <hr />
          <TwoColumns layout="1-1">
            <TextInputField
              label="Straße"
              name="externalManagingDirectorStreet"
              defaultValue={externalManagingDirectorStreet}
              placeholder="Wunderstraße"
              onChange={onChange}
            />
            <TextInputField
              label="Hausnummer"
              name="externalManagingDirectorHouseNumber"
              defaultValue={externalManagingDirectorHouseNumber}
              placeholder="30"
              onChange={onChange}
            />
          </TwoColumns>
          <TwoColumns layout="1-1">
            <TextInputField
              label="Postleitzahl"
              name="externalManagingDirectorZipCode"
              defaultValue={externalManagingDirectorZipCode}
              placeholder="42697"
              onChange={onChange}
            />
            <TextInputField
              label="Stadt"
              name="externalManagingDirectorCity"
              defaultValue={externalManagingDirectorCity}
              placeholder="Solingen"
              onChange={onChange}
            />
          </TwoColumns>
        </Fragment>
      )
    }
  }

  const renderExternalManagingDirectorQeustion = () => {
    if (is_companion_a_natural_person === 'Nein' && naturalPerson === 'Nein') {
      return renderFormFields()
    }
    return (
      <>
        <RadioInputField
          label="Gibt es einen Fremd-Geschäftsführer?"
          name="externalManagingDirector"
          radioOptions={['Nein', 'Ja']}
          defaultChecked={externalManagingDirector === 'Nein' ? 0 : 1}
          onChange={onChange}
        />
        {renderFormFields()}
      </>
    )
  }

  return (
    <FormContainer
      className={classes.externalManagingDirectorForm}
      onSubmit={onNextButtonClick}
    >
      <HelperButton onClick={onHelperButtonClick} />
      <FormHeader headlineText="Angaben zur Geschäftsführung" />
      <FormBody>
        {renderExternalManagingDirectorQeustion()}
        {/* {renderFormFields()} */}
      </FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText={onBackButtonClick ? 'Zurück' : null}
        onSecondaryButtonClick={onBackButtonClick}
      />
    </FormContainer>
  )
}

export default ExternalManagingDirectorForm
