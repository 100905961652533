import React from 'react'
import { Route } from 'react-router'
import CivilLawCompany from '../views/FormularViews/fastdocs-client/CivilLawCompany/CivilLawCompany'
import LimitedCompany from '../views/FormularViews/fastdocs-client/LimitedCompany/LimitedCompany'
import LimitedCompanyAndLimitedPartnership from '../views/FormularViews/fastdocs-client/LimitedCompanyAndLimitedPartnership/LimitedCompanyAndLimitedPartnership'
import LimitedEntrepreneurialCompany from '../views/FormularViews/fastdocs-client/LimitedEntrepreneurialCompany/LimitedEntrepreneurialCompany'
import PrivatePerson from '../views/FormularViews/fastdocs-client/PrivatePerson/PrivatePerson'
import SingleCompanies from '../views/FormularViews/fastdocs-client/SingleCompanies/SingleCompanies'

interface Props {
  features: {
    sepa: boolean
  }
}

export default function ClientFormRoutes(props: Props) {
  return (
    <>
      <Route
        exact
        path="/client-onboarding/private-person"
        component={() => <PrivatePerson features={props.features} />}
      />
      <Route
        exact
        path="/client-onboarding/single-companies"
        component={() => <SingleCompanies features={props.features} />}
      />
      <Route
        exact
        path="/client-onboarding/limited-company"
        component={() => <LimitedCompany features={props.features} />}
      />

      <Route
        exact
        path="/client-onboarding/limited-entrepreneurial-company"
        component={() => (
          <LimitedEntrepreneurialCompany features={props.features} />
        )}
      />

      <Route
        exact
        path="/client-onboarding/limited-company-with-limited-partnership"
        component={() => (
          <LimitedCompanyAndLimitedPartnership features={props.features} />
        )}
      />
      <Route
        exact
        path="/client-onboarding/civil-law-company"
        component={() => <CivilLawCompany features={props.features} />}
      />
    </>
  )
}
