import React from 'react'

import classes from './UploadButton.module.scss'

const UploadButton = (props: {
  buttonState: string
  content: { initial: string; finished: string }
  onChange?: any
  disabled: boolean
  fullWidth?: boolean
  type?: 'submit'
  buttonStyle?: 'primary'
}) => {
  const setSize = props.fullWidth ? 'full-width' : ''
  const setStyle = props.buttonStyle ? props.buttonStyle : 'secondary'

  const renderContent = () => {
    if (props.buttonState === 'initial') {
      return props.content.initial
    }
    if (props.buttonState === 'active') {
      return (
        <div className="animated-dots">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      )
    }
    if (props.buttonState === 'finished') {
      return props.content.finished
    }
  }

  return (
    <label className={`${classes.uploadButton} button ${setStyle} ${setSize}`}>
      {renderContent()}
      <input
        type="file"
        id="file-upload"
        name="filename"
        onChange={props.onChange}
        accept=".png, .svg"
        hidden
        disabled={props.disabled}
      />
    </label>
  )
}

export default UploadButton
