import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import DashboardNavigation from '../../../components/Navigations/DashboardNavigation/DashboardNavigation'
import Table, {
  TableBody,
  TableData,
  TableHead,
  TableRow,
} from '../../../components/Table/Table'
import { getTaxConsultant } from '../../utility/utility'
import LoadingIndicator from '../../../components/ui-components/LoadingIndicator/LoadingIndicator'

import './StaffDataView.scss'
import FastdocsStaffLogo from '../../../static/fastdocs-staff-logo.svg'
import JobDetailsDialog from '../../../components/Overlays/JobDetailsDialog/JobDetailsDialog'
import { ReactComponent as EmptyInbox } from '../../../static/icons/80x80/inbox-80.svg'
import { ReactComponent as NoJobs } from '../../../static/icons/80x80/folder-80.svg'
import StatusLabel from '../../../components/ui-components/StatusLabel/StatusLabel'
import { STAFF_FORMS } from '../../../constants/Formulars'

interface Props {
  staffContract: boolean
}

const StaffDataView = (props: Props) => {
  document.title = 'Personaldaten :: Dashboard'

  const [data, setData] = useState([{}])
  const [dialogData, setDialogData] = useState([])
  const [loading, setLoading] = useState(true)
  const [dialog, setDialog] = useState(false)
  const [containerClass, setContainerClass] = useState('')
  const [contentClass, setContentClass] = useState('')
  const [activeSearch, setActiveSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    const dbRef = 'jobs'

    firebase.auth().onAuthStateChanged(() => {
      firebase
        .database()
        .ref(dbRef)
        .on('value', (snapshot) => {
          let data: object[] = []

          snapshot.forEach((childSnapshot: any) => {
            const { taxConsultant } = childSnapshot.val().clientData

            if (taxConsultant === getTaxConsultant()) {
              data.push({ [childSnapshot.key]: childSnapshot.val() })
            }
          })

          setData(data)
          setLoading(false)
        })
    })

    return () => {
      firebase.database().ref(dbRef).off()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (search) {
      setActiveSearch(true)

      let result: any = []

      data.map((initialData) => {
        return Object.entries(initialData).map((entry: any) => {
          const searchValue = search
          const formId = entry[0]
          const clerk = entry[1].clientData.selectedClerk
            ? entry[1].clientData.selectedClerk
            : ''
          const client = entry[1].clientData.company
          const date = entry[1].clientData.formCreationDate
          const name = `${entry[1].clientData.firstname} ${entry[1].clientData.lastname}`

          if (searchValue.toLowerCase() === 'in arbeit') {
            if (!entry[1].finishedJob) {
              result.push({ [formId]: entry[1] })
            }
          }

          if (searchValue.toLowerCase() === 'abgeschlossen') {
            if (entry[1].finishedJob) {
              result.push({ [formId]: entry[1] })
            }
          }

          if (
            formId.toLowerCase().includes(searchValue.toLowerCase()) ||
            client.toLowerCase().includes(searchValue.toLowerCase()) ||
            name.toLowerCase().includes(searchValue.toLowerCase()) ||
            date.toLowerCase().includes(searchValue.toLowerCase()) ||
            clerk.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            result.push({ [formId]: entry[1] })
          }

          return null
        })
      })

      setFilteredData(result)
    } else {
      setActiveSearch(false)
      setFilteredData([])
    }

    // eslint-disable-next-line
  }, [search])

  const getRows = () => {
    return data.map((entry) => {
      return Object.entries(entry).map((entry: any, key) => {
        const {
          company,
          formCreationDate,
          firstname,
          lastname,
          entryday,
          entrymonth,
          entryyear,
          formularName,
          selectedClerk,
        } = entry[1].clientData
        const { consultantData, employeeData } = entry[1]
        const { staffNumber } = consultantData || ''
        const receivedEmployeeData = employeeData ? 'finished' : 'open'
        const receivedConsultantData = consultantData ? 'finished' : 'open'
        const formId = entry[0]
        const entrydate = `${entryday}.${entrymonth}.${entryyear}`

        return (
          <TableRow onClick={(e) => openDialog(e)} key={key} datakey={formId}>
            <TableData>{formId}</TableData>
            <TableData>{company}</TableData>
            <TableData>
              {firstname} {lastname}
            </TableData>
            <TableData>{staffNumber || ''}</TableData>
            <TableData>{entrydate}</TableData>
            <TableData>{formCreationDate}</TableData>
            <TableData>{selectedClerk ? selectedClerk : ''}</TableData>
            <TableData>
              <StatusLabel label="AG" status="finished" />
              {formularName !== STAFF_FORMS.IMMEDIATE_REPORT && (
                <StatusLabel label="AN" status={receivedEmployeeData} />
              )}
              <StatusLabel label="SB" status={receivedConsultantData} />
            </TableData>
          </TableRow>
        )
      })
    })
  }

  const getFilteredRows = () => {
    return filteredData.map((entry) => {
      return Object.entries(entry).map((entry: any, key) => {
        const {
          company,
          formCreationDate,
          firstname,
          lastname,
          entryday,
          entrymonth,
          entryyear,
          formularName,
          selectedClerk,
        } = entry[1].clientData
        const { consultantData, employeeData } = entry[1]
        const { staffNumber } = consultantData || ''
        const formId = entry[0]
        const entrydate = `${entryday}.${entrymonth}.${entryyear}`
        const receivedEmployeeData = employeeData ? 'finished' : 'open'
        const receivedConsultantData = consultantData ? 'finished' : 'open'

        return (
          <TableRow onClick={(e) => openDialog(e)} key={key} datakey={formId}>
            <TableData>{formId}</TableData>
            <TableData>{company}</TableData>
            <TableData>
              {firstname} {lastname}
            </TableData>
            <TableData>{staffNumber || ''}</TableData>
            <TableData>{entrydate}</TableData>
            <TableData>{formCreationDate}</TableData>
            <TableData>{selectedClerk ? selectedClerk : ''}</TableData>
            <TableData>
              <StatusLabel label="AG" status="finished" />
              {formularName !== STAFF_FORMS.IMMEDIATE_REPORT && (
                <StatusLabel label="AN" status={receivedEmployeeData} />
              )}
              <StatusLabel label="SB" status={receivedConsultantData} />
            </TableData>
          </TableRow>
        )
      })
    })
  }

  const renderTableData = () => {
    if (data.length === 0 && !loading) {
      return (
        <tbody>
          <tr className="empty">
            <td colSpan={20}>
              <NoJobs />
              <span>Aktuell sind keine Vorgänge vorhanden.</span>
            </td>
          </tr>
        </tbody>
      )
    }
    if (data.length !== 0 && !loading) {
      if (activeSearch) {
        if (filteredData.length !== 0) {
          return <TableBody>{getFilteredRows()}</TableBody>
        }
        return (
          <tbody>
            <tr className="empty">
              <td colSpan={20}>
                <EmptyInbox />
                <span>Die Suche hat leider kein Ergebniss gebracht.</span>
              </td>
            </tr>
          </tbody>
        )
      }
      return <TableBody>{getRows()}</TableBody>
    }
    return (
      <tbody>
        <tr>
          <td colSpan={20}>
            <LoadingIndicator size="small" />
          </td>
        </tr>
      </tbody>
    )
  }

  const openDialog = (e: any) => {
    const target = e.target.parentElement.dataset.key

    return data.map((entry) => {
      return Object.entries(entry).map((entry: any) => {
        if (target === entry[0]) {
          setDialogData(entry)
          setDialog(true)
          setTimeout(() => {
            setContainerClass('fade')
            setContentClass('move')
          }, 100)
        }
        return null
      })
    })
  }

  const closeDialog = () => {
    setContentClass('')
    setContainerClass('')

    setTimeout(() => {
      setDialog(false)
      setDialogData([])
    }, 300)
  }

  const renderDialog = () => {
    if (dialog && dialogData) {
      const data = dialogData
      const headline = dialogData[0]
      const formId = dialogData[0]
      const {
        clientData,
        employeeData,
        consultantData,
        finishedJob,
        finishedJobOn,
      } = data[1]
      const {
        formularName,
        formCreationDate,
        company,
        clientEmail,
        firstname,
        lastname,
        employeeEmail,
        entryday,
        entrymonth,
        entryyear,
        selectedClerk,
      } = clientData
      const { staffNumber } = consultantData || ''
      const employeeDataIsSet = employeeData ? true : false
      const consultantDataIsSet = consultantData ? true : false
      const entrydate = `${entryday}.${entrymonth}.${entryyear}`

      const deleteForm = () => {
        firebase
          .database()
          .ref(`jobs/${formId}`)
          .remove()
          .then(() => setActiveSearch(false))
          .then(() => setSearch(''))
          .then(() => setFilteredData([]))
          .then(() => closeDialog())
      }

      const deletionDay = () => {
        if (finishedJob) {
          return moment(formCreationDate, 'D.M.YYYY')
            .add(14, 'days')
            .format('D.M.YYYY')
        }
        return moment(formCreationDate, 'D.M.YYYY')
          .add(1, 'month')
          .add(1, 'day')
          .format('D.M.YYYY')
      }

      const finishedDate = () => {
        if (finishedJob) {
          return moment(finishedJobOn[0]).format('DD.MM.YYYY')
        }
        return ''
      }

      const getForm = () => {
        if (formularName === STAFF_FORMS.APPRENTICESHIP) {
          return 'apprentice-form'
        }
        if (formularName === STAFF_FORMS.FOREIGN_EMPLOYEE) {
          return 'foreign-employee'
        }
        if (formularName === STAFF_FORMS.IMMEDIATE_REPORT) {
          return 'immediate-report'
        }
        if (formularName === STAFF_FORMS.MINIJOB) {
          return 'minijobber-form'
        }
        if (formularName === STAFF_FORMS.STANDARD_EMPLOYEE) {
          return 'staff-form'
        }
        if (formularName === STAFF_FORMS.STUDENT_MINIJOB) {
          return 'student-minijob'
        }
        if (formularName === STAFF_FORMS.STUDENT) {
          return 'student'
        }
        return ''
      }

      return (
        <JobDetailsDialog
          headline={headline}
          formularName={formularName}
          formCreationDate={formCreationDate}
          entrydate={entrydate}
          company={company}
          clientEmail={clientEmail}
          firstname={firstname}
          lastname={lastname}
          employeeEmail={employeeEmail}
          staffNumber={staffNumber}
          deletionDay={deletionDay()}
          employeeDataIsSet={employeeDataIsSet}
          consultantDataIsSet={consultantDataIsSet}
          handleIconButtonClick={() => closeDialog()}
          finishedDate={finishedDate()}
          form={getForm()}
          formId={formId}
          finishedJob={finishedJob}
          containerAnimation={containerClass}
          contentAnimation={contentClass}
          handleDeleteFormClick={() => deleteForm()}
          selectedClerk={selectedClerk}
        />
      )
    }
  }

  const renderContent = () => {
    if (props.staffContract) {
      return (
        <Fragment>
          <Table
            onChange={(e) => setSearch(e.target.value)}
            defaultSearchValue={search}
            onResetClick={() => setSearch('')}
          >
            <TableHead
              headers={[
                'Formular-ID',
                'Mandant:in',
                'Mitarbeiter:in',
                'Personalnummer',
                'Eintrittsdatum',
                'Angelegt am',
                'Sachbearbeiter:in',
                'Status',
              ]}
            />
            {renderTableData()}
          </Table>
          {renderDialog()}
        </Fragment>
      )
    }
    return (
      <div className="card staff-advertisement">
        <img src={FastdocsStaffLogo} alt="Fastdocs Staff Logo" width="200" />
        <h5>
          Fastdocs Staff erleichtert Steuerberatungskanzleien und deren
          Mandanten die mühevolle und fehleranfällige Erfassung von
          Personaldaten bei Neueinstellungen.
        </h5>
        <a
          className="button primary"
          href="https://www.fastdocs.de/staff"
          rel="noopener noreferrer"
          target="_blank"
        >
          Mehr erfahren
        </a>
      </div>
    )
  }

  return (
    <div className="staff-data-view">
      <DashboardNavigation />
      {renderContent()}
    </div>
  )
}

export default StaffDataView
