import React from 'react'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import SelectField from '../../Selects/SelectField/SelectField'
import TextInputField from '../../Inputs/TextInputField/TextInputField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import { tinValidation } from '../../utility/tinValidation'
import './BeginningStatusForm.scss'
import RadioInputField from '../../Inputs/RadioInputField/RadioInputField'
import { STAFF_FORMS } from '../../../constants/Formulars'

interface Props {
  taxIdNumber: string
  mariatialStatus: string
  formularName: string
  beginningStatus?: string
  activityStatus: string
  taxClass: string
  denomination: string
  taxIdExists?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onTaxClassChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSecondaryButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const BeginningStatusForm = (props: Props) => {
  const validateTin = () => {
    if (props.taxIdNumber.length <= 0) {
      return 'Persönliche Steuer-ID'
    }
    if (tinValidation(props.taxIdNumber) !== true) {
      return <span className="error">Das ist noch keine gültige Steuer-ID</span>
    }
    return 'Persönliche Steuer-ID'
  }

  const disableButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.taxIdNumber.length <= 0) {
      return null
    }
    if (tinValidation(props.taxIdNumber) !== true) {
      e.preventDefault()
      e.target.blur()
    }
    return null
  }

  const getTaxClassOptions = () => {
    if (
      props.mariatialStatus === 'Verheiratet' ||
      props.mariatialStatus === 'Lebenspartnerschaft eingetragen'
    ) {
      return [
        'III - Steuerklasse Drei',
        'IV - Steuerklasse Vier',
        'V - Steuerklasse Fünf',
        'VI - Steuerklasse Sechs',
      ]
    }
    if (
      props.mariatialStatus === 'Ledig' ||
      props.mariatialStatus === 'Geschieden' ||
      props.mariatialStatus === 'Lebenspartnerschaft aufgehoben'
    ) {
      return [
        'I - Steuerklasse Eins',
        'II - Steuerklasse Zwei',
        'VI - Steuerklasse Sechs',
      ]
    }
    return [
      'I - Steuerklasse Eins',
      'II - Steuerklasse Zwei',
      'III - Steuerklasse Drei',
      'IV - Steuerklasse Vier',
      'V - Steuerklasse Fünf',
      'VI - Steuerklasse Sechs',
    ]
  }

  const renderFormularInputs = () => {
    if (
      props.formularName === STAFF_FORMS.STUDENT_MINIJOB ||
      props.formularName === STAFF_FORMS.MINIJOB ||
      props.formularName === 'Minijobber bis 450€' // TODO check and remove legacy
    ) {
      return (
        <React.Fragment>
          <SelectField
            defaultValue={props.beginningStatus}
            label={'Status außerhalb des Minijobs'}
            name={'beginningStatus'}
            onChange={props.onChange}
            options={[
              'Arbeitnehmer:in',
              'Arbeitnehmer:in in Elternzeit',
              'Arbeitslose/r',
              'Beamtin/Beamter',
              'Hausfrau/Hausmann',
              'Schüler/in',
              'Schulentlassene/r',
              'Selbstständige/r',
              'Student/in',
              'ALG-/Sozialhilfeempfänger/in',
              'Studienbewerber/in',
              'Wehr/Zivildienstleistende/r',
              'Rentner/in',
            ]}
            required
            noDefault
          />
          <TextInputField
            defaultValue={props.taxIdNumber}
            label={validateTin()}
            name={'taxIdNumber'}
            onChange={props.onChange}
            placeholder={'56345234987'}
            minLength={11}
            required
          />
        </React.Fragment>
      )
    }
    if (props.formularName === STAFF_FORMS.FOREIGN_EMPLOYEE) {
      return (
        <React.Fragment>
          <RadioInputField
            label="Tätigkeitsstatus"
            name="activityStatus"
            radioOptions={['Hauptbeschäftigung', 'Nebenbeschäftigung']}
            defaultChecked={
              props.activityStatus === 'Hauptbeschäftigung' ? 0 : 1
            }
            onChange={props.onChange}
          />
          <SelectField
            defaultValue={props.taxClass}
            label={'Steuerklasse'}
            name={'taxClass'}
            onChange={props.onTaxClassChange}
            options={[
              'I - Steuerklasse Eins',
              'II - Steuerklasse Zwei',
              'III - Steuerklasse Drei',
              'IV - Steuerklasse Vier',
              'V - Steuerklasse Fünf',
              'VI - Steuerklasse Sechs',
            ]}
            required
            noDefault
          />
          <SelectField
            defaultValue={props.denomination}
            label={'Konfession'}
            name={'denomination'}
            onChange={props.onChange}
            options={[
              'Konfessionslos / Keine Kirchensteuerberechnung',
              'ev - Evangelische Kirchensteuer',
              'ib - Israelitische Religionsgemeinschaft Baden',
              'ih - Jüdische Kultussteuer',
              'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
              'is - Israelitische / Jüdische Kultussteuer',
              'iw - Israelitische Religionsgemeinschaft Württembergs',
              'jd - Jüdische Kultussteuer',
              'jh - Jüdische Kultussteuer',
              'lt - Evangelisch lutherisch (bis 12/2015)',
              'rf - Evangelisch reformiert (bis 12/2015)',
              'rk - Römisch-Katholische Kirchensteuer',
              'ak - Altkatholische Kirchensteuer',
              'fa - Freie Religionsgemeinschaft Alzey',
              'fb - Freireligiöse Landesgemeinde Baden',
              'fg - Freireligiöse Landesgemeinde Pfalz',
              'fm - Freireligiöse Gemeinde Mainz',
              'fr - Französisch reformiert (bis 12/2015)',
              'fs - Freireligiöse Gemeinde Offenbach/Main',
            ]}
            required
            noDefault
          />
          <div>
            <RadioInputField
              name="taxIdExists"
              label="Besitzt Du eine persönliche Steuer-ID?"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={props.taxIdExists === 'Nein' ? 0 : 1}
              required
              onChange={props.onChange}
            />
            {props.taxIdExists === 'Ja' ? (
              <TextInputField
                defaultValue={props.taxIdNumber}
                label={validateTin()}
                name={'taxIdNumber'}
                onChange={props.onChange}
                placeholder={'56345234987'}
                minLength={11}
                required
              />
            ) : null}
          </div>
        </React.Fragment>
      )
    }
    if (
      props.formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      props.formularName === STAFF_FORMS.APPRENTICESHIP ||
      props.formularName === STAFF_FORMS.STUDENT
    ) {
      return (
        <React.Fragment>
          <RadioInputField
            label="Tätigkeitsstatus"
            name="activityStatus"
            radioOptions={['Hauptbeschäftigung', 'Nebenbeschäftigung']}
            defaultChecked={
              props.activityStatus === 'Hauptbeschäftigung' ? 0 : 1
            }
            onChange={props.onChange}
          />
          <TextInputField
            defaultValue={props.taxIdNumber}
            label={validateTin()}
            name={'taxIdNumber'}
            onChange={props.onChange}
            placeholder={'56345234987'}
            minLength={11}
            required
          />
          <SelectField
            defaultValue={props.taxClass}
            label={'Steuerklasse'}
            name={'taxClass'}
            onChange={props.onTaxClassChange}
            options={getTaxClassOptions()}
            required
            noDefault
          />
          <SelectField
            defaultValue={props.denomination}
            label={'Konfession'}
            name={'denomination'}
            onChange={props.onChange}
            options={[
              'Konfessionslos / Keine Kirchensteuerberechnung',
              'ev - Evangelische Kirchensteuer',
              'ib - Israelitische Religionsgemeinschaft Baden',
              'ih - Jüdische Kultussteuer',
              'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
              'is - Israelitische / Jüdische Kultussteuer',
              'iw - Israelitische Religionsgemeinschaft Württembergs',
              'jd - Jüdische Kultussteuer',
              'jh - Jüdische Kultussteuer',
              'lt - Evangelisch lutherisch (bis 12/2015)',
              'rf - Evangelisch reformiert (bis 12/2015)',
              'rk - Römisch-Katholische Kirchensteuer',
              'ak - Altkatholische Kirchensteuer',
              'fa - Freie Religionsgemeinschaft Alzey',
              'fb - Freireligiöse Landesgemeinde Baden',
              'fg - Freireligiöse Landesgemeinde Pfalz',
              'fm - Freireligiöse Gemeinde Mainz',
              'fr - Französisch reformiert (bis 12/2015)',
              'fs - Freireligiöse Gemeinde Offenbach/Main',
            ]}
            required
            noDefault
          />
        </React.Fragment>
      )
    }
  }

  return (
    <FormContainer
      className={'beginning-status-form'}
      onSubmit={props.onSubmit}
    >
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Informationen zur Beschäftigung'} />
      <FormBody>{renderFormularInputs()}</FormBody>
      <FormFooter
        primaryButtonText="Weiter"
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
        onClick={disableButton}
      />
    </FormContainer>
  )
}

export default BeginningStatusForm
