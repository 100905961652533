import React, { Fragment, useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import consultantFormValues from 'helpers/autofill/immediateReportForm/consultantFormValues'

import { getFormId, getTaxConsultant } from 'views/utility/utility.jsx'
import AutofillButton from 'helpers/autofill/AutofillButton'
import ConsultantDataForm from 'components/Forms/ConsultantDataForm/ConsultantDataForm'
import Overlay from 'components/Overlays/Overlay/Overlay'
import SplashStage from 'components/Stages/SplashStage/SplashStage'
import SuccsessForm from 'components/Forms/SuccessForm/SuccessForm'
import useSetProgressAsDocumentTitle from 'hooks/useSetProgressAsDocumentTitle'

const database = firebase.database()

const ConsultantViewForImmediateReportForm = () => {
  const formId = getFormId()
  const [isLoading, setIsLoading] = useState(true)
  const [formIdExist, setFormIdExist] = useState(true)
  const [formularName, setFormularName] = useState('')
  const [formWasSubmittedBefore, setFormWasSubmittedBefore] = useState(false)

  const [accountSetup, setAccountSetup] = useState({
    contractOption: '',
    taxConsultantImportSystem: '',
  })

  const [formState, setFormState] = useState({
    formStep: 0,
    totalFormSteps: 1,
  })

  const [employmentInformation, setEmploymentInformation] = useState({
    clientStaffNumber: '',
    company: '',
    firstname: '',
    lastname: '',
    costCenter: '',
    employmentLocation: '',
    employeeType: '',
  })

  const [formValues, setFormValues] = useState({
    clientNumber: '',
    staffNumber: '',
    autosuggestSearchValue: '',
    importOption: '',
    importFile: '',
    activityKey: '',
    jobTitle: '',
    consultantNumber: '',
  })

  document.title = useSetProgressAsDocumentTitle(
    formState.formStep,
    formState.totalFormSteps,
    formularName
  )

  const getData = async () => {
    const jobRef = database.ref(`jobs/${formId}`)
    const consultantRef = database.ref(`taxConsultant/${getTaxConsultant()}`)

    try {
      const formIdExist = (await (
        await jobRef.child('clientData/company').get()
      ).val())
        ? true
        : false
      setFormIdExist(formIdExist)

      const formularName = await (
        await jobRef.child('clientData/formularName').get()
      ).val()
      setFormularName(formularName)

      const formWasSubmittedBefore = await (
        await jobRef.child('consultantData/formWasSubmittedBefore').get()
      ).val()

      if (formWasSubmittedBefore) {
        setFormWasSubmittedBefore(formWasSubmittedBefore)
        return
      }

      const account = await (await consultantRef.get()).val()
      setAccountSetup({
        ...accountSetup,
        contractOption: account.contracts.staff.contractOption,
        taxConsultantImportSystem: account.importSystem,
      })

      const consultantNumber = await (
        await consultantRef.child('consultantNumber').get()
      ).val()
      const staffNumber = await (
        await jobRef.child('clientData/staffNumber').get()
      ).val()

      const costCenter = await (
        await jobRef.child('clientData/costCenter').get()
      ).val()
      const company = await (
        await jobRef.child('clientData/company').get()
      ).val()
      const firstname = await (
        await jobRef.child('clientData/firstname').get()
      ).val()
      const lastname = await (
        await jobRef.child('clientData/lastname').get()
      ).val()
      const employmentLocation = await (
        await jobRef.child('clientData/employmentLocation').get()
      ).val()

      const employeeType = await (
        await jobRef.child('clientData/employeeType').get()
      ).val()

      setFormValues({ ...formValues, consultantNumber, staffNumber })
      setEmploymentInformation({
        ...employmentInformation,
        clientStaffNumber: staffNumber,
        costCenter,
        company,
        firstname,
        lastname,
        employmentLocation,
        employeeType,
      })

      return setIsLoading(false)
    } catch (err) {
      return alert(err)
    }
  }

  useEffect(() => {
    getData().catch((err) => alert(err))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    })
  }

  const renderNextFormStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFormState({
      ...formState,
      formStep: formState.formStep + 1,
    })
  }

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    e.preventDefault()

    database.ref(`taxConsultant/${getTaxConsultant()}`).update({
      consultantNumber: formValues.consultantNumber,
    })

    database
      .ref('jobs/' + formId + '/consultantData')
      .set({
        ...formValues,
        formWasSubmittedBefore: true,
      })
      .then(() => {
        renderNextFormStep(e)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const updateAutosuggestSearchValue = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormValues({
      ...formValues,
      autosuggestSearchValue: e.target.value,
    })
  }

  const onClearButtonClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFormValues({
      ...formValues,
      autosuggestSearchValue: '',
      activityKey: '',
      jobTitle: '',
    })
  }

  const onOptionClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFormValues({
      ...formValues,
      autosuggestSearchValue: e.target.value + ' - ' + e.target.dataset.title,
      activityKey: e.target.value,
      jobTitle: e.target.dataset.title || '',
    })
  }

  const renderFormSteps = () => {
    const accountHasProFeatures =
      accountSetup.contractOption === 'pro' ? true : false

    switch (formState.formStep) {
      case 0:
        return (
          <ConsultantDataForm
            accountHasProFeatures={accountHasProFeatures}
            onSubmit={handleSubmit}
            onChange={handleInputChange}
            clientNumber={formValues.clientNumber}
            consultantNumber={formValues.consultantNumber}
            staffNumber={formValues.staffNumber}
            formularName={formularName}
            company={employmentInformation.company}
            firstname={employmentInformation.firstname}
            clientStaffNumber={employmentInformation.clientStaffNumber}
            lastname={employmentInformation.lastname}
            autosuggestSearchValue={formValues.autosuggestSearchValue}
            activityKey={formValues.activityKey}
            costCenter={employmentInformation.costCenter}
            jobTitle={formValues.jobTitle}
            updateAutosuggestSearchValue={updateAutosuggestSearchValue}
            onClearButtonClick={onClearButtonClick}
            onOptionClick={onOptionClick}
            formId={formId}
            importOption={formValues.importOption}
            importFile={formValues.importFile}
            employeeType={employmentInformation.employeeType}
            taxConsultantImportSystem={accountSetup.taxConsultantImportSystem}
            employmentLocation={employmentInformation.employmentLocation}
            isLoading={isLoading}
          />
        )
      default:
        return (
          <SuccsessForm
            successText="Mandantendaten erfolgreich hinzugefügt"
            formId={formId}
          >
            <p>
              Du bekommst die Import-Datei nun automatisch per E-Mail von uns
              geschickt.
            </p>
            <p>
              Auf Wiedersehen{' '}
              <span role="img" aria-label="Winkende-Hand">
                👋
              </span>
              .
            </p>
          </SuccsessForm>
        )
    }
  }

  return (
    <Fragment>
      <SplashStage headlineText="Formular für Sofortmeldung" />

      {renderFormSteps()}

      {formWasSubmittedBefore && (
        <Overlay
          headerText="Achtung"
          headlineText="Formular bereits ausgefüllt"
          text="Du hast das Formular bereits ausgefüllt und abgeschickt. Du kannst deine Angaben nachträglich nicht noch mal ändern."
          additionalText="Sollte dir ein Fehler unterlaufen sein, wende Dich bitte an support@fastdocs.de"
        />
      )}

      {formId === null && (
        <Overlay
          headerText="Fehler"
          headlineText="Falsche oder keine Formular-ID gefunden"
          text="Du hast eine falsche, oder keine Formular-ID. Ohne Diese ID können wir Deone Eingaben nicht richtig zuordnen."
          additionalText="Vergewisser Dich bitte, ob Du den erhaltenen Link vollständig und richtig in den Browser übernnommen hast."
          type="error"
        />
      )}

      {!formIdExist && (
        <Overlay
          type="error"
          headerText="Achtung"
          headlineText="Ungültige oder falsche Formular-ID"
          text="Für dieses Formular gibt es keine passende Formular-ID, die wir in unseren Systemen finden können."
        />
      )}

      <AutofillButton
        onClick={() =>
          setFormValues({
            ...formValues,
            ...consultantFormValues,
          })
        }
      />
    </Fragment>
  )
}

export default ConsultantViewForImmediateReportForm
