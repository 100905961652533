const clientFormValues = {
  capitalAccumulation: 'Ja',
  company: '[TEST] Porsche AG',
  contractForm: 'Befristet Teilzeit',
  contractLimitDay: '01',
  contractLimitMonth: (new Date().getMonth() + 2).toString(),
  contractLimitYear: (new Date().getFullYear() + 1).toString(),
  employedAs: 'CAD-Designer',
  employmentLocation: 'Stuttgart',
  entryday: '01',
  entrymonth: (new Date().getMonth() + 2).toString(),
  entryyear: new Date().getFullYear().toString(),
  firstname: 'Oliver',
  friday: '4',
  hollidays: '15',
  lastname: 'Steiner',
  monday: '4',
  monthlyProportion: '40',
  sallaryValue: '2000',
  saturday: '0',
  sunday: '0',
  statusIndicator: 'Nein',
  thursday: '4',
  tuesday: '4',
  typeOfEmployee: 'Angestellter',
  wednesday: '4',
}

export default clientFormValues
