import React from 'react'
import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import TextInputField from '../../Inputs/TextInputField/TextInputField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import TwoColumns from '../../ui-components/layouts/TwoColumns/TwoColumns'
import SelectField from '../../Selects/SelectField/SelectField'
import DateInputField from '../../Inputs/DateInputField/DateInputField'
import countries from '../InsuranceInformationForm/utility/countries.json'
import './EmployeeForm.scss'
import NumberInputField from '../../Inputs/NumberInputField/NumberInputField'
import RadioInputField from '../../Inputs/RadioInputField/RadioInputField'
import { STAFF_FORMS } from '../../../constants/Formulars'

interface Props {
  formularName: string
  statusIndicator: string
  costCenter: string
  staffNumber: string
  firstname: string
  lastname: string
  sex?: string
  birthdate?: string[]
  nationality?: string
  employeeType?: string
  employmentLocation: string
  entryDate: string[]
  employedAs: string
  typeOfEmployee: string
  handycapped: string
  contractForm: string
  contractLimitationDate: string[]
  costCenterQuerstion: boolean
  staffNumberQuerstion: boolean
  features?: {
    costCenter: boolean
    staffNumber: boolean
  }
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleCostCenterQuestionChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  handleStaffNumberQuestionChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSecondaryButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
}

class EmployeeForm extends React.PureComponent<Props> {
  renderStatusIndicatorFormField = () => {
    if (
      this.props.formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      this.props.formularName === STAFF_FORMS.FOREIGN_EMPLOYEE ||
      this.props.formularName === STAFF_FORMS.STUDENT
    ) {
      return (
        <SelectField
          label={'Familienangehörigkeit zum Arbeitgeber'}
          name={'statusIndicator'}
          defaultValue={this.props.statusIndicator}
          onChange={this.props.onChange}
          required
          options={[
            'Nein',
            'Ehegatte',
            'Lebenspartner',
            'Kind/Enkel/Urenkel',
            'Geschäftsführender Gesellschafter (GmbH)',
          ]}
          noDefault
        />
      )
    }
  }

  renderCostCenterFormField = () => {
    if (this.props.features && this.props.features.costCenter) {
      return (
        <React.Fragment>
          <RadioInputField
            label="Einer Kostenstelle zuordnen?"
            radioOptions={['Nein', 'Ja']}
            name="costCenterQuestion"
            defaultChecked={this.props.costCenterQuerstion ? 1 : 0}
            onChange={this.props.handleCostCenterQuestionChange}
          />
          {this.props.costCenterQuerstion ? (
            <TextInputField
              label={'Kostenstelle'}
              name={'costCenter'}
              placeholder={'200'}
              defaultValue={this.props.costCenter}
              onChange={this.props.onChange}
              required
            />
          ) : null}
        </React.Fragment>
      )
    }
  }

  renderStaffNumberFormField = () => {
    if (this.props.features && this.props.features.staffNumber) {
      return (
        <React.Fragment>
          <RadioInputField
            label="Eine Personalnummer vergeben?"
            radioOptions={['Nein', 'Ja']}
            defaultChecked={this.props.staffNumberQuerstion ? 1 : 0}
            name="staffNumberQuerstion"
            onChange={this.props.handleStaffNumberQuestionChange}
          />
          {this.props.staffNumberQuerstion ? (
            <NumberInputField
              label={'Personalnummer'}
              name={'staffNumber'}
              placeholder={'100'}
              defaultValue={this.props.staffNumber}
              onChange={this.props.onChange}
              required
            />
          ) : null}
        </React.Fragment>
      )
    }
  }

  validateEmployedAs = () => {
    if (this.props.employedAs === 'Aushilfe') {
      return true
    }
    return false
  }

  renderEmployedAsLabel = () => {
    if (this.validateEmployedAs()) {
      return (
        <span className="validation-info">
          Beschäftigt als <span className="warn">Ungültige Eingabe</span>
        </span>
      )
    }
    return 'Beschäftigt als'
  }

  validateData = (e: any) => {
    if (this.validateEmployedAs()) {
      e.preventDefault()
    }
  }

  renderFormFields = () => {
    if (this.props.formularName === STAFF_FORMS.IMMEDIATE_REPORT) {
      return (
        <React.Fragment>
          <TwoColumns layout={'1-1'}>
            <TextInputField
              label={'Vorname'}
              name={'firstname'}
              type={'text'}
              placeholder={'Marie'}
              required={true}
              defaultValue={this.props.firstname}
              onChange={this.props.onChange}
              autoFocus
            />
            <TextInputField
              label={'Nachname'}
              name={'lastname'}
              type={'text'}
              placeholder={'Muster'}
              required={true}
              defaultValue={this.props.lastname}
              onChange={this.props.onChange}
            />
          </TwoColumns>
          <TwoColumns layout={'1-1'}>
            <SelectField
              label={'Geschlecht'}
              name={'sex'}
              defaultValue={this.props.sex}
              onChange={this.props.onChange}
              required
              options={['Weiblich', 'Männlich', 'Divers', 'Unbestimmt']}
              noDefault
            />
            <DateInputField
              label={'Geburtsdatum'}
              names={['birthday', 'birthmonth', 'birthyear']}
              onChange={this.props.onChange}
              placeholder={['TT', 'MM', 'JJJJ']}
              maxLength={['2', '2', '4']}
              max={['31', '12', new Date().getFullYear() - 13]}
              min={['1', '1', '1900']}
              pattern="\d"
              required
              defaultValue={this.props.birthdate}
            />
          </TwoColumns>
          <TwoColumns layout={'1-1'}>
            <SelectField
              label="Staatsangehörigkeit"
              name="nationality"
              options={countries.countries}
              defaultValue={this.props.nationality}
              onChange={this.props.onChange}
              required
            />
            <SelectField
              defaultValue={this.props.employeeType}
              label={'Mitarbeitertyp'}
              name={'employeeType'}
              onChange={this.props.onChange}
              options={[
                'Minijobber bis 450€',
                'Personengruppenübergreifend',
                'Werkstudent/in',
              ]}
              required
              noDefault
            />
          </TwoColumns>
          <TwoColumns layout={'1-1'}>
            <TextInputField
              name="employmentLocation"
              label="Beschäftigungsort"
              placeholder="Musterstadt"
              required
              onChange={this.props.onChange}
              defaultValue={this.props.employmentLocation}
              pattern="[^\/\\\+!.,;:_—–#?=()&%$§!\^°*]+"
              title="Bitte verwenden Sie keine Sonderzeichen"
            />
            <DateInputField
              label={'Eintrittsdatum'}
              names={['entryday', 'entrymonth', 'entryyear']}
              onChange={this.props.onChange}
              placeholder={['TT', 'MM', 'JJJJ']}
              maxLength={['2', '2', '4']}
              max={['31', '12', new Date().getFullYear() + 5]}
              min={['1', '1', '1960']}
              pattern="\d"
              required
              defaultValue={this.props.entryDate}
            />
          </TwoColumns>
          {this.props.features?.costCenter ||
          this.props.features?.staffNumber ? (
            <React.Fragment>
              <hr />
              {this.renderCostCenterFormField()}
              {this.renderStaffNumberFormField()}
            </React.Fragment>
          ) : null}
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <TwoColumns layout={'1-1'}>
          <TextInputField
            label={'Vorname'}
            name={'firstname'}
            type={'text'}
            placeholder={'Marie'}
            required={true}
            defaultValue={this.props.firstname}
            onChange={this.props.onChange}
            autoFocus
          />
          <TextInputField
            label={'Nachname'}
            name={'lastname'}
            type={'text'}
            placeholder={'Muster'}
            required={true}
            defaultValue={this.props.lastname}
            onChange={this.props.onChange}
          />
        </TwoColumns>
        <TwoColumns layout={'1-1'}>
          <TextInputField
            label={this.renderEmployedAsLabel()}
            name={'employedAs'}
            type={'text'}
            placeholder={'Steuerfachangestellter'}
            required={true}
            defaultValue={this.props.employedAs}
            onChange={this.props.onChange}
          />
          <DateInputField
            label={'Eintrittsdatum'}
            names={['entryday', 'entrymonth', 'entryyear']}
            onChange={this.props.onChange}
            placeholder={['TT', 'MM', 'JJJJ']}
            maxLength={['2', '2', '4']}
            max={['31', '12', new Date().getFullYear() + 5]}
            min={['1', '1', '1960']}
            pattern="\d"
            required
            defaultValue={this.props.entryDate}
          />
        </TwoColumns>
        <TwoColumns layout={'1-1'}>
          <SelectField
            defaultValue={this.props.typeOfEmployee}
            label={'Arbeitnehmertyp'}
            name={'typeOfEmployee'}
            onChange={this.props.onChange}
            options={['Angestellter', 'Arbeiter']}
            required
            noDefault
          />
          <SelectField
            options={['Unbekannt', 'Ja']}
            label={'Schwerbehinderung'}
            defaultValue={this.props.handycapped}
            onChange={this.props.onChange}
            name={'handycapped'}
          />
        </TwoColumns>
        <TwoColumns layout={'1-1'}>
          <TextInputField
            name="employmentLocation"
            label="Beschäftigungsort"
            placeholder="Musterstadt"
            required
            onChange={this.props.onChange}
            defaultValue={this.props.employmentLocation}
            pattern="[^\/\\\+!.,;:_—–#?=()&%$§!\^°*]+"
            title="Bitte verwenden Sie keine Sonderzeichen"
          />
          <SelectField
            defaultValue={this.props.contractForm}
            label={'Vertragsform'}
            name={'contractForm'}
            onChange={this.props.onChange}
            options={this.setContractOptions()}
            required
            noDefault
          />
        </TwoColumns>
        <TwoColumns layout="1-1">
          {this.renderLimitedContractDateInput()}
          {this.renderStatusIndicatorFormField()}
        </TwoColumns>
        {this.props.features?.costCenter || this.props.features?.staffNumber ? (
          <React.Fragment>
            <hr />
            {this.renderCostCenterFormField()}
            {this.renderStaffNumberFormField()}
          </React.Fragment>
        ) : null}
      </React.Fragment>
    )
  }

  setContractOptions = () => {
    if (
      this.props.formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      this.props.formularName === STAFF_FORMS.FOREIGN_EMPLOYEE ||
      this.props.formularName === STAFF_FORMS.STUDENT
    ) {
      return [
        'Unbefristet Teilzeit',
        'Unbefristet Vollzeit',
        'Befristet Teilzeit',
        'Befristet Vollzeit',
      ]
    }
    if (this.props.formularName === STAFF_FORMS.APPRENTICESHIP) {
      return ['Unbefristet Vollzeit', 'Befristet Vollzeit']
    }
    return ['Unbefristet Teilzeit', 'Befristet Teilzeit']
  }

  renderLimitedContractDateInput = () => {
    if (
      this.props.formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      this.props.formularName === STAFF_FORMS.FOREIGN_EMPLOYEE ||
      this.props.formularName === STAFF_FORMS.APPRENTICESHIP ||
      this.props.formularName === STAFF_FORMS.STUDENT
    ) {
      if (
        this.props.contractForm === 'Befristet Teilzeit' ||
        this.props.contractForm === 'Befristet Vollzeit'
      ) {
        return (
          <DateInputField
            label={'Vertrag befristet bis'}
            names={[
              'contractLimitDay',
              'contractLimitMonth',
              'contractLimitYear',
            ]}
            defaultValue={this.props.contractLimitationDate}
            onChange={this.props.onChange}
            placeholder={['TT', 'MM', 'JJJJ']}
            maxLength={['2', '2', '4']}
            max={['31', '12', new Date().getFullYear() + 5]}
            min={['1', '1', new Date().getFullYear()]}
            pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
            required
          />
        )
      }
    }
  }

  getHeadlineText = () => {
    if (this.props.formularName !== STAFF_FORMS.APPRENTICESHIP) {
      return 'Informationen MitarbeiterIn'
    }
    return 'Azubi-Informationen'
  }

  render() {
    return (
      <FormContainer className={'employee-form'} onSubmit={this.props.onSubmit}>
        <HelperButton onClick={this.props.onHelperButtonClick} />
        <FormHeader headlineText={this.getHeadlineText()} />
        <FormBody>{this.renderFormFields()}</FormBody>
        <FormFooter
          primaryButtonText={'Weiter'}
          secondaryButtonText="Zurück"
          onSecondaryButtonClick={this.props.onSecondaryButtonClick}
          onClick={this.validateData}
        />
      </FormContainer>
    )
  }
}

export default EmployeeForm
