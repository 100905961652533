import React from 'react'

import Accordion from '../../../../ui-components/accordions/Accordion/Accordion'
import helpOverContentForClients from './helpOverContentForClients.json'
import helpOverContentForEmployees from './helpOverContentForEmployees.json'
import HelpOverBase from '../../HelpOverBase/HelpOverBase'

const EmptyHint = () => {
  return (
    <div className="empty-hint">
      <h5>
        <span role="img" aria-label="Illustration">
          🤘
        </span>
        <br />
        Hier brauchst Du keine Hilfe
      </h5>
    </div>
  )
}

const renderHelpOverContent = (props: any) => {
  const client = helpOverContentForClients[0]
  const employee = helpOverContentForEmployees[0]

  if (window.location.pathname.includes('client')) {
    switch (props) {
      case 0:
        return client.unternehmensdaten.map((accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        ))
      case 1:
        return client.mitarbeiterInformationen.map((accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        ))
      case 2:
        return client.gehalt.map((accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            text={accordion.text}
            key={i}
          />
        ))
      case 3:
        return client.arbeitszeiten.map((accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        ))
      case 4:
        return client.gesamtarbeitszeit.map((accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            text={accordion.text}
            key={i}
          />
        ))
      case 5:
        return client.vwl.map((accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            text={accordion.text}
            key={i}
          />
        ))
      default:
        return <EmptyHint />
    }
  }
  switch (props) {
    case 0:
      return employee.persoehnlicheAngaben.map((accordion, i) => (
        <Accordion
          formStep={props}
          headline={accordion.headline}
          text={accordion.text}
          example={accordion.example}
          key={i}
        />
      ))
    case 1:
      return employee.ausbildung.map((accordion, i) => (
        <Accordion
          formStep={props}
          headline={accordion.headline}
          text={accordion.text}
          key={i}
        />
      ))
    case 2:
      return employee.infosZurBeschaeftigung.map((accordion, i) => (
        <Accordion
          formStep={props}
          headline={accordion.headline}
          url={accordion.url}
          urlText={accordion.urlText}
          text={accordion.text}
          example={accordion.example}
          key={i}
        />
      ))
    case 3:
      return employee.krankenversicherung.map((accordion, i) => (
        <Accordion
          formStep={props}
          headline={accordion.headline}
          text={accordion.text}
          example={accordion.example}
          key={i}
        />
      ))
    case 4:
      return employee.angabenZurKrankenversicherung.map((accordion, i) => (
        <Accordion
          formStep={props}
          headline={accordion.headline}
          url={accordion.url}
          urlText={accordion.urlText}
          text={accordion.text}
          example={accordion.example}
          key={i}
        />
      ))
    case 5:
      return employee.elterneigenschaft.map((accordion, i) => (
        <Accordion
          formStep={props}
          headline={accordion.headline}
          text={accordion.text}
          key={i}
        />
      ))
    case 6:
      return employee.bankverbindungen.map((accordion, i) => (
        <Accordion
          formStep={props}
          headline={accordion.headline}
          text={accordion.text}
          example={accordion.example}
          key={i}
        />
      ))
    case 7:
      return employee.weitereBeschaeftigungen.map((accordion, i) => (
        <Accordion
          formStep={props}
          headline={accordion.headline}
          text={accordion.text}
          key={i}
        />
      ))
    default:
      return <EmptyHint />
  }
}

interface Props {
  className: string
  formId: string | null
  formStep: number
  onCloseButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const HelpOverForStudentForm = (props: Props) => {
  return (
    <HelpOverBase
      className={props.className}
      onCloseButtonClick={props.onCloseButtonClick}
      formId={props.formId}
    >
      {renderHelpOverContent(props.formStep)}
    </HelpOverBase>
  )
}

export default HelpOverForStudentForm
