const data = {
  salutation: 'Firma',
  companyName: 'Fastdocs.de KG',
  contactPerson: 'Alex Steinhoff',
  annualFinancialReport: '2019',
  businessPurpose: 'SAAS',
  foundingday: '01',
  foundingmonth: '01',
  foundingyear: '2018',
  businessStreet: 'Zeppelinstraße',
  businessHouseNumber: '1',
  businessZipCode: '42781',
  businessCity: 'Haan',
  businessPhoneNumber: '02129123456789',
  businessEmail: 'daniel@fastdocs.de',
  businessBankAccountHolder: 'Fastdocs.de KG',
  businessBankName: 'Stadtsparkasse Haan',
  businessIban: 'DE48303512200091327361',
  businessTaxOffice: 'Hilden',
  vatNumberAvailable: 'Ja',
  vatRegion: 'DE',
  vatNumber: '22231234283',
  capitalContribution: '10000',
  businessTaxNumberAvailable: 'Ja',
  businessTaxNumber: '135/5919/1398',
  tradeRegisterEntry: 'Ja',
  registryCourt: 'Wuppertal',
  tradeRegisterNumber: 'HRA8837',
  academicTitle: 'Keine',
  firstname: 'Evelyn',
  lastname: 'Oettinger',
  birthday: '29',
  birthmonth: '04',
  birthyear: '1980',
  denomination: 'Konfessionslos / Keine Kirchensteuerberechnung',
  socialSecurityNumber: '',
  taxIdNumber: '86149736056',
  street: 'Zeppelinstraße',
  houseNumber: '1',
  zipCode: '42781',
  city: 'Haan',
  phoneNumber: '02129123456789',
  email: 'daniel@fastdocs.de',
  typeOfIdentification: 'Personalausweis',
  placeOfBirth: 'Haan',
  pep: 'Nein',
  nationality: 'Deutsch',
  identityCardNumber: 'L7ZYH2710',
  issuingAuthority: 'Haan',
  issuedDay: '12',
  issuedMonth: '3',
  issuedYear: '2015',
  validDay: '12',
  validMonth: '3',
  validYear: '2025',
  privateTaxOffice: 'Hilden',
  privateTaxNumber: '135/5919/1398',
  married: 'Nein',
  companionAcademicTitle: 'Keine',
  taxOfficeProxy: 'Ja',
  limitedCompanySalutation: 'Firma',
  limitedCompanyName: 'Ecovis KSO',
  limitedCompanyFoundingday: '15',
  limitedCompanyFoundingmonth: '08',
  limitedCompanyFoundingyear: '2005',
  limitedCompanyBusinessStreet: 'Grafenberger Allee',
  limitedCompanyBusinessHouseNumber: '297',
  limitedCompanyBusinessZipCode: '40237',
  limitedCompanyBusinessCity: 'Düsseldorf',
  limitedCompanyBusinessBankAccountHolder: 'Ecovis KSO',
  limitedCompanyBusinessBankName: 'Sparkasse Düsseldorf',
  limitedCompanyBusinessIban: 'DE48303512200091327361',
  limitedCompanyBusinessTaxOffice: 'Düsseldorf-Nord',
  limitedCompanyVatNumberAvailable: 'Ja',
  limitedCompanyVatNumber: '292833065',
  limitedCompanyVatRegion: 'DE',
  limitedCompanyBusinessTaxNumberAvailable: 'Ja',
  limitedCompanyBusinessTaxNumber: '135/5919/1398',
  limitedCompanyTradeRegisterEntry: 'Ja',
  limitedCompanyRegistryCourt: 'Düsseldorf',
  limitedCompanyTradeRegisterNumber: 'HRB3827',
  limitedCompanyAcademicTitle: 'Keine',
  limitedCompanyFirstname: 'Evelyn',
  limitedCompanyLastname: 'Oettinger',
  limitedCompanyBirthday: '29',
  limitedCompanyBirthmonth: '04',
  limitedCompanyBirthyear: '1980',
  limitedCompanyDenomination: 'Konfessionslos / Keine Kirchensteuerberechnung',
  limitedCompanyTaxIdNumber: '86149736056',
  limitedCompanyStreet: 'Zeppelinstraße',
  limitedCompanyHouseNumber: '1',
  limitedCompanyZipCode: '42781',
  limitedCompanyCity: 'Haan',
  limitedCompanyPhoneNumber: '02129123456789',
  limitedCompanyEmail: 'daniel@fastdocs.de',
  amountOfSubscribedCapital: '25000',
  sameShareholder: 'Ja',
  shareCapital: '100',
  order: true,
}
export default data
