import React from 'react'
import PropTypes from 'prop-types'
import './Overlay.scss'

const Overlay = (props) => (
  <div className={`overlay ${props.type}`}>
    <article className="card-dark">
      <header>{props.headerText}</header>
      <main>
        <h5>{props.headlineText}</h5>
        <p>{props.text}</p>
        {props.additionalText ? <p>{props.additionalText}</p> : null}
      </main>
      {props.primaryButtonText ? (
        <footer>
          <button onClick={props.onClick}>{props.primaryButtonText}</button>
        </footer>
      ) : null}
    </article>
  </div>
)

Overlay.propTypes = {
  headerText: PropTypes.string.isRequired,
  headlineText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  additionalText: PropTypes.string,
  primaryButtonText: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['error']),
}

export default Overlay
