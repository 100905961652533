import React from 'react'
import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import TextInputField from '../../Inputs/TextInputField/TextInputField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import { getTaxConsultant } from '../../../views/utility/utility'
import SelectField from '../../Selects/SelectField/SelectField'

import './CompanyForm.scss'
import { STAFF_FORMS } from '../../../constants/Formulars'

interface Props {
  company: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  formularName?: string
  taxConsultantImportSystem?: string
  selectedClerk?: string
  listOfClerks?: string[]
  onClerkChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  showClerkSeletion?: boolean
}

const CompanyForm = (props: Props) => {
  const renderFormFields = () => (
    <>
      <TextInputField
        autoFocus
        defaultValue={props.company}
        label={'Firmenname'}
        name={'company'}
        onChange={props.onChange}
        placeholder={'Wunderbox GmbH'}
        required
      />
      {props.showClerkSeletion ? (
        <SelectField
          name="selectedClerk"
          onChange={props.onClerkChange}
          required
          noDefault
          defaultValue={props.selectedClerk}
          options={props.listOfClerks}
          label="SachbearbeiterIn in der Kanzlei"
        />
      ) : null}
    </>
  )

  const renderDevFormFields = () => {
    if (
      process.env.NODE_ENV === 'development' ||
      getTaxConsultant() === 'dev-test-account' ||
      getTaxConsultant() === 'test-account'
    ) {
      if (props.formularName !== STAFF_FORMS.IMMEDIATE_REPORT) {
        return null
      }
      return (
        <>
          <hr />
          <SelectField
            defaultValue={props.taxConsultantImportSystem}
            label={'Import-System'}
            name={'taxConsultantImportSystem'}
            onChange={props.onChange}
            options={['lodas', 'lug']}
            required
            noDefault
          />
        </>
      )
    }
  }

  return (
    <FormContainer className={'company-form'} onSubmit={props.onSubmit}>
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Unternehmen'} />
      <FormBody>
        {renderFormFields()}
        {renderDevFormFields()}
      </FormBody>
      <FormFooter primaryButtonText={'Weiter'} />
    </FormContainer>
  )
}

export default CompanyForm
