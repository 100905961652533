import React, { PureComponent } from 'react'
import { ReactComponent as CrossIcon } from '../../../static/CrossIcon.svg'
import Label from '../../ui-components/labels/Label/Label'

import './ActivityKeyAutosuggestTextInputField.scss'

class ActivityKeyAutosuggestTextInputField extends PureComponent {
  state = {
    showList: false,
  }

  renderListOfEmploymentTitles = () => {
    if (Number(this.props.autosuggestSearchValue)) {
      const filteredObjects = []

      const filteredData = this.props.listData.filter((activityKey) => {
        return activityKey.key.indexOf(this.props.autosuggestSearchValue) > -1
      })

      const jobTitles = filteredData.map((activityKeys) => {
        return Object.entries(activityKeys.titles).map((title) => {
          return [activityKeys.key, title[1]]
        })
      })

      jobTitles.map((entries) => {
        return entries.map((entry) => filteredObjects.push(entry))
      })

      if (this.state.showList === true) {
        if (this.props.autosuggestSearchValue.length >= 3) {
          return (
            <React.Fragment>
              {filteredObjects.length !== 0 ? (
                <ul>
                  {filteredObjects.map((item, i) => {
                    return (
                      <li key={i}>
                        <button
                          value={item[0]}
                          data-title={item[1]}
                          onClick={this.props.onOptionClick}
                        >
                          <b>{item[0]}</b> - {item[1]}
                        </button>
                      </li>
                    )
                  })}
                </ul>
              ) : (
                <ul>
                  <li className="result-hint">
                    <div>
                      Dieser Tätigkeitsschlüssel scheint nicht zu existieren.
                    </div>
                  </li>
                </ul>
              )}
            </React.Fragment>
          )
        }
        return (
          <React.Fragment>
            {this.props.autosuggestSearchValue.length <= 3 ? (
              <ul>
                <li className="result-hint">
                  <div>Bitte mindestens 3 Zahlen eingeben.</div>
                </li>
              </ul>
            ) : null}
          </React.Fragment>
        )
      }
    }
    if (!Number(this.props.autosuggestSearchValue)) {
      if (this.state.showList === true) {
        if (this.props.autosuggestSearchValue.length >= 3) {
          const filteredData = []

          this.props.listData.map((titles) => {
            return titles.titles
              .filter((title) => {
                return (
                  title
                    .toLowerCase()
                    .indexOf(this.props.autosuggestSearchValue.toLowerCase()) >=
                  0
                )
              })
              .map((title) => filteredData.push([titles.key, title]))
          })

          if (filteredData.length !== 0) {
            return (
              <ul>
                {filteredData.map((item, i) => {
                  return (
                    <li key={i}>
                      <button
                        value={item[0]}
                        data-title={item[1]}
                        onClick={this.props.onOptionClick}
                      >
                        <b>{item[0]}</b> – {item[1]}
                      </button>
                    </li>
                  )
                })}
              </ul>
            )
          } else {
            return (
              <ul>
                <li className="result-hint">
                  <div>Diese Tätigkeit scheint nicht zu existieren.</div>
                </li>
              </ul>
            )
          }
        }
        return (
          <ul>
            <li className="result-hint">
              {this.props.autosuggestSearchValue.length <= 3 ? (
                <div>Bitte mindestens 3 Buchstaben eingeben.</div>
              ) : null}
            </li>
          </ul>
        )
      }
    }
  }

  componentDidUpdate = () => {
    if (this.props.autosuggestSearchValue.length !== 0) {
      this.setState({
        ...this.state,
        showList: true,
      })
    } else {
      this.setState({
        ...this.state,
        showList: false,
      })
    }
    if (this.refs.autosuggestTextInput.value === '') {
      this.refs.autosuggestTextInput.focus()
    }
    if (this.props.activityKey !== '') {
      this.setState({
        ...this.state,
        showList: false,
      })
    }
  }

  renderClearButton = () => {
    if (this.props.autosuggestSearchValue.length !== 0) {
      return (
        <button
          className="clear-button"
          onClick={this.props.onClearButtonClick}
        >
          <CrossIcon />
        </button>
      )
    }
  }

  render() {
    return (
      <div className="autosuggest-text-input-field">
        <Label>{this.props.label}</Label>
        <input
          type="text"
          name={this.props.name}
          ref="autosuggestTextInput"
          onChange={this.props.updateAutosuggestSearchValue}
          value={this.props.autosuggestSearchValue}
          placeholder={this.props.placeholder}
          required={this.props.required}
          autoComplete="off"
        />
        {this.renderClearButton()}
        {this.renderListOfEmploymentTitles()}
      </div>
    )
  }
}

export default ActivityKeyAutosuggestTextInputField
