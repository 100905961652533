import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/auth'

import { AuthenticatedDashboardRoutes } from '../../../routes/DashboardRoutes'

interface Props {
  logo: string
  contracts: {
    staff: {
      contract: boolean
      contractOption: string
    }
    client: {
      contract: boolean
    }
  }
}

const AuthenticatedDashboardView = (props: Props) => {
  document.title = 'Dashboard :: Fastdocs'

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    if (firebase.auth().currentUser?.uid) {
      return true
    }
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn') || ''
      const persistenceSetup =
        window.localStorage.getItem('authPersistence') || ''
      const persistence =
        persistenceSetup === 'true'
          ? firebase.auth.Auth.Persistence.LOCAL
          : firebase.auth.Auth.Persistence.SESSION

      return firebase
        .auth()
        .setPersistence(persistence)
        .then(() => {
          return firebase
            .auth()
            .signInWithEmailLink(email, window.location.href)
            .then(() => {
              return true
            })
            .then(() => {
              window.history.replaceState(
                {},
                document.title,
                '/dashboard/staff'
              )
            })
            .catch((error) => {
              if (error.code === 'auth/invalid-action-code') {
                alert('Der Login-Link ist ungültig.')
                setIsAuthenticated(false)
              }
              setIsAuthenticated(false)
            })
        })
        .catch((error) => {
          if (error.code === 'auth/invalid-action-code') {
            alert('Der Login-Link ist ungültig.')
            setIsAuthenticated(false)
          }
          setIsAuthenticated(false)
        })
    }
    return false
  })

  useEffect(() => {
    firebase.auth().onAuthStateChanged(() => {
      if (firebase.auth().currentUser?.isAnonymous) {
        return firebase.auth().signOut()
      }
    })

    // eslint-disable-next-line
  }, [])

  const checkAuthentication = () => {
    if (isAuthenticated) {
      return (
        <AuthenticatedDashboardRoutes
          contracts={props.contracts}
          logo={props.logo}
        />
      )
    }
    if (!isAuthenticated) {
      return <Redirect exact to="/login" />
    }
  }

  return <section style={{ height: '100%' }}>{checkAuthentication()}</section>
}

export default AuthenticatedDashboardView
