import React from 'react'
import ActionButton from '../../ui-components/buttons/ActionButton/ActionButton'

import classes from './SingleInputFormField.module.scss'

interface Props {
  name: string
  label: string
  type: 'text' | 'email' | 'number'
  defaultValue: string | number
  buttonState: string
  pattern?: string
  caption?: string
  required?: boolean
  placeholder?: string
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onButtonClick: () => void
}

const SingleInputFormField = (props: Props) => {
  return (
    <div className={classes.singleInputFormField}>
      <label>{props.label}</label>
      <form
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <input
          name={props.name}
          type={props.type}
          defaultValue={props.defaultValue}
          required={props.required}
          onChange={props.onInputChange}
          pattern={props.pattern}
          placeholder={props.placeholder}
        />
        <ActionButton
          buttonState={props.buttonState}
          content={{
            initial: 'Speichern',
            finished: 'Gespeichert',
          }}
          onClick={props.onButtonClick}
          disabled={props.buttonState !== 'initial' ? true : false}
          type="submit"
        />
      </form>
      <small>{props.caption}</small>
    </div>
  )
}

export default SingleInputFormField
