import React from 'react'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../../ui-components/buttons/HelperButton/HelperButton'
import SelectField from '../../../Selects/SelectField/SelectField'
import TextInputField from '../../../Inputs/TextInputField/TextInputField'
import DateInputField from '../../../Inputs/DateInputField/DateInputField'
import TwoColumns from '../../../ui-components/layouts/TwoColumns/TwoColumns'
import RadioInputField from '../../../Inputs/RadioInputField/RadioInputField'

import nations from './countries.json'

import classes from './IdentifcationForm.module.scss'

interface Props {
  type:
    | 'private'
    | 'limited-company-and-partnership-kg'
    | 'limited-company'
    | 'limited-entrepreneurial-company'
    | 'civil-law-company-first-partner'
    | 'civil-law-company-second-partner'
  values: {
    typeOfIdentification: string
    placeOfBirth: string
    pep: string
    nationality: string
    identityCardNumber: string
    issuingAuthority: string
    issuedDay: string
    issuedMonth: string
    issuedYear: string
    validDay: string
    validMonth: string
    validYear: string
    naturalPerson: string

    secondPartnerTypeOfIdentification: string
    secondPartnerPlaceOfBirth: string
    secondPartnerNationality: string
    secondPartnerIdentityCardNumber: string
    secondPartnerIssuingAuthority: string
    secondPartnerIssuedDay: string
    secondPartnerIssuedMonth: string
    secondPartnerIssuedYear: string
    secondPartnerValidDay: string
    secondPartnerValidMonth: string
    secondPartnerValidYear: string
    secondPartnerPep: string
  }
  onHelperButtonClick: () => void
  onNextButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onBackButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const IdentifcationForm = (props: Props) => {
  const { onHelperButtonClick, onNextButtonClick, onChange } = props
  const {
    values: {
      typeOfIdentification,
      placeOfBirth,
      pep,
      nationality,
      identityCardNumber,
      issuingAuthority,
      issuedDay,
      issuedMonth,
      issuedYear,
      validDay,
      validMonth,
      validYear,
      naturalPerson,

      secondPartnerTypeOfIdentification,
      secondPartnerPlaceOfBirth,
      secondPartnerNationality,
      secondPartnerIdentityCardNumber,
      secondPartnerIssuingAuthority,
      secondPartnerIssuedDay,
      secondPartnerIssuedMonth,
      secondPartnerIssuedYear,
      secondPartnerValidDay,
      secondPartnerValidMonth,
      secondPartnerValidYear,
      secondPartnerPep,
    },
    type,
    onBackButtonClick,
  } = props

  const setHeadlineText = () => {
    if (type === 'limited-company-and-partnership-kg') {
      return 'Identifikation (KG-GesellschafterIn)'
    }
    if (
      type === 'limited-company' ||
      type === 'limited-entrepreneurial-company'
    ) {
      return 'Identifikation des Gesellschafters'
    }
    if (type === 'civil-law-company-first-partner') {
      return 'Identifikation (GesellschafterIn 1)'
    }
    if (type === 'civil-law-company-second-partner') {
      return 'Identifikation (GesellschafterIn 2)'
    }
    return 'Identifikation'
  }

  const renderFormContent = () => {
    if (type === 'civil-law-company-second-partner') {
      return (
        <>
          {naturalPerson && naturalPerson === 'Ja' ? (
            <FormBody>
              <RadioInputField
                label="Identifizierungsart"
                name="secondPartnerTypeOfIdentification"
                radioOptions={['Personalausweis', 'Reisepass']}
                onChange={onChange}
                defaultChecked={
                  secondPartnerTypeOfIdentification === 'Personalausweis'
                    ? 0
                    : 1
                }
              />
              <TwoColumns layout="1-1">
                <TextInputField
                  label="Geburtsort"
                  name="secondPartnerPlaceOfBirth"
                  onChange={onChange}
                  defaultValue={secondPartnerPlaceOfBirth}
                  placeholder="Musterstadt"
                  pattern=".*[\w].+"
                />
                <SelectField
                  label="Staatsangehörigkeit"
                  name="secondPartnerNationality"
                  options={nations}
                  noDefault
                  onChange={onChange}
                  required
                  defaultValue={secondPartnerNationality}
                />
              </TwoColumns>
              <TwoColumns layout="1-1">
                <TextInputField
                  label="Ausweisnummer"
                  name="secondPartnerIdentityCardNumber"
                  onChange={onChange}
                  defaultValue={secondPartnerIdentityCardNumber}
                  placeholder="M6YXG21094"
                  minLength={9}
                  maxLength={10}
                />
                <TextInputField
                  label="Ausstellende Behörde"
                  name="secondPartnerIssuingAuthority"
                  onChange={onChange}
                  defaultValue={secondPartnerIssuingAuthority}
                  placeholder="Musterstadt"
                />
              </TwoColumns>
              <TwoColumns layout="1-1">
                <DateInputField
                  label="Ausgestellt am"
                  names={[
                    'secondPartnerIssuedDay',
                    'secondPartnerIssuedMonth',
                    'secondPartnerIssuedYear',
                  ]}
                  onChange={onChange}
                  placeholder={['TT', 'MM', 'JJJJ']}
                  maxLength={['2', '2', '4']}
                  max={['31', '12', new Date().getFullYear()]}
                  min={['1', '1', '']}
                  pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                  required
                  defaultValue={[
                    secondPartnerIssuedDay,
                    secondPartnerIssuedMonth,
                    secondPartnerIssuedYear,
                  ]}
                />
                <DateInputField
                  label="Gültig bis"
                  names={[
                    'secondPartnerValidDay',
                    'secondPartnerValidMonth',
                    'secondPartnerValidYear',
                  ]}
                  onChange={onChange}
                  placeholder={['TT', 'MM', 'JJJJ']}
                  maxLength={['2', '2', '4']}
                  max={['31', '12', '']}
                  min={['1', '1', '']}
                  pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                  required
                  defaultValue={[
                    secondPartnerValidDay,
                    secondPartnerValidMonth,
                    secondPartnerValidYear,
                  ]}
                />
              </TwoColumns>
              <TwoColumns layout="1-1">
                <RadioInputField
                  label="PeP-Status/Politisch exponierte Person"
                  name="secondPartnerPep"
                  radioOptions={['Nein', 'Ja']}
                  onChange={onChange}
                  defaultChecked={secondPartnerPep === 'Nein' ? 0 : 1}
                />
              </TwoColumns>
            </FormBody>
          ) : null}
        </>
      )
    }
    return (
      <>
        {naturalPerson && naturalPerson === 'Ja' ? (
          <FormBody>
            <RadioInputField
              label="Identifizierungsart"
              name="typeOfIdentification"
              radioOptions={['Personalausweis', 'Reisepass']}
              onChange={onChange}
              defaultChecked={
                typeOfIdentification === 'Personalausweis' ? 0 : 1
              }
            />
            <TwoColumns layout="1-1">
              <TextInputField
                label="Geburtsort"
                name="placeOfBirth"
                onChange={onChange}
                defaultValue={placeOfBirth}
                placeholder="Musterstadt"
                pattern=".*[\w].+"
              />
              <SelectField
                label="Staatsangehörigkeit"
                name="nationality"
                options={nations}
                noDefault
                onChange={onChange}
                required
                defaultValue={nationality}
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <TextInputField
                label="Ausweisnummer"
                name="identityCardNumber"
                onChange={onChange}
                defaultValue={identityCardNumber}
                placeholder="M6YXG21094"
                minLength={9}
                maxLength={10}
              />
              <TextInputField
                label="Ausstellende Behörde"
                name="issuingAuthority"
                onChange={onChange}
                defaultValue={issuingAuthority}
                placeholder="Musterstadt"
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <DateInputField
                label="Ausgestellt am"
                names={['issuedDay', 'issuedMonth', 'issuedYear']}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                required
                defaultValue={[issuedDay, issuedMonth, issuedYear]}
              />
              <DateInputField
                label="Gültig bis"
                names={['validDay', 'validMonth', 'validYear']}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', '']}
                min={['1', '1', '']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                required
                defaultValue={[validDay, validMonth, validYear]}
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <RadioInputField
                label="PeP-Status/Politisch exponierte Person"
                name="pep"
                radioOptions={['Nein', 'Ja']}
                onChange={onChange}
                defaultChecked={pep === 'Nein' ? 0 : 1}
              />
            </TwoColumns>
          </FormBody>
        ) : null}
      </>
    )
  }

  return (
    <FormContainer
      className={classes.personalInformationForm}
      onSubmit={onNextButtonClick}
    >
      <HelperButton onClick={onHelperButtonClick} />
      <FormHeader
        headlineText={setHeadlineText()}
        sublineText={
          naturalPerson && naturalPerson === 'Ja'
            ? 'Nach §10 Abs. 1 Nr. 2 GwG (Geldwäschegesetz) sind wir zur Abfrage dieser Angaben verpflichtet.'
            : 'Da es sich nicht um eine natürliche Person handelt, kann dieser Schritt übersprungen werden.'
        }
      />
      {renderFormContent()}
      <FormFooter
        primaryButtonText="Weiter"
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={onBackButtonClick}
      />
    </FormContainer>
  )
}

export default IdentifcationForm
