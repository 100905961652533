const data = {
  formularName: 'GmbH',
  salutation: 'Firma',
  companyName: 'Foo GmbH',
  businessForm: '',
  businessPurpose: '',
  foundingday: '',
  foundingmonth: '',
  foundingyear: '',
  businessStreet: '',
  businessHouseNumber: '',
  businessZipCode: '',
  businessCity: '',
  businessPhoneNumber: '',
  businessEmail: '',
  businessBankAccountHolder: '',
  businessBankName: '',
  businessIban: '',
  businessTaxOffice: '',
  vatNumberAvailable: 'Nein',
  vatRegion: 'DE',
  vatNumber: '',
  capitalContribution: '',
  businessTaxNumberAvailable: 'Nein',
  businessTaxNumber: '',
  tradeRegisterEntry: 'Nein',
  registryCourt: '',
  tradeRegisterNumber: '',
  academicTitle: 'Keine',
  firstname: '',
  lastname: '',
  birthday: '',
  birthmonth: '',
  birthyear: '',
  denomination: '',
  socialSecurityNumber: '',
  taxIdNumber: '',
  street: '',
  houseNumber: '',
  zipCode: '',
  city: '',
  phoneNumber: '',
  email: 'daniel@fastdocs.de',
  typeOfIdentification: 'Personalausweis',
  placeOfBirth: '',
  pep: 'Nein',
  nationality: '',
  identityCardNumber: '',
  issuingAuthority: '',
  issuedDay: '',
  issuedMonth: '',
  issuedYear: '',
  validDay: '',
  validMonth: '',
  validYear: '',
  privateIban: '',
  privateBankAccountHolder: '',
  privateBankName: '',
  privateTaxOffice: '',
  privateTaxNumber: '',
  married: 'Nein',
  companionAcademicTitle: 'Keine',
  companionFirstname: 'Peter',
  companionLastname: 'Schmitz',
  companionBirthday: '17',
  companionBirthmonth: '11',
  companionBirthyear: '1960',
  companionDenomination: 'ev - Evangelische Kirchensteuer',
  companionSocialSecurityNumber: '',
  companionTaxIdNumber: '86149736056',
  companionStreet: 'Schnuckstraße',
  companionHouseNumber: '12',
  companionZipCode: '33928',
  companionCity: 'Schnuckhausen',
  companionPhoneNumber: '000201384929',
  companionEmail: 'daniel@fastdocs.de',
  companionTypeOfIdentification: 'Personalausweis',
  companionPlaceOfBirth: 'Schnuckhausen',
  companionPep: 'Nein',
  companionNationality: 'Deutsch',
  companionIdentityCardNumber: 'L7ZYH2710',
  companionIssuingAuthority: 'Stadt Schnuckhausen',
  companionIssuedDay: '1',
  companionIssuedMonth: '1',
  companionIssuedYear: '2000',
  companionValidDay: '1',
  companionValidMonth: '1',
  companionValidYear: '2025',
  children: 'Nein',
  numberOfChildren: 1,
  child1Firstname: '',
  child1Lastname: '',
  child1Birthday: '',
  child1Birthmonth: '',
  child1Birthyear: '',
  child1TaxIdNumber: '',
  child1IsAdult: 'Nein',
  child1Status: '',
  child1Benefit: 'Ja',
  child2Firstname: '',
  child2Lastname: '',
  child2Birthday: '',
  child2Birthmonth: '',
  child2Birthyear: '',
  child2TaxIdNumber: '',
  child2IsAdult: 'Nein',
  child2Status: '',
  child2Benefit: 'Ja',
  child3Firstname: '',
  child3Lastname: '',
  child3Birthday: '',
  child3Birthmonth: '',
  child3Birthyear: '',
  child3TaxIdNumber: '',
  child3IsAdult: 'Nein',
  child3Status: '',
  child3Benefit: 'Ja',
  taxOfficeProxy: 'Ja',
  limitedCompanySalutation: 'Firma',
  limitedCompanyName: 'Muster GmbH',
  limitedCompanyFoundingday: '10',
  limitedCompanyFoundingmonth: '10',
  limitedCompanyFoundingyear: '1995',
  limitedCompanyBusinessStreet: 'Zeppelinstraße',
  limitedCompanyBusinessHouseNumber: '10',
  limitedCompanyBusinessZipCode: '42781',
  limitedCompanyBusinessCity: 'Haan',
  limitedCompanyBusinessBankAccountHolder: 'Muster GmbH',
  limitedCompanyBusinessBankName: 'Stadtsparkasse Haan',
  limitedCompanyBusinessIban: 'DE44100110012625484636',
  limitedCompanyBusinessTaxOffice: 'Hilden',
  limitedCompanyVatNumberAvailable: 'Ja',
  limitedCompanyVatNumber: '3392819',
  limitedCompanyVatRegion: 'DE',
  limitedCompanyBusinessTaxNumberAvailable: 'Ja',
  limitedCompanyBusinessTaxNumber: '939392203',
  limitedCompanyTradeRegisterEntry: 'Ja',
  limitedCompanyRegistryCourt: 'Wuppertal',
  limitedCompanyTradeRegisterNumber: 'HBR1337',
  limitedCompanyAcademicTitle: '',
  limitedCompanyFirstname: '',
  limitedCompanyLastname: '',
  limitedCompanyBirthday: '',
  limitedCompanyBirthmonth: '',
  limitedCompanyBirthyear: '',
  limitedCompanyDenomination: '',
  limitedCompanyTaxIdNumber: '',
  limitedCompanyStreet: 'Meterostraße',
  limitedCompanyHouseNumber: '30',
  limitedCompanyZipCode: '42697',
  limitedCompanyCity: 'Solingen',
  limitedCompanyPhoneNumber: '',
  limitedCompanyEmail: '',
  amountOfSubscribedCapital: '25000',
  sameShareholder: 'Nein',
  additionalShareHolder: 'Ja',
  companionShareCapital: '50',
  shareCapital: '50',
  contactPerson: 'Alex Steinhoff',
  annualFinancialReport: '2019',
  externalManagingDirector: 'Ja',
  externalManagingDirectorFirstname: 'John ',
  externalManagingDirectorLastname: 'Doe',
  externalManagingDirectorBirthday: '27',
  externalManagingDirectorBirthmonth: '10',
  externalManagingDirectorBirthyear: '1989',
  externalManagingDirectorStreet: 'Bahnhofstraße',
  externalManagingDirectorHouseNumber: '48',
  externalManagingDirectorZipCode: '42781',
  externalManagingDirectorCity: 'Haan',
  naturalPerson: 'Nein',
  marriedDay: '',
  marriedMonth: '',
  marriedYear: '',
  order: true,
}

export default data
