const firebaseConfig = () => {
  if (
    process.env.NODE_ENV === 'development' ||
    window.location.host === 'fastdocs-web-app-dev.firebaseapp.com'
  ) {
    return {
      apiKey: 'AIzaSyB9ZHuGGsDdkMwvXWK418JFZOg3O6O-8uM',
      authDomain: 'fastdocs-web-app-dev.firebaseapp.com',
      databaseURL: 'https://fastdocs-web-app-dev.firebaseio.com',
      projectId: 'fastdocs-web-app-dev',
      storageBucket: 'fastdocs-web-app-dev.appspot.com',
      messagingSenderId: '425320757892',
      appId: '1:425320757892:web:e985ff54b50cb9834020d1',
    }
  } else {
    return {
      apiKey: 'AIzaSyBMfEtldGbU10Xy3fgxg6iO6Viv9jOSYOc',
      authDomain: 'fastdocs-web-app.firebaseapp.com',
      databaseURL: 'https://fastdocs-web-app.firebaseio.com',
      projectId: 'fastdocs-web-app',
      storageBucket: 'fastdocs-web-app.appspot.com',
      messagingSenderId: '444926783159',
      appId: '1:444926783159:web:a0f4ad4559805b6a9ae732',
    }
  }
}

export default firebaseConfig
