import React from 'react'
import { ReactComponent as HelpIcon } from 'static/icons/16x16/help-icon-16.svg'
import './HelperButton.scss'

const HelperButton = (props) => (
  <div className={'helper'}>
    <button type="button" className="helper-button" onClick={props.onClick}>
      <HelpIcon />
    </button>
  </div>
)

export default HelperButton
