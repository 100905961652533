import React, { Fragment } from 'react'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../../ui-components/buttons/HelperButton/HelperButton'
import SelectField from '../../../Selects/SelectField/SelectField'
import TextInputField from '../../../Inputs/TextInputField/TextInputField'
import DateInputField from '../../../Inputs/DateInputField/DateInputField'
import NumberInputField from '../../../Inputs/NumberInputField/NumberInputField'
import EmailInputField from '../../../Inputs/EmailInputField/EmailInputField'
import TwoColumns from '../../../ui-components/layouts/TwoColumns/TwoColumns'
import PrependNumberInputField from '../../../Inputs/PrependNumberInputField/PrependNumberInputField'

import {
  validateSocialSecurityNumber,
  validateBirthdate,
} from '../../utility/socialSecurityNumberValidation'
import { tinValidation } from 'components/utility/tinValidation'

import classes from './PersonalInformationForm.module.scss'
import RadioInputField from '../../../Inputs/RadioInputField/RadioInputField'

interface Props {
  type:
    | 'private'
    | 'limited-company-and-partnership-kg'
    | 'limited-company-and-partnership-gmbh'
    | 'limited-company'
    | 'limited-entrepreneurial-company'
    | 'civil-law-company-first-partner'
    | 'civil-law-company-second-partner'
  values: {
    companyName: string
    academicTitle: string
    firstname: string
    lastname: string
    birthday: string
    birthmonth: string
    birthyear: string
    denomination: string
    socialSecurityNumber: string
    taxIdNumber: string
    street: string
    houseNumber: string
    zipCode: string
    city: string
    phoneNumber: string
    email: string
    nationality: string
    capitalContribution: string
    limitedCompanyAcademicTitle: string
    limitedCompanyFirstname: string
    limitedCompanyLastname: string
    limitedCompanyBirthday: string
    limitedCompanyBirthmonth: string
    limitedCompanyBirthyear: string
    limitedCompanyDenomination: string
    limitedCompanyTaxIdNumber: string
    limitedCompanyStreet: string
    limitedCompanyHouseNumber: string
    limitedCompanyZipCode: string
    limitedCompanyCity: string
    limitedCompanyPhoneNumber: string
    limitedCompanyEmail: string
    sameShareholder: string
    shareCapital: string
    naturalPerson: string
  }
  onHelperButtonClick: () => void
  onNextButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBackButtonClick?: (e: React.FormEvent<HTMLFormElement>) => void
  onSameShareholderChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const PersonalInformationForm = (props: Props) => {
  const {
    values: {
      academicTitle,
      firstname,
      lastname,
      birthday,
      birthmonth,
      birthyear,
      denomination,
      socialSecurityNumber,
      taxIdNumber,
      street,
      houseNumber,
      zipCode,
      city,
      phoneNumber,
      email,
      capitalContribution,
      limitedCompanyAcademicTitle,
      limitedCompanyFirstname,
      limitedCompanyLastname,
      limitedCompanyBirthday,
      limitedCompanyBirthmonth,
      limitedCompanyBirthyear,
      limitedCompanyDenomination,
      limitedCompanyTaxIdNumber,
      limitedCompanyStreet,
      limitedCompanyHouseNumber,
      limitedCompanyZipCode,
      limitedCompanyCity,
      limitedCompanyPhoneNumber,
      limitedCompanyEmail,
      sameShareholder,
      shareCapital,
      naturalPerson,
      companyName,
    },
    type,
    onHelperButtonClick,
    onNextButtonClick,
    onChange,
    onBackButtonClick,
    onSameShareholderChange,
  } = props

  const setSocialSecurityNumberValidationLabel = () => {
    if (socialSecurityNumber.length <= 0) {
      return 'Sozialversicherungsnummer (optional)'
    }
    if (socialSecurityNumber.length <= 11) {
      return (
        <span className={classes.validateInfo}>
          Sozialversicherungsnummer (optional){' '}
          <span className={classes.warn}>Nummer ist unvollständig</span>
        </span>
      )
    }
    if (
      validateSocialSecurityNumber(socialSecurityNumber) === undefined &&
      socialSecurityNumber.length >= 12
    ) {
      return (
        <span className={classes.validateInfo}>
          Sozialversicherungsnummer (optional){' '}
          <span className={classes.error}>Nummer ist zu lang</span>
        </span>
      )
    }
    if (
      validateBirthdate(
        socialSecurityNumber,
        birthday,
        birthmonth,
        birthyear
      ) === false
    ) {
      return (
        <span className={classes.validateInfo}>
          Sozialversicherungsnummer (optional){' '}
          <span className={classes.error}>
            Nummer passt nicht zum Geburtsdatum
          </span>
        </span>
      )
    }
    if (validateSocialSecurityNumber(socialSecurityNumber) === undefined) {
      return (
        <span className={classes.validateInfo}>
          Sozialversicherungsnummer (optional){' '}
          <span className={classes.error}>Nummer ist falsch</span>
        </span>
      )
    }
    if (validateSocialSecurityNumber(socialSecurityNumber) === false) {
      return (
        <span className={classes.validateInfo}>
          Sozialversicherungsnummer (optional){' '}
          <span className={classes.error}>Nummer ist falsch</span>
        </span>
      )
    }
    if (validateSocialSecurityNumber(socialSecurityNumber) === true) {
      return 'Sozialversicherungsnummer (optional)'
    }
  }

  const setTinLabelValidationLabelForTaxIdNumber = () => {
    if (taxIdNumber.length <= 0) {
      return 'Persönliche Steuer-ID'
    }
    if (!tinValidation(taxIdNumber)) {
      return (
        <span className={classes.validateInfo}>
          Persönliche Steuer-ID{' '}
          <span className={classes.warn}>Keine gültige Steuer-ID</span>
        </span>
      )
    }
    return 'Persönliche Steuer-ID'
  }

  const setTinLabelValidationForLimitedCompanyTaxIdNumber = () => {
    if (limitedCompanyTaxIdNumber.length <= 0) {
      return 'Persönliche Steuer-ID'
    }
    if (!tinValidation(limitedCompanyTaxIdNumber)) {
      return (
        <span className={classes.validateInfo}>
          Persönliche Steuer-ID{' '}
          <span className={classes.warn}>Keine gültige Steuer-ID</span>
        </span>
      )
    }
    return 'Persönliche Steuer-ID'
  }

  const preventSubmiting = () => {
    if (
      !validateSocialSecurityNumber(socialSecurityNumber) ||
      !tinValidation(taxIdNumber) ||
      !tinValidation(limitedCompanyTaxIdNumber)
    ) {
      return null
    }
  }

  const renderNaturalPersonFormFields = () => {
    if (naturalPerson === 'Ja') {
      return (
        <Fragment>
          <TwoColumns layout="1-1">
            <SelectField
              label="Akademischer Titel"
              name="limitedCompanyAcademicTitle"
              options={['Keine', 'Dr.', 'Prof.', 'Dipl.-Ing.']}
              noDefault
              onChange={onChange}
              required
              defaultValue={limitedCompanyAcademicTitle}
            />
          </TwoColumns>

          <TwoColumns layout="1-1">
            <TextInputField
              label="Vorname"
              name="firstname"
              onChange={onChange}
              required
              defaultValue={firstname}
              placeholder="Alex"
            />
            <TextInputField
              label="Nachname"
              name="lastname"
              onChange={onChange}
              required
              defaultValue={lastname}
              placeholder="Schmitz"
            />
          </TwoColumns>

          <TwoColumns layout="1-1">
            <DateInputField
              label="Geburtsdatum"
              names={[
                'limitedCompanyBirthday',
                'limitedCompanyBirthmonth',
                'limitedCompanyBirthyear',
              ]}
              onChange={onChange}
              placeholder={['TT', 'MM', 'JJJJ']}
              maxLength={['2', '2', '4']}
              max={['31', '12', new Date().getFullYear()]}
              min={['1', '1', '1900']}
              pattern="\d"
              required
              defaultValue={[
                limitedCompanyBirthday,
                limitedCompanyBirthmonth,
                limitedCompanyBirthyear,
              ]}
            />
            <SelectField
              label="Konfession"
              name="limitedCompanyDenomination"
              options={[
                'Konfessionslos / Keine Kirchensteuerberechnung',
                'ev - Evangelische Kirchensteuer',
                'ib - Israelitische Religionsgemeinschaft Baden',
                'ih - Jüdische Kultussteuer',
                'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
                'is - Israelitische / Jüdische Kultussteuer',
                'iw - Israelitische Religionsgemeinschaft Württembergs',
                'jd - Jüdische Kultussteuer',
                'jh - Jüdische Kultussteuer',
                'lt - Evangelisch lutherisch (bis 12/2015)',
                'rf - Evangelisch reformiert (bis 12/2015)',
                'rk - Römisch-Katholische Kirchensteuer',
                'ak - Altkatholische Kirchensteuer',
                'fa - Freie Religionsgemeinschaft Alzey',
                'fb - Freireligiöse Landesgemeinde Baden',
                'fg - Freireligiöse Landesgemeinde Pfalz',
                'fm - Freireligiöse Gemeinde Mainz',
                'fr - Französisch reformiert (bis 12/2015)',
                'fs - Freireligiöse Gemeinde Offenbach/Main',
              ]}
              noDefault
              onChange={onChange}
              required
              defaultValue={limitedCompanyDenomination}
            />
          </TwoColumns>

          <TwoColumns layout="1-1">
            <PrependNumberInputField
              label="Beteiligung am Stammkapital"
              name="shareCapital"
              placeholder="100"
              prependText="%"
              defaultValue={shareCapital}
              required
              min={1}
              max={100}
              step={0.01}
              onChange={onChange}
            />
            <TextInputField
              defaultValue={limitedCompanyTaxIdNumber}
              label={setTinLabelValidationForLimitedCompanyTaxIdNumber()}
              name={'limitedCompanyTaxIdNumber'}
              onChange={onChange}
              placeholder={'56345234987'}
              minLength={11}
              required
            />
          </TwoColumns>

          <TwoColumns layout="1-1">
            <TextInputField
              label="Straße"
              name="limitedCompanyStreet"
              onChange={onChange}
              required
              defaultValue={limitedCompanyStreet}
              placeholder="Musterstraße"
            />
            <TextInputField
              label="Hausnummer"
              name="limitedCompanyHouseNumber"
              onChange={onChange}
              required
              defaultValue={limitedCompanyHouseNumber}
              placeholder="123"
            />
          </TwoColumns>

          <TwoColumns layout="1-1">
            <TextInputField
              label="Postleitzahl"
              name="limitedCompanyZipCode"
              onChange={onChange}
              required
              defaultValue={limitedCompanyZipCode}
              placeholder="45678"
            />
            <TextInputField
              label="Stadt"
              name="limitedCompanyCity"
              onChange={onChange}
              required
              defaultValue={limitedCompanyCity}
              placeholder="Musterstadt"
            />
          </TwoColumns>

          <TwoColumns layout="1-1">
            <NumberInputField
              label="Telefonnummer"
              name="limitedCompanyPhoneNumber"
              onChange={onChange}
              required
              defaultValue={limitedCompanyPhoneNumber}
              placeholder="0212123456789"
            />
            <EmailInputField
              label="E-Mail"
              name="email"
              onChange={onChange}
              required
              defaultValue={email}
              placeholder="hallo@alexschmitz.de"
            />
          </TwoColumns>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <TwoColumns layout="1-1">
          <TextInputField
            label="Name des Unternehmens"
            name="companyName"
            onChange={onChange}
            required
            defaultValue={companyName}
            placeholder={
              type === 'limited-entrepreneurial-company'
                ? 'Muster Verwaltungs UG'
                : 'Muster Verwaltungs GmbH'
            }
          />
          <PrependNumberInputField
            label="Beteiligung am Stammkapital"
            name="shareCapital"
            placeholder="100"
            prependText="%"
            defaultValue={shareCapital}
            required
            min={1}
            max={100}
            step={0.01}
            onChange={onChange}
          />
        </TwoColumns>

        <TwoColumns layout="1-1">
          <TextInputField
            label="Straße"
            name="limitedCompanyStreet"
            onChange={onChange}
            required
            defaultValue={limitedCompanyStreet}
            placeholder="Musterstraße"
          />
          <TextInputField
            label="Hausnummer"
            name="limitedCompanyHouseNumber"
            onChange={onChange}
            required
            defaultValue={limitedCompanyHouseNumber}
            placeholder="123"
          />
        </TwoColumns>

        <TwoColumns layout="1-1">
          <TextInputField
            label="Postleitzahl"
            name="limitedCompanyZipCode"
            onChange={onChange}
            required
            defaultValue={limitedCompanyZipCode}
            placeholder="45678"
          />
          <TextInputField
            label="Stadt"
            name="limitedCompanyCity"
            onChange={onChange}
            required
            defaultValue={limitedCompanyCity}
            placeholder="Musterstadt"
          />
        </TwoColumns>

        <TwoColumns layout="1-1">
          <NumberInputField
            label="Telefonnummer"
            name="limitedCompanyPhoneNumber"
            onChange={onChange}
            required
            defaultValue={limitedCompanyPhoneNumber}
            placeholder="0212123456789"
          />
          <EmailInputField
            label="E-Mail"
            name="email"
            onChange={onChange}
            required
            defaultValue={email}
            placeholder="hallo@alexschmitz.de"
          />
        </TwoColumns>
      </Fragment>
    )
  }

  const renderContent = () => {
    if (type === 'private') {
      return (
        <Fragment>
          <FormHeader headlineText="Persönliche Angaben" />
          <FormBody>
            <TwoColumns layout="1-1">
              <SelectField
                label="Akademischer Titel"
                name="academicTitle"
                options={['Keine', 'Dr.', 'Prof.', 'Dipl.-Ing.']}
                noDefault
                onChange={onChange}
                required
                defaultValue={academicTitle}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Vorname"
                name="firstname"
                onChange={onChange}
                required
                defaultValue={firstname}
                placeholder="Alex"
              />
              <TextInputField
                label="Nachname"
                name="lastname"
                onChange={onChange}
                required
                defaultValue={lastname}
                placeholder="Schmitz"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <DateInputField
                label="Geburtsdatum"
                names={['birthday', 'birthmonth', 'birthyear']}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="\d"
                required
                defaultValue={[birthday, birthmonth, birthyear]}
              />
              <SelectField
                label="Konfession"
                name="denomination"
                options={[
                  'Konfessionslos / Keine Kirchensteuerberechnung',
                  'ev - Evangelische Kirchensteuer',
                  'ib - Israelitische Religionsgemeinschaft Baden',
                  'ih - Jüdische Kultussteuer',
                  'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
                  'is - Israelitische / Jüdische Kultussteuer',
                  'iw - Israelitische Religionsgemeinschaft Württembergs',
                  'jd - Jüdische Kultussteuer',
                  'jh - Jüdische Kultussteuer',
                  'lt - Evangelisch lutherisch (bis 12/2015)',
                  'rf - Evangelisch reformiert (bis 12/2015)',
                  'rk - Römisch-Katholische Kirchensteuer',
                  'ak - Altkatholische Kirchensteuer',
                  'fa - Freie Religionsgemeinschaft Alzey',
                  'fb - Freireligiöse Landesgemeinde Baden',
                  'fg - Freireligiöse Landesgemeinde Pfalz',
                  'fm - Freireligiöse Gemeinde Mainz',
                  'fr - Französisch reformiert (bis 12/2015)',
                  'fs - Freireligiöse Gemeinde Offenbach/Main',
                ]}
                noDefault
                onChange={onChange}
                required
                defaultValue={denomination}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label={setSocialSecurityNumberValidationLabel()}
                placeholder="12190367K006"
                name="socialSecurityNumber"
                defaultValue={socialSecurityNumber}
                onChange={onChange}
                minLength={12}
                title="Bitte trage deine Sozialversicherungsnummer ein"
              />
              <TextInputField
                defaultValue={taxIdNumber}
                label={setTinLabelValidationLabelForTaxIdNumber()}
                name={'taxIdNumber'}
                onChange={onChange}
                placeholder={'56345234987'}
                minLength={11}
                required
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Straße"
                name="street"
                onChange={onChange}
                required
                defaultValue={street}
                placeholder="Musterstraße"
              />
              <TextInputField
                label="Hausnummer"
                name="houseNumber"
                onChange={onChange}
                required
                defaultValue={houseNumber}
                placeholder="123"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Postleitzahl"
                name="zipCode"
                onChange={onChange}
                required
                defaultValue={zipCode}
                placeholder="45678"
              />
              <TextInputField
                label="Stadt"
                name="city"
                onChange={onChange}
                required
                defaultValue={city}
                placeholder="Musterstadt"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <NumberInputField
                label="Telefonnummer"
                name="phoneNumber"
                onChange={onChange}
                required
                defaultValue={phoneNumber}
                placeholder="0212123456789"
              />
              <EmailInputField
                label="E-Mail"
                name="email"
                onChange={onChange}
                required
                defaultValue={email}
                placeholder="hallo@alexschmitz.de"
              />
            </TwoColumns>
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-company-and-partnership-kg') {
      return (
        <Fragment>
          <FormHeader headlineText="Persönliche Angaben (KG-GesellschafterIn)">
            <p>
              Die KG hat einen Vollhafter (in der Regel eine GmbH) und einen
              Teilhafter, den Kommanditisten.
            </p>
            <p>
              Der Kommanditist haftet bis zur Höhe seiner Einlage, die im
              Handelsregister eintragen ist. Erfassen Sie hier bitte den
              Gesellschafter als Teilhafter.
            </p>
          </FormHeader>
          <FormBody>
            <TwoColumns layout="1-1">
              <SelectField
                label="Akademischer Titel"
                name="academicTitle"
                options={['Keine', 'Dr.', 'Prof.', 'Dipl.-Ing.']}
                noDefault
                onChange={onChange}
                required
                defaultValue={academicTitle}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Vorname"
                name="firstname"
                onChange={onChange}
                required
                defaultValue={firstname}
                placeholder="Alex"
              />
              <TextInputField
                label="Nachname"
                name="lastname"
                onChange={onChange}
                required
                defaultValue={lastname}
                placeholder="Schmitz"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <DateInputField
                label="Geburtsdatum"
                names={['birthday', 'birthmonth', 'birthyear']}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="\d"
                required
                defaultValue={[birthday, birthmonth, birthyear]}
              />
              <SelectField
                label="Konfession"
                name="denomination"
                options={[
                  'Konfessionslos / Keine Kirchensteuerberechnung',
                  'ev - Evangelische Kirchensteuer',
                  'ib - Israelitische Religionsgemeinschaft Baden',
                  'ih - Jüdische Kultussteuer',
                  'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
                  'is - Israelitische / Jüdische Kultussteuer',
                  'iw - Israelitische Religionsgemeinschaft Württembergs',
                  'jd - Jüdische Kultussteuer',
                  'jh - Jüdische Kultussteuer',
                  'lt - Evangelisch lutherisch (bis 12/2015)',
                  'rf - Evangelisch reformiert (bis 12/2015)',
                  'rk - Römisch-Katholische Kirchensteuer',
                  'ak - Altkatholische Kirchensteuer',
                  'fa - Freie Religionsgemeinschaft Alzey',
                  'fb - Freireligiöse Landesgemeinde Baden',
                  'fg - Freireligiöse Landesgemeinde Pfalz',
                  'fm - Freireligiöse Gemeinde Mainz',
                  'fr - Französisch reformiert (bis 12/2015)',
                  'fs - Freireligiöse Gemeinde Offenbach/Main',
                ]}
                noDefault
                onChange={onChange}
                required
                defaultValue={denomination}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                defaultValue={taxIdNumber}
                label={setTinLabelValidationLabelForTaxIdNumber()}
                name={'taxIdNumber'}
                onChange={onChange}
                placeholder={'56345234987'}
                minLength={11}
                required
              />
              <PrependNumberInputField
                label="Höhe der Einlage laut Vertrag"
                name="capitalContribution"
                placeholder="10000"
                prependText="€"
                defaultValue={capitalContribution}
                required
                min={0}
                step={0.01}
                onChange={onChange}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Straße"
                name="street"
                onChange={onChange}
                required
                defaultValue={street}
                placeholder="Musterstraße"
              />
              <TextInputField
                label="Hausnummer"
                name="houseNumber"
                onChange={onChange}
                required
                defaultValue={houseNumber}
                placeholder="123"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Postleitzahl"
                name="zipCode"
                onChange={onChange}
                required
                defaultValue={zipCode}
                placeholder="45678"
              />
              <TextInputField
                label="Stadt"
                name="city"
                onChange={onChange}
                required
                defaultValue={city}
                placeholder="Musterstadt"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <NumberInputField
                label="Telefonnummer"
                name="phoneNumber"
                onChange={onChange}
                required
                defaultValue={phoneNumber}
                placeholder="0212123456789"
              />
              <EmailInputField
                label="E-Mail"
                name="email"
                onChange={onChange}
                required
                defaultValue={email}
                placeholder="hallo@alexschmitz.de"
              />
            </TwoColumns>
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-company-and-partnership-gmbh') {
      return (
        <Fragment>
          <FormHeader headlineText="Persönliche Angaben (GmbH-GesellschafterIn)">
            <p>
              Auch die GmbH als Vollhafter hat einen oder mehrere
              Gesellschafter.
            </p>
            <p>
              Bitte erfassen Sie nachfolgend die persönlichen Daten des
              Hauptgesellschafters der GmbH. In vielen Fällen handelt es sich
              hier um den Kommanditisten (Teilhafter) der KG.
            </p>
          </FormHeader>
          <FormBody>
            <RadioInputField
              label="Ist der/die TeilhafterIn der KG gleichzeitig GesellschafterIn der GmbH?"
              name="sameShareholder"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={sameShareholder === 'Nein' ? 0 : 1}
              onChange={onSameShareholderChange}
            />

            {sameShareholder === 'Nein' ? (
              <Fragment>
                <TwoColumns layout="1-1">
                  <SelectField
                    label="Akademischer Titel"
                    name="limitedCompanyAcademicTitle"
                    options={['Keine', 'Dr.', 'Prof.', 'Dipl.-Ing.']}
                    noDefault
                    onChange={onChange}
                    required
                    defaultValue={limitedCompanyAcademicTitle}
                  />
                </TwoColumns>

                <TwoColumns layout="1-1">
                  <TextInputField
                    label="Vorname"
                    name="limitedCompanyFirstname"
                    onChange={onChange}
                    required
                    defaultValue={limitedCompanyFirstname}
                    placeholder="Alex"
                  />
                  <TextInputField
                    label="Nachname"
                    name="limitedCompanyLastname"
                    onChange={onChange}
                    required
                    defaultValue={limitedCompanyLastname}
                    placeholder="Schmitz"
                  />
                </TwoColumns>

                <TwoColumns layout="1-1">
                  <DateInputField
                    label="Geburtsdatum"
                    names={[
                      'limitedCompanyBirthday',
                      'limitedCompanyBirthmonth',
                      'limitedCompanyBirthyear',
                    ]}
                    onChange={onChange}
                    placeholder={['TT', 'MM', 'JJJJ']}
                    maxLength={['2', '2', '4']}
                    max={['31', '12', new Date().getFullYear()]}
                    min={['1', '1', '1900']}
                    pattern="\d"
                    required
                    defaultValue={[
                      limitedCompanyBirthday,
                      limitedCompanyBirthmonth,
                      limitedCompanyBirthyear,
                    ]}
                  />
                  <SelectField
                    label="Konfession"
                    name="limitedCompanyDenomination"
                    options={[
                      'Konfessionslos / Keine Kirchensteuerberechnung',
                      'ev - Evangelische Kirchensteuer',
                      'ib - Israelitische Religionsgemeinschaft Baden',
                      'ih - Jüdische Kultussteuer',
                      'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
                      'is - Israelitische / Jüdische Kultussteuer',
                      'iw - Israelitische Religionsgemeinschaft Württembergs',
                      'jd - Jüdische Kultussteuer',
                      'jh - Jüdische Kultussteuer',
                      'lt - Evangelisch lutherisch (bis 12/2015)',
                      'rf - Evangelisch reformiert (bis 12/2015)',
                      'rk - Römisch-Katholische Kirchensteuer',
                      'ak - Altkatholische Kirchensteuer',
                      'fa - Freie Religionsgemeinschaft Alzey',
                      'fb - Freireligiöse Landesgemeinde Baden',
                      'fg - Freireligiöse Landesgemeinde Pfalz',
                      'fm - Freireligiöse Gemeinde Mainz',
                      'fr - Französisch reformiert (bis 12/2015)',
                      'fs - Freireligiöse Gemeinde Offenbach/Main',
                    ]}
                    noDefault
                    onChange={onChange}
                    required
                    defaultValue={limitedCompanyDenomination}
                  />
                </TwoColumns>

                <TwoColumns layout="1-1">
                  <TextInputField
                    defaultValue={limitedCompanyTaxIdNumber}
                    label={setTinLabelValidationForLimitedCompanyTaxIdNumber()}
                    name={'limitedCompanyTaxIdNumber'}
                    onChange={onChange}
                    placeholder={'56345234987'}
                    minLength={11}
                    required
                  />
                  <TextInputField
                    label="Straße"
                    name="limitedCompanyStreet"
                    onChange={onChange}
                    required
                    defaultValue={limitedCompanyStreet}
                    placeholder="Musterstraße"
                  />
                </TwoColumns>

                <TwoColumns layout="1-1">
                  <TextInputField
                    label="Hausnummer"
                    name="limitedCompanyHouseNumber"
                    onChange={onChange}
                    required
                    defaultValue={limitedCompanyHouseNumber}
                    placeholder="123"
                  />
                  <TextInputField
                    label="Postleitzahl"
                    name="limitedCompanyZipCode"
                    onChange={onChange}
                    required
                    defaultValue={limitedCompanyZipCode}
                    placeholder="45678"
                  />
                </TwoColumns>

                <TwoColumns layout="1-1">
                  <TextInputField
                    label="Stadt"
                    name="limitedCompanyCity"
                    onChange={onChange}
                    required
                    defaultValue={limitedCompanyCity}
                    placeholder="Musterstadt"
                  />
                  <NumberInputField
                    label="Telefonnummer"
                    name="limitedCompanyPhoneNumber"
                    onChange={onChange}
                    required
                    defaultValue={limitedCompanyPhoneNumber}
                    placeholder="0212123456789"
                  />
                </TwoColumns>

                <TwoColumns layout="1-1">
                  <EmailInputField
                    label="E-Mail"
                    name="limitedCompanyEmail"
                    onChange={onChange}
                    required
                    defaultValue={limitedCompanyEmail}
                    placeholder="hallo@alexschmitz.de"
                  />
                </TwoColumns>
              </Fragment>
            ) : (
              <Fragment>
                <PrependNumberInputField
                  label="Beteiligung am Stammkapital"
                  name="shareCapital"
                  placeholder="100"
                  prependText="%"
                  defaultValue={shareCapital}
                  required
                  min={1}
                  max={100}
                  step={0.01}
                  onChange={onChange}
                />
              </Fragment>
            )}
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Persönliche Daten des Gesellschafters" />
          <FormBody>
            <RadioInputField
              label="Ist der Gesellschafter eine natürliche Person?"
              name="naturalPerson"
              radioOptions={['Ja', 'Nein']}
              defaultChecked={naturalPerson === 'Ja' ? 0 : 1}
              onChange={onChange}
            />
            {renderNaturalPersonFormFields()}
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-entrepreneurial-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Persönliche Daten des Gesellschafters" />
          <FormBody>
            <RadioInputField
              label="Ist der Gesellschafter eine natürliche Person?"
              name="naturalPerson"
              radioOptions={['Ja', 'Nein']}
              defaultChecked={naturalPerson === 'Ja' ? 0 : 1}
              onChange={onChange}
            />
            {renderNaturalPersonFormFields()}
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'civil-law-company-first-partner') {
      return (
        <Fragment>
          <FormHeader headlineText="Persönliche Angaben (GesellschafterIn 1)" />
          <FormBody>
            <TwoColumns layout="1-1">
              <SelectField
                label="Akademischer Titel"
                name="academicTitle"
                options={['Keine', 'Dr.', 'Prof.', 'Dipl.-Ing.']}
                noDefault
                onChange={onChange}
                required
                defaultValue={academicTitle}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Vorname"
                name="firstname"
                onChange={onChange}
                required
                defaultValue={firstname}
                placeholder="Alex"
              />
              <TextInputField
                label="Nachname"
                name="lastname"
                onChange={onChange}
                required
                defaultValue={lastname}
                placeholder="Schmitz"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <DateInputField
                label="Geburtsdatum"
                names={['birthday', 'birthmonth', 'birthyear']}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="\d"
                required
                defaultValue={[birthday, birthmonth, birthyear]}
              />
              <SelectField
                label="Konfession"
                name="denomination"
                options={[
                  'Konfessionslos / Keine Kirchensteuerberechnung',
                  'ev - Evangelische Kirchensteuer',
                  'ib - Israelitische Religionsgemeinschaft Baden',
                  'ih - Jüdische Kultussteuer',
                  'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
                  'is - Israelitische / Jüdische Kultussteuer',
                  'iw - Israelitische Religionsgemeinschaft Württembergs',
                  'jd - Jüdische Kultussteuer',
                  'jh - Jüdische Kultussteuer',
                  'lt - Evangelisch lutherisch (bis 12/2015)',
                  'rf - Evangelisch reformiert (bis 12/2015)',
                  'rk - Römisch-Katholische Kirchensteuer',
                  'ak - Altkatholische Kirchensteuer',
                  'fa - Freie Religionsgemeinschaft Alzey',
                  'fb - Freireligiöse Landesgemeinde Baden',
                  'fg - Freireligiöse Landesgemeinde Pfalz',
                  'fm - Freireligiöse Gemeinde Mainz',
                  'fr - Französisch reformiert (bis 12/2015)',
                  'fs - Freireligiöse Gemeinde Offenbach/Main',
                ]}
                noDefault
                onChange={onChange}
                required
                defaultValue={denomination}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                defaultValue={taxIdNumber}
                label={setTinLabelValidationLabelForTaxIdNumber()}
                name={'taxIdNumber'}
                onChange={onChange}
                placeholder={'56345234987'}
                minLength={11}
                required
              />
              <TextInputField
                label="Straße"
                name="street"
                onChange={onChange}
                required
                defaultValue={street}
                placeholder="Musterstraße"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Hausnummer"
                name="houseNumber"
                onChange={onChange}
                required
                defaultValue={houseNumber}
                placeholder="123"
              />
              <TextInputField
                label="Postleitzahl"
                name="zipCode"
                onChange={onChange}
                required
                defaultValue={zipCode}
                placeholder="45678"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Stadt"
                name="city"
                onChange={onChange}
                required
                defaultValue={city}
                placeholder="Musterstadt"
              />
              <NumberInputField
                label="Telefonnummer"
                name="phoneNumber"
                onChange={onChange}
                required
                defaultValue={phoneNumber}
                placeholder="0212123456789"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <EmailInputField
                label="E-Mail"
                name="email"
                onChange={onChange}
                required
                defaultValue={email}
                placeholder="hallo@alexschmitz.de"
              />
            </TwoColumns>

            <hr />

            <PrependNumberInputField
              label="Gesellschaftsanteile"
              name="capitalContribution"
              placeholder="50"
              prependText="%"
              defaultValue={capitalContribution}
              required
              min={0}
              max={100}
              step={0.01}
              onChange={onChange}
            />
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'civil-law-company-second-partner') {
      return (
        <Fragment>
          <FormHeader headlineText="Persönliche Angaben (GesellschafterIn 2)" />
          <FormBody>
            <TwoColumns layout="1-1">
              <SelectField
                label="Akademischer Titel"
                name="limitedCompanyAcademicTitle"
                options={['Keine', 'Dr.', 'Prof.', 'Dipl.-Ing.']}
                noDefault
                onChange={onChange}
                required
                defaultValue={limitedCompanyAcademicTitle}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Vorname"
                name="limitedCompanyFirstname"
                onChange={onChange}
                required
                defaultValue={limitedCompanyFirstname}
                placeholder="Alex"
              />
              <TextInputField
                label="Nachname"
                name="limitedCompanyLastname"
                onChange={onChange}
                required
                defaultValue={limitedCompanyLastname}
                placeholder="Schmitz"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <DateInputField
                label="Geburtsdatum"
                names={[
                  'limitedCompanyBirthday',
                  'limitedCompanyBirthmonth',
                  'limitedCompanyBirthyear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="\d"
                required
                defaultValue={[
                  limitedCompanyBirthday,
                  limitedCompanyBirthmonth,
                  limitedCompanyBirthyear,
                ]}
              />
              <SelectField
                label="Konfession"
                name="limitedCompanyDenomination"
                options={[
                  'Konfessionslos / Keine Kirchensteuerberechnung',
                  'ev - Evangelische Kirchensteuer',
                  'ib - Israelitische Religionsgemeinschaft Baden',
                  'ih - Jüdische Kultussteuer',
                  'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
                  'is - Israelitische / Jüdische Kultussteuer',
                  'iw - Israelitische Religionsgemeinschaft Württembergs',
                  'jd - Jüdische Kultussteuer',
                  'jh - Jüdische Kultussteuer',
                  'lt - Evangelisch lutherisch (bis 12/2015)',
                  'rf - Evangelisch reformiert (bis 12/2015)',
                  'rk - Römisch-Katholische Kirchensteuer',
                  'ak - Altkatholische Kirchensteuer',
                  'fa - Freie Religionsgemeinschaft Alzey',
                  'fb - Freireligiöse Landesgemeinde Baden',
                  'fg - Freireligiöse Landesgemeinde Pfalz',
                  'fm - Freireligiöse Gemeinde Mainz',
                  'fr - Französisch reformiert (bis 12/2015)',
                  'fs - Freireligiöse Gemeinde Offenbach/Main',
                ]}
                noDefault
                onChange={onChange}
                required
                defaultValue={limitedCompanyDenomination}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                defaultValue={limitedCompanyTaxIdNumber}
                label={setTinLabelValidationLabelForTaxIdNumber()}
                name={'limitedCompanyTaxIdNumber'}
                onChange={onChange}
                placeholder={'56345234987'}
                minLength={11}
                required
              />
              <TextInputField
                label="Straße"
                name="limitedCompanyStreet"
                onChange={onChange}
                required
                defaultValue={limitedCompanyStreet}
                placeholder="Musterstraße"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Hausnummer"
                name="limitedCompanyHouseNumber"
                onChange={onChange}
                required
                defaultValue={limitedCompanyHouseNumber}
                placeholder="123"
              />
              <TextInputField
                label="Postleitzahl"
                name="limitedCompanyZipCode"
                onChange={onChange}
                required
                defaultValue={limitedCompanyZipCode}
                placeholder="45678"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Stadt"
                name="limitedCompanyCity"
                onChange={onChange}
                required
                defaultValue={limitedCompanyCity}
                placeholder="Musterstadt"
              />
              <NumberInputField
                label="Telefonnummer"
                name="limitedCompanyPhoneNumber"
                onChange={onChange}
                required
                defaultValue={limitedCompanyPhoneNumber}
                placeholder="0212123456789"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <EmailInputField
                label="E-Mail"
                name="limitedCompanyEmail"
                onChange={onChange}
                required
                defaultValue={limitedCompanyEmail}
                placeholder="hallo@alexschmitz.de"
              />
            </TwoColumns>

            <hr />

            <PrependNumberInputField
              label="Gesellschaftsanteile"
              name="shareCapital"
              placeholder="50"
              prependText="%"
              defaultValue={shareCapital}
              required
              min={0}
              max={100}
              step={0.01}
              onChange={onChange}
            />
          </FormBody>
        </Fragment>
      )
    }
  }

  return (
    <FormContainer
      className={classes.personalInformationForm}
      onSubmit={onNextButtonClick}
    >
      <HelperButton onClick={onHelperButtonClick} />
      {renderContent()}
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText={onBackButtonClick ? 'Zurück' : null}
        onClick={preventSubmiting}
        onSecondaryButtonClick={onBackButtonClick}
      />
    </FormContainer>
  )
}

export default PersonalInformationForm
