import React from 'react'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import './SummaryForm.scss'
import { STAFF_FORMS } from '../../../constants/Formulars'

const SummaryForm = (props) => {
  const renderCompanyInformations = () => {
    const companyInformationItems = () => {
      return (
        <React.Fragment>
          <li>
            <span>Firmenname</span> <span>{props.company}</span>
          </li>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <ul>
          <li>
            <b>Unternehmen</b>
          </li>
          {companyInformationItems()}
        </ul>
      </React.Fragment>
    )
  }

  const renderEmployeeInformations = () => {
    const employeeInformationItems = () => {
      if (props.formularName === STAFF_FORMS.IMMEDIATE_REPORT) {
        return (
          <React.Fragment>
            <li>
              <span>Vorname</span> <span>{props.firstname}</span>
            </li>
            <li>
              <span>Nachname</span> <span>{props.lastname}</span>
            </li>
            <li>
              <span>Geschlecht</span> <span>{props.sex}</span>
            </li>
            <li>
              <span>Geburtsdatum</span>{' '}
              <span>
                {props.birthday}.{props.birthmonth}.{props.birthyear}
              </span>
            </li>
            <li>
              <span>Staatsangehörigkeit</span> <span>{props.nationality}</span>
            </li>
            <li>
              <span>Mitarbeitertyp</span> <span>{props.employeeType}</span>
            </li>
            <li>
              <span>Beschäftigungsort</span>{' '}
              <span>{props.employmentLocation}</span>
            </li>
            <li>
              <span>Eintrittsdatum</span>{' '}
              <span>
                {props.entryday}.{props.entrymonth}.{props.entryyear}
              </span>
            </li>
            {props.costCenter ? (
              <li>
                <span>Kostenstelle</span> <span>{props.costCenter}</span>
              </li>
            ) : null}
            {props.staffNumber ? (
              <li>
                <span>Personalnummer</span> <span>{props.staffNumber}</span>
              </li>
            ) : null}
          </React.Fragment>
        )
      }
      return (
        <React.Fragment>
          <li>
            <span>Vorname</span> <span>{props.firstname}</span>
          </li>
          <li>
            <span>Nachname</span> <span>{props.lastname}</span>
          </li>
          <li>
            <span>Beschäftigt als</span> <span>{props.employedAs}</span>
          </li>
          <li>
            <span>Schwerbehinderung</span> <span>{props.handycapped}</span>
          </li>
          <li>
            <span>Beschäftigungsort</span>{' '}
            <span>{props.employmentLocation}</span>
          </li>
          <li>
            <span>Eintrittsdatum</span>{' '}
            <span>
              {props.entryday}.{props.entrymonth}.{props.entryyear}
            </span>
          </li>

          {props.contractForm && (
            <li>
              <span>Vertragsform</span> <span>{props.contractForm}</span>
            </li>
          )}

          {props.contractLimitDay && (
            <li>
              <span>Vertrag befristet bis</span>{' '}
              <span>
                {props.contractLimitDay}.{props.contractLimitMonth}.
                {props.contractLimitYear}
              </span>
            </li>
          )}

          {props.statusIndicator && (
            <li>
              <span>Familienangehörigkeit zum Arbeitgeber</span>{' '}
              <span>{props.statusIndicator}</span>
            </li>
          )}

          {props.costCenter && (
            <li>
              <span>Kostenstelle</span> <span>{props.costCenter}</span>
            </li>
          )}

          {props.staffNumber && (
            <li>
              <span>Personalnummer</span> <span>{props.staffNumber}</span>
            </li>
          )}
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <ul>
          <li>
            <b>Mitarbeiter:in Informationen</b>
          </li>
          {employeeInformationItems()}
        </ul>
      </React.Fragment>
    )
  }

  const renderInsuranceInformations = () => {
    const insuranceInformationItems = () => {
      if (props.insuranceNumber === true) {
        return (
          <li>
            <span>Sozialversicherungsnummer</span>{' '}
            <span>{props.socialSecurityNumber}</span>
          </li>
        )
      }

      return (
        <React.Fragment>
          <li>
            <span>Straße</span> <span>{props.street}</span>
          </li>
          <li>
            <span>Hausnummer</span> <span>{props.houseNumber}</span>
          </li>
          <li>
            <span>Postleitzahl</span> <span>{props.zipCode}</span>
          </li>
          <li>
            <span>Stadt</span> <span>{props.city}</span>
          </li>
          <li>
            <span>Geburtsdatum</span>{' '}
            <span>
              {props.birthday}.{props.birthmonth}.{props.birthyear}
            </span>
          </li>
          <li>
            <span>Geburtsname</span> <span>{props.birthName}</span>
          </li>
          <li>
            <span>Geburtsort</span> <span>{props.placeOfBirth}</span>
          </li>
          <li>
            <span>Geburtsland</span> <span>{props.birthCountry}</span>
          </li>
          <li>
            <span>Staatsangehörigkeit</span> <span>{props.nationality}</span>
          </li>
        </React.Fragment>
      )
    }

    if (props.formularName === STAFF_FORMS.IMMEDIATE_REPORT) {
      return (
        <React.Fragment>
          <ul>
            <li>
              <b>Angaben zu Versicherung</b>
            </li>
            {insuranceInformationItems()}
          </ul>
        </React.Fragment>
      )
    }
    return null
  }

  const renderSallaryInformations = () => {
    if (props.formularName === STAFF_FORMS.IMMEDIATE_REPORT) {
      return null
    }
    return (
      <React.Fragment>
        <ul>
          <li>
            <b>Gehalt und Vergütung</b>
          </li>
          {props.formularName === STAFF_FORMS.MINIJOB ||
          props.formularName === STAFF_FORMS.STUDENT_MINIJOB ? (
            <li>
              <span>Alter der Arbeitskraft</span>{' '}
              <span>{props.employeeAge}</span>
            </li>
          ) : null}
          <li>
            <span>Vergütung durch</span> <span>{props.sallary}</span>
          </li>
          <li>
            <span>Gehalt/Stundenlohn</span> <span>{props.sallaryValue}€</span>
          </li>
          <li>
            <span>Auszahlungart</span> <span>{props.payingOption}</span>
          </li>
        </ul>
      </React.Fragment>
    )
  }

  const renderWorkingHoursInformations = () => {
    if (props.formularName === STAFF_FORMS.IMMEDIATE_REPORT) {
      return null
    }
    return (
      <React.Fragment>
        <ul>
          <li>
            <b>Arbeitszeiten</b>
          </li>
          {props.monday &&
          props.tuesday &&
          props.wednesday &&
          props.thursday &&
          props.friday &&
          props.saturday &&
          props.sunday ? (
            <React.Fragment>
              <li>
                <span>Montag</span> <span>{props.monday} Stunden</span>
              </li>
              <li>
                <span>Dienstag</span> <span>{props.tuesday} Stunden</span>
              </li>
              <li>
                <span>Mittwoch</span> <span>{props.wednesday} Stunden</span>
              </li>
              <li>
                <span>Donnerstag</span> <span>{props.thursday} Stunden</span>
              </li>
              <li>
                <span>Freitag</span> <span>{props.friday} Stunden</span>
              </li>
              <li>
                <span>Samstag</span> <span>{props.saturday} Stunden</span>
              </li>
              <li>
                <span>Sonntag</span> <span>{props.sunday} Stunden</span>
              </li>
            </React.Fragment>
          ) : (
            <li>
              <span>Wöchentliche Arbeitszeit</span>{' '}
              <span>Flexibel, allerdings maximal 10 Wochenstunden</span>
            </li>
          )}

          {props.hollidays ? (
            <li>
              <span>Urlaubstage</span> <span>{props.hollidays} Tage/Jahr</span>
            </li>
          ) : null}
        </ul>
      </React.Fragment>
    )
  }

  const renderTaxInformations = () => {
    if (
      props.formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      props.formularName === STAFF_FORMS.FOREIGN_EMPLOYEE
    ) {
      return null
    }
    if (
      props.formularName === STAFF_FORMS.MINIJOB ||
      props.formularName === 'Minijobber bis 450€' // TODO check and remove legacy
    ) {
      return (
        <React.Fragment>
          <ul>
            <li>
              <b>Steuerliche Angaben</b>
            </li>
            <li>
              <span>Lohnsteuerpauschalierung</span> <span>{props.lumpSum}</span>
            </li>
            <li>
              <span>Lohnsteuer umlegen</span>{' '}
              <span>{props.passingToEmployee}</span>
            </li>
          </ul>
        </React.Fragment>
      )
    }
  }

  const renderCapitalAccumulationInformations = () => {
    if (props.formularName === STAFF_FORMS.IMMEDIATE_REPORT) {
      return null
    }
    return (
      <React.Fragment>
        <ul>
          <li>
            <b>Vermögenswirksame Leistungen</b>
          </li>
          <li>
            <span>Vermögenswirksame Leistungen</span>{' '}
            <span>{props.capitalAccumulation}</span>
          </li>
          {props.capitalAccumulation === 'Ja' ? (
            <li>
              <span>Arbeitgeberanteil</span>{' '}
              <span>{props.monthlyProportion}€</span>
            </li>
          ) : null}
        </ul>
      </React.Fragment>
    )
  }

  const renderAdditionalBenefitsInformation = () => {
    if (
      props.formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      props.formularName === STAFF_FORMS.FOREIGN_EMPLOYEE ||
      props.formularName === STAFF_FORMS.APPRENTICESHIP ||
      props.formularName === STAFF_FORMS.STUDENT
    ) {
      return (
        <React.Fragment>
          <ul>
            <li>
              <b>Zusätzliche Absprachen</b>
            </li>
            <li>
              <span>Gehaltserhöhung nach der Probezeit</span>
              <span>
                {props.sallaryIncreaseAfterTrialPeriod ? 'Ja' : 'Nein'}
              </span>
            </li>
            <li>
              <span>Firmenwagen</span>{' '}
              <span>{props.companyCar ? 'Ja' : 'Nein'}</span>
            </li>
            <li>
              <span>Betriebliche Altersvorsorge</span>{' '}
              <span>{props.companyPensionPlan ? 'Ja' : 'Nein'}</span>
            </li>
            <li>
              <span>Job-Ticket</span>{' '}
              <span>{props.jobTicket ? 'Ja' : 'Nein'}</span>
            </li>
            <li>
              <span>Fahrtkostenzuschuss</span>{' '}
              <span>{props.travelAllowance ? 'Ja' : 'Nein'}</span>
            </li>
            <li>
              <span>Steuerfreier Sachbezug (50€)</span>{' '}
              <span>{props.taxFreeBenefit ? 'Ja' : 'Nein'}</span>
            </li>
            <li>
              <span>Internetzuschuss</span>{' '}
              <span>{props.internetGrant ? 'Ja' : 'Nein'}</span>
            </li>
          </ul>
        </React.Fragment>
      )
    }
    return null
  }

  const renderEmailInformations = () => {
    const emailInformationItems = () => {
      if (props.formularName === STAFF_FORMS.IMMEDIATE_REPORT) {
        return (
          <li>
            <span>Deine E-Mail</span> <span>{props.clientEmail}</span>
          </li>
        )
      }
      return (
        <React.Fragment>
          <li>
            <span>Deine E-Mail</span> <span>{props.clientEmail}</span>
          </li>
          <li>
            <span>E-Mail Arbeitnehmer:in</span>{' '}
            <span>{props.employeeEmail}</span>
          </li>
        </React.Fragment>
      )
    }

    return (
      <ul>
        <li>
          <b>Kontakt</b>
        </li>
        {emailInformationItems()}
      </ul>
    )
  }

  const renderOtherInformation = () => {
    return (
      <ul>
        <li>
          <b>Sonstiges</b>
        </li>
        <li className="other-informations">
          {props.otherInformation ? (
            <div className="other-information-container">
              <span>{props.otherInformation}</span>
            </div>
          ) : (
            'Keine'
          )}
        </li>
      </ul>
    )
  }

  const renderWerkstudentWeeklyWorkingTimeInformation = () => {
    const renderOperationTime = () => {
      if (props.totalWeeklyWorkingTimeLimit === 'Gelegentlich')
        return (
          <li>
            <span>
              Finden die Einsätze meistens am Wochenende oder Abends/Nachts
              statt?
            </span>{' '}
            <span>{props.operationTime}</span>
          </li>
        )
    }

    const renderOperationTimeLimit = () => {
      if (props.operationTime === 'Nein')
        return (
          <li>
            <span>Sind die Einsätze auf die Semesterferien begrenzt?</span>{' '}
            <span>{props.operationTimeLimit}</span>
          </li>
        )
    }

    const renderOperationTimeExercised = () => {
      if (props.operationTime === 'Ja' || props.operationTimeLimit === 'Ja')
        return (
          <li>
            <span>
              Werden die Einsätze an mehr als 26 Wochen in einem Jahr ausgeübt?
            </span>{' '}
            <span>{props.operationTimeExercised}</span>
          </li>
        )
    }

    if (props.formularName === STAFF_FORMS.STUDENT) {
      return (
        <React.Fragment>
          <ul>
            <li>
              <b>Wöchentliche Gesamtarbeitszeit</b>
            </li>
            <li>
              <span>
                Liegt die wöchentliche Gesamtarbeitszeit über 20 Stunden?
              </span>{' '}
              <span>{props.totalWeeklyWorkingTimeLimit}</span>
            </li>
            {props.totalWeeklyWorkingTimeLimit === 'Gelegentlich' ? (
              <>
                {renderOperationTime()}
                {renderOperationTimeLimit()}
                {renderOperationTimeExercised()}
              </>
            ) : null}
          </ul>
        </React.Fragment>
      )
    }
    return null
  }

  return (
    <FormContainer className={'summary-form'} onSubmit={props.onSubmit}>
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Zusammenfassung'} />
      <FormBody>
        {renderCompanyInformations()}
        {renderEmployeeInformations()}
        {renderInsuranceInformations()}
        {renderSallaryInformations()}
        {renderWorkingHoursInformations()}
        {renderWerkstudentWeeklyWorkingTimeInformation()}
        {renderTaxInformations()}
        {renderCapitalAccumulationInformations()}
        {renderAdditionalBenefitsInformation()}
        {renderEmailInformations()}
        {renderOtherInformation()}
      </FormBody>
      <FormFooter
        primaryButtonText={'Fertigstellen'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
      />
    </FormContainer>
  )
}

export default SummaryForm
