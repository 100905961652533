import React from 'react'
import ProgressBar from '../../ui-components/ProgressBar/ProgressBar'
import './FormStage.scss'
class FormStage extends React.PureComponent {
  render() {
    return (
      <section className={'form-stage'}>
        <div className={'content-container'}>
          <header className={'stage-header'}>
            <h4>{this.props.headlineText}</h4>
          </header>
          <footer className={'stage-footer'}>
            <div className={'form-steps'}>
              Schritt {this.props.actualStep} von {this.props.totalSteps}
            </div>
            <div className="progress-indicator">
              <span>{this.props.progress}%</span>
              <ProgressBar progress={this.props.progress} />
            </div>
          </footer>
        </div>
      </section>
    )
  }
}

export default FormStage
