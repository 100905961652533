const employeeFormValues = {
  street: 'Mittelstraße',
  houseNumber: '44b',
  zipCode: '70435',
  city: 'Stuttgart',
  birthday: '29',
  birthmonth: '04',
  birthyear: '1980',
  sex: 'Männlich',
  mariatialStatus: 'Ledig',
  matriculation: 'Ja',
  denomination: 'ev - Evangelische Kirchensteuer',
  taxIdNumber: '86149736056',
  taxClass: 'I - Steuerklasse Eins',
  graduation: 'Abitur/Fachabitur',
  education: 'ohne beruflichen Ausbildungabschluss',
  insuranceCompanyName: 'AOK Baden-Württemberg',
  insuranceNumber: true,
  socialSecurityNumber: '60290480O505',
  placeOfBirth: 'Stuttgart',
  birthCountry: 'Deutschland',
  nationality: 'Deutschland',
  bankAccountHolder: 'Oliver Steiner',
  iban: 'DE54342500000001623321',
  capitalAccumulationQuestion: 'Ja',
  capitalAccumulationBankAccountHolder: 'Allianz Versicherung',
  capitalAccumulationIban: 'DE44100110012625484636',
  contractNumber: '08151337/G/4432',
  capitalAccumulationValue: '40',
  totalWeeklyWorkingTimeLimitForStudents: 'Gelegentlich',
  operationTimeIsLimitedToSemesterBreaks: 'Ja',
  furtherEmployments: true,
}

export default employeeFormValues
