const employeeFormValues = {
  amountOfChildren: '1',
  bankAccountHolder: 'Amilia De Jong',
  bea: true,
  birthCountry: 'Niederlande',
  birthday: '29',
  birthmonth: '04',
  birthyear: '1980',
  capitalAccumulationBankAccountHolder: 'LBS',
  capitalAccumulationIban: 'DE54342500000001623321',
  capitalAccumulationQuestion: 'Ja',
  capitalAccumulationValue: '80',
  city: 'Enschede',
  country: 'Niederlande',
  contractNumber: '44859372',
  denomination: 'rk - Römisch-Katholische Kirchensteuer',
  education: 'Anerkannte Berufsausbildung',
  graduation: 'Abitur/Fachabitur',
  houseNumber: '1130',
  iban: 'DE44100110012625484636',
  insuranceCompanyName: 'AOK Bayern',
  insuranceType: 'Privat',
  mariatialStatus: 'Verheiratet',
  nationality: 'Niederlande',
  parentsProperty: 'Ja',
  placeOfBirth: 'Enschede',
  sex: 'Weiblich',
  socialSecurityNumber: '60290480O505',
  street: 'Schopenhauerstraße',
  taxClass: 'III - Steuerklasse Drei',
  typeOfEmployment: 'Minijob bis 450€',
  zipCode: '7534',
}

export default employeeFormValues
