import React from 'react'
import './DateInput.scss'

const DateInput = (props) => {
  return (
    <div className="DateInput">
      <input
        type={'number'}
        name={props.names[0]}
        placeholder={props.placeholder[0]}
        required={props.required}
        defaultValue={props.defaultValue[0]}
        onChange={props.onChange}
        disabled={props.disabled}
        maxLength={props.maxLength[0]}
        max={props.max[0]}
        min={props.min[0]}
        autoFocus={props.autoFocus}
        pattern={props.pattern}
        title={props.title}
      />
      <input
        type={'number'}
        name={props.names[1]}
        placeholder={props.placeholder[1]}
        required={props.required}
        defaultValue={props.defaultValue[1]}
        onChange={props.onChange}
        disabled={props.disabled}
        maxLength={props.maxLength[1]}
        max={props.max[1]}
        min={props.min[1]}
        autoFocus={props.autoFocus}
        pattern={props.pattern}
        title={props.title}
      />
      <input
        type={'number'}
        name={props.names[2]}
        placeholder={props.placeholder[2]}
        required={props.required}
        defaultValue={props.defaultValue[2]}
        onChange={props.onChange}
        disabled={props.disabled}
        maxLength={props.maxLength[2]}
        max={props.max[2]}
        min={props.min[2]}
        autoFocus={props.autoFocus}
        pattern={props.pattern}
        title={props.title}
      />
    </div>
  )
}

export default DateInput
