import React from 'react'
import { Link } from 'react-router-dom'

import FIVE_F_LOGO from '../../../static/images/5fsoftware_logo-claim.png'

import './IntegrationsSettingsView.scss'

interface Props {
  data: {
    contracts: {
      staff: { contract: boolean; contractOption: string }
    }
    integrations: {
      fiveFSoftware: {}
    }
  }
}

const IntegrationsSettingsView = (props: Props) => {
  const renderPrimaryButton = () => {
    if (props.data.integrations && props.data.integrations.fiveFSoftware) {
      return (
        <button className="button secondary" disabled>
          Verknüpft
        </button>
      )
    }

    return (
      <Link to="/dashboard/integrations/5f-software">
        <button
          className="button primary"
          disabled={
            props.data.contracts.staff.contract === true &&
            props.data.contracts.staff.contractOption === 'pro'
              ? false
              : true
          }
        >
          Verknüpfen
        </button>
      </Link>
    )
  }

  const renderContractHint = () => {
    if (
      props.data.contracts.staff.contract === true &&
      props.data.contracts.staff.contractOption === 'pro'
    ) {
      return null
    }
    return (
      <div className="integration-item__contract-hint">
        <p>
          Diese Integration kann nur mit einem Fastdocs Pro Vertrag genutzt
          werden.
        </p>
        <a href="mailto:sales@fastdocs.de?subject=Integration für 5F-Software – Fastdocs Staff Vertrag anpassen&body=Hallo liebes Sales-Team,%0D%0A%0D%0A wir würden gerne die Integration von 5F-Software nutzen.">
          Sales-Team kontaktieren
        </a>
      </div>
    )
  }

  return (
    <div className="integrations-settings-details">
      <header>
        <h4>Integrationen</h4>
      </header>

      <main>
        <section className="integration-item">
          <div className="integration-item__header">
            <img src={FIVE_F_LOGO} alt="5F Software Logo" />
          </div>
          <div className="integration-item__main">
            <p>
              5F-Software ist die führende Arbeitsplattform für
              Wirtschaftsprüfer, Steuerberater und Rechtsanwälte.
            </p>
          </div>
          <div className="integration-item__footer">
            {renderPrimaryButton()}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://fastdocs.de/fastdocs-staff-5f-integration.html"
            >
              <button className="ghost">Mehr erfahren</button>
            </a>
          </div>
          {renderContractHint()}
        </section>
      </main>
    </div>
  )
}

export default IntegrationsSettingsView
