import React, { useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import { getTaxConsultant } from '../../utility/utility'

import RadioInputField from '../../../components/Inputs/RadioInputField/RadioInputField'

import './ClientFormOptionsView.scss'

interface Props {
  data: {
    features: {
      sepa: boolean
    }
  }
}

const ClientFormOptionsView = (props: Props) => {
  const [sepaSavedHint, setSepaSavedHint] = useState(false)

  const handleSepaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'Anzeigen' ? true : false
    const ref = firebase
      .database()
      .ref(`taxConsultant/${getTaxConsultant()}/features`)

    if (value) {
      return ref
        .update({ sepa: true })
        .then(() => {
          setSepaSavedHint(true)

          setTimeout(() => {
            setSepaSavedHint(false)
          }, 1000)
        })
        .catch((error) => alert(error))
    }
    return ref
      .update({ sepa: false })
      .then(() => {
        setSepaSavedHint(true)

        setTimeout(() => {
          setSepaSavedHint(false)
        }, 1000)
      })
      .catch((error) => alert(error))
  }

  return (
    <div className="client-form-options-details">
      <header>
        <h4>Formularoptionen</h4>
        <p>
          Welche Felder sollen bei der Erfassung von neuen Mandant:Innen
          angezeigt werden?
        </p>
      </header>

      <main>
        <section>
          <div className="input-container">
            <RadioInputField
              label="Abfrage zur Erteilung eines SEPA-Lastschrift-Mandates"
              name="sepa"
              radioOptions={['Nicht anzeigen', 'Anzeigen']}
              defaultChecked={props.data.features.sepa ? 1 : 0}
              onChange={(e) => handleSepaChange(e)}
              key="sepa"
            />
            {sepaSavedHint ? (
              <span className="saved-hint">Gespeichert</span>
            ) : null}
          </div>
          <small>
            Mit diesem Feld können MandantInnen in die Zahlung via
            SEPA-Lastschrift einwilligen. Das Formular für das entsprechende
            Mandat wird der PDF-Dokumentation beigefügt.
          </small>
        </section>
      </main>
    </div>
  )
}

export default ClientFormOptionsView
