import React, { ChangeEvent } from 'react'

import FormContainer from 'components/ui-components/forms/FormContainer/FormContainer'
import FormHeader from 'components/ui-components/forms/FormHeader/FormHeader'
import FormBody from 'components/ui-components/forms/FormBody/FormBody'
import FormFooter from 'components/ui-components/forms/FormFooter/FormFooter'
import RadioInputField from 'components/Inputs/RadioInputField/RadioInputField'
import PrependNumberInput from 'components/ui-components/inputs/PrependNumberInput/PrependNumberInput'
import Label from 'components/ui-components/labels/Label/Label'
import HelperButton from 'components/ui-components/buttons/HelperButton/HelperButton'
import FormHint from 'components/ui-components/hints/FormHint'

import './CapitalAccumulationBenefits.scss'
import { STAFF_FORMS } from 'constants/Formulars'

interface Props {
  formularName: STAFF_FORMS
  sallaryValue: string
  capitalAccumulation: string
  monthlyProportion: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: ChangeEvent<HTMLInputElement>) => void
  onSecondaryButtonClick: (e: ChangeEvent<HTMLInputElement>) => void
}

const CapitalAccumulationBenefits = (props: Props) => {
  const calculateMaxProportion = () => {
    if (
      props.formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      props.formularName === STAFF_FORMS.FOREIGN_EMPLOYEE ||
      props.formularName === STAFF_FORMS.STUDENT
    ) {
      const maxMonthlyProportion = 40
      return maxMonthlyProportion
    }

    if (props.formularName === STAFF_FORMS.APPRENTICESHIP) {
      const maxMonthlyProportion = 40
      return maxMonthlyProportion
    }

    if (
      props.formularName === STAFF_FORMS.STUDENT_MINIJOB ||
      props.formularName === STAFF_FORMS.MINIJOB
    ) {
      const sallaryValue = Number(props.sallaryValue)
      const maxSallaryValue = 450
      const maxMonthlyProportion = 40

      if (sallaryValue >= maxSallaryValue - maxMonthlyProportion) {
        return Number((maxSallaryValue - sallaryValue).toFixed(2))
      }
      return maxMonthlyProportion
    }

    return 0
  }

  const setLabelText = () => {
    if (props.formularName === STAFF_FORMS.APPRENTICESHIP) {
      return 'Erhält Dein Azubi Vermögenswirksame Leistungen?'
    }
    return 'Erhält der Arbeitnehmer Vermögenswirksame Leistungen?'
  }

  return (
    <FormContainer
      className={'capital-accumulation-form'}
      onSubmit={props.onSubmit}
    >
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Vermögenswirksame Leistungen'} />
      <FormBody>
        <RadioInputField
          label={setLabelText()}
          name="capitalAccumulation"
          radioOptions={['Nein', 'Ja']}
          autoFocus
          required={true}
          defaultChecked={props.capitalAccumulation === 'Nein' ? 0 : 1}
          onChange={props.onChange}
        />
        {props.capitalAccumulation === 'Ja' ? (
          <React.Fragment>
            {props.sallaryValue === '450' ? (
              <FormHint hintHeadline="Achtung:">
                <p>
                  Wenn Du deinem/r neuen Arbeitnehmer:in bereits die vollen 450€
                  auszahlst, kannst Du ihm/ihr leider keine Vermögenswirsame
                  Leistungen anrechnen. Das monatliche Gehalt und der monatliche
                  Anteil an Vermögenswirksamen Leistungen darf insgesammt nicht
                  450€ übersteigen.
                </p>
                <p>
                  Passe entweder das Gehalt an, oder wähle <b>Nein</b> aus um
                  weiter machen zu können.
                </p>
              </FormHint>
            ) : (
              <div className="preprend-number-input-field">
                <Label>Monatlicher Arbeitgeberanteil</Label>
                <PrependNumberInput
                  name="monthlyProportion"
                  defaultValue={props.monthlyProportion}
                  placeholder={calculateMaxProportion().toString()}
                  prependText="€"
                  onChange={props.onChange}
                  required
                  max={Number(calculateMaxProportion())}
                  min={0.0}
                  step={0.01}
                />
              </div>
            )}
          </React.Fragment>
        ) : null}
      </FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
        onClick={
          props.capitalAccumulation === 'Ja' && props.sallaryValue === '450'
            ? (e) => {
                e.preventDefault()
                e.target.blur()
              }
            : null
        }
      />
    </FormContainer>
  )
}

export default CapitalAccumulationBenefits
