const checkLocalStorageFor = (
  formularName: string,
  key: string,
  initialValue: string | number | boolean
): any => {
  if (localStorage.formularName === formularName) {
    if (typeof initialValue === 'string') {
      return localStorage.getItem(key)
        ? localStorage.getItem(key)?.toString()
        : initialValue
    }
    if (typeof initialValue === 'number') {
      return localStorage.getItem(key)
        ? Number(localStorage.getItem(key))
        : initialValue
    }
    if (typeof initialValue === 'boolean') {
      if (localStorage.getItem(key) === 'true') {
        return true
      }
      if (localStorage.getItem(key) === 'false') {
        return false
      }
      return initialValue
    }
  }
  return initialValue
}

export default checkLocalStorageFor
