import React from 'react'
import Label from '../../ui-components/labels/Label/Label'
import TextInput from '../../ui-components/inputs/TextInput/TextInput'
import './TextInputField.scss'

const TextInputField = props => (
  <div className={'text-input-field'}>
    <Label>{props.label}</Label>
    <TextInput
      name={props.name}
      placeholder={props.placeholder}
      required={props.required}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      disabled={props.disabled}
      maxLength={props.maxLength}
      minLength={props.minLength}
      autoFocus={props.autoFocus}
      pattern={props.pattern}
      title={props.title}
      autoComplete={props.autoComplete}
    />
  </div>
)

export default TextInputField
