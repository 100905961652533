import React from 'react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as CaseIcon } from '../../../static/icons/16x16/account-icon-16.svg'
import { ReactComponent as FileIcon } from '../../../static/icons/16x16/file-icon-16.svg'
import { ReactComponent as IntegrationsIcon } from '../../../static/icons/16x16/integrations-icon-16.svg'
import { ReactComponent as PenIcon } from '../../../static/icons/16x16/pen-icon-16.svg'
import { ReactComponent as ListIcon } from '../../../static/icons/16x16/list-icon-16.svg'
import { ReactComponent as MapIcon } from '../../../static/icons/16x16/map-icon-16.svg'

import './DashboardSubNavigation.scss'

interface Props {
  contracts: {
    client: { contract: boolean }
  }
}

const DashboardSubNavigation = (props: Props) => {
  return (
    <nav className="dashboard-sub-navigation">
      <ul>
        <label>Allgemein</label>
        <li>
          <NavLink
            exact
            to="/dashboard/settings/account"
            activeClassName="active"
          >
            <CaseIcon />
            Account
          </NavLink>
        </li>

        <li>
          <NavLink
            exact
            to="/dashboard/settings/contact-information"
            activeClassName="active"
          >
            <MapIcon />
            Unternehmensangaben
          </NavLink>
        </li>

        <li>
          <NavLink
            exact
            to="/dashboard/settings/customization"
            activeClassName="active"
          >
            <PenIcon />
            Individualisierung
          </NavLink>
        </li>
      </ul>

      <ul>
        <label>Fastdocs Staff</label>
        <li>
          <NavLink
            exact
            to="/dashboard/settings/fastdocs-staff/import"
            activeClassName="active"
          >
            <FileIcon />
            Import
          </NavLink>
        </li>

        <li>
          <NavLink
            exact
            to="/dashboard/settings/fastdocs-staff/form-options"
            activeClassName="active"
          >
            <ListIcon />
            Formularoptionen
          </NavLink>
        </li>

        <li>
          <NavLink
            exact
            to="/dashboard/settings/integrations"
            activeClassName="active"
          >
            <IntegrationsIcon />
            Integrationen
          </NavLink>
        </li>
      </ul>

      {props.contracts.client.contract ? (
        <ul>
          <label>Fastdocs Client</label>
          <li>
            <NavLink
              exact
              to="/dashboard/settings/fastdocs-client/form-options/"
              activeClassName="active"
            >
              <ListIcon />
              Formularoptionen
            </NavLink>
          </li>
        </ul>
      ) : null}
    </nav>
  )
}

export default DashboardSubNavigation
