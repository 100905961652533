import React from 'react'
import PropTypes from 'prop-types'
import Label from '../../ui-components/labels/Label/Label'
import Checkbox from '../../ui-components/inputs/Checkbox/Checkbox'
import './CheckboxField.scss'

const CheckboxField = (props) => (
  <div className={'checkbox-field'}>
    <Label>
      <Checkbox
        name={props.name}
        defaultChecked={props.defaultChecked}
        autoFocus={props.autoFocus}
        required={props.required}
        onClick={props.onClick}
      />
      <span>{props.children || props.label}</span>
    </Label>
  </div>
)

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.node,
  required: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onClick: PropTypes.func,
}

export default CheckboxField
