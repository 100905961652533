import React, { Fragment } from 'react'

import IconButton from '../../ui-components/buttons/IconButton/IconButton'
import { ReactComponent as CrossIcon } from '../../../static/CrossIcon.svg'
import { ReactComponent as AlertIcon } from '../../../static/icons/48x48/alert-triangle-48.svg'

import EmailInputField from '../../Inputs/EmailInputField/EmailInputField'
import TextInputField from '../../Inputs/TextInputField/TextInputField'

import './CreateClerkDialog.scss'

interface Props {
  name: string
  email: string
  containerAnimation: string
  contentAnimation: string
  limitation: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleIconButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleSaveClerkClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const CreateClerkDialog = (props: Props) => {
  const {
    name,
    email,
    containerAnimation,
    contentAnimation,
    limitation,
    handleIconButtonClick,
    handleSaveClerkClick,
    onChange,
  } = props

  const renderButtons = () => {
    return (
      <Fragment>
        <button className="secondary" onClick={handleSaveClerkClick}>
          Speichern
        </button>
      </Fragment>
    )
  }

  const renderDialogContent = () => {
    if (limitation) {
      return (
        <Fragment>
          <div className="create-clerk-details-container--header">
            <p></p>
            <IconButton onClick={handleIconButtonClick}>
              <CrossIcon />
            </IconButton>
          </div>

          <div className="create-clerk-details-container--limitation-alert">
            <main>
              <AlertIcon />
              <h5>Das Limit für SachbearbeiterInnen ist erreicht.</h5>
              <a
                className="button primary"
                href="mailto:sales@fastdocs.de?subject=Limitierung für SachbearbeiterInnen – Fastdocs Staff Vertrag anpassen&body=Hallo liebes Sales-Team,%0D%0A%0D%0A wir würden gerne weitere SachbearbeiterInnen hinterlegen."
                target="_blank"
                rel="noopener noreferrer"
              >
                Mit dem Sales-Team kontakt aufnehmen
              </a>
              <a
                className="button ghost"
                href="https://fastdocs.de/fastdocs-staff-pricing.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mehr erfahren
              </a>
            </main>
          </div>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <div className="create-clerk-details-container--header">
          <p>SachbearbeiterIn anlegen</p>
          <IconButton onClick={handleIconButtonClick}>
            <CrossIcon />
          </IconButton>
        </div>

        <div className="create-clerk-details-container--details">
          <main>
            <TextInputField
              label="Name"
              defaultValue={name}
              placeholder="Alex Meier"
              required
              name="name"
              onChange={onChange}
            />

            <EmailInputField
              label="E-Mail-Adresse"
              defaultValue={email}
              placeholder="alex@meine-kanzlei.de"
              required
              name="email"
              onChange={onChange}
            />
          </main>
        </div>

        <div className="create-clerk-details-container--options">
          {renderButtons()}
        </div>
      </Fragment>
    )
  }

  return (
    <div className={`create-clerk-details-container ${containerAnimation}`}>
      <div className="create-clerk-details-container--wrapper">
        <div
          className={`create-clerk-details-container--content ${contentAnimation}`}
        >
          {renderDialogContent()}
        </div>
      </div>
    </div>
  )
}

export default CreateClerkDialog
