import React, { useEffect, useState } from 'react'
import './OptionField.scss'

interface OptionFieldProps {
  name: string
  label: string
  defaultChecked: boolean | undefined
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

type State = 'active' | ''

const OptionField = ({
  label,
  defaultChecked,
  name,
  onChange,
}: OptionFieldProps) => {
  const [state, setState] = useState<State>('')

  useEffect(() => {
    setState(defaultChecked ? 'active' : '')
  }, [defaultChecked])

  return (
    <label className={`option-field ${state}`}>
      <div className="option-field-container">
        <input
          type="checkbox"
          defaultChecked={defaultChecked}
          onChange={onChange}
          name={name}
        />
        <div className={`checkmark ${state}`}>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
              d="M10 3L4.5 8.5L2 6"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <span>{label}</span>
    </label>
  )
}

export default OptionField
