import React, { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/auth'

import EmailInputField from '../../../components/Inputs/EmailInputField/EmailInputField'

import { getTaxConsultant } from '../../utility/utility'

import FormHint from '../../../components/ui-components/hints/FormHint'

import './DashboardLogin.scss'
import { Redirect } from 'react-router-dom'
import CheckboxField from '../../../components/Inputs/CheckboxField/CheckboxField'
import LoadingIndicator from '../../../components/ui-components/LoadingIndicator/LoadingIndicator'

const DashboardLogin = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [formStep, setFormStep] = useState(0)
  const [formValues, setFormValues] = useState({
    taxConsultant: getTaxConsultant(),
    email: '',
    errorMessage: false,
  })
  const [persistLogin, setPersistLogin] = useState(false)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(() => {
      if (firebase.auth().currentUser?.isAnonymous) {
        setIsLoading(false)
        return firebase.auth().signOut()
      }
      if (firebase.auth().currentUser) {
        setIsLoading(false)
        return setFormStep(2)
      }

      return setIsLoading(false)
    })

    // eslint-disable-next-line
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    return setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const ref = firebase
      .database()
      .ref('taxConsultant/' + formValues.taxConsultant)

    const persistence = persistLogin
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION

    if (process.env.NODE_ENV === 'development') {
      try {
        const actionCodeSettings = {
          url: 'http://localhost:3000/dashboard/staff',
          handleCodeInApp: true,
        }

        const email = await ref
          .child('email')
          .once('value')
          .then((value) => value.exportVal())

        if (formValues.email.toLowerCase() === email.toLowerCase()) {
          setFormStep(1)

          firebase
            .auth()
            .setPersistence(persistence)
            .then(() => {
              firebase
                .auth()
                .sendSignInLinkToEmail(formValues.email, actionCodeSettings)
                .then(() => {
                  setFormStep(1)
                  window.localStorage.setItem(
                    'emailForSignIn',
                    formValues.email
                  )
                  window.localStorage.setItem(
                    'authPersistence',
                    persistLogin ? 'true' : 'false'
                  )
                })
                .catch((error) => {
                  setFormValues({ ...formValues, errorMessage: true })
                  console.log(error)
                })
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          setFormValues({ ...formValues, errorMessage: true })
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      try {
        const email = await ref
          .child('email')
          .once('value')
          .then((value) => value.exportVal())

        if (formValues.email.toLowerCase() === email.toLowerCase()) {
          setFormStep(1)

          firebase
            .auth()
            .setPersistence(persistence)
            .then(() => {
              window.localStorage.setItem('emailForSignIn', formValues.email)
              window.localStorage.setItem(
                'authPersistence',
                persistLogin ? 'true' : 'false'
              )
            })
            .then(() => {
              ref
                .child('requestLoginVerification')
                .once('value')
                .then(async (snapshot) => {
                  if (snapshot.exists()) {
                    return firebase
                      .database()
                      .ref('taxConsultant/' + formValues.taxConsultant)
                      .update({ requestLoginVerification: true })
                  } else {
                    await firebase
                      .database()
                      .ref('taxConsultant/' + formValues.taxConsultant)
                      .update({ requestLoginVerification: false })

                    firebase
                      .database()
                      .ref('taxConsultant/' + formValues.taxConsultant)
                      .update({ requestLoginVerification: true })
                  }
                })
                .catch((error) => {
                  console.error(error)
                })
            })
            .catch((error) => {
              console.error(error)
            })
        } else {
          setFormValues({ ...formValues, errorMessage: true })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const renderFormSteps = () => {
    switch (formStep) {
      case 0:
        return (
          <form className="card-dark content" onSubmit={(e) => handleSubmit(e)}>
            <header>
              <h4>Dashboard Login</h4>
              <p>
                Nutze für den Login bitte die Haupt-Adresse des Accounts{' '}
                <b>{formValues.taxConsultant}</b>.
              </p>
            </header>

            {isLoading ? (
              <LoadingIndicator size="small" />
            ) : (
              <>
                <main>
                  <EmailInputField
                    label="E-Mail"
                    placeholder="info@meine-kanzlei.de"
                    name="email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e)
                    }
                    required
                    defaultValue={formValues.email}
                  />
                  <CheckboxField
                    name="persistLogin"
                    label="Angemeldet bleiben"
                    defaultChecked={persistLogin}
                    onClick={() => setPersistLogin(!persistLogin)}
                  />
                  {formValues.errorMessage && (
                    <FormHint hintHeadline="Falsche E-Mail-Adresse">
                      <p>
                        Die angegebene E-Mail-Adresse stimmt nicht mit der
                        hinterlegten Adresse für den Account überein.
                      </p>
                      <p>
                        Probier es bitte noch ein mal oder wende dich an unseren{' '}
                        <a
                          href={`mailto:support@fastdocs.de?subject=Dashboard Login — ${formValues.taxConsultant}`}
                        >
                          Support
                        </a>
                        .
                      </p>
                    </FormHint>
                  )}
                </main>

                <footer>
                  <button
                    disabled={formValues.email.length > 0 ? false : true}
                    className="primary full-width"
                  >
                    Magic-Login-Link senden
                  </button>
                </footer>
              </>
            )}
          </form>
        )
      case 1:
        return (
          <form className="card-dark content">
            <header>
              <h4>Dashboard Login</h4>
            </header>
            <main>
              <p>
                Wir haben dir Deinen Magic-Login-Link an{' '}
                <b>{formValues.email}</b> gesendet.
              </p>
              <p>
                Mit einem Klick auf den Button in der E-Mail wirst Du dann
                automatisch in das Dashboard eingeloggt.
              </p>
            </main>
          </form>
        )
      case 2:
        return <Redirect exact to="/dashboard/staff" />
    }
  }
  return <div className="dashboard-login">{renderFormSteps()}</div>
}

export default DashboardLogin
