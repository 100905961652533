import React from 'react'
import { ReactComponent as ChevronIcon } from 'static/ChevronIcon.svg'

import './TextInputWithOption.scss'

interface Props {
  type: 'number' | 'text'
  optionName: string
  options: string[]
  name: string
  placeholder: string
  optionDefaultValue: string
  required?: boolean
  defaultValue: string
  maxLength?: number
  step?: number
  pattern?: string
  title?: string
  max?: string
  min?: string
  disabled?: boolean
  autoFocus?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onOptionChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const TextInputWithOption = (props: Props) => (
  <div className="text-input-with-option">
    <div className="select-container">
      <select
        name={props.optionName}
        onChange={props.onOptionChange}
        defaultValue={props.optionDefaultValue}
      >
        {props.options.map((option: string, key: number) => (
          <option key={key} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className={'icon-container'}>
        <ChevronIcon />
      </div>
    </div>
    <input
      type={props.type}
      name={props.name}
      placeholder={props.placeholder}
      required={props.required}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      disabled={props.disabled}
      maxLength={props.maxLength}
      autoFocus={props.autoFocus}
      title={props.title}
      max={props.max}
      min={props.min}
      step={props.step}
      pattern={props.pattern}
    />
  </div>
)

export default TextInputWithOption
