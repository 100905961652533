const employeeFormValues = {
  bankAccountHolder: 'Jan Brunner',
  bea: true,
  beginningStatus: 'Student/in',
  birthCountry: 'Deutschland',
  birthday: '29',
  birthmonth: '04',
  birthyear: '1980',
  birthName: '',
  city: 'Solingen',
  graduation: 'Mittlere Reife/gleichwertiger Abschluss',
  houseNumber: '40a',
  iban: 'DE54342500000001623321',
  taxIdNumber: '86149736056',
  insuranceCompanyName: 'BARMER',
  mariatialStatus: 'Ledig',
  nationality: 'Deutschland',
  pensionInsuranceNumber: '',
  placeOfBirth: 'Solingen',
  sex: 'Männlich',
  socialSecurityNumber: '60290480O505',
  street: 'Am Zentral',
  zipCode: '42657',
}

export default employeeFormValues
