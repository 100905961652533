import React from 'react'
import PropTypes from 'prop-types'

const Input = (props) => (
  <input
    type={'text'}
    name={props.name}
    placeholder={props.placeholder}
    required={props.required}
    defaultValue={props.defaultValue}
    onChange={props.onChange}
    disabled={props.disabled}
    maxLength={props.maxLength}
    minLength={props.minLength}
    autoFocus={props.autoFocus}
    pattern={props.pattern}
    title={props.title}
    autoComplete={props.autoComplete}
  />
)

Input.propTypes = {
  /** Value of the input field */
  defaultValue: PropTypes.string,
  /** Sets a field into a disabled state */
  disabled: PropTypes.bool,
  /** Sets the name of the input */
  name: PropTypes.string.isRequired,
  /** Listen for onChange events */
  onChange: PropTypes.func,
  /** Sets the placeholder of the input */
  placeholder: PropTypes.string.isRequired,
  /** If a TextInput needs to be filled for further actions, the required statement needs to be set to true */
  required: PropTypes.bool,
  /** Specifies the size (in characters) for the input field */
  maxLength: PropTypes.number,
  /** Specifies the minimal size (in characters) for the input field */
  minLength: PropTypes.number,
  /** Specifies that the input field should automatically get focus when the page loads */
  autoFocus: PropTypes.bool,
  /** Specifies a regular expression that the <input> element's value is checked against */
  pattern: PropTypes.any,
  /** Sets a title used as description */
  title: PropTypes.string,
}

export default Input
