import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './SuggestTextInput.scss'

const propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  autoFocus: PropTypes.bool,
  pattern: PropTypes.any,
  title: PropTypes.string,
  data: PropTypes.array,
}

class SuggestTextInput extends PureComponent {
  filteredList() {
    const filteredList = this.props.data.filter((value) => {
      return value.toLowerCase().indexOf(this.props.value.toLowerCase()) >= 0
    })

    return filteredList
  }

  renderSuggestions() {
    return this.filteredList().map((item, i) => {
      return (
        <li key={i}>
          <button
            name={this.props.name}
            onClick={this.props.onSuggestionClick}
            value={item}
          >
            {item}
          </button>
        </li>
      )
    })
  }

  showList() {
    if (
      this.props.value === this.filteredList()[0] ||
      this.renderSuggestions().length === 0
    ) {
      return null
    }
    if (this.props.value.length !== 0) {
      return <ul>{this.renderSuggestions()}</ul>
    }
  }

  render() {
    return (
      <div className="suggest-text-input">
        <input
          type={'text'}
          name={this.props.name}
          placeholder={this.props.placeholder}
          required={this.props.required}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          maxLength={this.props.maxLength}
          minLength={this.props.minLength}
          autoFocus={this.props.autoFocus}
          pattern={this.props.pattern}
          title={this.props.title}
          value={this.props.value}
        />
        {this.showList()}
      </div>
    )
  }
}

SuggestTextInput.propTypes = propTypes

export default SuggestTextInput
