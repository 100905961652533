import React, { PureComponent } from 'react'

import ActivityKeyAutosuggestTextInputField from '../../Inputs/ActivityKeyAutosuggestTextInputField/ActivityKeyAutosuggestTextInputField'
import activityKeyList from './activityKeyList.json'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import LoadingIndicator from 'components/ui-components/LoadingIndicator/LoadingIndicator'
import SelectField from '../../Selects/SelectField/SelectField'
import TextInputField from '../../Inputs/TextInputField/TextInputField'

import { STAFF_FORMS } from '../../../constants/Formulars'

import './ConsultantDataForm.scss'

interface Props {
  accountHasProFeatures: boolean
  activityKey: string
  autosuggestSearchValue: string
  clientNumber: string
  clientStaffNumber: string
  company: string
  consultantNumber: string
  costCenter: string
  firstname: string
  formId: string | null
  formularName: string
  importFile: string
  importOption: string
  jobTitle: string
  lastname: string
  staffNumber: string
  taxConsultantImportSystem: string
  employedAs?: string
  employeeType?: string
  employmentLocation?: string
  isLoading?: boolean
  operationNumber?: string
  pensionInsuranceRequest?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClearButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onOptionClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void
  updateAutosuggestSearchValue: (e: React.ChangeEvent<HTMLInputElement>) => void
}

class ConsultantDataForm extends PureComponent<Props> {
  disableSubmitButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      this.props.autosuggestSearchValue.length !== 0 &&
      this.props.activityKey.length === 0
    ) {
      e.preventDefault()
      e.target.blur()
    } else {
      return null
    }
  }

  renderProFeatures = () => {
    if (this.props.accountHasProFeatures) {
      return (
        <React.Fragment>
          <SelectField
            label="Importdatei"
            name="importFile"
            onChange={this.props.onChange}
            options={['Lodas', 'Lohn & Gehalt']}
            noDefault
            defaultValue={this.props.importFile}
            required
          />
          {this.props.importFile === 'Lohn & Gehalt' ? (
            <SelectField
              label="Importoption"
              name="importOption"
              onChange={this.props.onChange}
              options={[
                'Mitarbeiter neu anlegen',
                'Mitarbeiter aktualisieren (Sofortmeldung bereits erfolgt)',
              ]}
              noDefault
              defaultValue={this.props.importOption}
              required
            />
          ) : null}
        </React.Fragment>
      )
    }
    if (this.props.taxConsultantImportSystem === 'lug') {
      return (
        <SelectField
          label="Importoption"
          name="importOption"
          onChange={this.props.onChange}
          options={[
            'Mitarbeiter neu anlegen',
            'Mitarbeiter aktualisieren (Sofortmeldung bereits erfolgt)',
          ]}
          noDefault
          defaultValue={this.props.importOption}
          required
        />
      )
    }
    return null
  }

  renderClientAndEmployeeInformations = () => {
    if (this.props.formularName === STAFF_FORMS.IMMEDIATE_REPORT) {
      return (
        <React.Fragment>
          <div className="meta-container">
            <label>Unternehmen</label>
            <p>{this.props.company}</p>
          </div>

          <div className="meta-container">
            <label>Vorname</label>
            <p>{this.props.firstname}</p>
          </div>

          <div className="meta-container">
            <label>Nachname</label>
            <p>{this.props.lastname}</p>
          </div>

          {this.props.costCenter && (
            <div className="meta-container">
              <label>Kostenstelle</label>
              <p>{this.props.costCenter}</p>
            </div>
          )}

          {this.props.clientStaffNumber && (
            <div className="meta-container">
              <label>Personalnummer</label>
              <p>{this.props.clientStaffNumber}</p>
            </div>
          )}

          {this.props.employeeType && (
            <div className="meta-container">
              <label>Mitarbeitertyp</label>
              <p>{this.props.employeeType}</p>
            </div>
          )}

          <div className="meta-container">
            <label>Formular-ID</label>
            <p>{this.props.formId}</p>
          </div>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <div className="meta-container">
          <label>Unternehmen</label>
          <p>{this.props.company}</p>
        </div>

        <div className="meta-container">
          <label>Vorname</label>
          <p>{this.props.firstname}</p>
        </div>

        <div className="meta-container">
          <label>Nachname</label>
          <p>{this.props.lastname}</p>
        </div>

        <div className="meta-container">
          <label>Angestellt als</label>
          <p>{this.props.employedAs}</p>
        </div>

        <div className="meta-container">
          <label>Beschäftigungsort</label>
          <p>{this.props.employmentLocation}</p>
        </div>

        {this.props.costCenter && this.props.costCenter.length !== 0 ? (
          <div className="meta-container">
            <label>Kostenstelle</label>
            <p>{this.props.costCenter}</p>
          </div>
        ) : null}

        {this.props.clientStaffNumber &&
        this.props.clientStaffNumber.length !== 0 ? (
          <div className="meta-container">
            <label>Personalnummer</label>
            <p>{this.props.clientStaffNumber}</p>
          </div>
        ) : null}

        <div className="meta-container">
          <label>Formular-ID</label>
          <p>{this.props.formId}</p>
        </div>
      </React.Fragment>
    )
  }

  renderOperationNumberField = () => {
    if (
      this.props.formularName === STAFF_FORMS.MINIJOB ||
      this.props.formularName === STAFF_FORMS.STUDENT_MINIJOB
    ) {
      return (
        <TextInputField
          label={'Betriebsnummer'}
          name={'operationNumber'}
          type={'text'}
          placeholder={'95471'}
          defaultValue={this.props.operationNumber}
          onChange={this.props.onChange}
          autoFocus
        />
      )
    }
  }

  render() {
    return (
      <FormContainer
        className={'consultant-data-form'}
        onSubmit={this.props.onSubmit}
      >
        <div className="form-content">
          <div className="client-information">
            <FormHeader headlineText="Mandanteninfos" />
            {this.props.isLoading && (
              <FormBody>
                <LoadingIndicator size="mini" />
              </FormBody>
            )}
            {!this.props.isLoading && (
              <FormBody>{this.renderClientAndEmployeeInformations()}</FormBody>
            )}
          </div>
          <div className="form-fields">
            <FormHeader headlineText="Mandantendaten" />

            {this.props.isLoading && (
              <FormBody>
                <LoadingIndicator size="mini" />
              </FormBody>
            )}
            {!this.props.isLoading && (
              <>
                <FormBody>
                  {this.renderOperationNumberField()}

                  <TextInputField
                    label={'Mandantennummer'}
                    name={'clientNumber'}
                    type={'text'}
                    placeholder={'34857'}
                    required={true}
                    defaultValue={this.props.clientNumber}
                    onChange={this.props.onChange}
                    autoFocus
                  />

                  <TextInputField
                    label={'Personalnummer'}
                    name={'staffNumber'}
                    type={'text'}
                    placeholder={'007'}
                    required={true}
                    defaultValue={this.props.staffNumber}
                    onChange={this.props.onChange}
                  />

                  <TextInputField
                    label={'Beraternummer'}
                    name={'consultantNumber'}
                    type={'text'}
                    placeholder={'44987'}
                    required={true}
                    defaultValue={this.props.consultantNumber}
                    onChange={this.props.onChange}
                  />

                  {this.props.formularName !== STAFF_FORMS.IMMEDIATE_REPORT && (
                    <ActivityKeyAutosuggestTextInputField
                      label="Tätigkeitsschlüssel"
                      name="activityKey"
                      required
                      placeholder="Suchen"
                      autosuggestSearchValue={this.props.autosuggestSearchValue}
                      activityKey={this.props.activityKey}
                      jobTitle={this.props.jobTitle}
                      updateAutosuggestSearchValue={
                        this.props.updateAutosuggestSearchValue
                      }
                      onClearButtonClick={this.props.onClearButtonClick}
                      onOptionClick={this.props.onOptionClick}
                      listData={activityKeyList}
                    />
                  )}

                  {this.renderProFeatures()}
                </FormBody>

                <FormFooter
                  primaryButtonText={'Fertigstellen'}
                  onClick={this.disableSubmitButton}
                />
              </>
            )}
          </div>
        </div>
      </FormContainer>
    )
  }
}

export default ConsultantDataForm
