const data = {
  company: 'Wunderbox GmbH',
  employeeName: 'Jan Brunner',
  dayOfUse: '1',
  monthOfUse: '1',
  yearOfUse: '2021',
  vehicleModel: 'BMW 330i',
  vehicleRegistrationNumber: 'ME:FD:1337',
  grossListPrice: '70000',
  typeOfVehicle: 'Verbrenner',
}
export default data
