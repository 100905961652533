import React from 'react'
import { Route } from 'react-router'
import ProductSelectionView from '../views/FormSelectionView/ProductSelectionView/ProductSelectionView'
import StaffFormsSelectionView from '../views/FormSelectionView/StaffFormsSelectionView/StaffFormsSelectionView'
import ClientFormsSelectionView from '../views/FormSelectionView/ClientFormsSelectionView/ClientFormsSelectionView'

interface Props {
  contracts: {
    staff: {
      contract: boolean
      contractOption: string
    }
    client: {
      contract: boolean
    }
  }
}

export default function IndexRoutes(props: Props) {
  const { contract: clientContract } = props.contracts.client
  const { contract: staffContract } = props.contracts.staff

  function RenderProductSelectionView() {
    if (clientContract && staffContract) return <ProductSelectionView />
    if (clientContract) return <ClientFormsSelectionView />
    if (staffContract) return <StaffFormsSelectionView />
    return <StaffFormsSelectionView />
  }

  return (
    <>
      <Route exact path="/">
        {RenderProductSelectionView()}
      </Route>
      <Route path="/staff" component={StaffFormsSelectionView} />
      <Route path="/client" component={ClientFormsSelectionView} />
    </>
  )
}
