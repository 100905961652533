import React from 'react'
import PropTypes from 'prop-types'

import IconButton from '../../../ui-components/buttons/IconButton/IconButton'
import { ReactComponent as CrossIcon } from 'static/CrossIcon.svg'

import './HelpOverBase.scss'

const propTypes = {
  className: PropTypes.string.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  formId: PropTypes.string.isRequired,
}

class HelpOverBase extends React.Component {
  render() {
    return (
      <div className={`help-over-base ${this.props.className}`}>
        <header>
          <h5>Hilfe</h5>
          <IconButton onClick={this.props.onCloseButtonClick}>
            <CrossIcon />
          </IconButton>
        </header>
        <main>
          <article>
            {this.props.children}

            <div className="bug-hint">
              <a
                href={`mailto:support@fastdocs.de?subject=Formular-ID: ${this.props.formId}`}
              >
                Ein Problem melden
              </a>
            </div>
          </article>
        </main>
      </div>
    )
  }
}

HelpOverBase.propTypes = propTypes

export default HelpOverBase
