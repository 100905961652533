const clientFormValues = {
  birthday: '29',
  birthmonth: '04',
  birthyear: '1980',
  company: '[TEST] Wunderbox GmbH',
  employeeType: 'Minijobber bis 450€',
  employmentLocation: 'Haan',
  entryday: '01',
  entrymonth: (new Date().getMonth() + 2).toString(),
  entryyear: new Date().getFullYear().toString(),
  firstname: 'Eduart',
  lastname: 'Robotnik',
  nationality: 'Deutschland',
  sex: 'Männlich',
  socialSecurityNumber: '60290480O505',
  otherInformation:
    '- Arbeitet in der Abteilung X\n- Wird nach dem 20. ausgezahlt',
}

export default clientFormValues
