import React from 'react'
import './Textarea.scss'

interface Props {
  name: string
  defaultValue: string
  placeholder?: string
  rows?: number
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const Textarea = (props: Props) => (
  <textarea
    name={props.name}
    placeholder={props.placeholder}
    rows={props.rows}
    onChange={props.onChange}
    defaultValue={props.defaultValue}
  />
)

export default Textarea
