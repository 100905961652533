import React from 'react'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'

import './AdditionalBenefitsForm.scss'
import OptionField from '../../Inputs/OptionField/OptionField'

interface AdditionalBenefitsFormProps {
  firstname: string
  lastname: string
  sallaryIncreaseAfterTrialPeriod: boolean
  companyCar: boolean
  companyPensionPlan: boolean
  jobTicket: boolean
  travelAllowance: boolean
  taxFreeBenefit: boolean
  internetGrant: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSecondaryButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const AdditionalBenefitsForm = (props: AdditionalBenefitsFormProps) => {
  const name = () => {
    if (props.firstname.length === 0 || props.lastname.length === 0) {
      return 'Die neue Arbeitskraft erhält zudem:'
    }
    return `${props.firstname} ${props.lastname} erhält zudem:`
  }
  return (
    <FormContainer
      className={'additional-benefits-form'}
      onSubmit={props.onSubmit}
    >
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Zusätzliche Absprachen'} />
      <FormBody>
        <h5>{name()}</h5>

        <OptionField
          label="Eine Gehaltserhöhung nach der Probezeit"
          name="sallaryIncreaseAfterTrialPeriod"
          defaultChecked={props.sallaryIncreaseAfterTrialPeriod}
          onChange={props.onChange}
        />

        <OptionField
          label="Einen Firmenwagen"
          name="companyCar"
          defaultChecked={props.companyCar}
          onChange={props.onChange}
        />

        <OptionField
          label="Eine Betriebliche Altersvorsorge"
          name="companyPensionPlan"
          defaultChecked={props.companyPensionPlan}
          onChange={props.onChange}
        />

        <OptionField
          label="Ein Job-Ticket"
          name="jobTicket"
          defaultChecked={props.jobTicket}
          onChange={props.onChange}
        />

        <OptionField
          label="Einen Fahrtkostenzuschuss"
          name="travelAllowance"
          defaultChecked={props.travelAllowance}
          onChange={props.onChange}
        />

        <OptionField
          label="Einen Steuerfreien Sachbezug (50€)"
          name="taxFreeBenefit"
          defaultChecked={props.taxFreeBenefit}
          onChange={props.onChange}
        />

        <OptionField
          label="Einen Internetzuschuss"
          name="internetGrant"
          defaultChecked={props.internetGrant}
          onChange={props.onChange}
        />
      </FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
      />
    </FormContainer>
  )
}

export default AdditionalBenefitsForm
