const fiveFapi = (taxConsultant: string) => {
  if (taxConsultant === 'dev-test-account') {
    return {
      accountId: '33ebf807-2d4a-47ec-8950-0aa620009b84',
      apiKey: '4MNodH2zVfF2ckwOWvnF1ekTYjQCAK1gxWxrCXxAD4o',
      url: 'https://api.staging.5fsoftware.de',
    }
  }
  return {
    accountId: '8dba17de-488d-41a3-8e00-307b702f039a',
    apiKey: '035b28eec48d11900d7db2e7261516cf025cd86d262720c530fe3819ad7d2f9',
    url: 'https://papi.5f.5fsoftware.de',
  }
}

export default fiveFapi
