import React from 'react'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'

import classes from './SuccessForm.module.scss'

interface Props {
  formId: string
  type: 'default' | 'alternative'
}

const SuccessForm = (props: Props) => {
  const { formId, type } = props

  const renderContent = () => {
    if (type === 'default') {
      return (
        <>
          <p>
            Sie haben Ihre Daten erfolgreich an unsere Kanzlei übermittelt.
            Vielen Dank, dass Sie von diesem Service Gebrauch gemacht haben.
          </p>
          <p>
            Sie erhalten in Kürze eine E-Mail mit einem Link zum Download Ihrer
            Angaben als Dokumentation im PDF Format. Diese können Sie sehr gerne
            zu Ihren Unterlagen nehmen.
          </p>
          <p>
            Wir bitten Sie, uns die unterschriebene Vollmacht als PDF zur
            Verfügung zu stellen, sofern Sie unserer Kanzlei eine
            Empfangsvollmacht für das Finanzamt erteilen möchten.
          </p>
        </>
      )
    }
    if (type === 'alternative') {
      return (
        <>
          <p>
            Sie haben Ihre Daten erfolgreich an unsere Kanzlei übermittelt.
            Vielen Dank, dass Sie von diesem Service Gebrauch gemacht haben.
          </p>
          <p>
            Sie erhalten in Kürze eine E-Mail mit einem Link zum Download Ihrer
            Angaben als Dokumentation im PDF Format. Diese können Sie sehr gerne
            zu Ihren Unterlagen nehmen.
          </p>
        </>
      )
    }
  }

  return (
    <FormContainer className={classes.successForm}>
      <FormHeader headlineText="Vielen Dank!" />
      <FormBody>
        <h5>
          Formular-ID: <span>{formId}</span>
        </h5>
        {renderContent()}
      </FormBody>
    </FormContainer>
  )
}

export default SuccessForm
