import React from 'react'
import classes from './SplashStage.module.scss'

interface Props {
  headlineText?: string
  children?: React.ReactChild
}

const SplashStage = (props: Props) => (
  <section className={classes.header}>
    <div className={classes.contentContainer}>
      <header className={classes.stageHeader}>
        {props.children ? props.children : <h4>{props.headlineText}</h4>}
      </header>
    </div>
  </section>
)

export default SplashStage
