import React from 'react'

import { STAFF_FORMS } from 'constants/Formulars'
import { WorkingHoursProps } from './WorkingHours.props'

import FormContainer from 'components/ui-components/forms/FormContainer/FormContainer'
import FormHeader from 'components/ui-components/forms/FormHeader/FormHeader'
import FormBody from 'components/ui-components/forms/FormBody/FormBody'
import FormFooter from 'components/ui-components/forms/FormFooter/FormFooter'
import PrependNumberInput from 'components/ui-components/inputs/PrependNumberInput/PrependNumberInput'
import FourColumns from 'components/ui-components/layouts/FourColumns/FourColumns'
import Label from 'components/ui-components/labels/Label/Label'
import HelperButton from 'components/ui-components/buttons/HelperButton/HelperButton'
import FormHint from 'components/ui-components/hints/FormHint'
import setMinimumWage from 'components/utility/setMinimumWage'

import setMaximumWorkingHourPerDay from './utility/setMaximumWorkingHourPerDay'
import setPlaceholderValues from './utility/setPlaceholderValues'
import getTotalWeekHoursResult from './utility/getTotalWeekHoursResult'
import isUnderMinimumWage from './utility/isUnderMinimumWage'

import './WorkingHours.scss'

const WorkingHours = ({
  onChange,
  onSecondaryButtonClick,
  onHelperButtonClick,
  onSubmit,
  sallary,
  sallaryValue,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
  formularName,
  hollidays,
  employeeAge,
  entrydate,
}: WorkingHoursProps) => {
  const days = {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
  }

  const disableButton = (e: any) => {
    if (
      monday === '' &&
      tuesday === '' &&
      wednesday === '' &&
      thursday === '' &&
      friday === '' &&
      saturday === '' &&
      sunday === ''
    ) {
      return null
    }

    if (
      formularName === STAFF_FORMS.MINIJOB ||
      formularName === STAFF_FORMS.STUDENT_MINIJOB
    ) {
      if (
        getTotalWeekHoursResult(days).asNumber >= 11.31 ||
        getTotalWeekHoursResult(days).asNumber <= 0.1 ||
        isUnderMinimumWage(sallary, employeeAge, entrydate, sallaryValue, days)
      ) {
        e.preventDefault()
        e.target.blur()
      }
    }

    if (
      formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      formularName === STAFF_FORMS.STUDENT ||
      formularName === STAFF_FORMS.FOREIGN_EMPLOYEE
    ) {
      if (
        getTotalWeekHoursResult(days).asNumber > 48 ||
        getTotalWeekHoursResult(days).asNumber <= 0.1 ||
        isUnderMinimumWage(sallary, employeeAge, entrydate, sallaryValue, days)
      ) {
        e.preventDefault()
        e.target.blur()
      }
    }

    if (formularName === STAFF_FORMS.APPRENTICESHIP) {
      if (
        getTotalWeekHoursResult(days).asNumber > 48 ||
        getTotalWeekHoursResult(days).asNumber <= 0.1
      ) {
        e.preventDefault()
        e.target.blur()
      }
    }
  }

  const renderMinimumWorkingTimeHint = () => {
    if (
      formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      formularName === STAFF_FORMS.STUDENT ||
      formularName === STAFF_FORMS.FOREIGN_EMPLOYEE ||
      formularName === STAFF_FORMS.APPRENTICESHIP
    ) {
      if (
        getTotalWeekHoursResult(days).asNumber > 48.0 ||
        getTotalWeekHoursResult(days).asNumber < 0.1
      ) {
        return (
          <FormHint hintHeadline="Achtung!">
            <p>
              Die Arbeitszeit muss zwischen 0,10 und 48,00 Std/Woche betragen.
            </p>
          </FormHint>
        )
      }
    }

    if (
      formularName === STAFF_FORMS.MINIJOB ||
      formularName === STAFF_FORMS.STUDENT_MINIJOB
    ) {
      if (getTotalWeekHoursResult(days).asNumber < 0.1) {
        return (
          <FormHint hintHeadline="Achtung!">
            <p>Die Arbeitszeit muss mindestens 0,10 Std/Woche betragen.</p>
          </FormHint>
        )
      }
    }
  }

  const renderUnderMinimumWageHint = () => {
    const monthlyHours = Number(
      (getTotalWeekHoursResult(days).asNumber * 13) / 3
    )
    const hourlyWage = Number(Number(sallaryValue) / monthlyHours)

    const getActualWage = () => {
      return hourlyWage.toFixed(2).toString().replace('.', ',')
    }

    if (
      isUnderMinimumWage(sallary, employeeAge, entrydate, sallaryValue, days) &&
      formularName !== STAFF_FORMS.APPRENTICESHIP
    ) {
      return (
        <FormHint hintHeadline="Achtung!">
          <p>
            Bei der aktuellen Verteilung der Arbeitsstunden und einem Gehalt von{' '}
            {sallaryValue}€ ist der reale Stundenlohn {getActualWage()}€. Somit
            liegt dieser unterhalb des vorgeschriebenen Mindestlohns von{' '}
            {setMinimumWage(entrydate, employeeAge)
              .toFixed(2)
              .toString()
              .replace('.', ',')}
            €.
          </p>
          <p>
            Erhöhe entweder das zuvor angegebene Gehalt, oder reduziere die
            Arbeitsstunden.
          </p>
          <hr />
          <p>
            <b>Beispielrechnung:</b>
          </p>
          <p>
            Bei 8 Wochenstunden mal 13 Wochen<sup>1</sup> geteilt durch 3 Monate
            hast Du insgesamt 34,67 Monatsstunden.
          </p>
          <p>
            Teilst Du ein Gehalt von 200 € / 34,67 Monatsstunden ergibt sich ein
            Stundenlohn von 5,77 €.
          </p>
          <p>
            Dieser liegt unterhalb des gesetzlichen Mindestlohnes von{' '}
            {setMinimumWage(entrydate, employeeAge)
              .toString()
              .replace('.', ',')}
            0 €.
          </p>
          <hr />

          <p>
            <i>
              <sup>1</sup> Bei 52 Wochen im Jahr, hat ein Quartal (3 Monate)
              immer 13 Wochen.
            </i>
          </p>
        </FormHint>
      )
    }
  }

  const renderHollidayInput = () => {
    if (
      formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      formularName === STAFF_FORMS.FOREIGN_EMPLOYEE ||
      formularName === STAFF_FORMS.APPRENTICESHIP ||
      formularName === STAFF_FORMS.STUDENT
    ) {
      return (
        <div className="hollidays">
          <Label>Jährlicher Urlaubsanspruch</Label>
          <PrependNumberInput
            placeholder="30"
            name="hollidays"
            onChange={onChange}
            defaultValue={hollidays}
            prependText="Tage"
            min={0}
            max={50}
            step={0.25}
            autoFocus
          />
        </div>
      )
    }
  }

  const renderFormFields = () => {
    if (
      (formularName === STAFF_FORMS.MINIJOB &&
        sallary !== 'Festes monatliches Gehalt') ||
      (formularName === STAFF_FORMS.STUDENT_MINIJOB &&
        sallary !== 'Festes monatliches Gehalt')
    ) {
      return (
        <React.Fragment>
          <p>Deine Arbeitskraft wird mit einem Stundenlohn vergütet.</p>

          <FormHint hintHeadline="Wichtig:">
            <p>
              Damit Du als Arbeitgeber in Zukunft nicht Schwierigkeiten aufgrund
              des Teilzeit- und Befristungsgesetztes bekommst, ist es besser,
              die Arbeitszeit mit deinen Aushilfen schriftlich/vertraglich zu
              regeln.
            </p>

            <p>
              Liegt keine schriftliche Vereinbarung vor, dann können die
              Behörden von einer wöchentlichen Arbeitszeit von 20 Stunden
              ausgehen. Damit wären die 450,00€ monatlich überschritten.
            </p>

            <p>
              Bitte bestätige durch Unterschrift des PDF, was du im Anschluss
              erhältst, dass die wöchentliche Arbeitszeit von 10 Stunden nicht
              überschritten wird.
            </p>
          </FormHint>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Label>Verteilung der Arbeitszeit in Std.</Label>
        <FourColumns>
          <PrependNumberInput
            placeholder={setPlaceholderValues(formularName)}
            name="monday"
            onChange={onChange}
            defaultValue={monday}
            prependText="Mo"
            required
            min={0}
            max={setMaximumWorkingHourPerDay(formularName)}
            step={0.01}
            autoFocus
          />
          <PrependNumberInput
            placeholder={setPlaceholderValues(formularName)}
            name="tuesday"
            prependText="Di"
            onChange={onChange}
            defaultValue={tuesday}
            required
            min={0}
            max={setMaximumWorkingHourPerDay(formularName)}
            step={0.01}
          />
          <PrependNumberInput
            placeholder={setPlaceholderValues(formularName)}
            name="wednesday"
            prependText="Mi"
            onChange={onChange}
            defaultValue={wednesday}
            required
            min={0}
            max={setMaximumWorkingHourPerDay(formularName)}
            step={0.01}
          />
          <PrependNumberInput
            placeholder={setPlaceholderValues(formularName)}
            name="thursday"
            prependText="Do"
            onChange={onChange}
            defaultValue={thursday}
            required
            min={0}
            max={setMaximumWorkingHourPerDay(formularName)}
            step={0.01}
          />
        </FourColumns>
        <FourColumns>
          <PrependNumberInput
            placeholder={setPlaceholderValues(formularName)}
            name="friday"
            prependText="Fr"
            onChange={onChange}
            defaultValue={friday}
            required
            min={0}
            max={setMaximumWorkingHourPerDay(formularName)}
            step={0.01}
          />
          <PrependNumberInput
            placeholder={'0'}
            name="saturday"
            prependText="Sa"
            onChange={onChange}
            defaultValue={saturday}
            required
            min={0}
            max={setMaximumWorkingHourPerDay(formularName)}
            step={0.01}
          />
          <PrependNumberInput
            placeholder={'0'}
            name="sunday"
            prependText="So"
            onChange={onChange}
            defaultValue={sunday}
            required
            min={0}
            max={setMaximumWorkingHourPerDay(formularName)}
            step={0.01}
          />
        </FourColumns>
        <h5>
          <span>Gesamt:</span> {getTotalWeekHoursResult(days).asLocaleString}{' '}
          Std/Woche
        </h5>
        {renderMinimumWorkingTimeHint()}
        {renderUnderMinimumWageHint()}
        {renderHollidayInput()}
      </React.Fragment>
    )
  }

  return (
    <FormContainer className={'working-hours-form'} onSubmit={onSubmit}>
      <HelperButton onClick={onHelperButtonClick} />
      <FormHeader headlineText={'Arbeitszeiten'} />
      <FormBody>{renderFormFields()}</FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        onClick={sallary === 'Festes monatliches Gehalt' ? disableButton : null}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={onSecondaryButtonClick}
      />
    </FormContainer>
  )
}

export default WorkingHours
