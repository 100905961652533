import React, { Component } from 'react'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import RadioInputField from '../../Inputs/RadioInputField/RadioInputField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import FormHint from '../../ui-components/hints/FormHint'

import './TaxForm.scss'

class TaxForm extends Component {
  state = {
    lumpSumValue: '2%',
  }

  handleLumpSumChange = (e) => {
    this.setState({
      lumpSumValue: e.target.value,
      passingToEmployee: this.state.lumpSumValue !== '2%' ? false : false,
    })
  }

  handlePassingToEmployeeChange = (e) => {
    this.setState({
      passingToEmployee: !this.state.passingToEmployee,
    })
  }

  render() {
    return (
      <FormContainer className={'tax-form'} onSubmit={this.props.onSubmit}>
        <HelperButton onClick={this.props.onHelperButtonClick} />
        <FormHeader headlineText={'Steuerliche Angaben'} />
        <FormBody>
          <RadioInputField
            label="Lohnsteuerpauschalierung"
            name="lumpSum"
            radioOptions={['2% (Standard)']}
            autoFocus
            required={true}
            defaultChecked={0}
          />
          {this.state.lumpSumValue !== '2%' ? null : (
            <RadioInputField
              label="Lohnsteuer auf Arbeitnehmer:in umlegen?"
              name="passingToEmployee"
              defaultValue={this.props.passingToEmployee}
              radioOptions={['Nein (Standard)', 'Ja']}
              required={true}
              defaultChecked={
                this.props.passingToEmployee === 'Nein (Standard)' ? 0 : 1
              }
              onChange={this.props.onChange}
            />
          )}
          {this.props.passingToEmployee === 'Ja' ? (
            <FormHint
              hintHeadline="Wichtiger Hinweis:"
              hintText="Im Regelfall zahlst Du als Arbeitgeber:in 2% pauschale Lohnsteuer an die MiniJob Zentrale. Diese Steuer kannst Du übernehmen (Regelfall), dann stellen die 2% Betriebsausgabe dar. Oder aber wir ziehen Deinem/r Arbeitnehmer:in diese 2% pauschale Lohnsteuer vom Nettolohn ab (Abwälzung). Bei einem Aushilfslohn von 450,00 EUR würden Deinem/r Arbeitnehmer:in bei Abwälzung 441,00 EUR ausgezahlt werden."
            />
          ) : null}
        </FormBody>
        <FormFooter
          primaryButtonText={'Weiter'}
          secondaryButtonText="Zurück"
          onSecondaryButtonClick={this.props.onSecondaryButtonClick}
        />
      </FormContainer>
    )
  }
}

export default TaxForm
