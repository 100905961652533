import React from 'react'
import FormularSelector from '../../../components/Selects/FormularSelector/FormularSelector'
import SplashStage from '../../../components/Stages/SplashStage/SplashStage'
import classes from './ClientFormsSelectionView.module.scss'

class ClientFormsSelectionView extends React.Component {
  componentDidMount = () => {
    document.title = 'Formular auswählen - Fastdocs'
  }

  render() {
    return (
      <div className={classes.formSelection}>
        <SplashStage>
          <h4 className={classes.stageHeadline}>
            Willkommen im Portal zur Erfassung von neuen Mandanten.
          </h4>
        </SplashStage>
        <FormularSelector type="client" />
      </div>
    )
  }
}

export default ClientFormsSelectionView
