import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = (props) => (
  <div className="checkbox-container">
    <input
      type="checkbox"
      name={props.name}
      defaultChecked={props.defaultChecked}
      required={props.required}
      onClick={props.onClick}
    />
    <span className="checkmark"></span>
  </div>
)

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

export default Checkbox
