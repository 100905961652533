import React, { Fragment } from 'react'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../../ui-components/buttons/HelperButton/HelperButton'
import CheckboxField from '../../../Inputs/CheckboxField/CheckboxField'

import classes from './ScopeOfOrderForm.module.scss'

interface Props {
  type:
    | 'standard'
    | 'limited-company-and-partnership-kg'
    | 'limited-company'
    | 'limited-entrepreneurial-company'
  values: {
    order: boolean
    electronicInvoicing: boolean
  }
  onHelperButtonClick: () => void
  onNextButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onBackButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onCheckboxClick: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ScopeOfOrderForm = (props: Props) => {
  const {
    type,
    values: { order, electronicInvoicing },
    onHelperButtonClick,
    onNextButtonClick,
    onCheckboxClick,
    onBackButtonClick,
  } = props

  const renderContent = () => {
    if (type === 'standard') {
      return (
        <Fragment>
          <p>
            Ich beauftrage die Steuerberatungskanzlei, mich und meine Belange zu
            betreuen.
          </p>
          <p>
            Die vorgenannte Tätigkeit schließt den entsprechenden Schriftverkehr
            und Verhandlungen mit den Steuerbehörden, sonstigen Behörden und
            Stellen mit ein.
          </p>
          <p>
            Die Prüfung des Steuerbescheides ist abhängig von der erteilen
            Vollmacht.
          </p>
          <p>
            Ich bin damit einverstanden, dass meine Daten zum Zweck des
            Auftrages erhoben, verarbeitet und genutzt sowie an die
            Auftragsdatenverarbeiter weitergegeben bzw. übermittelt und dort
            ebenfalls verarbeitet und genutzt werden.
          </p>
          <p>
            Ich bin zudem darauf hingewiesen worden, dass die Erhebung,
            Verarbeitung und Nutzung meiner Daten auf freiwilliger Basis
            erfolgt. Ferner, dass ich mein Einverständnis ohne für mich
            nachteilige Folgen jederzeit mit Wirkung für die Zukunft widerrufen
            kann.
          </p>
          <p>
            Die Daten werden bei Beendigung des Mandatsverhältnisses inaktiv
            gesetzt, allerdings nicht gelöscht.
          </p>
        </Fragment>
      )
    }
    if (
      type === 'limited-company-and-partnership-kg' ||
      type === 'limited-company' ||
      type === 'limited-entrepreneurial-company'
    ) {
      return (
        <Fragment>
          <p>
            Ich beauftrage die Steuerberatungskanzlei, die Belange des
            Unternehmens zu betreuen.
          </p>
          <p>
            Die vorgenannte Tätigkeit schließt den entsprechenden Schriftverkehr
            und Verhandlungen mit den Steuerbehörden, sonstigen Behörden und
            Stellen mit ein.
          </p>
          <p>
            Die Prüfung der Steuerbescheide ist abhängig von der erteilten
            Vollmacht.
          </p>
          <p>
            Das Unternehmen ist damit einverstanden, dass die Daten zum Zweck
            des Auftrages erhoben, verarbeitet und genutzt sowie an die
            Auftragsdatenverarbeiter weitergegeben werden bzw. übermittelt und
            dort ebenfalls verarbeitet und genutzt werden.
          </p>
          <p>
            Zudem sind wir darauf hingewiesen worden, dass die Erhebung,
            Verarbeitung und Nutzung der Daten auf freiwilliger Basis erfolgt.
            Ferner, dass das Unternehmen sein Einverständnis ohne nachteilige
            Folgen jederzeit mit Wirkung für die Zukunft widerrufen kann.
          </p>
          <p>
            Die Daten werden bei Beendigung des Mandatsverhältnisses inaktiv
            gesetzt, allerdings nicht gelöscht.
          </p>
        </Fragment>
      )
    }
  }

  return (
    <FormContainer
      className={classes.scopeOfOrderForm}
      onSubmit={onNextButtonClick}
    >
      <HelperButton onClick={onHelperButtonClick} />
      <FormHeader headlineText="Auftragsumfang">{renderContent()}</FormHeader>
      <FormBody>
        <CheckboxField
          name="order"
          defaultChecked={order}
          onClick={onCheckboxClick}
          label="Hiermit bestätige ich, den Auftrag erteilt zu haben und willige in die Datenerhebung und Datenverarbeitung ein."
          required
        />
        <CheckboxField
          name="electronicInvoicing"
          defaultChecked={electronicInvoicing}
          onClick={onCheckboxClick}
          label="Ich stimme einem elektronischen Rechnungsversand via E-Mail zu."
        />
      </FormBody>
      <FormFooter
        primaryButtonText="Weiter"
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={onBackButtonClick}
      />
    </FormContainer>
  )
}

export default ScopeOfOrderForm
