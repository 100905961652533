import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './FormFooter.scss'

const FormFooter = (props) => (
  <footer
    className={
      props.secondaryButtonText ? 'form-footer two-buttons' : 'form-footer'
    }
  >
    {props.secondaryButtonText ? (
      <button type={'button'} onClick={props.onSecondaryButtonClick}>
        {props.secondaryButtonText}
      </button>
    ) : null}
    {props.linkTo ? (
      <Link to={props.linkTo}>
        <button type={'submit'} onClick={props.onClick}>
          {props.primaryButtonText}
        </button>
      </Link>
    ) : (
      <button type={'submit'} onClick={props.onClick}>
        {props.primaryButtonText}
      </button>
    )}
  </footer>
)

FormFooter.propTypes = {
  primaryButtonText: PropTypes.string.isRequired,
  secondaryButtonText: PropTypes.string,
  onSecondaryButtonClick: PropTypes.func,
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
}

export default FormFooter
