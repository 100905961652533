const employeeFormValues = {
  additionalMiniJob: 'Nein',
  bankAccountHolder: 'Andrea Miczalski',
  bea: true,
  beginningStatus: 'Arbeitslose/r',
  birthCountry: 'Deutschland',
  birthday: '29',
  birthmonth: '04',
  birthyear: '1980',
  capitalAccumulationBankAccountHolder: 'Schwäbisch Hall',
  capitalAccumulationIban: 'DE54342500000001623321',
  capitalAccumulationQuestion: 'Ja',
  capitalAccumulationValue: '40',
  city: 'Ingolstadt',
  contractNumber: '44859372',
  education: 'Anerkannte Berufsausbildung',
  firstname: 'Andrea',
  furtherEmploymentCompanyName: 'Backhaus Hackner GmbH',
  furtherEmployments: true,
  furtherEmploymentsTotalSallary: 'Ja',
  graduation: 'Abitur/Fachabitur',
  houseNumber: '17',
  iban: 'DE44100110012625484636',
  insuranceCompanyName: 'Techniker Krankenkasse',
  insuranceType: 'Gesetzlich',
  lastname: 'Miczalski',
  mariatialStatus: 'Ledig',
  nationality: 'Deutschland',
  pensionInsuranceRequest: 'Nein',
  placeOfBirth: 'München',
  request: false,
  requestDeclaration: false,
  sex: 'Weiblich',
  socialSecurityNumber: '60290480O505',
  street: 'Hans-Stuck-Straße',
  taxIdNumber: '86149736056',
  typeOfEmployment: 'Minijob bis 450€',
  additionalEmployement: 'Nein',
  payingPensionInsurance: 'Nein',
  furtherMinijobEntryDay: '1',
  furtherMinijobEntryMonth: '5',
  furtherMinijobEntryYear: '2019',
  zipCode: '85045',
}

export default employeeFormValues
