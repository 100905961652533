const consultantFormValues = {
  clientNumber: '11500',
  consultantNumber: '260814',
  staffNumber: '1300',
  autosuggestSearchValue: '63402 - Veranstaltungskaufmann/-frau',
  activityKey: '63402',
  jobTitle: 'Veranstaltungskaufmann/-frau',
  operationNumber: '15277142',
}

export default consultantFormValues
