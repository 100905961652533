import React from 'react'
import PropTypes from 'prop-types'
import Label from '../../ui-components/labels/Label/Label'
import RadioInput from '../../ui-components/inputs/RadioInput/RadioInput'
import './RadioInputField.scss'

const RadioInputField = (props) => (
  <div className={`radio-input-field ${props.disabled ? 'disabled' : ''}`}>
    <Label>{props.label}</Label>
    <div>
      <div>
        <label>
          <RadioInput
            name={props.name}
            required={props.required}
            defaultValue={props.radioOptions[0]}
            onChange={props.onChange}
            autoFocus={props.autoFocus}
            defaultChecked={props.defaultChecked !== 0 ? false : true}
            disabled={props.disabled}
          />
          {props.radioOptions[0]}
        </label>
      </div>
      {props.radioOptions.length <= 1 ? null : (
        <div>
          <label>
            <RadioInput
              name={props.name}
              defaultValue={props.radioOptions[1]}
              onChange={props.onChange}
              autoFocus={props.autoFocus}
              defaultChecked={props.defaultChecked !== 1 ? false : true}
              disabled={props.disabled}
            />
            {props.radioOptions[1]}
          </label>
        </div>
      )}
    </div>
  </div>
)

RadioInputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  radioOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultChecked: PropTypes.oneOf([0, 1]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

export default RadioInputField
