import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import './TwoColumns.scss'

interface Props {
  layout: '1-1' | '3-5' | '5-3'
  children: ReactNode
}

const layouts = ['1-1', '3-5', '5-3']

const TwoColumns = (props: Props) => {
  if (props.layout === layouts[0]) {
    return <div className={`columns-${layouts[0]}`}>{props.children}</div>
  }
  if (props.layout === layouts[1]) {
    return <div className={`columns-${layouts[1]}`}>{props.children}</div>
  }
  if (props.layout === layouts[2]) {
    return <div className={`columns-${layouts[2]}`}>{props.children}</div>
  }
  return null
}

TwoColumns.propTypes = {
  layout: PropTypes.oneOf(layouts).isRequired,
  children: PropTypes.node,
}

export default TwoColumns
