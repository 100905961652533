import React, { useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'
import { useHistory } from 'react-router-dom'

import { getTaxConsultant, setStorage } from '../../utility/utility'
import UploadButton from '../../../components/ui-components/buttons/UploadButton/UploadButton'

import './CustomizingSettingsView.scss'
import ActionButton from '../../../components/ui-components/buttons/ActionButton/ActionButton'

interface Props {
  logo: string
  contracts: {
    client: { contract: boolean; contractOption: string }
    staff: { contract: boolean; contractOption: string }
  }
}

const CustomizingSettingsView = (props: Props) => {
  const history = useHistory()
  const [logoUrl] = useState(props.logo)
  const [uploadLogoButton, setUploadLogoButton] = useState('initial')
  const [deleteLogoButton, setDeleteLogoButton] = useState('initial')

  const uploadLogo = (e: any) => {
    const file = e.target.files[0]
    const storageRef = firebase
      .app()
      .storage(setStorage())
      .ref(`${getTaxConsultant()}/logo`)
    const uploadTask = storageRef.put(file)

    uploadTask.on(
      'state_changed',
      () => {
        setUploadLogoButton('active')
      },
      (error) => {
        console.error(error)
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          history.go(0)
        })
      }
    )
  }

  const deleteLogo = () => {
    const storageRef = firebase
      .app()
      .storage(setStorage())
      .ref(`${getTaxConsultant()}/logo`)

    setDeleteLogoButton('active')

    storageRef.delete().then(() => {
      history.go(0)
    })
  }

  const renderLogoInterface = () => {
    if (!props.logo) {
      return (
        <div className="logo">
          <div className="logo-preview">
            <p>Kein Logo hinterlegt</p>
          </div>

          <UploadButton
            content={{ initial: 'Hochladen', finished: 'Gespeichert' }}
            buttonState={uploadLogoButton}
            disabled={uploadLogoButton !== 'initial' ? true : false}
            onChange={(e: any) => uploadLogo(e)}
          />
        </div>
      )
    }
    return (
      <div className="logo">
        <div className="logo-preview">
          <img src={logoUrl} alt="" height="20px" />
        </div>
        {logoUrl ? (
          <>
            <UploadButton
              content={{ initial: 'Ersetzen', finished: 'Gespeichert' }}
              buttonState={uploadLogoButton}
              disabled={uploadLogoButton !== 'initial' ? true : false}
              onChange={(e: any) => uploadLogo(e)}
            />
            <ActionButton
              content={{ initial: 'Entfernen', finished: 'Gespeichert' }}
              buttonState={deleteLogoButton}
              disabled={deleteLogoButton !== 'initial' ? true : false}
              onClick={deleteLogo}
            />
          </>
        ) : (
          <UploadButton
            content={{ initial: 'Hochladen', finished: 'Gespeichert' }}
            buttonState={uploadLogoButton}
            disabled={uploadLogoButton !== 'initial' ? true : false}
            onChange={(e: any) => uploadLogo(e)}
          />
        )}
      </div>
    )
  }

  const allowUserToCustomize = () => {
    if (
      (props.contracts.client.contract &&
        props.contracts.client.contractOption === 'pro') ||
      (props.contracts.staff.contract &&
        props.contracts.staff.contractOption === 'pro')
    ) {
      return true
    }
    return false
  }

  return (
    <div className="customizing-settings-details">
      <header>
        <h4>Individualisierung</h4>
      </header>

      <main>
        {allowUserToCustomize() ? (
          <section>
            <label className="label">Kanzlei-Logo</label>
            {renderLogoInterface()}
            <small>
              Das Logo wird nur auf der Website verwendet. Empfohlene
              Datei-Eigenschaften: PNG- oder SGV-Datei (.png oder .svg) mit
              einem transparentem Hintergrund und einer maximalen Höhe von 32px.
            </small>
          </section>
        ) : (
          <section>
            <p>
              Die Individualisierungs-Funktionen sind nur in einem Pro Vertrag
              verfügbar.
              <br />
              <br />
              <a
                href="https://www.fastdocs.de/fastdocs-staff-pricing.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mehr über Fastdocs Staff Pro erfahren
              </a>
              <br />
              <a
                href="https://www.fastdocs.de/fastdocs-client-pricing.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mehr über Fastdocs Client Pro erfahren
              </a>
              <br />
              <br />
              <a
                className="button secondary"
                href="mailto:sales@fastdocs.de?subject=Individualisierung für Fastdocs Account&body=Hallo liebes Sales-Team,"
              >
                Sales-Team kontaktieren
              </a>
            </p>
          </section>
        )}
      </main>
    </div>
  )
}

export default CustomizingSettingsView
