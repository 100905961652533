import { STAFF_FORMS } from 'constants/Formulars'

const setMaximumWorkingHourPerDay = (formularName: STAFF_FORMS) => {
  if (
    formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
    formularName === STAFF_FORMS.STUDENT ||
    formularName === STAFF_FORMS.FOREIGN_EMPLOYEE ||
    formularName === STAFF_FORMS.APPRENTICESHIP
  ) {
    return 10
  }
  return 9
}

export default setMaximumWorkingHourPerDay
