import checkLocalStorageFor from 'views/utility/checkLocalStorageFor'

const initialFormValues = (formularName: string) => {
  return {
    formularName: formularName,
    annualFinancialReport: checkLocalStorageFor(
      formularName,
      'annualFinancialReport',
      ''
    ),
    partnershipAgreement: checkLocalStorageFor(
      formularName,
      'partnershipAgreement',
      'Nein'
    ),
    is_companion_a_natural_person: checkLocalStorageFor(
      formularName,
      'is_companion_a_natural_person',
      'Ja'
    ),
    companion_company_name: checkLocalStorageFor(
      formularName,
      'companion_company_name',
      ''
    ),
    salutation: checkLocalStorageFor(formularName, 'salutation', ''),
    companyName: checkLocalStorageFor(formularName, 'companyName', ''),
    contactPerson: checkLocalStorageFor(formularName, 'contactPerson', ''),
    businessForm: checkLocalStorageFor(formularName, 'businessForm', ''),
    businessPurpose: checkLocalStorageFor(formularName, 'businessPurpose', ''),
    foundingday: checkLocalStorageFor(formularName, 'foundingday', ''),
    foundingmonth: checkLocalStorageFor(formularName, 'foundingmonth', ''),
    foundingyear: checkLocalStorageFor(formularName, 'foundingyear', ''),
    businessStreet: checkLocalStorageFor(formularName, 'businessStreet', ''),
    businessHouseNumber: checkLocalStorageFor(
      formularName,
      'businessHouseNumber',
      ''
    ),
    businessZipCode: checkLocalStorageFor(formularName, 'businessZipCode', ''),
    businessCity: checkLocalStorageFor(formularName, 'businessCity', ''),
    businessPhoneNumber: checkLocalStorageFor(
      formularName,
      'businessPhoneNumber',
      ''
    ),
    businessEmail: checkLocalStorageFor(formularName, 'businessEmail', ''),
    businessBankAccountHolder: checkLocalStorageFor(
      formularName,
      'businessBankAccountHolder',
      ''
    ),
    businessBankName: checkLocalStorageFor(
      formularName,
      'businessBankName',
      ''
    ),
    businessIban: checkLocalStorageFor(formularName, 'businessIban', ''),
    businessTaxOffice: checkLocalStorageFor(
      formularName,
      'businessTaxOffice',
      ''
    ),
    vatNumberAvailable: checkLocalStorageFor(
      formularName,
      'vatNumberAvailable',
      'Nein'
    ),
    vatRegion: checkLocalStorageFor(formularName, 'vatRegion', 'DE'),
    vatNumber: checkLocalStorageFor(formularName, 'vatNumber', ''),
    businessTaxNumberAvailable: checkLocalStorageFor(
      formularName,
      'businessTaxNumberAvailable',
      'Nein'
    ),
    businessTaxNumber: checkLocalStorageFor(
      formularName,
      'businessTaxNumber',
      ''
    ),
    tradeRegisterEntry: checkLocalStorageFor(
      formularName,
      'tradeRegisterEntry',
      'Nein'
    ),
    registryCourt: checkLocalStorageFor(formularName, 'registryCourt', ''),
    tradeRegisterNumber: checkLocalStorageFor(
      formularName,
      'tradeRegisterNumber',
      ''
    ),
    academicTitle: checkLocalStorageFor(formularName, 'academicTitle', 'Keine'),
    firstname: checkLocalStorageFor(formularName, 'firstname', ''),
    lastname: checkLocalStorageFor(formularName, 'lastname', ''),
    birthday: checkLocalStorageFor(formularName, 'birthday', ''),
    birthmonth: checkLocalStorageFor(formularName, 'birthmonth', ''),
    birthyear: checkLocalStorageFor(formularName, 'birthyear', ''),
    denomination: checkLocalStorageFor(formularName, 'denomination', ''),
    socialSecurityNumber: checkLocalStorageFor(
      formularName,
      'socialSecurityNumber',
      ''
    ),
    taxIdNumber: checkLocalStorageFor(formularName, 'taxIdNumber', ''),
    street: checkLocalStorageFor(formularName, 'street', ''),
    houseNumber: checkLocalStorageFor(formularName, 'houseNumber', ''),
    zipCode: checkLocalStorageFor(formularName, 'zipCode', ''),
    city: checkLocalStorageFor(formularName, 'city', ''),
    phoneNumber: checkLocalStorageFor(formularName, 'phoneNumber', ''),
    email: checkLocalStorageFor(formularName, 'email', ''),
    typeOfIdentification: checkLocalStorageFor(
      formularName,
      'typeOfIdentification',
      'Personalausweis'
    ),
    placeOfBirth: checkLocalStorageFor(formularName, 'placeOfBirth', ''),
    pep: checkLocalStorageFor(formularName, 'pep', 'Nein'),
    nationality: checkLocalStorageFor(formularName, 'nationality', ''),
    identityCardNumber: checkLocalStorageFor(
      formularName,
      'identityCardNumber',
      ''
    ),
    issuingAuthority: checkLocalStorageFor(
      formularName,
      'issuingAuthority',
      ''
    ),
    issuedDay: checkLocalStorageFor(formularName, 'issuedDay', ''),
    issuedMonth: checkLocalStorageFor(formularName, 'issuedMonth', ''),
    issuedYear: checkLocalStorageFor(formularName, 'issuedYear', ''),
    validDay: checkLocalStorageFor(formularName, 'validDay', ''),
    validMonth: checkLocalStorageFor(formularName, 'validMonth', ''),
    validYear: checkLocalStorageFor(formularName, 'validYear', ''),
    privateIban: checkLocalStorageFor(formularName, 'privateIban', ''),
    privateBankAccountHolder: checkLocalStorageFor(
      formularName,
      'privateBankAccountHolder',
      ''
    ),
    privateBankName: checkLocalStorageFor(formularName, 'privateBankName', ''),
    privateTaxOffice: checkLocalStorageFor(
      formularName,
      'privateTaxOffice',
      ''
    ),
    privateTaxNumber: checkLocalStorageFor(
      formularName,
      'privateTaxNumber',
      ''
    ),
    married: checkLocalStorageFor(formularName, 'married', 'Nein'),
    companionAcademicTitle: checkLocalStorageFor(
      formularName,
      'companionAcademicTitle',
      'Keine'
    ),
    companionFirstname: checkLocalStorageFor(
      formularName,
      'companionFirstname',
      ''
    ),
    companionLastname: checkLocalStorageFor(
      formularName,
      'companionLastname',
      ''
    ),
    companionBirthday: checkLocalStorageFor(
      formularName,
      'companionBirthday',
      ''
    ),
    companionBirthmonth: checkLocalStorageFor(
      formularName,
      'companionBirthmonth',
      ''
    ),
    companionBirthyear: checkLocalStorageFor(
      formularName,
      'companionBirthyear',
      ''
    ),
    companionDenomination: checkLocalStorageFor(
      formularName,
      'companionDenomination',
      ''
    ),
    companionSocialSecurityNumber: checkLocalStorageFor(
      formularName,
      'companionSocialSecurityNumber',
      ''
    ),
    companionTaxIdNumber: checkLocalStorageFor(
      formularName,
      'companionTaxIdNumber',
      ''
    ),
    companionStreet: checkLocalStorageFor(formularName, 'companionStreet', ''),
    companionHouseNumber: checkLocalStorageFor(
      formularName,
      'companionHouseNumber',
      ''
    ),
    companionZipCode: checkLocalStorageFor(
      formularName,
      'companionZipCode',
      ''
    ),
    companionCity: checkLocalStorageFor(formularName, 'companionCity', ''),
    companionPhoneNumber: checkLocalStorageFor(
      formularName,
      'companionPhoneNumber',
      ''
    ),
    companionEmail: checkLocalStorageFor(formularName, 'companionEmail', ''),
    companionTypeOfIdentification: checkLocalStorageFor(
      formularName,
      'companionTypeOfIdentification',
      'Personalausweis'
    ),
    companionPlaceOfBirth: checkLocalStorageFor(
      formularName,
      'companionPlaceOfBirth',
      ''
    ),
    companionPep: checkLocalStorageFor(formularName, 'companionPep', 'Nein'),
    companionNationality: checkLocalStorageFor(
      formularName,
      'companionNationality',
      ''
    ),
    companionIdentityCardNumber: checkLocalStorageFor(
      formularName,
      'companionIdentityCardNumber',
      ''
    ),
    companionIssuingAuthority: checkLocalStorageFor(
      formularName,
      'companionIssuingAuthority',
      ''
    ),
    companionIssuedDay: checkLocalStorageFor(
      formularName,
      'companionIssuedDay',
      ''
    ),
    companionIssuedMonth: checkLocalStorageFor(
      formularName,
      'companionIssuedMonth',
      ''
    ),
    companionIssuedYear: checkLocalStorageFor(
      formularName,
      'companionIssuedYear',
      ''
    ),
    companionValidDay: checkLocalStorageFor(
      formularName,
      'companionValidDay',
      ''
    ),
    companionValidMonth: checkLocalStorageFor(
      formularName,
      'companionValidMonth',
      ''
    ),
    companionValidYear: checkLocalStorageFor(
      formularName,
      'companionValidYear',
      ''
    ),
    children: checkLocalStorageFor(formularName, 'children', 'Nein'),
    numberOfChildren: checkLocalStorageFor(formularName, 'numberOfChildren', 1),
    child1Firstname: checkLocalStorageFor(formularName, 'child1Firstname', ''),
    child1Lastname: checkLocalStorageFor(formularName, 'child1Lastname', ''),
    child1Birthday: checkLocalStorageFor(formularName, 'child1Birthday', ''),
    child1Birthmonth: checkLocalStorageFor(
      formularName,
      'child1Birthmonth',
      ''
    ),
    child1Birthyear: checkLocalStorageFor(formularName, 'child1Birthyear', ''),
    child1TaxIdNumber: checkLocalStorageFor(
      formularName,
      'child1TaxIdNumber',
      ''
    ),
    child1IsAdult: checkLocalStorageFor(formularName, 'child1IsAdult', 'Nein'),
    child1Status: checkLocalStorageFor(formularName, 'child1Status', ''),
    child1Benefit: checkLocalStorageFor(formularName, 'child1Benefit', 'Ja'),
    child2Firstname: checkLocalStorageFor(formularName, 'child2Firstname', ''),
    child2Lastname: checkLocalStorageFor(formularName, 'child2Lastname', ''),
    child2Birthday: checkLocalStorageFor(formularName, 'child2Birthday', ''),
    child2Birthmonth: checkLocalStorageFor(
      formularName,
      'child2Birthmonth',
      ''
    ),
    child2Birthyear: checkLocalStorageFor(formularName, 'child2Birthyear', ''),
    child2TaxIdNumber: checkLocalStorageFor(
      formularName,
      'child2TaxIdNumber',
      ''
    ),
    child2IsAdult: checkLocalStorageFor(formularName, 'child2IsAdult', 'Nein'),
    child2Status: checkLocalStorageFor(formularName, 'child2Status', ''),
    child2Benefit: checkLocalStorageFor(formularName, 'child2Benefit', 'Ja'),
    child3Firstname: checkLocalStorageFor(formularName, 'child3Firstname', ''),
    child3Lastname: checkLocalStorageFor(formularName, 'child3Lastname', ''),
    child3Birthday: checkLocalStorageFor(formularName, 'child3Birthday', ''),
    child3Birthmonth: checkLocalStorageFor(
      formularName,
      'child3Birthmonth',
      ''
    ),
    child3Birthyear: checkLocalStorageFor(formularName, 'child3Birthyear', ''),
    child3TaxIdNumber: checkLocalStorageFor(
      formularName,
      'child3TaxIdNumber',
      ''
    ),
    child3IsAdult: checkLocalStorageFor(formularName, 'child3IsAdult', 'Nein'),
    child3Status: checkLocalStorageFor(formularName, 'child3Status', ''),
    child3Benefit: checkLocalStorageFor(formularName, 'child3Benefit', 'Ja'),
    taxOfficeProxy: checkLocalStorageFor(formularName, 'taxOfficeProxy', 'Ja'),
    capitalContribution: checkLocalStorageFor(
      formularName,
      'capitalContribution',
      ''
    ),
    limitedCompanySalutation: checkLocalStorageFor(
      formularName,
      'limitedCompanySalutation',
      ''
    ),
    limitedCompanyName: checkLocalStorageFor(
      formularName,
      'limitedCompanyName',
      ''
    ),
    limitedCompanyFoundingday: checkLocalStorageFor(
      formularName,
      'limitedCompanyFoundingday',
      ''
    ),
    limitedCompanyFoundingmonth: checkLocalStorageFor(
      formularName,
      'limitedCompanyFoundingmonth',
      ''
    ),
    limitedCompanyFoundingyear: checkLocalStorageFor(
      formularName,
      'limitedCompanyFoundingyear',
      ''
    ),
    limitedCompanyBusinessStreet: checkLocalStorageFor(
      formularName,
      'limitedCompanyBusinessStreet',
      ''
    ),
    limitedCompanyBusinessHouseNumber: checkLocalStorageFor(
      formularName,
      'limitedCompanyBusinessHouseNumber',
      ''
    ),
    limitedCompanyBusinessZipCode: checkLocalStorageFor(
      formularName,
      'limitedCompanyBusinessZipCode',
      ''
    ),
    limitedCompanyBusinessCity: checkLocalStorageFor(
      formularName,
      'limitedCompanyBusinessCity',
      ''
    ),
    limitedCompanyBusinessBankAccountHolder: checkLocalStorageFor(
      formularName,
      'limitedCompanyBusinessBankAccountHolder',
      ''
    ),
    limitedCompanyBusinessBankName: checkLocalStorageFor(
      formularName,
      'limitedCompanyBusinessBankName',
      ''
    ),
    limitedCompanyBusinessIban: checkLocalStorageFor(
      formularName,
      'limitedCompanyBusinessIban',
      ''
    ),
    limitedCompanyBusinessTaxOffice: checkLocalStorageFor(
      formularName,
      'limitedCompanyBusinessTaxOffice',
      ''
    ),
    limitedCompanyVatNumberAvailable: checkLocalStorageFor(
      formularName,
      'limitedCompanyVatNumberAvailable',
      'Nein'
    ),
    limitedCompanyVatNumber: checkLocalStorageFor(
      formularName,
      'limitedCompanyVatNumber',
      ''
    ),
    limitedCompanyVatRegion: checkLocalStorageFor(
      formularName,
      'limitedCompanyVatRegion',
      'DE'
    ),
    limitedCompanyBusinessTaxNumberAvailable: checkLocalStorageFor(
      formularName,
      'limitedCompanyBusinessTaxNumberAvailable',
      'Nein'
    ),
    limitedCompanyBusinessTaxNumber: checkLocalStorageFor(
      formularName,
      'limitedCompanyBusinessTaxNumber',
      ''
    ),
    limitedCompanyTradeRegisterEntry: checkLocalStorageFor(
      formularName,
      'limitedCompanyTradeRegisterEntry',
      'Nein'
    ),
    limitedCompanyRegistryCourt: checkLocalStorageFor(
      formularName,
      'limitedCompanyRegistryCourt',
      ''
    ),
    limitedCompanyTradeRegisterNumber: checkLocalStorageFor(
      formularName,
      'limitedCompanyTradeRegisterNumber',
      ''
    ),
    limitedCompanyAcademicTitle: checkLocalStorageFor(
      formularName,
      'limitedCompanyAcademicTitle',
      ''
    ),
    limitedCompanyFirstname: checkLocalStorageFor(
      formularName,
      'limitedCompanyFirstname',
      ''
    ),
    limitedCompanyLastname: checkLocalStorageFor(
      formularName,
      'limitedCompanyLastname',
      ''
    ),
    limitedCompanyBirthday: checkLocalStorageFor(
      formularName,
      'limitedCompanyBirthday',
      ''
    ),
    limitedCompanyBirthmonth: checkLocalStorageFor(
      formularName,
      'limitedCompanyBirthmonth',
      ''
    ),
    limitedCompanyBirthyear: checkLocalStorageFor(
      formularName,
      'limitedCompanyBirthyear',
      ''
    ),
    limitedCompanyDenomination: checkLocalStorageFor(
      formularName,
      'limitedCompanyDenomination',
      ''
    ),
    limitedCompanyTaxIdNumber: checkLocalStorageFor(
      formularName,
      'limitedCompanyTaxIdNumber',
      ''
    ),
    limitedCompanyCapitalContribution: checkLocalStorageFor(
      formularName,
      'limitedCompanyCapitalContribution',
      ''
    ),
    limitedCompanyStreet: checkLocalStorageFor(
      formularName,
      'limitedCompanyStreet',
      ''
    ),
    limitedCompanyHouseNumber: checkLocalStorageFor(
      formularName,
      'limitedCompanyHouseNumber',
      ''
    ),
    limitedCompanyZipCode: checkLocalStorageFor(
      formularName,
      'limitedCompanyZipCode',
      ''
    ),
    limitedCompanyCity: checkLocalStorageFor(
      formularName,
      'limitedCompanyCity',
      ''
    ),
    limitedCompanyPhoneNumber: checkLocalStorageFor(
      formularName,
      'limitedCompanyPhoneNumber',
      ''
    ),
    limitedCompanyEmail: checkLocalStorageFor(
      formularName,
      'limitedCompanyEmail',
      ''
    ),
    amountOfSubscribedCapital: checkLocalStorageFor(
      formularName,
      'amountOfSubscribedCapital',
      ''
    ),
    sameShareholder: checkLocalStorageFor(
      formularName,
      'sameShareholder',
      'Nein'
    ),
    shareCapital: checkLocalStorageFor(formularName, 'shareCapital', ''),
    additionalShareHolder: checkLocalStorageFor(
      formularName,
      'additionalShareHolder',
      'Nein'
    ),
    companionShareCapital: checkLocalStorageFor(
      formularName,
      'companionShareCapital',
      ''
    ),
    externalManagingDirector: checkLocalStorageFor(
      formularName,
      'externalManagingDirector',
      'Nein'
    ),
    externalManagingDirectorFirstname: checkLocalStorageFor(
      formularName,
      'externalManagingDirectorFirstname',
      ''
    ),
    externalManagingDirectorLastname: checkLocalStorageFor(
      formularName,
      'externalManagingDirectorLastname',
      ''
    ),
    externalManagingDirectorBirthday: checkLocalStorageFor(
      formularName,
      'externalManagingDirectorBirthday',
      ''
    ),
    externalManagingDirectorBirthmonth: checkLocalStorageFor(
      formularName,
      'externalManagingDirectorBirthmonth',
      ''
    ),
    externalManagingDirectorBirthyear: checkLocalStorageFor(
      formularName,
      'externalManagingDirectorBirthyear',
      ''
    ),
    externalManagingDirectorStreet: checkLocalStorageFor(
      formularName,
      'externalManagingDirectorStreet',
      ''
    ),
    externalManagingDirectorHouseNumber: checkLocalStorageFor(
      formularName,
      'externalManagingDirectorHouseNumber',
      ''
    ),
    externalManagingDirectorZipCode: checkLocalStorageFor(
      formularName,
      'externalManagingDirectorZipCode',
      ''
    ),
    externalManagingDirectorCity: checkLocalStorageFor(
      formularName,
      'externalManagingDirectorCity',
      ''
    ),
    naturalPerson: checkLocalStorageFor(formularName, 'naturalPerson', 'Ja'),
    marriedDay: checkLocalStorageFor(formularName, 'marriedDay', ''),
    marriedMonth: checkLocalStorageFor(formularName, 'marriedMonth', ''),
    marriedYear: checkLocalStorageFor(formularName, 'marriedYear', ''),
    fixedCapital: checkLocalStorageFor(formularName, 'fixedCapital', ''),
    secondPartnerTypeOfIdentification: checkLocalStorageFor(
      formularName,
      'secondPartnerTypeOfIdentification',
      'Personalausweis'
    ),
    secondPartnerPlaceOfBirth: checkLocalStorageFor(
      formularName,
      'secondPartnerPlaceOfBirth',
      ''
    ),
    secondPartnerNationality: checkLocalStorageFor(
      formularName,
      'secondPartnerNationality',
      ''
    ),
    secondPartnerIdentityCardNumber: checkLocalStorageFor(
      formularName,
      'secondPartnerIdentityCardNumber',
      ''
    ),
    secondPartnerIssuingAuthority: checkLocalStorageFor(
      formularName,
      'secondPartnerIssuingAuthority',
      ''
    ),
    secondPartnerIssuedDay: checkLocalStorageFor(
      formularName,
      'secondPartnerIssuedDay',
      ''
    ),
    secondPartnerIssuedMonth: checkLocalStorageFor(
      formularName,
      'secondPartnerIssuedMonth',
      ''
    ),
    secondPartnerIssuedYear: checkLocalStorageFor(
      formularName,
      'secondPartnerIssuedYear',
      ''
    ),
    secondPartnerValidDay: checkLocalStorageFor(
      formularName,
      'secondPartnerValidDay',
      ''
    ),
    secondPartnerValidMonth: checkLocalStorageFor(
      formularName,
      'secondPartnerValidMonth',
      ''
    ),
    secondPartnerValidYear: checkLocalStorageFor(
      formularName,
      'secondPartnerValidYear',
      ''
    ),
    secondPartnerPep: checkLocalStorageFor(
      formularName,
      'secondPartnerPep',
      'Nein'
    ),
    differentProfitDistribution: checkLocalStorageFor(
      formularName,
      'differentProfitDistribution',
      'Nein'
    ),
    numberOfShareholders: checkLocalStorageFor(
      formularName,
      'numberOfShareholders',
      1
    ),
    additionalShareholders: checkLocalStorageFor(
      formularName,
      'additionalShareholders',
      'Nein'
    ),
    additionalShareholder1AcademicTitle: checkLocalStorageFor(
      formularName,
      'additionalShareholder1AcademicTitle',
      ''
    ),
    additionalShareholder1Firstname: checkLocalStorageFor(
      formularName,
      'additionalShareholder1Firstname',
      ''
    ),
    additionalShareholder1Lastname: checkLocalStorageFor(
      formularName,
      'additionalShareholder1Lastname',
      ''
    ),
    additionalShareholder1Birthday: checkLocalStorageFor(
      formularName,
      'additionalShareholder1Birthday',
      ''
    ),
    additionalShareholder1Birthmonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder1Birthmonth',
      ''
    ),
    additionalShareholder1Birthyear: checkLocalStorageFor(
      formularName,
      'additionalShareholder1Birthyear',
      ''
    ),
    additionalShareholder1Denomination: checkLocalStorageFor(
      formularName,
      'additionalShareholder1Denomination',
      ''
    ),
    additionalShareholder1TaxIdNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder1TaxIdNumber',
      ''
    ),
    additionalShareholder1Street: checkLocalStorageFor(
      formularName,
      'additionalShareholder1Street',
      ''
    ),
    additionalShareholder1HouseNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder1HouseNumber',
      ''
    ),
    additionalShareholder1ZipCode: checkLocalStorageFor(
      formularName,
      'additionalShareholder1ZipCode',
      ''
    ),
    additionalShareholder1City: checkLocalStorageFor(
      formularName,
      'additionalShareholder1City',
      ''
    ),
    additionalShareholder1PhoneNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder1PhoneNumber',
      ''
    ),
    additionalShareholder1Email: checkLocalStorageFor(
      formularName,
      'additionalShareholder1Email',
      ''
    ),
    additionalShareholder1fixedCapital: checkLocalStorageFor(
      formularName,
      'additionalShareholder1fixedCapital',
      ''
    ),
    additionalShareholder1CapitalContribution: checkLocalStorageFor(
      formularName,
      'additionalShareholder1CapitalContribution',
      ''
    ),
    additionalShareholder1TypeOfIdentification: checkLocalStorageFor(
      formularName,
      'additionalShareholder1TypeOfIdentification',
      'Personalausweis'
    ),
    additionalShareholder1PlaceOfBirth: checkLocalStorageFor(
      formularName,
      'additionalShareholder1PlaceOfBirth',
      ''
    ),
    additionalShareholder1Nationality: checkLocalStorageFor(
      formularName,
      'additionalShareholder1Nationality',
      ''
    ),
    additionalShareholder1IdentityCardNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder1IdentityCardNumber',
      ''
    ),
    additionalShareholder1IssuingAuthority: checkLocalStorageFor(
      formularName,
      'additionalShareholder1IssuingAuthority',
      ''
    ),
    additionalShareholder1IssuedDay: checkLocalStorageFor(
      formularName,
      'additionalShareholder1IssuedDay',
      ''
    ),
    additionalShareholder1IssuedMonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder1IssuedMonth',
      ''
    ),
    additionalShareholder1IssuedYear: checkLocalStorageFor(
      formularName,
      'additionalShareholder1IssuedYear',
      ''
    ),
    additionalShareholder1ValidDay: checkLocalStorageFor(
      formularName,
      'additionalShareholder1ValidDay',
      ''
    ),
    additionalShareholder1ValidMonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder1ValidMonth',
      ''
    ),
    additionalShareholder1ValidYear: checkLocalStorageFor(
      formularName,
      'additionalShareholder1ValidYear',
      ''
    ),
    additionalShareholder1Pep: checkLocalStorageFor(
      formularName,
      'additionalShareholder1Pep',
      'Nein'
    ),
    additionalShareholder1PrivateTaxOffice: checkLocalStorageFor(
      formularName,
      'additionalShareholder1PrivateTaxOffice',
      ''
    ),
    additionalShareholder1PrivateTaxNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder1PrivateTaxNumber',
      ''
    ),

    additionalShareholder2AcademicTitle: checkLocalStorageFor(
      formularName,
      'additionalShareholder2AcademicTitle',
      ''
    ),
    additionalShareholder2Firstname: checkLocalStorageFor(
      formularName,
      'additionalShareholder2Firstname',
      ''
    ),
    additionalShareholder2Lastname: checkLocalStorageFor(
      formularName,
      'additionalShareholder2Lastname',
      ''
    ),
    additionalShareholder2Birthday: checkLocalStorageFor(
      formularName,
      'additionalShareholder2Birthday',
      ''
    ),
    additionalShareholder2Birthmonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder2Birthmonth',
      ''
    ),
    additionalShareholder2Birthyear: checkLocalStorageFor(
      formularName,
      'additionalShareholder2Birthyear',
      ''
    ),
    additionalShareholder2Denomination: checkLocalStorageFor(
      formularName,
      'additionalShareholder2Denomination',
      ''
    ),
    additionalShareholder2TaxIdNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder2TaxIdNumber',
      ''
    ),
    additionalShareholder2Street: checkLocalStorageFor(
      formularName,
      'additionalShareholder2Street',
      ''
    ),
    additionalShareholder2HouseNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder2HouseNumber',
      ''
    ),
    additionalShareholder2ZipCode: checkLocalStorageFor(
      formularName,
      'additionalShareholder2ZipCode',
      ''
    ),
    additionalShareholder2City: checkLocalStorageFor(
      formularName,
      'additionalShareholder2City',
      ''
    ),
    additionalShareholder2PhoneNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder2PhoneNumber',
      ''
    ),
    additionalShareholder2Email: checkLocalStorageFor(
      formularName,
      'additionalShareholder2Email',
      ''
    ),
    additionalShareholder2fixedCapital: checkLocalStorageFor(
      formularName,
      'additionalShareholder2fixedCapital',
      ''
    ),
    additionalShareholder2CapitalContribution: checkLocalStorageFor(
      formularName,
      'additionalShareholder2CapitalContribution',
      ''
    ),
    additionalShareholder2TypeOfIdentification: checkLocalStorageFor(
      formularName,
      'additionalShareholder2TypeOfIdentification',
      'Personalausweis'
    ),
    additionalShareholder2PlaceOfBirth: checkLocalStorageFor(
      formularName,
      'additionalShareholder2PlaceOfBirth',
      ''
    ),
    additionalShareholder2Nationality: checkLocalStorageFor(
      formularName,
      'additionalShareholder2Nationality',
      ''
    ),
    additionalShareholder2IdentityCardNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder2IdentityCardNumber',
      ''
    ),
    additionalShareholder2IssuingAuthority: checkLocalStorageFor(
      formularName,
      'additionalShareholder2IssuingAuthority',
      ''
    ),
    additionalShareholder2IssuedDay: checkLocalStorageFor(
      formularName,
      'additionalShareholder2IssuedDay',
      ''
    ),
    additionalShareholder2IssuedMonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder2IssuedMonth',
      ''
    ),
    additionalShareholder2IssuedYear: checkLocalStorageFor(
      formularName,
      'additionalShareholder2IssuedYear',
      ''
    ),
    additionalShareholder2ValidDay: checkLocalStorageFor(
      formularName,
      'additionalShareholder2ValidDay',
      ''
    ),
    additionalShareholder2ValidMonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder2ValidMonth',
      ''
    ),
    additionalShareholder2ValidYear: checkLocalStorageFor(
      formularName,
      'additionalShareholder2ValidYear',
      ''
    ),
    additionalShareholder2Pep: checkLocalStorageFor(
      formularName,
      'additionalShareholder2Pep',
      'Nein'
    ),
    additionalShareholder2PrivateTaxOffice: checkLocalStorageFor(
      formularName,
      'additionalShareholder2PrivateTaxOffice',
      ''
    ),
    additionalShareholder2PrivateTaxNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder2PrivateTaxNumber',
      ''
    ),

    additionalShareholder3AcademicTitle: checkLocalStorageFor(
      formularName,
      'additionalShareholder3AcademicTitle',
      ''
    ),
    additionalShareholder3Firstname: checkLocalStorageFor(
      formularName,
      'additionalShareholder3Firstname',
      ''
    ),
    additionalShareholder3Lastname: checkLocalStorageFor(
      formularName,
      'additionalShareholder3Lastname',
      ''
    ),
    additionalShareholder3Birthday: checkLocalStorageFor(
      formularName,
      'additionalShareholder3Birthday',
      ''
    ),
    additionalShareholder3Birthmonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder3Birthmonth',
      ''
    ),
    additionalShareholder3Birthyear: checkLocalStorageFor(
      formularName,
      'additionalShareholder3Birthyear',
      ''
    ),
    additionalShareholder3Denomination: checkLocalStorageFor(
      formularName,
      'additionalShareholder3Denomination',
      ''
    ),
    additionalShareholder3TaxIdNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder3TaxIdNumber',
      ''
    ),
    additionalShareholder3Street: checkLocalStorageFor(
      formularName,
      'additionalShareholder3Street',
      ''
    ),
    additionalShareholder3HouseNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder3HouseNumber',
      ''
    ),
    additionalShareholder3ZipCode: checkLocalStorageFor(
      formularName,
      'additionalShareholder3ZipCode',
      ''
    ),
    additionalShareholder3City: checkLocalStorageFor(
      formularName,
      'additionalShareholder3City',
      ''
    ),
    additionalShareholder3PhoneNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder3PhoneNumber',
      ''
    ),
    additionalShareholder3Email: checkLocalStorageFor(
      formularName,
      'additionalShareholder3Email',
      ''
    ),
    additionalShareholder3fixedCapital: checkLocalStorageFor(
      formularName,
      'additionalShareholder3fixedCapital',
      ''
    ),
    additionalShareholder3CapitalContribution: checkLocalStorageFor(
      formularName,
      'additionalShareholder3CapitalContribution',
      ''
    ),
    additionalShareholder3TypeOfIdentification: checkLocalStorageFor(
      formularName,
      'additionalShareholder3TypeOfIdentification',
      'Personalausweis'
    ),
    additionalShareholder3PlaceOfBirth: checkLocalStorageFor(
      formularName,
      'additionalShareholder3PlaceOfBirth',
      ''
    ),
    additionalShareholder3Nationality: checkLocalStorageFor(
      formularName,
      'additionalShareholder3Nationality',
      ''
    ),
    additionalShareholder3IdentityCardNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder3IdentityCardNumber',
      ''
    ),
    additionalShareholder3IssuingAuthority: checkLocalStorageFor(
      formularName,
      'additionalShareholder3IssuingAuthority',
      ''
    ),
    additionalShareholder3IssuedDay: checkLocalStorageFor(
      formularName,
      'additionalShareholder3IssuedDay',
      ''
    ),
    additionalShareholder3IssuedMonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder3IssuedMonth',
      ''
    ),
    additionalShareholder3IssuedYear: checkLocalStorageFor(
      formularName,
      'additionalShareholder3IssuedYear',
      ''
    ),
    additionalShareholder3ValidDay: checkLocalStorageFor(
      formularName,
      'additionalShareholder3ValidDay',
      ''
    ),
    additionalShareholder3ValidMonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder3ValidMonth',
      ''
    ),
    additionalShareholder3ValidYear: checkLocalStorageFor(
      formularName,
      'additionalShareholder3ValidYear',
      ''
    ),
    additionalShareholder3Pep: checkLocalStorageFor(
      formularName,
      'additionalShareholder3Pep',
      'Nein'
    ),
    additionalShareholder3PrivateTaxOffice: checkLocalStorageFor(
      formularName,
      'additionalShareholder3PrivateTaxOffice',
      ''
    ),
    additionalShareholder3PrivateTaxNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder3PrivateTaxNumber',
      ''
    ),

    additionalShareholder4AcademicTitle: checkLocalStorageFor(
      formularName,
      'additionalShareholder4AcademicTitle',
      ''
    ),
    additionalShareholder4Firstname: checkLocalStorageFor(
      formularName,
      'additionalShareholder4Firstname',
      ''
    ),
    additionalShareholder4Lastname: checkLocalStorageFor(
      formularName,
      'additionalShareholder4Lastname',
      ''
    ),
    additionalShareholder4Birthday: checkLocalStorageFor(
      formularName,
      'additionalShareholder4Birthday',
      ''
    ),
    additionalShareholder4Birthmonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder4Birthmonth',
      ''
    ),
    additionalShareholder4Birthyear: checkLocalStorageFor(
      formularName,
      'additionalShareholder4Birthyear',
      ''
    ),
    additionalShareholder4Denomination: checkLocalStorageFor(
      formularName,
      'additionalShareholder4Denomination',
      ''
    ),
    additionalShareholder4TaxIdNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder4TaxIdNumber',
      ''
    ),
    additionalShareholder4Street: checkLocalStorageFor(
      formularName,
      'additionalShareholder4Street',
      ''
    ),
    additionalShareholder4HouseNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder4HouseNumber',
      ''
    ),
    additionalShareholder4ZipCode: checkLocalStorageFor(
      formularName,
      'additionalShareholder4ZipCode',
      ''
    ),
    additionalShareholder4City: checkLocalStorageFor(
      formularName,
      'additionalShareholder4City',
      ''
    ),
    additionalShareholder4PhoneNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder4PhoneNumber',
      ''
    ),
    additionalShareholder4Email: checkLocalStorageFor(
      formularName,
      'additionalShareholder4Email',
      ''
    ),
    additionalShareholder4fixedCapital: checkLocalStorageFor(
      formularName,
      'additionalShareholder4fixedCapital',
      ''
    ),
    additionalShareholder4CapitalContribution: checkLocalStorageFor(
      formularName,
      'additionalShareholder4CapitalContribution',
      ''
    ),
    additionalShareholder4TypeOfIdentification: checkLocalStorageFor(
      formularName,
      'additionalShareholder4TypeOfIdentification',
      'Personalausweis'
    ),
    additionalShareholder4PlaceOfBirth: checkLocalStorageFor(
      formularName,
      'additionalShareholder4PlaceOfBirth',
      ''
    ),
    additionalShareholder4Nationality: checkLocalStorageFor(
      formularName,
      'additionalShareholder4Nationality',
      ''
    ),
    additionalShareholder4IdentityCardNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder4IdentityCardNumber',
      ''
    ),
    additionalShareholder4IssuingAuthority: checkLocalStorageFor(
      formularName,
      'additionalShareholder4IssuingAuthority',
      ''
    ),
    additionalShareholder4IssuedDay: checkLocalStorageFor(
      formularName,
      'additionalShareholder4IssuedDay',
      ''
    ),
    additionalShareholder4IssuedMonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder4IssuedMonth',
      ''
    ),
    additionalShareholder4IssuedYear: checkLocalStorageFor(
      formularName,
      'additionalShareholder4IssuedYear',
      ''
    ),
    additionalShareholder4ValidDay: checkLocalStorageFor(
      formularName,
      'additionalShareholder4ValidDay',
      ''
    ),
    additionalShareholder4ValidMonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder4ValidMonth',
      ''
    ),
    additionalShareholder4ValidYear: checkLocalStorageFor(
      formularName,
      'additionalShareholder4ValidYear',
      ''
    ),
    additionalShareholder4Pep: checkLocalStorageFor(
      formularName,
      'additionalShareholder4Pep',
      'Nein'
    ),
    additionalShareholder4PrivateTaxOffice: checkLocalStorageFor(
      formularName,
      'additionalShareholder4PrivateTaxOffice',
      ''
    ),
    additionalShareholder4PrivateTaxNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder4PrivateTaxNumber',
      ''
    ),

    additionalShareholder5AcademicTitle: checkLocalStorageFor(
      formularName,
      'additionalShareholder5AcademicTitle',
      ''
    ),
    additionalShareholder5Firstname: checkLocalStorageFor(
      formularName,
      'additionalShareholder5Firstname',
      ''
    ),
    additionalShareholder5Lastname: checkLocalStorageFor(
      formularName,
      'additionalShareholder5Lastname',
      ''
    ),
    additionalShareholder5Birthday: checkLocalStorageFor(
      formularName,
      'additionalShareholder5Birthday',
      ''
    ),
    additionalShareholder5Birthmonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder5Birthmonth',
      ''
    ),
    additionalShareholder5Birthyear: checkLocalStorageFor(
      formularName,
      'additionalShareholder5Birthyear',
      ''
    ),
    additionalShareholder5Denomination: checkLocalStorageFor(
      formularName,
      'additionalShareholder5Denomination',
      ''
    ),
    additionalShareholder5TaxIdNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder5TaxIdNumber',
      ''
    ),
    additionalShareholder5Street: checkLocalStorageFor(
      formularName,
      'additionalShareholder5Street',
      ''
    ),
    additionalShareholder5HouseNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder5HouseNumber',
      ''
    ),
    additionalShareholder5ZipCode: checkLocalStorageFor(
      formularName,
      'additionalShareholder5ZipCode',
      ''
    ),
    additionalShareholder5City: checkLocalStorageFor(
      formularName,
      'additionalShareholder5City',
      ''
    ),
    additionalShareholder5PhoneNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder5PhoneNumber',
      ''
    ),
    additionalShareholder5Email: checkLocalStorageFor(
      formularName,
      'additionalShareholder5Email',
      ''
    ),
    additionalShareholder5fixedCapital: checkLocalStorageFor(
      formularName,
      'additionalShareholder5fixedCapital',
      ''
    ),
    additionalShareholder5CapitalContribution: checkLocalStorageFor(
      formularName,
      'additionalShareholder5CapitalContribution',
      ''
    ),
    additionalShareholder5TypeOfIdentification: checkLocalStorageFor(
      formularName,
      'additionalShareholder5TypeOfIdentification',
      'Personalausweis'
    ),
    additionalShareholder5PlaceOfBirth: checkLocalStorageFor(
      formularName,
      'additionalShareholder5PlaceOfBirth',
      ''
    ),
    additionalShareholder5Nationality: checkLocalStorageFor(
      formularName,
      'additionalShareholder5Nationality',
      ''
    ),
    additionalShareholder5IdentityCardNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder5IdentityCardNumber',
      ''
    ),
    additionalShareholder5IssuingAuthority: checkLocalStorageFor(
      formularName,
      'additionalShareholder5IssuingAuthority',
      ''
    ),
    additionalShareholder5IssuedDay: checkLocalStorageFor(
      formularName,
      'additionalShareholder5IssuedDay',
      ''
    ),
    additionalShareholder5IssuedMonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder5IssuedMonth',
      ''
    ),
    additionalShareholder5IssuedYear: checkLocalStorageFor(
      formularName,
      'additionalShareholder5IssuedYear',
      ''
    ),
    additionalShareholder5ValidDay: checkLocalStorageFor(
      formularName,
      'additionalShareholder5ValidDay',
      ''
    ),
    additionalShareholder5ValidMonth: checkLocalStorageFor(
      formularName,
      'additionalShareholder5ValidMonth',
      ''
    ),
    additionalShareholder5ValidYear: checkLocalStorageFor(
      formularName,
      'additionalShareholder5ValidYear',
      ''
    ),
    additionalShareholder5Pep: checkLocalStorageFor(
      formularName,
      'additionalShareholder5Pep',
      'Nein'
    ),
    additionalShareholder5PrivateTaxOffice: checkLocalStorageFor(
      formularName,
      'additionalShareholder5PrivateTaxOffice',
      ''
    ),
    additionalShareholder5PrivateTaxNumber: checkLocalStorageFor(
      formularName,
      'additionalShareholder5PrivateTaxNumber',
      ''
    ),
    order: false,
    electronicInvoicing: false,

    allowSepaForPrivateIban: checkLocalStorageFor(
      formularName,
      'allowSepaForPrivateIban',
      'Nein'
    ),
    allowSepaForBusinessIban: checkLocalStorageFor(
      formularName,
      'allowSepaForBusinessIban',
      'Nein'
    ),
    allowSepaForlimitedCompanyBusinessIban: checkLocalStorageFor(
      formularName,
      'allowSepaForlimitedCompanyBusinessIban',
      'Nein'
    ),
  }
}

export default initialFormValues
