import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import DashboardNavigation from '../../../components/Navigations/DashboardNavigation/DashboardNavigation'
import Table, {
  TableBody,
  TableData,
  TableHead,
  TableRow,
} from '../../../components/Table/Table'
import { getTaxConsultant } from '../../utility/utility'
import LoadingIndicator from '../../../components/ui-components/LoadingIndicator/LoadingIndicator'

import FastdocsClientLogo from '../../../static/fastdocs-client-logo.svg'
import { ReactComponent as EmptyInbox } from '../../../static/icons/80x80/inbox-80.svg'
import { ReactComponent as NoJobs } from '../../../static/icons/80x80/folder-80.svg'
import ClientJobDetailsDialog from '../../../components/Overlays/ClientJobDetailsDialog/ClientJobDetailsDialog'

import './ClientDataView.scss'
import StatusLabel from '../../../components/ui-components/StatusLabel/StatusLabel'

interface Props {
  clientContract: boolean
}

const ClientDataView = (props: Props) => {
  document.title = 'Personaldaten :: Dashboard'

  const [data, setData] = useState([{}])
  const [dialogData, setDialogData] = useState([])
  const [loading, setLoading] = useState(true)
  const [dialog, setDialog] = useState(false)
  const [containerClass, setContainerClass] = useState('')
  const [contentClass, setContentClass] = useState('')
  const [activeSearch, setActiveSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    const dbRef = 'clientJobs'

    firebase
      .database()
      .ref(dbRef)
      .on('value', (snapshot) => {
        let data: object[] = []

        snapshot.forEach((childSnapshot: any) => {
          const { taxConsultant } = childSnapshot.val()

          if (taxConsultant === getTaxConsultant()) {
            data.push({ [childSnapshot.key]: childSnapshot.val() })
          }
        })

        setData(data)
        setLoading(false)
      })

    return () => {
      firebase.database().ref(dbRef).off()
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (search) {
      setActiveSearch(true)

      let result: any = []

      data.map((initialData) => {
        return Object.entries(initialData).map((entry: any) => {
          const { companyName, firstname, lastname } = entry[1].formValues
          const client = companyName ? companyName : `${firstname} ${lastname}`
          const searchValue = search
          const formId = entry[0]
          const date = entry[1].formCreationDate
          const name = `${entry[1].formValues.firstname} ${entry[1].formValues.lastname}`

          if (
            formId.toLowerCase().includes(searchValue.toLowerCase()) ||
            client.toLowerCase().includes(searchValue.toLowerCase()) ||
            name.toLowerCase().includes(searchValue.toLowerCase()) ||
            date.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            result.push({ [formId]: entry[1] })
          }

          return null
        })
      })

      setFilteredData(result)
    } else {
      setActiveSearch(false)
      setFilteredData([])
    }

    // eslint-disable-next-line
  }, [search])

  const getRows = () => {
    return data.map((entry) => {
      return Object.entries(entry).map((entry: any, key) => {
        const { formCreationDate } = entry[1]
        const { companyName, firstname, lastname } = entry[1].formValues
        const formId = entry[0]
        const client = companyName ? companyName : `${firstname} ${lastname}`

        return (
          <TableRow onClick={(e) => openDialog(e)} key={key} datakey={formId}>
            <TableData>{formId}</TableData>
            <TableData>{client}</TableData>
            <TableData>{formCreationDate}</TableData>
            <TableData>
              <StatusLabel label="Abgeschlossen" status="finished" />
            </TableData>
          </TableRow>
        )
      })
    })
  }

  const getFilteredRows = () => {
    return filteredData.map((entry) => {
      return Object.entries(entry).map((entry: any, key) => {
        const { formCreationDate } = entry[1]
        const { companyName, firstname, lastname } = entry[1].formValues
        const formId = entry[0]
        const client = companyName ? companyName : `${firstname} ${lastname}`

        return (
          <TableRow onClick={(e) => openDialog(e)} key={key} datakey={formId}>
            <TableData>{formId}</TableData>
            <TableData>{client}</TableData>
            <TableData>{formCreationDate}</TableData>
            <TableData>
              <StatusLabel label="Abgeschlossen" status="finished" />
            </TableData>
          </TableRow>
        )
      })
    })
  }

  const renderTableData = () => {
    if (data.length === 0 && !loading) {
      return (
        <tbody>
          <tr className="empty">
            <td colSpan={20}>
              <NoJobs />
              <span>Aktuell sind keine Vorgänge vorhanden.</span>
            </td>
          </tr>
        </tbody>
      )
    }
    if (data.length !== 0 && !loading) {
      if (activeSearch) {
        if (filteredData.length !== 0) {
          return <TableBody>{getFilteredRows()}</TableBody>
        }
        return (
          <tbody>
            <tr className="empty">
              <td colSpan={20}>
                <EmptyInbox />
                <span>Die Suche hat leider kein Ergebniss gebracht.</span>
              </td>
            </tr>
          </tbody>
        )
      }
      return <TableBody>{getRows()}</TableBody>
    }
    return (
      <tbody>
        <tr>
          <td colSpan={20}>
            <LoadingIndicator size="small" />
          </td>
        </tr>
      </tbody>
    )
  }

  const openDialog = (e: any) => {
    const target = e.target.parentElement.dataset.key

    return data.map((entry) => {
      return Object.entries(entry).map((entry: any) => {
        if (target === entry[0]) {
          setDialogData(entry)
          setDialog(true)
          setTimeout(() => {
            setContainerClass('fade')
            setContentClass('move')
          }, 100)
        }
        return null
      })
    })
  }

  const closeDialog = () => {
    setContentClass('')
    setContainerClass('')

    setTimeout(() => {
      setDialog(false)
      setDialogData([])
    }, 300)
  }

  const renderDialog = () => {
    if (dialog && dialogData) {
      const headline = dialogData[0]
      const formId = dialogData[0]
      const { formValues, formCreationDate } = dialogData[1]
      const { formularName, firstname, lastname, companyName } = formValues
      const client = companyName ? companyName : `${firstname} ${lastname}`

      const deleteForm = () => {
        firebase
          .database()
          .ref(`clientJobs/${formId}`)
          .remove()
          .then(() => setActiveSearch(false))
          .then(() => setSearch(''))
          .then(() => setFilteredData([]))
          .then(() => closeDialog())
      }

      const deletionDay = () => {
        return moment(formCreationDate, 'D.M.YYYY')
          .add(14, 'days')
          .format('D.M.YYYY')
      }

      return (
        <ClientJobDetailsDialog
          headline={headline}
          formularName={formularName}
          formCreationDate={formCreationDate}
          client={client}
          deletionDay={deletionDay()}
          handleIconButtonClick={() => closeDialog()}
          handleDeleteFormClick={() => deleteForm()}
          containerAnimation={containerClass}
          contentAnimation={contentClass}
          formId={formId}
        />
      )
    }
  }

  const renderContent = () => {
    if (props.clientContract) {
      return (
        <Fragment>
          <Table
            onChange={(e) => setSearch(e.target.value)}
            defaultSearchValue={search}
            onResetClick={() => setSearch('')}
          >
            <TableHead
              headers={[
                'Formular-ID',
                'Mandant:in',
                'Ausgestellt am',
                'Status',
              ]}
            />
            {renderTableData()}
          </Table>
          {renderDialog()}
        </Fragment>
      )
    }
    return (
      <div className="card client-advertisement">
        <img src={FastdocsClientLogo} alt="Fastdocs Client Logo" width="200" />
        <h5>
          Erfassen Sie Mandanten­stammdaten mit Fastdocs Client vollständig und
          ohne Aufwand.
        </h5>
        <a
          className="button primary"
          href="https://www.fastdocs.de/client"
          rel="noopener noreferrer"
          target="_blank"
        >
          Mehr erfahren
        </a>
      </div>
    )
  }

  return (
    <div className="client-data-view">
      <DashboardNavigation />
      {renderContent()}
    </div>
  )
}

export default ClientDataView
