import React from 'react'
import Label from '../../ui-components/labels/Label/Label'
import DateInput from '../../ui-components/inputs/DateInput/DateInput'
import './DateInputField.scss'

const DateInputField = (props) => (
  <div className={'date-input-field'}>
    <Label>{props.label}</Label>
    <DateInput
      names={props.names}
      placeholder={props.placeholder}
      required={props.required}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      disabled={props.disabled}
      maxLength={props.maxLength}
      max={props.max}
      min={props.min}
      autoFocus={props.autoFocus}
      pattern={props.pattern}
      title={props.title}
    />
  </div>
)

export default DateInputField
