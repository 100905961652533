import React, { Fragment, useState } from 'react'

import IconButton from '../../ui-components/buttons/IconButton/IconButton'
import { ReactComponent as CrossIcon } from '../../../static/CrossIcon.svg'
import { ReactComponent as AlertIcon } from '../../../static/icons/48x48/alert-triangle-48.svg'

import EmailInputField from '../../Inputs/EmailInputField/EmailInputField'
import TextInputField from '../../Inputs/TextInputField/TextInputField'

import './ClerkDetailsDialog.scss'

interface Props {
  name: string
  email: string
  containerAnimation: string
  contentAnimation: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleIconButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleDeleteClerkClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleSaveClerkClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ClerkDetailsDialog = (props: Props) => {
  const [alert, setAlert] = useState(false)

  const {
    name,
    email,
    containerAnimation,
    contentAnimation,
    handleIconButtonClick,
    handleDeleteClerkClick,
    handleSaveClerkClick,
    onChange,
  } = props

  const renderButtons = () => {
    return (
      <Fragment>
        <button className="secondary" onClick={handleSaveClerkClick}>
          Speichern
        </button>
        <button
          className="danger"
          onClick={() => {
            setAlert(true)
          }}
        >
          Löschen
        </button>
      </Fragment>
    )
  }

  const renderAlertContent = () => {
    if (alert) {
      return (
        <div className="clerk-details-container--alert">
          <main>
            <AlertIcon />
            <h5>Soll {name} wirklich gelöscht werden?</h5>

            <button
              className="secondary full-width"
              onClick={handleDeleteClerkClick}
            >
              Ja, löschen
            </button>

            <button
              className="danger full-width"
              onClick={() => {
                setAlert(false)
              }}
            >
              Nein, nicht löschen
            </button>

            <p>
              Gelöschte SachbearbeiterInnen können nicht wiederhergestellt
              werden.
            </p>
          </main>
        </div>
      )
    }
  }

  const renderDialogContent = () => {
    return (
      <Fragment>
        <div className="clerk-details-container--header">
          <p>{name} bearbeiten</p>
          <IconButton onClick={handleIconButtonClick}>
            <CrossIcon />
          </IconButton>
        </div>

        <div className="clerk-details-container--details">
          <main>
            <TextInputField
              label="Name"
              defaultValue={name}
              placeholder={name}
              required
              name="name"
              onChange={onChange}
            />

            <EmailInputField
              label="E-Mail-Adresse"
              defaultValue={email}
              placeholder={email}
              required
              name="email"
              onChange={onChange}
            />
          </main>
        </div>

        <div className="clerk-details-container--options">
          {renderButtons()}
        </div>

        {renderAlertContent()}
      </Fragment>
    )
  }

  return (
    <div className={`clerk-details-container ${containerAnimation}`}>
      <div className="clerk-details-container--wrapper">
        <div className={`clerk-details-container--content ${contentAnimation}`}>
          {renderDialogContent()}
        </div>
      </div>
    </div>
  )
}

export default ClerkDetailsDialog
