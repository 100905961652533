export const getProgressValue = (formStep, totalFormSteps) =>
  Number(((formStep / totalFormSteps) * 100).toFixed(0))

export const getFormStep = (formStep, totalFormSteps) =>
  formStep === totalFormSteps ? totalFormSteps : formStep + 1

export const getFormId = () => {
  const pathname = document.location.pathname
  const id = pathname.substr(pathname.lastIndexOf('/') + 1)

  if (id === 'employee' || id === '') {
    return null
  } else {
    return id
  }
}

export const setDocumentTitleForEmployeeFormViews = (
  formStep,
  totalFormSteps,
  formularName
) => {
  document.title = `${getProgressValue(
    formStep,
    totalFormSteps
  )}% | ${formularName}`
}

export const getTaxConsultant = () => {
  const hostname = document.location.hostname.split('.')
  const subdomain = hostname[0]
  const taxConsultant = subdomain

  if (
    taxConsultant.includes('localhost') ||
    taxConsultant.includes('192') ||
    taxConsultant.includes('fastdocs-web-app-dev')
  ) {
    return 'dev-test-account'
  }

  return taxConsultant
}

export const setStorage = () => {
  if (getTaxConsultant() === 'dev-test-account') {
    return 'tax-consultants'
  }
  return 'fastdocs-web-app-tax-consultants'
}
