import React from 'react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as AddEmployeeIcon } from '../../../static/icons/16x16/add-employee-icon-16.svg'
import { ReactComponent as ClientIcon } from '../../../static/icons/16x16/client-icon-16.svg'
import { ReactComponent as SettingsIcon } from '../../../static/icons/16x16/settings-icon-16.svg'
import { ReactComponent as UsersIcon } from '../../../static/icons/16x16/users-icon-16.svg'
import { ReactComponent as ClipboardIcon } from '../../../static/icons/16x16/clipboard-icon-16.svg'

import classes from './DashboardNavigation.module.scss'

const DashboardNavigation = () => {
  return (
    <nav className={classes.dashboardNavigation}>
      <div className={classes.linkContainer}>
        <ul>
          <li>
            <NavLink
              exact
              to="/dashboard/staff"
              activeClassName={classes.active}
            >
              <AddEmployeeIcon />
              Neues Personal
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/dashboard/client"
              activeClassName={classes.active}
            >
              <ClientIcon />
              Neue Mandanten
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/dashboard/other"
              activeClassName={classes.active}
            >
              <ClipboardIcon />
              Andere Formulare
            </NavLink>
          </li>
        </ul>

        <ul>
          <li>
            <NavLink to="/dashboard/clerks" activeClassName={classes.active}>
              <UsersIcon />
              SachbearbeiterInnen
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/settings" activeClassName={classes.active}>
              <SettingsIcon />
              Einstellungen
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default DashboardNavigation
