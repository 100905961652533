import React from 'react'
import TextInputWithOption from '../../ui-components/inputs/TextInputWithOption/TextInputWithOption'
import Label from '../../ui-components/labels/Label/Label'

import './TextInputFieldWithOptions.scss'

interface Props {
  label: string
  type: 'number' | 'text'
  optionName: string
  options: string[]
  name: string
  placeholder: string
  required?: boolean
  defaultValue: string
  optionDefaultValue: string
  maxLength?: number
  step?: number
  pattern?: string
  title?: string
  max?: string
  min?: string
  disabled?: boolean
  autoFocus?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onOptionChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const TextInputFieldWithOptions = (props: Props) => {
  const {
    label,
    type,
    optionName,
    options,
    name,
    placeholder,
    required,
    defaultValue,
    maxLength,
    step,
    pattern,
    title,
    max,
    min,
    disabled,
    autoFocus,
    optionDefaultValue,
    onChange,
    onOptionChange,
  } = props

  return (
    <div className={'text-input-field-with-options'}>
      <Label>{label}</Label>
      <TextInputWithOption
        type={type}
        name={name}
        placeholder={placeholder}
        required={required}
        defaultValue={defaultValue}
        onChange={onChange}
        onOptionChange={onOptionChange}
        disabled={disabled}
        maxLength={maxLength}
        autoFocus={autoFocus}
        title={title}
        max={max}
        min={min}
        step={step}
        pattern={pattern}
        optionName={optionName}
        options={options}
        optionDefaultValue={optionDefaultValue}
      />
    </div>
  )
}

export default TextInputFieldWithOptions
