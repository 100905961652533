import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import RadioInputField from '../../Inputs/RadioInputField/RadioInputField'
import NumberInputField from '../../Inputs/NumberInputField/NumberInputField'

import './ParentsPropertyForm.scss'

class ParentsPropertyForm extends PureComponent {
  state = {
    showFormFields:
      this.props.taxClass === 'V - Steuerklasse Fünf' ? false : true,
    showFormFieldOptions:
      this.props.taxClass === 'II - Steuerklasse Zwei' ? false : true,
  }

  renderFormFields = () => {
    if (this.state.showFormFields === true) {
      if (this.state.showFormFieldOptions === true) {
        return (
          <React.Fragment>
            <RadioInputField
              label="Hast Du ein oder mehrere Kinder?"
              name="parentsProperty"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={this.props.parentsProperty === 'Nein' ? 0 : 1}
              onChange={this.props.onChange}
            />
            {this.props.parentsProperty !== 'Nein' && (
              <>
                <NumberInputField
                  label="Anzahl der Kinderfreibeträge"
                  placeholder="2"
                  name="amountOfChildren"
                  defaultValue={this.props.amountOfChildren}
                  step="0.5"
                  max="5"
                  min="0"
                  onChange={this.props.onChange}
                  required
                />
                <RadioInputField
                  label="Bist Du Alleinerziehend?"
                  name="singleParent"
                  radioOptions={['Nein', 'Ja']}
                  defaultChecked={this.props.singleParent === 'Nein' ? 0 : 1}
                  onChange={this.props.onChange}
                />
              </>
            )}
          </React.Fragment>
        )
      }
      return (
        <>
          <NumberInputField
            label="Anzahl der Kinderfreibeträge"
            placeholder="2"
            name="amountOfChildren"
            defaultValue={this.props.amountOfChildren}
            step="0.5"
            max="5"
            min="0"
            onChange={this.props.onChange}
            required
          />
          <RadioInputField
            label="Bist Du Alleinerziehend?"
            name="singleParent"
            radioOptions={['Nein', 'Ja']}
            defaultChecked={this.props.singleParent === 'Nein' ? 0 : 1}
            onChange={this.props.onChange}
          />
        </>
      )
    }
    return (
      <React.Fragment>
        <RadioInputField
          label="Hast Du ein oder mehrere Kinder?"
          name="parentsProperty"
          radioOptions={['Nein', 'Ja']}
          defaultChecked={this.props.parentsProperty === 'Nein' ? 0 : 1}
          onChange={this.props.onChange}
        />
        <RadioInputField
          label="Bist Du Alleinerziehend?"
          name="singleParent"
          radioOptions={['Nein', 'Ja']}
          defaultChecked={this.props.singleParent === 'Nein' ? 0 : 1}
          onChange={this.props.onChange}
        />
      </React.Fragment>
    )
  }

  render() {
    return (
      <FormContainer
        className={'parents-property-form'}
        onSubmit={this.props.onSubmit}
      >
        <HelperButton onClick={this.props.onHelperButtonClick} />
        <FormHeader headlineText={'Elterneigenschaft'} />
        <FormBody>{this.renderFormFields()}</FormBody>
        <FormFooter
          primaryButtonText={'Weiter'}
          secondaryButtonText="Zurück"
          onSecondaryButtonClick={this.props.onSecondaryButtonClick}
        />
      </FormContainer>
    )
  }
}

ParentsPropertyForm.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onHelperButtonClick: PropTypes.func,
  parentsProperty: PropTypes.string.isRequired,
}

export default ParentsPropertyForm
