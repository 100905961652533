import React, { Fragment, useState, useEffect } from 'react'
import { detect } from 'detect-browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import FormStage from 'components/Stages/FormStage/FormStage'
import HelpOverForLimitedCompany from 'components/Overlays/HelpOver/fastdocs-client/HelpOverForLimitedCompany/HelpOverForLimitedCompany'
import NotificationCard from 'components/Notifications/NotificationCard/NotificationCard'
import PersonalInformationForm from 'components/Forms/fastdocs-client/PersonalInformationForm/PersonalInformationForm'
import IdentifcationForm from 'components/Forms/fastdocs-client/IdentifcationForm/IdentifcationForm'
import { ReactComponent as InfoIcon } from 'static/InformationIcon.svg'

import {
  getFormStep,
  getProgressValue,
  getTaxConsultant,
} from 'views/utility/utility.jsx'
import createFormId from 'views/utility/createFormId'
import AutofillButton from 'helpers/autofill/AutofillButton'
import autofillData from 'helpers/autofill/fastdocs-client/limitedEntrepreneurialCompany'
import BankInformationForm from 'components/Forms/fastdocs-client/BankInformationForm/BankInformationForm'
import TaxOfficeForm from 'components/Forms/fastdocs-client/TaxOfficeForm/TaxOfficeForm'
import TaxOfficeProxyForm from 'components/Forms/fastdocs-client/TaxOfficeProxyForm/TaxOfficeProxyForm'
import ScopeOfOrderForm from 'components/Forms/fastdocs-client/ScopeOfOrderForm/ScopeOfOrderForm'
import SummaryForm from 'components/Forms/fastdocs-client/SummaryForm/SummaryForm'
import SuccessForm from 'components/Forms/fastdocs-client/SuccessForm/SuccessForm'
import CompanyInformationForm from 'components/Forms/fastdocs-client/CompanyInformationForm/CompanyInformationForm'
import saveFormValuesInLocalStorage from 'views/utility/saveFormValuesInLocalStorage'
import clearFormValuesFromLocalStorage from 'views/utility/clearFormValuesFromLocalStorage'
import StartForm from 'components/Forms/fastdocs-client/StartForm/StartForm'
import CompanionForm from 'components/Forms/fastdocs-client/CompanionForm/CompanionForm'
import ExternalManagingDirectorForm from 'components/Forms/fastdocs-client/ExternalManagingDirectorForm/ExternalManagingDirectorForm'
import initialFormValues from './initialFormValues'

interface Props {
  features: {
    sepa: boolean
  }
}

const LimitedEntrepreneurialCompany = (props: Props) => {
  const [formId] = useState(createFormId())
  const [taxConsultant] = useState(getTaxConsultant())
  const [taxConsultantName, setTaxConsultantName] = useState('')
  const [taxConsultantContactInformation, setTaxConsultantContactInformation] =
    useState({})
  const totalFormSteps = 11
  const [formStep, setFormStep] = useState(0)
  const [helpover, setHelpover] = useState(false)
  const [formularName] = useState('UG (Unternehmergesellschaft)')
  const [formValues, setFormValues] = useState(initialFormValues(formularName))
  const formCreationDate = new Date().toLocaleString()

  document.title =
    formValues.formularName +
    ' | ' +
    getProgressValue(formStep, totalFormSteps) +
    '% erledigt'

  useEffect(() => {
    Object.entries(formValues).forEach((entry) => {
      const key = entry[0]
      const value = entry[1].toString()

      if (key.includes('day') || key.includes('month')) {
        if (value && value.length > 0 && value.length <= 1) {
          const newValue = `0${value}`

          setFormValues({
            ...formValues,
            [key]: newValue,
          })
        }
      }
    })

    firebase
      .database()
      .ref('taxConsultant/' + taxConsultant)
      .once('value', (snapshot) => {
        const { name, contactInformation } = snapshot.val()
        setTaxConsultantName(name)
        setTaxConsultantContactInformation(contactInformation)
      })
  }, [formValues, taxConsultant, taxConsultantName])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const consultantContactInformation = taxConsultantContactInformation || null

    firebase
      .database()
      .ref('clientJobs/' + formId)
      .set({
        formValues,
        taxConsultant,
        taxConsultantName,
        formCreationDate,
        consultantContactInformation,
      })
      .then(() => {
        setFormStep(formStep + 1)
        clearFormValuesFromLocalStorage(formValues)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const handleNextButtonClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    saveFormValuesInLocalStorage(formValues)
    setFormStep(formStep + 1)
  }

  const handleBackButtonClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFormStep(formStep - 1)
  }

  const handleTaxOfficeSuggestionClick = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault()
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    })
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.checked,
    })
  }

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault()
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    })
  }

  const renderFormSteps = () => {
    switch (formStep) {
      case 0:
        return (
          <StartForm
            type="limited-entrepreneurial-company"
            onNextButtonClick={handleNextButtonClick}
            onChange={handleInputChange}
            values={formValues}
          />
        )
      case 1:
        return (
          <CompanyInformationForm
            type="limited-entrepreneurial-company"
            values={formValues}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            onNextButtonClick={handleNextButtonClick}
            onBackButtonClick={handleBackButtonClick}
          />
        )
      case 2:
        return (
          <BankInformationForm
            type="limited-entrepreneurial-company"
            values={formValues}
            features={props.features}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            onNextButtonClick={handleNextButtonClick}
            onBackButtonClick={handleBackButtonClick}
          />
        )
      case 3:
        return (
          <TaxOfficeForm
            type="limited-entrepreneurial-company"
            values={formValues}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            onNextButtonClick={handleNextButtonClick}
            onBackButtonClick={handleBackButtonClick}
            onSuggestionClick={(e) => handleTaxOfficeSuggestionClick(e)}
            onOptionChange={(e) => handleOptionChange(e)}
          />
        )
      case 4:
        return (
          <PersonalInformationForm
            type="limited-entrepreneurial-company"
            values={formValues}
            onChange={handleInputChange}
            onSameShareholderChange={() => {}}
            onHelperButtonClick={() => setHelpover(true)}
            onNextButtonClick={handleNextButtonClick}
            onBackButtonClick={handleBackButtonClick}
          />
        )
      case 5:
        return (
          <IdentifcationForm
            type="limited-entrepreneurial-company"
            values={formValues}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            onNextButtonClick={handleNextButtonClick}
            onBackButtonClick={handleBackButtonClick}
          />
        )
      case 6:
        return (
          <CompanionForm
            type="limited-entrepreneurial-company"
            values={formValues}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            onNextButtonClick={handleNextButtonClick}
            onBackButtonClick={handleBackButtonClick}
          />
        )
      case 7:
        return (
          <ExternalManagingDirectorForm
            values={formValues}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            onNextButtonClick={handleNextButtonClick}
            onBackButtonClick={handleBackButtonClick}
          />
        )
      case 8:
        return (
          <TaxOfficeProxyForm
            values={formValues}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            onNextButtonClick={handleNextButtonClick}
            onBackButtonClick={handleBackButtonClick}
          />
        )
      case 9:
        return (
          <ScopeOfOrderForm
            type="limited-entrepreneurial-company"
            values={formValues}
            onCheckboxClick={handleCheckboxChange}
            onHelperButtonClick={() => setHelpover(true)}
            onNextButtonClick={handleNextButtonClick}
            onBackButtonClick={handleBackButtonClick}
          />
        )
      case 10:
        return (
          <SummaryForm
            type="limited-entrepreneurial-company"
            values={formValues}
            features={props.features}
            onHelperButtonClick={() => setHelpover(true)}
            onSubmit={handleSubmit}
            onBackButtonClick={handleBackButtonClick}
          />
        )
      case 11:
        return <SuccessForm formId={formId} type="default" />
    }
  }

  const renderHelpOver = () => {
    const handleHelpOverClose = () => {
      setHelpover(false)
    }

    return (
      <HelpOverForLimitedCompany
        onCloseButtonClick={handleHelpOverClose}
        className={helpover === true ? 'show' : ''}
        formStep={formStep}
        formId={formId}
      />
    )
  }

  const showEdgeNotification = () => {
    const closeEdgeNotification = () => {
      const notificationCard =
        document.querySelector<HTMLElement>('.notification-card')
      if (notificationCard) {
        notificationCard.style.display = 'none'
      }
    }

    const browser = detect()

    if (browser && browser.name === 'edge') {
      return (
        <NotificationCard
          icon={<InfoIcon />}
          title="Wichtiger Hinweis:"
          text="Dein Browser erwartet bei den Nachkommastellen eventuell einen Punkt statt eines Kommas."
          onClick={closeEdgeNotification}
        />
      )
    }
  }

  return (
    <Fragment>
      <FormStage
        headlineText={formularName}
        totalSteps={totalFormSteps}
        actualStep={getFormStep(formStep, totalFormSteps)}
        progress={getProgressValue(formStep, totalFormSteps)}
      />
      {renderFormSteps()}
      {renderHelpOver()}
      {showEdgeNotification()}
      <AutofillButton
        onClick={() => {
          setFormValues({
            ...formValues,
            ...autofillData,
          })
        }}
      />
    </Fragment>
  )
}

export default LimitedEntrepreneurialCompany
