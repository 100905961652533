import React from 'react'
import classes from './RadioGroup.module.scss'

interface RadioGroupOptionProps {
  name: string
  value: string | number
  defaultChecked: boolean
  label: string
  description?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const RadioGroupOption = (props: RadioGroupOptionProps) => {
  return (
    <label>
      <div className={classes.inputContainer}>
        <input
          type="radio"
          name={props.name}
          defaultChecked={props.defaultChecked}
          value={props.value}
          onChange={props.onChange}
        />
      </div>
      <div className={classes.description}>
        {props.label}
        <span>{props.description}</span>
      </div>
    </label>
  )
}

interface Props {
  label: string
  children: React.ReactNode
}

export const RadioGroup = (props: Props) => {
  return (
    <div className={classes.radioGroupContainer}>
      <label>{props.label}</label>
      <div className={classes.radioGroup}>{props.children}</div>
    </div>
  )
}

export default RadioGroup
