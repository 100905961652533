import React from 'react'
import { Link } from 'react-router-dom'

import { getTaxConsultant } from '../../../views/utility/utility'
import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import './SuccessForm.scss'

const createDevQuickLink = props => {
  const formularPath = window.location.pathname.split('/')[1]
  const userPath =
    window.location.pathname.split('/')[2] === 'client'
      ? 'employee'
      : 'consultant'

  if (
    process.env.NODE_ENV === 'development' ||
    getTaxConsultant() === 'dev-test-account' ||
    getTaxConsultant() === 'test-account'
  ) {
    if (window.location.pathname.includes('immediate-report/client')) {
      return (
        <Link to={`/immediate-report/consultant/${props.formId}`}>
          {props.formId}
        </Link>
      )
    }
    return (
      <Link to={`/${formularPath}/${userPath}/${props.formId}`}>
        {props.formId}
      </Link>
    )
  } else {
    return props.formId
  }
}

const SuccessForm = props => (
  <FormContainer className={'success-form'} onSubmit={props.onSubmit}>
    <main className={'form-body'}>
      <h4>
        <span role="img" aria-label="daumen-hoch">
          👍
        </span>{' '}
        {props.successText}
      </h4>
      {props.formId ? (
        <h5>
          Formular-ID: <span>{createDevQuickLink(props)}</span>
        </h5>
      ) : null}
      {props.children}
    </main>
  </FormContainer>
)

export default SuccessForm
