import React, { ChangeEvent } from 'react'
import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import CheckboxField from '../../Inputs/CheckboxField/CheckboxField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import './FinalDeclarationsForm.scss'

interface Props {
  onSubmit: (e: ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: ChangeEvent<HTMLInputElement>) => void
  onSecondaryButtonClick: (e: ChangeEvent<HTMLInputElement>) => void
}

const FinalDeclarationsForm = (props: Props) => (
  <FormContainer
    className={'final-declarations-form'}
    onSubmit={props.onSubmit}
  >
    <HelperButton onClick={props.onHelperButtonClick} />
    <FormHeader headlineText={'Abschlusserklärung'} />
    <FormBody>
      <CheckboxField
        label={
          'Ich versichere, dass die Angaben der Wahrheit entsprechen. Ich verpflichte mich, meinem Arbeitgeber alle Änderungen, insbesondere in Bezug auf weitere Beschäftigungen nach Art, Dauer und Entgelt unverzüglich mitzuteilen.'
        }
        name="truthCheck"
        required
      />
    </FormBody>
    <FormFooter
      primaryButtonText={'Fertigstellen'}
      secondaryButtonText="Zurück"
      onSecondaryButtonClick={props.onSecondaryButtonClick}
    />
  </FormContainer>
)

export default FinalDeclarationsForm
