import React from 'react'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import TextInputField from '../../Inputs/TextInputField/TextInputField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'

import DateInputField from '../../Inputs/DateInputField/DateInputField'
import PrependNumberInputField from '../../Inputs/PrependNumberInputField/PrependNumberInputField'
import SelectField from '../../Selects/SelectField/SelectField'

import './VehicleForm.scss'
import EmailInputField from '../../Inputs/EmailInputField/EmailInputField'

interface Props {
  formValues: {
    company: string
    employeeName: string
    dayOfUse: string
    monthOfUse: string
    yearOfUse: string
    vehicleModel: string
    vehicleRegistrationNumber: string
    grossListPrice: string
    typeOfVehicle: string
    clientEmail: string
  }
  selectedClerk?: string
  listOfClerks?: string[]
  showClerkSeletion?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  onHelperButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClerkChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const VehicleForm = (props: Props) => {
  const {
    company,
    employeeName,
    dayOfUse,
    monthOfUse,
    yearOfUse,
    vehicleModel,
    vehicleRegistrationNumber,
    grossListPrice,
    typeOfVehicle,
    clientEmail,
  } = props.formValues

  const renderFormularInputs = () => {
    return (
      <React.Fragment>
        <TextInputField
          defaultValue={company}
          label="Name des Unternehmens"
          name="company"
          onChange={props.onChange}
          placeholder="Wunderbox GmbH"
          required
        />
        <TextInputField
          defaultValue={employeeName}
          label="Name ArbeitnehmerIn"
          name="employeeName"
          onChange={props.onChange}
          placeholder="Jan Brunner"
          required
        />
        <DateInputField
          label="Ab wann wird das Fahrzeug genutzt?"
          names={['dayOfUse', 'monthOfUse', 'yearOfUse']}
          onChange={props.onChange}
          placeholder={['TT', 'MM', 'JJJJ']}
          maxLength={['2', '2', '4']}
          max={['31', '12', new Date().getFullYear() + 5]}
          min={['1', '1', new Date().getFullYear() - 5]}
          pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
          required
          defaultValue={[dayOfUse, monthOfUse, yearOfUse]}
        />
        <SelectField
          label="Fahrzeugtyp"
          name="typeOfVehicle"
          defaultValue={typeOfVehicle}
          onChange={props.onChange}
          required
          options={['Elektro', 'Hybrid', 'Verbrenner']}
          noDefault
        />
        <TextInputField
          defaultValue={vehicleModel}
          label="Bezeichnung des Fahrzeugmodells"
          name="vehicleModel"
          onChange={props.onChange}
          placeholder="BMW 330i"
          required
        />
        <PrependNumberInputField
          prependText="€"
          min={0}
          step={0.01}
          defaultValue={grossListPrice}
          label="Höhe des Bruttolistenpreises (Neupreis)"
          name="grossListPrice"
          onChange={props.onChange}
          placeholder="56000"
          required
        />
        <TextInputField
          defaultValue={vehicleRegistrationNumber}
          label="Kennzeichen"
          name="vehicleRegistrationNumber"
          onChange={props.onChange}
          placeholder="ME FD 1337"
          required
        />
        <hr />
        <EmailInputField
          autoFocus
          defaultValue={clientEmail}
          label="Deine E-Mail Adresse"
          name="clientEmail"
          onChange={props.onChange}
          placeholder={'meine-email@adresse.de'}
          required
        />
        {props.showClerkSeletion ? (
          <SelectField
            name="selectedClerk"
            onChange={props.onClerkChange}
            required
            noDefault
            defaultValue={props.selectedClerk}
            options={props.listOfClerks}
            label="SachbearbeiterIn in der Kanzlei"
          />
        ) : null}
      </React.Fragment>
    )
  }

  return (
    <FormContainer className={'vehicle-form'} onSubmit={props.onSubmit}>
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Informationen zum Fahrzeug'} />
      <FormBody>{renderFormularInputs()}</FormBody>
      <FormFooter primaryButtonText="Fertigstellen" />
    </FormContainer>
  )
}

export default VehicleForm
