import React from 'react'
import './LoadingIndicator.scss'

interface Props {
  center?: boolean
  size?: 'normal' | 'small' | 'mini'
}

const LoadingIndicator = (props: Props) => {
  const { center, size } = props

  if (size === 'small') {
    return (
      <div className={center ? 'loading-container' : ''}>
        <div className="loading-view">
          <div className="loading-small"></div>
        </div>
      </div>
    )
  }

  if (size === 'mini') {
    return (
      <div className={center ? 'loading-container' : ''}>
        <div className="loading-view">
          <div className="loading-mini"></div>
        </div>
      </div>
    )
  }

  return (
    <div className={center ? 'loading-container' : ''}>
      <div className="loading-view">
        <svg width="24" height="24" viewBox="0 0 15 16" fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.98432 0C2.8918 0 0.384815 2.50697 0.384815 5.59951V8.76174C0.384701 8.77422 0.384644 8.78671 0.384644 8.79922V11.9989C0.384644 14.2079 2.17535 16 4.3843 16H8.38393V11.199H11.5836V6.39943H14.7436V0H5.98432ZM1.98467 9.59914V8.79922C1.98467 7.47385 3.05908 6.39943 4.38446 6.39943H9.98378V9.59914H1.98467ZM11.5836 4.79957H13.1438V1.59986H5.98432C3.77552 1.59986 1.9849 3.39034 1.98467 5.59908C2.65312 5.09705 3.48397 4.79957 4.3843 4.79957H11.5836ZM1.9845 11.9989V11.199H6.78407V14.4H4.3843C3.05892 14.4 1.9845 13.3243 1.9845 11.9989Z"
            fill="#e6e8eb"
          />
        </svg>
        <div className="loading"></div>
      </div>
    </div>
  )
}

export default LoadingIndicator
