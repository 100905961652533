const clientFormValues = {
  company: 'Wunderbox GmbH',
  firstname: 'Ed',
  lastname: 'Therobot',
  employedAs: 'Steuerberater',
  entryday: '01',
  entrymonth: (new Date().getMonth() + 2).toString(),
  entryyear: new Date().getFullYear().toString(),
  typeOfEmployee: 'Angestellter',
  handycapped: 'Unbekannt',
  employmentLocation: 'Haan',
  contractForm: 'Befristet Vollzeit',
  contractLimitDay: '01',
  contractLimitMonth: '04',
  contractLimitYear: '2024',
  sallary: 'Festes monatliches Gehalt',
  sallaryValue: '2500',
  payingOption: 'Überweisung',
  hollidays: '30',
  monday: '8',
  tuesday: '8',
  wednesday: '8',
  thursday: '8',
  friday: '8',
  saturday: '0',
  sunday: '0',
  capitalAccumulation: 'Ja',
  monthlyProportion: '30',
  companyCar: true,
  companyPensionPlan: false,
  jobTicket: false,
  travelAllowance: true,
  sallaryIncreaseAfterTrialPeriod: true,
  otherInformation:
    '- Arbeitet in der Abteilung X\n- Wird nach dem 20. ausgezahlt',
}

export default clientFormValues
