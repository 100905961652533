import React from 'react'
import './IconButton.scss'

const IconButton = (props) => {
  const setTooltipPosition = () => {
    if (props.tooltipPosition === 'left') {
      return 'tooltip tooltip__left'
    } else {
      return 'tooltip tooltip__right'
    }
  }
  return (
    <div className={'icon-button'} onClick={props.onClick}>
      {props.children}
      {props.tooltip ? (
        <div className={setTooltipPosition()}>{props.tooltip}</div>
      ) : null}
    </div>
  )
}

export default IconButton
