import React from 'react'

import { ReactComponent as SearchIcon } from '../../static/icons/16x16/search-icon-16.svg'
import { ReactComponent as CrossIcon } from '../../static/CrossIcon.svg'
import { ReactComponent as PlusIcon } from '../../static/icons/16x16/plus-icon-16.svg'
import './Table.scss'

export const TableHead = (props: { headers: string[] }) => {
  const { headers } = props

  return (
    <thead>
      <tr>
        {headers.map((head, key) => (
          <th key={key} title={head}>
            {head}
          </th>
        ))}
      </tr>
    </thead>
  )
}

export const TableBody = (props: { children: React.ReactNode }) => {
  const { children } = props

  return <tbody>{children}</tbody>
}

export const TableRow = (props: {
  datakey?: string
  children: React.ReactNode
  onClick: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void
}) => {
  const { children, datakey, onClick } = props

  return (
    <tr onClick={onClick} data-key={datakey}>
      {children}
    </tr>
  )
}

export const TableData = (props: { children: React.ReactNode }) => {
  const { children } = props

  return <td>{children}</td>
}

export const Table = (props: {
  children: React.ReactNode
  defaultSearchValue: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onResetClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  primaryAction?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {
  const {
    children,
    defaultSearchValue,
    onChange,
    onResetClick,
    primaryAction,
  } = props

  return (
    <section className="table">
      <div className="card table-container">
        <div className="actions-bar">
          <div className="search">
            <SearchIcon className="icon" />
            <input
              type="text"
              placeholder="Formular-ID, Unternehmen, Name, Datum"
              onChange={onChange}
              name="searchField"
              value={defaultSearchValue}
              title="Tabelle durchsuchen"
            />
            {defaultSearchValue ? (
              <button
                className="secondary reset"
                title="Suche zurücksetzen"
                onClick={onResetClick}
              >
                <CrossIcon />
              </button>
            ) : null}
          </div>

          {primaryAction ? (
            <div className="actions">
              <button className="ghost icon" onClick={primaryAction}>
                <PlusIcon />
              </button>
            </div>
          ) : null}
        </div>
        <div className="table-content">
          <table cellPadding="0" cellSpacing="0">
            {children}
          </table>
        </div>
      </div>
    </section>
  )
}

export default Table
