import React, { Fragment } from 'react'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../../ui-components/buttons/HelperButton/HelperButton'
import SelectField from '../../../Selects/SelectField/SelectField'
import TextInputField from '../../../Inputs/TextInputField/TextInputField'
import DateInputField from '../../../Inputs/DateInputField/DateInputField'
import NumberInputField from '../../../Inputs/NumberInputField/NumberInputField'
import EmailInputField from '../../../Inputs/EmailInputField/EmailInputField'
import TwoColumns from '../../../ui-components/layouts/TwoColumns/TwoColumns'

import {
  validateSocialSecurityNumber,
  validateBirthdate,
} from '../../utility/socialSecurityNumberValidation'
import { tinValidation } from 'components/utility/tinValidation'
import nations from '../IdentifcationForm/countries.json'

import classes from './CompanionForm.module.scss'
import RadioInputField from '../../../Inputs/RadioInputField/RadioInputField'
import PrependNumberInputField from '../../../Inputs/PrependNumberInputField/PrependNumberInputField'

interface Props {
  type: 'private' | 'limited-company' | 'limited-entrepreneurial-company'
  values: {
    is_companion_a_natural_person: string
    companion_company_name: string
    married: string
    companionAcademicTitle: string
    companionFirstname: string
    companionLastname: string
    companionBirthday: string
    companionBirthmonth: string
    companionBirthyear: string
    companionDenomination: string
    companionSocialSecurityNumber: string
    companionTaxIdNumber: string
    companionStreet: string
    companionHouseNumber: string
    companionZipCode: string
    companionCity: string
    companionPhoneNumber: string
    companionEmail: string
    companionTypeOfIdentification: string
    companionPlaceOfBirth: string
    companionPep: string
    companionNationality: string
    companionIdentityCardNumber: string
    companionIssuingAuthority: string
    companionIssuedDay: string
    companionIssuedMonth: string
    companionIssuedYear: string
    companionValidDay: string
    companionValidMonth: string
    companionValidYear: string
    companionShareCapital: string
    additionalShareHolder: string
    marriedDay: string
    marriedMonth: string
    marriedYear: string
  }
  onHelperButtonClick: () => void
  onNextButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onBackButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const CompanionForm = (props: Props) => {
  const {
    type,
    values: {
      is_companion_a_natural_person,
      companion_company_name,
      married,
      companionAcademicTitle,
      companionFirstname,
      companionLastname,
      companionBirthday,
      companionBirthmonth,
      companionBirthyear,
      companionDenomination,
      companionSocialSecurityNumber,
      companionTaxIdNumber,
      companionStreet,
      companionHouseNumber,
      companionZipCode,
      companionCity,
      companionPhoneNumber,
      companionEmail,
      companionTypeOfIdentification,
      companionPlaceOfBirth,
      companionPep,
      companionNationality,
      companionIdentityCardNumber,
      companionIssuingAuthority,
      companionIssuedDay,
      companionIssuedMonth,
      companionIssuedYear,
      companionValidDay,
      companionValidMonth,
      companionValidYear,
      additionalShareHolder,
      companionShareCapital,
      marriedDay,
      marriedMonth,
      marriedYear,
    },
    onHelperButtonClick,
    onNextButtonClick,
    onChange,
    onBackButtonClick,
  } = props

  const setSocialSecurityNumberValidationLabel = () => {
    if (companionSocialSecurityNumber.length <= 0) {
      return 'Sozialversicherungsnummer'
    }
    if (companionSocialSecurityNumber.length <= 11) {
      return (
        <span className={classes.validateInfo}>
          Sozialversicherungsnummer{' '}
          <span className={classes.warn}>Nummer ist unvollständig</span>
        </span>
      )
    }
    if (
      validateSocialSecurityNumber(companionSocialSecurityNumber) ===
        undefined &&
      companionSocialSecurityNumber.length >= 12
    ) {
      return (
        <span className={classes.validateInfo}>
          Sozialversicherungsnummer{' '}
          <span className={classes.error}>Nummer ist zu lang</span>
        </span>
      )
    }
    if (
      validateBirthdate(
        companionSocialSecurityNumber,
        companionBirthday,
        companionBirthmonth,
        companionBirthyear
      ) === false
    ) {
      return (
        <span className={classes.validateInfo}>
          Sozialversicherungsnummer{' '}
          <span className={classes.error}>
            Nummer passt nicht zum Geburtsdatum
          </span>
        </span>
      )
    }
    if (
      validateSocialSecurityNumber(companionSocialSecurityNumber) === undefined
    ) {
      return (
        <span className={classes.validateInfo}>
          Sozialversicherungsnummer{' '}
          <span className={classes.error}>Nummer ist falsch</span>
        </span>
      )
    }
    if (validateSocialSecurityNumber(companionSocialSecurityNumber) === false) {
      return (
        <span className={classes.validateInfo}>
          Sozialversicherungsnummer{' '}
          <span className={classes.error}>Nummer ist falsch</span>
        </span>
      )
    }
    if (validateSocialSecurityNumber(companionSocialSecurityNumber) === true) {
      return 'Sozialversicherungsnummer'
    }
  }

  const setTinLabelValidationLabel = () => {
    if (companionTaxIdNumber.length <= 0) {
      return 'Persönliche Steuer-ID'
    }
    if (!tinValidation(companionTaxIdNumber)) {
      return (
        <span className={classes.validateInfo}>
          Persönliche Steuer-ID{' '}
          <span className={classes.warn}>Keine gültige Steuer-ID</span>
        </span>
      )
    }
    return 'Persönliche Steuer-ID'
  }

  const preventSubmiting = () => {
    if (married === 'Ja') {
      if (
        !validateSocialSecurityNumber(companionSocialSecurityNumber) ||
        !tinValidation(companionTaxIdNumber)
      ) {
        return null
      }
    }
  }

  const renderNaturalPersonFormFields = () => {
    if (is_companion_a_natural_person === 'Ja') {
      return (
        <Fragment>
          <TwoColumns layout="1-1">
            <SelectField
              label="Akademischer Titel"
              name="companionAcademicTitle"
              options={['Keine', 'Dr.', 'Prof.', 'Dipl.-Ing.']}
              noDefault
              onChange={onChange}
              required
              defaultValue={companionAcademicTitle}
            />
          </TwoColumns>

          <TwoColumns layout="1-1">
            <TextInputField
              label="Vorname"
              name="companionFirstname"
              onChange={onChange}
              required
              defaultValue={companionFirstname}
              placeholder="Alex"
            />
            <TextInputField
              label="Nachname"
              name="companionLastname"
              onChange={onChange}
              required
              defaultValue={companionLastname}
              placeholder="Schmitz"
            />
          </TwoColumns>

          <TwoColumns layout="1-1">
            <DateInputField
              label="Geburtsdatum"
              names={[
                'companionBirthday',
                'companionBirthmonth',
                'companionBirthyear',
              ]}
              onChange={onChange}
              placeholder={['TT', 'MM', 'JJJJ']}
              maxLength={['2', '2', '4']}
              max={['31', '12', new Date().getFullYear()]}
              min={['1', '1', '1900']}
              pattern="\d"
              required
              defaultValue={[
                companionBirthday,
                companionBirthmonth,
                companionBirthyear,
              ]}
            />
            <SelectField
              label="Konfession"
              name="companionDenomination"
              options={[
                'Konfessionslos / Keine Kirchensteuerberechnung',
                'ev - Evangelische Kirchensteuer',
                'ib - Israelitische Religionsgemeinschaft Baden',
                'ih - Jüdische Kultussteuer',
                'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
                'is - Israelitische / Jüdische Kultussteuer',
                'iw - Israelitische Religionsgemeinschaft Württembergs',
                'jd - Jüdische Kultussteuer',
                'jh - Jüdische Kultussteuer',
                'lt - Evangelisch lutherisch (bis 12/2015)',
                'rf - Evangelisch reformiert (bis 12/2015)',
                'rk - Römisch-Katholische Kirchensteuer',
                'ak - Altkatholische Kirchensteuer',
                'fa - Freie Religionsgemeinschaft Alzey',
                'fb - Freireligiöse Landesgemeinde Baden',
                'fg - Freireligiöse Landesgemeinde Pfalz',
                'fm - Freireligiöse Gemeinde Mainz',
                'fr - Französisch reformiert (bis 12/2015)',
                'fs - Freireligiöse Gemeinde Offenbach/Main',
              ]}
              noDefault
              onChange={onChange}
              required
              defaultValue={companionDenomination}
            />
          </TwoColumns>

          <TwoColumns layout="1-1">
            <PrependNumberInputField
              label="Beteiligung am Stammkapital"
              name="companionShareCapital"
              placeholder="100"
              prependText="%"
              defaultValue={companionShareCapital}
              required
              min={1}
              max={100}
              step={0.01}
              onChange={onChange}
            />
            <TextInputField
              defaultValue={companionTaxIdNumber}
              label={setTinLabelValidationLabel()}
              name={'companionTaxIdNumber'}
              onChange={onChange}
              placeholder={'56345234987'}
              minLength={11}
              required
            />
          </TwoColumns>

          <TwoColumns layout="1-1">
            <TextInputField
              label="Straße"
              name="companionStreet"
              onChange={onChange}
              required
              defaultValue={companionStreet}
              placeholder="Musterstraße"
            />
            <TextInputField
              label="Hausnummer"
              name="companionHouseNumber"
              onChange={onChange}
              required
              defaultValue={companionHouseNumber}
              placeholder="123"
            />
          </TwoColumns>

          <TwoColumns layout="1-1">
            <TextInputField
              label="Postleitzahl"
              name="companionZipCode"
              onChange={onChange}
              required
              defaultValue={companionZipCode}
              placeholder="45678"
            />
            <TextInputField
              label="Ort"
              name="companionCity"
              onChange={onChange}
              required
              defaultValue={companionCity}
              placeholder="Musterstadt"
            />
          </TwoColumns>

          <TwoColumns layout="1-1">
            <NumberInputField
              label="Telefonnummer"
              name="companionPhoneNumber"
              onChange={onChange}
              required
              defaultValue={companionPhoneNumber}
              placeholder="0212123456789"
            />
            <EmailInputField
              label="E-Mail"
              name="companionEmail"
              onChange={onChange}
              required
              defaultValue={companionEmail}
              placeholder="hallo@alexschmitz.de"
            />
          </TwoColumns>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <TwoColumns layout="1-1">
          <TextInputField
            label="Name des Unternehmens"
            name="companion_company_name"
            onChange={onChange}
            required
            defaultValue={companion_company_name}
            placeholder={
              type === 'limited-entrepreneurial-company'
                ? 'Muster Verwaltungs UG'
                : 'Muster Verwaltungs GmbH'
            }
          />
          <PrependNumberInputField
            label="Beteiligung am Stammkapital"
            name="companionShareCapital"
            placeholder="100"
            prependText="%"
            defaultValue={companionShareCapital}
            required
            min={1}
            max={100}
            step={0.01}
            onChange={onChange}
          />
        </TwoColumns>

        <TwoColumns layout="1-1">
          <TextInputField
            label="Straße"
            name="companionStreet"
            onChange={onChange}
            required
            defaultValue={companionStreet}
            placeholder="Musterstraße"
          />
          <TextInputField
            label="Hausnummer"
            name="companionHouseNumber"
            onChange={onChange}
            required
            defaultValue={companionHouseNumber}
            placeholder="123"
          />
        </TwoColumns>

        <TwoColumns layout="1-1">
          <TextInputField
            label="Postleitzahl"
            name="companionZipCode"
            onChange={onChange}
            required
            defaultValue={companionZipCode}
            placeholder="45678"
          />
          <TextInputField
            label="Stadt"
            name="companionCity"
            onChange={onChange}
            required
            defaultValue={companionCity}
            placeholder="Musterstadt"
          />
        </TwoColumns>

        <TwoColumns layout="1-1">
          <NumberInputField
            label="Telefonnummer"
            name="companionPhoneNumber"
            onChange={onChange}
            required
            defaultValue={companionPhoneNumber}
            placeholder="0212123456789"
          />
          <EmailInputField
            label="E-Mail"
            name="companionEmail"
            onChange={onChange}
            required
            defaultValue={companionEmail}
            placeholder="hallo@alexschmitz.de"
          />
        </TwoColumns>
      </Fragment>
    )
  }

  const renderFormFields = () => {
    if (type === 'limited-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Weitere Gesellschafter" />
          <FormBody>
            <TwoColumns layout="1-1">
              <RadioInputField
                label="Hat die GmbH weitere Gesellschafter"
                name="additionalShareHolder"
                radioOptions={['Nein', 'Ja']}
                onChange={onChange}
                defaultChecked={additionalShareHolder === 'Nein' ? 0 : 1}
              />
            </TwoColumns>

            {additionalShareHolder === 'Ja' && (
              <Fragment>
                <RadioInputField
                  label="Ist der Gesellschafter eine natürliche Person?"
                  name="is_companion_a_natural_person"
                  radioOptions={['Ja', 'Nein']}
                  defaultChecked={
                    is_companion_a_natural_person === 'Ja' ? 0 : 1
                  }
                  onChange={onChange}
                />

                {renderNaturalPersonFormFields()}
              </Fragment>
            )}
          </FormBody>

          {is_companion_a_natural_person === 'Ja' && (
            <Fragment>
              <FormHeader
                headlineText="Identifikation Gesellschafter"
                sublineText="Nach §10 Abs. 1 Nr. 2 GwG (Geldwäschegesetz) sind wir zur Abfrage dieser Angaben verpflichtet."
              />
              <FormBody>
                <RadioInputField
                  label="Identifizierungsart"
                  name="companionTypeOfIdentification"
                  radioOptions={['Personalausweis', 'Reisepass']}
                  onChange={onChange}
                  defaultChecked={
                    companionTypeOfIdentification === 'Personalausweis' ? 0 : 1
                  }
                />
                <TwoColumns layout="1-1">
                  <TextInputField
                    label="Geburtsort"
                    name="companionPlaceOfBirth"
                    onChange={onChange}
                    defaultValue={companionPlaceOfBirth}
                    placeholder="Musterstadt"
                    pattern=".*[\w].+"
                  />
                  <SelectField
                    label="Staatsangehörigkeit"
                    name="companionNationality"
                    options={nations}
                    noDefault
                    onChange={onChange}
                    required
                    defaultValue={companionNationality}
                  />
                </TwoColumns>
                <TwoColumns layout="1-1">
                  <TextInputField
                    label="Ausweisnummer"
                    name="companionIdentityCardNumber"
                    onChange={onChange}
                    defaultValue={companionIdentityCardNumber}
                    placeholder="M6YXG21094"
                    minLength={9}
                    maxLength={10}
                  />
                  <TextInputField
                    label="Ausstellende Behörde"
                    name="companionIssuingAuthority"
                    onChange={onChange}
                    defaultValue={companionIssuingAuthority}
                    placeholder="Musterstadt"
                  />
                </TwoColumns>
                <TwoColumns layout="1-1">
                  <DateInputField
                    label="Ausgestellt am"
                    names={[
                      'companionIssuedDay',
                      'companionIssuedMonth',
                      'companionIssuedYear',
                    ]}
                    onChange={onChange}
                    placeholder={['TT', 'MM', 'JJJJ']}
                    maxLength={['2', '2', '4']}
                    max={['31', '12', new Date().getFullYear()]}
                    min={['1', '1', '']}
                    pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                    required
                    defaultValue={[
                      companionIssuedDay,
                      companionIssuedMonth,
                      companionIssuedYear,
                    ]}
                  />
                  <DateInputField
                    label="Gültig bis"
                    names={[
                      'companionValidDay',
                      'companionValidMonth',
                      'companionValidYear',
                    ]}
                    onChange={onChange}
                    placeholder={['TT', 'MM', 'JJJJ']}
                    maxLength={['2', '2', '4']}
                    max={['31', '12', '']}
                    min={['1', '1', '']}
                    pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                    required
                    defaultValue={[
                      companionValidDay,
                      companionValidMonth,
                      companionValidYear,
                    ]}
                  />
                </TwoColumns>
                <TwoColumns layout="1-1">
                  <RadioInputField
                    label="PeP-Status/Politisch exponierte Person"
                    name="companionPep"
                    radioOptions={['Nein', 'Ja']}
                    onChange={onChange}
                    defaultChecked={companionPep === 'Nein' ? 0 : 1}
                  />
                </TwoColumns>
              </FormBody>
            </Fragment>
          )}
        </Fragment>
      )
    }
    if (type === 'limited-entrepreneurial-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Weitere Gesellschafter" />
          <FormBody>
            <TwoColumns layout="1-1">
              <RadioInputField
                label="Hat die GmbH weitere Gesellschafter"
                name="additionalShareHolder"
                radioOptions={['Nein', 'Ja']}
                onChange={onChange}
                defaultChecked={additionalShareHolder === 'Nein' ? 0 : 1}
              />
            </TwoColumns>

            {additionalShareHolder === 'Ja' && (
              <Fragment>
                <RadioInputField
                  label="Ist der Gesellschafter eine natürliche Person?"
                  name="is_companion_a_natural_person"
                  radioOptions={['Ja', 'Nein']}
                  defaultChecked={
                    is_companion_a_natural_person === 'Ja' ? 0 : 1
                  }
                  onChange={onChange}
                />

                {renderNaturalPersonFormFields()}
              </Fragment>
            )}
          </FormBody>

          {additionalShareHolder === 'Ja' &&
            is_companion_a_natural_person === 'Ja' && (
              <Fragment>
                <FormHeader
                  headlineText="Identifikation Gesellschafter"
                  sublineText="Nach §10 Abs. 1 Nr. 2 GwG (Geldwäschegesetz) sind wir zur Abfrage dieser Angaben verpflichtet."
                />
                <FormBody>
                  <RadioInputField
                    label="Identifizierungsart"
                    name="companionTypeOfIdentification"
                    radioOptions={['Personalausweis', 'Reisepass']}
                    onChange={onChange}
                    defaultChecked={
                      companionTypeOfIdentification === 'Personalausweis'
                        ? 0
                        : 1
                    }
                  />
                  <TwoColumns layout="1-1">
                    <TextInputField
                      label="Geburtsort"
                      name="companionPlaceOfBirth"
                      onChange={onChange}
                      defaultValue={companionPlaceOfBirth}
                      placeholder="Musterstadt"
                      pattern=".*[\w].+"
                    />
                    <SelectField
                      label="Staatsangehörigkeit"
                      name="companionNationality"
                      options={nations}
                      noDefault
                      onChange={onChange}
                      required
                      defaultValue={companionNationality}
                    />
                  </TwoColumns>
                  <TwoColumns layout="1-1">
                    <TextInputField
                      label="Ausweisnummer"
                      name="companionIdentityCardNumber"
                      onChange={onChange}
                      defaultValue={companionIdentityCardNumber}
                      placeholder="M6YXG21094"
                      minLength={9}
                      maxLength={10}
                    />
                    <TextInputField
                      label="Ausstellende Behörde"
                      name="companionIssuingAuthority"
                      onChange={onChange}
                      defaultValue={companionIssuingAuthority}
                      placeholder="Musterstadt"
                    />
                  </TwoColumns>
                  <TwoColumns layout="1-1">
                    <DateInputField
                      label="Ausgestellt am"
                      names={[
                        'companionIssuedDay',
                        'companionIssuedMonth',
                        'companionIssuedYear',
                      ]}
                      onChange={onChange}
                      placeholder={['TT', 'MM', 'JJJJ']}
                      maxLength={['2', '2', '4']}
                      max={['31', '12', new Date().getFullYear()]}
                      min={['1', '1', '']}
                      pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                      required
                      defaultValue={[
                        companionIssuedDay,
                        companionIssuedMonth,
                        companionIssuedYear,
                      ]}
                    />
                    <DateInputField
                      label="Gültig bis"
                      names={[
                        'companionValidDay',
                        'companionValidMonth',
                        'companionValidYear',
                      ]}
                      onChange={onChange}
                      placeholder={['TT', 'MM', 'JJJJ']}
                      maxLength={['2', '2', '4']}
                      max={['31', '12', '']}
                      min={['1', '1', '']}
                      pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                      required
                      defaultValue={[
                        companionValidDay,
                        companionValidMonth,
                        companionValidYear,
                      ]}
                    />
                  </TwoColumns>
                  <TwoColumns layout="1-1">
                    <RadioInputField
                      label="PeP-Status/Politisch exponierte Person"
                      name="companionPep"
                      radioOptions={['Nein', 'Ja']}
                      onChange={onChange}
                      defaultChecked={companionPep === 'Nein' ? 0 : 1}
                    />
                  </TwoColumns>
                </FormBody>
              </Fragment>
            )}
        </Fragment>
      )
    }
    return (
      <Fragment>
        <FormHeader headlineText="Angaben LebenspartnerIn" />
        <FormBody>
          <TwoColumns layout="1-1">
            <RadioInputField
              label="Verheiratet/Verpartnert"
              name="married"
              radioOptions={['Nein', 'Ja']}
              onChange={onChange}
              defaultChecked={married === 'Nein' ? 0 : 1}
            />
          </TwoColumns>

          {married === 'Ja' ? (
            <Fragment>
              <TwoColumns layout="1-1">
                <DateInputField
                  label="Verheiratet seit"
                  names={['marriedDay', 'marriedMonth', 'marriedYear']}
                  onChange={onChange}
                  placeholder={['TT', 'MM', 'JJJJ']}
                  maxLength={['2', '2', '4']}
                  max={['31', '12', new Date().getFullYear()]}
                  min={['1', '1', '1900']}
                  pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                  required
                  defaultValue={[marriedDay, marriedMonth, marriedYear]}
                />
                <SelectField
                  label="Akademischer Titel"
                  name="companionAcademicTitle"
                  options={['Keine', 'Dr.', 'Prof.', 'Dipl.-Ing.']}
                  noDefault
                  onChange={onChange}
                  required
                  defaultValue={companionAcademicTitle}
                />
              </TwoColumns>

              <TwoColumns layout="1-1">
                <TextInputField
                  label="Vorname"
                  name="companionFirstname"
                  onChange={onChange}
                  required
                  defaultValue={companionFirstname}
                  placeholder="Alex"
                />
                <TextInputField
                  label="Nachname"
                  name="companionLastname"
                  onChange={onChange}
                  required
                  defaultValue={companionLastname}
                  placeholder="Schmitz"
                />
              </TwoColumns>

              <TwoColumns layout="1-1">
                <DateInputField
                  label="Geburtsdatum"
                  names={[
                    'companionBirthday',
                    'companionBirthmonth',
                    'companionBirthyear',
                  ]}
                  onChange={onChange}
                  placeholder={['TT', 'MM', 'JJJJ']}
                  maxLength={['2', '2', '4']}
                  max={['31', '12', new Date().getFullYear()]}
                  min={['1', '1', '1900']}
                  pattern="\d"
                  required
                  defaultValue={[
                    companionBirthday,
                    companionBirthmonth,
                    companionBirthyear,
                  ]}
                />
                <SelectField
                  label="Konfession"
                  name="companionDenomination"
                  options={[
                    'Konfessionslos / Keine Kirchensteuerberechnung',
                    'ev - Evangelische Kirchensteuer',
                    'ib - Israelitische Religionsgemeinschaft Baden',
                    'ih - Jüdische Kultussteuer',
                    'il - Israelitische Kultussteuer der kultusberechtigten Gemeinden',
                    'is - Israelitische / Jüdische Kultussteuer',
                    'iw - Israelitische Religionsgemeinschaft Württembergs',
                    'jd - Jüdische Kultussteuer',
                    'jh - Jüdische Kultussteuer',
                    'lt - Evangelisch lutherisch (bis 12/2015)',
                    'rf - Evangelisch reformiert (bis 12/2015)',
                    'rk - Römisch-Katholische Kirchensteuer',
                    'ak - Altkatholische Kirchensteuer',
                    'fa - Freie Religionsgemeinschaft Alzey',
                    'fb - Freireligiöse Landesgemeinde Baden',
                    'fg - Freireligiöse Landesgemeinde Pfalz',
                    'fm - Freireligiöse Gemeinde Mainz',
                    'fr - Französisch reformiert (bis 12/2015)',
                    'fs - Freireligiöse Gemeinde Offenbach/Main',
                  ]}
                  noDefault
                  onChange={onChange}
                  required
                  defaultValue={companionDenomination}
                />
              </TwoColumns>

              <TwoColumns layout="1-1">
                <TextInputField
                  label={setSocialSecurityNumberValidationLabel()}
                  placeholder="12190367K006"
                  name="companionSocialSecurityNumber"
                  defaultValue={companionSocialSecurityNumber}
                  onChange={onChange}
                  minLength={12}
                  title="Bitte trage deine Sozialversicherungsnummer ein"
                  required
                />
                <TextInputField
                  defaultValue={companionTaxIdNumber}
                  label={setTinLabelValidationLabel()}
                  name={'companionTaxIdNumber'}
                  onChange={onChange}
                  placeholder={'56345234987'}
                  minLength={11}
                  required
                />
              </TwoColumns>

              <TwoColumns layout="1-1">
                <TextInputField
                  label="Straße"
                  name="companionStreet"
                  onChange={onChange}
                  required
                  defaultValue={companionStreet}
                  placeholder="Musterstraße"
                />
                <TextInputField
                  label="Hausnummer"
                  name="companionHouseNumber"
                  onChange={onChange}
                  required
                  defaultValue={companionHouseNumber}
                  placeholder="123"
                />
              </TwoColumns>

              <TwoColumns layout="1-1">
                <TextInputField
                  label="Postleitzahl"
                  name="companionZipCode"
                  onChange={onChange}
                  required
                  defaultValue={companionZipCode}
                  placeholder="45678"
                />
                <TextInputField
                  label="Ort"
                  name="companionCity"
                  onChange={onChange}
                  required
                  defaultValue={companionCity}
                  placeholder="Musterstadt"
                />
              </TwoColumns>

              <TwoColumns layout="1-1">
                <NumberInputField
                  label="Telefonnummer"
                  name="companionPhoneNumber"
                  onChange={onChange}
                  required
                  defaultValue={companionPhoneNumber}
                  placeholder="0212123456789"
                />
                <EmailInputField
                  label="E-Mail"
                  name="companionEmail"
                  onChange={onChange}
                  required
                  defaultValue={companionEmail}
                  placeholder="hallo@alexschmitz.de"
                />
              </TwoColumns>
            </Fragment>
          ) : null}
        </FormBody>

        {married === 'Ja' ? (
          <Fragment>
            <FormHeader
              headlineText="Identifikation LebenspartnerIn"
              sublineText="Nach §10 Abs. 1 Nr. 2 GwG (Geldwäschegesetz) sind wir zur Abfrage dieser Angaben verpflichtet."
            />
            <FormBody>
              <RadioInputField
                label="Identifizierungsart"
                name="companionTypeOfIdentification"
                radioOptions={['Personalausweis', 'Reisepass']}
                onChange={onChange}
                defaultChecked={
                  companionTypeOfIdentification === 'Personalausweis' ? 0 : 1
                }
              />
              <TwoColumns layout="1-1">
                <TextInputField
                  label="Geburtsort"
                  name="companionPlaceOfBirth"
                  onChange={onChange}
                  defaultValue={companionPlaceOfBirth}
                  placeholder="Musterstadt"
                  pattern=".*[\w].+"
                />
                <SelectField
                  label="Staatsangehörigkeit"
                  name="companionNationality"
                  options={nations}
                  noDefault
                  onChange={onChange}
                  required
                  defaultValue={companionNationality}
                />
              </TwoColumns>
              <TwoColumns layout="1-1">
                <TextInputField
                  label="Ausweisnummer"
                  name="companionIdentityCardNumber"
                  onChange={onChange}
                  defaultValue={companionIdentityCardNumber}
                  placeholder="M6YXG21094"
                  minLength={9}
                  maxLength={10}
                />
                <TextInputField
                  label="Ausstellende Behörde"
                  name="companionIssuingAuthority"
                  onChange={onChange}
                  defaultValue={companionIssuingAuthority}
                  placeholder="Musterstadt"
                />
              </TwoColumns>
              <TwoColumns layout="1-1">
                <DateInputField
                  label="Ausgestellt am"
                  names={[
                    'companionIssuedDay',
                    'companionIssuedMonth',
                    'companionIssuedYear',
                  ]}
                  onChange={onChange}
                  placeholder={['TT', 'MM', 'JJJJ']}
                  maxLength={['2', '2', '4']}
                  max={['31', '12', new Date().getFullYear()]}
                  min={['1', '1', '']}
                  pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                  required
                  defaultValue={[
                    companionIssuedDay,
                    companionIssuedMonth,
                    companionIssuedYear,
                  ]}
                />
                <DateInputField
                  label="Gültig bis"
                  names={[
                    'companionValidDay',
                    'companionValidMonth',
                    'companionValidYear',
                  ]}
                  onChange={onChange}
                  placeholder={['TT', 'MM', 'JJJJ']}
                  maxLength={['2', '2', '4']}
                  max={['31', '12', '']}
                  min={['1', '1', '']}
                  pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                  required
                  defaultValue={[
                    companionValidDay,
                    companionValidMonth,
                    companionValidYear,
                  ]}
                />
              </TwoColumns>
              <TwoColumns layout="1-1">
                <RadioInputField
                  label="PeP-Status/Politisch exponierte Person"
                  name="companionPep"
                  radioOptions={['Nein', 'Ja']}
                  onChange={onChange}
                  defaultChecked={companionPep === 'Nein' ? 0 : 1}
                />
              </TwoColumns>
            </FormBody>
          </Fragment>
        ) : null}
      </Fragment>
    )
  }

  return (
    <FormContainer
      className={classes.companionForm}
      onSubmit={onNextButtonClick}
    >
      <HelperButton onClick={onHelperButtonClick} />
      {renderFormFields()}
      <FormFooter
        onClick={preventSubmiting}
        primaryButtonText="Weiter"
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={onBackButtonClick}
      />
    </FormContainer>
  )
}

export default CompanionForm
