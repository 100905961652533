const consultantFormValues = {
  activityKey: '94243',
  autosuggestSearchValue: '94243 - Autorennfahrer/in',
  clientNumber: '11500',
  consultantNumber: '260814',
  jobTitle: 'Autorennfahrer/in',
  staffNumber: '1400',
}

export default consultantFormValues
