import React, { Fragment } from 'react'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import TextInputField from '../../Inputs/TextInputField/TextInputField'
import RadioInputField from '../../Inputs/RadioInputField/RadioInputField'
import SelectField from '../../Selects/SelectField/SelectField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import { validateSocialSecurityNumber } from '../utility/socialSecurityNumberValidation'
import countries from './utility/countries.json'
import './InsuranceInformationForm.scss'
import { STAFF_FORMS } from '../../../constants/Formulars'

const InsuranceInformationForm = (props) => {
  const renderFormFields = () => {
    if (props.formularName === STAFF_FORMS.IMMEDIATE_REPORT) {
      return (
        <Fragment>
          <RadioInputField
            label="Sozialversicherungsnummer des Mitarbeiters bekannt?"
            name="insuranceNumber"
            radioOptions={['Ja', 'Nein']}
            defaultChecked={props.insuranceNumber === true ? 0 : 1}
            onChange={props.handleInsuranceNumberChange}
          />
          {props.insuranceNumber === true ? (
            <TextInputField
              label={renderLabelText()}
              placeholder="12190367K006"
              name="socialSecurityNumber"
              defaultValue={props.socialSecurityNumber}
              onChange={props.onChange}
              minLength={12}
              title="Bitte trage die Sozialversicherungsnummer Deines neuen Mitarbeiters ein"
              required
            />
          ) : null}
        </Fragment>
      )
    }
    return (
      <Fragment>
        <RadioInputField
          label="Hast Du eine Sozialversicherungsnummer?"
          name="insuranceNumber"
          radioOptions={['Ja', 'Nein']}
          defaultChecked={props.insuranceNumber === true ? 0 : 1}
          onChange={props.handleInsuranceNumberChange}
        />
        {props.insuranceNumber && (
          <TextInputField
            label={renderLabelText()}
            placeholder="12190367K006"
            name="socialSecurityNumber"
            defaultValue={props.socialSecurityNumber}
            onChange={props.onChange}
            minLength={12}
            title="Bitte trage deine Sozialversicherungsnummer ein"
            required
          />
        )}

        <SelectField
          label="Staatsangehörigkeit"
          name="nationality"
          options={countries.countries}
          defaultValue={props.nationality}
          onChange={props.onChange}
          required
          noDefault
        />
        <SelectField
          label="Geburtsland"
          name="birthCountry"
          options={countries.countries}
          defaultValue={props.birthCountry}
          onChange={props.onChange}
          required
          noDefault
        />
        <TextInputField
          label="Geburtsort"
          placeholder="Musterstadt"
          name="placeOfBirth"
          defaultValue={props.placeOfBirth}
          onChange={props.onChange}
          required
          pattern="[^\/\\\+!.,;:_—–#?=()&%$§!\^°*]+"
          title="Bitte verwenden Sie keine Sonderzeichen"
        />

        {!props.insuranceNumber && (
          <TextInputField
            label="Geburtsname"
            placeholder="Meier"
            name="birthName"
            defaultValue={props.birthName}
            onChange={props.onChange}
            required
            pattern=".*[\w].+"
          />
        )}
      </Fragment>
    )
  }

  const validateBirthdate = () => {
    const socialSecurityNumberBirthString =
      props.socialSecurityNumber.substring(2, 8)
    const birthday = `${props.birthday}${
      props.birthmonth
    }${props.birthyear.substring(2)}`

    if (socialSecurityNumberBirthString !== birthday) {
      return false
    }
    return true
  }

  const renderLabelText = () => {
    if (props.socialSecurityNumber.length <= 0) {
      return 'Sozialversicherungsnummer'
    }
    if (props.socialSecurityNumber.length <= 11) {
      return (
        <span className="validation-info">
          Sozialversicherungsnummer{' '}
          <span className="warn">Nummer ist unvollständig</span>
        </span>
      )
    }
    if (
      validateSocialSecurityNumber(props.socialSecurityNumber) === undefined &&
      props.socialSecurityNumber.length >= 12
    ) {
      return (
        <span className="validation-info">
          Sozialversicherungsnummer{' '}
          <span className="error">Nummer ist zu lang</span>
        </span>
      )
    }
    if (validateBirthdate() === false) {
      return (
        <span className="validation-info">
          Sozialversicherungsnummer{' '}
          <span className="error">Nummer passt nicht zum Geburtsdatum</span>
        </span>
      )
    }
    if (
      validateSocialSecurityNumber(props.socialSecurityNumber) === undefined
    ) {
      return (
        <span className="validation-info">
          Sozialversicherungsnummer{' '}
          <span className="error">Nummer ist falsch</span>
        </span>
      )
    }
    if (validateSocialSecurityNumber(props.socialSecurityNumber) === false) {
      return (
        <span className="validation-info">
          Sozialversicherungsnummer{' '}
          <span className="error">Nummer ist falsch</span>
        </span>
      )
    }
    if (validateSocialSecurityNumber(props.socialSecurityNumber) === true) {
      return 'Sozialversicherungsnummer'
    }
  }

  const disableButton = (e) => {
    if (props.socialSecurityNumber.length <= 0) {
      return null
    }
    if (props.socialSecurityNumber.length !== 12) {
      e.preventDefault()
      e.target.blur()
    }
    if (
      validateBirthdate() === false ||
      validateSocialSecurityNumber(props.socialSecurityNumber) === false
    ) {
      e.preventDefault()
      e.target.blur()
    }
    return null
  }

  return (
    <FormContainer
      className={'insurance-informations-form'}
      onSubmit={props.onSubmit}
    >
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Angaben zu Versicherung'} />
      <FormBody>{renderFormFields()}</FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
        onClick={disableButton}
      />
    </FormContainer>
  )
}

export default InsuranceInformationForm
