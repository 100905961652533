import React from 'react'
import PrependNumberInput from '../../ui-components/inputs/PrependNumberInput/PrependNumberInput'
import Label from '../../ui-components/labels/Label/Label'

import './PrependNumberInputField.scss'

interface Props {
  label: string
  name: string
  placeholder: string
  prependText: string
  defaultValue: string
  required: boolean
  min: number
  max?: number
  step: number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const PrependNumberInputField = (props: Props) => {
  const {
    label,
    name,
    placeholder,
    prependText,
    defaultValue,
    required,
    min,
    max,
    step,
    onChange,
  } = props

  return (
    <div className="prepend-number-input-field">
      <Label>{label}</Label>
      <PrependNumberInput
        name={name}
        placeholder={placeholder}
        prependText={prependText}
        defaultValue={defaultValue}
        required={required}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
      />
    </div>
  )
}

export default PrependNumberInputField
