import React from 'react'
import PropTypes from 'prop-types'

const RadioInput = (props) => (
  <input
    type="radio"
    name={props.name}
    defaultValue={props.defaultValue}
    defaultChecked={props.defaultChecked}
    autoFocus={props.autoFocus}
    required={props.required}
    onChange={props.onChange}
    disabled={props.disabled}
  />
)

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  defaultChecked: PropTypes.bool,
  autoFocus: PropTypes.bool,
}

export default RadioInput
