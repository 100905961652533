import React, { Fragment } from 'react'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../../ui-components/buttons/HelperButton/HelperButton'

import classes from './SummaryForm.module.scss'

interface Props {
  type:
    | 'standard'
    | 'limited-company-and-partnership-kg'
    | 'limited-company'
    | 'limited-entrepreneurial-company'
    | 'civil-law-company'
  features: {
    sepa: boolean
  }
  values: {
    contactPerson: string
    academicTitle: string
    firstname: string
    lastname: string
    birthday: string
    birthmonth: string
    birthyear: string
    denomination: string
    socialSecurityNumber: string
    taxIdNumber: string
    street: string
    houseNumber: string
    zipCode: string
    city: string
    phoneNumber: string
    email: string
    typeOfIdentification: string
    placeOfBirth: string
    pep: string
    nationality: string
    identityCardNumber: string
    issuingAuthority: string
    issuedDay: string
    issuedMonth: string
    issuedYear: string
    validDay: string
    validMonth: string
    validYear: string
    privateIban: string
    privateBankAccountHolder: string
    privateBankName: string
    businessIban: string
    businessBankAccountHolder: string
    businessBankName: string
    allowSepaForPrivateIban: string
    allowSepaForBusinessIban: string
    allowSepaForlimitedCompanyBusinessIban: string
    privateTaxOffice: string
    privateTaxNumber: string
    businessTaxOffice: string
    businessTaxNumberAvailable: string
    businessTaxNumber: string
    tradeRegisterEntry: string
    registryCourt: string
    tradeRegisterNumber: string
    married: string
    companionAcademicTitle: string
    companionFirstname: string
    companionLastname: string
    companionBirthday: string
    companionBirthmonth: string
    companionBirthyear: string
    companionDenomination: string
    companionSocialSecurityNumber: string
    companionTaxIdNumber: string
    companionStreet: string
    companionHouseNumber: string
    companionZipCode: string
    companionCity: string
    companionPhoneNumber: string
    companionEmail: string
    companionTypeOfIdentification: string
    companionPlaceOfBirth: string
    companionPep: string
    companionNationality: string
    companionIdentityCardNumber: string
    companionIssuingAuthority: string
    companionIssuedDay: string
    companionIssuedMonth: string
    companionIssuedYear: string
    companionValidDay: string
    companionValidMonth: string
    companionValidYear: string
    children: string
    numberOfChildren: number
    child1Firstname: string
    child1Lastname: string
    child1Birthday: string
    child1Birthmonth: string
    child1Birthyear: string
    child1TaxIdNumber: string
    child1IsAdult: string
    child1Status: string
    child1Benefit: string
    child2Firstname: string
    child2Lastname: string
    child2Birthday: string
    child2Birthmonth: string
    child2Birthyear: string
    child2TaxIdNumber: string
    child2IsAdult: string
    child2Status: string
    child2Benefit: string
    child3Firstname: string
    child3Lastname: string
    child3Birthday: string
    child3Birthmonth: string
    child3Birthyear: string
    child3TaxIdNumber: string
    child3IsAdult: string
    child3Status: string
    child3Benefit: string
    taxOfficeProxy: string
    salutation: string
    companyName: string
    businessPurpose: string
    foundingday: string
    foundingmonth: string
    foundingyear: string
    businessStreet: string
    businessHouseNumber: string
    businessZipCode: string
    businessCity: string
    businessPhoneNumber: string
    businessEmail: string
    vatRegion: string
    vatNumber: string
    vatNumberAvailable: string
    capitalContribution: string
    limitedCompanySalutation: string
    limitedCompanyName: string
    limitedCompanyFoundingday: string
    limitedCompanyFoundingmonth: string
    limitedCompanyFoundingyear: string
    limitedCompanyBusinessStreet: string
    limitedCompanyBusinessHouseNumber: string
    limitedCompanyBusinessZipCode: string
    limitedCompanyBusinessCity: string
    limitedCompanyBusinessBankAccountHolder: string
    limitedCompanyBusinessBankName: string
    limitedCompanyBusinessIban: string
    limitedCompanyBusinessTaxOffice: string
    limitedCompanyVatNumberAvailable: string
    limitedCompanyVatRegion: string
    limitedCompanyVatNumber: string
    limitedCompanyBusinessTaxNumberAvailable: string
    limitedCompanyBusinessTaxNumber: string
    limitedCompanyTradeRegisterEntry: string
    limitedCompanyRegistryCourt: string
    limitedCompanyTradeRegisterNumber: string
    limitedCompanyAcademicTitle: string
    limitedCompanyFirstname: string
    limitedCompanyLastname: string
    limitedCompanyBirthday: string
    limitedCompanyBirthmonth: string
    limitedCompanyBirthyear: string
    limitedCompanyDenomination: string
    limitedCompanyTaxIdNumber: string
    limitedCompanyStreet: string
    limitedCompanyHouseNumber: string
    limitedCompanyZipCode: string
    limitedCompanyCity: string
    limitedCompanyPhoneNumber: string
    limitedCompanyEmail: string
    sameShareholder: string
    shareCapital: string
    amountOfSubscribedCapital: string
    additionalShareHolder: string
    companionShareCapital: string
    externalManagingDirector: string
    externalManagingDirectorFirstname: string
    externalManagingDirectorLastname: string
    externalManagingDirectorBirthday: string
    externalManagingDirectorBirthmonth: string
    externalManagingDirectorBirthyear: string
    externalManagingDirectorStreet: string
    externalManagingDirectorHouseNumber: string
    externalManagingDirectorZipCode: string
    externalManagingDirectorCity: string
    naturalPerson: string
    marriedDay: string
    marriedMonth: string
    marriedYear: string
    annualFinancialReport: string
    differentProfitDistribution: string
    partnershipAgreement: string
    fixedCapital: string
    secondPartnerTypeOfIdentification: string
    secondPartnerPlaceOfBirth: string
    secondPartnerNationality: string
    secondPartnerIdentityCardNumber: string
    secondPartnerIssuingAuthority: string
    secondPartnerIssuedDay: string
    secondPartnerIssuedMonth: string
    secondPartnerIssuedYear: string
    secondPartnerValidDay: string
    secondPartnerValidMonth: string
    secondPartnerValidYear: string
    secondPartnerPep: string
    additionalShareholders: string
    numberOfShareholders: number
    additionalShareholder1AcademicTitle: string
    additionalShareholder1Firstname: string
    additionalShareholder1Lastname: string
    additionalShareholder1Birthday: string
    additionalShareholder1Birthmonth: string
    additionalShareholder1Birthyear: string
    additionalShareholder1Denomination: string
    additionalShareholder1TaxIdNumber: string
    additionalShareholder1Street: string
    additionalShareholder1HouseNumber: string
    additionalShareholder1ZipCode: string
    additionalShareholder1City: string
    additionalShareholder1PhoneNumber: string
    additionalShareholder1Email: string
    additionalShareholder1CapitalContribution: string
    additionalShareholder1TypeOfIdentification: string
    additionalShareholder1PlaceOfBirth: string
    additionalShareholder1Nationality: string
    additionalShareholder1IdentityCardNumber: string
    additionalShareholder1IssuingAuthority: string
    additionalShareholder1IssuedDay: string
    additionalShareholder1IssuedMonth: string
    additionalShareholder1IssuedYear: string
    additionalShareholder1ValidDay: string
    additionalShareholder1ValidMonth: string
    additionalShareholder1ValidYear: string
    additionalShareholder1Pep: string
    additionalShareholder1PrivateTaxOffice: string
    additionalShareholder1PrivateTaxNumber: string

    additionalShareholder2AcademicTitle: string
    additionalShareholder2Firstname: string
    additionalShareholder2Lastname: string
    additionalShareholder2Birthday: string
    additionalShareholder2Birthmonth: string
    additionalShareholder2Birthyear: string
    additionalShareholder2Denomination: string
    additionalShareholder2TaxIdNumber: string
    additionalShareholder2Street: string
    additionalShareholder2HouseNumber: string
    additionalShareholder2ZipCode: string
    additionalShareholder2City: string
    additionalShareholder2PhoneNumber: string
    additionalShareholder2Email: string
    additionalShareholder2CapitalContribution: string
    additionalShareholder2TypeOfIdentification: string
    additionalShareholder2PlaceOfBirth: string
    additionalShareholder2Nationality: string
    additionalShareholder2IdentityCardNumber: string
    additionalShareholder2IssuingAuthority: string
    additionalShareholder2IssuedDay: string
    additionalShareholder2IssuedMonth: string
    additionalShareholder2IssuedYear: string
    additionalShareholder2ValidDay: string
    additionalShareholder2ValidMonth: string
    additionalShareholder2ValidYear: string
    additionalShareholder2Pep: string
    additionalShareholder2PrivateTaxOffice: string
    additionalShareholder2PrivateTaxNumber: string

    additionalShareholder3AcademicTitle: string
    additionalShareholder3Firstname: string
    additionalShareholder3Lastname: string
    additionalShareholder3Birthday: string
    additionalShareholder3Birthmonth: string
    additionalShareholder3Birthyear: string
    additionalShareholder3Denomination: string
    additionalShareholder3TaxIdNumber: string
    additionalShareholder3Street: string
    additionalShareholder3HouseNumber: string
    additionalShareholder3ZipCode: string
    additionalShareholder3City: string
    additionalShareholder3PhoneNumber: string
    additionalShareholder3Email: string
    additionalShareholder3CapitalContribution: string
    additionalShareholder3TypeOfIdentification: string
    additionalShareholder3PlaceOfBirth: string
    additionalShareholder3Nationality: string
    additionalShareholder3IdentityCardNumber: string
    additionalShareholder3IssuingAuthority: string
    additionalShareholder3IssuedDay: string
    additionalShareholder3IssuedMonth: string
    additionalShareholder3IssuedYear: string
    additionalShareholder3ValidDay: string
    additionalShareholder3ValidMonth: string
    additionalShareholder3ValidYear: string
    additionalShareholder3Pep: string
    additionalShareholder3PrivateTaxOffice: string
    additionalShareholder3PrivateTaxNumber: string

    additionalShareholder4AcademicTitle: string
    additionalShareholder4Firstname: string
    additionalShareholder4Lastname: string
    additionalShareholder4Birthday: string
    additionalShareholder4Birthmonth: string
    additionalShareholder4Birthyear: string
    additionalShareholder4Denomination: string
    additionalShareholder4TaxIdNumber: string
    additionalShareholder4Street: string
    additionalShareholder4HouseNumber: string
    additionalShareholder4ZipCode: string
    additionalShareholder4City: string
    additionalShareholder4PhoneNumber: string
    additionalShareholder4Email: string
    additionalShareholder4CapitalContribution: string
    additionalShareholder4TypeOfIdentification: string
    additionalShareholder4PlaceOfBirth: string
    additionalShareholder4Nationality: string
    additionalShareholder4IdentityCardNumber: string
    additionalShareholder4IssuingAuthority: string
    additionalShareholder4IssuedDay: string
    additionalShareholder4IssuedMonth: string
    additionalShareholder4IssuedYear: string
    additionalShareholder4ValidDay: string
    additionalShareholder4ValidMonth: string
    additionalShareholder4ValidYear: string
    additionalShareholder4Pep: string
    additionalShareholder4PrivateTaxOffice: string
    additionalShareholder4PrivateTaxNumber: string

    additionalShareholder5AcademicTitle: string
    additionalShareholder5Firstname: string
    additionalShareholder5Lastname: string
    additionalShareholder5Birthday: string
    additionalShareholder5Birthmonth: string
    additionalShareholder5Birthyear: string
    additionalShareholder5Denomination: string
    additionalShareholder5TaxIdNumber: string
    additionalShareholder5Street: string
    additionalShareholder5HouseNumber: string
    additionalShareholder5ZipCode: string
    additionalShareholder5City: string
    additionalShareholder5PhoneNumber: string
    additionalShareholder5Email: string
    additionalShareholder5CapitalContribution: string
    additionalShareholder5TypeOfIdentification: string
    additionalShareholder5PlaceOfBirth: string
    additionalShareholder5Nationality: string
    additionalShareholder5IdentityCardNumber: string
    additionalShareholder5IssuingAuthority: string
    additionalShareholder5IssuedDay: string
    additionalShareholder5IssuedMonth: string
    additionalShareholder5IssuedYear: string
    additionalShareholder5ValidDay: string
    additionalShareholder5ValidMonth: string
    additionalShareholder5ValidYear: string
    additionalShareholder5Pep: string
    additionalShareholder5PrivateTaxOffice: string
    additionalShareholder5PrivateTaxNumber: string
    electronicInvoicing: boolean
    is_companion_a_natural_person: string
    companion_company_name: string
  }
  onHelperButtonClick: () => void
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  onBackButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
}

const SummaryForm = (props: Props) => {
  const {
    type,
    values: {
      contactPerson,
      academicTitle,
      firstname,
      lastname,
      birthday,
      birthmonth,
      birthyear,
      denomination,
      socialSecurityNumber,
      taxIdNumber,
      street,
      houseNumber,
      zipCode,
      city,
      phoneNumber,
      email,
      typeOfIdentification,
      placeOfBirth,
      pep,
      nationality,
      identityCardNumber,
      issuingAuthority,
      issuedDay,
      issuedMonth,
      issuedYear,
      validDay,
      validMonth,
      validYear,
      privateIban,
      privateBankAccountHolder,
      privateBankName,
      businessIban,
      businessBankAccountHolder,
      businessBankName,
      allowSepaForPrivateIban,
      allowSepaForBusinessIban,
      allowSepaForlimitedCompanyBusinessIban,
      privateTaxOffice,
      privateTaxNumber,
      businessTaxOffice,
      businessTaxNumberAvailable,
      businessTaxNumber,
      tradeRegisterEntry,
      registryCourt,
      tradeRegisterNumber,
      married,
      companionAcademicTitle,
      companionFirstname,
      companionLastname,
      companionBirthday,
      companionBirthmonth,
      companionBirthyear,
      companionDenomination,
      companionSocialSecurityNumber,
      companionTaxIdNumber,
      companionStreet,
      companionHouseNumber,
      companionZipCode,
      companionCity,
      companionPhoneNumber,
      companionEmail,
      companionTypeOfIdentification,
      companionPlaceOfBirth,
      companionPep,
      companionNationality,
      companionIdentityCardNumber,
      companionIssuingAuthority,
      companionIssuedDay,
      companionIssuedMonth,
      companionIssuedYear,
      companionValidDay,
      companionValidMonth,
      companionValidYear,
      children,
      numberOfChildren,
      child1Firstname,
      child1Lastname,
      child1Birthday,
      child1Birthmonth,
      child1Birthyear,
      child1TaxIdNumber,
      child1IsAdult,
      child1Status,
      child1Benefit,
      child2Firstname,
      child2Lastname,
      child2Birthday,
      child2Birthmonth,
      child2Birthyear,
      child2TaxIdNumber,
      child2IsAdult,
      child2Status,
      child2Benefit,
      child3Firstname,
      child3Lastname,
      child3Birthday,
      child3Birthmonth,
      child3Birthyear,
      child3TaxIdNumber,
      child3IsAdult,
      child3Status,
      child3Benefit,
      taxOfficeProxy,
      salutation,
      companyName,
      businessPurpose,
      foundingday,
      foundingmonth,
      foundingyear,
      businessStreet,
      businessHouseNumber,
      businessZipCode,
      businessCity,
      businessPhoneNumber,
      businessEmail,
      vatRegion,
      vatNumber,
      vatNumberAvailable,
      capitalContribution,
      limitedCompanySalutation,
      limitedCompanyName,
      limitedCompanyFoundingday,
      limitedCompanyFoundingmonth,
      limitedCompanyFoundingyear,
      limitedCompanyBusinessStreet,
      limitedCompanyBusinessHouseNumber,
      limitedCompanyBusinessZipCode,
      limitedCompanyBusinessCity,
      limitedCompanyBusinessBankAccountHolder,
      limitedCompanyBusinessBankName,
      limitedCompanyBusinessIban,
      limitedCompanyBusinessTaxOffice,
      limitedCompanyVatNumberAvailable,
      limitedCompanyVatRegion,
      limitedCompanyVatNumber,
      limitedCompanyBusinessTaxNumberAvailable,
      limitedCompanyBusinessTaxNumber,
      limitedCompanyTradeRegisterEntry,
      limitedCompanyRegistryCourt,
      limitedCompanyTradeRegisterNumber,
      limitedCompanyAcademicTitle,
      limitedCompanyFirstname,
      limitedCompanyLastname,
      limitedCompanyBirthday,
      limitedCompanyBirthmonth,
      limitedCompanyBirthyear,
      limitedCompanyDenomination,
      limitedCompanyTaxIdNumber,
      limitedCompanyStreet,
      limitedCompanyHouseNumber,
      limitedCompanyZipCode,
      limitedCompanyCity,
      limitedCompanyPhoneNumber,
      limitedCompanyEmail,
      shareCapital,
      sameShareholder,
      amountOfSubscribedCapital,
      additionalShareHolder,
      companionShareCapital,
      externalManagingDirector,
      externalManagingDirectorFirstname,
      externalManagingDirectorLastname,
      externalManagingDirectorBirthday,
      externalManagingDirectorBirthmonth,
      externalManagingDirectorBirthyear,
      externalManagingDirectorStreet,
      externalManagingDirectorHouseNumber,
      externalManagingDirectorZipCode,
      externalManagingDirectorCity,
      naturalPerson,
      marriedDay,
      marriedMonth,
      marriedYear,
      annualFinancialReport,
      differentProfitDistribution,
      partnershipAgreement,
      fixedCapital,
      secondPartnerTypeOfIdentification,
      secondPartnerPlaceOfBirth,
      secondPartnerNationality,
      secondPartnerIdentityCardNumber,
      secondPartnerIssuingAuthority,
      secondPartnerIssuedDay,
      secondPartnerIssuedMonth,
      secondPartnerIssuedYear,
      secondPartnerValidDay,
      secondPartnerValidMonth,
      secondPartnerValidYear,
      secondPartnerPep,
      additionalShareholders,
      additionalShareholder1AcademicTitle,
      additionalShareholder1Firstname,
      additionalShareholder1Lastname,
      additionalShareholder1Birthday,
      additionalShareholder1Birthmonth,
      additionalShareholder1Birthyear,
      additionalShareholder1Denomination,
      additionalShareholder1TaxIdNumber,
      additionalShareholder1Street,
      additionalShareholder1HouseNumber,
      additionalShareholder1ZipCode,
      additionalShareholder1City,
      additionalShareholder1PhoneNumber,
      additionalShareholder1Email,
      additionalShareholder1CapitalContribution,
      additionalShareholder1TypeOfIdentification,
      additionalShareholder1PlaceOfBirth,
      additionalShareholder1Nationality,
      additionalShareholder1IdentityCardNumber,
      additionalShareholder1IssuingAuthority,
      additionalShareholder1IssuedDay,
      additionalShareholder1IssuedMonth,
      additionalShareholder1IssuedYear,
      additionalShareholder1ValidDay,
      additionalShareholder1ValidMonth,
      additionalShareholder1ValidYear,
      additionalShareholder1Pep,
      additionalShareholder1PrivateTaxOffice,
      additionalShareholder1PrivateTaxNumber,
      additionalShareholder2AcademicTitle,
      additionalShareholder2Firstname,
      additionalShareholder2Lastname,
      additionalShareholder2Birthday,
      additionalShareholder2Birthmonth,
      additionalShareholder2Birthyear,
      additionalShareholder2Denomination,
      additionalShareholder2TaxIdNumber,
      additionalShareholder2Street,
      additionalShareholder2HouseNumber,
      additionalShareholder2ZipCode,
      additionalShareholder2City,
      additionalShareholder2PhoneNumber,
      additionalShareholder2Email,
      additionalShareholder2CapitalContribution,
      additionalShareholder2TypeOfIdentification,
      additionalShareholder2PlaceOfBirth,
      additionalShareholder2Nationality,
      additionalShareholder2IdentityCardNumber,
      additionalShareholder2IssuingAuthority,
      additionalShareholder2IssuedDay,
      additionalShareholder2IssuedMonth,
      additionalShareholder2IssuedYear,
      additionalShareholder2ValidDay,
      additionalShareholder2ValidMonth,
      additionalShareholder2ValidYear,
      additionalShareholder2Pep,
      additionalShareholder2PrivateTaxOffice,
      additionalShareholder2PrivateTaxNumber,
      additionalShareholder3AcademicTitle,
      additionalShareholder3Firstname,
      additionalShareholder3Lastname,
      additionalShareholder3Birthday,
      additionalShareholder3Birthmonth,
      additionalShareholder3Birthyear,
      additionalShareholder3Denomination,
      additionalShareholder3TaxIdNumber,
      additionalShareholder3Street,
      additionalShareholder3HouseNumber,
      additionalShareholder3ZipCode,
      additionalShareholder3City,
      additionalShareholder3PhoneNumber,
      additionalShareholder3Email,
      additionalShareholder3CapitalContribution,
      additionalShareholder3TypeOfIdentification,
      additionalShareholder3PlaceOfBirth,
      additionalShareholder3Nationality,
      additionalShareholder3IdentityCardNumber,
      additionalShareholder3IssuingAuthority,
      additionalShareholder3IssuedDay,
      additionalShareholder3IssuedMonth,
      additionalShareholder3IssuedYear,
      additionalShareholder3ValidDay,
      additionalShareholder3ValidMonth,
      additionalShareholder3ValidYear,
      additionalShareholder3Pep,
      additionalShareholder3PrivateTaxOffice,
      additionalShareholder3PrivateTaxNumber,
      additionalShareholder4AcademicTitle,
      additionalShareholder4Firstname,
      additionalShareholder4Lastname,
      additionalShareholder4Birthday,
      additionalShareholder4Birthmonth,
      additionalShareholder4Birthyear,
      additionalShareholder4Denomination,
      additionalShareholder4TaxIdNumber,
      additionalShareholder4Street,
      additionalShareholder4HouseNumber,
      additionalShareholder4ZipCode,
      additionalShareholder4City,
      additionalShareholder4PhoneNumber,
      additionalShareholder4Email,
      additionalShareholder4CapitalContribution,
      additionalShareholder4TypeOfIdentification,
      additionalShareholder4PlaceOfBirth,
      additionalShareholder4Nationality,
      additionalShareholder4IdentityCardNumber,
      additionalShareholder4IssuingAuthority,
      additionalShareholder4IssuedDay,
      additionalShareholder4IssuedMonth,
      additionalShareholder4IssuedYear,
      additionalShareholder4ValidDay,
      additionalShareholder4ValidMonth,
      additionalShareholder4ValidYear,
      additionalShareholder4Pep,
      additionalShareholder4PrivateTaxOffice,
      additionalShareholder4PrivateTaxNumber,
      additionalShareholder5AcademicTitle,
      additionalShareholder5Firstname,
      additionalShareholder5Lastname,
      additionalShareholder5Birthday,
      additionalShareholder5Birthmonth,
      additionalShareholder5Birthyear,
      additionalShareholder5Denomination,
      additionalShareholder5TaxIdNumber,
      additionalShareholder5Street,
      additionalShareholder5HouseNumber,
      additionalShareholder5ZipCode,
      additionalShareholder5City,
      additionalShareholder5PhoneNumber,
      additionalShareholder5Email,
      additionalShareholder5CapitalContribution,
      additionalShareholder5TypeOfIdentification,
      additionalShareholder5PlaceOfBirth,
      additionalShareholder5Nationality,
      additionalShareholder5IdentityCardNumber,
      additionalShareholder5IssuingAuthority,
      additionalShareholder5IssuedDay,
      additionalShareholder5IssuedMonth,
      additionalShareholder5IssuedYear,
      additionalShareholder5ValidDay,
      additionalShareholder5ValidMonth,
      additionalShareholder5ValidYear,
      additionalShareholder5Pep,
      additionalShareholder5PrivateTaxOffice,
      additionalShareholder5PrivateTaxNumber,
      numberOfShareholders,
      electronicInvoicing,
      is_companion_a_natural_person,
      companion_company_name,
    },
    onHelperButtonClick,
    onSubmit,
    onBackButtonClick,
  } = props

  const renderChildren = () => {
    if (numberOfChildren === 1) {
      return (
        <Fragment>
          <br />
          <li>
            <b>Erstes Kind</b>
          </li>
          <li>Vorname: {child1Firstname || '—'}</li>
          <li>Nachname: {child1Lastname || '—'}</li>
          <li>
            Geburtstag:{' '}
            {`${child1Birthday}.${child1Birthmonth}.${child1Birthyear}` || '—'}
          </li>
          <li>Persönliche-Steuer-ID: {child1TaxIdNumber || '—'}</li>
          <li>Über 18 Jahre: {child1IsAdult || '—'}</li>
          <li>Kindergeld erhalten?: {child1Benefit || '—'}</li>
          <li>Status: {child1Status || '—'}</li>
        </Fragment>
      )
    }
    if (numberOfChildren === 2) {
      return (
        <Fragment>
          <br />
          <li>
            <b>Erstes Kind</b>
          </li>
          <li>Vorname: {child1Firstname || '—'}</li>
          <li>Nachname: {child1Lastname || '—'}</li>
          <li>
            Geburtstag:{' '}
            {`${child1Birthday}.${child1Birthmonth}.${child1Birthyear}` || '—'}
          </li>
          <li>Persönliche-Steuer-ID: {child1TaxIdNumber || '—'}</li>
          <li>Über 18 Jahre: {child1IsAdult || '—'}</li>
          <li>Kindergeld erhalten?: {child1Benefit || '—'}</li>
          <li>Status: {child1Status || '—'}</li>

          <br />
          <li>
            <b>Zweites Kind</b>
          </li>
          <li>Vorname: {child2Firstname || '—'}</li>
          <li>Nachname: {child2Lastname || '—'}</li>
          <li>
            Geburtstag:{' '}
            {`${child2Birthday}.${child2Birthmonth}.${child2Birthyear}` || '—'}
          </li>
          <li>Persönliche-Steuer-ID: {child2TaxIdNumber || '—'}</li>
          <li>Über 18 Jahre: {child2IsAdult || '—'}</li>
          <li>Kindergeld erhalten?: {child2Benefit || '—'}</li>
          <li>Status: {child2Status || '—'}</li>
        </Fragment>
      )
    }
    if (numberOfChildren === 3) {
      return (
        <Fragment>
          <br />
          <li>
            <b>Erstes Kind</b>
          </li>
          <li>Vorname: {child1Firstname || '—'}</li>
          <li>Nachname: {child1Lastname || '—'}</li>
          <li>
            Geburtstag:{' '}
            {`${child1Birthday}.${child1Birthmonth}.${child1Birthyear}` || '—'}
          </li>
          <li>Persönliche-Steuer-ID: {child1TaxIdNumber || '—'}</li>
          <li>Über 18 Jahre: {child1IsAdult || '—'}</li>
          <li>Kindergeld erhalten?: {child1Benefit || '—'}</li>
          <li>Status: {child1Status || '—'}</li>

          <br />
          <li>
            <b>Zweites Kind</b>
          </li>
          <li>Vorname: {child2Firstname || '—'}</li>
          <li>Nachname: {child2Lastname || '—'}</li>
          <li>
            Geburtstag:{' '}
            {`${child2Birthday}.${child2Birthmonth}.${child2Birthyear}` || '—'}
          </li>
          <li>Persönliche-Steuer-ID: {child2TaxIdNumber || '—'}</li>
          <li>Über 18 Jahre: {child2IsAdult || '—'}</li>
          <li>Kindergeld erhalten?: {child2Benefit || '—'}</li>
          <li>Status: {child2Status || '—'}</li>

          <br />
          <li>
            <b>Drittes Kind</b>
          </li>
          <li>Vorname: {child3Firstname || '—'}</li>
          <li>Nachname: {child3Lastname || '—'}</li>
          <li>
            Geburtstag:{' '}
            {`${child3Birthday}.${child3Birthmonth}.${child3Birthyear}` || '—'}
          </li>
          <li>Persönliche-Steuer-ID: {child3TaxIdNumber || '—'}</li>
          <li>Über 18 Jahre: {child3IsAdult || '—'}</li>
          <li>Kindergeld erhalten?: {child3Benefit || '—'}</li>
          <li>Status: {child3Status || '—'}</li>
        </Fragment>
      )
    }
    return null
  }

  const renderAdditionalShareholders1 = () => {
    if (additionalShareholders === 'Ja' && additionalShareholder1Firstname) {
      return (
        <ul>
          <li>
            <b>GesellschafterIn 3</b>
          </li>
          <li>
            Akademischer Titel: {additionalShareholder1AcademicTitle || '—'}
          </li>
          <li>Vorname: {additionalShareholder1Firstname || '—'}</li>
          <li>Nachname: {additionalShareholder1Lastname || '—'}</li>
          <li>
            Geburtsdatum:{' '}
            {`${additionalShareholder1Birthday}.${additionalShareholder1Birthmonth}.${additionalShareholder1Birthyear}` ||
              '—'}
          </li>
          <li>Konfession: {additionalShareholder1Denomination || '—'}</li>
          <li>
            Persönliche Steuer-ID: {additionalShareholder1TaxIdNumber || '—'}
          </li>
          <li>
            Höhe der Einlage laut Vertrag:{' '}
            {additionalShareholder1CapitalContribution || '—'}
          </li>
          <li>
            Straße, Hausnummer: {additionalShareholder1Street || '—'}{' '}
            {additionalShareholder1HouseNumber || '—'}
          </li>
          <li>
            Postleitzahl, Ort: {additionalShareholder1ZipCode || '—'}{' '}
            {additionalShareholder1City || '—'}
          </li>
          <li>Telefonnummer: {additionalShareholder1PhoneNumber || '—'}</li>
          <li>E-Mail: {additionalShareholder1Email || '—'}</li>
          <li>
            Gesellschaftsanteile:{' '}
            {additionalShareholder1CapitalContribution || '—'}%
          </li>
          <li>
            Identifizierungsart:{' '}
            {additionalShareholder1TypeOfIdentification || '—'}
          </li>
          <li>Geburtsort: {additionalShareholder1PlaceOfBirth || '—'}</li>
          <li>
            Staatsangehörigkeit: {additionalShareholder1Nationality || '—'}
          </li>
          <li>
            Ausweisnummer: {additionalShareholder1IdentityCardNumber || '—'}
          </li>
          <li>
            Ausstellende Behörde:{' '}
            {additionalShareholder1IssuingAuthority || '—'}
          </li>
          <li>
            Ausgestellt am:{' '}
            {`${additionalShareholder1IssuedDay}.${additionalShareholder1IssuedMonth}.${additionalShareholder1IssuedYear}` ||
              '—'}
          </li>
          <li>
            Gültig bis:{' '}
            {`${additionalShareholder1ValidDay}.${additionalShareholder1ValidMonth}.${additionalShareholder1ValidYear}` ||
              '—'}
          </li>
          <li>
            Politisch exponierte Person: {additionalShareholder1Pep || '—'}
          </li>
          <li>
            Name des Finanzamtes: {additionalShareholder1PrivateTaxOffice || ''}
          </li>
          <li>Steuernummer: {additionalShareholder1PrivateTaxNumber || '—'}</li>
        </ul>
      )
    }
  }

  const renderAdditionalShareholders2 = () => {
    if (
      additionalShareholders === 'Ja' &&
      additionalShareholder2Firstname &&
      numberOfShareholders > 1
    ) {
      return (
        <ul>
          <li>
            <b>GesellschafterIn 4</b>
          </li>
          <li>
            Akademischer Titel: {additionalShareholder2AcademicTitle || '—'}
          </li>
          <li>Vorname: {additionalShareholder2Firstname || '—'}</li>
          <li>Nachname: {additionalShareholder2Lastname || '—'}</li>
          <li>
            Geburtsdatum:{' '}
            {`${additionalShareholder2Birthday}.${additionalShareholder2Birthmonth}.${additionalShareholder2Birthyear}` ||
              '—'}
          </li>
          <li>Konfession: {additionalShareholder2Denomination || '—'}</li>
          <li>
            Persönliche Steuer-ID: {additionalShareholder2TaxIdNumber || '—'}
          </li>
          <li>
            Höhe der Einlage laut Vertrag:{' '}
            {additionalShareholder2CapitalContribution || '—'}
          </li>
          <li>
            Straße, Hausnummer: {additionalShareholder2Street || '—'}{' '}
            {additionalShareholder2HouseNumber || '—'}
          </li>
          <li>
            Postleitzahl, Ort: {additionalShareholder2ZipCode || '—'}{' '}
            {additionalShareholder2City || '—'}
          </li>
          <li>Telefonnummer: {additionalShareholder2PhoneNumber || '—'}</li>
          <li>E-Mail: {additionalShareholder2Email || '—'}</li>
          <li>
            Gesellschaftsanteile:{' '}
            {additionalShareholder2CapitalContribution || '—'}%
          </li>
          <li>
            Identifizierungsart:{' '}
            {additionalShareholder2TypeOfIdentification || '—'}
          </li>
          <li>Geburtsort: {additionalShareholder2PlaceOfBirth || '—'}</li>
          <li>
            Staatsangehörigkeit: {additionalShareholder2Nationality || '—'}
          </li>
          <li>
            Ausweisnummer: {additionalShareholder2IdentityCardNumber || '—'}
          </li>
          <li>
            Ausstellende Behörde:{' '}
            {additionalShareholder2IssuingAuthority || '—'}
          </li>
          <li>
            Ausgestellt am:{' '}
            {`${additionalShareholder2IssuedDay}.${additionalShareholder2IssuedMonth}.${additionalShareholder2IssuedYear}` ||
              '—'}
          </li>
          <li>
            Gültig bis:{' '}
            {`${additionalShareholder2ValidDay}.${additionalShareholder2ValidMonth}.${additionalShareholder2ValidYear}` ||
              '—'}
          </li>
          <li>
            Politisch exponierte Person: {additionalShareholder2Pep || '—'}
          </li>
          <li>
            Name des Finanzamtes: {additionalShareholder2PrivateTaxOffice || ''}
          </li>
          <li>Steuernummer: {additionalShareholder2PrivateTaxNumber || '—'}</li>
        </ul>
      )
    }
  }

  const renderAdditionalShareholders3 = () => {
    if (
      additionalShareholders === 'Ja' &&
      additionalShareholder3Firstname &&
      numberOfShareholders > 2
    ) {
      return (
        <ul>
          <li>
            <b>GesellschafterIn 5</b>
          </li>
          <li>
            Akademischer Titel: {additionalShareholder3AcademicTitle || '—'}
          </li>
          <li>Vorname: {additionalShareholder3Firstname || '—'}</li>
          <li>Nachname: {additionalShareholder3Lastname || '—'}</li>
          <li>
            Geburtsdatum:{' '}
            {`${additionalShareholder3Birthday}.${additionalShareholder3Birthmonth}.${additionalShareholder3Birthyear}` ||
              '—'}
          </li>
          <li>Konfession: {additionalShareholder3Denomination || '—'}</li>
          <li>
            Persönliche Steuer-ID: {additionalShareholder3TaxIdNumber || '—'}
          </li>
          <li>
            Höhe der Einlage laut Vertrag:{' '}
            {additionalShareholder3CapitalContribution || '—'}
          </li>
          <li>
            Straße, Hausnummer: {additionalShareholder3Street || '—'}{' '}
            {additionalShareholder3HouseNumber || '—'}
          </li>
          <li>
            Postleitzahl, Ort: {additionalShareholder3ZipCode || '—'}{' '}
            {additionalShareholder3City || '—'}
          </li>
          <li>Telefonnummer: {additionalShareholder3PhoneNumber || '—'}</li>
          <li>E-Mail: {additionalShareholder3Email || '—'}</li>
          <li>
            Gesellschaftsanteile:{' '}
            {additionalShareholder3CapitalContribution || '—'}%
          </li>
          <li>
            Identifizierungsart:{' '}
            {additionalShareholder3TypeOfIdentification || '—'}
          </li>
          <li>Geburtsort: {additionalShareholder3PlaceOfBirth || '—'}</li>
          <li>
            Staatsangehörigkeit: {additionalShareholder3Nationality || '—'}
          </li>
          <li>
            Ausweisnummer: {additionalShareholder3IdentityCardNumber || '—'}
          </li>
          <li>
            Ausstellende Behörde:{' '}
            {additionalShareholder3IssuingAuthority || '—'}
          </li>
          <li>
            Ausgestellt am:{' '}
            {`${additionalShareholder3IssuedDay}.${additionalShareholder3IssuedMonth}.${additionalShareholder3IssuedYear}` ||
              '—'}
          </li>
          <li>
            Gültig bis:{' '}
            {`${additionalShareholder3ValidDay}.${additionalShareholder3ValidMonth}.${additionalShareholder3ValidYear}` ||
              '—'}
          </li>
          <li>
            Politisch exponierte Person: {additionalShareholder3Pep || '—'}
          </li>
          <li>
            Name des Finanzamtes: {additionalShareholder3PrivateTaxOffice || ''}
          </li>
          <li>Steuernummer: {additionalShareholder3PrivateTaxNumber || '—'}</li>
        </ul>
      )
    }
  }

  const renderAdditionalShareholders4 = () => {
    if (
      additionalShareholders === 'Ja' &&
      additionalShareholder4Firstname &&
      numberOfShareholders > 3
    ) {
      return (
        <ul>
          <li>
            <b>GesellschafterIn 6</b>
          </li>
          <li>
            Akademischer Titel: {additionalShareholder4AcademicTitle || '—'}
          </li>
          <li>Vorname: {additionalShareholder4Firstname || '—'}</li>
          <li>Nachname: {additionalShareholder4Lastname || '—'}</li>
          <li>
            Geburtsdatum:{' '}
            {`${additionalShareholder4Birthday}.${additionalShareholder4Birthmonth}.${additionalShareholder4Birthyear}` ||
              '—'}
          </li>
          <li>Konfession: {additionalShareholder4Denomination || '—'}</li>
          <li>
            Persönliche Steuer-ID: {additionalShareholder4TaxIdNumber || '—'}
          </li>
          <li>
            Höhe der Einlage laut Vertrag:{' '}
            {additionalShareholder4CapitalContribution || '—'}
          </li>
          <li>
            Straße, Hausnummer: {additionalShareholder4Street || '—'}{' '}
            {additionalShareholder4HouseNumber || '—'}
          </li>
          <li>
            Postleitzahl, Ort: {additionalShareholder4ZipCode || '—'}{' '}
            {additionalShareholder4City || '—'}
          </li>
          <li>Telefonnummer: {additionalShareholder4PhoneNumber || '—'}</li>
          <li>E-Mail: {additionalShareholder4Email || '—'}</li>
          <li>
            Gesellschaftsanteile:{' '}
            {additionalShareholder4CapitalContribution || '—'}%
          </li>
          <li>
            Identifizierungsart:{' '}
            {additionalShareholder4TypeOfIdentification || '—'}
          </li>
          <li>Geburtsort: {additionalShareholder4PlaceOfBirth || '—'}</li>
          <li>
            Staatsangehörigkeit: {additionalShareholder4Nationality || '—'}
          </li>
          <li>
            Ausweisnummer: {additionalShareholder4IdentityCardNumber || '—'}
          </li>
          <li>
            Ausstellende Behörde:{' '}
            {additionalShareholder4IssuingAuthority || '—'}
          </li>
          <li>
            Ausgestellt am:{' '}
            {`${additionalShareholder4IssuedDay}.${additionalShareholder4IssuedMonth}.${additionalShareholder4IssuedYear}` ||
              '—'}
          </li>
          <li>
            Gültig bis:{' '}
            {`${additionalShareholder4ValidDay}.${additionalShareholder4ValidMonth}.${additionalShareholder4ValidYear}` ||
              '—'}
          </li>
          <li>
            Politisch exponierte Person: {additionalShareholder4Pep || '—'}
          </li>
          <li>
            Name des Finanzamtes: {additionalShareholder4PrivateTaxOffice || ''}
          </li>
          <li>Steuernummer: {additionalShareholder4PrivateTaxNumber || '—'}</li>
        </ul>
      )
    }
  }

  const renderAdditionalShareholders5 = () => {
    if (
      additionalShareholders === 'Ja' &&
      additionalShareholder5Firstname &&
      numberOfShareholders > 4
    ) {
      return (
        <ul>
          <li>
            <b>GesellschafterIn 7</b>
          </li>
          <li>
            Akademischer Titel: {additionalShareholder5AcademicTitle || '—'}
          </li>
          <li>Vorname: {additionalShareholder5Firstname || '—'}</li>
          <li>Nachname: {additionalShareholder5Lastname || '—'}</li>
          <li>
            Geburtsdatum:{' '}
            {`${additionalShareholder5Birthday}.${additionalShareholder5Birthmonth}.${additionalShareholder5Birthyear}` ||
              '—'}
          </li>
          <li>Konfession: {additionalShareholder5Denomination || '—'}</li>
          <li>
            Persönliche Steuer-ID: {additionalShareholder5TaxIdNumber || '—'}
          </li>
          <li>
            Höhe der Einlage laut Vertrag:{' '}
            {additionalShareholder5CapitalContribution || '—'}
          </li>
          <li>
            Straße, Hausnummer: {additionalShareholder5Street || '—'}{' '}
            {additionalShareholder5HouseNumber || '—'}
          </li>
          <li>
            Postleitzahl, Ort: {additionalShareholder5ZipCode || '—'}{' '}
            {additionalShareholder5City || '—'}
          </li>
          <li>Telefonnummer: {additionalShareholder5PhoneNumber || '—'}</li>
          <li>E-Mail: {additionalShareholder5Email || '—'}</li>
          <li>
            Gesellschaftsanteile:{' '}
            {additionalShareholder5CapitalContribution || '—'}%
          </li>
          <li>
            Identifizierungsart:{' '}
            {additionalShareholder5TypeOfIdentification || '—'}
          </li>
          <li>Geburtsort: {additionalShareholder5PlaceOfBirth || '—'}</li>
          <li>
            Staatsangehörigkeit: {additionalShareholder5Nationality || '—'}
          </li>
          <li>
            Ausweisnummer: {additionalShareholder5IdentityCardNumber || '—'}
          </li>
          <li>
            Ausstellende Behörde:{' '}
            {additionalShareholder5IssuingAuthority || '—'}
          </li>
          <li>
            Ausgestellt am:{' '}
            {`${additionalShareholder5IssuedDay}.${additionalShareholder5IssuedMonth}.${additionalShareholder5IssuedYear}` ||
              '—'}
          </li>
          <li>
            Gültig bis:{' '}
            {`${additionalShareholder5ValidDay}.${additionalShareholder5ValidMonth}.${additionalShareholder5ValidYear}` ||
              '—'}
          </li>
          <li>
            Politisch exponierte Person: {additionalShareholder5Pep || '—'}
          </li>

          <li>
            Name des Finanzamtes: {additionalShareholder5PrivateTaxOffice || ''}
          </li>
          <li>Steuernummer: {additionalShareholder5PrivateTaxNumber || '—'}</li>
        </ul>
      )
    }
  }

  const renderContent = () => {
    if (type === 'standard') {
      return (
        <FormBody>
          <div className={classes.summaryList}>
            <ul>
              <li>
                <b>Allgemein</b>
              </li>
              <li>Name: {contactPerson}</li>
              <li>Letzte Einkommensteuererklärung: {annualFinancialReport}</li>
            </ul>
            <ul>
              <li>
                <b>Persönliche Angaben</b>
              </li>
              <li>Akademischer Titel: {academicTitle || '—'}</li>
              <li>Vorname: {firstname || '—'}</li>
              <li>Nachname: {lastname || '—'}</li>
              <li>
                Geburtstag: {`${birthday}.${birthmonth}.${birthyear}` || '—'}
              </li>
              <li>Konfession: {denomination || '—'}</li>
              <li>Sozialversicherungsnummer: {socialSecurityNumber || '—'}</li>
              <li>Persönliche Steuer-ID: {taxIdNumber || '—'}</li>
              <li>Straße, Hausnummer: {`${street} ${houseNumber}` || '—'}</li>
              <li>Postleitzahl: {zipCode || '—'}</li>
              <li>Stadt: {city || '—'}</li>
              <li>Telefonnummer: {phoneNumber || '—'}</li>
              <li>E-Mail-Adresse: {email || '—'}</li>{' '}
            </ul>

            <ul>
              <li>
                <b>Identifikation</b>
              </li>
              <li>Identifizierungsart: {typeOfIdentification || '—'}</li>
              <li>Geburtsort: {placeOfBirth || '—'}</li>
              <li>Staatsangehörigkeit: {nationality || '—'}</li>
              <li>Ausweisnummer: {identityCardNumber || '—'}</li>
              <li>Ausstellende Behörde: {issuingAuthority || '—'}</li>
              <li>
                Ausgestellt am:{' '}
                {`${issuedDay}.${issuedMonth}.${issuedYear}` || '—'}
              </li>
              <li>
                Gültig bis: {`${validDay}.${validMonth}.${validYear}` || '—'}
              </li>
              <li>Politisch exponierte Person: {pep || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Private Bankdaten</b>
              </li>
              <li>KontoinhaberIn: {privateBankAccountHolder || '—'}</li>
              <li>Name der Bank: {privateBankName || '—'}</li>
              <li>IBAN: {privateIban || '—'}</li>
              {props.features.sepa ? (
                <li>SEPA-Lastschrift: {allowSepaForPrivateIban || '—'}</li>
              ) : null}
            </ul>

            {businessBankAccountHolder ? (
              <ul>
                <li>
                  <b>Geschäftliche Bankdaten</b>
                </li>
                <li>KontoinhaberIn: {businessBankAccountHolder || '—'}</li>
                <li>Name der Bank: {businessBankName || '—'}</li>
                <li>IBAN: {businessIban || '—'}</li>
                {props.features.sepa ? (
                  <li>SEPA-Lastschrift: {allowSepaForBusinessIban || '—'}</li>
                ) : null}
              </ul>
            ) : null}

            {businessTaxOffice ? (
              <ul>
                <li>
                  <b>Betriebliches Finanzamt</b>
                </li>
                <li>Name des Finanzamtes: {businessTaxOffice || ''}</li>
                {vatNumberAvailable === 'Ja' ? (
                  <Fragment>
                    <li>
                      Ist eine Umsatzsteuer-Identifikationsnummer vorhanden?:{' '}
                      {vatNumberAvailable || '—'}
                    </li>
                    <li>
                      Umsatzsteuer-Identifikationsnummer:{' '}
                      {`${vatRegion}${vatNumber}` || '—'}
                    </li>
                  </Fragment>
                ) : (
                  <li>
                    Ist eine Umsatzsteuer-Identifikationsnummer vorhanden?:{' '}
                    {vatNumberAvailable || '—'}
                  </li>
                )}
                {businessTaxNumberAvailable === 'Ja' ? (
                  <Fragment>
                    <li>
                      Steuernummer vorhanden:{' '}
                      {businessTaxNumberAvailable || '—'}
                    </li>
                    <li>Steuernummer: {businessTaxNumber || '—'}</li>
                  </Fragment>
                ) : (
                  <li>
                    Steuernummer vorhanden: {businessTaxNumberAvailable || '—'}
                  </li>
                )}
                <li>Handelsregistereintrag: {tradeRegisterEntry || '—'}</li>
                {tradeRegisterEntry === 'Ja' ? (
                  <Fragment>
                    <li>Registergericht: {registryCourt || '—'}</li>
                    <li>
                      Handelsregisternummber: {tradeRegisterNumber || '—'}
                    </li>
                  </Fragment>
                ) : null}
              </ul>
            ) : null}

            <ul>
              <li>
                <b>Finanzamt</b>
              </li>
              <li>Name des Finanzamtes: {privateTaxOffice || ''}</li>
              <li>Steuernummer: {privateTaxNumber || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Angaben LebenspartnerIn</b>
              </li>
              <li>Verheiratet/Verpartnert: {married || '—'}</li>
              {married === 'Ja' ? (
                <Fragment>
                  <li>
                    Verheiratet seit:{' '}
                    {`${marriedDay}.${marriedMonth}.${marriedYear}` || '—'}
                  </li>
                  <li>Akademischer Titel: {companionAcademicTitle || '—'}</li>
                  <li>Vorname: {companionFirstname || '—'}</li>
                  <li>Nachname: {companionLastname || '—'}</li>
                  <li>
                    Geburtstag:{' '}
                    {`${companionBirthday}.${companionBirthmonth}.${companionBirthyear}` ||
                      '—'}
                  </li>
                  <li>Konfession: {companionDenomination || '—'}</li>
                  <li>
                    Sozialversicherungsnummer:{' '}
                    {companionSocialSecurityNumber || '—'}
                  </li>
                  <li>Persönliche Steuer-ID: {companionTaxIdNumber || '—'}</li>
                  <li>
                    Straße, Hausnummer:{' '}
                    {`${companionStreet} ${companionHouseNumber}` || '—'}
                  </li>
                  <li>Postleitzahl: {companionZipCode || '—'}</li>
                  <li>Stadt: {companionCity || '—'}</li>
                  <li>Telefonnummer: {companionPhoneNumber || '—'}</li>
                  <li>E-Mail-Adresse: {companionEmail || '—'}</li>{' '}
                </Fragment>
              ) : null}
            </ul>

            {married === 'Ja' ? (
              <ul>
                <li>
                  <b>Identifikation LebenspartnerIn</b>
                </li>
                <li>Verheiratet/Verpartnert: {married || '—'}</li>

                <li>
                  Identifizierungsart: {companionTypeOfIdentification || '—'}
                </li>
                <li>Geburtsort: {companionPlaceOfBirth || '—'}</li>
                <li>Staatsangehörigkeit: {companionNationality || '—'}</li>
                <li>Ausweisnummer: {companionIdentityCardNumber || '—'}</li>
                <li>
                  Ausstellende Behörde: {companionIssuingAuthority || '—'}
                </li>
                <li>
                  Ausgestellt am:{' '}
                  {`${companionIssuedDay}.${companionIssuedMonth}.${companionIssuedYear}` ||
                    '—'}
                </li>
                <li>
                  Gültig bis:{' '}
                  {`${companionValidDay}.${companionValidMonth}.${companionValidYear}` ||
                    '—'}
                </li>
                <li>Politisch exponierte Person: {companionPep || '—'}</li>
              </ul>
            ) : null}

            <ul>
              <li>
                <b>Angaben zu Kindern</b>
              </li>
              {children === 'Ja' ? (
                renderChildren()
              ) : (
                <li>Kinder: {children || '—'}</li>
              )}
            </ul>

            <ul>
              <li>
                <b>Vollmacht für das Finanzamt</b>
              </li>
              <li>Vollmacht erteilt: {taxOfficeProxy || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Elektronischer Rechnungsversand</b>
              </li>
              <li>
                {electronicInvoicing
                  ? 'Versand via E-Mail zugestimmt'
                  : 'Versand via E-Mail nicht zugestimmt'}
              </li>
            </ul>
          </div>
        </FormBody>
      )
    }
    if (type === 'limited-company-and-partnership-kg') {
      return (
        <FormBody>
          <div className={classes.summaryList}>
            <ul>
              <li>
                <b>Allgemein</b>
              </li>
              <li>Name: {contactPerson}</li>
              <li>Letzter Jahresabschluss: {annualFinancialReport}</li>
            </ul>
            <ul>
              <li>
                <b>Betriebliche Angaben der KG (Kommanditgesellschaft)</b>
              </li>
              <li>Anrede: {salutation || '—'}</li>
              <li>Name des Unternehmens: {companyName || '—'}</li>
              <li>Ausgeführte Tätigkeit: {businessPurpose || '—'}</li>
              <li>
                Gegründet am:{' '}
                {`${foundingday}.${foundingmonth}.${foundingyear}` || '—'}
              </li>
              <li>
                Straße, Hausnummer:{' '}
                {`${businessStreet} ${businessHouseNumber}` || '—'}
              </li>
              <li>Postleitzahl: {businessZipCode || '—'}</li>
              <li>Stadt: {businessCity || '—'}</li>
              <li>Telefonnummer: {businessPhoneNumber || '—'}</li>
              <li>E-Mail-Adresse: {businessEmail || '—'}</li>{' '}
            </ul>

            <ul>
              <li>
                <b>Betriebliche Bankdaten der KG (Kommanditgesellschaft)</b>
              </li>
              <li>KontoinhaberIn: {businessBankAccountHolder || '—'}</li>
              <li>Name der Bank: {businessBankName || '—'}</li>
              <li>IBAN: {businessIban || '—'}</li>
              {props.features.sepa ? (
                <li>SEPA-Lastschrift: {allowSepaForBusinessIban || '—'}</li>
              ) : null}
            </ul>

            <ul>
              <li>
                <b>Betriebliches Finanzamt der KG (Kommanditgesellschaft)</b>
              </li>
              <li>Name des Finanzamtes: {businessTaxOffice || '—'}</li>
              {vatNumberAvailable === 'Ja' ? (
                <li>
                  Umsatzsteuer-Identifikationsnummer: {vatRegion}
                  {vatNumber || '—'}
                </li>
              ) : (
                <li>
                  Ist eine Umsatzsteuer-Identifikationsnummer vorhanden?:{' '}
                  {vatNumberAvailable || '—'}
                </li>
              )}
              {businessTaxNumberAvailable === 'Ja' ? (
                <li>Steuernummer: {businessTaxNumber || '—'}</li>
              ) : (
                <li>
                  Ist eine Steuernummer vorhanden?:{' '}
                  {businessTaxNumberAvailable || '—'}
                </li>
              )}
              {tradeRegisterEntry === 'Ja' ? (
                <Fragment>
                  <li>Ort des Registergerichtes: {registryCourt || '—'}</li>
                  <li>Handelsregister-Nummer: {tradeRegisterNumber || '—'}</li>
                </Fragment>
              ) : (
                <li>
                  Handelsregistereintrag vorhanden?: {tradeRegisterEntry || '—'}
                </li>
              )}
            </ul>

            <ul>
              <li>
                <b>Persönliche Angaben (KG-GesellschafterIn)</b>
              </li>
              <li>Akademischer Titel: {academicTitle || '—'}</li>
              <li>Vorname: {firstname || '—'}</li>
              <li>Nachname: {lastname || '—'}</li>
              <li>
                Geburtsdatum: {`${birthday}.${birthmonth}.${birthyear}` || '—'}
              </li>
              <li>Konfession: {denomination || '—'}</li>
              <li>Persönliche Steuer-ID: {taxIdNumber || '—'}</li>
              <li>
                Höhe der Einlage laut Vertrag: {capitalContribution || '—'}
              </li>
              <li>
                Straße, Hausnummer: {street || '—'} {houseNumber || '—'}
              </li>
              <li>
                Postleitzahl, Ort: {zipCode || '—'} {city || '—'}
              </li>
              <li>Telefonnummer: {phoneNumber || '—'}</li>
              <li>E-Mail: {email || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Identifikation (KG-GesellschafterIn)</b>
              </li>
              <li>Identifizierungsart: {typeOfIdentification || '—'}</li>
              <li>Geburtsort: {placeOfBirth || '—'}</li>
              <li>Staatsangehörigkeit: {nationality || '—'}</li>
              <li>Ausweisnummer: {identityCardNumber || '—'}</li>
              <li>Ausstellende Behörde: {issuingAuthority || '—'}</li>
              <li>
                Ausgestellt am:{' '}
                {`${issuedDay}.${issuedMonth}.${issuedYear}` || '—'}
              </li>
              <li>
                Gültig bis: {`${validDay}.${validMonth}.${validYear}` || '—'}
              </li>
              <li>Politisch exponierte Person: {pep || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Privates Finanzamt (KG-GesellschafterIn)</b>
              </li>
              <li>Name des Finanzamtes: {privateTaxOffice || ''}</li>
              <li>Steuernummer: {privateTaxNumber || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Betriebliche Angaben des Vollhafters (GmbH)</b>
              </li>
              <li>Anrede: {limitedCompanySalutation || ''}</li>
              <li>Name des Unternehmens: {limitedCompanyName || '—'}</li>
              <li>
                Gegründet am:{' '}
                {`${limitedCompanyFoundingday}.${limitedCompanyFoundingmonth}.${limitedCompanyFoundingyear}` ||
                  '—'}
              </li>
              <li>
                Höhe des gezeichneten Kapitals:{' '}
                {amountOfSubscribedCapital || '—'}
              </li>
              <li>
                Straße, Hausnummer:{' '}
                {`${limitedCompanyBusinessStreet} ${limitedCompanyBusinessHouseNumber}` ||
                  '—'}
              </li>
              <li>
                Postleitzahl, Ort:{' '}
                {`${limitedCompanyBusinessZipCode} ${limitedCompanyBusinessCity}` ||
                  '—'}
              </li>
            </ul>

            <ul>
              <li>
                <b>Betriebliche Bankdaten des Vollhafters (GmbH)</b>
              </li>
              <li>
                KontoinhaberIn: {limitedCompanyBusinessBankAccountHolder || '—'}
              </li>
              <li>Name der Bank: {limitedCompanyBusinessBankName || '—'}</li>
              <li>IBAN: {limitedCompanyBusinessIban || '—'}</li>
              {props.features.sepa ? (
                <li>
                  SEPA-Lastschrift:{' '}
                  {allowSepaForlimitedCompanyBusinessIban || '—'}
                </li>
              ) : null}
            </ul>

            <ul>
              <li>
                <b>Betriebliches Finanzamt des Vollhafters (GmbH)</b>
              </li>
              <li>
                Name des Finanzamtes: {limitedCompanyBusinessTaxOffice || '—'}
              </li>
              {limitedCompanyVatNumberAvailable === 'Ja' ? (
                <li>
                  Umsatzsteuer-Identifikationsnummer: {limitedCompanyVatRegion}
                  {limitedCompanyVatNumber || '—'}
                </li>
              ) : (
                <li>
                  Ist eine Umsatzsteuer-Identifikationsnummer vorhanden?:{' '}
                  {limitedCompanyVatNumberAvailable || '—'}
                </li>
              )}
              {limitedCompanyBusinessTaxNumberAvailable === 'Ja' ? (
                <li>Steuernummer: {limitedCompanyBusinessTaxNumber || '—'}</li>
              ) : (
                <li>
                  Ist eine Steuernummer vorhanden?:{' '}
                  {limitedCompanyBusinessTaxNumberAvailable || '—'}
                </li>
              )}
              {limitedCompanyTradeRegisterEntry === 'Ja' ? (
                <Fragment>
                  <li>
                    Ort des Registergerichtes:{' '}
                    {limitedCompanyRegistryCourt || '—'}
                  </li>
                  <li>
                    Handelsregister-Nummer:{' '}
                    {limitedCompanyTradeRegisterNumber || '—'}
                  </li>
                </Fragment>
              ) : (
                <li>
                  Handelsregistereintrag vorhanden?:{' '}
                  {limitedCompanyTradeRegisterEntry || '—'}
                </li>
              )}
            </ul>

            <ul>
              <li>
                <b>Persönliche Angaben (GmbH-GesellschafterIn)</b>
              </li>
              <li>Akademischer Titel: {limitedCompanyAcademicTitle || '—'}</li>
              <li>Vorname: {limitedCompanyFirstname || '—'}</li>
              <li>Nachname: {limitedCompanyLastname || '—'}</li>
              <li>
                Geburtsdatum:{' '}
                {`${limitedCompanyBirthday}.${limitedCompanyBirthmonth}.${limitedCompanyBirthyear}` ||
                  '—'}
              </li>
              <li>Konfession: {limitedCompanyDenomination || '—'}</li>
              <li>Persönliche Steuer-ID: {limitedCompanyTaxIdNumber || '—'}</li>
              <li>
                Straße, Hausnummer: {limitedCompanyStreet || '—'}{' '}
                {limitedCompanyHouseNumber || '—'}
              </li>
              <li>
                Postleitzahl, Ort: {limitedCompanyZipCode || '—'}{' '}
                {limitedCompanyCity || '—'}
              </li>
              <li>Telefonnummer: {limitedCompanyPhoneNumber || '—'}</li>
              <li>E-Mail: {limitedCompanyEmail || '—'}</li>
              {sameShareholder === 'Ja' ? (
                <li>Beteiligung am Stammkapital: {shareCapital}%</li>
              ) : null}
            </ul>

            <ul>
              <li>
                <b>Vollmacht für das Finanzamt</b>
              </li>
              <li>Vollmacht erteilt: {taxOfficeProxy || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Elektronischer Rechnungsversand</b>
              </li>
              <li>
                {electronicInvoicing
                  ? 'Versand via E-Mail zugestimmt'
                  : 'Versand via E-Mail nicht zugestimmt'}
              </li>
            </ul>
          </div>
        </FormBody>
      )
    }
    if (type === 'limited-company') {
      return (
        <FormBody>
          <div className={classes.summaryList}>
            <ul>
              <li>
                <b>Allgemein</b>
              </li>
              <li>Name: {contactPerson}</li>
              <li>Letzter Jahresabschluss: {annualFinancialReport}</li>
            </ul>
            <ul>
              <li>
                <b>Betriebliche Angaben</b>
              </li>
              <li>Anrede: {salutation || '—'}</li>
              <li>Name des Unternehmens: {limitedCompanyName || '—'}</li>
              <li>
                Gegründet am:{' '}
                {`${limitedCompanyFoundingday}.${limitedCompanyFoundingmonth}.${limitedCompanyFoundingyear}` ||
                  '—'}
              </li>
              <li>
                Straße, Hausnummer:{' '}
                {`${limitedCompanyBusinessStreet} ${limitedCompanyBusinessHouseNumber}` ||
                  '—'}
              </li>
              <li>Postleitzahl: {limitedCompanyBusinessZipCode || '—'}</li>
              <li>Stadt: {limitedCompanyBusinessCity || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Betriebliche Bankdaten</b>
              </li>
              <li>
                KontoinhaberIn: {limitedCompanyBusinessBankAccountHolder || '—'}
              </li>
              <li>Name der Bank: {limitedCompanyBusinessBankName || '—'}</li>
              <li>IBAN: {limitedCompanyBusinessIban || '—'}</li>
              {props.features.sepa ? (
                <li>
                  SEPA-Lastschrift:{' '}
                  {allowSepaForlimitedCompanyBusinessIban || '—'}
                </li>
              ) : null}
            </ul>

            <ul>
              <li>
                <b>Betriebliches Finanzamt</b>
              </li>
              <li>
                Name des Finanzamtes: {limitedCompanyBusinessTaxOffice || '—'}
              </li>
              {limitedCompanyVatNumberAvailable === 'Ja' ? (
                <li>
                  Umsatzsteuer-Identifikationsnummer: {limitedCompanyVatRegion}
                  {limitedCompanyVatNumber || '—'}
                </li>
              ) : (
                <li>
                  Ist eine Umsatzsteuer-Identifikationsnummer vorhanden?:{' '}
                  {limitedCompanyVatNumberAvailable || '—'}
                </li>
              )}
              {limitedCompanyBusinessTaxNumberAvailable === 'Ja' ? (
                <li>Steuernummer: {limitedCompanyBusinessTaxNumber || '—'}</li>
              ) : (
                <li>
                  Ist eine Steuernummer vorhanden?:{' '}
                  {limitedCompanyBusinessTaxNumberAvailable || '—'}
                </li>
              )}
              {limitedCompanyTradeRegisterEntry === 'Ja' ? (
                <Fragment>
                  <li>
                    Ort des Registergerichtes:{' '}
                    {limitedCompanyRegistryCourt || '—'}
                  </li>
                  <li>
                    Handelsregister-Nummer:{' '}
                    {limitedCompanyTradeRegisterNumber || '—'}
                  </li>
                </Fragment>
              ) : (
                <li>
                  Handelsregistereintrag vorhanden?:{' '}
                  {limitedCompanyTradeRegisterEntry || '—'}
                </li>
              )}
            </ul>

            <ul>
              <li>
                <b>Persönliche Daten des Gesellschafters</b>
              </li>
              <li>
                Ist der Gesellschafter eine natürliche Person?:{' '}
                {naturalPerson || '—'}
              </li>
              {naturalPerson === 'Ja' ? (
                <Fragment>
                  <li>
                    Akademischer Titel: {limitedCompanyAcademicTitle || '—'}
                  </li>
                  <li>Vorname: {firstname || '—'}</li>
                  <li>Nachname: {lastname || '—'}</li>
                  <li>
                    Geburtsdatum:{' '}
                    {`${limitedCompanyBirthday}.${limitedCompanyBirthmonth}.${limitedCompanyBirthyear}` ||
                      '—'}
                  </li>
                  <li>Konfession: {limitedCompanyDenomination || '—'}</li>
                  <li>Beteiligung am Stammkapital: {shareCapital || '—'}%</li>
                  <li>
                    Persönliche Steuer-ID: {limitedCompanyTaxIdNumber || '—'}
                  </li>
                  <li>
                    Straße, Hausnummer: {limitedCompanyStreet || '—'}{' '}
                    {limitedCompanyHouseNumber || '—'}
                  </li>
                  <li>
                    Postleitzahl, Ort: {limitedCompanyZipCode || '—'}{' '}
                    {limitedCompanyCity || '—'}
                  </li>
                  <li>Telefonnummer: {limitedCompanyPhoneNumber || '—'}</li>
                  <li>E-Mail: {email || '—'}</li>
                </Fragment>
              ) : (
                <Fragment>
                  <li>Name des Unternehmens: {companyName || '—'}</li>
                  <li>
                    Höhe des gezeichneten Kapitals:{' '}
                    {amountOfSubscribedCapital || '—'}
                  </li>
                  <li>
                    Straße, Hausnummer:{' '}
                    {`${limitedCompanyStreet} ${limitedCompanyHouseNumber}` ||
                      '—'}
                  </li>
                  <li>
                    Postleitzahl, Ort:{' '}
                    {`${limitedCompanyZipCode} ${limitedCompanyCity}` || '—'}
                  </li>
                  <li>Telefonnummer: {limitedCompanyPhoneNumber || '—'}</li>
                  <li>E-Mail: {email || '—'}</li>
                </Fragment>
              )}
            </ul>

            {naturalPerson === 'Ja' ? (
              <ul>
                <li>
                  <b>Identifikation des Gesellschafters</b>
                </li>
                <li>Identifizierungsart: {typeOfIdentification || '—'}</li>
                <li>Geburtsort: {placeOfBirth || '—'}</li>
                <li>Staatsangehörigkeit: {nationality || '—'}</li>
                <li>Ausweisnummer: {identityCardNumber || '—'}</li>
                <li>Ausstellende Behörde: {issuingAuthority || '—'}</li>
                <li>
                  Ausgestellt am:{' '}
                  {`${issuedDay}.${issuedMonth}.${issuedYear}` || '—'}
                </li>
                <li>
                  Gültig bis: {`${validDay}.${validMonth}.${validYear}` || '—'}
                </li>
                <li>Politisch exponierte Person: {pep || '—'}</li>
              </ul>
            ) : null}

            <ul>
              <li>
                <b>Weitere Gesellschafter</b>
              </li>
              {additionalShareHolder === 'Ja' ? (
                <>
                  {is_companion_a_natural_person === 'Ja' ? (
                    <Fragment>
                      <li>
                        Akademischer Titel: {companionAcademicTitle || '—'}
                      </li>
                      <li>Vorname: {companionFirstname || '—'}</li>
                      <li>Nachname: {companionLastname || '—'}</li>
                      <li>
                        Geburtsdatum:{' '}
                        {`${companionBirthday}.${companionBirthmonth}.${companionBirthyear}` ||
                          '—'}
                      </li>
                      <li>Konfession: {companionDenomination || '—'}</li>
                      <li>
                        Beteiligung am Stammkapital:{' '}
                        {companionShareCapital || '—'}%
                      </li>
                      <li>
                        Persönliche Steuer-ID: {companionTaxIdNumber || '—'}
                      </li>
                      <li>
                        Straße, Hausnummer: {companionStreet || '—'}{' '}
                        {companionHouseNumber || '—'}
                      </li>
                      <li>
                        Postleitzahl, Ort: {companionZipCode || '—'}{' '}
                        {companionCity || '—'}
                      </li>
                      <li>Telefonnummer: {companionPhoneNumber || '—'}</li>
                      <li>E-Mail: {companionEmail || '—'}</li>
                      <li>
                        Identifizierungsart:{' '}
                        {companionTypeOfIdentification || '—'}
                      </li>
                      <li>Geburtsort: {companionPlaceOfBirth || '—'}</li>
                      <li>
                        Staatsangehörigkeit: {companionNationality || '—'}
                      </li>
                      <li>
                        Ausweisnummer: {companionIdentityCardNumber || '—'}
                      </li>
                      <li>
                        Ausstellende Behörde: {companionIssuingAuthority || '—'}
                      </li>
                      <li>
                        Ausgestellt am:{' '}
                        {`${companionIssuedDay}.${companionIssuedMonth}.${companionIssuedYear}` ||
                          '—'}
                      </li>
                      <li>
                        Gültig bis:{' '}
                        {`${companionValidDay}.${companionValidMonth}.${companionValidYear}` ||
                          '—'}
                      </li>
                      <li>
                        Politisch exponierte Person: {companionPep || '—'}
                      </li>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <li>
                        Name des Unternehmens: {companion_company_name || '—'}
                      </li>
                      <li>
                        Beteiligung am Stammkapital:{' '}
                        {companionShareCapital || '—'}%
                      </li>
                      <li>
                        Persönliche Steuer-ID: {companionTaxIdNumber || '—'}
                      </li>
                      <li>
                        Straße, Hausnummer: {companionStreet || '—'}{' '}
                        {companionHouseNumber || '—'}
                      </li>
                      <li>
                        Postleitzahl, Ort: {companionZipCode || '—'}{' '}
                        {companionCity || '—'}
                      </li>
                      <li>Telefonnummer: {companionPhoneNumber || '—'}</li>
                      <li>E-Mail: {companionEmail || '—'}</li>
                    </Fragment>
                  )}
                </>
              ) : (
                <li>
                  Hat die GmbH weitere Gesellschafter:{' '}
                  {additionalShareHolder || '—'}
                </li>
              )}
            </ul>

            <ul>
              <li>
                <b>Angaben zur Geschäftsführung</b>
              </li>
              {(is_companion_a_natural_person === 'Nein' &&
                naturalPerson === 'Nein') ||
              externalManagingDirector === 'Ja' ? (
                <Fragment>
                  {externalManagingDirector === 'Ja' && (
                    <li>
                      Gibt es einen Fremd-Geschäftsführer:{' '}
                      {externalManagingDirector || '—'}
                    </li>
                  )}
                  <li>
                    Name:{' '}
                    {`${externalManagingDirectorFirstname} ${externalManagingDirectorLastname}` ||
                      '—'}
                  </li>
                  <li>
                    Geburtsdatum:{' '}
                    {`${externalManagingDirectorBirthday}.${externalManagingDirectorBirthmonth}.${externalManagingDirectorBirthyear}` ||
                      '—'}
                  </li>
                  <li>
                    Straße, Hausnummer:{' '}
                    {`${externalManagingDirectorStreet} ${externalManagingDirectorHouseNumber}` ||
                      '—'}
                  </li>
                  <li>
                    Postleitzahl, Stadt:{' '}
                    {`${externalManagingDirectorZipCode} ${externalManagingDirectorCity}` ||
                      '—'}
                  </li>
                </Fragment>
              ) : (
                <li>
                  Gibt es einen Fremd-Geschäftsführer:{' '}
                  {externalManagingDirector || '—'}
                </li>
              )}
            </ul>

            <ul>
              <li>
                <b>Vollmacht für das Finanzamt</b>
              </li>
              <li>Vollmacht erteilt: {taxOfficeProxy || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Elektronischer Rechnungsversand</b>
              </li>
              <li>
                {electronicInvoicing
                  ? 'Versand via E-Mail zugestimmt'
                  : 'Versand via E-Mail nicht zugestimmt'}
              </li>
            </ul>
          </div>
        </FormBody>
      )
    }
    if (type === 'limited-entrepreneurial-company') {
      return (
        <FormBody>
          <div className={classes.summaryList}>
            <ul>
              <li>
                <b>Allgemein</b>
              </li>
              <li>Name: {contactPerson}</li>
              <li>Letzter Jahresabschluss: {annualFinancialReport}</li>
            </ul>
            <ul>
              <li>
                <b>Betriebliche Angaben</b>
              </li>
              <li>Anrede: {salutation || '—'}</li>
              <li>Name des Unternehmens: {limitedCompanyName || '—'}</li>
              <li>
                Gegründet am:{' '}
                {`${limitedCompanyFoundingday}.${limitedCompanyFoundingmonth}.${limitedCompanyFoundingyear}` ||
                  '—'}
              </li>
              <li>
                Straße, Hausnummer:{' '}
                {`${limitedCompanyBusinessStreet} ${limitedCompanyBusinessHouseNumber}` ||
                  '—'}
              </li>
              <li>Postleitzahl: {limitedCompanyBusinessZipCode || '—'}</li>
              <li>Stadt: {limitedCompanyBusinessCity || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Betriebliche Bankdaten</b>
              </li>
              <li>
                KontoinhaberIn: {limitedCompanyBusinessBankAccountHolder || '—'}
              </li>
              <li>Name der Bank: {limitedCompanyBusinessBankName || '—'}</li>
              <li>IBAN: {limitedCompanyBusinessIban || '—'}</li>
              {props.features.sepa ? (
                <li>
                  SEPA-Lastschrift:{' '}
                  {allowSepaForlimitedCompanyBusinessIban || '—'}
                </li>
              ) : null}
            </ul>

            <ul>
              <li>
                <b>Betriebliches Finanzamt</b>
              </li>
              <li>
                Name des Finanzamtes: {limitedCompanyBusinessTaxOffice || '—'}
              </li>
              {limitedCompanyVatNumberAvailable === 'Ja' ? (
                <li>
                  Umsatzsteuer-Identifikationsnummer: {limitedCompanyVatRegion}
                  {limitedCompanyVatNumber || '—'}
                </li>
              ) : (
                <li>
                  Ist eine Umsatzsteuer-Identifikationsnummer vorhanden?:{' '}
                  {limitedCompanyVatNumberAvailable || '—'}
                </li>
              )}
              {limitedCompanyBusinessTaxNumberAvailable === 'Ja' ? (
                <li>Steuernummer: {limitedCompanyBusinessTaxNumber || '—'}</li>
              ) : (
                <li>
                  Ist eine Steuernummer vorhanden?:{' '}
                  {limitedCompanyBusinessTaxNumberAvailable || '—'}
                </li>
              )}
              {limitedCompanyTradeRegisterEntry === 'Ja' ? (
                <Fragment>
                  <li>
                    Ort des Registergerichtes:{' '}
                    {limitedCompanyRegistryCourt || '—'}
                  </li>
                  <li>
                    Handelsregister-Nummer:{' '}
                    {limitedCompanyTradeRegisterNumber || '—'}
                  </li>
                </Fragment>
              ) : (
                <li>
                  Handelsregistereintrag vorhanden?:{' '}
                  {limitedCompanyTradeRegisterEntry || '—'}
                </li>
              )}
            </ul>

            <ul>
              <li>
                <b>Persönliche Daten des Gesellschafters</b>
              </li>
              <li>
                Ist der Gesellschafter eine natürliche Person?:{' '}
                {naturalPerson || '—'}
              </li>
              {naturalPerson === 'Ja' ? (
                <Fragment>
                  <li>
                    Akademischer Titel: {limitedCompanyAcademicTitle || '—'}
                  </li>
                  <li>Vorname: {firstname || '—'}</li>
                  <li>Nachname: {lastname || '—'}</li>
                  <li>
                    Geburtsdatum:{' '}
                    {`${limitedCompanyBirthday}.${limitedCompanyBirthmonth}.${limitedCompanyBirthyear}` ||
                      '—'}
                  </li>
                  <li>Konfession: {limitedCompanyDenomination || '—'}</li>
                  <li>Beteiligung am Stammkapital: {shareCapital || '—'}%</li>
                  <li>
                    Persönliche Steuer-ID: {limitedCompanyTaxIdNumber || '—'}
                  </li>
                  <li>
                    Straße, Hausnummer: {limitedCompanyStreet || '—'}{' '}
                    {limitedCompanyHouseNumber || '—'}
                  </li>
                  <li>
                    Postleitzahl, Ort: {limitedCompanyZipCode || '—'}{' '}
                    {limitedCompanyCity || '—'}
                  </li>
                  <li>Telefonnummer: {limitedCompanyPhoneNumber || '—'}</li>
                  <li>E-Mail: {email || '—'}</li>
                </Fragment>
              ) : (
                <Fragment>
                  <li>Name des Unternehmens: {companyName || '—'}</li>
                  <li>
                    Höhe des gezeichneten Kapitals:{' '}
                    {amountOfSubscribedCapital || '—'}
                  </li>
                  <li>
                    Straße, Hausnummer:{' '}
                    {`${limitedCompanyStreet} ${limitedCompanyHouseNumber}` ||
                      '—'}
                  </li>
                  <li>
                    Postleitzahl, Ort:{' '}
                    {`${limitedCompanyZipCode} ${limitedCompanyCity}` || '—'}
                  </li>
                  <li>Telefonnummer: {limitedCompanyPhoneNumber || '—'}</li>
                  <li>E-Mail: {email || '—'}</li>
                </Fragment>
              )}
            </ul>

            {naturalPerson === 'Ja' ? (
              <ul>
                <li>
                  <b>Identifikation des Gesellschafters</b>
                </li>
                <li>Identifizierungsart: {typeOfIdentification || '—'}</li>
                <li>Geburtsort: {placeOfBirth || '—'}</li>
                <li>Staatsangehörigkeit: {nationality || '—'}</li>
                <li>Ausweisnummer: {identityCardNumber || '—'}</li>
                <li>Ausstellende Behörde: {issuingAuthority || '—'}</li>
                <li>
                  Ausgestellt am:{' '}
                  {`${issuedDay}.${issuedMonth}.${issuedYear}` || '—'}
                </li>
                <li>
                  Gültig bis: {`${validDay}.${validMonth}.${validYear}` || '—'}
                </li>
                <li>Politisch exponierte Person: {pep || '—'}</li>
              </ul>
            ) : null}

            <ul>
              <li>
                <b>Weitere Gesellschafter</b>
              </li>
              {additionalShareHolder === 'Ja' ? (
                <>
                  {is_companion_a_natural_person === 'Ja' ? (
                    <Fragment>
                      <li>
                        Akademischer Titel: {companionAcademicTitle || '—'}
                      </li>
                      <li>Vorname: {companionFirstname || '—'}</li>
                      <li>Nachname: {companionLastname || '—'}</li>
                      <li>
                        Geburtsdatum:{' '}
                        {`${companionBirthday}.${companionBirthmonth}.${companionBirthyear}` ||
                          '—'}
                      </li>
                      <li>Konfession: {companionDenomination || '—'}</li>
                      <li>
                        Beteiligung am Stammkapital:{' '}
                        {companionShareCapital || '—'}%
                      </li>
                      <li>
                        Persönliche Steuer-ID: {companionTaxIdNumber || '—'}
                      </li>
                      <li>
                        Straße, Hausnummer: {companionStreet || '—'}{' '}
                        {companionHouseNumber || '—'}
                      </li>
                      <li>
                        Postleitzahl, Ort: {companionZipCode || '—'}{' '}
                        {companionCity || '—'}
                      </li>
                      <li>Telefonnummer: {companionPhoneNumber || '—'}</li>
                      <li>E-Mail: {companionEmail || '—'}</li>
                      <li>
                        Identifizierungsart:{' '}
                        {companionTypeOfIdentification || '—'}
                      </li>
                      <li>Geburtsort: {companionPlaceOfBirth || '—'}</li>
                      <li>
                        Staatsangehörigkeit: {companionNationality || '—'}
                      </li>
                      <li>
                        Ausweisnummer: {companionIdentityCardNumber || '—'}
                      </li>
                      <li>
                        Ausstellende Behörde: {companionIssuingAuthority || '—'}
                      </li>
                      <li>
                        Ausgestellt am:{' '}
                        {`${companionIssuedDay}.${companionIssuedMonth}.${companionIssuedYear}` ||
                          '—'}
                      </li>
                      <li>
                        Gültig bis:{' '}
                        {`${companionValidDay}.${companionValidMonth}.${companionValidYear}` ||
                          '—'}
                      </li>
                      <li>
                        Politisch exponierte Person: {companionPep || '—'}
                      </li>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <li>
                        Name des Unternehmens: {companion_company_name || '—'}
                      </li>
                      <li>
                        Beteiligung am Stammkapital:{' '}
                        {companionShareCapital || '—'}%
                      </li>
                      <li>
                        Persönliche Steuer-ID: {companionTaxIdNumber || '—'}
                      </li>
                      <li>
                        Straße, Hausnummer: {companionStreet || '—'}{' '}
                        {companionHouseNumber || '—'}
                      </li>
                      <li>
                        Postleitzahl, Ort: {companionZipCode || '—'}{' '}
                        {companionCity || '—'}
                      </li>
                      <li>Telefonnummer: {companionPhoneNumber || '—'}</li>
                      <li>E-Mail: {companionEmail || '—'}</li>
                    </Fragment>
                  )}
                </>
              ) : (
                <li>
                  Hat die GmbH weitere Gesellschafter:{' '}
                  {additionalShareHolder || '—'}
                </li>
              )}
            </ul>

            <ul>
              <li>
                <b>Angaben zur Geschäftsführung</b>
              </li>
              {(is_companion_a_natural_person === 'Nein' &&
                naturalPerson === 'Nein') ||
              externalManagingDirector === 'Ja' ? (
                <Fragment>
                  {externalManagingDirector === 'Ja' && (
                    <li>
                      Gibt es einen Fremd-Geschäftsführer:{' '}
                      {externalManagingDirector || '—'}
                    </li>
                  )}
                  <li>
                    Name:{' '}
                    {`${externalManagingDirectorFirstname} ${externalManagingDirectorLastname}` ||
                      '—'}
                  </li>
                  <li>
                    Geburtsdatum:{' '}
                    {`${externalManagingDirectorBirthday}.${externalManagingDirectorBirthmonth}.${externalManagingDirectorBirthyear}` ||
                      '—'}
                  </li>
                  <li>
                    Straße, Hausnummer:{' '}
                    {`${externalManagingDirectorStreet} ${externalManagingDirectorHouseNumber}` ||
                      '—'}
                  </li>
                  <li>
                    Postleitzahl, Stadt:{' '}
                    {`${externalManagingDirectorZipCode} ${externalManagingDirectorCity}` ||
                      '—'}
                  </li>
                </Fragment>
              ) : (
                <li>
                  Gibt es einen Fremd-Geschäftsführer:{' '}
                  {externalManagingDirector || '—'}
                </li>
              )}
            </ul>

            <ul>
              <li>
                <b>Vollmacht für das Finanzamt</b>
              </li>
              <li>Vollmacht erteilt: {taxOfficeProxy || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Elektronischer Rechnungsversand</b>
              </li>
              <li>
                {electronicInvoicing
                  ? 'Versand via E-Mail zugestimmt'
                  : 'Versand via E-Mail nicht zugestimmt'}
              </li>
            </ul>
          </div>
        </FormBody>
      )
    }
    if (type === 'civil-law-company') {
      return (
        <FormBody>
          <div className={classes.summaryList}>
            <ul>
              <li>
                <b>Allgemein</b>
              </li>
              <li>Name Ihres Gesprächspartners : {contactPerson}</li>
              <li>Letzter Jahresabschluss: {annualFinancialReport}</li>
            </ul>
            <ul>
              <li>
                <b>Betriebliche Angaben</b>
              </li>
              <li>
                Abweichende Gewinnverteilung?:{' '}
                {differentProfitDistribution || '—'}
              </li>
              <li>
                Gesellschaftsvertrag vorahnden?: {partnershipAgreement || '—'}
              </li>
              <li>Name des Unternehmens: {companyName || '—'}</li>
              <li>Ausgeführte Tätigkeit: {businessPurpose || '—'}</li>
              <li>
                Gegründet am:{' '}
                {`${foundingday}.${foundingmonth}.${foundingyear}` || '—'}
              </li>
              <li>
                Straße, Hausnummer:{' '}
                {`${businessStreet} ${businessHouseNumber}` || '—'}
              </li>
              <li>Postleitzahl: {businessZipCode || '—'}</li>
              <li>Stadt: {businessCity || '—'}</li>
              <li>Telefonnummer: {businessPhoneNumber || '—'}</li>
              <li>E-Mail-Adresse: {businessEmail || '—'}</li>
              <li>Festkapital: {fixedCapital || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Geschäftliche Bankdaten</b>
              </li>
              <li>KontoinhaberIn: {businessBankAccountHolder || '—'}</li>
              <li>Name der Bank: {businessBankName || '—'}</li>
              <li>IBAN: {businessIban || '—'}</li>
              {props.features.sepa ? (
                <li>SEPA-Lastschrift: {allowSepaForBusinessIban || '—'}</li>
              ) : null}
            </ul>

            <ul>
              <li>
                <b>Geschäftliche Finanzamt</b>
              </li>
              <li>Name des Finanzamtes: {businessTaxOffice || '—'}</li>
              {vatNumberAvailable === 'Ja' ? (
                <li>
                  Umsatzsteuer-Identifikationsnummer: {vatRegion}
                  {vatNumber || '—'}
                </li>
              ) : (
                <li>
                  Ist eine Umsatzsteuer-Identifikationsnummer vorhanden?:{' '}
                  {vatNumberAvailable || '—'}
                </li>
              )}
              {businessTaxNumberAvailable === 'Ja' ? (
                <li>Steuernummer: {businessTaxNumber || '—'}</li>
              ) : (
                <li>
                  Ist eine Steuernummer vorhanden?:{' '}
                  {businessTaxNumberAvailable || '—'}
                </li>
              )}
              {tradeRegisterEntry === 'Ja' ? (
                <Fragment>
                  <li>Ort des Registergerichtes: {registryCourt || '—'}</li>
                  <li>Handelsregister-Nummer: {tradeRegisterNumber || '—'}</li>
                </Fragment>
              ) : (
                <li>
                  Handelsregistereintrag vorhanden?: {tradeRegisterEntry || '—'}
                </li>
              )}
            </ul>

            <ul>
              <li>
                <b>Persönliche Angaben (GesellschafterIn 1)</b>
              </li>
              <li>Akademischer Titel: {academicTitle || '—'}</li>
              <li>Vorname: {firstname || '—'}</li>
              <li>Nachname: {lastname || '—'}</li>
              <li>
                Geburtsdatum: {`${birthday}.${birthmonth}.${birthyear}` || '—'}
              </li>
              <li>Konfession: {denomination || '—'}</li>
              <li>Persönliche Steuer-ID: {taxIdNumber || '—'}</li>
              <li>
                Höhe der Einlage laut Vertrag: {capitalContribution || '—'}
              </li>
              <li>
                Straße, Hausnummer: {street || '—'} {houseNumber || '—'}
              </li>
              <li>
                Postleitzahl, Ort: {zipCode || '—'} {city || '—'}
              </li>
              <li>Telefonnummer: {phoneNumber || '—'}</li>
              <li>E-Mail: {email || '—'}</li>
              <li>Gesellschaftsanteile: {capitalContribution || '—'}%</li>
            </ul>

            <ul>
              <li>
                <b>Identifikation (GesellschafterIn 1)</b>
              </li>
              <li>Identifizierungsart: {typeOfIdentification || '—'}</li>
              <li>Geburtsort: {placeOfBirth || '—'}</li>
              <li>Staatsangehörigkeit: {nationality || '—'}</li>
              <li>Ausweisnummer: {identityCardNumber || '—'}</li>
              <li>Ausstellende Behörde: {issuingAuthority || '—'}</li>
              <li>
                Ausgestellt am:{' '}
                {`${issuedDay}.${issuedMonth}.${issuedYear}` || '—'}
              </li>
              <li>
                Gültig bis: {`${validDay}.${validMonth}.${validYear}` || '—'}
              </li>
              <li>Politisch exponierte Person: {pep || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Privates Finanzamt (GesellschafterIn 1)</b>
              </li>
              <li>Name des Finanzamtes: {privateTaxOffice || ''}</li>
              <li>Steuernummer: {privateTaxNumber || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Persönliche Angaben (GesellschafterIn 2)</b>
              </li>
              <li>Akademischer Titel: {limitedCompanyAcademicTitle || '—'}</li>
              <li>Vorname: {limitedCompanyFirstname || '—'}</li>
              <li>Nachname: {limitedCompanyLastname || '—'}</li>
              <li>
                Geburtsdatum:{' '}
                {`${limitedCompanyBirthday}.${limitedCompanyBirthmonth}.${limitedCompanyBirthyear}` ||
                  '—'}
              </li>
              <li>Konfession: {limitedCompanyDenomination || '—'}</li>
              <li>Persönliche Steuer-ID: {limitedCompanyTaxIdNumber || '—'}</li>
              <li>Höhe der Einlage laut Vertrag: {shareCapital || '—'}</li>
              <li>
                Straße, Hausnummer: {limitedCompanyStreet || '—'}{' '}
                {limitedCompanyHouseNumber || '—'}
              </li>
              <li>
                Postleitzahl, Ort: {limitedCompanyZipCode || '—'}{' '}
                {limitedCompanyCity || '—'}
              </li>
              <li>Telefonnummer: {limitedCompanyPhoneNumber || '—'}</li>
              <li>E-Mail: {limitedCompanyEmail || '—'}</li>
              <li>Gesellschaftsanteile: {shareCapital || '—'}%</li>
            </ul>

            <ul>
              <li>
                <b>Identifikation (GesellschafterIn 2)</b>
              </li>
              <li>
                Identifizierungsart: {secondPartnerTypeOfIdentification || '—'}
              </li>
              <li>Geburtsort: {secondPartnerPlaceOfBirth || '—'}</li>
              <li>Staatsangehörigkeit: {secondPartnerNationality || '—'}</li>
              <li>Ausweisnummer: {secondPartnerIdentityCardNumber || '—'}</li>
              <li>
                Ausstellende Behörde: {secondPartnerIssuingAuthority || '—'}
              </li>
              <li>
                Ausgestellt am:{' '}
                {`${secondPartnerIssuedDay}.${secondPartnerIssuedMonth}.${secondPartnerIssuedYear}` ||
                  '—'}
              </li>
              <li>
                Gültig bis:{' '}
                {`${secondPartnerValidDay}.${secondPartnerValidMonth}.${secondPartnerValidYear}` ||
                  '—'}
              </li>
              <li>Politisch exponierte Person: {secondPartnerPep || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Privates Finanzamt (GesellschafterIn 2)</b>
              </li>
              <li>
                Name des Finanzamtes: {limitedCompanyBusinessTaxOffice || ''}
              </li>
              <li>Steuernummer: {limitedCompanyBusinessTaxNumber || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Weitere GesellschafterInnen</b>
              </li>
              {additionalShareholders === 'Ja' ? (
                <>
                  {renderAdditionalShareholders1()}
                  {renderAdditionalShareholders2()}
                  {renderAdditionalShareholders3()}
                  {renderAdditionalShareholders4()}
                  {renderAdditionalShareholders5()}
                </>
              ) : (
                <li>
                  Gibt es weitere GesellschafterInnen?:{' '}
                  {additionalShareholders || '-'}
                </li>
              )}
            </ul>

            <ul>
              <li>
                <b>Vollmacht für das Finanzamt</b>
              </li>
              <li>Vollmacht erteilt: {taxOfficeProxy || '—'}</li>
            </ul>

            <ul>
              <li>
                <b>Elektronischer Rechnungsversand</b>
              </li>
              <li>
                {electronicInvoicing
                  ? 'Versand via E-Mail zugestimmt'
                  : 'Versand via E-Mail nicht zugestimmt'}
              </li>
            </ul>
          </div>
        </FormBody>
      )
    }
  }

  return (
    <FormContainer className={classes.summaryForm} onSubmit={onSubmit}>
      <HelperButton onClick={onHelperButtonClick} />
      <FormHeader headlineText="Zusammenfassung" />
      {renderContent()}
      <FormFooter
        primaryButtonText="Fertigstellen"
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={onBackButtonClick}
      />
    </FormContainer>
  )
}

export default SummaryForm
