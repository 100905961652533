import React, { Fragment } from 'react'
import moment from 'moment'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'

import classes from './StartForm.module.scss'
import FormFooter from '../../../ui-components/forms/FormFooter/FormFooter'
import TextInputField from '../../../Inputs/TextInputField/TextInputField'
import NumberInputField from '../../../Inputs/NumberInputField/NumberInputField'

interface Props {
  values: {
    contactPerson: string
    annualFinancialReport: string
  }
  type:
    | 'private-person'
    | 'single-company'
    | 'limited-company-and-partnership-kg'
    | 'limited-company'
    | 'limited-entrepreneurial-company'
    | 'civil-law-company'
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onNextButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
}

const StartForm = (props: Props) => {
  const { onChange, onNextButtonClick, type } = props

  const getContent = () => {
    if (type === 'private-person') {
      return (
        <Fragment>
          <b>Immer notwendig:</b>
          <ul>
            <li>das für Sie zuständige Finanzamt</li>
            <li>Ihre Steuernummer</li>
            <li>
              Ihre{' '}
              <a
                href="https://praxistipps.focus.de/steuer-id-herausfinden-so-gehts_48062"
                target="_blank"
                rel="noopener noreferrer"
              >
                Steuer-Identifikationsnummer
              </a>
            </li>
            <li>
              Ihre{' '}
              <a
                href="https://www.fastdocs.de/rentenversicherungsnummer-herausfinden.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sozialversicherungsnummer
              </a>{' '}
              bzw.{' '}
              <a
                href="https://www.fastdocs.de/rentenversicherungsnummer-herausfinden.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Rentenversicherungsnummer
              </a>
            </li>
            <li>Ihren Personalausweis oder Reisepass</li>
          </ul>

          <b>Notwendig, wenn Sie verheiratet oder verpartnert sind:</b>
          <ul>
            <li>
              <a
                href="https://praxistipps.focus.de/steuer-id-herausfinden-so-gehts_48062"
                target="_blank"
                rel="noopener noreferrer"
              >
                Steuer-Identifikationsnummer
              </a>{' '}
              des Partners
            </li>
            <li>
              <a
                href="https://www.fastdocs.de/rentenversicherungsnummer-herausfinden.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sozialversicherungsnummer
              </a>{' '}
              bzw.{' '}
              <a
                href="https://www.fastdocs.de/rentenversicherungsnummer-herausfinden.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Rentenversicherungsnummer
              </a>{' '}
              des Partners
            </li>
            <li>Personalausweis oder Reisepass des Partners</li>
          </ul>

          <b>Notwendig, wenn Sie Kinder haben:</b>
          <ul>
            <li>
              <a
                href="https://praxistipps.focus.de/steuer-id-herausfinden-so-gehts_48062"
                target="_blank"
                rel="noopener noreferrer"
              >
                Steuer-Identifikationsnummer
              </a>{' '}
              des Kindes
            </li>
          </ul>
        </Fragment>
      )
    }
    if (type === 'single-company') {
      return (
        <Fragment>
          <b>Immer notwendig:</b>
          <ul>
            <li>das für das Unternehmen zuständige Finanzamt</li>
            <li>die Steuernummer des Unternehmens</li>
            <li>
              die Handelsregister-Nummer, wenn ein Handelsregistereintrag
              besteht
            </li>
            <li>das für Sie als Privatperson zuständige Finanzamt</li>
            <li>Ihre Steuernummer</li>
            <li>
              Ihre{' '}
              <a
                href="https://praxistipps.focus.de/steuer-id-herausfinden-so-gehts_48062"
                target="_blank"
                rel="noopener noreferrer"
              >
                Steuer-Identifikationsnummer
              </a>
            </li>
            <li>
              Ihre{' '}
              <a
                href="https://www.fastdocs.de/rentenversicherungsnummer-herausfinden.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sozialversicherungsnummer
              </a>{' '}
              bzw.{' '}
              <a
                href="https://www.fastdocs.de/rentenversicherungsnummer-herausfinden.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Rentenversicherungsnummer
              </a>
            </li>
            <li>Ihren Personalausweis oder Reisepass</li>
          </ul>

          <b>Notwendig, wenn Sie verheiratet oder verpartnert sind:</b>
          <ul>
            <li>
              <a
                href="https://praxistipps.focus.de/steuer-id-herausfinden-so-gehts_48062"
                target="_blank"
                rel="noopener noreferrer"
              >
                Steuer-Identifikationsnummer
              </a>{' '}
              des Partners
            </li>
            <li>
              <a
                href="https://www.fastdocs.de/rentenversicherungsnummer-herausfinden.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sozialversicherungsnummer
              </a>{' '}
              bzw.{' '}
              <a
                href="https://www.fastdocs.de/rentenversicherungsnummer-herausfinden.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Rentenversicherungsnummer
              </a>{' '}
              des Partners
            </li>
            <li>Personalausweis oder Reisepass des Partners</li>
          </ul>

          <b>Notwendig, wenn Sie Kinder haben:</b>
          <ul>
            <li>
              <a
                href="https://praxistipps.focus.de/steuer-id-herausfinden-so-gehts_48062"
                target="_blank"
                rel="noopener noreferrer"
              >
                Steuer-Identifikationsnummer
              </a>{' '}
              des Kindes
            </li>
          </ul>
        </Fragment>
      )
    }
    if (type === 'limited-company-and-partnership-kg') {
      return (
        <Fragment>
          <b>Immer notwendig:</b>
          <ul>
            <li>das für das Unternehmen zuständige Finanzamt</li>
            <li>die Steuernummer des Unternehmens</li>
            <li>die Handelsregisternummer der GmbH</li>
            <li>die Handelsregisternummer der KG</li>
            <li>
              das für die Gesellschafter zuständige Finanzamt (privates
              Finanzamt)
            </li>
            <li>die Steuernummer der Gesellschafter</li>
            <li>
              die{' '}
              <a
                href="https://praxistipps.focus.de/steuer-id-herausfinden-so-gehts_48062"
                target="_blank"
                rel="noopener noreferrer"
              >
                Steuer-Identifikationsnummer
              </a>{' '}
              der Gesellschafter
            </li>
            <li>der Personalsausweis oder Reisepass der Gesellschafter</li>
          </ul>
        </Fragment>
      )
    }
    if (type === 'limited-company') {
      return (
        <Fragment>
          <b>Immer notwendig:</b>
          <ul>
            <li>das für das Unternehmen zuständige Finanzamt</li>
            <li>die Steuernummer des Unternehmens</li>
            <li>die Handelsregisternummer der GmbH</li>
            <li>
              das für die Gesellschafter zuständige Finanzamt (privates
              Finanzamt)
            </li>
            <li>die Steuernummer der Gesellschafter</li>
            <li>
              die{' '}
              <a
                href="https://praxistipps.focus.de/steuer-id-herausfinden-so-gehts_48062"
                target="_blank"
                rel="noopener noreferrer"
              >
                Steuer-Identifikationsnummer
              </a>{' '}
              der Gesellschafter
            </li>
            <li>der Personalsausweis oder Reisepass der Gesellschafter</li>
          </ul>
        </Fragment>
      )
    }
    if (type === 'limited-entrepreneurial-company') {
      return (
        <Fragment>
          <b>Immer notwendig:</b>
          <ul>
            <li>das für das Unternehmen zuständige Finanzamt</li>
            <li>die Steuernummer des Unternehmens</li>
            <li>die Handelsregisternummer der UG (Unternehmergesellschaft)</li>
            <li>
              das für die Gesellschafter zuständige Finanzamt (privates
              Finanzamt)
            </li>
            <li>die Steuernummer der Gesellschafter</li>
            <li>
              die{' '}
              <a
                href="https://praxistipps.focus.de/steuer-id-herausfinden-so-gehts_48062"
                target="_blank"
                rel="noopener noreferrer"
              >
                Steuer-Identifikationsnummer
              </a>{' '}
              der Gesellschafter
            </li>
            <li>der Personalsausweis oder Reisepass der Gesellschafter</li>
          </ul>
        </Fragment>
      )
    }
    if (type === 'civil-law-company') {
      return (
        <Fragment>
          <b>Immer notwendig:</b>
          <ul>
            <li>das für das Unternehmen zuständige Finanzamt</li>
            <li>die Steuernummer des Unternehmens</li>
            <li>die Handelsregisternummer</li>
            <li>das für die Gesellschafter zuständige Finanzamt</li>
            <li>die Steuernummer der Gesellschafter</li>
            <li>
              die{' '}
              <a
                href="https://praxistipps.focus.de/steuer-id-herausfinden-so-gehts_48062"
                target="_blank"
                rel="noopener noreferrer"
              >
                Steuer-Identifikationsnummer
              </a>{' '}
              der Gesellschafter
            </li>
            <li>der Personalsausweis oder Reisepass der Gesellschafter</li>
          </ul>
        </Fragment>
      )
    }
  }

  const getLabelDescription = () => {
    if (type === 'private-person') {
      return 'Für welches Jahr wurde die letzte Einkommensteuererklärung erstellt?'
    }
    return 'Für welches Wirtschaftsjahr wurde der letzte Jahresabschluss erstellt?'
  }

  return (
    <FormContainer className={classes.startForm} onSubmit={onNextButtonClick}>
      <FormHeader headlineText="Für einen reibungslosen Ablauf empfehlen wir folgende Infos bereitzuhalten:" />
      <FormBody>
        {getContent()}
        <hr />
        <TextInputField
          label="Name Ihres Gesprächspartners aus dem Erstgespräch"
          name="contactPerson"
          onChange={onChange}
          required
          defaultValue={props.values.contactPerson}
          placeholder="AnsprechpartnerIn"
        />
        <NumberInputField
          label={getLabelDescription()}
          name="annualFinancialReport"
          onChange={onChange}
          defaultValue={props.values.annualFinancialReport}
          placeholder={`${moment().year() - 1}`}
          max={moment().year()}
          min={moment().year() - 10}
        />
      </FormBody>
      <FormFooter primaryButtonText={'Starten'} />
    </FormContainer>
  )
}

export default StartForm
