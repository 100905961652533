import React from 'react'
import Iban from 'iban'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import TextInputField from '../../Inputs/TextInputField/TextInputField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'

import './PrivateBankInformationForm.scss'
import PrependNumberInput from '../../ui-components/inputs/PrependNumberInput/PrependNumberInput'
import Label from '../../ui-components/labels/Label/Label'
import RadioInputField from '../../Inputs/RadioInputField/RadioInputField'
import { STAFF_FORMS } from '../../../constants/Formulars'

const PrivateBankInformationForm = (props) => {
  const validateIban = (iban) => {
    if (Iban.isValid(iban)) {
      return true
    }
    return false
  }

  const validatePrivateIban = () => {
    if (props.iban.length <= 0) {
      return 'IBAN Nummer'
    }
    if (Iban.isValid(props.iban) !== true) {
      return <span className="error">Dies ist noch keine gültige IBAN</span>
    } else {
      return 'IBAN Nummer'
    }
  }

  const validateInsuranceIban = () => {
    if (props.capitalAccumulationIban.length <= 0) {
      return 'IBAN Nummer'
    }
    if (Iban.isValid(props.capitalAccumulationIban) !== true) {
      return <span className="error">Dies ist noch keine gültige IBAN</span>
    }
    if (props.capitalAccumulationIban === props.iban) {
      return (
        <span className="error">
          Die IBAN kann nicht die gleiche wie Deine Private sein.
        </span>
      )
    }
    return 'IBAN Nummer'
  }

  const disableButton = (e) => {
    if (props.iban.length !== 0 && !validateIban(props.iban)) {
      e.preventDefault()
    }
    if (
      props.capitalAccumulationIban.length !== 0 &&
      !validateIban(props.capitalAccumulationIban)
    ) {
      e.preventDefault()
    }
    if (
      props.iban.length !== 0 &&
      props.capitalAccumulationIban.length !== 0 &&
      props.iban === props.capitalAccumulationIban
    ) {
      e.preventDefault()
    }
  }

  const fieldIsRequired = () => {
    if (props.formularName === STAFF_FORMS.APPRENTICESHIP) {
      return false
    }
    return true
  }

  const renderFormBodyContent = () => {
    if (
      props.payingOption !== 'Überweisung' &&
      props.capitalAccumulation === 'Nein'
    ) {
      return (
        <FormBody>
          <p>
            Da Dein Arbeitgeber angegeben hat, dass Du Bar ausgezahlt wirst,
            brauchen wir an dieser Stelle keine weiteren Informationen von dir.
          </p>
        </FormBody>
      )
    }
    if (
      props.payingOption !== 'Überweisung' &&
      props.capitalAccumulation === 'Ja'
    ) {
      return (
        <FormBody>
          <RadioInputField
            radioOptions={['Nein', 'Ja']}
            defaultChecked={
              props.capitalAccumulationQuestion === 'Nein' ? 0 : 1
            }
            label="Hast du einen Vertrag über Vermögenswirksame Leistungen, die von deinem Arbeitgeber überwiesen werden sollen?"
            name="capitalAccumulationQuestion"
            onChange={props.onChange}
          />
          {props.capitalAccumulationQuestion !== 'Nein' ? (
            <React.Fragment>
              <TextInputField
                label="Name der Bank/Versicherung/Bausparkasse etc."
                placeholder="Allianz Versicherung"
                name="capitalAccumulationBankAccountHolder"
                onChange={props.onChange}
                defaultValue={props.capitalAccumulationBankAccountHolder}
                required={fieldIsRequired()}
              />
              <TextInputField
                label={
                  props.iban.length <= 0
                    ? 'IBAN Nummer'
                    : validateInsuranceIban()
                }
                placeholder="DE19123412341234123412"
                onChange={props.onChange}
                defaultValue={props.capitalAccumulationIban}
                name="capitalAccumulationIban"
                required={fieldIsRequired()}
              />
              <TextInputField
                label="Vertragsnummer"
                placeholder="9499728290940"
                onChange={props.onChange}
                defaultValue={props.contractNumber}
                name="contractNumber"
                required={fieldIsRequired()}
              />
              <Label>Monatlicher Spar-Betrag</Label>
              <PrependNumberInput
                name="capitalAccumulationValue"
                placeholder="40,00"
                onChange={props.onChange}
                prependText="€"
                defaultValue={props.capitalAccumulationValue}
                min={1}
                required={fieldIsRequired()}
              />
            </React.Fragment>
          ) : null}
        </FormBody>
      )
    }
    return (
      <FormBody>
        <TextInputField
          label="Kontoinhaber/in"
          placeholder="Marie Muster"
          name="bankAccountHolder"
          onChange={props.onChange}
          defaultValue={props.bankAccountHolder}
          required
        />
        <TextInputField
          label={props.iban.length <= 0 ? 'IBAN Nummer' : validatePrivateIban()}
          placeholder="DE19123412341234123412"
          onChange={props.onChange}
          defaultValue={props.iban}
          name="iban"
          required
          autoComplete="off"
        />
        {props.capitalAccumulation === 'Ja' ? (
          <React.Fragment>
            <RadioInputField
              radioOptions={['Nein', 'Ja']}
              defaultChecked={
                props.capitalAccumulationQuestion === 'Nein' ? 0 : 1
              }
              label="Hast du einen Vertrag über Vermögenswirksame Leistungen, die von deinem Arbeitgeber überwiesen werden sollen?"
              name="capitalAccumulationQuestion"
              onChange={props.onChange}
            />
            {props.capitalAccumulationQuestion !== 'Nein' ? (
              <React.Fragment>
                <TextInputField
                  label="Name der Bank/Versicherung/Bausparkasse etc."
                  placeholder="Allianz Versicherung"
                  name="capitalAccumulationBankAccountHolder"
                  onChange={props.onChange}
                  defaultValue={props.capitalAccumulationBankAccountHolder}
                  required={fieldIsRequired()}
                />
                <TextInputField
                  label={
                    props.iban.length <= 0
                      ? 'IBAN Nummer'
                      : validateInsuranceIban()
                  }
                  placeholder="DE19123412341234123412"
                  onChange={props.onChange}
                  defaultValue={props.capitalAccumulationIban}
                  name="capitalAccumulationIban"
                  required={fieldIsRequired()}
                />
                <TextInputField
                  label="Vertragsnummer"
                  placeholder="9499728290940"
                  onChange={props.onChange}
                  defaultValue={props.contractNumber}
                  name="contractNumber"
                  required={fieldIsRequired()}
                />
                <Label>Monatlicher Spar-Betrag</Label>
                <PrependNumberInput
                  name="capitalAccumulationValue"
                  placeholder="40,00"
                  onChange={props.onChange}
                  prependText="€"
                  defaultValue={props.capitalAccumulationValue}
                  min={1}
                  required={fieldIsRequired()}
                />
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}
      </FormBody>
    )
  }

  return (
    <FormContainer
      className={'bank-informations-form'}
      onSubmit={props.onSubmit}
    >
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader
        headlineText={
          props.capitalAccumulation === 'Ja'
            ? 'Deine Bankverbindungen'
            : 'Deine Bankverbindung'
        }
      />
      {renderFormBodyContent()}
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
        onClick={disableButton}
      />
    </FormContainer>
  )
}

export default PrivateBankInformationForm
