import React, { useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import { getTaxConsultant } from '../../utility/utility'

import './AccountSettingsView.scss'
import SingleInputFormField from '../../../components/Inputs/SingleInputFormField/SingleInputFormField'

interface Props {
  data: {
    name: string
    email: string
    fastdocsClientEmail: string
    contracts: {
      client: { contract: boolean }
    }
  }
}

const AccountSettingsView = (props: Props) => {
  const [name, setName] = useState(props.data.name)
  const [saveNameButton, setSaveNameButton] = useState('initial')

  const [email, setEmail] = useState(props.data.email)
  const [saveEmailButton, setSaveEmailButton] = useState('initial')

  const [fastdocsClientEmail, setFastdocsClientEmail] = useState(
    props.data.fastdocsClientEmail
  )
  const [saveFastdocsClientEmailButton, setSaveFastdocsClientEmailButton] =
    useState('initial')

  const saveClientName = () => {
    if (name) {
      setSaveNameButton('active')

      setTimeout(() => {
        firebase
          .database()
          .ref(`taxConsultant/${getTaxConsultant()}`)
          .update({ name: name })
          .then(() => {
            setSaveNameButton('finished')

            setTimeout(() => {
              setSaveNameButton('initial')
            }, 1000)
          })
      }, 1000)
    }
  }

  const saveStaffEmail = () => {
    if (
      email &&
      RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(email)
    ) {
      setSaveEmailButton('active')

      setTimeout(() => {
        firebase
          .database()
          .ref(`taxConsultant/${getTaxConsultant()}`)
          .update({ email: email })
          .then(() => {
            setSaveEmailButton('finished')

            setTimeout(() => {
              setSaveEmailButton('initial')
            }, 1000)
          })
      }, 1000)
    }
  }

  const saveClientEmail = () => {
    if (
      fastdocsClientEmail &&
      RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(fastdocsClientEmail)
    ) {
      setSaveFastdocsClientEmailButton('active')

      setTimeout(() => {
        firebase
          .database()
          .ref(`taxConsultant/${getTaxConsultant()}`)
          .update({ fastdocsClientEmail: fastdocsClientEmail })
          .then(() => {
            setSaveFastdocsClientEmailButton('finished')

            setTimeout(() => {
              setSaveFastdocsClientEmailButton('initial')
            }, 1000)
          })
      }, 1000)
    }
  }

  return (
    <div className="settings-details">
      <header>
        <h4>Account</h4>
      </header>

      <main>
        <section>
          <div className="information">
            <label>Portal-Domain</label>
            <p>
              <a href={`https://${window.location.hostname}`}>
                https://{window.location.hostname}
              </a>
            </p>
            <small>
              Die Domain kann nur mit Hilfe des Supports geändert werden.
            </small>
          </div>
        </section>

        <section>
          <SingleInputFormField
            required
            label="Anzeigename"
            type="text"
            name="name"
            defaultValue={props.data.name}
            buttonState={saveNameButton}
            caption="Dieser Name erscheint in den automatisierten E-Mails und in den
            Dokumenten, die wir an Sie und Ihre Mandaten versenden."
            onButtonClick={() => saveClientName()}
            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
          />
        </section>

        <section>
          <SingleInputFormField
            required
            label="Standard / Fastdocs Staff E-Mail-Adresse"
            type="email"
            name="email"
            defaultValue={props.data.email}
            buttonState={saveEmailButton}
            caption="Alle automatisierten E-Mails für Personaldaten werden standardmäßig
            an diese Adresse versendet. Zudem wird diese Adresse für den Login
            zum Dashboard verwendet."
            onButtonClick={() => saveStaffEmail()}
            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
        </section>

        {props.data.contracts.client.contract ? (
          <>
            <section>
              <SingleInputFormField
                required
                label="Fastdocs Client E-Mail-Adresse"
                type="email"
                name="fastdocsClientEmail"
                defaultValue={props.data.fastdocsClientEmail}
                buttonState={saveFastdocsClientEmailButton}
                caption="Alle automatisierten E-Mails für neue Mandanten werden
            standardmäßig an diese Adresse versendet."
                onButtonClick={() => saveClientEmail()}
                onInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFastdocsClientEmail(e.target.value)
                }
              />
            </section>
          </>
        ) : null}
      </main>
    </div>
  )
}

export default AccountSettingsView
