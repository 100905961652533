import React from 'react'
import PropTypes from 'prop-types'
import './PrependNumberInput.scss'

const PrependNumberInput = (props) => (
  <div className="prepend-number-input">
    <div className="prepend-field">{props.prependText}</div>
    <input
      type={'number'}
      name={props.name}
      placeholder={props.placeholder}
      required={props.required}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      disabled={props.disabled}
      maxLength={props.maxLength}
      autoFocus={props.autoFocus}
      title={props.title}
      max={props.max}
      min={props.min}
      step={props.step}
      pattern={props.pattern}
    />
  </div>
)

PrependNumberInput.propTypes = {
  /** defaultValue of the input field */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Sets a field into a disabled state */
  disabled: PropTypes.bool,
  /** Sets the name of the input */
  name: PropTypes.string.isRequired,
  /** Listen for onChange events */
  onChange: PropTypes.func,
  /** Sets the placeholder of the input */
  placeholder: PropTypes.string.isRequired,
  /** If a TextInput needs to be filled for further actions, the required statement needs to be set to true */
  required: PropTypes.bool,
  /** Specifies the size (in characters) for the input field */
  maxLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  /** Specifies that the input field should automatically get focus when the page loads */
  autoFocus: PropTypes.bool,
  /** Specifies a regular expression that the <input> element's value is checked against */
  pattern: PropTypes.string,
  /** Sets a title used as description */
  title: PropTypes.string,
  prependText: PropTypes.string.isRequired,
}

export default PrependNumberInput
