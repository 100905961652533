import React from 'react'

import Accordion from '../../../../ui-components/accordions/Accordion/Accordion'
import helpOverContentForClients from './helpOverContentForClients.json'
import HelpOverBase from '../../HelpOverBase/HelpOverBase'

const HelpOverForLimitedCompanyAndLimitedPartnershipForm = (props: any) => {
  switch (props) {
    case 1:
      return helpOverContentForClients[0].betrieblicheAngabenKG.map(
        (accordion: any, i: number) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 2:
      return helpOverContentForClients[0].betrieblicheBankdatenKG.map(
        (accordion: any, i: number) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 3:
      return helpOverContentForClients[0].betrieblicheFinanzamtKG.map(
        (accordion: any, i: number) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 4:
      return helpOverContentForClients[0].persoehnlicheAngabenKG.map(
        (accordion: any, i: number) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 5:
      return helpOverContentForClients[0].identifikationKG.map(
        (accordion: any, i: number) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 6:
      return helpOverContentForClients[0].privatesFinanzamtKG.map(
        (accordion: any, i: number) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 7:
      return helpOverContentForClients[0].betrieblicheAngabenGmbH.map(
        (accordion: any, i: number) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 8:
      return helpOverContentForClients[0].betrieblicheBankdatenGmbH.map(
        (accordion: any, i: number) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 9:
      return helpOverContentForClients[0].betrieblicheFinanzamtGmbH.map(
        (accordion: any, i: number) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 10:
      return helpOverContentForClients[0].persoehnlicheAngabenGmbH.map(
        (accordion: any, i: number) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    default:
      return (
        <div className="empty-hint">
          <h5>Hier gibt es keine Hilfen.</h5>
        </div>
      )
  }
}

const HelpOverForMinijobberForm = (props: {
  className: string
  onCloseButtonClick: () => void
  formId: string
  formStep: number
}) => {
  return (
    <HelpOverBase
      className={props.className}
      onCloseButtonClick={props.onCloseButtonClick}
      formId={props.formId}
    >
      {HelpOverForLimitedCompanyAndLimitedPartnershipForm(props.formStep)}
    </HelpOverBase>
  )
}

export default HelpOverForMinijobberForm
