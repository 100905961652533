import moment from 'moment'
import checkLocalStorageFor from 'views/utility/checkLocalStorageFor'

export default function initialFormValues(formularName: string) {
  return {
    date: moment().format('DD.MM.YYYY'),
    totalWeeklyWorkingTimeLimitForStudents: checkLocalStorageFor(
      formularName,
      'totalWeeklyWorkingTimeLimitForStudents',
      'Nein'
    ),
    operationTimeForStudents: checkLocalStorageFor(
      formularName,
      'operationTimeForStudents',
      'Nein'
    ),
    operationTimeIsLimitedToSemesterBreaks: checkLocalStorageFor(
      formularName,
      'operationTimeIsLimitedToSemesterBreaks',
      'Nein'
    ),
    operationTimeIsExercisedForMoreThan26WeeksPerYear: checkLocalStorageFor(
      formularName,
      'operationTimeIsExercisedForMoreThan26WeeksPerYear',
      'Nein'
    ),
    firstname: checkLocalStorageFor(formularName, 'firstname', ''),
    lastname: checkLocalStorageFor(formularName, 'lastname', ''),
    street: checkLocalStorageFor(formularName, 'street', ''),
    houseNumber: checkLocalStorageFor(formularName, 'houseNumber', ''),
    zipCode: checkLocalStorageFor(formularName, 'zipCode', ''),
    city: checkLocalStorageFor(formularName, 'city', ''),
    birthday: checkLocalStorageFor(formularName, 'birthday', ''),
    birthmonth: checkLocalStorageFor(formularName, 'birthmonth', ''),
    birthyear: checkLocalStorageFor(formularName, 'birthyear', ''),
    sex: checkLocalStorageFor(formularName, 'sex', 'Weiblich'),
    mariatialStatus: checkLocalStorageFor(formularName, 'mariatialStatus', ''),
    singleParent: checkLocalStorageFor(formularName, 'singleParent', 'Nein'),
    matriculation: checkLocalStorageFor(formularName, 'matriculation', 'Nein'),
    denomination: checkLocalStorageFor(formularName, 'denomination', ''),
    taxIdNumber: checkLocalStorageFor(formularName, 'taxIdNumber', ''),
    parentsProperty: checkLocalStorageFor(
      formularName,
      'parentsProperty',
      'Nein'
    ),
    amountOfChildren: checkLocalStorageFor(
      formularName,
      'amountOfChildren',
      ''
    ),
    taxClass: checkLocalStorageFor(formularName, 'taxClass', ''),
    activityStatus: checkLocalStorageFor(
      formularName,
      'activityStatus',
      'Hauptbeschäftigung'
    ),
    graduation: checkLocalStorageFor(
      formularName,
      'graduation',
      'ohne Schulabschluss'
    ),
    education: checkLocalStorageFor(
      formularName,
      'education',
      'ohne beruflichen Ausbildungabschluss'
    ),
    beginningStatus: checkLocalStorageFor(formularName, 'beginningStatus', ''),
    insuranceType: checkLocalStorageFor(
      formularName,
      'insuranceType',
      'Gesetzlich'
    ),
    insuranceCompanyName: checkLocalStorageFor(
      formularName,
      'insuranceCompanyName',
      ''
    ),
    insuranceNumber: checkLocalStorageFor(
      formularName,
      'insuranceNumber',
      true
    ),
    socialSecurityNumber: checkLocalStorageFor(
      formularName,
      'socialSecurityNumber',
      ''
    ),
    birthName: checkLocalStorageFor(formularName, 'birthName', ''),
    placeOfBirth: checkLocalStorageFor(formularName, 'placeOfBirth', ''),
    birthCountry: checkLocalStorageFor(formularName, 'birthCountry', ''),
    nationality: checkLocalStorageFor(formularName, 'nationality', ''),
    bankAccountHolder: checkLocalStorageFor(
      formularName,
      'bankAccountHolder',
      ''
    ),
    iban: checkLocalStorageFor(formularName, 'iban', ''),
    capitalAccumulationQuestion: checkLocalStorageFor(
      formularName,
      'capitalAccumulationQuestion',
      'Nein'
    ),
    capitalAccumulationBankAccountHolder: checkLocalStorageFor(
      formularName,
      'capitalAccumulationBankAccountHolder',
      ''
    ),
    capitalAccumulationIban: checkLocalStorageFor(
      formularName,
      'capitalAccumulationIban',
      ''
    ),
    contractNumber: checkLocalStorageFor(formularName, 'contractNumber', ''),
    capitalAccumulationValue: checkLocalStorageFor(
      formularName,
      'capitalAccumulationValue',
      ''
    ),
    furtherEmployments: checkLocalStorageFor(
      formularName,
      'furtherEmployments',
      false
    ),
    typeOfEmployment: checkLocalStorageFor(
      formularName,
      'typeOfEmployment',
      'Minijob bis 450€'
    ),
    furtherEmploymentCompanyName: checkLocalStorageFor(
      formularName,
      'furtherEmploymentCompanyName',
      ''
    ),
    payingPensionInsurance: checkLocalStorageFor(
      formularName,
      'payingPensionInsurance',
      'Nein'
    ),
    furtherMinijobEntryDay: checkLocalStorageFor(
      formularName,
      'furtherMinijobEntryDay',
      ''
    ),
    furtherMinijobEntryMonth: checkLocalStorageFor(
      formularName,
      'furtherMinijobEntryMonth',
      ''
    ),
    furtherMinijobEntryYear: checkLocalStorageFor(
      formularName,
      'furtherMinijobEntryYear',
      ''
    ),
    additionalMiniJob: checkLocalStorageFor(
      formularName,
      'additionalMiniJob',
      'Nein'
    ),
    additionalEmployement: checkLocalStorageFor(
      formularName,
      'additionalEmployement',
      'Nein'
    ),
    furtherEmploymentsTotalSallary: checkLocalStorageFor(
      formularName,
      'furtherEmploymentsTotalSallary',
      'Nein'
    ),
    bea: checkLocalStorageFor(formularName, 'bea', false),
    pensionInsuranceRequest: checkLocalStorageFor(
      formularName,
      'pensionInsuranceRequest',
      'Nein'
    ),
    request: checkLocalStorageFor(formularName, 'request', false),
    requestDeclaration: checkLocalStorageFor(
      formularName,
      'requestDeclaration',
      false
    ),
  }
}
