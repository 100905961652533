import React from 'react'

import Accordion from '../../../../ui-components/accordions/Accordion/Accordion'
import helpOverContentForClients from './helpOverContentForClients.json'
import HelpOverBase from '../../HelpOverBase/HelpOverBase'

const HelpOverForImmediateReportForm = (props) => {
  if (window.location.pathname.includes('client')) {
    switch (props) {
      case 0:
        return helpOverContentForClients[0].unternehmensdaten.map(
          (accordion, i) => (
            <Accordion
              formStep={props}
              headline={accordion.headline}
              url={accordion.url}
              urlText={accordion.urlText}
              text={accordion.text}
              example={accordion.example}
              key={i}
            />
          )
        )
      case 1:
        return helpOverContentForClients[0].mitarbeiterInformationen.map(
          (accordion, i) => (
            <Accordion
              formStep={props}
              headline={accordion.headline}
              url={accordion.url}
              urlText={accordion.urlText}
              text={accordion.text}
              example={accordion.example}
              key={i}
            />
          )
        )
      case 2:
        return helpOverContentForClients[0].angabenZurKrankenversicherung.map(
          (accordion, i) => (
            <Accordion
              formStep={props}
              headline={accordion.headline}
              url={accordion.url}
              urlText={accordion.urlText}
              text={accordion.text}
              example={accordion.example}
              key={i}
            />
          )
        )
      case 3:
        return helpOverContentForClients[0].weitereAngabenZurKrankenversicherung.map(
          (accordion, i) => (
            <Accordion
              formStep={props}
              headline={accordion.headline}
              url={accordion.url}
              urlText={accordion.urlText}
              text={accordion.text}
              example={accordion.example}
              key={i}
            />
          )
        )
      default:
        return (
          <div className="empty-hint">
            <h5>
              <span role="img" aria-label="Illustration">
                🤘
              </span>
              <br />
              Hier brauchst Du keine Hilfe
            </h5>
          </div>
        )
    }
  }
}

class HelpOverForMinijobberForm extends React.Component {
  render() {
    return (
      <HelpOverBase
        className={this.props.className}
        onCloseButtonClick={this.props.onCloseButtonClick}
        formId={this.props.formId}
      >
        {HelpOverForImmediateReportForm(this.props.formStep)}
      </HelpOverBase>
    )
  }
}

export default HelpOverForMinijobberForm
