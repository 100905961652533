import React from 'react'
import { getTaxConsultant } from '../../views/utility/utility'
import classes from './AutofillButton.module.scss'

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const AutofillButton = (props: Props) => {
  if (
    process.env.NODE_ENV === 'development' ||
    getTaxConsultant() === 'dev-test-account' ||
    getTaxConsultant() === 'test-account'
  ) {
    return (
      <React.Fragment>
        <button className={classes.autofillButton} onClick={props.onClick}>
          Formular ausfüllen
        </button>
      </React.Fragment>
    )
  }
  return null
}

export default AutofillButton
