import React from 'react'
import PropTypes from 'prop-types'
import './FormHeader.scss'

const FormHeader = (props) => (
  <header className={'form-header'}>
    <h1 className={'h4'}>{props.headlineText}</h1>
    {props.sublineText ? <p>{props.sublineText}</p> : null}
    {props.children}
  </header>
)

FormHeader.propTypes = {
  headlineText: PropTypes.string.isRequired,
  sublineText: PropTypes.string,
  children: PropTypes.node,
}

export default FormHeader
