import React from 'react'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import EmailInputField from '../../Inputs/EmailInputField/EmailInputField'
import TextareaField from '../../Inputs/TextareaField/TextareaField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'

import './EmailForm.scss'
import { STAFF_FORMS } from '../../../constants/Formulars'

const EmailForm = (props) => {
  const renderFormFields = () => {
    if (props.formularName === STAFF_FORMS.IMMEDIATE_REPORT) {
      return (
        <>
          <EmailInputField
            autoFocus
            defaultValue={props.clientEmail}
            label={'Deine E-Mail Adresse als Arbeitgeber'}
            name={'clientEmail'}
            onChange={props.onChange}
            placeholder={'meine-email@adresse.de'}
            required
          />
          <hr />
          <TextareaField
            name="otherInformation"
            defaultValue={props.otherInformation}
            onChange={props.onChange}
            label="Sonstige Informationen für die Steuerberatungskanzlei"
            rows={4}
            placeholder="Weitere Informationen"
          />
        </>
      )
    }
    return (
      <React.Fragment>
        <EmailInputField
          autoFocus
          defaultValue={props.clientEmail}
          label={'Deine E-Mail Adresse als Arbeitgeber'}
          name={'clientEmail'}
          onChange={props.onChange}
          placeholder={'meine-email@adresse.de'}
          required
        />
        <EmailInputField
          defaultValue={props.employeeEmail}
          label={setLabelText()}
          name={'employeeEmail'}
          onChange={props.onChange}
          placeholder={setPlaceholderText()}
          required
        />
        <hr />
        <TextareaField
          name="otherInformation"
          defaultValue={props.otherInformation}
          onChange={props.onChange}
          label="Sonstige Informationen für die Steuerberatungskanzlei"
          rows={4}
          placeholder="Weitere Informationen"
        />
      </React.Fragment>
    )
  }

  const renderHeadText = () => {
    if (props.formularName === STAFF_FORMS.IMMEDIATE_REPORT) {
      return null
    }
    return (
      <React.Fragment>
        <p>
          Um alle weiteren Informationen einsammeln zu können, müssen wir
          Kontakt mit der neuen Arbeitskraft aufnehmen.
        </p>
        <p>
          Sollten die restlichen Daten gemeinsam mit der neuen Arbeitskraft
          hinterlegt werden, dann muss in beiden Feldern dieselbe E-Mail-Adresse
          hinterlegt werden.
        </p>
      </React.Fragment>
    )
  }

  const setLabelText = () => {
    if (props.formularName === STAFF_FORMS.APPRENTICESHIP) {
      return 'E-Mail Adresse des Azubis'
    }
    return 'E-Mail Adresse der neuen Arbeitskraft'
  }

  const setPlaceholderText = () => {
    if (props.formularName === STAFF_FORMS.APPRENTICESHIP) {
      return 'azubi-email@adresse.de'
    }
    return 'mitarbeiter-email@adresse.de'
  }

  return (
    <FormContainer className={'email-form'} onSubmit={props.onSubmit}>
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Kontakt & Sonstiges'}>
        {renderHeadText()}
      </FormHeader>
      <FormBody>{renderFormFields()}</FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
      />
    </FormContainer>
  )
}

export default EmailForm
