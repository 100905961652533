import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './FormHint.scss'

const propTypes = {
  hintHeadline: PropTypes.string,
  hintText: PropTypes.string,
}

class FormHint extends PureComponent {
  render() {
    return (
      <div className="hint">
        {this.props.hintHeadline ? <b>{this.props.hintHeadline}</b> : null}
        {this.props.children ? (
          this.props.children
        ) : (
          <p>{this.props.hintText}</p>
        )}
      </div>
    )
  }
}

FormHint.propTypes = propTypes

export default FormHint
