import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as MinijobIcon } from '../../../static/icons/40x40/minijob-icon-40.svg'
import { ReactComponent as StudentMinijobIcon } from '../../../static/icons/40x40/student-minijob-icon-40.svg'
import { ReactComponent as StudentIcon } from '../../../static/icons/40x40/student-icon-40.svg'
import { ReactComponent as ForeignEmployeeIcon } from '../../../static/icons/40x40/foreign-employee-icon-40.svg'
import { ReactComponent as CarIcon } from '../../../static/icons/40x40/car-icon-40.svg'
import { ReactComponent as EmployeeIcon } from '../../../static/icons/40x40/employee-icon-40.svg'
import { ReactComponent as ApprenticeIcon } from '../../../static/icons/40x40/apprentice-icon-40.svg'
import { ReactComponent as ImmediateReportIcon } from '../../../static/icons/40x40/immediate-report-icon-40.svg'
import { ReactComponent as AddEmployeeIcon } from '../../../static/icons/40x40/add-employee-icon-40.svg'
import { ReactComponent as ClientIcon } from '../../../static/icons/40x40/client-icon-40.svg'
import { ReactComponent as PrivatePersonIcon } from '../../../static/icons/40x40/private-person-icon-40.svg'
import { ReactComponent as IndividualCompanyIcon } from '../../../static/icons/40x40/individual-company-icon-40.svg'
import { ReactComponent as LimitedCompanyIcon } from '../../../static/icons/40x40/limited-company-icon-40.svg'
import { ReactComponent as CivilLawCompanyIcon } from '../../../static/icons/40x40/civil-law-company-icon-40.svg'

import classes from './FormularSelector.module.scss'

const renderLinks = (type: 'start' | 'staff') => {
  if (type === 'start') {
    return (
      <React.Fragment>
        <Link to="/staff" className={classes.formularLink}>
          <div className={classes.formularLinkContent}>
            <AddEmployeeIcon />
            <h4>Personal managen</h4>
            <p>
              Sie sind bereits MandantIn der Kanzlei und möchten neue
              Arbeitskräfte anmelden.
            </p>
          </div>
        </Link>

        <Link to="/client" className={classes.formularLink}>
          <div className={classes.formularLinkContent}>
            <ClientIcon />
            <h4>MandantIn werden</h4>
            <p>
              Sie möchten MandantIn in der Kanzlei werden und möchten nun Ihre
              entsprechenden Stammdaten hinterlegen.
            </p>
          </div>
        </Link>
      </React.Fragment>
    )
  }
  if (type === 'staff') {
    return (
      <>
        <Link to="/minijobber-form/client" className={classes.formularLink}>
          <div className={classes.formularLinkContent}>
            <MinijobIcon />
            <h4>Minijob bis 450€</h4>
            <p>Für Angestellte bis zu einem max. Betrag von 450€.</p>
          </div>
        </Link>

        <Link to="/staff-form/client" className={classes.formularLink}>
          <div className={classes.formularLinkContent}>
            <EmployeeIcon />
            <h4>Beschäftigung über 450€</h4>
            <p>Für Angestellte mit einem Betrag von mehr als 450€.</p>
          </div>
        </Link>

        <Link to="/apprentice-form/client" className={classes.formularLink}>
          <div className={classes.formularLinkContent}>
            <ApprenticeIcon />
            <h4>Ausbildung</h4>
            <p>Für die Einstellung von neuen Auszubildenden.</p>
          </div>
        </Link>

        <Link to="/immediate-report/client" className={classes.formularLink}>
          <div className={classes.formularLinkContent}>
            <ImmediateReportIcon />
            <h4>Sofortmeldung</h4>
            <p>Für Branchen mit Sofortmeldepflicht.</p>
          </div>
        </Link>

        <Link to="/student-minijob/client" className={classes.formularLink}>
          <div className={classes.formularLinkContent}>
            <StudentMinijobIcon />
            <h4>Studenten bis 450€</h4>
            <p>Für Studenten bis zu einem max. Betrag von 450€.</p>
          </div>
        </Link>

        <Link to="/student/client" className={classes.formularLink}>
          <div className={classes.formularLinkContent}>
            <StudentIcon />
            <h4>Werkstudenten</h4>
            <p>Für Werkstudenten mit einem Betrag von mehr als 450€.</p>
          </div>
        </Link>

        <Link to="/foreign-employee/client" className={classes.formularLink}>
          <div className={classes.formularLinkContent}>
            <ForeignEmployeeIcon />
            <h4>Wohnsitz im / Zugezogen aus dem Ausland</h4>
            <p>
              Für Beschäftigte mit Wohnsitz im Ausland oder Zugezogene aus dem
              Ausland und mehr als 450€/Monat.
            </p>
          </div>
        </Link>

        <Link to="/vehicle-form" className={classes.formularLink}>
          <div className={classes.formularLinkContent}>
            <CarIcon />
            <h4>Firmenfahrzeug</h4>
            <p>
              Für die Nutzung eines Firmenfahrzeuges durch ArbeitnehmerInnen
            </p>
          </div>
        </Link>
      </>
    )
  }
  if (type === 'client') {
    return (
      <React.Fragment>
        <Link
          to="client-onboarding/private-person"
          className={classes.formularLink}
        >
          <div className={classes.formularLinkContent}>
            <PrivatePersonIcon />
            <h4>Privatperson</h4>
            <p>
              Sie möchten Ihre Einkommensteuererklärung von unserer Kanzlei
              erstellen lassen.
            </p>
          </div>
        </Link>

        <Link
          to="client-onboarding/single-companies"
          className={classes.formularLink}
        >
          <div className={classes.formularLinkContent}>
            <IndividualCompanyIcon />
            <h4>Einzelunternehmen / FreiberuflerIn</h4>
            <p>
              Sie möchten die laufenden Unterlagen für Ihr Unternehmen von
              unserer Kanzlei erstellen lassen.
            </p>
          </div>
        </Link>

        <Link
          to="client-onboarding/limited-entrepreneurial-company"
          className={classes.formularLink}
        >
          <div className={classes.formularLinkContent}>
            <CivilLawCompanyIcon />
            <h4>UG Unternehmer&shy;gesellschaft (haftungsbeschränkt)</h4>
            <p>
              Sie möchten die laufenden Unterlagen für Ihre UG
              (haftungsbeschränkt) von unserer Kanzlei erstellen lassen.
            </p>
          </div>
        </Link>

        <Link
          to="client-onboarding/limited-company"
          className={classes.formularLink}
        >
          <div className={classes.formularLinkContent}>
            <LimitedCompanyIcon />
            <h4>GmbH</h4>
            <p>
              Sie möchten die laufenden Unterlagen für Ihre GmbH von unserer
              Kanzlei erstellen lassen.
            </p>
          </div>
        </Link>

        <Link
          to="client-onboarding/limited-company-with-limited-partnership"
          className={classes.formularLink}
        >
          <div className={classes.formularLinkContent}>
            <LimitedCompanyIcon />
            <h4>GmbH & Co. KG</h4>
            <p>
              Sie möchten die laufenden Unterlagen für Ihre GmbH & Co. KG von
              unserer Kanzlei erstellen lassen.
            </p>
          </div>
        </Link>

        <Link
          to="client-onboarding/civil-law-company"
          className={classes.formularLink}
        >
          <div className={classes.formularLinkContent}>
            <CivilLawCompanyIcon />
            <h4>GbR</h4>
            <p>
              Sie möchten die laufenden Unterlagen für Ihre GbR von unserer
              Kanzlei erstellen lassen.
            </p>
          </div>
        </Link>
      </React.Fragment>
    )
  }
}

const FormularSelector = (props: { type: any }) => {
  return (
    <section className={classes.formularSelector}>
      {renderLinks(props.type)}
    </section>
  )
}

export default FormularSelector
