import React from 'react'
import PropTypes from 'prop-types'
import './FormBody.scss'

const FormBody = props => <main className={'form-body'}>{props.children}</main>

FormBody.propTypes = {
  children: PropTypes.node,
}

export default FormBody
