import React from 'react'
import FormularSelector from '../../../components/Selects/FormularSelector/FormularSelector'
import SplashStage from '../../../components/Stages/SplashStage/SplashStage'
import ScrollToTop from '../../utility/ScrollToTop'
import classes from './StaffFormsSelectionView.module.scss'

class FormSelectionView extends React.Component {
  componentDidMount = () => {
    document.title = 'Formular auswählen - Fastdocs'
  }

  render() {
    return (
      <>
        <ScrollToTop />
        <div className={classes.formSelection}>
          <SplashStage>
            <h4 className={classes.stageHeadline}>
              Willkommen im Portal für ArbeitgeberInnen.
            </h4>
          </SplashStage>
          <FormularSelector type="staff" />
        </div>
      </>
    )
  }
}

export default FormSelectionView
