import React from 'react'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import SuggestTextInputField from '../../Inputs/SuggestTextInputField/SuggestTextInputField'
import RadioInputField from '../../Inputs/RadioInputField/RadioInputField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'

import InsuranceCompanies from './insuranceCompanies.json'

import './InsuranceDataForm.scss'
import { STAFF_FORMS } from '../../../constants/Formulars'

interface Props {
  insuranceType: String
  insuranceCompanyName: String
  formularName: String
  previousInsuranceCompanyName?: String
  setPreviousInsuranceCompanyName?: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSuggestionClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSecondaryButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const InsuranceDataForm = (props: Props) => {
  const renderLastInsuranceCompanyField = () => {
    if (
      props.formularName === STAFF_FORMS.STANDARD_EMPLOYEE &&
      props.insuranceType === 'Privat'
    ) {
      return (
        <SuggestTextInputField
          label="Name der letzten gesetzlichen Krankenversicherung"
          placeholder="Krankenversicherungs AG"
          name="previousInsuranceCompanyName"
          value={props.previousInsuranceCompanyName}
          onChange={props.onChange}
          required
          data={InsuranceCompanies}
          onSuggestionClick={props.setPreviousInsuranceCompanyName}
          pattern="[\-:./üäö&+()a-zA-Z 24]+"
        />
      )
    }
  }

  return (
    <FormContainer className={'insurance-data-form'} onSubmit={props.onSubmit}>
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Krankenversicherung'} />
      <FormBody>
        <RadioInputField
          label="Art der Krankenversicherung"
          name="insuranceType"
          radioOptions={['Gesetzlich', 'Privat']}
          defaultChecked={props.insuranceType === 'Gesetzlich' ? 0 : 1}
          onChange={props.onChange}
        />
        <SuggestTextInputField
          label="Name der Krankenversicherung"
          placeholder="Krankenversicherungs AG"
          name="insuranceCompanyName"
          value={props.insuranceCompanyName}
          onChange={props.onChange}
          required
          data={InsuranceCompanies}
          onSuggestionClick={props.onSuggestionClick}
          pattern="[\-:./üäö&+()a-zA-Z 24]+"
        />
        {renderLastInsuranceCompanyField()}
      </FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
      />
    </FormContainer>
  )
}

export default InsuranceDataForm
