import React from 'react'
import './StatusLabel.scss'

interface StatusLabelProps {
  label: string
  status: 'open' | 'finished'
}

const StatusLabel = ({
  label,
  status,
}: StatusLabelProps): React.ReactElement => {
  return (
    <div className={`status-label ${status}`}>
      <div className={`indicator ${status}`}>
        {status === 'finished' ? (
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
              d="M10 3L4.5 8.5L2 6"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : (
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
            <path
              d="M9 3L3 9"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 3L9 9"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
      <span>{label}</span>
    </div>
  )
}

export default StatusLabel
