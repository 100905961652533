import React, { Component } from 'react'
import doogieIllustration from '../../../static/illustrations/doogieDoodle.svg'

import './MaintenanceMode.scss'

export class MaintenanceMode extends Component {
  render() {
    return (
      <article className="browser-error-state">
        <figure>
          <img
            src={doogieIllustration}
            width="100%"
            alt="Illustierte Figur, die Papier-Blätter verliert."
          />
        </figure>

        <aside>
          <section>
            <h1 className="h4">Geplante Wartungsarbeiten</h1>
            <h2 className="h5">Wir sind bald wieder zurück.</h2>
            <p>
              Die Wartungsarbeiten werden vorraussichtlich am Sonntag, dem 10.4.
              abgeschlossen sein. Danach werden unsere Services wieder wie
              gewohnt zur Verfügung stehen.
            </p>
            <br />
            <h3 className="h6">Dein Team von Fastdocs</h3>
          </section>
        </aside>
      </article>
    )
  }
}

export default MaintenanceMode
