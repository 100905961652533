import React, { useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import { getTaxConsultant } from '../../utility/utility'
import SingleInputFormField from '../../../components/Inputs/SingleInputFormField/SingleInputFormField'

import './ImportFileSettingsView.scss'

interface Props {
  data: {
    contracts: {
      client: { contract: boolean; contractOption: string }
      staff: { contract: boolean; contractOption: string }
    }
    importVersion: string
    importSystem: string
  }
}

const AccountSettingsView = (props: Props) => {
  const [saveImportVersionButton, setSaveImportVersionButton] =
    useState('initial')
  const [importVersion, setImportVersion] = useState(props.data.importVersion)

  const saveImportVersion = () => {
    if (
      RegExp('[1-9]{2}[.](0|1|2|3|4|5|6|7|8|9){1}').test(importVersion) &&
      importVersion.includes('.') &&
      importVersion.split('.')[1].length === 1
    ) {
      setSaveImportVersionButton('active')

      setTimeout(() => {
        firebase
          .database()
          .ref(`taxConsultant/${getTaxConsultant()}`)
          .update({ importVersion: importVersion })
          .then(() => {
            setSaveImportVersionButton('finished')

            setTimeout(() => {
              setSaveImportVersionButton('initial')
            }, 1000)
          })
      }, 1000)
    }
  }

  const getImportSystemInformation = () => {
    if (props.data.contracts.staff.contractOption === 'pro') {
      return 'DATEV Lodas & DATEV Lohn & Gehalt'
    }
    if (props.data.importSystem === 'lodas') {
      return 'DATEV Lodas'
    }
    if (props.data.importSystem === 'lug') {
      return 'DATEV Lohn & Gehalt'
    }
  }

  const renderLugImportVersionSetup = () => {
    if (
      props.data.importSystem === 'lug' ||
      props.data.contracts.staff.contractOption === 'pro'
    ) {
      return (
        <section>
          <SingleInputFormField
            required
            label="Import Version für Lohn & Gehalt"
            type="text"
            name="importVersion"
            pattern="([1-9]{2})[.]([0-9])"
            defaultValue={props.data.importVersion}
            buttonState={saveImportVersionButton}
            caption="Nach einem Update durch die DATEV kann die neue geforderte
            Import-Version hier aktualisiert werden. Alle neuen Vorgänge werden
            ab dem Zeitpunkt der Anpassung mit der eingetragenen Version
            ausgeliefert."
            onButtonClick={() => saveImportVersion()}
            onInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setImportVersion(e.target.value)
            }
          />
        </section>
      )
    }
  }

  return (
    <div className="import-file-settings-details">
      <header>
        <h4>Infos zum Import System</h4>
      </header>

      <main>
        <section>
          <div className="information">
            <label>Import System</label>
            <p>{getImportSystemInformation()}</p>
            <small>
              Für die angegebenen System können Import-Dateien generiert werden.
            </small>
          </div>
        </section>

        {renderLugImportVersionSetup()}
      </main>
    </div>
  )
}

export default AccountSettingsView
