const clientFormValues = {
  capitalAccumulation: 'Nein',
  clientEmail: '',
  company: '[TEST] Bäckerei Evertzberg',
  contractForm: 'Befristet Teilzeit',
  employedAs: 'Kellner',
  employeeEmail: '',
  employmentLocation: 'Solingen',
  entryday: '01',
  entrymonth: (new Date().getMonth() + 2).toString(),
  entryyear: new Date().getFullYear().toString(),
  firstname: 'Jan',
  lastname: 'Brunner',
  monthlyProportion: '',
  paymentValue: '',
  sallary: 'Stundenlohn',
  sallaryValue: '10.15',
  typeOfEmployee: 'Angestellter',
  otherInformation:
    '- Arbeitet in der Abteilung X\n- Wird nach dem 20. ausgezahlt',
}

export default clientFormValues
