import React from 'react'
import PropTypes from 'prop-types'
import './FormContainer.scss'
import ScrollToTop from '../../../../views/utility/ScrollToTop'

const FormContainer = (props) => {
  const className = 'card form-container'

  const getClassNames = props.className
    ? `${className} ${props.className}`
    : className

  return (
    <>
      <ScrollToTop />
      <form className={getClassNames} onSubmit={props.onSubmit}>
        {props.children}
      </form>
    </>
  )
}

FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
}

export default FormContainer
