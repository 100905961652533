import React from 'react'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import RadioInputField from '../../Inputs/RadioInputField/RadioInputField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'

import RadioGroup, {
  RadioGroupOption,
} from '../../Inputs/RadioGroup/RadioGroup'

import './WerkstudentWeeklyWorkingTimeForm.scss'

interface Props {
  totalWeeklyWorkingTimeLimit: string
  operationTime: string
  operationTimeLimit: string
  operationTimeExercised: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSecondaryButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const WerkstudentWeeklyWorkingTimeForm = (props: Props) => {
  const {
    totalWeeklyWorkingTimeLimit,
    operationTime,
    operationTimeLimit,
    operationTimeExercised,
  } = props

  const renderFormInputs = () => {
    return (
      <>
        <RadioGroup label="Liegt die wöchentliche Gesamtarbeitszeit über 20 Stunden?">
          <RadioGroupOption
            name="totalWeeklyWorkingTimeLimit"
            defaultChecked={totalWeeklyWorkingTimeLimit === 'Nein'}
            value="Nein"
            onChange={props.onChange}
            description="Die Gesamtarbeitszeit ist nie höher als 20 Stunden in der Woche."
            label="Nein"
          />
          <RadioGroupOption
            name="totalWeeklyWorkingTimeLimit"
            defaultChecked={totalWeeklyWorkingTimeLimit === 'Ja'}
            value="Ja"
            onChange={props.onChange}
            description="Die Gesamtarbeitszeit liegt immer über 20 Stunden in der Woche."
            label="Ja"
          />
          <RadioGroupOption
            name="totalWeeklyWorkingTimeLimit"
            defaultChecked={totalWeeklyWorkingTimeLimit === 'Gelegentlich'}
            value="Gelegentlich"
            onChange={props.onChange}
            description="Die Gesamtarbeitszeit liegt in ausnahmefällen über 20 Stunden in der Woche."
            label="Gelegentlich"
          />
        </RadioGroup>
        {totalWeeklyWorkingTimeLimit === 'Gelegentlich' ? (
          <>
            <RadioInputField
              name="operationTime"
              label="Finden unvorhersehbar befristete Einsätze vornehmlich am Wochenende oder Abends/Nachts statt?"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={operationTime === 'Nein' ? 0 : 1}
              defaultValue={operationTime}
              onChange={props.onChange}
            />
            {operationTime === 'Nein' ? (
              <RadioInputField
                name="operationTimeLimit"
                label="Sind die Einsätze auf die Semesterferien begrenzt?"
                radioOptions={['Nein', 'Ja']}
                defaultChecked={operationTimeLimit === 'Nein' ? 0 : 1}
                defaultValue={operationTimeLimit}
                onChange={props.onChange}
              />
            ) : null}
            {operationTime === 'Ja' || operationTimeLimit === 'Ja' ? (
              <RadioInputField
                name="operationTimeExercised"
                label="Werden die Einsätze an mehr als 26 Wochen in einem Jahr ausgeübt?"
                radioOptions={['Nein', 'Ja']}
                defaultChecked={operationTimeExercised === 'Nein' ? 0 : 1}
                defaultValue={operationTimeExercised}
                onChange={props.onChange}
              />
            ) : null}
          </>
        ) : null}
      </>
    )
  }

  return (
    <FormContainer
      className="werkstudent-weekly-working-time-form"
      onSubmit={props.onSubmit}
    >
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText="Wöchentliche Gesamtarbeitszeit" />
      <FormBody>{renderFormInputs()}</FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
      />
    </FormContainer>
  )
}

export default WerkstudentWeeklyWorkingTimeForm
