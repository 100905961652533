const consultantFormValues = {
  activityKey: '62102',
  autosuggestSearchValue: '62102 - Einzelhandelskaufmann/-frau',
  clientNumber: '11500',
  consultantNumber: '260814',
  jobTitle: 'Einzelhandelskaufmann/-frau',
  staffNumber: '1100',
}

export default consultantFormValues
