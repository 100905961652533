import React, { useEffect, useState } from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import DashboardNavigation from '../../../components/Navigations/DashboardNavigation/DashboardNavigation'
import DashboardSubNavigation from '../../../components/Navigations/DashboardSubNavigation/DashboardSubNavigation'
import AccountSettingsView from '../AccountSettingsView/AccountSettingsView'
import ImportFileSettingsView from '../ImportFileSettingsView/ImportFileSettingsView'
import { getTaxConsultant } from '../../utility/utility'

import './SettingsView.scss'
import IntegrationsSettingsView from '../IntegrationsSettingsView/IntegrationsSettingsView'
import FiveFSoftware from '../../IntegrationViews/FiveFSoftware/FiveFSoftware'
import CustomizingSettingsView from '../CustomizingSettingsView/CustomizingSettingsView'
import StaffFormOptionsView from '../StaffFormOptionsView/StaffFormOptionsView'
import ContactInformationSettingsView from '../ContactInformationSettingsView/ContactInformationSettingsView'
import ClientFormOptionsView from '../ClientFormOptionsView/ClientFormOptionsView'

interface Props {
  logo: string
}

const SettingsView = (props: Props) => {
  const location = useLocation()
  const [data, setData] = useState({
    name: '',
    email: '',
    fastdocsClientEmail: '',
    contracts: {
      client: { contract: false, contractOption: 'lite' },
      staff: { contract: false, contractOption: 'lite' },
    },
    features: {
      costCenter: false,
      staffNumber: false,
      sepa: false,
    },
    subscriptionFee: '',
    clientSubscriptionFee: '',
    importVersion: '',
    importSystem: '',
    integrations: {
      fiveFSoftware: {},
    },
    contactInformation: {
      companyName: '',
      street: '',
      houseNumber: '',
      zipCode: '',
      city: '',
      creditorId: '',
    },
    logo: props.logo,
  })

  useEffect(() => {
    const dbRef = `taxConsultant/${getTaxConsultant()}`

    firebase
      .database()
      .ref(dbRef)
      .on('value', (snapshot) => {
        setData(snapshot.val())
      })

    return () => {
      firebase.database().ref(dbRef).off()
    }
  }, [])

  return (
    <>
      <DashboardNavigation />
      <div className="card container">
        <DashboardSubNavigation contracts={data.contracts} />

        {location.pathname === '/dashboard/settings' ? (
          <Redirect
            from="/dashboard/settings"
            to="/dashboard/settings/account"
          />
        ) : null}

        <Route exact path="/dashboard/settings/account">
          <AccountSettingsView data={data} />
        </Route>

        <Route exact path="/dashboard/settings/contact-information">
          <ContactInformationSettingsView data={data} />
        </Route>

        <Route path="/dashboard/settings/customization">
          <CustomizingSettingsView
            logo={props.logo}
            contracts={data.contracts}
          />
        </Route>

        <Route exact path="/dashboard/settings/fastdocs-staff/import">
          <ImportFileSettingsView data={data} />
        </Route>

        <Route exact path="/dashboard/settings/fastdocs-staff/form-options">
          <StaffFormOptionsView data={data} />
        </Route>

        <Route exact path="/dashboard/settings/integrations">
          <IntegrationsSettingsView data={data} />
        </Route>

        <Route path="/dashboard/settings/integrations/5f-software">
          <FiveFSoftware />
        </Route>

        {data.contracts.client.contract ? (
          <Route exact path="/dashboard/settings/fastdocs-client/form-options">
            <ClientFormOptionsView data={data} />
          </Route>
        ) : null}
      </div>
    </>
  )
}

export default SettingsView
