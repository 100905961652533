import React, { Fragment } from 'react'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import RadioInputField from '../../Inputs/RadioInputField/RadioInputField'
import TextInputField from '../../Inputs/TextInputField/TextInputField'
import SelectField from '../../Selects/SelectField/SelectField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import FormHint from '../../ui-components/hints/FormHint'

import './FurtherEmploymentsForm.scss'
import DateInputField from '../../Inputs/DateInputField/DateInputField'
import RadioGroup, {
  RadioGroupOption,
} from '../../Inputs/RadioGroup/RadioGroup'
import { STAFF_FORMS } from '../../../constants/Formulars'

interface Props {
  additionalMiniJob: string
  additionalEmployement: string
  formularName: string
  activityStatus: string
  furtherEmployments: boolean
  typeOfEmployment: string
  furtherEmploymentCompanyName: string
  furtherMinijobEntryDay: string
  furtherMinijobEntryMonth: string
  furtherMinijobEntryYear: string
  furtherEmploymentsTotalSallary: string
  payingPensionInsurance: string
  totalWeeklyWorkingTimeLimitForStudents: string
  operationTimeForStudents: string
  operationTimeIsLimitedToSemesterBreaks: string
  operationTimeIsExercisedForMoreThan26WeeksPerYear: string
  handleFurtherEmploymentsChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSecondaryButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const FurtherEmploymentsForm = (props: Props) => {
  const disableNextButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.additionalMiniJob === 'Ja') {
      e.preventDefault()
      e.target.blur()
    }
    if (props.additionalEmployement === 'Ja') {
      e.preventDefault()
      e.target.blur()
    }
  }

  const studentFormFields = () => {
    return (
      <>
        <RadioGroup label="Liegt Deine Wöchentliche Gesamtarbeitszeit über 20 Stunden?">
          <RadioGroupOption
            name="totalWeeklyWorkingTimeLimitForStudents"
            defaultChecked={
              props.totalWeeklyWorkingTimeLimitForStudents === 'Nein'
            }
            value="Nein"
            onChange={props.onChange}
            description="Meine Gesamtarbeitszeit ist nie höher als 20 Stunden in der Woche."
            label="Nein"
          />
          <RadioGroupOption
            name="totalWeeklyWorkingTimeLimitForStudents"
            defaultChecked={
              props.totalWeeklyWorkingTimeLimitForStudents === 'Ja'
            }
            value="Ja"
            onChange={props.onChange}
            description="Meine Gesamtarbeitszeit liegt immer über 20 Stunden in der Woche."
            label="Ja"
          />
          <RadioGroupOption
            name="totalWeeklyWorkingTimeLimitForStudents"
            defaultChecked={
              props.totalWeeklyWorkingTimeLimitForStudents === 'Gelegentlich'
            }
            value="Gelegentlich"
            onChange={props.onChange}
            description="Meine Gesamtarbeitszeit liegt in ausnahmefällen über 20 Stunden in der Woche."
            label="Gelegentlich"
          />
        </RadioGroup>
        {props.totalWeeklyWorkingTimeLimitForStudents === 'Gelegentlich' ? (
          <>
            <RadioInputField
              name="operationTimeForStudents"
              label="Finden unvorhersehbar befristete Einsätze vornehmlich am Wochenende oder Abends/Nachts statt?"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={props.operationTimeForStudents === 'Nein' ? 0 : 1}
              defaultValue={props.operationTimeForStudents}
              onChange={props.onChange}
            />
            {props.operationTimeForStudents === 'Nein' ? (
              <RadioInputField
                name="operationTimeIsLimitedToSemesterBreaks"
                label="Sind die Einsätze auf die Semesterferien begrenzt?"
                radioOptions={['Nein', 'Ja']}
                defaultChecked={
                  props.operationTimeIsLimitedToSemesterBreaks === 'Nein'
                    ? 0
                    : 1
                }
                defaultValue={props.operationTimeIsLimitedToSemesterBreaks}
                onChange={props.onChange}
              />
            ) : null}
            {props.operationTimeForStudents === 'Ja' ||
            props.operationTimeIsLimitedToSemesterBreaks === 'Ja' ? (
              <RadioInputField
                name="operationTimeIsExercisedForMoreThan26WeeksPerYear"
                label="Werden die Einsätze an mehr als 26 Wochen in einem Jahr ausgeübt? "
                radioOptions={['Nein', 'Ja']}
                defaultChecked={
                  props.operationTimeIsExercisedForMoreThan26WeeksPerYear ===
                  'Nein'
                    ? 0
                    : 1
                }
                defaultValue={
                  props.operationTimeIsExercisedForMoreThan26WeeksPerYear
                }
                onChange={props.onChange}
              />
            ) : null}
          </>
        ) : null}
      </>
    )
  }

  const renderFormInputs = () => {
    if (props.activityStatus) {
      return (
        <React.Fragment>
          <RadioInputField
            name="furtherEmployments"
            label="Bist Du noch bei anderen Unternehmen beschäftigt?"
            radioOptions={['Nein', 'Ja']}
            defaultChecked={props.furtherEmployments === false ? 0 : 1}
            defaultValue={props.furtherEmployments}
            onChange={props.handleFurtherEmploymentsChange}
          />
          {props.furtherEmployments !== false ? (
            <>
              <SelectField
                label="Art der Tätigkeit"
                name="typeOfEmployment"
                defaultValue={props.typeOfEmployment}
                onChange={props.onChange}
                options={['Minijob bis 450€', 'Beschäftigung über 450€']}
                noDefault
                required
              />
              {props.formularName === STAFF_FORMS.STUDENT
                ? studentFormFields()
                : null}
            </>
          ) : null}
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <RadioInputField
          name="furtherEmployments"
          label="Bist Du noch bei anderen Unternehmen beschäftigt?"
          radioOptions={['Nein', 'Ja']}
          defaultChecked={props.furtherEmployments === false ? 0 : 1}
          defaultValue={props.furtherEmployments}
          onChange={props.handleFurtherEmploymentsChange}
        />
        {props.furtherEmployments !== false ? (
          <div className="further-employment-fields">
            <SelectField
              label="Art der Tätigkeit"
              name="typeOfEmployment"
              defaultValue={props.typeOfEmployment}
              onChange={props.onChange}
              options={['Minijob bis 450€', 'Beschäftigung über 450€']}
              noDefault
              required
            />
            {props.typeOfEmployment !== 'Beschäftigung über 450€' ? (
              <Fragment>
                <RadioInputField
                  key="additionalEmployement"
                  name="additionalEmployement"
                  label="Hast Du noch eine weitere Beschäftigung, bei der Du über 450€ verdienst?"
                  radioOptions={['Nein', 'Ja']}
                  defaultChecked={
                    props.additionalEmployement === 'Nein' ? 0 : 1
                  }
                  defaultValue={props.additionalEmployement}
                  onChange={props.onChange}
                />

                {props.additionalEmployement === 'Nein' ? (
                  <Fragment>
                    <RadioInputField
                      name="payingPensionInsurance"
                      label="Bist Du bei dem bestehenden Minijob von der Rentenversicherung befreit?"
                      radioOptions={['Nein', 'Ja']}
                      defaultChecked={
                        props.payingPensionInsurance === 'Nein' ? 0 : 1
                      }
                      defaultValue={props.payingPensionInsurance}
                      onChange={props.onChange}
                    />
                    <Fragment>
                      <TextInputField
                        label="Arbeitgeber des bestehenden Minijobs"
                        placeholder="Firmenname GmbH"
                        name="furtherEmploymentCompanyName"
                        defaultValue={props.furtherEmploymentCompanyName}
                        onChange={props.onChange}
                        required
                      />
                      <DateInputField
                        label={'Seit wann hast Du den bestehenden Minijob?'}
                        names={[
                          'furtherMinijobEntryDay',
                          'furtherMinijobEntryMonth',
                          'furtherMinijobEntryYear',
                        ]}
                        onChange={props.onChange}
                        placeholder={['TT', 'MM', 'JJJJ']}
                        maxLength={['2', '2', '4']}
                        max={['31', '12', new Date().getFullYear()]}
                        min={['1', '1', new Date().getFullYear() - 10]}
                        pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                        required
                        defaultValue={[
                          props.furtherMinijobEntryDay,
                          props.furtherMinijobEntryMonth,
                          props.furtherMinijobEntryYear,
                        ]}
                      />
                      <RadioInputField
                        name="furtherEmploymentsTotalSallary"
                        label="Verdienst Du mit dem bestehenden und dem neuen Minijob zusammen, insgesamt mehr als 450€ im Monat?"
                        radioOptions={['Nein', 'Ja']}
                        defaultChecked={
                          props.furtherEmploymentsTotalSallary === 'Nein'
                            ? 0
                            : 1
                        }
                        required
                        onChange={props.onChange}
                      />
                    </Fragment>
                  </Fragment>
                ) : (
                  <FormHint
                    hintHeadline="Achtung:"
                    hintText="Du kannst diesen MiniJob nicht „Brutto für Netto“ machen. Bitte setz dich mit deinem Arbeitgeber und dessen Steuerkanzlei in Verbindung."
                  />
                )}
              </Fragment>
            ) : (
              <Fragment>
                <RadioInputField
                  key="additionalMiniJob"
                  name="additionalMiniJob"
                  label="Hast Du noch einen weiteren Minijob?"
                  radioOptions={['Nein', 'Ja']}
                  defaultChecked={props.additionalMiniJob === 'Nein' ? 0 : 1}
                  required
                  onChange={props.onChange}
                />
                {props.additionalMiniJob === 'Ja' ? (
                  <FormHint
                    hintHeadline="Achtung:"
                    hintText="Du kannst diesen MiniJob nicht „Brutto für Netto“ machen. Bitte setz dich mit deinem Arbeitgeber und dessen Steuerkanzlei in Verbindung."
                  />
                ) : null}
              </Fragment>
            )}
          </div>
        ) : null}
      </React.Fragment>
    )
  }

  return (
    <FormContainer
      className={'further-employments-form'}
      onSubmit={props.onSubmit}
    >
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Weitere Beschäftigungen'} />
      <FormBody>{renderFormInputs()}</FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
        onClick={disableNextButton}
      />
    </FormContainer>
  )
}

export default FurtherEmploymentsForm
