import React, { Component, Fragment } from 'react'
import { detect } from 'detect-browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import moment from 'moment'

import clientFormValues from 'helpers/autofill/studentForm/clientFormValues'

import FormStage from 'components/Stages/FormStage/FormStage'
import CompanyForm from 'components/Forms/CompanyForm/CompanyForm'
import EmployeeForm from 'components/Forms/EmployeeForm/EmployeeForm'
import WorkingHours from 'components/Forms/WorkingHours/WorkingHours'
import SallaryForm from 'components/Forms/SallaryForm/SallaryForm'
import WerkstudentWeeklyWorkingTimeForm from 'components/Forms/WerkstudentWeeklyWorkingTimeForm/WerkstudentWeeklyWorkingTimeForm'
import EmailForm from 'components/Forms/EmailForm/EmailForm'
import CapitalAccumulationBenefits from 'components/Forms/CapitalAccumulationBenefits/CapitalAccumulationBenefits'
import SuccsessForm from 'components/Forms/SuccessForm/SuccessForm'
import HelpOverForStudentForm from 'components/Overlays/HelpOver/fastdocs-staff/HelpOverForStudentForm/HelpOverForStudentForm'
import AdditionalBenefitsForm from 'components/Forms/AdditionalBenefitsForm/AdditionalBenefitsForm'
import SummaryFormClient from 'components/Forms/SummaryForm/SummaryFormClient'
import NotificationCard from 'components/Notifications/NotificationCard/NotificationCard'
import { ReactComponent as InfoIcon } from 'static/InformationIcon.svg'
import createFormId from 'views/utility/createFormId'
import {
  getFormStep,
  getProgressValue,
  getTaxConsultant,
} from 'views/utility/utility.jsx'
import AutofillButton from 'helpers/autofill/AutofillButton'
import { STAFF_FORMS } from 'constants/Formulars'

const database = firebase.database()

class ClientsViewForStudentForm extends Component {
  state = {
    formId: createFormId(),
    formState: {
      formStep: 0,
      totalFormSteps: 9,
    },
    clerks: {},
    showClerks: false,
    taxConsultantUseFiveFIntegration: false,
    formValues: {
      capitalAccumulation: 'Nein',
      clientEmail: '',
      company: '',
      companyCar: false,
      companyPensionPlan: false,
      contractForm: '',
      contractLimitDay: '',
      contractLimitMonth: '',
      contractLimitYear: '',
      costCenter: '',
      costCenterQuerstion: false,
      employedAs: '',
      employeeEmail: '',
      employmentLocation: '',
      entryday: '',
      entrymonth: '',
      entryyear: '',
      firstname: '',
      formCreationDate: moment().format('DD.MM.YYYY'),
      formularKey: '101',
      formularName: STAFF_FORMS.STUDENT,
      friday: '',
      handycapped: 'Unbekannt',
      hollidays: '',
      jobTicket: false,
      taxFreeBenefit: false,
      internetGrant: false,
      lastname: '',
      monday: '',
      monthlyProportion: '',
      payingOption: 'Überweisung',
      sallary: 'Festes monatliches Gehalt',
      sallaryIncreaseAfterTrialPeriod: false,
      sallaryValue: '',
      saturday: '',
      selectedClerk: '',
      staffNumber: '',
      staffNumberQuerstion: false,
      statusIndicator: '',
      sunday: '',
      taxConsultant: getTaxConsultant(),
      taxConsultantEmail: '',
      taxConsultantImportSystem: '',
      thursday: '',
      travelAllowance: false,
      tuesday: '',
      typeOfEmployee: '',
      wednesday: '',
      otherInformation: '',
      totalWeeklyWorkingTimeLimit: 'Nein',
      operationTime: 'Nein',
      operationTimeLimit: 'Nein',
      operationTimeExercised: 'Nein',
      employeeAge: '18 Jahre oder älter',
    },
    features: {
      costCenter: false,
      staffNumber: false,
    },
    helpOver: {
      isOpen: false,
      class: '',
    },
  }

  closeEdgeNotification = () => {
    const notificationCard =
      document.querySelector<HTMLElement>('.notification-card')
    if (notificationCard) {
      notificationCard.style.display = 'none'
    }
  }

  showEdgeNotification = () => {
    const browser = detect()

    if (browser?.name === 'edge') {
      return (
        <NotificationCard
          icon={<InfoIcon />}
          title="Wichtiger Hinweis:"
          text="Dein Browser erwartet bei den Nachkommastellen eventuell einen Punkt statt eines Kommas."
          onClick={this.closeEdgeNotification}
        />
      )
    }
  }

  handleCostCenterQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'Ja') {
      this.setState({
        formValues: {
          ...this.state.formValues,
          costCenterQuerstion: true,
        },
      })
    } else {
      this.setState({
        formValues: {
          ...this.state.formValues,
          costCenterQuerstion: false,
        },
      })
    }
  }

  handleStaffNumberQuestionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === 'Ja') {
      this.setState({
        formValues: {
          ...this.state.formValues,
          staffNumberQuerstion: true,
        },
      })
    } else {
      this.setState({
        formValues: {
          ...this.state.formValues,
          staffNumberQuerstion: false,
        },
      })
    }
  }

  componentDidUpdate = () => {
    if (this.state.formValues.entryday.length === 1) {
      this.setState({
        formValues: {
          ...this.state.formValues,
          entryday: `0${this.state.formValues.entryday}`,
        },
      })
    }
    if (this.state.formValues.entrymonth.length === 1) {
      this.setState({
        formValues: {
          ...this.state.formValues,
          entrymonth: `0${this.state.formValues.entrymonth}`,
        },
      })
    }
    if (this.state.formValues.contractLimitDay.length === 1) {
      this.setState({
        formValues: {
          ...this.state.formValues,
          contractLimitDay: `0${this.state.formValues.contractLimitDay}`,
        },
      })
    }
    if (this.state.formValues.contractLimitMonth.length === 1) {
      this.setState({
        formValues: {
          ...this.state.formValues,
          contractLimitMonth: `0${this.state.formValues.contractLimitMonth}`,
        },
      })
    }

    const setDocumentTitle = () => {
      if (
        this.state.formState.formStep === this.state.formState.totalFormSteps
      ) {
        document.title =
          'Schritt ' +
          this.state.formState.formStep +
          ' von ' +
          this.state.formState.totalFormSteps +
          ' - ' +
          this.state.formValues.formularName
      } else {
        document.title =
          'Schritt ' +
          (this.state.formState.formStep + 1) +
          ' von ' +
          this.state.formState.totalFormSteps +
          ' - ' +
          this.state.formValues.formularName
      }
    }

    setDocumentTitle()
  }

  componentDidMount = () => {
    database
      .ref(`taxConsultant/${this.state.formValues.taxConsultant}`)
      .once('value', (snapshot) => {
        const data = snapshot.val()
        const {
          importSystem,

          email,
          features,
          integrations,
          clerks,
        } = data

        this.setState({
          formValues: {
            ...this.state.formValues,
            taxConsultantEmail: email,
            taxConsultantImportSystem: importSystem,
          },
          taxConsultantUseFiveFIntegration: integrations?.fiveFSoftware
            ? true
            : false,
          features: features ? { ...features } : null,
          clerks: clerks ? { ...clerks } : null,
          showClerks: clerks && Object.keys(clerks).length > 0 ? true : false,
        })
      })
  }

  renderNextFormStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const increaseFormStep = this.state.formState.formStep + 1

    e.preventDefault()
    this.setState({
      formState: {
        ...this.state.formState,
        formStep: increaseFormStep,
      },
    })
  }

  renderPreviousFormStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const decreaseFormStep = this.state.formState.formStep - 1

    e.preventDefault()
    this.setState({
      formState: {
        ...this.state.formState,
        formStep: decreaseFormStep,
      },
    })
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [e.target.name]: e.target.value,
      },
    })
  }

  handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    e.preventDefault()

    database
      .ref(`jobs/${this.state.formId}`)
      .set({
        clientData: {
          ...this.state.formValues,
        },
        sentJobCreationMailToClient: true,
        sentJobCreationMailToEmployee: true,
        sentJobCreationMailToConsultant: true,
        taxConsultantUseFiveFIntegration:
          this.state.taxConsultantUseFiveFIntegration,
      })
      .then(() => {
        this.renderNextFormStep(e)
      })
      .catch((error) => {
        alert(error)
      })
  }

  handleHelperButtonClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!this.state.helpOver.isOpen) {
      this.setState({
        ...this.state,
        helpOver: {
          ...this.state.helpOver,
          isOpen: !this.state.helpOver.isOpen,
        },
      })
      setTimeout(() => {
        this.setState({
          ...this.state,
          helpOver: {
            ...this.state.helpOver,
            class: 'show',
          },
        })
      }, 50)
    }
    if (this.state.helpOver.isOpen) {
      this.setState({
        ...this.state,
        helpOver: {
          ...this.state.helpOver,
          class: '',
        },
      })
      setTimeout(() => {
        this.setState({
          ...this.state,
          helpOver: {
            ...this.state.helpOver,
            isOpen: false,
          },
        })
      }, 500)
    }
    e.target.blur()
  }

  handleHelpOverCloseButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    this.setState({
      ...this.state,
      helpOver: {
        ...this.state.helpOver,
        class: '',
      },
    })
    setTimeout(() => {
      this.setState({
        ...this.state,
        helpOver: {
          ...this.state.helpOver,
          isOpen: false,
        },
      })
    }, 500)
  }

  handleClerkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    Object.entries(this.state.clerks).forEach((clerk: any) => {
      if (e.target.value === clerk[1].name) {
        this.setState({
          formValues: {
            ...this.state.formValues,
            selectedClerk: clerk[1].email,
          },
        })
      }
      if (e.target.value === 'Unbekannt') {
        this.setState({
          formValues: {
            ...this.state.formValues,
            selectedClerk: this.state.formValues.taxConsultantEmail,
          },
        })
      }
    })
  }

  getListOfClerks = () => {
    const clerksList = this.state.clerks
      ? Object.entries(this.state.clerks).map((entry: any) => {
          return `${entry[1].name}`
        })
      : []
    clerksList.sort()
    clerksList.unshift('Unbekannt')

    return clerksList
  }

  handleSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [e.target.name]: e.target.checked,
      },
    })
  }

  renderFormSteps = () => {
    switch (this.state.formState.formStep) {
      case 0:
        return (
          <CompanyForm
            onSubmit={this.renderNextFormStep}
            onChange={this.handleInputChange}
            onHelperButtonClick={this.handleHelperButtonClick}
            company={this.state.formValues.company}
            selectedClerk={this.state.formValues.selectedClerk}
            listOfClerks={this.getListOfClerks()}
            onClerkChange={this.handleClerkChange}
            showClerkSeletion={this.state.showClerks}
          />
        )
      case 1:
        return (
          <EmployeeForm
            onSubmit={this.renderNextFormStep}
            onSecondaryButtonClick={this.renderPreviousFormStep}
            onChange={this.handleInputChange}
            onHelperButtonClick={this.handleHelperButtonClick}
            firstname={this.state.formValues.firstname}
            lastname={this.state.formValues.lastname}
            employedAs={this.state.formValues.employedAs}
            handycapped={this.state.formValues.handycapped}
            employmentLocation={this.state.formValues.employmentLocation}
            costCenter={this.state.formValues.costCenter}
            staffNumber={this.state.formValues.staffNumber}
            entryDate={[
              this.state.formValues.entryday,
              this.state.formValues.entrymonth,
              this.state.formValues.entryyear,
            ]}
            contractLimitationDate={[
              this.state.formValues.contractLimitDay,
              this.state.formValues.contractLimitMonth,
              this.state.formValues.contractLimitYear,
            ]}
            contractForm={this.state.formValues.contractForm}
            typeOfEmployee={this.state.formValues.typeOfEmployee}
            formularName={this.state.formValues.formularName}
            statusIndicator={this.state.formValues.statusIndicator}
            features={this.state.features}
            costCenterQuerstion={this.state.formValues.costCenterQuerstion}
            staffNumberQuerstion={this.state.formValues.staffNumberQuerstion}
            handleCostCenterQuestionChange={this.handleCostCenterQuestionChange}
            handleStaffNumberQuestionChange={
              this.handleStaffNumberQuestionChange
            }
          />
        )
      case 2:
        return (
          <SallaryForm
            onSubmit={this.renderNextFormStep}
            onSecondaryButtonClick={this.renderPreviousFormStep}
            onChange={this.handleInputChange}
            sallary={this.state.formValues.sallary}
            sallaryValue={this.state.formValues.sallaryValue}
            payingOption={this.state.formValues.payingOption}
            formularName={this.state.formValues.formularName}
            employeeAge={this.state.formValues.employeeAge}
            onHelperButtonClick={this.handleHelperButtonClick}
            entrydate={`${this.state.formValues.entryyear}-${this.state.formValues.entrymonth}-${this.state.formValues.entryday}`}
          />
        )
      case 3:
        return (
          <WorkingHours
            onSubmit={this.renderNextFormStep}
            onSecondaryButtonClick={this.renderPreviousFormStep}
            onChange={this.handleInputChange}
            onHelperButtonClick={this.handleHelperButtonClick}
            monday={this.state.formValues.monday}
            tuesday={this.state.formValues.tuesday}
            wednesday={this.state.formValues.wednesday}
            thursday={this.state.formValues.thursday}
            friday={this.state.formValues.friday}
            saturday={this.state.formValues.saturday}
            sunday={this.state.formValues.sunday}
            sallary={this.state.formValues.sallary}
            employeeAge={this.state.formValues.employeeAge}
            sallaryValue={this.state.formValues.sallaryValue}
            formularName={this.state.formValues.formularName}
            hollidays={this.state.formValues.hollidays}
            entrydate={`${this.state.formValues.entryyear}-${this.state.formValues.entrymonth}-${this.state.formValues.entryday}`}
          />
        )
      case 4:
        return (
          <WerkstudentWeeklyWorkingTimeForm
            totalWeeklyWorkingTimeLimit={
              this.state.formValues.totalWeeklyWorkingTimeLimit
            }
            operationTime={this.state.formValues.operationTime}
            operationTimeLimit={this.state.formValues.operationTimeLimit}
            operationTimeExercised={
              this.state.formValues.operationTimeExercised
            }
            onSubmit={this.renderNextFormStep}
            onSecondaryButtonClick={this.renderPreviousFormStep}
            onChange={this.handleInputChange}
            onHelperButtonClick={this.handleHelperButtonClick}
          />
        )
      case 5:
        return (
          <CapitalAccumulationBenefits
            onSubmit={this.renderNextFormStep}
            onSecondaryButtonClick={this.renderPreviousFormStep}
            onChange={this.handleInputChange}
            monthlyProportion={this.state.formValues.monthlyProportion}
            capitalAccumulation={this.state.formValues.capitalAccumulation}
            onHelperButtonClick={this.handleHelperButtonClick}
            sallaryValue={this.state.formValues.sallaryValue}
            formularName={this.state.formValues.formularName}
          />
        )
      case 6:
        return (
          <AdditionalBenefitsForm
            onSubmit={this.renderNextFormStep}
            onSecondaryButtonClick={this.renderPreviousFormStep}
            onChange={this.handleSelection}
            onHelperButtonClick={this.handleHelperButtonClick}
            firstname={this.state.formValues.firstname}
            lastname={this.state.formValues.lastname}
            companyCar={this.state.formValues.companyCar}
            companyPensionPlan={this.state.formValues.companyPensionPlan}
            jobTicket={this.state.formValues.jobTicket}
            taxFreeBenefit={this.state.formValues.taxFreeBenefit}
            internetGrant={this.state.formValues.internetGrant}
            travelAllowance={this.state.formValues.travelAllowance}
            sallaryIncreaseAfterTrialPeriod={
              this.state.formValues.sallaryIncreaseAfterTrialPeriod
            }
          />
        )
      case 7:
        return (
          <EmailForm
            onSubmit={this.renderNextFormStep}
            onSecondaryButtonClick={this.renderPreviousFormStep}
            onChange={this.handleInputChange}
            onHelperButtonClick={this.handleHelperButtonClick}
            clientEmail={this.state.formValues.clientEmail}
            employeeEmail={this.state.formValues.employeeEmail}
            taxConsultantEmail={this.state.formValues.taxConsultantEmail}
            taxConsultantImportSystem={
              this.state.formValues.taxConsultantImportSystem
            }
            formularName={this.state.formValues.formularName}
            otherInformation={this.state.formValues.otherInformation}
          />
        )
      case 8:
        return (
          <SummaryFormClient
            onSubmit={this.handleSubmit}
            onSecondaryButtonClick={this.renderPreviousFormStep}
            onHelperButtonClick={this.handleHelperButtonClick}
            company={this.state.formValues.company}
            capitalAccumulation={this.state.formValues.capitalAccumulation}
            clientEmail={this.state.formValues.clientEmail}
            employeeEmail={this.state.formValues.employeeEmail}
            employedAs={this.state.formValues.employedAs}
            firstname={this.state.formValues.firstname}
            formularName={this.state.formValues.formularName}
            friday={this.state.formValues.friday}
            handycapped={this.state.formValues.handycapped}
            employmentLocation={this.state.formValues.employmentLocation}
            lastname={this.state.formValues.lastname}
            monday={this.state.formValues.monday}
            monthlyProportion={this.state.formValues.monthlyProportion}
            payingOption={this.state.formValues.payingOption}
            sallary={this.state.formValues.sallary}
            sallaryValue={this.state.formValues.sallaryValue}
            saturday={this.state.formValues.saturday}
            sunday={this.state.formValues.sunday}
            thursday={this.state.formValues.thursday}
            tuesday={this.state.formValues.tuesday}
            wednesday={this.state.formValues.wednesday}
            entryday={this.state.formValues.entryday}
            entrymonth={this.state.formValues.entrymonth}
            entryyear={this.state.formValues.entryyear}
            hollidays={this.state.formValues.hollidays}
            companyCar={this.state.formValues.companyCar}
            companyPensionPlan={this.state.formValues.companyPensionPlan}
            jobTicket={this.state.formValues.jobTicket}
            travelAllowance={this.state.formValues.travelAllowance}
            sallaryIncreaseAfterTrialPeriod={
              this.state.formValues.sallaryIncreaseAfterTrialPeriod
            }
            otherInformation={this.state.formValues.otherInformation}
            staffNumber={this.state.formValues.staffNumber}
            costCenter={this.state.formValues.costCenter}
            totalWeeklyWorkingTimeLimit={
              this.state.formValues.totalWeeklyWorkingTimeLimit
            }
            operationTime={this.state.formValues.operationTime}
            operationTimeLimit={this.state.formValues.operationTimeLimit}
            operationTimeExercised={
              this.state.formValues.operationTimeExercised
            }
            taxFreeBenefit={this.state.formValues.taxFreeBenefit}
            internetGrant={this.state.formValues.internetGrant}
          />
        )
      default:
        return (
          <SuccsessForm
            successText="Personaldaten erfolgreich angelegt."
            formId={this.state.formId}
          >
            <p>
              {this.state.formValues.firstname} bekommt nun eine E-Mail von uns.
              Die E-Mail geht an die von dir eben eingetragene Adresse{' '}
              {this.state.formValues.employeeEmail}. Mit dieser E-Mail bekommt{' '}
              {this.state.formValues.firstname} einen Link von uns. Mit diesem
              Link werden wir die notwendigen Arbeitnehmerdaten abfragen.
            </p>
            <p>
              Sobald diese Daten hinterlegt sind und Deine Steuerkanzlei alles
              verarbeitet hat, bekommst Du automatisch ein PDF von uns für deine
              Unterlagen. Dieses PDF musst Du dir von{' '}
              {this.state.formValues.firstname} gegenzeichnen lassen.
            </p>
            <p>
              Auf Wiedersehen{' '}
              <span role="img" aria-label="Winkende-Hand">
                👋
              </span>
              .
            </p>
          </SuccsessForm>
        )
    }
  }

  render() {
    return (
      <Fragment>
        <FormStage
          headlineText={STAFF_FORMS.STUDENT}
          totalSteps={this.state.formState.totalFormSteps}
          actualStep={getFormStep(
            this.state.formState.formStep,
            this.state.formState.totalFormSteps
          )}
          progress={getProgressValue(
            this.state.formState.formStep,
            this.state.formState.totalFormSteps
          )}
        />
        {this.renderFormSteps()}
        {this.state.helpOver.isOpen === true ? (
          <HelpOverForStudentForm
            onCloseButtonClick={this.handleHelpOverCloseButton}
            className={this.state.helpOver.class}
            formStep={this.state.formState.formStep}
            formId={this.state.formId}
          />
        ) : null}

        {this.showEdgeNotification()}

        <AutofillButton
          onClick={() =>
            this.setState({
              formValues: {
                ...this.state.formValues,
                ...clientFormValues,
              },
            })
          }
        />
      </Fragment>
    )
  }
}

export default ClientsViewForStudentForm
