import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ChevronIcon } from 'static/ChevronIcon.svg'
import './Accordion.scss'

class Accordion extends React.Component {
  state = {
    showContent: false,
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.formStep) {
      this.setState({
        showContent: false,
      })
    }
  }

  handleShowContent = () => {
    this.setState({
      showContent: !this.state.showContent,
    })
  }

  renderText = () => {
    if (this.props.text.includes('<br/>')) {
      const textParts = this.props.text.split('<br/>')
      return (
        <React.Fragment>
          <p>{textParts[0]}</p>
          <p>{textParts[1]}</p>
          <p>{textParts[2]}</p>
        </React.Fragment>
      )
    }
    return <p>{this.props.text}</p>
  }

  render() {
    return (
      <div className="accordion">
        <h5
          className={
            this.state.showContent === true ? 'headline show' : 'headline'
          }
          onClick={this.handleShowContent}
        >
          {this.props.headline}
          <span
            className={this.state.showContent === true ? 'icon show' : 'icon'}
          >
            <ChevronIcon />
          </span>
        </h5>
        <div
          className={
            this.state.showContent === true ? 'content show' : 'content'
          }
        >
          {this.renderText()}
          {this.props.url ? (
            <p>
              <a
                href={this.props.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.urlText}
              </a>
            </p>
          ) : null}
          {this.props.example ? (
            <p>
              <span className="example">Beispiel:</span> {this.props.example}
            </p>
          ) : null}
        </div>
      </div>
    )
  }
}

Accordion.propTypes = {
  headline: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  moreText: PropTypes.string,
  example: PropTypes.string,
}

export default Accordion
