import React from 'react'
import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import TextInputField from '../../Inputs/TextInputField/TextInputField'
import DateInputField from '../../Inputs/DateInputField/DateInputField'
import TwoColumns from '../../ui-components/layouts/TwoColumns/TwoColumns'
import SelectField from '../../Selects/SelectField/SelectField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import nations from '../../../data/nations.json'

import './EmployeeInformationsForm.scss'
import RadioInputField from '../../Inputs/RadioInputField/RadioInputField'
import { STAFF_FORMS } from '../../../constants/Formulars'

interface Props {
  firstname: string
  lastname: string
  street: string
  houseNumber: string
  zipCode: string
  city: string
  birthdate: string[]
  sex: string
  mariatialStatus: string
  formularName: string
  country: string
  matriculation: string
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const EmployeeInformationsForm = (props: Props) => {
  const {
    formularName,
    country,
    firstname,
    lastname,
    street,
    houseNumber,
    zipCode,
    city,
    birthdate,
    sex,
    mariatialStatus,
    matriculation,
    onChange,
    onSubmit,
    onHelperButtonClick,
  } = props

  const renderCountryField = () => {
    if (formularName === STAFF_FORMS.FOREIGN_EMPLOYEE) {
      return (
        <SelectField
          label="Land"
          name="country"
          options={nations.nations}
          defaultValue={country}
          onChange={onChange}
          required
          noDefault
        />
      )
    }
  }

  const renderMatriculationField = () => {
    if (formularName === STAFF_FORMS.STUDENT) {
      return (
        <RadioInputField
          label="Ist eine Immatrikulationsbescheinigung vorhanden?"
          name="matriculation"
          radioOptions={['Nein', 'Ja']}
          defaultChecked={matriculation === 'Nein' ? 0 : 1}
          onChange={onChange}
          required
        />
      )
    }
  }

  return (
    <FormContainer className={'employee-informations-form'} onSubmit={onSubmit}>
      <HelperButton onClick={onHelperButtonClick} />
      <FormHeader headlineText={'Persönliche Angaben'} />
      <FormBody>
        <TwoColumns layout="1-1">
          <TextInputField
            label={'Vorname'}
            name={'firstname'}
            type={'text'}
            placeholder={'Marie'}
            required={true}
            defaultValue={firstname}
            onChange={onChange}
            autoFocus
          />
          <TextInputField
            label={'Nachname'}
            name={'lastname'}
            type={'text'}
            placeholder={'Muster'}
            required={true}
            defaultValue={lastname}
            onChange={onChange}
          />

          <TextInputField
            defaultValue={street}
            label={'Straße'}
            name={'street'}
            onChange={onChange}
            placeholder={'Musterstraße'}
            required
            type={'text'}
          />
          <TextInputField
            defaultValue={houseNumber}
            label={'Hausnummer'}
            name={'houseNumber'}
            onChange={onChange}
            placeholder={'27'}
            required
            type={'text'}
            pattern="[a-zA-Z0-9-/]+"
            title="Hausnummer z.B. 10, 21b oder 3-7"
          />

          <TextInputField
            defaultValue={zipCode}
            label={'Postleitzahl'}
            name={'zipCode'}
            onChange={onChange}
            placeholder="45678"
            pattern="[0-9]{4,6}"
            required
            type={'text'}
          />
          <TextInputField
            defaultValue={city}
            label={'Stadt'}
            name={'city'}
            onChange={onChange}
            placeholder={'Musterstadt'}
            required
            type={'text'}
            pattern="[^\/\\\+!.,;:_—–#?=()&%$§!\^°*]+"
            title="Bitte verwenden Sie keine Sonderzeichen"
          />

          {renderCountryField()}

          <DateInputField
            label={'Geburtsdatum'}
            names={['birthday', 'birthmonth', 'birthyear']}
            onChange={onChange}
            placeholder={['TT', 'MM', 'JJJJ']}
            maxLength={['2', '2', '4']}
            max={['31', '12', new Date().getFullYear() - 13]}
            min={['1', '1', '1900']}
            pattern="\d"
            required
            defaultValue={birthdate}
          />
          <SelectField
            label={'Geschlecht'}
            name={'sex'}
            defaultValue={sex}
            onChange={onChange}
            required
            options={['Weiblich', 'Männlich', 'Divers', 'Unbestimmt']}
          />

          <SelectField
            label={'Familienstand'}
            name={'mariatialStatus'}
            defaultValue={mariatialStatus}
            onChange={onChange}
            required
            options={[
              'Ledig',
              'Verheiratet',
              'Geschieden',
              'Verwitwet',
              'Dauernd getrennt lebend',
              'Lebenspartnerschaft eingetragen',
              'Lebenspartnerschaft aufgehoben',
            ]}
            noDefault
          />
        </TwoColumns>
        {renderMatriculationField()}
      </FormBody>
      <FormFooter primaryButtonText={'Weiter'} />
    </FormContainer>
  )
}

export default EmployeeInformationsForm
