import React, { Fragment, useEffect, useState } from 'react'
import { detect } from 'detect-browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import employeeFormValues from 'helpers/autofill/studentForm/employeeFormValues'

import FormStage from 'components/Stages/FormStage/FormStage'
import EmployeeInformationsForm from 'components/Forms/EmployeeInformationsForm/EmployeeInformationsForm'
import EducationForm from 'components/Forms/EducationForm/EducationForm'
import BeginningStatusForm from 'components/Forms/BeginningStatusForm/BeginningStatusForm'
import InsuranceInformationForm from 'components/Forms/InsuranceInformationForm/InsuranceInformationForm'
import InsuranceDataForm from 'components/Forms/InsuranceDataForm/InsuranceDataForm'
import PrivateBankInformationForm from 'components/Forms/PrivateBankInformationForm/PrivateBankInformationForm'
import FurtherEmploymentsForm from 'components/Forms/FurtherEmploymentsForm/FurtherEmploymentsForm'
import FinalDeclarationsForm from 'components/Forms/FinalDeclarationsForm/FinalDeclarationsForm'
import SuccsessForm from 'components/Forms/SuccessForm/SuccessForm'
import SummaryFormEmployee from 'components/Forms/SummaryForm/SummaryFormEmployee'
import Overlay from 'components/Overlays/Overlay/Overlay'
import HelpOverForStudentForm from 'components/Overlays/HelpOver/fastdocs-staff/HelpOverForStudentForm/HelpOverForStudentForm'
import NotificationCard from 'components/Notifications/NotificationCard/NotificationCard'
import { ReactComponent as InfoIcon } from 'static/InformationIcon.svg'
import {
  getFormStep,
  getProgressValue,
  getFormId,
  setDocumentTitleForEmployeeFormViews,
} from 'views/utility/utility.jsx'
import AutofillButton from 'helpers/autofill/AutofillButton'
import saveFormValuesInLocalStorage from 'views/utility/saveFormValuesInLocalStorage'
import clearFormValuesFromLocalStorage from 'views/utility/clearFormValuesFromLocalStorage'
import ParentsPropertyForm from 'components/Forms/ParentsPropertyForm/ParentsPropertyForm'
import initialFormValues from './initialFormValues'
import { STAFF_FORMS } from 'constants/Formulars'

const database = firebase.database()

const EmployeeViewForStudentForm = () => {
  const [formId] = useState(getFormId())
  const [capitalAccumulation, setCapitalAccumulation] = useState('')
  const [payingOption, setPayingOption] = useState('')
  const [formWasSubmittedBefore, setFormWasSubmittedBefore] = useState(false)
  const [formIdExist, setFormIdExist] = useState(true)
  const [formularName] = useState(STAFF_FORMS.STUDENT)
  const [formStep, setFormStep] = useState(0)
  const totalFormSteps = 10
  const [helpover, setHelpover] = useState(false)
  const [formValues, setFormValues] = useState(initialFormValues(formularName))

  setDocumentTitleForEmployeeFormViews(formStep, totalFormSteps, formularName)

  const closeEdgeNotification = () => {
    const notificationCard =
      document.querySelector<HTMLElement>('.notification-card')
    if (notificationCard) {
      notificationCard.style.display = 'none'
    }
  }

  const showEdgeNotification = () => {
    const browser = detect()

    if (browser && browser.name === 'edge') {
      return (
        <NotificationCard
          icon={<InfoIcon />}
          title="Wichtiger Hinweis:"
          text="Dein Browser erwartet bei den Nachkommastellen eventuell einen Punkt statt eines Kommas."
          onClick={closeEdgeNotification}
        />
      )
    }
  }

  const setCapitalAccumulationValueSuggestion = () => {
    if (capitalAccumulation === 'Ja') {
      setFormValues({
        ...formValues,
        capitalAccumulationValue: '40.00',
      })
    }
  }

  useEffect(() => {
    const previousTypeOfEmployment =
      formValues.typeOfEmployment === 'Minijob bis 450€'
        ? 'Beschäftigung über 450€'
        : 'Minijob bis 450€'

    if (formValues.birthday.length === 1) {
      setFormValues({
        ...formValues,
        birthday: `0${formValues.birthday}`,
      })
    }
    if (formValues.birthmonth.length === 1) {
      setFormValues({
        ...formValues,
        birthmonth: `0${formValues.birthmonth}`,
      })
    }
    if (previousTypeOfEmployment !== formValues.typeOfEmployment) {
      setFormValues({
        ...formValues,
        additionalMiniJob: 'Nein',
        additionalEmployement: 'Nein',
      })
    }
    // eslint-disable-next-line
  }, [formValues.birthday, formValues.birthmonth, formValues.typeOfEmployment])

  useEffect(() => {
    database
      .ref(`jobs/${formId}`)
      .child('clientData')
      .child('capitalAccumulation')
      .once('value')
      .then((snapshot) => setCapitalAccumulation(snapshot.val()))
      .then(() => setCapitalAccumulationValueSuggestion())

    database
      .ref(`jobs/${formId}`)
      .child('employeeData')
      .child('formWasSubmittedBefore')
      .once('value')
      .then((snapshot) => setFormWasSubmittedBefore(snapshot.val()))

    database
      .ref(`jobs/${formId}`)
      .child('clientData')
      .child('payingOption')
      .once('value')
      .then((snapshot) => setPayingOption(snapshot.val()))

    database
      .ref(`jobs/${formId}`)
      .child('clientData')
      .child('company')
      .once('value')
      .then((snapshot) => {
        if (snapshot.exists()) {
          return setFormIdExist(snapshot.val() ? true : false)
        }
        return setFormIdExist(false)
      })
      .catch(() => {
        return setFormIdExist(false)
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    database
      .ref(`jobs/${formId}`)
      .child('clientData')
      .once('value', (snapshot) => {
        const { firstname, lastname } = snapshot.val()
        setFormValues({
          ...formValues,
          firstname,
          lastname,
        })
      })

    // eslint-disable-next-line
  }, [])

  const renderNextFormStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFormStep(formStep + 1)

    saveFormValuesInLocalStorage({ ...formValues, formularName })
  }

  const renderPreviousFormStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFormStep(formStep - 1)
  }

  const handleInsuranceNumberChange = () => {
    setFormValues({
      ...formValues,
      insuranceNumber: !formValues.insuranceNumber,
    })
  }

  const handleFurtherEmploymentsChange = () => {
    setFormValues({
      ...formValues,
      furtherEmployments: !formValues.furtherEmployments,
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name || e.currentTarget.name]: e.target.value,
    })
  }

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    e.preventDefault()

    database
      .ref('jobs/' + formId + '/employeeData')
      .set({
        ...formValues,
        formWasSubmittedBefore: true,
      })
      .then(() => {
        renderNextFormStep(e)
        clearFormValuesFromLocalStorage(formValues)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const renderHelpOver = () => {
    const handleHelpOverClose = () => {
      setHelpover(false)
    }

    return (
      <HelpOverForStudentForm
        formStep={formStep}
        onCloseButtonClick={handleHelpOverClose}
        className={helpover === true ? 'show' : ''}
        formId={formId}
      />
    )
  }

  const handleSuggestionClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFormValues({
      ...formValues,
      insuranceCompanyName: e.target.value,
    })
  }

  const handleTaxClassChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'II - Steuerklasse Zwei') {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
        parentsProperty: 'Ja',
      })
    } else {
      setFormValues({
        ...formValues,
        [e.target.name]: e.target.value,
        parentsProperty: 'Nein',
      })
    }
  }

  function handleHelperButtonClick(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    setHelpover(true)
  }

  useEffect(() => {
    if (!formValues.insuranceNumber) {
      setFormValues({ ...formValues, socialSecurityNumber: '' })
    }
    // eslint-disable-next-line
  }, [formValues.insuranceNumber])

  const renderFormSteps = () => {
    switch (formStep) {
      case 0:
        return (
          <EmployeeInformationsForm
            onSubmit={renderNextFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={(e) => handleHelperButtonClick(e)}
            firstname={formValues.firstname}
            lastname={formValues.lastname}
            street={formValues.street}
            houseNumber={formValues.houseNumber}
            zipCode={formValues.zipCode}
            city={formValues.city}
            birthdate={[
              formValues.birthday,
              formValues.birthmonth,
              formValues.birthyear,
            ]}
            sex={formValues.sex}
            mariatialStatus={formValues.mariatialStatus}
            country=""
            formularName={formularName}
            matriculation={formValues.matriculation}
          />
        )
      case 1:
        return (
          <EducationForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleHelperButtonClick(e)
            }
            graduation={formValues.graduation}
            education={formValues.education}
          />
        )
      case 2:
        return (
          <BeginningStatusForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            beginningStatus={formValues.beginningStatus}
            taxIdNumber={formValues.taxIdNumber}
            onHelperButtonClick={(e) => handleHelperButtonClick(e)}
            activityStatus={formValues.activityStatus}
            formularName={formularName}
            taxClass={formValues.taxClass}
            onTaxClassChange={handleTaxClassChange}
            denomination={formValues.denomination}
            mariatialStatus={formValues.mariatialStatus}
          />
        )
      case 3:
        return (
          <InsuranceDataForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={(e) => handleHelperButtonClick(e)}
            insuranceType={formValues.insuranceType}
            insuranceCompanyName={formValues.insuranceCompanyName}
            onSuggestionClick={handleSuggestionClick}
            formularName={formularName}
          />
        )
      case 4:
        return (
          <InsuranceInformationForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            handleInsuranceNumberChange={handleInsuranceNumberChange}
            insuranceNumber={formValues.insuranceNumber}
            socialSecurityNumber={formValues.socialSecurityNumber}
            placeOfBirth={formValues.placeOfBirth}
            nationality={formValues.nationality}
            birthName={formValues.birthName}
            birthday={formValues.birthday}
            birthmonth={formValues.birthmonth}
            birthyear={formValues.birthyear}
            birthCountry={formValues.birthCountry}
            onHelperButtonClick={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleHelperButtonClick(e)
            }
          />
        )
      case 5:
        return (
          <ParentsPropertyForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleHelperButtonClick(e)
            }
            parentsProperty={formValues.parentsProperty}
            amountOfChildren={formValues.amountOfChildren}
            taxClass={formValues.taxClass}
            singleParent={formValues.singleParent}
          />
        )
      case 6:
        return (
          <PrivateBankInformationForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleHelperButtonClick(e)
            }
            bankAccountHolder={formValues.bankAccountHolder}
            iban={formValues.iban}
            capitalAccumulation={capitalAccumulation}
            capitalAccumulationBankAccountHolder={
              formValues.capitalAccumulationBankAccountHolder
            }
            capitalAccumulationIban={formValues.capitalAccumulationIban}
            contractNumber={formValues.contractNumber}
            capitalAccumulationValue={formValues.capitalAccumulationValue}
            capitalAccumulationQuestion={formValues.capitalAccumulationQuestion}
            payingOption={payingOption}
          />
        )
      case 7:
        return (
          <FurtherEmploymentsForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            handleFurtherEmploymentsChange={handleFurtherEmploymentsChange}
            onHelperButtonClick={(e) => handleHelperButtonClick(e)}
            furtherEmployments={formValues.furtherEmployments}
            furtherEmploymentCompanyName={
              formValues.furtherEmploymentCompanyName
            }
            furtherEmploymentsTotalSallary={
              formValues.furtherEmploymentsTotalSallary
            }
            typeOfEmployment={formValues.typeOfEmployment}
            additionalMiniJob={formValues.additionalMiniJob}
            activityStatus={formValues.activityStatus}
            formularName={formularName}
            payingPensionInsurance=""
            furtherMinijobEntryDay=""
            furtherMinijobEntryMonth=""
            furtherMinijobEntryYear=""
            additionalEmployement=""
            totalWeeklyWorkingTimeLimitForStudents={
              formValues.totalWeeklyWorkingTimeLimitForStudents
            }
            operationTimeIsExercisedForMoreThan26WeeksPerYear={
              formValues.operationTimeIsExercisedForMoreThan26WeeksPerYear
            }
            operationTimeForStudents={formValues.operationTimeForStudents}
            operationTimeIsLimitedToSemesterBreaks={
              formValues.operationTimeIsLimitedToSemesterBreaks
            }
          />
        )
      case 8:
        return (
          <SummaryFormEmployee
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onHelperButtonClick={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleHelperButtonClick(e)
            }
            formularName={formularName}
            firstname={formValues.firstname}
            pensionInsuranceRequest={formValues.pensionInsuranceRequest}
            lastname={formValues.lastname}
            street={formValues.street}
            houseNumber={formValues.houseNumber}
            zipCode={formValues.zipCode}
            city={formValues.city}
            birthday={formValues.birthday}
            birthmonth={formValues.birthmonth}
            birthyear={formValues.birthyear}
            sex={formValues.sex}
            taxIdNumber={formValues.taxIdNumber}
            graduation={formValues.graduation}
            education={formValues.education}
            beginningStatus={formValues.beginningStatus}
            insuranceType={formValues.insuranceType}
            insuranceCompanyName={formValues.insuranceCompanyName}
            insuranceNumber={formValues.insuranceNumber}
            socialSecurityNumber={formValues.socialSecurityNumber}
            birthName={formValues.birthName}
            placeOfBirth={formValues.placeOfBirth}
            birthCountry={formValues.birthCountry}
            nationality={formValues.nationality}
            bankAccountHolder={formValues.bankAccountHolder}
            iban={formValues.iban}
            capitalAccumulationBankAccountHolder={
              formValues.capitalAccumulationBankAccountHolder
            }
            capitalAccumulationIban={formValues.capitalAccumulationIban}
            contractNumber={formValues.contractNumber}
            furtherEmployments={formValues.furtherEmployments}
            typeOfEmployment={formValues.typeOfEmployment}
            furtherEmploymentCompanyName={
              formValues.furtherEmploymentCompanyName
            }
            furtherEmploymentsTotalSallary={
              formValues.furtherEmploymentsTotalSallary
            }
            capitalAccumulationValue={formValues.capitalAccumulationValue}
            mariatialStatus={formValues.mariatialStatus}
            payingPensionInsurance={formValues.payingPensionInsurance}
            furtherMinijobEntryDay={formValues.furtherMinijobEntryDay}
            furtherMinijobEntryMonth={formValues.furtherMinijobEntryMonth}
            furtherMinijobEntryYear={formValues.furtherMinijobEntryYear}
            activityStatus={formValues.activityStatus}
            taxClass={formValues.taxClass}
            denomination={formValues.denomination}
            parentsProperty={formValues.parentsProperty}
            amountOfChildren={formValues.amountOfChildren}
            matriculation={formValues.matriculation}
            totalWeeklyWorkingTimeLimitForStudents={
              formValues.totalWeeklyWorkingTimeLimitForStudents
            }
            operationTimeForStudents={formValues.operationTimeForStudents}
            operationTimeIsLimitedToSemesterBreaks={
              formValues.operationTimeIsLimitedToSemesterBreaks
            }
            operationTimeIsExercisedForMoreThan26WeeksPerYear={
              formValues.operationTimeIsExercisedForMoreThan26WeeksPerYear
            }
            singleParent={formValues.singleParent}
          />
        )
      case 9:
        return (
          <FinalDeclarationsForm
            onSubmit={handleSubmit}
            onSecondaryButtonClick={renderPreviousFormStep}
            onHelperButtonClick={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleHelperButtonClick(e)
            }
          />
        )
      default:
        return (
          <SuccsessForm
            successText={`Super ${formValues.firstname}, alles hat geklappt!`}
            formId={formId}
          >
            <p>
              Wenn die Steuerkanzlei deines Arbeitgebers alles verarbeitet hat,
              bekommst Du automatisch ein PDF von uns für deine Unterlagen.
              Dieses PDF musst Du für deinen Arbeitgeber noch unterschreiben und
              an ihn weiter geben.
            </p>
            <p>
              Auf Wiedersehen{' '}
              <span role="img" aria-label="Winkende-Hand">
                👋
              </span>
              .
            </p>
          </SuccsessForm>
        )
    }
  }

  return (
    <Fragment>
      <FormStage
        headlineText="Formular für Werkstudenten"
        totalSteps={totalFormSteps}
        actualStep={getFormStep(formStep, totalFormSteps)}
        progress={getProgressValue(formStep, totalFormSteps)}
      />

      {renderFormSteps()}

      {formId === null ? (
        <Overlay
          headerText="Fehler"
          headlineText="Falsche oder keine Formular-ID gefunden"
          text="Du hast eine falsche, oder keine Formular-ID. Ohne Diese ID können wir Deone Eingaben nicht richtig zuordnen."
          additionalText="Vergewisser Dich bitte, ob Du den erhaltenen Link vollständig und richtig in den Browser übernnommen hast."
          type="error"
        />
      ) : null}

      {formWasSubmittedBefore === true ? (
        <Overlay
          headerText="Achtung"
          headlineText="Formular bereits ausgefüllt"
          text="Du hast das Formular bereits ausgefüllt und abgeschickt. Du kannst deine Angaben nachträglich nicht noch mal ändern."
          additionalText="Sollte dir ein Fehler unterlaufen sein, sprich bitte kurz mit deinem Arbeitgeber."
        />
      ) : null}

      {formIdExist === false ? (
        <Overlay
          type="error"
          headerText="Achtung"
          headlineText="Ungültige oder falsche Formular-ID"
          text="Für dieses Formular gibt es keine passende Formular-ID, die wir in unseren Systemen finden können."
        />
      ) : null}

      {renderHelpOver()}

      {showEdgeNotification()}

      <AutofillButton
        onClick={() =>
          setFormValues({
            ...formValues,
            ...employeeFormValues,
          })
        }
      />
    </Fragment>
  )
}

export default EmployeeViewForStudentForm
