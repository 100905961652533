import React from 'react'
import { detect } from 'detect-browser'
import NotificationCard from '../components/Notifications/NotificationCard/NotificationCard'
import { ReactComponent as InfoIcon } from '../static/InformationIcon.svg'

type EdgeNotification = JSX.Element | null

const useEdgeNotification = (): EdgeNotification => {
  const browser = detect()

  const hideNotification = (): void => {
    const notificationCard =
      document.querySelector<HTMLElement>('.notification-card')

    if (notificationCard) {
      notificationCard.style.display = 'none'
    }
  }

  if (browser?.name === 'edge') {
    return (
      <NotificationCard
        icon={<InfoIcon />}
        title="Wichtiger Hinweis:"
        text="Dein Browser erwartet bei den Nachkommastellen eventuell einen Punkt statt eines Kommas."
        onClick={hideNotification}
      />
    )
  }

  return null
}

export default useEdgeNotification
