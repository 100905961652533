import React, { ChangeEvent, Fragment } from 'react'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import TextInputField from '../../Inputs/TextInputField/TextInputField'
import TwoColumns from '../../ui-components/layouts/TwoColumns/TwoColumns'
import SelectField from '../../Selects/SelectField/SelectField'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import { validateSocialSecurityNumber } from './utility/socialSecurityNumberValidation'
import countries from '../../../data/countries.json'

import './AdditionalInsuranceInformationForm.scss'

// ATTANTION: THIS FORM IS USED AS A SPECIAL STEP IN THE IMMEDIATE REPORT FORMULAR.
// This is only used as a workaround, because of an importing bug in Lohn & Gehalt.
// The bug causes every time further address information of the employee.

interface Props {
  taxConsultantImportSystem: string
  street: string
  houseNumber: string
  zipCode: string
  city: string
  insuranceNumber: boolean
  birthName: string
  placeOfBirth: string
  birthCountry: string
  socialSecurityNumber: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: ChangeEvent<HTMLInputElement>) => void
  onSecondaryButtonClick: (e: ChangeEvent<HTMLInputElement>) => void
}

const AdditionalInsuranceInformationForm = (props: Props) => {
  const renderFormFields = () => {
    if (props.taxConsultantImportSystem === 'lug') {
      return (
        <Fragment>
          <h5>Anschrift Arbeitnehmer:in</h5>
          <TwoColumns layout={'1-1'}>
            <TextInputField
              defaultValue={props.street}
              label={'Straße'}
              name={'street'}
              onChange={props.onChange}
              placeholder={'Musterstraße'}
              required
              type={'text'}
            />
            <TextInputField
              defaultValue={props.houseNumber}
              label={'Hausnummer'}
              name={'houseNumber'}
              onChange={props.onChange}
              placeholder={'27'}
              required
              type={'text'}
              pattern="[a-zA-Z0-9-]+"
              title="Hausnummer z.B. 10, 21b oder 3-7"
            />
          </TwoColumns>
          <TwoColumns layout={'1-1'}>
            <TextInputField
              defaultValue={props.zipCode}
              label={'Postleitzahl'}
              name={'zipCode'}
              onChange={props.onChange}
              placeholder="45678"
              pattern="[0-9]{4,6}"
              required
              type={'text'}
            />
            <TextInputField
              defaultValue={props.city}
              label={'Stadt'}
              name={'city'}
              onChange={props.onChange}
              placeholder={'Musterstadt'}
              required
              type={'text'}
              pattern="[^\/\\\+!.,;:_—–#?=()&%$§!\^°*]+"
              title="Bitte verwenden Sie keine Sonderzeichen"
            />
          </TwoColumns>

          {!props.insuranceNumber ? (
            <Fragment>
              <h5>Geburtsdaten Arbeitnehmer:in</h5>
              <TwoColumns layout={'1-1'}>
                <TextInputField
                  label="Geburtsname"
                  placeholder="Meier"
                  name="birthName"
                  defaultValue={props.birthName}
                  onChange={props.onChange}
                  required
                  pattern=".*[\w].+"
                />
                <TextInputField
                  label="Geburtsort"
                  placeholder="Musterstadt"
                  name="placeOfBirth"
                  defaultValue={props.placeOfBirth}
                  onChange={props.onChange}
                  required
                  pattern="[^\/\\\+!.,;:_—–#?=()&%$§!\^°*]+"
                  title="Bitte verwenden Sie keine Sonderzeichen"
                />
              </TwoColumns>
              <TwoColumns layout="1-1">
                <SelectField
                  label="Geburtsland"
                  name="birthCountry"
                  options={countries.countries}
                  defaultValue={props.birthCountry}
                  onChange={props.onChange}
                  required
                  noDefault
                />
              </TwoColumns>
            </Fragment>
          ) : null}
        </Fragment>
      )
    }
    if (
      props.taxConsultantImportSystem === 'lodas' &&
      props.insuranceNumber === false
    ) {
      return (
        <Fragment>
          <h5>Anschrift Arbeitnehmer:in</h5>
          <TwoColumns layout={'1-1'}>
            <TextInputField
              defaultValue={props.street}
              label={'Straße'}
              name={'street'}
              onChange={props.onChange}
              placeholder={'Musterstraße'}
              required
              type={'text'}
            />
            <TextInputField
              defaultValue={props.houseNumber}
              label={'Hausnummer'}
              name={'houseNumber'}
              onChange={props.onChange}
              placeholder={'27'}
              required
              type={'text'}
              pattern="[a-zA-Z0-9-]+"
              title="Hausnummer z.B. 10, 21b oder 3-7"
            />
          </TwoColumns>
          <TwoColumns layout={'1-1'}>
            <TextInputField
              defaultValue={props.zipCode}
              label={'Postleitzahl'}
              name={'zipCode'}
              onChange={props.onChange}
              placeholder="45678"
              pattern="[0-9]{4,6}"
              required
              type={'text'}
            />
            <TextInputField
              defaultValue={props.city}
              label={'Stadt'}
              name={'city'}
              onChange={props.onChange}
              placeholder={'Musterstadt'}
              required
              type={'text'}
              pattern="[^\/\\\+!.,;:_—–#?=()&%$§!\^°*]+"
              title="Bitte verwenden Sie keine Sonderzeichen"
            />
          </TwoColumns>

          <h5>Geburtsdaten Arbeitnehmer:in</h5>
          <TwoColumns layout={'1-1'}>
            <TextInputField
              label="Geburtsname"
              placeholder="Meier"
              name="birthName"
              defaultValue={props.birthName}
              onChange={props.onChange}
              required
              pattern=".*[\w].+"
            />
            <TextInputField
              label="Geburtsort"
              placeholder="Musterstadt"
              name="placeOfBirth"
              defaultValue={props.placeOfBirth}
              onChange={props.onChange}
              required
              pattern="[^\/\\\+!.,;:_—–#?=()&%$§!\^°*]+"
              title="Bitte verwenden Sie keine Sonderzeichen"
            />
          </TwoColumns>
          <TwoColumns layout="1-1">
            <SelectField
              label="Geburtsland"
              name="birthCountry"
              options={countries.countries}
              defaultValue={props.birthCountry}
              onChange={props.onChange}
              required
              noDefault
            />
          </TwoColumns>
        </Fragment>
      )
    }
    return (
      <p>
        Da du die Sozialversicherungsnummer kennst und eingegeben hast,
        benötiger wir hier keine weiteren Angaben. Du kannst einfach zum
        nächsten Schritt weiter gehen.
      </p>
    )
  }

  const disableButton = (e: ChangeEvent<HTMLButtonElement>) => {
    if (props.socialSecurityNumber.length <= 0) {
      return null
    }
    if (validateSocialSecurityNumber(props.socialSecurityNumber) !== true) {
      e.preventDefault()
      e.target.blur()
    }
    return null
  }

  return (
    <FormContainer
      className={'additional-insurance-informations-form'}
      onSubmit={props.onSubmit}
    >
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Weitere Angaben zur Versicherung'} />
      <FormBody>{renderFormFields()}</FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
        onClick={disableButton}
      />
    </FormContainer>
  )
}

export default AdditionalInsuranceInformationForm
