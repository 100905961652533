import React from 'react'

import Accordion from '../../../../ui-components/accordions/Accordion/Accordion'
import helpOverContentForClients from './helpOverContentForClients.json'
import helpOverContentForEmployees from './helpOverContentForEmployees.json'
import HelpOverBase from '../../HelpOverBase/HelpOverBase'

const HelpOverForStaffForm = (props) => {
  if (window.location.pathname.includes('client')) {
    switch (props) {
      case 0:
        return helpOverContentForClients[0].unternehmensdaten.map(
          (accordion, i) => (
            <Accordion
              formStep={props}
              headline={accordion.headline}
              url={accordion.url}
              urlText={accordion.urlText}
              text={accordion.text}
              example={accordion.example}
              key={i}
            />
          )
        )
      case 1:
        return helpOverContentForClients[0].mitarbeiterInformationen.map(
          (accordion, i) => (
            <Accordion
              formStep={props}
              headline={accordion.headline}
              url={accordion.url}
              urlText={accordion.urlText}
              text={accordion.text}
              example={accordion.example}
              key={i}
            />
          )
        )
      case 2:
        return helpOverContentForClients[0].gehalt.map((accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        ))
      case 3:
        return helpOverContentForClients[0].arbeitszeiten.map(
          (accordion, i) => (
            <Accordion
              formStep={props}
              headline={accordion.headline}
              url={accordion.url}
              urlText={accordion.urlText}
              text={accordion.text}
              example={accordion.example}
              key={i}
            />
          )
        )
      case 4:
        return helpOverContentForClients[0].vwl.map((accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        ))
      default:
        return (
          <div className="empty-hint">
            <h5>
              <span role="img" aria-label="Illustration">
                🤘
              </span>
              <br />
              Hier brauchst Du keine Hilfe
            </h5>
          </div>
        )
    }
  }
  switch (props) {
    case 0:
      return helpOverContentForEmployees[0].persoehnlicheAngaben.map(
        (accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 1:
      return helpOverContentForEmployees[0].ausbildung.map((accordion, i) => (
        <Accordion
          formStep={props}
          headline={accordion.headline}
          url={accordion.url}
          urlText={accordion.urlText}
          text={accordion.text}
          example={accordion.example}
          key={i}
        />
      ))
    case 2:
      return helpOverContentForEmployees[0].infosZurBeschaeftigung.map(
        (accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 3:
      return helpOverContentForEmployees[0].krankenversicherung.map(
        (accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 4:
      return helpOverContentForEmployees[0].angabenZurKrankenversicherung.map(
        (accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 5:
      return helpOverContentForEmployees[0].elterneigenschaft.map(
        (accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 6:
      return helpOverContentForEmployees[0].bankverbindungen.map(
        (accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    case 7:
      return helpOverContentForEmployees[0].weitereBeschaeftigungen.map(
        (accordion, i) => (
          <Accordion
            formStep={props}
            headline={accordion.headline}
            url={accordion.url}
            urlText={accordion.urlText}
            text={accordion.text}
            example={accordion.example}
            key={i}
          />
        )
      )
    default:
      return (
        <div className="empty-hint">
          <h5>
            <span role="img" aria-label="Illustration">
              🤘
            </span>
            <br />
            Hier brauchst Du keine Hilfe
          </h5>
        </div>
      )
  }
}

class HelpOverForMinijobberForm extends React.Component {
  render() {
    return (
      <HelpOverBase
        className={this.props.className}
        onCloseButtonClick={this.props.onCloseButtonClick}
        formId={this.props.formId}
      >
        {HelpOverForStaffForm(this.props.formStep)}
      </HelpOverBase>
    )
  }
}

export default HelpOverForMinijobberForm
