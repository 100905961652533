import moment from 'moment'

type Entrydate = string
type EmployeeAge = string | undefined

const setMinimumWage = (entrydate: Entrydate, employeeAge: EmployeeAge) => {
  function employeeAgeIsOver18() {
    if (employeeAge === '18 Jahre oder älter' || employeeAge === undefined) {
      return true
    }
    return false
  }

  if (!employeeAgeIsOver18()) {
    return 1.0
  }

  if (employeeAgeIsOver18()) {
    if (moment(entrydate).isBefore('2021-07-01')) return 9.5
    if (moment(entrydate).isBefore('2021-12-31')) return 9.6
    if (moment(entrydate).isBefore('2022-07-01')) return 9.82
    if (moment(entrydate).isBefore('2022-12-31')) return 10.45
  }

  return 1.0
}

export default setMinimumWage
