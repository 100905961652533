import React, { useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import { getTaxConsultant } from '../../utility/utility'

import RadioInputField from '../../../components/Inputs/RadioInputField/RadioInputField'

import './StaffFormOptionsView.scss'

interface Props {
  data: {
    features: {
      costCenter: boolean
      staffNumber: boolean
    }
    contracts: {
      client: { contract: boolean; contractOption: string }
      staff: { contract: boolean; contractOption: string }
    }
  }
}

const StaffFormOptionsView = (props: Props) => {
  const [staffNumberSavedHint, setStaffNumberSavedHint] = useState(false)
  const [costCenterSavedHint, setCostCenterSavedHint] = useState(false)

  const handleStaffNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'Anzeigen' ? true : false
    const ref = firebase
      .database()
      .ref(`taxConsultant/${getTaxConsultant()}/features`)

    if (value) {
      return ref
        .update({ staffNumber: true })
        .then(() => {
          setStaffNumberSavedHint(true)

          setTimeout(() => {
            setStaffNumberSavedHint(false)
          }, 1000)
        })
        .catch((error) => alert(error))
    }
    return ref
      .update({ staffNumber: false })
      .then(() => {
        setStaffNumberSavedHint(true)

        setTimeout(() => {
          setStaffNumberSavedHint(false)
        }, 1000)
      })
      .catch((error) => alert(error))
  }

  const handleCostCenterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'Anzeigen' ? true : false
    const ref = firebase
      .database()
      .ref(`taxConsultant/${getTaxConsultant()}/features`)

    if (value) {
      return ref
        .update({ costCenter: true })
        .then(() => {
          setCostCenterSavedHint(true)

          setTimeout(() => {
            setCostCenterSavedHint(false)
          }, 1000)
        })
        .catch((error) => alert(error))
    }
    return ref
      .update({ costCenter: false })
      .then(() => {
        setCostCenterSavedHint(true)

        setTimeout(() => {
          setCostCenterSavedHint(false)
        }, 1000)
      })
      .catch((error) => alert(error))
  }

  const allowUserToEdit = () => {
    if (
      props.data.contracts.staff.contract &&
      props.data.contracts.staff.contractOption === 'pro'
    ) {
      return true
    }
    return false
  }

  return (
    <div className="form-options-details">
      <header>
        <h4>Formularoptionen</h4>
        <p>
          Welche Felder sollen MandantInnen optional zur Verfügung gestellt
          werden?
        </p>
      </header>

      <main>
        {allowUserToEdit() ? (
          <>
            <section>
              <div className="input-container">
                <RadioInputField
                  label="Personalnummer"
                  name="staffNumber"
                  radioOptions={['Nicht anzeigen', 'Anzeigen']}
                  defaultChecked={props.data.features.staffNumber ? 1 : 0}
                  onChange={(e) => handleStaffNumberChange(e)}
                  key="staffNumber"
                />
                {staffNumberSavedHint ? (
                  <span className="saved-hint">Gespeichert</span>
                ) : null}
              </div>
              <small>
                MandantInnen erhalten ein optionales Feld, in dem sie selbst
                eine Personalnummer hinterlegen können.
              </small>
            </section>

            <section>
              <div className="input-container">
                <RadioInputField
                  label="Kostenstelle"
                  name="costCenter"
                  radioOptions={['Nicht anzeigen', 'Anzeigen']}
                  defaultChecked={props.data.features.costCenter ? 1 : 0}
                  onChange={(e) => handleCostCenterChange(e)}
                  key="costCenter"
                />
                {costCenterSavedHint ? (
                  <span className="saved-hint">Gespeichert</span>
                ) : null}
              </div>
              <small>
                MandantInnen erhalten ein optionales Feld, in dem sie selbst
                eine Kostenstelle definieren können.
              </small>
            </section>
          </>
        ) : (
          <section>
            <p>
              Optionale Felder wie das Abfragen der Kostenstelle, oder die
              Vergabe einer Personalnummer sind nur im Fastdocs Staff Pro
              Vertrag verfügbar.
              <br />
              <br />
              <a
                href="https://www.fastdocs.de/fastdocs-staff-pricing.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mehr über Fastdocs Staff Pro erfahren
              </a>
              <br />
              <br />
              <a
                className="button secondary"
                href="mailto:sales@fastdocs.de?subject=Individualisierung für Fastdocs Account&body=Hallo liebes Sales-Team,"
              >
                Sales-Team kontaktieren
              </a>
            </p>
          </section>
        )}
      </main>
    </div>
  )
}

export default StaffFormOptionsView
