import React, { Fragment, useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import { v4 as uuidv4 } from 'uuid'

import FastdocsStaffLogo from '../../../static/fastdocs-staff-logo.svg'
import DashboardNavigation from '../../../components/Navigations/DashboardNavigation/DashboardNavigation'
import Table, {
  TableBody,
  TableData,
  TableHead,
  TableRow,
} from '../../../components/Table/Table'
import { getTaxConsultant } from '../../utility/utility'
import LoadingIndicator from '../../../components/ui-components/LoadingIndicator/LoadingIndicator'

import { ReactComponent as EmptyInbox } from '../../../static/icons/80x80/inbox-80.svg'
import { ReactComponent as NoUsers } from '../../../static/icons/80x80/users-icon-80.svg'

import './ClerksView.scss'
import ClerkDetailsDialog from '../../../components/Overlays/ClerkDetailsDialog/ClerkDetailsDialog'
import CreateClerkDialog from '../../../components/Overlays/CreateClerkDialog/CreateClerkDialog'

interface Props {
  staffContract: boolean
  staffContractOption: string
}

const ClerksView = (props: Props) => {
  document.title = 'Personaldaten :: Dashboard'

  const [data, setData] = useState([{}])
  const [loading, setLoading] = useState(true)

  const [updateDialogData, setUpdateDialogData] = useState([])
  const [updatedDialogData, setUpdatedDialogData] = useState({})

  const [showUpdateClerkDialog, setShowUpdateClerDialog] = useState(false)
  const [showCreateClerkDialog, setShowCreateClerkDialog] = useState(false)
  const [newClerkData, setNewClerkData] = useState({})

  const [containerAnimationClass, setContainerAnimationClass] = useState('')
  const [contentAnimationClass, setContentAnimationClass] = useState('')

  const [searchIsActive, setSearchIsActive] = useState(false)
  const [search, setSearch] = useState('')
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    const dbRef = `taxConsultant/${getTaxConsultant()}/clerks`

    firebase
      .database()
      .ref(dbRef)
      .on('value', (snapshot) => {
        if (snapshot.exists()) {
          setData([snapshot.val()])
          setLoading(false)
        } else {
          setData([])
          setLoading(false)
        }
      })

    return () => {
      firebase.database().ref(dbRef).off()
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (search) {
      setSearchIsActive(true)

      let result: any = []

      data.map((initialData) => {
        return Object.entries(initialData).map((entry: any) => {
          const searchValue = search
          const { email, name } = entry[1]
          const clerkId = entry[0]

          if (
            email.toLowerCase().includes(searchValue.toLowerCase()) ||
            name.toLowerCase().includes(searchValue.toLowerCase())
          ) {
            result.push({ [clerkId]: entry[1] })
          }

          return null
        })
      })

      setFilteredData(result)
    } else {
      setSearchIsActive(false)
      setFilteredData([])
    }

    // eslint-disable-next-line
  }, [search])

  const getRows = () => {
    return data.map((entry) => {
      return Object.entries(entry).map((entry: any, key) => {
        const clerkId = entry[0]
        const { email, name } = entry[1]

        return (
          <TableRow
            onClick={(e) => openUpdateDialog(e)}
            key={key}
            datakey={clerkId}
          >
            <TableData>{name}</TableData>
            <TableData>{email}</TableData>
          </TableRow>
        )
      })
    })
  }

  const getFilteredRows = () => {
    return filteredData.map((entry) => {
      return Object.entries(entry).map((entry: any, key) => {
        const clerkId = entry[0]
        const { email, name } = entry[1]

        return (
          <TableRow
            onClick={(e) => openUpdateDialog(e)}
            key={key}
            datakey={clerkId}
          >
            <TableData>{name}</TableData>
            <TableData>{email}</TableData>
          </TableRow>
        )
      })
    })
  }

  const renderTableData = () => {
    if (data.length === 0 && !loading) {
      return (
        <tbody>
          <tr className="empty">
            <td colSpan={20}>
              <NoUsers />
              <span>Aktuell sind keine SachbearbeiterInnen angelegt.</span>
            </td>
          </tr>
        </tbody>
      )
    }
    if (data.length !== 0 && !loading) {
      if (searchIsActive) {
        if (filteredData.length !== 0) {
          return <TableBody>{getFilteredRows()}</TableBody>
        }
        return (
          <tbody>
            <tr className="empty">
              <td colSpan={20}>
                <EmptyInbox />
                <span>Die Suche hat leider kein Ergebniss gebracht.</span>
              </td>
            </tr>
          </tbody>
        )
      }
      return <TableBody>{getRows()}</TableBody>
    }
    return (
      <tbody>
        <tr>
          <td colSpan={20}>
            <LoadingIndicator size="small" />
          </td>
        </tr>
      </tbody>
    )
  }

  const openUpdateDialog = (e: any) => {
    const target = e.target.parentElement.dataset.key

    return data.map((entry) => {
      return Object.entries(entry).map((entry: any) => {
        if (target === entry[0]) {
          setUpdateDialogData(entry)
          setShowUpdateClerDialog(true)
          setUpdatedDialogData(entry[1])
          setTimeout(() => {
            setContainerAnimationClass('fade')
            setContentAnimationClass('move')
          }, 100)
        }
        return null
      })
    })
  }

  const closeUpdateDialog = () => {
    setContentAnimationClass('')
    setContainerAnimationClass('')

    setTimeout(() => {
      setShowUpdateClerDialog(false)
      setUpdateDialogData([])
    }, 300)
  }

  const renderUpdateDialog = () => {
    if (showUpdateClerkDialog && updateDialogData) {
      const clerkId = updateDialogData[0]
      const data = updateDialogData[1]
      const { email, name } = data

      const deleteClerk = () => {
        firebase
          .database()
          .ref(`taxConsultant/${getTaxConsultant()}/clerks/${clerkId}`)
          .remove()
          .then(() => setSearchIsActive(false))
          .then(() => setSearch(''))
          .then(() => setFilteredData([]))
          .then(() => closeUpdateDialog())
      }

      const saveClerkChanges = () => {
        firebase
          .database()
          .ref(`taxConsultant/${getTaxConsultant()}/clerks/${clerkId}`)
          .update({ ...updatedDialogData })
          .then(() => setSearchIsActive(false))
          .then(() => setSearch(''))
          .then(() => setFilteredData([]))
          .then(() => closeUpdateDialog())
      }

      const handleClerkDataChange = (
        e: React.ChangeEvent<HTMLInputElement>
      ) => {
        setUpdatedDialogData({
          ...updatedDialogData,
          [e.target.name]: e.target.value,
        })
      }

      return (
        <ClerkDetailsDialog
          email={email}
          name={name}
          containerAnimation={containerAnimationClass}
          contentAnimation={contentAnimationClass}
          handleDeleteClerkClick={deleteClerk}
          handleIconButtonClick={() => closeUpdateDialog()}
          handleSaveClerkClick={saveClerkChanges}
          onChange={(e) => handleClerkDataChange(e)}
        />
      )
    }
  }

  const renderContent = () => {
    if (props.staffContract) {
      return (
        <Fragment>
          <Table
            onChange={(e) => setSearch(e.target.value)}
            defaultSearchValue={search}
            onResetClick={() => setSearch('')}
            primaryAction={() => openCreateClerkDialog()}
          >
            <TableHead headers={['Name', 'E-Mail-Adresse']} />
            {renderTableData()}
          </Table>
          {renderUpdateDialog()}
          {renderCreateClerkDialog()}
        </Fragment>
      )
    }
    return (
      <div className="card staff-advertisement">
        <img src={FastdocsStaffLogo} alt="Fastdocs Staff Logo" width="200" />
        <h5>
          Mit Fastdocs Staff können verschiedene SachbearbeiterInnen hinterlegt
          werden.
        </h5>
        <a
          className="button primary"
          href="https://www.fastdocs.de/staff"
          rel="noopener noreferrer"
          target="_blank"
        >
          Mehr erfahren
        </a>
      </div>
    )
  }

  const openCreateClerkDialog = () => {
    setShowCreateClerkDialog(true)
    setTimeout(() => {
      setContainerAnimationClass('fade')
      setContentAnimationClass('move')
    }, 100)
  }

  const closeCreateClerkDialog = () => {
    setContentAnimationClass('')
    setContainerAnimationClass('')

    setTimeout(() => {
      setShowCreateClerkDialog(false)
    }, 300)
  }

  const handleCreateClerkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewClerkData({
      ...newClerkData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSaveCreateClerk = () => {
    const id = uuidv4().split('-')[0]

    firebase
      .database()
      .ref(`taxConsultant/${getTaxConsultant()}/clerks`)
      .once('value', (snapshot) => {
        if (snapshot.exists()) {
          firebase
            .database()
            .ref(`taxConsultant/${getTaxConsultant()}/clerks`)
            .update({
              [id]: { ...newClerkData },
            })
            .then(() => setNewClerkData({}))
            .then(() => setSearch(''))
            .then(() => setFilteredData([]))
            .then(() => closeCreateClerkDialog())
        } else {
          firebase
            .database()
            .ref(`taxConsultant/${getTaxConsultant()}`)
            .update({
              clerks: {
                [id]: { ...newClerkData },
              },
            })
            .then(() => setNewClerkData({}))
            .then(() => setSearch(''))
            .then(() => setFilteredData([]))
            .then(() => closeCreateClerkDialog())
        }
      })
  }

  const renderCreateClerkDialog = () => {
    const setClerkLimitation = () => {
      if (data.length !== 0) {
        const amountOfClerks = Object.entries(data[0]).length
        const contract = props.staffContractOption

        if (contract === 'lite' && amountOfClerks >= 3) {
          return true
        }

        return false
      }
      return false
    }

    if (showCreateClerkDialog) {
      return (
        <CreateClerkDialog
          email=""
          name=""
          containerAnimation={containerAnimationClass}
          contentAnimation={contentAnimationClass}
          handleIconButtonClick={() => closeCreateClerkDialog()}
          handleSaveClerkClick={() => handleSaveCreateClerk()}
          onChange={(e) => handleCreateClerkChange(e)}
          limitation={setClerkLimitation()}
        />
      )
    }
  }

  return (
    <div className="clerks-view">
      <DashboardNavigation />
      {renderContent()}
    </div>
  )
}

export default ClerksView
