import React from 'react'
import Label from '../../ui-components/labels/Label/Label'
import Select from '../../ui-components/selects/Select/Select'
import './SelectField.scss'

const SelectField = props => (
  <div className={'select-field'}>
    <Label>{props.label}</Label>
    <Select
      defaultValue={props.defaultValue}
      autoFocus={props.autoFocus}
      options={props.options}
      onChange={props.onChange}
      name={props.name}
      required={props.required}
      noDefault={props.noDefault}
    />
  </div>
)

export default SelectField
