const employeeFormValues = {
  amountOfChildren: '1',
  bankAccountHolder: 'Marco Wittmann',
  bea: true,
  birthCountry: 'Deutschland',
  birthday: '29',
  birthmonth: '04',
  birthyear: '1980',
  capitalAccumulationBankAccountHolder: 'LBS',
  capitalAccumulationIban: 'DE54342500000001623321',
  capitalAccumulationQuestion: 'Ja',
  capitalAccumulationValue: '80',
  city: 'München',
  contractNumber: '44859372',
  denomination: 'rk - Römisch-Katholische Kirchensteuer',
  education: 'Anerkannte Berufsausbildung',
  firstname: 'Marco',
  furtherEmployments: true,
  graduation: 'Abitur/Fachabitur',
  houseNumber: '23',
  iban: 'DE44100110012625484636',
  insuranceCompanyName: 'AOK Bayern',
  insuranceType: 'Privat',
  lastname: 'Wittmann',
  mariatialStatus: 'Verheiratet',
  nationality: 'Deutschland',
  parentsProperty: 'Ja',
  placeOfBirth: 'München',
  sex: 'Männlich',
  socialSecurityNumber: '60290480O505',
  street: 'Schopenhauerstraße',
  taxClass: 'III - Steuerklasse Drei',
  taxIdNumber: '86149736056',
  typeOfEmployment: 'Minijob bis 450€',
  zipCode: '80809',
}

export default employeeFormValues
