import React from 'react'
import Label from '../../ui-components/labels/Label/Label'
import Textarea from '../../ui-components/inputs/Textarea/Textarea'

import './TextareaField.scss'

interface Props {
  label: string
  name: string
  defaultValue: string
  placeholder?: string
  rows?: number
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const TextareaField = (props: Props) => (
  <div className={'textarea-field'}>
    <Label>{props.label}</Label>
    <Textarea
      name={props.name}
      placeholder={props.placeholder}
      rows={props.rows}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
    />
  </div>
)

export default TextareaField
