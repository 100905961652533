import React from 'react'
import PropTypes from 'prop-types'

const Label = (props) => <label>{props.children}</label>

Label.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Label
