import React from 'react'
import Label from '../../ui-components/labels/Label/Label'
import EmailInput from '../../ui-components/inputs/EmailInput/EmailInput'
import './EmailInputField.scss'

const EmailInputField = props => (
  <div className={'email-input-field'}>
    <Label>{props.label}</Label>
    <EmailInput
      name={props.name}
      placeholder={props.placeholder}
      required={props.required}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      disabled={props.disabled}
      maxLength={props.maxLength}
      autoFocus={props.autoFocus}
      pattern={props.pattern}
      title={props.title}
    />
  </div>
)

export default EmailInputField
