const saveFormValuesInLocalStorage = (formValues: object) => {
  Object.entries(formValues).forEach((entry) => {
    const key = entry[0]
    const value = entry[1]

    localStorage.setItem(key, value)
  })
}

export default saveFormValuesInLocalStorage
