import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ChevronIcon } from 'static/ChevronIcon.svg'
import './Select.scss'

const Select = (props) => (
  <div className={'select-box'}>
    <select
      name={props.name}
      autoFocus={props.autoFocus}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      required={props.required}
    >
      {props.noDefault ? (
        <option value="" disabled>
          Bitte Wählen
        </option>
      ) : null}
      {props.options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
    <div className={'icon-container'}>
      <ChevronIcon />
    </div>
  </div>
)

Select.propTypes = {
  autoFocus: PropTypes.bool,
  options: PropTypes.array.isRequired,
}

export default Select
