import React, { Fragment } from 'react'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../../ui-components/buttons/HelperButton/HelperButton'
import SelectField from '../../../Selects/SelectField/SelectField'
import TextInputField from '../../../Inputs/TextInputField/TextInputField'
import NumberInputField from '../../../Inputs/NumberInputField/NumberInputField'
import EmailInputField from '../../../Inputs/EmailInputField/EmailInputField'
import TwoColumns from '../../../ui-components/layouts/TwoColumns/TwoColumns'
import DateInputField from '../../../Inputs/DateInputField/DateInputField'
import PrependNumberInputField from '../../../Inputs/PrependNumberInputField/PrependNumberInputField'

import classes from './CompanyInformationForm.module.scss'
import RadioInputField from '../../../Inputs/RadioInputField/RadioInputField'
import FormHint from '../../../ui-components/hints/FormHint'

interface Props {
  type:
    | 'single-company'
    | 'limited-company-and-partnership-kg'
    | 'limited-company-and-partnership-gmbh'
    | 'limited-company'
    | 'limited-entrepreneurial-company'
    | 'civil-law-company'
  values: {
    salutation: string
    companyName: string
    businessForm: string
    businessPurpose: string
    businessStreet: string
    businessHouseNumber: string
    businessZipCode: string
    businessCity: string
    businessPhoneNumber: string
    businessEmail: string
    foundingday: string
    foundingmonth: string
    foundingyear: string
    limitedCompanyName: string
    limitedCompanyFoundingday: string
    limitedCompanyFoundingmonth: string
    limitedCompanyFoundingyear: string
    limitedCompanyBusinessStreet: string
    limitedCompanyBusinessHouseNumber: string
    limitedCompanyBusinessZipCode: string
    limitedCompanyBusinessCity: string
    amountOfSubscribedCapital: string
    partnershipAgreement: string
    differentProfitDistribution: string
    fixedCapital: string
  }
  onHelperButtonClick: () => void
  onNextButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onBackButtonClick?: (e: React.FormEvent<HTMLFormElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const CompanyInformationForm = (props: Props) => {
  const {
    onHelperButtonClick,
    onNextButtonClick,
    onChange,
    onBackButtonClick,
    type,
    values: {
      fixedCapital,
      salutation,
      companyName,
      businessForm,
      businessPurpose,
      businessStreet,
      businessHouseNumber,
      businessZipCode,
      businessCity,
      businessPhoneNumber,
      businessEmail,
      foundingday,
      foundingmonth,
      foundingyear,
      limitedCompanyName,
      limitedCompanyFoundingday,
      limitedCompanyFoundingmonth,
      limitedCompanyFoundingyear,
      limitedCompanyBusinessStreet,
      limitedCompanyBusinessHouseNumber,
      limitedCompanyBusinessZipCode,
      limitedCompanyBusinessCity,
      amountOfSubscribedCapital,
      partnershipAgreement,
      differentProfitDistribution,
    },
  } = props

  const renderFormFields = () => {
    if (type === 'single-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Betriebliche Angaben" />
          <FormBody>
            <TwoColumns layout="1-1">
              <SelectField
                label="Anrede"
                name="salutation"
                options={['Herr', 'Frau', 'Firma', 'Praxis', 'Kanzlei']}
                noDefault
                onChange={onChange}
                required
                defaultValue={salutation}
              />
              <TextInputField
                label="Name des Unternehmens"
                name="companyName"
                onChange={onChange}
                required
                defaultValue={companyName}
                placeholder="Alex"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <SelectField
                label="Unternehmensform"
                name="businessForm"
                options={['Einzelunternehmen', 'FreiberuflerIn']}
                noDefault
                onChange={onChange}
                required
                defaultValue={businessForm}
              />
              <TextInputField
                label="Ausgeführte Tätigkeit"
                name="businessPurpose"
                onChange={onChange}
                required
                defaultValue={businessPurpose}
                placeholder="Designer"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Straße"
                name="businessStreet"
                onChange={onChange}
                required
                defaultValue={businessStreet}
                placeholder="Musterstraße"
              />
              <TextInputField
                label="Hausnummer"
                name="businessHouseNumber"
                onChange={onChange}
                required
                defaultValue={businessHouseNumber}
                placeholder="123"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Postleitzahl"
                name="businessZipCode"
                onChange={onChange}
                required
                defaultValue={businessZipCode}
                placeholder="45678"
              />
              <TextInputField
                label="Stadt"
                name="businessCity"
                onChange={onChange}
                required
                defaultValue={businessCity}
                placeholder="Musterstadt"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <NumberInputField
                label="Telefonnummer"
                name="businessPhoneNumber"
                onChange={onChange}
                required
                defaultValue={businessPhoneNumber}
                placeholder="0212123456789"
              />
              <EmailInputField
                label="E-Mail"
                name="businessEmail"
                onChange={onChange}
                required
                defaultValue={businessEmail}
                placeholder="hallo@alexschmitz.de"
              />
            </TwoColumns>
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-company-and-partnership-kg') {
      return (
        <Fragment>
          <FormHeader headlineText="Betriebliche Angaben der KG (Kommanditgesellschaft)" />
          <FormBody>
            <TwoColumns layout="1-1">
              <TextInputField
                label="Name des Unternehmens"
                name="companyName"
                onChange={onChange}
                required
                defaultValue={companyName}
                placeholder="Muster GmbH & Co. KG"
              />
              <TextInputField
                label="Ausgeführte Tätigkeit"
                name="businessPurpose"
                onChange={onChange}
                required
                defaultValue={businessPurpose}
                placeholder="Messebaugesellschaft"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <DateInputField
                label="Gegründet am"
                names={['foundingday', 'foundingmonth', 'foundingyear']}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                defaultValue={[foundingday, foundingmonth, foundingyear]}
              />
              <TextInputField
                label="Straße"
                name="businessStreet"
                onChange={onChange}
                required
                defaultValue={businessStreet}
                placeholder="Musterstraße"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Hausnummer"
                name="businessHouseNumber"
                onChange={onChange}
                required
                defaultValue={businessHouseNumber}
                placeholder="123"
              />
              <TextInputField
                label="Postleitzahl"
                name="businessZipCode"
                onChange={onChange}
                required
                defaultValue={businessZipCode}
                placeholder="45678"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Stadt"
                name="businessCity"
                onChange={onChange}
                required
                defaultValue={businessCity}
                placeholder="Düsseldorf"
              />
              <NumberInputField
                label="Telefonnummer"
                name="businessPhoneNumber"
                onChange={onChange}
                required
                defaultValue={businessPhoneNumber}
                placeholder="0212123456789"
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <EmailInputField
                label="E-Mail"
                name="businessEmail"
                onChange={onChange}
                required
                defaultValue={businessEmail}
                placeholder="info@muster.de"
              />
            </TwoColumns>
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-company-and-partnership-gmbh') {
      return (
        <Fragment>
          <FormHeader
            headlineText="Betriebliche Angaben des Vollhafters (GmbH)"
            sublineText="Erfassen Sie hier bitte die Daten des Komplementärs (=Vollhafter). Es handelt es sich hier in der Regel um eine GmbH. In Außnahmefällen kann der Vollhafter auch eine UG (Unternehmergesellschaft) haftungsbeschränkt sein."
          />
          <FormBody>
            <TwoColumns layout="1-1">
              <TextInputField
                label="Name des Unternehmens"
                name="limitedCompanyName"
                onChange={onChange}
                required
                defaultValue={limitedCompanyName}
                placeholder="Muster Verwaltungs GmbH"
              />
              <DateInputField
                label="Gegründet am"
                names={[
                  'limitedCompanyFoundingday',
                  'limitedCompanyFoundingmonth',
                  'limitedCompanyFoundingyear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                defaultValue={[
                  limitedCompanyFoundingday,
                  limitedCompanyFoundingmonth,
                  limitedCompanyFoundingyear,
                ]}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <PrependNumberInputField
                label="Höhe des gezeichneten Kapitals"
                placeholder="25000"
                prependText="€"
                step={0.01}
                required
                defaultValue={amountOfSubscribedCapital}
                name="amountOfSubscribedCapital"
                onChange={onChange}
                min={25000}
              />
              <TextInputField
                label="Straße"
                name="limitedCompanyBusinessStreet"
                onChange={onChange}
                required
                defaultValue={limitedCompanyBusinessStreet}
                placeholder="Musterstraße"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Hausnummer"
                name="limitedCompanyBusinessHouseNumber"
                onChange={onChange}
                required
                defaultValue={limitedCompanyBusinessHouseNumber}
                placeholder="123"
              />
              <TextInputField
                label="Postleitzahl"
                name="limitedCompanyBusinessZipCode"
                onChange={onChange}
                required
                defaultValue={limitedCompanyBusinessZipCode}
                placeholder="45678"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Stadt"
                name="limitedCompanyBusinessCity"
                onChange={onChange}
                required
                defaultValue={limitedCompanyBusinessCity}
                placeholder="Musterstadt"
              />
            </TwoColumns>
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Betriebliche Angaben" />
          <FormBody>
            <TwoColumns layout="1-1">
              <TextInputField
                label="Name des Unternehmens"
                name="limitedCompanyName"
                onChange={onChange}
                required
                defaultValue={limitedCompanyName}
                placeholder="Muster GmbH"
              />
              <DateInputField
                label="Gegründet am"
                names={[
                  'limitedCompanyFoundingday',
                  'limitedCompanyFoundingmonth',
                  'limitedCompanyFoundingyear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                defaultValue={[
                  limitedCompanyFoundingday,
                  limitedCompanyFoundingmonth,
                  limitedCompanyFoundingyear,
                ]}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <PrependNumberInputField
                label="Höhe des gezeichneten Kapitals"
                placeholder="25000"
                prependText="€"
                step={0.01}
                required
                defaultValue={amountOfSubscribedCapital}
                name="amountOfSubscribedCapital"
                onChange={onChange}
                min={25000}
              />
              <TextInputField
                label="Straße"
                name="limitedCompanyBusinessStreet"
                onChange={onChange}
                required
                defaultValue={limitedCompanyBusinessStreet}
                placeholder="Musterstraße"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Hausnummer"
                name="limitedCompanyBusinessHouseNumber"
                onChange={onChange}
                required
                defaultValue={limitedCompanyBusinessHouseNumber}
                placeholder="123"
              />
              <TextInputField
                label="Postleitzahl"
                name="limitedCompanyBusinessZipCode"
                onChange={onChange}
                required
                defaultValue={limitedCompanyBusinessZipCode}
                placeholder="45678"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Stadt"
                name="limitedCompanyBusinessCity"
                onChange={onChange}
                required
                defaultValue={limitedCompanyBusinessCity}
                placeholder="Musterstadt"
              />
            </TwoColumns>
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-entrepreneurial-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Betriebliche Angaben" />
          <FormBody>
            <TwoColumns layout="1-1">
              <TextInputField
                label="Name des Unternehmens"
                name="limitedCompanyName"
                onChange={onChange}
                required
                defaultValue={limitedCompanyName}
                placeholder="Muster UG"
              />
              <DateInputField
                label="Gegründet am"
                names={[
                  'limitedCompanyFoundingday',
                  'limitedCompanyFoundingmonth',
                  'limitedCompanyFoundingyear',
                ]}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                defaultValue={[
                  limitedCompanyFoundingday,
                  limitedCompanyFoundingmonth,
                  limitedCompanyFoundingyear,
                ]}
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <PrependNumberInputField
                label="Höhe des gezeichneten Kapitals"
                placeholder="1500"
                prependText="€"
                step={0.01}
                required
                defaultValue={amountOfSubscribedCapital}
                name="amountOfSubscribedCapital"
                onChange={onChange}
                min={1}
              />
              <TextInputField
                label="Straße"
                name="limitedCompanyBusinessStreet"
                onChange={onChange}
                required
                defaultValue={limitedCompanyBusinessStreet}
                placeholder="Musterstraße"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Hausnummer"
                name="limitedCompanyBusinessHouseNumber"
                onChange={onChange}
                required
                defaultValue={limitedCompanyBusinessHouseNumber}
                placeholder="123"
              />
              <TextInputField
                label="Postleitzahl"
                name="limitedCompanyBusinessZipCode"
                onChange={onChange}
                required
                defaultValue={limitedCompanyBusinessZipCode}
                placeholder="45678"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Stadt"
                name="limitedCompanyBusinessCity"
                onChange={onChange}
                required
                defaultValue={limitedCompanyBusinessCity}
                placeholder="Musterstadt"
              />
            </TwoColumns>
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'civil-law-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Betriebliche Angaben" />
          <FormBody>
            <RadioInputField
              label="Gibt es eine abweichende Gewinnverteilung?"
              name="differentProfitDistribution"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={differentProfitDistribution === 'Nein' ? 0 : 1}
              onChange={onChange}
            />
            {differentProfitDistribution === 'Ja' ? (
              <>
                <RadioInputField
                  key={0}
                  label="Gibt es einen Gesellschaftsvertrag?"
                  name="partnershipAgreement"
                  radioOptions={['Nein', 'Ja']}
                  defaultChecked={1}
                  disabled
                />
                <FormHint hintHeadline="Hinweis">
                  <p>
                    Wenn es eine abweichende Gewinnverteilung gibt, dann muss
                    ein Gesellschaftsvertrag bestehen.
                  </p>
                </FormHint>
              </>
            ) : (
              <RadioInputField
                key={1}
                label="Gibt es einen Gesellschaftsvertrag?"
                name="partnershipAgreement"
                radioOptions={['Nein', 'Ja']}
                defaultChecked={partnershipAgreement === 'Nein' ? 0 : 1}
                onChange={onChange}
              />
            )}
            <PrependNumberInputField
              label="Festkapital laut GbR-Vertrag"
              name="fixedCapital"
              placeholder="10000"
              prependText="€"
              defaultValue={fixedCapital}
              required
              min={0}
              step={0.01}
              onChange={onChange}
            />
            <TwoColumns layout="1-1">
              <TextInputField
                label="Name des Unternehmens"
                name="companyName"
                onChange={onChange}
                required
                defaultValue={companyName}
                placeholder="Muster GbR"
              />
              <TextInputField
                label="Ausgeführte Tätigkeit"
                name="businessPurpose"
                onChange={onChange}
                required
                defaultValue={businessPurpose}
                placeholder="Messebau"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <DateInputField
                label="Gegründet am"
                names={['foundingday', 'foundingmonth', 'foundingyear']}
                onChange={onChange}
                placeholder={['TT', 'MM', 'JJJJ']}
                maxLength={['2', '2', '4']}
                max={['31', '12', new Date().getFullYear()]}
                min={['1', '1', '1900']}
                pattern="(0[1-9]|1[0-9]|2[0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}"
                defaultValue={[foundingday, foundingmonth, foundingyear]}
              />
              <TextInputField
                label="Straße"
                name="businessStreet"
                onChange={onChange}
                required
                defaultValue={businessStreet}
                placeholder="Musterstraße"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Hausnummer"
                name="businessHouseNumber"
                onChange={onChange}
                required
                defaultValue={businessHouseNumber}
                placeholder="123"
              />
              <TextInputField
                label="Postleitzahl"
                name="businessZipCode"
                onChange={onChange}
                required
                defaultValue={businessZipCode}
                placeholder="45678"
              />
            </TwoColumns>

            <TwoColumns layout="1-1">
              <TextInputField
                label="Stadt"
                name="businessCity"
                onChange={onChange}
                required
                defaultValue={businessCity}
                placeholder="Düsseldorf"
              />
              <NumberInputField
                label="Telefonnummer"
                name="businessPhoneNumber"
                onChange={onChange}
                required
                defaultValue={businessPhoneNumber}
                placeholder="0212123456789"
              />
            </TwoColumns>
            <TwoColumns layout="1-1">
              <EmailInputField
                label="E-Mail"
                name="businessEmail"
                onChange={onChange}
                required
                defaultValue={businessEmail}
                placeholder="info@muster.de"
              />
            </TwoColumns>
          </FormBody>
        </Fragment>
      )
    }
  }

  return (
    <FormContainer
      className={classes.companyInformationForm}
      onSubmit={onNextButtonClick}
    >
      <HelperButton onClick={onHelperButtonClick} />
      {renderFormFields()}
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText={onBackButtonClick ? 'Zurück' : null}
        onSecondaryButtonClick={onBackButtonClick}
      />
    </FormContainer>
  )
}

export default CompanyInformationForm
