import React from 'react'

import SplashStage from '../../../components/Stages/SplashStage/SplashStage'
import FormularSelector from '../../../components/Selects/FormularSelector/FormularSelector'

import classes from './ProductSelectionView.module.scss'

class ProductSelectionView extends React.Component {
  componentDidMount = () => {
    document.title = 'Formular auswählen - Fastdocs'
  }

  render() {
    return (
      <div className={classes.formSelection}>
        <SplashStage>
          <h4 className={classes.stageHeadline}>
            Willkommen im Mandanten-Portal.
          </h4>
        </SplashStage>
        <FormularSelector type="start" />
      </div>
    )
  }
}

export default ProductSelectionView
