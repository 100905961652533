import React, { Component, Fragment } from 'react'
import { detect } from 'detect-browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import moment from 'moment'

import clientFormValues from 'helpers/autofill/immediateReportForm/clientFormValues'

import FormStage from 'components/Stages/FormStage/FormStage'
import CompanyForm from 'components/Forms/CompanyForm/CompanyForm'
import EmployeeForm from 'components/Forms/EmployeeForm/EmployeeForm'
import EmailForm from 'components/Forms/EmailForm/EmailForm'
import SuccsessForm from 'components/Forms/SuccessForm/SuccessForm'
import HelpOverForImmediateReportForm from 'components/Overlays/HelpOver/fastdocs-staff/HelpOverForImmediateReportForm/HelpOverForImmediateReportForm'
import SummaryFormClient from 'components/Forms/SummaryForm/SummaryFormClient'
import InsuranceInformationForm from 'components/Forms/InsuranceInformationForm/InsuranceInformationForm'
import NotificationCard from 'components/Notifications/NotificationCard/NotificationCard'
import { ReactComponent as InfoIcon } from 'static/InformationIcon.svg'
import createFormId from 'views/utility/createFormId'
import {
  getFormStep,
  getProgressValue,
  getTaxConsultant,
} from 'views/utility/utility.jsx'
import AdditionalInsuranceInformationForm from 'components/Forms/AdditionalInsuranceInformationForm/AdditionalInsuranceInformationForm'
import AutofillButton from 'helpers/autofill/AutofillButton'
import { STAFF_FORMS } from 'constants/Formulars'

const database = firebase.database()

class ClientsViewForImmediateReportForm extends Component {
  state = {
    formId: createFormId(),
    formState: {
      formStep: 0,
      totalFormSteps: 6,
    },
    taxConsultantUseFiveFIntegration: false,
    clerks: {},
    showClerks: false,
    formValues: {
      formCreationDate: moment().format('DD.MM.YYYY'),
      formularKey: '101',
      formularName: STAFF_FORMS.IMMEDIATE_REPORT,
      taxConsultant: getTaxConsultant(),
      taxConsultantEmail: '',
      taxConsultantImportSystem: '',
      company: '',
      firstname: '',
      lastname: '',
      sex: '',
      employeeType: '',
      employmentLocation: '',
      entryday: '',
      entrymonth: '',
      entryyear: '',
      insuranceNumber: true,
      socialSecurityNumber: '',
      street: '',
      houseNumber: '',
      zipCode: '',
      city: '',
      birthday: '',
      birthmonth: '',
      birthyear: '',
      birthName: '',
      placeOfBirth: '',
      birthCountry: '',
      nationality: 'Deutschland',
      clientEmail: '',
      staffNumber: '',
      costCenter: '',
      costCenterQuerstion: false,
      staffNumberQuerstion: false,
      selectedClerk: '',
      otherInformation: '',
    },
    features: {
      costCenter: false,
      staffNumber: false,
    },
    helpOver: {
      isOpen: false,
      class: '',
    },
  }

  closeEdgeNotification = () => {
    const notificationCard =
      document.querySelector<HTMLElement>('.notification-card')
    if (notificationCard) {
      notificationCard.style.display = 'none'
    }
  }

  handleCostCenterQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'Ja') {
      this.setState({
        formValues: {
          ...this.state.formValues,
          costCenterQuerstion: true,
        },
      })
    } else {
      this.setState({
        formValues: {
          ...this.state.formValues,
          costCenterQuerstion: false,
        },
      })
    }
  }

  handleStaffNumberQuestionChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value === 'Ja') {
      this.setState({
        formValues: {
          ...this.state.formValues,
          staffNumberQuerstion: true,
        },
      })
    } else {
      this.setState({
        formValues: {
          ...this.state.formValues,
          staffNumberQuerstion: false,
        },
      })
    }
  }

  showEdgeNotification = () => {
    const browser = detect()

    if (browser?.name === 'edge') {
      return (
        <NotificationCard
          icon={<InfoIcon />}
          title="Wichtiger Hinweis:"
          text="Dein Browser erwartet bei den Nachkommastellen eventuell einen Punkt statt eines Kommas."
          onClick={this.closeEdgeNotification}
        />
      )
    }
  }

  componentDidUpdate = () => {
    if (this.state.formValues.entryday.length === 1) {
      this.setState({
        formValues: {
          ...this.state.formValues,
          entryday: `0${this.state.formValues.entryday}`,
        },
      })
    }
    if (this.state.formValues.entrymonth.length === 1) {
      this.setState({
        formValues: {
          ...this.state.formValues,
          entrymonth: `0${this.state.formValues.entrymonth}`,
        },
      })
    }

    if (this.state.formValues.birthday.length === 1) {
      this.setState({
        formValues: {
          ...this.state.formValues,
          birthday: `0${this.state.formValues.birthday}`,
        },
      })
    }
    if (this.state.formValues.birthmonth.length === 1) {
      this.setState({
        formValues: {
          ...this.state.formValues,
          birthmonth: `0${this.state.formValues.birthmonth}`,
        },
      })
    }

    const setDocumentTitle = () => {
      if (
        this.state.formState.formStep === this.state.formState.totalFormSteps
      ) {
        document.title =
          'Schritt ' +
          this.state.formState.formStep +
          ' von ' +
          this.state.formState.totalFormSteps +
          ' - ' +
          this.state.formValues.formularName
      } else {
        document.title =
          'Schritt ' +
          (this.state.formState.formStep + 1) +
          ' von ' +
          this.state.formState.totalFormSteps +
          ' - ' +
          this.state.formValues.formularName
      }
    }

    setDocumentTitle()
  }

  componentDidMount = () => {
    database
      .ref(`taxConsultant/${this.state.formValues.taxConsultant}`)
      .once('value', (snapshot) => {
        const data = snapshot.val()
        const { importSystem, email, features, integrations, clerks } = data

        this.setState({
          formValues: {
            ...this.state.formValues,
            taxConsultantEmail: email,
            taxConsultantImportSystem: importSystem,
          },
          taxConsultantUseFiveFIntegration: integrations?.fiveFSoftware
            ? true
            : false,
          features: features ? { ...features } : null,
          clerks: clerks ? { ...clerks } : null,
          showClerks: clerks && Object.keys(clerks).length > 0 ? true : false,
        })
      })
  }

  renderNextFormStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const increaseFormStep = this.state.formState.formStep + 1

    e.preventDefault()
    this.setState({
      formState: {
        ...this.state.formState,
        formStep: increaseFormStep,
      },
    })
  }

  renderPreviousFormStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const decreaseFormStep = this.state.formState.formStep - 1

    e.preventDefault()
    this.setState({
      formState: {
        ...this.state.formState,
        formStep: decreaseFormStep,
      },
    })
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [e.target.name]: e.target.value,
      },
    })
  }

  handleInsuranceNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        insuranceNumber: !this.state.formValues.insuranceNumber,
      },
    })
  }

  handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    e.preventDefault()

    database
      .ref(`jobs/${this.state.formId}`)
      .set({
        clientData: {
          ...this.state.formValues,
        },
        sentJobCreationMailToClient: true,
        sentJobCreationMailToConsultant: true,
        taxConsultantUseFiveFIntegration:
          this.state.taxConsultantUseFiveFIntegration,
      })
      .then(() => {
        this.renderNextFormStep(e)
      })
      .catch((error) => {
        alert(error)
      })
  }

  handleHelperButtonClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (!this.state.helpOver.isOpen) {
      this.setState({
        ...this.state,
        helpOver: {
          ...this.state.helpOver,
          isOpen: !this.state.helpOver.isOpen,
        },
      })
      setTimeout(() => {
        this.setState({
          ...this.state,
          helpOver: {
            ...this.state.helpOver,
            class: 'show',
          },
        })
      }, 50)
    }
    if (this.state.helpOver.isOpen) {
      this.setState({
        ...this.state,
        helpOver: {
          ...this.state.helpOver,
          class: '',
        },
      })
      setTimeout(() => {
        this.setState({
          ...this.state,
          helpOver: {
            ...this.state.helpOver,
            isOpen: false,
          },
        })
      }, 500)
    }
    e.target.blur()
  }

  handleHelpOverCloseButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    this.setState({
      ...this.state,
      helpOver: {
        ...this.state.helpOver,
        class: '',
      },
    })
    setTimeout(() => {
      this.setState({
        ...this.state,
        helpOver: {
          ...this.state.helpOver,
          isOpen: false,
        },
      })
    }, 500)
  }

  handleClerkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    Object.entries(this.state.clerks).forEach((clerk: any) => {
      if (e.target.value === clerk[1].name) {
        this.setState({
          formValues: {
            ...this.state.formValues,
            selectedClerk: clerk[1].email,
          },
        })
      }
      if (e.target.value === 'Unbekannt') {
        this.setState({
          formValues: {
            ...this.state.formValues,
            selectedClerk: this.state.formValues.taxConsultantEmail,
          },
        })
      }
    })
  }

  getListOfClerks = () => {
    const clerksList = this.state.clerks
      ? Object.entries(this.state.clerks).map((entry: any) => {
          return `${entry[1].name}`
        })
      : []
    clerksList.sort()
    clerksList.unshift('Unbekannt')

    return clerksList
  }

  renderFormSteps = () => {
    switch (this.state.formState.formStep) {
      case 0:
        return (
          <CompanyForm
            onSubmit={this.renderNextFormStep}
            onChange={this.handleInputChange}
            onHelperButtonClick={this.handleHelperButtonClick}
            company={this.state.formValues.company}
            formularName={this.state.formValues.formularName}
            taxConsultantImportSystem={
              this.state.formValues.taxConsultantImportSystem
            }
            selectedClerk={this.state.formValues.selectedClerk}
            listOfClerks={this.getListOfClerks()}
            onClerkChange={this.handleClerkChange}
            showClerkSeletion={this.state.showClerks}
          />
        )
      case 1:
        return (
          <EmployeeForm
            onSubmit={this.renderNextFormStep}
            onSecondaryButtonClick={this.renderPreviousFormStep}
            onChange={this.handleInputChange}
            onHelperButtonClick={this.handleHelperButtonClick}
            firstname={this.state.formValues.firstname}
            lastname={this.state.formValues.lastname}
            sex={this.state.formValues.sex}
            employeeType={this.state.formValues.employeeType}
            employmentLocation={this.state.formValues.employmentLocation}
            costCenter={this.state.formValues.costCenter}
            staffNumber={this.state.formValues.staffNumber}
            entryDate={[
              this.state.formValues.entryday,
              this.state.formValues.entrymonth,
              this.state.formValues.entryyear,
            ]}
            formularName={this.state.formValues.formularName}
            birthdate={[
              this.state.formValues.birthday,
              this.state.formValues.birthmonth,
              this.state.formValues.birthyear,
            ]}
            nationality={this.state.formValues.nationality}
            features={this.state.features}
            costCenterQuerstion={this.state.formValues.costCenterQuerstion}
            staffNumberQuerstion={this.state.formValues.staffNumberQuerstion}
            handleCostCenterQuestionChange={this.handleCostCenterQuestionChange}
            handleStaffNumberQuestionChange={
              this.handleStaffNumberQuestionChange
            }
            contractForm=""
            contractLimitationDate={[]}
            employedAs=""
            handycapped=""
            statusIndicator=""
            typeOfEmployee=""
          />
        )
      case 2:
        return (
          <InsuranceInformationForm
            onSubmit={this.renderNextFormStep}
            onSecondaryButtonClick={this.renderPreviousFormStep}
            onChange={this.handleInputChange}
            handleInsuranceNumberChange={this.handleInsuranceNumberChange}
            insuranceNumber={this.state.formValues.insuranceNumber}
            socialSecurityNumber={this.state.formValues.socialSecurityNumber}
            onHelperButtonClick={this.handleHelperButtonClick}
            birthday={this.state.formValues.birthday}
            birthmonth={this.state.formValues.birthmonth}
            birthyear={this.state.formValues.birthyear}
            formularName={this.state.formValues.formularName}
          />
        )
      case 3:
        return (
          <AdditionalInsuranceInformationForm
            onSubmit={this.renderNextFormStep}
            onSecondaryButtonClick={this.renderPreviousFormStep}
            onChange={this.handleInputChange}
            insuranceNumber={this.state.formValues.insuranceNumber}
            socialSecurityNumber={this.state.formValues.socialSecurityNumber}
            placeOfBirth={this.state.formValues.placeOfBirth}
            birthName={this.state.formValues.birthName}
            birthCountry={this.state.formValues.birthCountry}
            street={this.state.formValues.street}
            houseNumber={this.state.formValues.houseNumber}
            zipCode={this.state.formValues.zipCode}
            city={this.state.formValues.city}
            onHelperButtonClick={this.handleHelperButtonClick}
            taxConsultantImportSystem={
              this.state.formValues.taxConsultantImportSystem
            }
          />
        )
      case 4:
        return (
          <EmailForm
            onSubmit={this.renderNextFormStep}
            onSecondaryButtonClick={this.renderPreviousFormStep}
            onChange={this.handleInputChange}
            onHelperButtonClick={this.handleHelperButtonClick}
            clientEmail={this.state.formValues.clientEmail}
            formularName={this.state.formValues.formularName}
            taxConsultantEmail={this.state.formValues.taxConsultantEmail}
            taxConsultantImportSystem={
              this.state.formValues.taxConsultantImportSystem
            }
            otherInformation={this.state.formValues.otherInformation}
          />
        )
      case 5:
        return (
          <SummaryFormClient
            onSubmit={this.handleSubmit}
            onSecondaryButtonClick={this.renderPreviousFormStep}
            onHelperButtonClick={this.handleHelperButtonClick}
            company={this.state.formValues.company}
            firstname={this.state.formValues.firstname}
            lastname={this.state.formValues.lastname}
            sex={this.state.formValues.sex}
            employeeType={this.state.formValues.employeeType}
            employmentLocation={this.state.formValues.employmentLocation}
            entryday={this.state.formValues.entryday}
            entrymonth={this.state.formValues.entrymonth}
            entryyear={this.state.formValues.entryyear}
            insuranceNumber={this.state.formValues.insuranceNumber}
            socialSecurityNumber={this.state.formValues.socialSecurityNumber}
            street={this.state.formValues.street}
            houseNumber={this.state.formValues.houseNumber}
            zipCode={this.state.formValues.zipCode}
            city={this.state.formValues.city}
            birthday={this.state.formValues.birthday}
            birthmonth={this.state.formValues.birthmonth}
            birthyear={this.state.formValues.birthyear}
            birthName={this.state.formValues.birthName}
            placeOfBirth={this.state.formValues.placeOfBirth}
            birthCountry={this.state.formValues.birthCountry}
            nationality={this.state.formValues.nationality}
            clientEmail={this.state.formValues.clientEmail}
            formularName={this.state.formValues.formularName}
            otherInformation={this.state.formValues.otherInformation}
            staffNumber={this.state.formValues.staffNumber}
            costCenter={this.state.formValues.costCenter}
          />
        )
      default:
        return (
          <SuccsessForm
            successText="Personaldaten erfolgreich angelegt."
            formId={this.state.formId}
          >
            <p>
              Wir haben deine Steuerkanzlei über die Neuanstellung informiert.
            </p>
            <p>
              Sobald alles verarbeitet wurde, werden die Daten von Dir und
              Deiner neuen Arbeitskraft auf unserem System wieder gelöscht.
            </p>
            <p>
              Auf Wiedersehen{' '}
              <span role="img" aria-label="Winkende-Hand">
                👋
              </span>
              .
            </p>
          </SuccsessForm>
        )
    }
  }

  render() {
    return (
      <Fragment>
        <FormStage
          headlineText={STAFF_FORMS.IMMEDIATE_REPORT}
          totalSteps={this.state.formState.totalFormSteps}
          actualStep={getFormStep(
            this.state.formState.formStep,
            this.state.formState.totalFormSteps
          )}
          progress={getProgressValue(
            this.state.formState.formStep,
            this.state.formState.totalFormSteps
          )}
        />
        {this.renderFormSteps()}
        {this.state.helpOver.isOpen === true ? (
          <HelpOverForImmediateReportForm
            onCloseButtonClick={this.handleHelpOverCloseButton}
            className={this.state.helpOver.class}
            formStep={this.state.formState.formStep}
            formId={this.state.formId}
          />
        ) : null}

        {this.showEdgeNotification()}

        <AutofillButton
          onClick={() =>
            this.setState({
              formValues: {
                ...this.state.formValues,
                ...clientFormValues,
              },
            })
          }
        />
      </Fragment>
    )
  }
}

export default ClientsViewForImmediateReportForm
