import React from 'react'
import { Route } from 'react-router'
import AuthenticatedDashboardView from '../views/DashboardViews/AuthenticatedDashboardView/AuthenticatedDashboardView'
import ClerksView from '../views/DashboardViews/ClerksView/ClerksView'
import ClientDataView from '../views/DashboardViews/ClientDataView/ClientDataView'
import OtherFormsDataView from '../views/DashboardViews/OtherFormsDataView/OtherFormsDataView'
import SettingsView from '../views/DashboardViews/SettingsView/SettingsView'
import StaffDataView from '../views/DashboardViews/StaffDataView/StaffDataView'
import FiveFSoftware from '../views/IntegrationViews/FiveFSoftware/FiveFSoftware'
import DashboardLogin from '../views/DashboardViews/DashboardLogin/DashboardLogin'

interface Props {
  logo: string
  contracts: {
    staff: {
      contract: boolean
      contractOption: string
    }
    client: {
      contract: boolean
    }
  }
}

export function DashboardRoutes(props: Props) {
  return (
    <>
      <Route exact path="/login" component={DashboardLogin} />
      <Route path="/dashboard">
        <AuthenticatedDashboardView
          logo={props.logo}
          contracts={props.contracts}
        />
      </Route>
    </>
  )
}

export function AuthenticatedDashboardRoutes(props: Props) {
  return (
    <>
      <Route exact path="/dashboard/staff">
        <StaffDataView staffContract={props.contracts.staff.contract} />
      </Route>
      <Route exact path="/dashboard/client">
        <ClientDataView clientContract={props.contracts.client.contract} />
      </Route>
      <Route exact path="/dashboard/other">
        <OtherFormsDataView staffContract={props.contracts.staff.contract} />
      </Route>
      <Route path="/dashboard/settings">
        <SettingsView logo={props.logo} />
      </Route>
      <Route path="/dashboard/clerks">
        <ClerksView
          staffContract={props.contracts.staff.contract}
          staffContractOption={props.contracts.staff.contractOption}
        />
      </Route>
      <Route exact path="/dashboard/integrations/5f-software">
        <FiveFSoftware />
      </Route>
    </>
  )
}
