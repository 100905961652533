import React from 'react'
import Label from '../../ui-components/labels/Label/Label'
import NumberInput from '../../ui-components/inputs/NumberInput/NumberInput'
import './NumberInputField.scss'

const NumberInputField = props => (
  <div className={'number-input-field'}>
    <Label>{props.label}</Label>
    <NumberInput
      name={props.name}
      placeholder={props.placeholder}
      required={props.required}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      disabled={props.disabled}
      maxLength={props.maxLength}
      autoFocus={props.autoFocus}
      pattern={props.pattern}
      title={props.title}
      min={props.min}
      max={props.max}
      step={props.step}
    />
  </div>
)

export default NumberInputField
