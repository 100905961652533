import React from 'react'

import './ActionButtons.scss'

const ActionButton = (props: {
  buttonState: string
  content: { initial: string; finished: string }
  onClick?: any
  disabled: boolean
  fullWidth?: boolean
  type?: 'submit'
  buttonStyle?: 'primary'
}) => {
  const setSize = props.fullWidth ? 'full-width' : ''
  const setStyle = props.buttonStyle ? props.buttonStyle : 'secondary'

  const renderContent = () => {
    if (props.buttonState === 'initial') {
      return props.content.initial
    }
    if (props.buttonState === 'active') {
      return (
        <div className="animated-dots">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      )
    }
    if (props.buttonState === 'finished') {
      return props.content.finished
    }
  }

  return (
    <button
      className={`${setStyle} ${setSize}`}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type}
    >
      {renderContent()}
    </button>
  )
}

export default ActionButton
