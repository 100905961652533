import React, { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import './ContactInformationSettingsView.scss'

import ActionButton from '../../../components/ui-components/buttons/ActionButton/ActionButton'
import TextInputField from '../../../components/Inputs/TextInputField/TextInputField'
import { getTaxConsultant } from '../../utility/utility'
import FormHint from '../../../components/ui-components/hints/FormHint'
import SingleInputFormField from '../../../components/Inputs/SingleInputFormField/SingleInputFormField'
import TwoColumns from '../../../components/ui-components/layouts/TwoColumns/TwoColumns'

interface Props {
  data: {
    contactInformation: {
      companyName: string
      street: string
      houseNumber: string
      zipCode: string
      city: string
      creditorId: string
    }
    contracts: {
      client: { contract: boolean }
    }
  }
}

const ContactInformationSettingsView = (props: Props) => {
  const [contactInformation, setContactInformation] = useState(
    props.data.contactInformation
  )
  const [saveButton, setSaveButton] = useState('initial')
  const [saveCreditorIdButton, setSaveCreditorIdButton] = useState('initial')

  useEffect(() => {
    setContactInformation(props.data.contactInformation)
  }, [props.data.contactInformation])

  const saveContactInformation = () => {
    if (
      contactInformation.city &&
      contactInformation.companyName &&
      contactInformation.houseNumber &&
      contactInformation.street &&
      contactInformation.zipCode
    ) {
      setSaveButton('active')
      setTimeout(() => {
        firebase
          .database()
          .ref(`taxConsultant/${getTaxConsultant()}/`)
          .update({ contactInformation })
          .then(() => {
            setSaveButton('finished')
            setTimeout(() => {
              setSaveButton('initial')
            }, 1000)
          })
      }, 1000)
    }
  }

  const saveCreditorId = () => {
    setSaveCreditorIdButton('active')
    setTimeout(() => {
      firebase
        .database()
        .ref(`taxConsultant/${getTaxConsultant()}/contactInformation`)
        .update({ creditorId: contactInformation.creditorId })
        .then(() => {
          setSaveCreditorIdButton('finished')
          setTimeout(() => {
            setSaveCreditorIdButton('initial')
          }, 1000)
        })
    }, 1000)
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setContactInformation({
      ...contactInformation,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <div className="contact-information-details">
      <header>
        <h4>Unternehmensangaben</h4>
      </header>

      <main>
        <section>
          {!contactInformation ? (
            <FormHint
              hintHeadline="Fehlende Unternehmensangaben"
              hintText="Bitte die fehlenden Adressinformationen hinterlegen."
            />
          ) : null}

          <form
            onSubmit={(e) => {
              e.preventDefault()
            }}
            className="contact-data"
          >
            <TextInputField
              label="Name des Unternehmens"
              placeholder="Wunderbox"
              name="companyName"
              defaultValue={contactInformation?.companyName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e)
              }
              required
            />
            <TwoColumns layout="5-3">
              <TextInputField
                label="Straße"
                placeholder="Musterstraße"
                name="street"
                defaultValue={contactInformation?.street}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
                required
              />
              <TextInputField
                label="Hausnummer"
                placeholder="10a"
                name="houseNumber"
                defaultValue={contactInformation?.houseNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
                pattern="[a-zA-Z0-9-]+"
                required
              />
            </TwoColumns>
            <TwoColumns layout="3-5">
              <TextInputField
                label="Postleitzahl"
                placeholder="45678"
                name="zipCode"
                defaultValue={contactInformation?.zipCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
                pattern="[0-9]{4,6}"
                required
              />
              <TextInputField
                label="Stadt"
                placeholder="Musterstadt"
                name="city"
                defaultValue={contactInformation?.city}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
                required
              />
            </TwoColumns>
            <ActionButton
              buttonState={saveButton}
              content={{
                initial: 'Speichern',
                finished: 'Gespeichert',
              }}
              onClick={() => {
                saveContactInformation()
              }}
              disabled={saveButton !== 'initial' ? true : false}
              type="submit"
            />
          </form>
        </section>

        {props.data.contracts.client.contract ? (
          <>
            <section>
              <SingleInputFormField
                label="Gläubiger-Id"
                type="text"
                name="creditorId"
                defaultValue={contactInformation?.creditorId}
                buttonState={saveCreditorIdButton}
                placeholder="DE06ABC09876543210"
                caption="Die Gläubiger-ID wird automatisch im SEPA-Formular für neue Mandate hinterlegt."
                onButtonClick={() => saveCreditorId()}
                onInputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
              />
            </section>
          </>
        ) : null}
      </main>
    </div>
  )
}

export default ContactInformationSettingsView
