import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Label from '../../ui-components/labels/Label/Label'
import SuggestTextInput from '../../ui-components/inputs/SuggestTextInput/SuggestTextInput'
import './SuggestTextInputField.scss'

const propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  autoFocus: PropTypes.bool,
  pattern: PropTypes.any,
  title: PropTypes.string,
  data: PropTypes.array,
}

class SuggestTextInputField extends PureComponent {
  render() {
    return (
      <div className={'suggest-text-input-field'}>
        <Label>{this.props.label}</Label>
        <SuggestTextInput
          name={this.props.name}
          placeholder={this.props.placeholder}
          required={this.props.required}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          maxLength={this.props.maxLength}
          minLength={this.props.minLength}
          autoFocus={this.props.autoFocus}
          pattern={this.props.pattern}
          title={this.props.title}
          data={this.props.data}
          onSuggestionClick={this.props.onSuggestionClick}
          value={this.props.value}
        />
      </div>
    )
  }
}

SuggestTextInputField.propTypes = propTypes

export default SuggestTextInputField
