import React from 'react';
import classes from './ProgressBar.module.scss'

interface Props {
  progress: number
}

const ProgressBar = (props: Props) => {
  return (
    <div className={classes.progressBar}>
      <div
        className={classes.progressIndicator}
        style={{ width: props.progress + '%' }}
      />
    </div>
  );
}

export default ProgressBar;
