import React, { Fragment } from 'react'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../../ui-components/buttons/HelperButton/HelperButton'
import TextInputField from '../../../Inputs/TextInputField/TextInputField'
import SuggestTextInputField from '../../../Inputs/SuggestTextInputField/SuggestTextInputField'
import RadioInputField from '../../../Inputs/RadioInputField/RadioInputField'

import taxOffices from './taxOffices.json'
import TextInputFieldWithOptions from '../../../Inputs/TextInputFieldWithOptions/TextInputFieldWithOptions'
import { tinValidation } from 'components/utility/tinValidation'

import classes from './TaxOfficeForm.module.scss'

interface Props {
  type:
    | 'private'
    | 'business'
    | 'limited-company-and-partnership-kg'
    | 'limited-company-and-partnership-gmbh'
    | 'limited-company-and-partnership-private'
    | 'limited-company'
    | 'limited-entrepreneurial-company'
    | 'civil-law-company-first-partner'
    | 'civil-law-company-second-partner'
  values: {
    privateTaxOffice: string
    privateTaxNumber: string
    businessTaxOffice: string
    businessTaxNumber: string
    tradeRegisterEntry: string
    registryCourt: string
    tradeRegisterNumber: string
    businessTaxNumberAvailable: string
    vatNumberAvailable: string
    vatRegion: string
    vatNumber: string
    limitedCompanyBusinessTaxOffice: string
    limitedCompanyVatNumberAvailable: string
    limitedCompanyVatNumber: string
    limitedCompanyVatRegion: string
    limitedCompanyBusinessTaxNumberAvailable: string
    limitedCompanyBusinessTaxNumber: string
    limitedCompanyTradeRegisterEntry: string
    limitedCompanyRegistryCourt: string
    limitedCompanyTradeRegisterNumber: string
  }
  onHelperButtonClick: () => void
  onNextButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onBackButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onOptionChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onSuggestionClick: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const TaxOfficeForm = (props: Props) => {
  const {
    values: {
      privateTaxOffice,
      privateTaxNumber,
      businessTaxOffice,
      businessTaxNumber,
      tradeRegisterEntry,
      registryCourt,
      tradeRegisterNumber,
      businessTaxNumberAvailable,
      vatNumberAvailable,
      vatRegion,
      vatNumber,
      limitedCompanyBusinessTaxOffice,
      limitedCompanyVatNumberAvailable,
      limitedCompanyVatNumber,
      limitedCompanyVatRegion,
      limitedCompanyBusinessTaxNumberAvailable,
      limitedCompanyBusinessTaxNumber,
      limitedCompanyTradeRegisterEntry,
      limitedCompanyRegistryCourt,
      limitedCompanyTradeRegisterNumber,
    },
    type,
    onHelperButtonClick,
    onNextButtonClick,
    onChange,
    onBackButtonClick,
    onSuggestionClick,
    onOptionChange,
  } = props

  const preventSubmiting = (e: React.FormEvent<HTMLButtonElement>) => {
    if (
      tinValidation(privateTaxNumber) ||
      tinValidation(businessTaxNumber) ||
      tinValidation(limitedCompanyBusinessTaxNumber)
    ) {
      e.preventDefault()
    }
  }

  const setLabel = () => {
    if (
      tinValidation(privateTaxNumber) ||
      tinValidation(businessTaxNumber) ||
      tinValidation(limitedCompanyBusinessTaxNumber)
    ) {
      return (
        <span className={classes.validateInfo}>
          Steuernummer{' '}
          <span className={classes.warn}>Das ist eine Steuer-ID</span>
        </span>
      )
    }
    return 'Steuernummer'
  }

  const renderFormContent = () => {
    if (type === 'private') {
      return (
        <Fragment>
          <FormHeader headlineText="Privates Finanzamt" />
          <FormBody>
            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="privateTaxOffice"
              value={privateTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Finanzamt Hilden"
              required
              data={taxOffices}
            />
            <TextInputField
              label={setLabel()}
              name="privateTaxNumber"
              defaultValue={privateTaxNumber}
              placeholder="123/456/789"
              onChange={onChange}
            />
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'business') {
      return (
        <Fragment>
          <FormHeader headlineText="Geschäftliches Finanzamt" />
          <FormBody>
            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="businessTaxOffice"
              value={businessTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Hilden"
              required
              data={taxOffices}
            />

            <RadioInputField
              label="Ist eine Steuernummer vorhanden?"
              name="businessTaxNumberAvailable"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={businessTaxNumberAvailable === 'Nein' ? 0 : 1}
              onChange={onChange}
            />
            {businessTaxNumberAvailable === 'Ja' ? (
              <Fragment>
                <TextInputField
                  label={setLabel()}
                  name="businessTaxNumber"
                  defaultValue={businessTaxNumber}
                  placeholder="123/456/789"
                  onChange={onChange}
                  required
                />
              </Fragment>
            ) : null}

            <RadioInputField
              label="Ist eine Umsatzsteuer-Identifikationsnummer vorhanden?"
              name="vatNumberAvailable"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={vatNumberAvailable === 'Nein' ? 0 : 1}
              onChange={onChange}
            />
            {vatNumberAvailable === 'Ja' ? (
              <TextInputFieldWithOptions
                maxLength={12}
                defaultValue={vatNumber}
                label="Umsatzsteuer-Identifikationsnummer"
                name="vatNumber"
                onChange={onChange}
                onOptionChange={onOptionChange}
                optionDefaultValue={vatRegion}
                optionName="vatRegion"
                options={[
                  'BE',
                  'BG',
                  'DK',
                  'DE',
                  'EE',
                  'FI',
                  'FR',
                  'EL',
                  'IE',
                  'IT',
                  'HR',
                  'LV',
                  'LT',
                  'LU',
                  'MT',
                  'NL',
                  'AT',
                  'PL',
                  'PT',
                  'RO',
                  'SE',
                  'SK',
                  'SI',
                  'ES',
                  'CZ',
                  'HU',
                  'GB',
                  'CY',
                ]}
                placeholder="174354139"
                type="text"
              />
            ) : null}

            <RadioInputField
              label="Handelsregistereintrag vorhanden?"
              name="tradeRegisterEntry"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={tradeRegisterEntry === 'Nein' ? 0 : 1}
              onChange={onChange}
            />
            {tradeRegisterEntry === 'Ja' ? (
              <Fragment>
                <TextInputField
                  label="Ort des Registergerichtes"
                  name="registryCourt"
                  defaultValue={registryCourt}
                  placeholder="Wuppertal"
                  onChange={onChange}
                  required
                />
                <TextInputField
                  label="Handelsregister-Nummer"
                  name="tradeRegisterNumber"
                  defaultValue={tradeRegisterNumber}
                  placeholder="HRB9937"
                  onChange={onChange}
                  required
                />
              </Fragment>
            ) : null}
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-company-and-partnership-kg') {
      return (
        <Fragment>
          <FormHeader headlineText="Betriebliches Finanzamt der KG (Kommanditgesellschaft)" />
          <FormBody>
            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="businessTaxOffice"
              value={businessTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Hilden"
              required
              data={taxOffices}
            />

            <RadioInputField
              label="Ist eine Steuernummer vorhanden?"
              name="businessTaxNumberAvailable"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={businessTaxNumberAvailable === 'Nein' ? 0 : 1}
              onChange={onChange}
            />
            {businessTaxNumberAvailable === 'Ja' ? (
              <Fragment>
                <TextInputField
                  label={setLabel()}
                  name="businessTaxNumber"
                  defaultValue={businessTaxNumber}
                  placeholder="123/456/789"
                  onChange={onChange}
                  required
                />
              </Fragment>
            ) : null}

            <RadioInputField
              label="Ist eine Umsatzsteuer-Identifikationsnummer vorhanden?"
              name="vatNumberAvailable"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={vatNumberAvailable === 'Nein' ? 0 : 1}
              onChange={onChange}
            />
            {vatNumberAvailable === 'Ja' ? (
              <TextInputFieldWithOptions
                maxLength={12}
                defaultValue={vatNumber}
                label="Umsatzsteuer-Identifikationsnummer"
                name="vatNumber"
                onChange={onChange}
                onOptionChange={onOptionChange}
                optionDefaultValue={vatRegion}
                optionName="vatRegion"
                options={[
                  'BE',
                  'BG',
                  'DK',
                  'DE',
                  'EE',
                  'FI',
                  'FR',
                  'EL',
                  'IE',
                  'IT',
                  'HR',
                  'LV',
                  'LT',
                  'LU',
                  'MT',
                  'NL',
                  'AT',
                  'PL',
                  'PT',
                  'RO',
                  'SE',
                  'SK',
                  'SI',
                  'ES',
                  'CZ',
                  'HU',
                  'GB',
                  'CY',
                ]}
                placeholder="174354139"
                type="text"
              />
            ) : null}

            <RadioInputField
              label="Handelsregistereintrag vorhanden?"
              name="tradeRegisterEntry"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={tradeRegisterEntry === 'Nein' ? 0 : 1}
              onChange={onChange}
            />
            {tradeRegisterEntry === 'Ja' ? (
              <Fragment>
                <TextInputField
                  label="Ort des Registergerichtes"
                  name="registryCourt"
                  defaultValue={registryCourt}
                  placeholder="Wuppertal"
                  onChange={onChange}
                  required
                />
                <TextInputField
                  label="Handelsregister-Nummer"
                  name="tradeRegisterNumber"
                  defaultValue={tradeRegisterNumber}
                  placeholder="HRA9937"
                  onChange={onChange}
                  required
                />
              </Fragment>
            ) : null}
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-company-and-partnership-gmbh') {
      return (
        <Fragment>
          <FormHeader headlineText="Betriebliches Finanzamt des Vollhafters (GmbH)" />
          <FormBody>
            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="limitedCompanyBusinessTaxOffice"
              value={limitedCompanyBusinessTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Hilden"
              required
              data={taxOffices}
            />

            <RadioInputField
              label="Ist eine Steuernummer vorhanden?"
              name="limitedCompanyBusinessTaxNumberAvailable"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={
                limitedCompanyBusinessTaxNumberAvailable === 'Nein' ? 0 : 1
              }
              onChange={onChange}
            />
            {limitedCompanyBusinessTaxNumberAvailable === 'Ja' ? (
              <Fragment>
                <TextInputField
                  label={setLabel()}
                  name="limitedCompanyBusinessTaxNumber"
                  defaultValue={limitedCompanyBusinessTaxNumber}
                  placeholder="123/456/789"
                  onChange={onChange}
                  required
                />
              </Fragment>
            ) : null}

            <RadioInputField
              label="Ist eine Umsatzsteuer-Identifikationsnummer vorhanden?"
              name="limitedCompanyVatNumberAvailable"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={
                limitedCompanyVatNumberAvailable === 'Nein' ? 0 : 1
              }
              onChange={onChange}
            />
            {limitedCompanyVatNumberAvailable === 'Ja' ? (
              <TextInputFieldWithOptions
                maxLength={12}
                defaultValue={limitedCompanyVatNumber}
                label="Umsatzsteuer-Identifikationsnummer"
                name="limitedCompanyVatNumber"
                onChange={onChange}
                onOptionChange={onOptionChange}
                optionDefaultValue={limitedCompanyVatRegion}
                optionName="limitedCompanyVatRegion"
                options={[
                  'BE',
                  'BG',
                  'DK',
                  'DE',
                  'EE',
                  'FI',
                  'FR',
                  'EL',
                  'IE',
                  'IT',
                  'HR',
                  'LV',
                  'LT',
                  'LU',
                  'MT',
                  'NL',
                  'AT',
                  'PL',
                  'PT',
                  'RO',
                  'SE',
                  'SK',
                  'SI',
                  'ES',
                  'CZ',
                  'HU',
                  'GB',
                  'CY',
                ]}
                placeholder="174354139"
                type="text"
              />
            ) : null}

            <RadioInputField
              label="Handelsregistereintrag vorhanden?"
              name="limitedCompanyTradeRegisterEntry"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={
                limitedCompanyTradeRegisterEntry === 'Nein' ? 0 : 1
              }
              onChange={onChange}
            />
            {limitedCompanyTradeRegisterEntry === 'Ja' ? (
              <Fragment>
                <TextInputField
                  label="Ort des Registergerichtes"
                  name="limitedCompanyRegistryCourt"
                  defaultValue={limitedCompanyRegistryCourt}
                  placeholder="Wuppertal"
                  onChange={onChange}
                  required
                />
                <TextInputField
                  label="Handelsregister-Nummer"
                  name="limitedCompanyTradeRegisterNumber"
                  defaultValue={limitedCompanyTradeRegisterNumber}
                  placeholder="HRB9937"
                  onChange={onChange}
                  required
                />
              </Fragment>
            ) : null}
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-company-and-partnership-private') {
      return (
        <Fragment>
          <FormHeader headlineText="Privates Finanzamt (KG-GesellschafterIn)" />
          <FormBody>
            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="privateTaxOffice"
              value={privateTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Finanzamt Hilden"
              required
              data={taxOffices}
            />
            <TextInputField
              label={setLabel()}
              name="privateTaxNumber"
              defaultValue={privateTaxNumber}
              placeholder="123/456/789"
              onChange={onChange}
              required
            />
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Betriebliches Finanzamt" />
          <FormBody>
            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="limitedCompanyBusinessTaxOffice"
              value={limitedCompanyBusinessTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Hilden"
              required
              data={taxOffices}
            />

            <RadioInputField
              label="Ist eine Steuernummer vorhanden?"
              name="limitedCompanyBusinessTaxNumberAvailable"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={
                limitedCompanyBusinessTaxNumberAvailable === 'Nein' ? 0 : 1
              }
              onChange={onChange}
            />
            {limitedCompanyBusinessTaxNumberAvailable === 'Ja' ? (
              <Fragment>
                <TextInputField
                  label={setLabel()}
                  name="limitedCompanyBusinessTaxNumber"
                  defaultValue={limitedCompanyBusinessTaxNumber}
                  placeholder="123/456/789"
                  onChange={onChange}
                  required
                />
              </Fragment>
            ) : null}

            <RadioInputField
              label="Ist eine Umsatzsteuer-Identifikationsnummer vorhanden?"
              name="limitedCompanyVatNumberAvailable"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={
                limitedCompanyVatNumberAvailable === 'Nein' ? 0 : 1
              }
              onChange={onChange}
            />
            {limitedCompanyVatNumberAvailable === 'Ja' ? (
              <TextInputFieldWithOptions
                maxLength={12}
                defaultValue={limitedCompanyVatNumber}
                label="Umsatzsteuer-Identifikationsnummer"
                name="limitedCompanyVatNumber"
                onChange={onChange}
                onOptionChange={onOptionChange}
                optionDefaultValue={limitedCompanyVatRegion}
                optionName="limitedCompanyVatRegion"
                options={[
                  'BE',
                  'BG',
                  'DK',
                  'DE',
                  'EE',
                  'FI',
                  'FR',
                  'EL',
                  'IE',
                  'IT',
                  'HR',
                  'LV',
                  'LT',
                  'LU',
                  'MT',
                  'NL',
                  'AT',
                  'PL',
                  'PT',
                  'RO',
                  'SE',
                  'SK',
                  'SI',
                  'ES',
                  'CZ',
                  'HU',
                  'GB',
                  'CY',
                ]}
                placeholder="174354139"
                type="text"
              />
            ) : null}

            <RadioInputField
              label="Handelsregistereintrag vorhanden?"
              name="limitedCompanyTradeRegisterEntry"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={
                limitedCompanyTradeRegisterEntry === 'Nein' ? 0 : 1
              }
              onChange={onChange}
            />
            {limitedCompanyTradeRegisterEntry === 'Ja' ? (
              <Fragment>
                <TextInputField
                  label="Ort des Registergerichtes"
                  name="limitedCompanyRegistryCourt"
                  defaultValue={limitedCompanyRegistryCourt}
                  placeholder="Wuppertal"
                  onChange={onChange}
                  required
                />
                <TextInputField
                  label="Handelsregister-Nummer"
                  name="limitedCompanyTradeRegisterNumber"
                  defaultValue={limitedCompanyTradeRegisterNumber}
                  placeholder="HRB9937"
                  onChange={onChange}
                  required
                />
              </Fragment>
            ) : null}
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'limited-entrepreneurial-company') {
      return (
        <Fragment>
          <FormHeader headlineText="Betriebliches Finanzamt" />
          <FormBody>
            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="limitedCompanyBusinessTaxOffice"
              value={limitedCompanyBusinessTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Hilden"
              required
              data={taxOffices}
            />

            <RadioInputField
              label="Ist eine Steuernummer vorhanden?"
              name="limitedCompanyBusinessTaxNumberAvailable"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={
                limitedCompanyBusinessTaxNumberAvailable === 'Nein' ? 0 : 1
              }
              onChange={onChange}
            />
            {limitedCompanyBusinessTaxNumberAvailable === 'Ja' ? (
              <Fragment>
                <TextInputField
                  label={setLabel()}
                  name="limitedCompanyBusinessTaxNumber"
                  defaultValue={limitedCompanyBusinessTaxNumber}
                  placeholder="123/456/789"
                  onChange={onChange}
                  required
                />
              </Fragment>
            ) : null}

            <RadioInputField
              label="Ist eine Umsatzsteuer-Identifikationsnummer vorhanden?"
              name="limitedCompanyVatNumberAvailable"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={
                limitedCompanyVatNumberAvailable === 'Nein' ? 0 : 1
              }
              onChange={onChange}
            />
            {limitedCompanyVatNumberAvailable === 'Ja' ? (
              <TextInputFieldWithOptions
                maxLength={12}
                defaultValue={limitedCompanyVatNumber}
                label="Umsatzsteuer-Identifikationsnummer"
                name="limitedCompanyVatNumber"
                onChange={onChange}
                onOptionChange={onOptionChange}
                optionDefaultValue={limitedCompanyVatRegion}
                optionName="limitedCompanyVatRegion"
                options={[
                  'BE',
                  'BG',
                  'DK',
                  'DE',
                  'EE',
                  'FI',
                  'FR',
                  'EL',
                  'IE',
                  'IT',
                  'HR',
                  'LV',
                  'LT',
                  'LU',
                  'MT',
                  'NL',
                  'AT',
                  'PL',
                  'PT',
                  'RO',
                  'SE',
                  'SK',
                  'SI',
                  'ES',
                  'CZ',
                  'HU',
                  'GB',
                  'CY',
                ]}
                placeholder="174354139"
                type="text"
              />
            ) : null}

            <RadioInputField
              label="Handelsregistereintrag vorhanden?"
              name="limitedCompanyTradeRegisterEntry"
              radioOptions={['Nein', 'Ja']}
              defaultChecked={
                limitedCompanyTradeRegisterEntry === 'Nein' ? 0 : 1
              }
              onChange={onChange}
            />
            {limitedCompanyTradeRegisterEntry === 'Ja' ? (
              <Fragment>
                <TextInputField
                  label="Ort des Registergerichtes"
                  name="limitedCompanyRegistryCourt"
                  defaultValue={limitedCompanyRegistryCourt}
                  placeholder="Wuppertal"
                  onChange={onChange}
                  required
                />
                <TextInputField
                  label="Handelsregister-Nummer"
                  name="limitedCompanyTradeRegisterNumber"
                  defaultValue={limitedCompanyTradeRegisterNumber}
                  placeholder="HRB9937"
                  onChange={onChange}
                  required
                />
              </Fragment>
            ) : null}
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'civil-law-company-first-partner') {
      return (
        <Fragment>
          <FormHeader headlineText="Privates Finanzamt (GesellschafterIn 1)" />
          <FormBody>
            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="privateTaxOffice"
              value={privateTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Finanzamt Hilden"
              required
              data={taxOffices}
            />
            <TextInputField
              label={setLabel()}
              name="privateTaxNumber"
              defaultValue={privateTaxNumber}
              placeholder="123/456/789"
              onChange={onChange}
              required
            />
          </FormBody>
        </Fragment>
      )
    }
    if (type === 'civil-law-company-second-partner') {
      return (
        <Fragment>
          <FormHeader headlineText="Privates Finanzamt (GesellschafterIn 2)" />
          <FormBody>
            <SuggestTextInputField
              label="Name des Finanzamtes"
              name="limitedCompanyBusinessTaxOffice"
              value={limitedCompanyBusinessTaxOffice}
              onChange={onChange}
              onSuggestionClick={onSuggestionClick}
              placeholder="Finanzamt Hilden"
              required
              data={taxOffices}
            />
            <TextInputField
              label={setLabel()}
              name="limitedCompanyBusinessTaxNumber"
              defaultValue={limitedCompanyBusinessTaxNumber}
              placeholder="123/456/789"
              onChange={onChange}
              required
            />
          </FormBody>
        </Fragment>
      )
    }
  }

  return (
    <FormContainer
      className={classes.bankInformationForm}
      onSubmit={onNextButtonClick}
    >
      <HelperButton onClick={onHelperButtonClick} />
      {renderFormContent()}
      <FormFooter
        primaryButtonText="Weiter"
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={onBackButtonClick}
        onClick={preventSubmiting}
      />
    </FormContainer>
  )
}

export default TaxOfficeForm
