import React, { Fragment, useEffect, useState } from 'react'
import { detect } from 'detect-browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import employeeFormValues from 'helpers/autofill/studentMinijobForm/employeeFormValues'

import FormStage from 'components/Stages/FormStage/FormStage'
import EmployeeInformationsForm from 'components/Forms/EmployeeInformationsForm/EmployeeInformationsForm'
import EducationForm from 'components/Forms/EducationForm/EducationForm'
import BeginningStatusForm from 'components/Forms/BeginningStatusForm/BeginningStatusForm'
import InsuranceInformationForm from 'components/Forms/InsuranceInformationForm/InsuranceInformationForm'
import InsuranceDataForm from 'components/Forms/InsuranceDataForm/InsuranceDataForm'
import PrivateBankInformationForm from 'components/Forms/PrivateBankInformationForm/PrivateBankInformationForm'
import FurtherEmploymentsForm from 'components/Forms/FurtherEmploymentsForm/FurtherEmploymentsForm'
import FinalDeclarationsForm from 'components/Forms/FinalDeclarationsForm/FinalDeclarationsForm'
import SuccsessForm from 'components/Forms/SuccessForm/SuccessForm'
import SummaryFormEmployee from 'components/Forms/SummaryForm/SummaryFormEmployee'
import Overlay from 'components/Overlays/Overlay/Overlay'
import HelpOverForMinijobberForm from 'components/Overlays/HelpOver/fastdocs-staff/HelpOverForMinijobberForm/HelpOverForMinijobberForm'
import PensionInsuranceForm from 'components/Forms/PensionInsuranceForm/PensionInsuranceForm'
import NotificationCard from 'components/Notifications/NotificationCard/NotificationCard'
import { ReactComponent as InfoIcon } from 'static/InformationIcon.svg'
import {
  getFormStep,
  getProgressValue,
  getFormId,
  setDocumentTitleForEmployeeFormViews,
} from 'views/utility/utility.jsx'
import AutofillButton from 'helpers/autofill/AutofillButton'
import saveFormValuesInLocalStorage from 'views/utility/saveFormValuesInLocalStorage'
import clearFormValuesFromLocalStorage from 'views/utility/clearFormValuesFromLocalStorage'
import checkLocalStorageFor from 'views/utility/checkLocalStorageFor'
import moment from 'moment'
import { STAFF_FORMS } from 'constants/Formulars'

const database = firebase.database()

const EmployeeViewForStudentMinijobberForm = () => {
  const [formId] = useState(getFormId())
  const [capitalAccumulation, setCapitalAccumulation] = useState('')
  const [payingOption, setPayingOption] = useState('')
  const [formWasSubmittedBefore, setFormWasSubmittedBefore] = useState(false)
  const [formIdExist, setFormIdExist] = useState(true)
  const [formularName] = useState(STAFF_FORMS.STUDENT_MINIJOB)
  const [formStep, setFormStep] = useState(0)
  const totalFormSteps = 10
  const [helpover, setHelpover] = useState(false)
  const [formValues, setFormValues] = useState({
    date: moment().format('DD.MM.YYYY'),
    firstname: checkLocalStorageFor(formularName, 'firstname', ''),
    lastname: checkLocalStorageFor(formularName, 'lastname', ''),
    street: checkLocalStorageFor(formularName, 'street', ''),
    houseNumber: checkLocalStorageFor(formularName, 'houseNumber', ''),
    zipCode: checkLocalStorageFor(formularName, 'zipCode', ''),
    city: checkLocalStorageFor(formularName, 'city', ''),
    birthday: checkLocalStorageFor(formularName, 'birthday', ''),
    birthmonth: checkLocalStorageFor(formularName, 'birthmonth', ''),
    birthyear: checkLocalStorageFor(formularName, 'birthyear', ''),
    sex: checkLocalStorageFor(formularName, 'sex', 'Weiblich'),
    mariatialStatus: checkLocalStorageFor(formularName, 'mariatialStatus', ''),
    taxIdNumber: checkLocalStorageFor(formularName, 'taxIdNumber', ''),
    graduation: checkLocalStorageFor(
      formularName,
      'graduation',
      'ohne Schulabschluss'
    ),
    education: checkLocalStorageFor(
      formularName,
      'education',
      'ohne beruflichen Ausbildungabschluss'
    ),
    beginningStatus: checkLocalStorageFor(formularName, 'beginningStatus', ''),
    insuranceType: checkLocalStorageFor(
      formularName,
      'insuranceType',
      'Gesetzlich'
    ),
    insuranceCompanyName: checkLocalStorageFor(
      formularName,
      'insuranceCompanyName',
      ''
    ),
    insuranceNumber: checkLocalStorageFor(
      formularName,
      'insuranceNumber',
      true
    ),
    socialSecurityNumber: checkLocalStorageFor(
      formularName,
      'socialSecurityNumber',
      ''
    ),
    birthName: checkLocalStorageFor(formularName, 'birthName', ''),
    placeOfBirth: checkLocalStorageFor(formularName, 'placeOfBirth', ''),
    birthCountry: checkLocalStorageFor(formularName, 'birthCountry', ''),
    nationality: checkLocalStorageFor(formularName, 'nationality', ''),
    bankAccountHolder: checkLocalStorageFor(
      formularName,
      'bankAccountHolder',
      ''
    ),
    iban: checkLocalStorageFor(formularName, 'iban', ''),
    capitalAccumulationQuestion: checkLocalStorageFor(
      formularName,
      'capitalAccumulationQuestion',
      'Nein'
    ),
    capitalAccumulationBankAccountHolder: checkLocalStorageFor(
      formularName,
      'capitalAccumulationBankAccountHolder',
      ''
    ),
    capitalAccumulationIban: checkLocalStorageFor(
      formularName,
      'capitalAccumulationIban',
      ''
    ),
    contractNumber: checkLocalStorageFor(formularName, 'contractNumber', ''),
    capitalAccumulationValue: checkLocalStorageFor(
      formularName,
      'capitalAccumulationValue',
      ''
    ),
    furtherEmployments: checkLocalStorageFor(
      formularName,
      'furtherEmployments',
      false
    ),
    typeOfEmployment: checkLocalStorageFor(
      formularName,
      'typeOfEmployment',
      'Minijob bis 450€'
    ),
    furtherEmploymentCompanyName: checkLocalStorageFor(
      formularName,
      'furtherEmploymentCompanyName',
      ''
    ),
    payingPensionInsurance: checkLocalStorageFor(
      formularName,
      'payingPensionInsurance',
      'Nein'
    ),
    furtherMinijobEntryDay: checkLocalStorageFor(
      formularName,
      'furtherMinijobEntryDay',
      ''
    ),
    furtherMinijobEntryMonth: checkLocalStorageFor(
      formularName,
      'furtherMinijobEntryMonth',
      ''
    ),
    furtherMinijobEntryYear: checkLocalStorageFor(
      formularName,
      'furtherMinijobEntryYear',
      ''
    ),
    additionalMiniJob: checkLocalStorageFor(
      formularName,
      'additionalMiniJob',
      'Nein'
    ),
    additionalEmployement: checkLocalStorageFor(
      formularName,
      'additionalEmployement',
      'Nein'
    ),
    furtherEmploymentsTotalSallary: checkLocalStorageFor(
      formularName,
      'furtherEmploymentsTotalSallary',
      'Nein'
    ),
    bea: checkLocalStorageFor(formularName, 'bea', false),
    pensionInsuranceRequest: checkLocalStorageFor(
      formularName,
      'pensionInsuranceRequest',
      'Nein'
    ),
    request: checkLocalStorageFor(formularName, 'request', false),
    requestDeclaration: checkLocalStorageFor(
      formularName,
      'requestDeclaration',
      false
    ),
  })

  setDocumentTitleForEmployeeFormViews(formStep, totalFormSteps, formularName)

  const closeEdgeNotification = () => {
    const notificationCard =
      document.querySelector<HTMLElement>('.notification-card')
    if (notificationCard) {
      notificationCard.style.display = 'none'
    }
  }

  const showEdgeNotification = () => {
    const browser = detect()

    if (browser && browser.name === 'edge') {
      return (
        <NotificationCard
          icon={<InfoIcon />}
          title="Wichtiger Hinweis:"
          text="Dein Browser erwartet bei den Nachkommastellen eventuell einen Punkt statt eines Kommas."
          onClick={closeEdgeNotification}
        />
      )
    }
  }

  const setCapitalAccumulationValueSuggestion = () => {
    if (capitalAccumulation === 'Ja') {
      setFormValues({
        ...formValues,
        capitalAccumulationValue: '40.00',
      })
    }
  }

  useEffect(() => {
    const previousTypeOfEmployment =
      formValues.typeOfEmployment === 'Minijob bis 450€'
        ? 'Beschäftigung über 450€'
        : 'Minijob bis 450€'

    if (formValues.birthday.length === 1) {
      setFormValues({
        ...formValues,
        birthday: `0${formValues.birthday}`,
      })
    }
    if (formValues.birthmonth.length === 1) {
      setFormValues({
        ...formValues,
        birthmonth: `0${formValues.birthmonth}`,
      })
    }
    if (previousTypeOfEmployment !== formValues.typeOfEmployment) {
      setFormValues({
        ...formValues,
        additionalMiniJob: 'Nein',
        additionalEmployement: 'Nein',
      })
    }
    // eslint-disable-next-line
  }, [formValues.birthday, formValues.birthmonth, formValues.typeOfEmployment])

  useEffect(() => {
    database
      .ref(`jobs/${formId}`)
      .child('clientData')
      .child('firstname')
      .once('value')
      .then((snapshot) => {
        const firstname = snapshot.val()

        database
          .ref(`jobs/${formId}`)
          .child('clientData')
          .child('lastname')
          .once('value')
          .then((snapshot) => {
            if (!localStorage.firstname && !localStorage.lastname) {
              setFormValues({
                ...formValues,
                firstname: firstname,
                lastname: snapshot.val(),
              })
            }
          })
      })

    database
      .ref(`jobs/${formId}`)
      .child('clientData')
      .child('capitalAccumulation')
      .once('value')
      .then((snapshot) => setCapitalAccumulation(snapshot.val()))
      .then(() => setCapitalAccumulationValueSuggestion())

    database
      .ref(`jobs/${formId}`)
      .child('employeeData')
      .child('formWasSubmittedBefore')
      .once('value')
      .then((snapshot) => setFormWasSubmittedBefore(snapshot.val()))

    database
      .ref(`jobs/${formId}`)
      .child('clientData')
      .child('payingOption')
      .once('value')
      .then((snapshot) => setPayingOption(snapshot.val()))

    database
      .ref(`jobs/${formId}`)
      .child('clientData')
      .child('company')
      .once('value')
      .then((snapshot) => {
        if (snapshot.exists()) {
          return setFormIdExist(snapshot.val() ? true : false)
        }
        return setFormIdExist(false)
      })
      .catch(() => {
        return setFormIdExist(false)
      })
    // eslint-disable-next-line
  }, [])

  const renderNextFormStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFormStep(formStep + 1)

    saveFormValuesInLocalStorage({ ...formValues, formularName })
  }

  const renderPreviousFormStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFormStep(formStep - 1)
  }

  const handleInsuranceNumberChange = () => {
    setFormValues({
      ...formValues,
      insuranceNumber: !formValues.insuranceNumber,
    })
  }

  const handleFurtherEmploymentsChange = () => {
    setFormValues({
      ...formValues,
      furtherEmployments: !formValues.furtherEmployments,
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    e.preventDefault()

    database
      .ref('jobs/' + formId + '/employeeData')
      .set({
        ...formValues,
        formWasSubmittedBefore: true,
      })
      .then(() => {
        renderNextFormStep(e)
        clearFormValuesFromLocalStorage(formValues)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const renderHelpOver = () => {
    const handleHelpOverClose = () => {
      setHelpover(false)
    }

    return (
      <HelpOverForMinijobberForm
        formStep={formStep}
        onCloseButtonClick={handleHelpOverClose}
        className={helpover === true ? 'show' : ''}
        formId={formId}
      />
    )
  }

  const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.checked,
    })
  }

  const handleSuggestionClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFormValues({
      ...formValues,
      insuranceCompanyName: e.target.value,
    })
  }

  useEffect(() => {
    if (!formValues.insuranceNumber) {
      setFormValues({ ...formValues, socialSecurityNumber: '' })
    }
    // eslint-disable-next-line
  }, [formValues.insuranceNumber])

  const renderFormSteps = () => {
    switch (formStep) {
      case 0:
        return (
          <EmployeeInformationsForm
            onSubmit={renderNextFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            firstname={formValues.firstname}
            lastname={formValues.lastname}
            street={formValues.street}
            houseNumber={formValues.houseNumber}
            zipCode={formValues.zipCode}
            city={formValues.city}
            birthdate={[
              formValues.birthday,
              formValues.birthmonth,
              formValues.birthyear,
            ]}
            sex={formValues.sex}
            mariatialStatus={formValues.mariatialStatus}
            country=""
            formularName={formularName}
            matriculation=""
          />
        )
      case 1:
        return (
          <EducationForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            graduation={formValues.graduation}
            education={formValues.education}
          />
        )
      case 2:
        return (
          <BeginningStatusForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            beginningStatus={formValues.beginningStatus}
            taxIdNumber={formValues.taxIdNumber}
            onHelperButtonClick={() => setHelpover(true)}
            formularName={formularName}
            activityStatus=""
            denomination=""
            mariatialStatus={formValues.mariatialStatus}
            onTaxClassChange={() => {}}
            taxClass=""
          />
        )
      case 3:
        return (
          <InsuranceDataForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            insuranceType={formValues.insuranceType}
            insuranceCompanyName={formValues.insuranceCompanyName}
            onSuggestionClick={handleSuggestionClick}
            formularName={formularName}
          />
        )
      case 4:
        return (
          <InsuranceInformationForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            handleInsuranceNumberChange={handleInsuranceNumberChange}
            insuranceNumber={formValues.insuranceNumber}
            socialSecurityNumber={formValues.socialSecurityNumber}
            placeOfBirth={formValues.placeOfBirth}
            nationality={formValues.nationality}
            birthName={formValues.birthName}
            birthday={formValues.birthday}
            birthmonth={formValues.birthmonth}
            birthyear={formValues.birthyear}
            birthCountry={formValues.birthCountry}
            onHelperButtonClick={() => setHelpover(true)}
          />
        )
      case 5:
        return (
          <PrivateBankInformationForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            bankAccountHolder={formValues.bankAccountHolder}
            iban={formValues.iban}
            capitalAccumulation={capitalAccumulation}
            capitalAccumulationBankAccountHolder={
              formValues.capitalAccumulationBankAccountHolder
            }
            capitalAccumulationIban={formValues.capitalAccumulationIban}
            contractNumber={formValues.contractNumber}
            capitalAccumulationValue={formValues.capitalAccumulationValue}
            capitalAccumulationQuestion={formValues.capitalAccumulationQuestion}
            payingOption={payingOption}
          />
        )
      case 6:
        return (
          <FurtherEmploymentsForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            handleFurtherEmploymentsChange={handleFurtherEmploymentsChange}
            onHelperButtonClick={() => setHelpover(true)}
            furtherEmployments={formValues.furtherEmployments}
            typeOfEmployment={formValues.typeOfEmployment}
            furtherEmploymentCompanyName={
              formValues.furtherEmploymentCompanyName
            }
            furtherEmploymentsTotalSallary={
              formValues.furtherEmploymentsTotalSallary
            }
            additionalMiniJob={formValues.additionalMiniJob}
            additionalEmployement={formValues.additionalEmployement}
            formularName={formularName}
            payingPensionInsurance={formValues.payingPensionInsurance}
            activityStatus=""
            furtherMinijobEntryDay={formValues.furtherMinijobEntryDay}
            furtherMinijobEntryMonth={formValues.furtherMinijobEntryMonth}
            furtherMinijobEntryYear={formValues.furtherMinijobEntryYear}
            totalWeeklyWorkingTimeLimitForStudents=""
            operationTimeForStudents=""
            operationTimeIsLimitedToSemesterBreaks=""
            operationTimeIsExercisedForMoreThan26WeeksPerYear=""
          />
        )
      case 7:
        return (
          <PensionInsuranceForm
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onChange={handleInputChange}
            onHelperButtonClick={() => setHelpover(true)}
            pensionInsuranceRequest={formValues.pensionInsuranceRequest}
            request={formValues.request}
            requestDeclaration={formValues.requestDeclaration}
            onPensionRequestChange={handleCheckboxClick}
            onPensionRequestDeclarationChange={handleCheckboxClick}
            payingPensionInsurance={formValues.payingPensionInsurance}
            typeOfEmployment={formValues.typeOfEmployment}
            furtherEmployments={formValues.furtherEmployments}
          />
        )
      case 8:
        return (
          <SummaryFormEmployee
            onSubmit={renderNextFormStep}
            onSecondaryButtonClick={renderPreviousFormStep}
            onHelperButtonClick={() => setHelpover(true)}
            formularName={formularName}
            firstname={formValues.firstname}
            pensionInsuranceRequest={formValues.pensionInsuranceRequest}
            lastname={formValues.lastname}
            street={formValues.street}
            houseNumber={formValues.houseNumber}
            zipCode={formValues.zipCode}
            city={formValues.city}
            birthday={formValues.birthday}
            birthmonth={formValues.birthmonth}
            birthyear={formValues.birthyear}
            sex={formValues.sex}
            taxIdNumber={formValues.taxIdNumber}
            graduation={formValues.graduation}
            education={formValues.education}
            beginningStatus={formValues.beginningStatus}
            insuranceType={formValues.insuranceType}
            insuranceCompanyName={formValues.insuranceCompanyName}
            insuranceNumber={formValues.insuranceNumber}
            socialSecurityNumber={formValues.socialSecurityNumber}
            birthName={formValues.birthName}
            placeOfBirth={formValues.placeOfBirth}
            birthCountry={formValues.birthCountry}
            nationality={formValues.nationality}
            bankAccountHolder={formValues.bankAccountHolder}
            iban={formValues.iban}
            capitalAccumulationBankAccountHolder={
              formValues.capitalAccumulationBankAccountHolder
            }
            capitalAccumulationIban={formValues.capitalAccumulationIban}
            contractNumber={formValues.contractNumber}
            furtherEmployments={formValues.furtherEmployments}
            typeOfEmployment={formValues.typeOfEmployment}
            furtherEmploymentCompanyName={
              formValues.furtherEmploymentCompanyName
            }
            furtherEmploymentsTotalSallary={
              formValues.furtherEmploymentsTotalSallary
            }
            capitalAccumulationValue={formValues.capitalAccumulationValue}
            mariatialStatus={formValues.mariatialStatus}
            payingPensionInsurance={formValues.payingPensionInsurance}
            furtherMinijobEntryDay={formValues.furtherMinijobEntryDay}
            furtherMinijobEntryMonth={formValues.furtherMinijobEntryMonth}
            furtherMinijobEntryYear={formValues.furtherMinijobEntryYear}
          />
        )
      case 9:
        return (
          <FinalDeclarationsForm
            onSubmit={handleSubmit}
            onSecondaryButtonClick={renderPreviousFormStep}
            onHelperButtonClick={() => setHelpover(true)}
          />
        )
      default:
        return (
          <SuccsessForm
            successText={`Super ${formValues.firstname}, alles hat geklappt!`}
            formId={formId}
          >
            <p>
              Wenn die Steuerkanzlei deines Arbeitgebers alles verarbeitet hat,
              bekommst Du automatisch ein PDF von uns für deine Unterlagen.
              Dieses PDF musst Du für deinen Arbeitgeber noch unterschreiben und
              an ihn weiter geben.
            </p>
            <p>
              Auf Wiedersehen{' '}
              <span role="img" aria-label="Winkende-Hand">
                👋
              </span>
              .
            </p>
          </SuccsessForm>
        )
    }
  }

  return (
    <Fragment>
      <FormStage
        headlineText={'Formular für Studenten bis max. 450€.'}
        totalSteps={totalFormSteps}
        actualStep={getFormStep(formStep, totalFormSteps)}
        progress={getProgressValue(formStep, totalFormSteps)}
      />

      {renderFormSteps()}

      {formId === null ? (
        <Overlay
          headerText="Fehler"
          headlineText="Falsche oder keine Formular-ID gefunden"
          text="Du hast eine falsche, oder keine Formular-ID. Ohne Diese ID können wir Deone Eingaben nicht richtig zuordnen."
          additionalText="Vergewisser Dich bitte, ob Du den erhaltenen Link vollständig und richtig in den Browser übernnommen hast."
          type="error"
        />
      ) : null}

      {formWasSubmittedBefore === true ? (
        <Overlay
          headerText="Achtung"
          headlineText="Formular bereits ausgefüllt"
          text="Du hast das Formular bereits ausgefüllt und abgeschickt. Du kannst deine Angaben nachträglich nicht noch mal ändern."
          additionalText="Sollte dir ein Fehler unterlaufen sein, sprich bitte kurz mit deinem Arbeitgeber."
        />
      ) : null}

      {formIdExist === false ? (
        <Overlay
          type="error"
          headerText="Achtung"
          headlineText="Ungültige oder falsche Formular-ID"
          text="Für dieses Formular gibt es keine passende Formular-ID, die wir in unseren Systemen finden können."
        />
      ) : null}

      {renderHelpOver()}

      {showEdgeNotification()}

      <AutofillButton
        onClick={() =>
          setFormValues({
            ...formValues,
            ...employeeFormValues,
          })
        }
      />
    </Fragment>
  )
}

export default EmployeeViewForStudentMinijobberForm
