const consultantFormValues = {
  clientNumber: '11500',
  consultantNumber: '260814',
  staffNumber: '1000',
  autosuggestSearchValue: '72244 - Wirtschaftsprüfer/in und Steuerberater/in',
  activityKey: '72244',
  jobTitle: 'Rakentenwissenschaftler',
}

export default consultantFormValues
