import React from 'react'

import Accordion from '../../../../ui-components/accordions/Accordion/Accordion'
import helpOverContentForClients from './helpOverContentForClients.json'
import HelpOverBase from '../../HelpOverBase/HelpOverBase'

const renderHelpOverContent = (props) => {
  switch (props) {
    case 0:
      return helpOverContentForClients[0].generell.map((accordion, i) => (
        <Accordion
          formStep={props}
          headline={accordion.headline}
          url={accordion.url}
          urlText={accordion.urlText}
          text={accordion.text}
          example={accordion.example}
          key={i}
        />
      ))
    default:
      return (
        <div className="empty-hint">
          <h5>
            <span role="img" aria-label="Illustration">
              🤘
            </span>
            <br />
            Hier brauchst Du keine Hilfe
          </h5>
        </div>
      )
  }
}

class HelpOverForVehicleForm extends React.Component {
  render() {
    return (
      <HelpOverBase
        className={this.props.className}
        onCloseButtonClick={this.props.onCloseButtonClick}
        formId={this.props.formId}
      >
        {renderHelpOverContent(this.props.formStep)}
      </HelpOverBase>
    )
  }
}

export default HelpOverForVehicleForm
