import React from 'react'
import { Route } from 'react-router'

import ClientsViewForMinijobberForm from '../views/FormularViews/fasdocs-staff/MinijobberFormViews/ClientsViewForMinijobberForm'
import EmployeeViewForMinijobberForm from '../views/FormularViews/fasdocs-staff/MinijobberFormViews/EmployeeViewForMinijobberForm'
import ConsultantViewForMinijobberForm from '../views/FormularViews/fasdocs-staff/MinijobberFormViews/ConsultantViewForMinijobberForm'
import ClientsViewForStudentMinijobberForm from '../views/FormularViews/fasdocs-staff/StudentMinijobberFormViews/ClientsViewForStudentMinijobberForm'
import EmployeeViewForStudentMinijobberForm from '../views/FormularViews/fasdocs-staff/StudentMinijobberFormViews/EmployeeViewForStudentMinijobberForm'
import ConsultantViewForStudentMinijobberForm from '../views/FormularViews/fasdocs-staff/StudentMinijobberFormViews/ConsultantViewForStudentMinijobberForm'
import ClientsViewForStaffForm from '../views/FormularViews/fasdocs-staff/StaffFormViews/ClientsViewForStaffForm'
import EmployeeViewForStaffForm from '../views/FormularViews/fasdocs-staff/StaffFormViews/EmployeeViewForStaffForm'
import ConsultantViewForStaffForm from '../views/FormularViews/fasdocs-staff/StaffFormViews/ConsultantViewForStaffForm'
import ClientsViewForApprenticeForm from '../views/FormularViews/fasdocs-staff/ApprenticeFormViews/ClientsViewForApprenticeForm'
import EmployeeViewForApprenticeForm from '../views/FormularViews/fasdocs-staff/ApprenticeFormViews/EmployeeViewForApprenticeForm'
import ConsultantViewForApprenticeForm from '../views/FormularViews/fasdocs-staff/ApprenticeFormViews/ConsultantViewForApprenticeForm'
import ClientsViewForImmediateReportForm from '../views/FormularViews/fasdocs-staff/ImmediateReportForm/ClientsViewForImmediateReportForm'
import ConsultantViewForImmediateReportForm from '../views/FormularViews/fasdocs-staff/ImmediateReportForm/ConsultantViewForImmediateReportForm'
import ClientsViewForForeignEmployeesForm from '../views/FormularViews/fasdocs-staff/ForeignEmployeesFormViews/ClientsViewForForeignEmployeesForm'
import EmployeeViewForForeignEmployeesForm from '../views/FormularViews/fasdocs-staff/ForeignEmployeesFormViews/EmployeeViewForForeignEmployeesForm'
import ConsultantViewForForeignEmployeesForm from '../views/FormularViews/fasdocs-staff/ForeignEmployeesFormViews/ConsultantViewForForeignEmployeesForm'
import ClientsViewForVehicleForm from '../views/FormularViews/other-forms/VehicleFormView/ClientsViewForVehicleForm'
import ClientsViewForStudentForm from '../views/FormularViews/fasdocs-staff/StudentFormViews/ClientsViewForStudentForm'
import EmployeeViewForStudentForm from '../views/FormularViews/fasdocs-staff/StudentFormViews/EmployeeViewForStudentForm'
import ConsultantViewForStudentForm from '../views/FormularViews/fasdocs-staff/StudentFormViews/ConsultantViewForStudentForm'
import FormStatusView from '../views/FormStatusView/FormStatusView'

export default function StaffFormRoutes() {
  return (
    <>
      <Route
        path="/minijobber-form/client"
        component={ClientsViewForMinijobberForm}
      />
      <Route
        path="/minijobber-form/employee"
        component={EmployeeViewForMinijobberForm}
      />
      <Route
        path="/minijobber-form/consultant"
        component={ConsultantViewForMinijobberForm}
      />

      <Route path="/staff-form/client" component={ClientsViewForStaffForm} />
      <Route path="/staff-form/employee" component={EmployeeViewForStaffForm} />
      <Route
        path="/staff-form/consultant"
        component={ConsultantViewForStaffForm}
      />

      <Route
        path="/apprentice-form/client"
        component={ClientsViewForApprenticeForm}
      />
      <Route
        path="/apprentice-form/employee"
        component={EmployeeViewForApprenticeForm}
      />
      <Route
        path="/apprentice-form/consultant"
        component={ConsultantViewForApprenticeForm}
      />

      <Route
        path="/immediate-report/client"
        component={ClientsViewForImmediateReportForm}
      />
      <Route
        path="/immediate-report/consultant"
        component={ConsultantViewForImmediateReportForm}
      />

      <Route
        path="/student-minijob/client"
        component={ClientsViewForStudentMinijobberForm}
      />
      <Route
        path="/student-minijob/employee"
        component={EmployeeViewForStudentMinijobberForm}
      />
      <Route
        path="/student-minijob/consultant"
        component={ConsultantViewForStudentMinijobberForm}
      />

      <>
        <Route path="/student/client" component={ClientsViewForStudentForm} />
        <Route
          path="/student/employee"
          component={EmployeeViewForStudentForm}
        />
        <Route
          path="/student/consultant"
          component={ConsultantViewForStudentForm}
        />
      </>

      <Route
        path="/foreign-employee/client"
        component={ClientsViewForForeignEmployeesForm}
      />
      <Route
        path="/foreign-employee/employee"
        component={EmployeeViewForForeignEmployeesForm}
      />
      <Route
        path="/foreign-employee/consultant"
        component={ConsultantViewForForeignEmployeesForm}
      />

      <Route exact path="/vehicle-form" component={ClientsViewForVehicleForm} />
      <Route exact path="/status/:formId" component={FormStatusView} />
    </>
  )
}
