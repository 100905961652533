const data = {
  formularName: 'GbR (Gesellschaft bürgerlichen Rechts)',
  contactPerson: 'Andrea Steinhaus',
  annualFinancialReport: '2019',
  differentProfitDistribution: 'Ja',
  partnershipAgreement: 'Ja',
  companyName: 'Muster GbR',
  businessPurpose: 'Vermietung',
  foundingday: '01',
  foundingmonth: '01',
  foundingyear: '2010',
  businessStreet: 'Zeppelinstraße',
  businessHouseNumber: '1',
  businessZipCode: '42781',
  businessCity: 'Haan',
  businessPhoneNumber: '0212222222222',
  businessEmail: 'daniel@fastdocs.de',
  businessBankAccountHolder: 'Muster GbR',
  businessBankName: 'Deutsche Bank',
  businessIban: 'DE44100110012625484636',
  businessTaxOffice: 'Hilden',
  vatNumberAvailable: 'Ja',
  vatRegion: 'DE',
  vatNumber: '884738292',
  tradeRegisterEntry: 'Ja',
  registryCourt: 'Wuppertal',
  tradeRegisterNumber: 'HRB2293',
  academicTitle: 'Keine',
  firstname: 'Frank',
  lastname: 'Thelen',
  birthday: '27',
  birthmonth: '10',
  birthyear: '1989',
  denomination: 'rk - Römisch-Katholische Kirchensteuer',
  socialSecurityNumber: '',
  taxIdNumber: '86149736056',
  street: 'Moonland',
  houseNumber: '30',
  zipCode: '42697',
  city: 'Solingen',
  phoneNumber: '021222121212',
  email: 'daniel@fastdocs.de',
  businessTaxNumberAvailable: 'Ja',
  businessTaxNumber: '123/456/7890',
  fixedCapital: '1000000',
  capitalContribution: '70',
  typeOfIdentification: 'Personalausweis',
  placeOfBirth: 'Haan',
  pep: 'Nein',
  nationality: 'Deutsch',
  identityCardNumber: 'L7ZYH2710',
  issuingAuthority: 'Haan',
  issuedDay: '1',
  issuedMonth: '1',
  issuedYear: '2010',
  validDay: '1',
  validMonth: '1',
  validYear: '2030',
  privateTaxOffice: 'Hilden',
  privateTaxNumber: '135/5919/1398',
  limitedCompanyAcademicTitle: 'Dr.',
  limitedCompanyFirstname: 'Johan',
  limitedCompanyLastname: 'Lafer',
  limitedCompanyBirthday: '12',
  limitedCompanyBirthmonth: '02',
  limitedCompanyBirthyear: '1970',
  limitedCompanyDenomination: 'Konfessionslos / Keine Kirchensteuerberechnung',
  limitedCompanyTaxIdNumber: '86149736056',
  limitedCompanyStreet: 'Bahnhofstraße',
  limitedCompanyHouseNumber: '30',
  limitedCompanyZipCode: '42781',
  limitedCompanyCity: 'Haan',
  limitedCompanyPhoneNumber: '020392920192',
  limitedCompanyEmail: 'daniel@fastdoc.de',
  shareCapital: '5',
  secondPartnerTypeOfIdentification: 'Personalausweis',
  secondPartnerPlaceOfBirth: 'Berlin',
  secondPartnerNationality: 'Deutsch',
  secondPartnerIdentityCardNumber: 'L7ZYH2710',
  secondPartnerIssuingAuthority: 'Berlin',
  secondPartnerIssuedDay: '1',
  secondPartnerIssuedMonth: '1',
  secondPartnerIssuedYear: '2010',
  secondPartnerValidDay: '1',
  secondPartnerValidMonth: '1',
  secondPartnerValidYear: '2030',
  secondPartnerPep: 'Nein',
  limitedCompanyBusinessTaxOffice: 'Wuppertal-Elberfeld',
  limitedCompanyBusinessTaxNumber: '303/939/1192',
  additionalShareHolder: 'Nein',
  numberOfShareholders: 5,
  additionalShareholders: 'Ja',
  additionalShareholder1AcademicTitle: 'Keine',
  additionalShareholder1Firstname: 'Gesellschafter',
  additionalShareholder1Lastname: 'Drei',
  additionalShareholder1Birthday: '21',
  additionalShareholder1Birthmonth: '10',
  additionalShareholder1Birthyear: '2000',
  additionalShareholder1Denomination:
    'Konfessionslos / Keine Kirchensteuerberechnung',
  additionalShareholder1TaxIdNumber: '86149736056',
  additionalShareholder1Street: 'Wunderstraße',
  additionalShareholder1HouseNumber: '10',
  additionalShareholder1ZipCode: '42781',
  additionalShareholder1City: 'Haan',
  additionalShareholder1PhoneNumber: '02039302003',
  additionalShareholder1Email: 'daniel@fastdocs.de',
  additionalShareholder1fixedCapital: '',
  additionalShareholder1CapitalContribution: '5',
  additionalShareholder1TypeOfIdentification: 'Personalausweis',
  additionalShareholder1PlaceOfBirth: 'Solingen',
  additionalShareholder1Nationality: 'Deutsch',
  additionalShareholder1IdentityCardNumber: 'JFJSJ77392',
  additionalShareholder1IssuingAuthority: 'Haan',
  additionalShareholder1IssuedDay: '1',
  additionalShareholder1IssuedMonth: '1',
  additionalShareholder1IssuedYear: '2020',
  additionalShareholder1ValidDay: '1',
  additionalShareholder1ValidMonth: '1',
  additionalShareholder1ValidYear: '2030',
  additionalShareholder1Pep: 'Nein',
  additionalShareholder1PrivateTaxOffice: 'Bayreuth',
  additionalShareholder1PrivateTaxNumber: '135/5919/1398',
  additionalShareholder2AcademicTitle: 'Keine',
  additionalShareholder2Firstname: 'Gesellschafter',
  additionalShareholder2Lastname: 'Vier',
  additionalShareholder2Birthday: '01',
  additionalShareholder2Birthmonth: '01',
  additionalShareholder2Birthyear: '1900',
  additionalShareholder2Denomination:
    'Konfessionslos / Keine Kirchensteuerberechnung',
  additionalShareholder2TaxIdNumber: '86149736056',
  additionalShareholder2Street: 'Blastraße',
  additionalShareholder2HouseNumber: '29',
  additionalShareholder2ZipCode: '44920',
  additionalShareholder2City: 'Foostadt',
  additionalShareholder2PhoneNumber: '38939832481',
  additionalShareholder2Email: 'daniel@fastdocs.de',
  additionalShareholder2fixedCapital: '',
  additionalShareholder2CapitalContribution: '5',
  additionalShareholder2TypeOfIdentification: 'Personalausweis',
  additionalShareholder2PlaceOfBirth: 'Foostadt',
  additionalShareholder2Nationality: 'Deutsch',
  additionalShareholder2IdentityCardNumber: 'JGL99839D',
  additionalShareholder2IssuingAuthority: 'Haan',
  additionalShareholder2IssuedDay: '1',
  additionalShareholder2IssuedMonth: '1',
  additionalShareholder2IssuedYear: '2000',
  additionalShareholder2ValidDay: '1',
  additionalShareholder2ValidMonth: '1',
  additionalShareholder2ValidYear: '2010',
  additionalShareholder2Pep: 'Nein',
  additionalShareholder2PrivateTaxOffice: 'Solingen',
  additionalShareholder2PrivateTaxNumber: '135/5919/1398',
  additionalShareholder3AcademicTitle: 'Keine',
  additionalShareholder3Firstname: 'Gesellschafter',
  additionalShareholder3Lastname: 'Fünf',
  additionalShareholder3Birthday: '01',
  additionalShareholder3Birthmonth: '01',
  additionalShareholder3Birthyear: '1900',
  additionalShareholder3Denomination:
    'Konfessionslos / Keine Kirchensteuerberechnung',
  additionalShareholder3TaxIdNumber: '86149736056',
  additionalShareholder3Street: 'Wunderstraße',
  additionalShareholder3HouseNumber: '1',
  additionalShareholder3ZipCode: '44421',
  additionalShareholder3City: 'Wunderland',
  additionalShareholder3PhoneNumber: '231231231',
  additionalShareholder3Email: 'daniel@fastdocs.de',
  additionalShareholder3fixedCapital: '',
  additionalShareholder3CapitalContribution: '5',
  additionalShareholder3TypeOfIdentification: 'Personalausweis',
  additionalShareholder3PlaceOfBirth: 'Wuppertal',
  additionalShareholder3Nationality: 'Deutsch',
  additionalShareholder3IdentityCardNumber: 'L7ZYH2710',
  additionalShareholder3IssuingAuthority: 'Wuppertal',
  additionalShareholder3IssuedDay: '1',
  additionalShareholder3IssuedMonth: '1',
  additionalShareholder3IssuedYear: '2020',
  additionalShareholder3ValidDay: '1',
  additionalShareholder3ValidMonth: '1',
  additionalShareholder3ValidYear: '2030',
  additionalShareholder3Pep: 'Nein',
  additionalShareholder3PrivateTaxOffice: 'Wuppertal-Barmen',
  additionalShareholder3PrivateTaxNumber: '447/2838/882',
  additionalShareholder4AcademicTitle: 'Keine',
  additionalShareholder4Firstname: 'Gesellschaterin',
  additionalShareholder4Lastname: 'sechs',
  additionalShareholder4Birthday: '01',
  additionalShareholder4Birthmonth: '01',
  additionalShareholder4Birthyear: '1900',
  additionalShareholder4Denomination:
    'Konfessionslos / Keine Kirchensteuerberechnung',
  additionalShareholder4TaxIdNumber: '86149736056',
  additionalShareholder4Street: 'Foostraße',
  additionalShareholder4HouseNumber: '222',
  additionalShareholder4ZipCode: '42910',
  additionalShareholder4City: 'Blastadt',
  additionalShareholder4PhoneNumber: '2312312432',
  additionalShareholder4Email: 'daniel@fastdocs.de',
  additionalShareholder4fixedCapital: '',
  additionalShareholder4CapitalContribution: '5',
  additionalShareholder4TypeOfIdentification: 'Personalausweis',
  additionalShareholder4PlaceOfBirth: 'Haan',
  additionalShareholder4Nationality: 'Deutsch',
  additionalShareholder4IdentityCardNumber: 'L7ZYH2710',
  additionalShareholder4IssuingAuthority: 'Haan',
  additionalShareholder4IssuedDay: '1',
  additionalShareholder4IssuedMonth: '1',
  additionalShareholder4IssuedYear: '2020',
  additionalShareholder4ValidDay: '1',
  additionalShareholder4ValidMonth: '1',
  additionalShareholder4ValidYear: '2030',
  additionalShareholder4Pep: 'Nein',
  additionalShareholder4PrivateTaxOffice: 'Hilden',
  additionalShareholder4PrivateTaxNumber: '382/8839/910',
  additionalShareholder5AcademicTitle: 'Keine',
  additionalShareholder5Firstname: 'GesellschafterIn',
  additionalShareholder5Lastname: 'Sieben',
  additionalShareholder5Birthday: '01',
  additionalShareholder5Birthmonth: '01',
  additionalShareholder5Birthyear: '2000',
  additionalShareholder5Denomination: 'ev - Evangelische Kirchensteuer',
  additionalShareholder5TaxIdNumber: '86149736056',
  additionalShareholder5Street: 'Zuffenstraße',
  additionalShareholder5HouseNumber: '90',
  additionalShareholder5ZipCode: '39487',
  additionalShareholder5City: 'Zuffenhausen',
  additionalShareholder5PhoneNumber: '39483920',
  additionalShareholder5Email: 'daniel@fastdocs.de',
  additionalShareholder5fixedCapital: '',
  additionalShareholder5CapitalContribution: '5',
  additionalShareholder5TypeOfIdentification: 'Personalausweis',
  additionalShareholder5PlaceOfBirth: 'München',
  additionalShareholder5Nationality: 'Deutsch',
  additionalShareholder5IdentityCardNumber: 'L7ZYH2710',
  additionalShareholder5IssuingAuthority: 'München',
  additionalShareholder5IssuedDay: '1',
  additionalShareholder5IssuedMonth: '1',
  additionalShareholder5IssuedYear: '2020',
  additionalShareholder5ValidDay: '1',
  additionalShareholder5ValidMonth: '1',
  additionalShareholder5ValidYear: '2030',
  additionalShareholder5Pep: 'Nein',
  additionalShareholder5PrivateTaxOffice: 'München Erhebung',
  additionalShareholder5PrivateTaxNumber: '993/2929/330',
  taxOfficeProxy: 'Ja',
  order: true,
}

export default data
