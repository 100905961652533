import React, { Fragment, useEffect, useState } from 'react'
import { detect } from 'detect-browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import moment from 'moment'

import autofill from 'helpers/autofill/vehicleForm'

import {
  getFormStep,
  getProgressValue,
  getTaxConsultant,
} from 'views/utility/utility'

import NotificationCard from 'components/Notifications/NotificationCard/NotificationCard'
import FormStage from 'components/Stages/FormStage/FormStage'
import AutofillButton from 'helpers/autofill/AutofillButton'
import createFormId from 'views/utility/createFormId'
import SuccsessForm from 'components/Forms/fastdocs-client/SuccessForm/SuccessForm'
import VehicleForm from 'components/Forms/CarForm/VehicleForm'
import HelpOverForVehicleForm from 'components/Overlays/HelpOver/fastdocs-staff/HelpOverForVehicleForm/HelpOverForVehicleForm'

import { ReactComponent as InfoIcon } from 'static/InformationIcon.svg'

const ClientsViewForVehicleForm = () => {
  const [formId] = useState(createFormId())
  const [taxConsultant] = useState(getTaxConsultant())
  const [taxConsultantName, setTaxConsultantName] = useState('')
  const [taxConsultantEmail, setTaxConsultantEmail] = useState('')
  const [formStep, setFormStep] = useState(0)
  const totalFormSteps = 1
  const [helpover, setHelpover] = useState(false)
  const [clerks, setClerks] = useState({})
  const [showClerks, setShowClerks] = useState(false)
  const [formularName] = useState('Firmenfahrzeug für ArbeitnehmerInnen')
  const [formValues, setFormValues] = useState({
    company: '',
    employeeName: '',
    vehicleModel: '',
    vehicleRegistrationNumber: '',
    grossListPrice: '',
    typeOfVehicle: '',
    dayOfUse: '',
    monthOfUse: '',
    yearOfUse: '',
    clientEmail: '',
    selectedClerk: '',
  })
  const formCreationDate = moment().format('DD.MM.YYYY')
  document.title = formularName

  useEffect(() => {
    firebase
      .database()
      .ref('taxConsultant/' + taxConsultant)
      .once('value', (snapshot) => {
        const { name, email, clerks } = snapshot.val()

        setTaxConsultantName(name)
        setTaxConsultantEmail(email)
        setShowClerks(clerks && Object.keys(clerks).length > 0 ? true : false)
        setClerks(clerks ? { ...clerks } : null)
      })
  }, [formValues, taxConsultant, taxConsultantName])

  const renderHelpOver = () => {
    return (
      <HelpOverForVehicleForm
        formStep={formStep}
        onCloseButtonClick={() => setHelpover(false)}
        className={helpover === true ? 'show' : ''}
        formId={formId}
      />
    )
  }

  const showEdgeNotification = () => {
    const closeEdgeNotification = () => {
      const notificationCard =
        document.querySelector<HTMLElement>('.notification-card')
      if (notificationCard) {
        notificationCard.style.display = 'none'
      }
    }

    const browser = detect()

    if (browser && browser.name === 'edge') {
      return (
        <NotificationCard
          icon={<InfoIcon />}
          title="Wichtiger Hinweis:"
          text="Dein Browser erwartet bei den Nachkommastellen eventuell einen Punkt statt eines Kommas."
          onClick={closeEdgeNotification}
        />
      )
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    firebase
      .database()
      .ref('otherFormsJobs/' + formId)
      .set({
        formValues,
        formularName,
        taxConsultant,
        taxConsultantName,
        formCreationDate,
        taxConsultantEmail,
      })
      .then(() => {
        setFormStep(formStep + 1)
      })
      .catch((error) => {
        alert(error)
      })
  }

  const getListOfClerks = () => {
    const clerksList = clerks
      ? Object.entries(clerks).map((entry: any) => {
          return `${entry[1].name}`
        })
      : []
    clerksList.sort()
    clerksList.unshift('Unbekannt')

    return clerksList
  }

  const handleClerkChange = (e: any) => {
    Object.entries(clerks).forEach((clerk: any) => {
      if (e.target.value === clerk[1].name) {
        setFormValues({
          ...formValues,
          selectedClerk: clerk[1].email,
        })
      }
      if (e.target.value === 'Unbekannt') {
        setFormValues({
          ...formValues,
          selectedClerk: taxConsultantEmail,
        })
      }
    })
  }

  const renderFormSteps = () => {
    switch (formStep) {
      case 0:
        return (
          <VehicleForm
            formValues={formValues}
            onChange={(e) => {
              setFormValues({ ...formValues, [e.target.name]: e.target.value })
            }}
            onSubmit={handleSubmit}
            onHelperButtonClick={() => setHelpover(true)}
            showClerkSeletion={showClerks}
            listOfClerks={getListOfClerks()}
            onClerkChange={(e) => handleClerkChange(e)}
            selectedClerk={formValues.selectedClerk}
          />
        )
      default:
        return <SuccsessForm formId={formId} type="alternative"></SuccsessForm>
    }
  }

  return (
    <Fragment>
      <FormStage
        headlineText={formularName}
        totalSteps={totalFormSteps}
        actualStep={getFormStep(formStep, totalFormSteps)}
        progress={getProgressValue(formStep, totalFormSteps)}
      />

      {renderFormSteps()}
      {renderHelpOver()}
      {showEdgeNotification()}

      <AutofillButton
        onClick={() =>
          setFormValues({
            ...formValues,
            ...autofill,
          })
        }
      />
    </Fragment>
  )
}

export default ClientsViewForVehicleForm
