import React, { Component, Fragment } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import consultantFormValues from 'helpers/autofill/apprenticeForm/consultantFormValues'

import SplashStage from 'components/Stages/SplashStage/SplashStage'
import ConsultantDataForm from 'components/Forms/ConsultantDataForm/ConsultantDataForm'
import Overlay from 'components/Overlays/Overlay/Overlay'
import SuccsessForm from 'components/Forms/SuccessForm/SuccessForm'
import { getFormId, getTaxConsultant } from 'views/utility/utility.jsx'
import AutofillButton from 'helpers/autofill/AutofillButton'

const database = firebase.database()

interface State {
  formState: {
    formStep: number
    totalFormSteps: number
  }

  formId: string | null
  formIdExist: boolean
  clientStaffNumber: string
  company: string
  firstname: string
  lastname: string
  employedAs: string
  employmentLocation: string
  costCenter: string
  formularName: string
  contractOption: string

  formValues: {
    taxConsultantImportSystem: string
    clientNumber: string
    consultantNumber: string
    staffNumber: string
    autosuggestSearchValue: string
    activityKey: string
    jobTitle: string
    importOption: string
    importFile: string
    formWasSubmittedBefore: boolean
  }
}

class ConsultantViewForStaffForm extends Component {
  state: State = {
    formState: {
      formStep: 0,
      totalFormSteps: 1,
    },

    formId: getFormId(),
    formIdExist: true,
    clientStaffNumber: '',
    company: '',
    firstname: '',
    lastname: '',
    employedAs: '',
    employmentLocation: '',
    costCenter: '',
    formularName: '',
    contractOption: '',

    formValues: {
      clientNumber: '',
      consultantNumber: '',
      staffNumber: '',
      autosuggestSearchValue: '',
      activityKey: '',
      jobTitle: '',
      importOption: '',
      formWasSubmittedBefore: false,
      importFile: '',
      taxConsultantImportSystem: '',
    },
  }

  componentDidUpdate = () => {
    document.title = `${this.state.firstname} ${this.state.lastname} -  ${this.state.company} - ${this.state.formularName}`
  }

  getDatabaseData = () => {
    const consultant = database.ref(`taxConsultant/${getTaxConsultant()}`)
    const job = database.ref(`jobs/${this.state.formId}`)

    const formWasSubmittedBefore = job.child(
      'consultantData/formWasSubmittedBefore'
    )

    const formularName = job.child('clientData/formularName')
    const employmentLocation = job.child('clientData/employmentLocation')
    const staffNumber = job.child('clientData/staffNumber')

    const taxConsultantImportSystem = job.child(
      'clientData/taxConsultantImportSystem'
    )
    const company = job.child('clientData/company')
    const firstname = job.child('clientData/firstname')
    const lastname = job.child('clientData/lastname')
    const costCenter = job.child('clientData/costCenter')
    const employedAs = job.child('clientData/employedAs')
    const jobIdExist = job.child('clientData/company')

    const accountSetup = consultant
    accountSetup.once('value', (snapshot) => {
      const {
        consultantNumber,
        importSystem,
        contracts: {
          staff: { contractOption },
        },
      } = snapshot.val()

      this.setState({
        ...this.state,
        contractOption: contractOption,
        formValues: {
          ...this.state.formValues,
          consultantNumber: consultantNumber,
          taxConsultantImportSystem: importSystem,
        },
      })
    })

    formularName.once('value', (snapshot) => {
      this.setState({
        ...this.state,
        formularName: snapshot.val(),
      })
    })

    employmentLocation.once('value', (snapshot) => {
      this.setState({
        ...this.state,
        employmentLocation: snapshot.val(),
      })
    })

    staffNumber.once('value', (snapshot) => {
      this.setState({
        ...this.state,
        formValues: {
          ...this.state.formValues,
          staffNumber: snapshot.val(),
        },
        clientStaffNumber: snapshot.val(),
      })
    })

    taxConsultantImportSystem.once('value', (snapshot) => {
      this.setState({
        ...this.state,
        taxConsultantImportSystem: snapshot.val(),
      })
    })

    company.once('value', (snapshot) => {
      this.setState({
        ...this.state,
        company: snapshot.val(),
      })
    })

    firstname.once('value', (snapshot) => {
      this.setState({
        ...this.state,
        firstname: snapshot.val(),
      })
    })

    lastname.once('value', (snapshot) => {
      this.setState({
        ...this.state,
        lastname: snapshot.val(),
      })
    })

    costCenter.once('value', (snapshot) => {
      this.setState({
        ...this.state,
        costCenter: snapshot.val(),
      })
    })

    employedAs.once('value', (snapshot) => {
      this.setState({
        ...this.state,
        employedAs: snapshot.val(),
      })
    })

    jobIdExist.once('value', (snapshot) => {
      const jobExist = snapshot.val() ? true : false
      this.setState({
        ...this.state,
        formIdExist: jobExist,
      })
    })

    formWasSubmittedBefore.once('value', (snapshot) => {
      this.setState({
        ...this.state,
        formValues: {
          ...this.state.formValues,
          formWasSubmittedBefore: snapshot.val(),
        },
      })
    })
  }

  componentDidMount = () => {
    this.getDatabaseData()
  }

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [e.target.name]: e.target.value,
      },
    })
  }

  renderNextFormStep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const increaseFormStep = this.state.formState.formStep + 1

    e.preventDefault()
    this.setState({
      formState: {
        ...this.state.formState,
        formStep: increaseFormStep,
      },
    })
  }

  handleSubmit = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist()
    e.preventDefault()

    database.ref(`taxConsultant/${getTaxConsultant()}`).update({
      consultantNumber: this.state.formValues.consultantNumber,
    })

    database
      .ref('jobs/' + this.state.formId + '/consultantData')
      .set({
        ...this.state.formValues,
        formWasSubmittedBefore: true,
      })
      .then(() => {
        this.renderNextFormStep(e)
      })
      .catch((error) => {
        alert(error)
      })
  }

  updateAutosuggestSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        autosuggestSearchValue: e.target.value,
      },
    })
  }

  onClearButtonClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    this.setState({
      formValues: {
        ...this.state.formValues,
        autosuggestSearchValue: '',
        activityKey: '',
        jobTitle: '',
      },
    })
  }

  onOptionClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    this.setState({
      formValues: {
        ...this.state.formValues,
        autosuggestSearchValue: e.target.value + ' - ' + e.target.dataset.title,
        activityKey: e.target.value,
        jobTitle: e.target.dataset.title,
      },
    })
  }

  onImportOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        importOption: e.target.value,
      },
    })
  }

  renderFormSteps = () => {
    const accountHasProFeatures =
      this.state.contractOption === 'pro' ? true : false

    switch (this.state.formState.formStep) {
      case 0:
        return (
          <ConsultantDataForm
            importFile={this.state.formValues.importFile}
            accountHasProFeatures={accountHasProFeatures}
            onSubmit={this.handleSubmit}
            onChange={this.handleInputChange}
            clientNumber={this.state.formValues.clientNumber}
            consultantNumber={this.state.formValues.consultantNumber}
            staffNumber={this.state.formValues.staffNumber}
            company={this.state.company}
            firstname={this.state.firstname}
            lastname={this.state.lastname}
            employedAs={this.state.employedAs}
            clientStaffNumber={this.state.clientStaffNumber}
            autosuggestSearchValue={
              this.state.formValues.autosuggestSearchValue
            }
            activityKey={this.state.formValues.activityKey}
            jobTitle={this.state.formValues.jobTitle}
            updateAutosuggestSearchValue={this.updateAutosuggestSearchValue}
            onClearButtonClick={this.onClearButtonClick}
            onOptionClick={this.onOptionClick}
            importOption={this.state.formValues.importOption}
            taxConsultantImportSystem={
              this.state.formValues.taxConsultantImportSystem
            }
            employmentLocation={this.state.employmentLocation}
            costCenter={this.state.costCenter}
            formId={this.state.formId}
            formularName={this.state.formularName}
          />
        )
      default:
        return (
          <SuccsessForm
            successText="Mandanteninformationen erfolgreich hinzugefügt"
            formId={this.state.formId}
          >
            <p>
              Sobald {this.state.firstname} {this.state.lastname} die
              notwendigen Arbeitnehmerdaten bei uns hinterlegt hat, bekommst Du
              automatisch eine Zusammenfassung als PDF und die Import-Datei per
              E-Mail geschickt.
            </p>
            <p>
              Auf Wiedersehen{' '}
              <span role="img" aria-label="Winkende-Hand">
                👋
              </span>
              .
            </p>
          </SuccsessForm>
        )
    }
  }

  render() {
    return (
      <Fragment>
        <SplashStage headlineText="Formular für Auszubildende" />

        {this.renderFormSteps()}

        {this.state.formValues.formWasSubmittedBefore === true ? (
          <Overlay
            headerText="Achtung"
            headlineText="Formular bereits ausgefüllt"
            text="Du hast das Formular bereits ausgefüllt und abgeschickt. Du kannst deine Angaben nachträglich nicht noch mal ändern."
            additionalText="Sollte dir ein Fehler unterlaufen sein, wende Dich bitte an support@fastdocs.de"
          />
        ) : null}

        {this.state.formId === null ? (
          <Overlay
            headerText="Fehler"
            headlineText="Falsche oder keine Formular-ID gefunden"
            text="Du hast eine falsche, oder keine Formular-ID. Ohne Diese ID können wir Deone Eingaben nicht richtig zuordnen."
            additionalText="Vergewisser Dich bitte, ob Du den erhaltenen Link vollständig und richtig in den Browser übernnommen hast."
            type="error"
          />
        ) : null}

        {this.state.formIdExist === false ? (
          <Overlay
            type="error"
            headerText="Achtung"
            headlineText="Ungültige oder falsche Formular-ID"
            text="Für dieses Formular gibt es keine passende Formular-ID, die wir in unseren Systemen finden können."
          />
        ) : null}

        <AutofillButton
          onClick={() =>
            this.setState({
              formValues: {
                ...this.state.formValues,
                ...consultantFormValues,
              },
            })
          }
        />
      </Fragment>
    )
  }
}

export default ConsultantViewForStaffForm
