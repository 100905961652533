import React, { Fragment, useState } from 'react'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import IconButton from '../../ui-components/buttons/IconButton/IconButton'
import { ReactComponent as CrossIcon } from '../../../static/CrossIcon.svg'
import { ReactComponent as AlertIcon } from '../../../static/icons/48x48/alert-triangle-48.svg'
import { ReactComponent as BigEditIcon } from '../../../static/icons/48x48/edit-48.svg'
import { ReactComponent as MailIcon } from '../../../static/icons/48x48/mail-48.svg'
import { ReactComponent as EditIcon } from '../../../static/icons/16x16/edit-2-16.svg'
import { ReactComponent as CopyIcon } from '../../../static/icons/16x16/copy-16.svg'

import './JobDetailsDialog.scss'
import { Link } from 'react-router-dom'
import EmailInputField from '../../Inputs/EmailInputField/EmailInputField'
import ActionButton from '../../ui-components/buttons/ActionButton/ActionButton'
import { getTaxConsultant } from '../../../views/utility/utility'
import NumberInputField from '../../Inputs/NumberInputField/NumberInputField'
import StatusLabel from '../../ui-components/StatusLabel/StatusLabel'
interface Props {
  headline: string
  formularName: string
  formCreationDate: string
  entrydate: string
  company: string
  clientEmail: string
  firstname: string
  lastname: string
  employeeEmail: string
  deletionDay: string
  finishedDate: string
  form: string
  formId: string
  selectedClerk: string
  containerAnimation: string
  contentAnimation: string
  staffNumber: string
  employeeDataIsSet: boolean
  finishedJob: boolean
  consultantDataIsSet: boolean
  handleIconButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleDeleteFormClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const JobDetailsDialog = ({
  headline,
  formularName,
  formCreationDate,
  entrydate,
  company,
  clientEmail,
  firstname,
  lastname,
  employeeEmail,
  deletionDay,
  employeeDataIsSet,
  consultantDataIsSet,
  finishedDate,
  form,
  formId,
  containerAnimation,
  contentAnimation,
  finishedJob,
  staffNumber,
  selectedClerk,
  handleIconButtonClick,
  handleDeleteFormClick,
}: Props) => {
  const [editStaffNumber, setEditStaffNumber] = useState(false)
  const [initialStaffNumber, setInitialStaffNumber] = useState(staffNumber)
  const [newStaffNumber, setNewStaffNumber] = useState(initialStaffNumber)

  const [editEmployeeEmail, setEditEmployeeEmail] = useState(false)
  const [initialEmployeeEmail, setInitialEmployeeEmail] =
    useState(employeeEmail)
  const [newEmployeeEmail, setNewEmployeeEmail] = useState(initialEmployeeEmail)
  const [newEmployeeEmailButtonState, setNewEmployeeEmailButtonState] =
    useState('initial')

  const [alert, setAlert] = useState(false)
  const [employeeButtonState, setEmployeeButtonState] = useState('initial')
  const [
    importAndDocumentationButtonState,
    setImportAndDocumentationButtonState,
  ] = useState('initial')
  const [newStaffNumberButtonState, setNewStaffNumberButtonState] =
    useState('initial')

  const resendFormLinkEmailToEmployee = () => {
    setEmployeeButtonState('active')

    setTimeout(() => {
      firebase
        .database()
        .ref(`jobs/${formId}`)
        .update({
          sentJobCreationMailToEmployee: false,
        })
        .then(() => {
          setEmployeeButtonState('finished')

          setTimeout(() => {
            setEmployeeButtonState('initial')
          }, 1000)
        })
    }, 1000)
  }

  const resendImportAndDocumentation = () => {
    setImportAndDocumentationButtonState('active')

    setTimeout(() => {
      firebase
        .database()
        .ref(`jobs/${formId}`)
        .update({
          finishedJob: false,
        })
        .then(() => {
          setImportAndDocumentationButtonState('finished')

          setTimeout(() => {
            setImportAndDocumentationButtonState('initial')
          }, 1000)
        })
    }, 1000)
  }

  const renderButtons = () => {
    const renderConsultantFormLink = () => {
      if (!consultantDataIsSet) {
        return (
          <Link
            to={`/${form}/consultant/${formId}`}
            target="_blank"
            className="button center secondary"
          >
            Beraterformular öffnen
          </Link>
        )
      }
    }

    const renderResendEmployeeEmailButton = () => {
      if (employeeEmail && !employeeDataIsSet) {
        return (
          <ActionButton
            buttonState={employeeButtonState}
            content={{
              initial: 'Arbeitnehmerdaten erneut anfordern',
              finished: 'Gesendet',
            }}
            onClick={() => resendFormLinkEmailToEmployee()}
            disabled={employeeButtonState !== 'initial' ? true : false}
          />
        )
      }
    }

    const renderDeleteFormButton = () => {
      return (
        <button
          className="danger"
          onClick={() => {
            setAlert(true)
          }}
        >
          Vorgang löschen
        </button>
      )
    }

    if (finishedJob) {
      return (
        <Fragment>
          <ActionButton
            buttonState={importAndDocumentationButtonState}
            content={{
              initial: 'Importdatei und PDF-Dokumentation erneut senden',
              finished: 'Gesendet',
            }}
            onClick={() => resendImportAndDocumentation()}
            disabled={
              importAndDocumentationButtonState !== 'initial' ? true : false
            }
          />
          {renderDeleteFormButton()}
        </Fragment>
      )
    }
    return (
      <Fragment>
        {renderConsultantFormLink()}
        {renderResendEmployeeEmailButton()}
        {renderDeleteFormButton()}
      </Fragment>
    )
  }

  const renderAlertContent = () => {
    if (alert) {
      return (
        <div className="job-details-container--alert">
          <main>
            <AlertIcon />
            <h5>Soll der Vorgang wirklich gelöscht werden?</h5>

            <button
              className="secondary full-width"
              onClick={handleDeleteFormClick}
            >
              Ja, löschen
            </button>

            <button
              className="danger full-width"
              onClick={() => {
                setAlert(false)
              }}
            >
              Nein, nicht löschen
            </button>

            <p>Gelöschte Daten können nicht wiederhergestellt werden.</p>
          </main>
        </div>
      )
    }
  }

  const updateEmployeeEmail = (e: any) => {
    if (!e.preventDefault() && newEmployeeEmail.includes('.')) {
      setNewEmployeeEmailButtonState('active')

      setTimeout(() => {
        firebase
          .database()
          .ref(`jobs/${formId}/clientData`)
          .update({
            employeeEmail: newEmployeeEmail,
          })
          .then(() => {
            setNewEmployeeEmailButtonState('finished')
            setInitialEmployeeEmail(newEmployeeEmail)

            setTimeout(() => {
              setNewEmployeeEmailButtonState('initial')
              setEditEmployeeEmail(false)
              setNewEmployeeEmail('')
            }, 1000)
          })
      }, 1000)
    }
  }

  const updateStaffNumber = (e: any) => {
    if (!e.preventDefault()) {
      setNewStaffNumberButtonState('active')

      setTimeout(() => {
        firebase
          .database()
          .ref(`jobs/${formId}/consultantData`)
          .update({
            staffNumber: newStaffNumber,
          })
          .then(() => {
            setNewStaffNumberButtonState('finished')
            setInitialStaffNumber(newStaffNumber)

            setTimeout(() => {
              setNewStaffNumberButtonState('initial')
              setEditStaffNumber(false)
              setNewStaffNumber('')
            }, 1000)
          })
      }, 1000)
    }
  }

  const renderEditEmployeeEmailContent = () => {
    if (editEmployeeEmail) {
      return (
        <div className="job-details-container--edit">
          <main>
            <MailIcon />
            <h5>E-Mail-Adresse editieren</h5>

            <form onSubmit={(e) => updateEmployeeEmail(e)}>
              <EmailInputField
                label=""
                name="employeeEmail"
                placeholder={initialEmployeeEmail}
                defaultValue={initialEmployeeEmail}
                onChange={(e: any) => setNewEmployeeEmail(e.target.value)}
              />

              <ActionButton
                buttonState={newEmployeeEmailButtonState}
                content={{
                  initial: 'Aktualisieren',
                  finished: 'Adresse ist aktualisiert',
                }}
                type="submit"
                disabled={
                  initialEmployeeEmail === newEmployeeEmail ? true : false
                }
                fullWidth
                buttonStyle="primary"
              />

              <button
                className="secondary full-width"
                onClick={() => {
                  setEditEmployeeEmail(false)
                }}
              >
                Abbrechen
              </button>
            </form>
          </main>
        </div>
      )
    }
  }

  const renderEditStaffNumberContent = () => {
    if (editStaffNumber) {
      return (
        <div className="job-details-container--edit">
          <main>
            <BigEditIcon />
            <h5>Personalnummer editieren</h5>

            <form onSubmit={(e) => updateStaffNumber(e)}>
              <NumberInputField
                label=""
                name="staffNumber"
                placeholder={initialStaffNumber}
                defaultValue={initialStaffNumber}
                onChange={(e: any) => setNewStaffNumber(e.target.value)}
              />

              <ActionButton
                buttonState={newStaffNumberButtonState}
                content={{
                  initial: 'Aktualisieren',
                  finished: 'Aktualisiert',
                }}
                type="submit"
                disabled={initialStaffNumber === newStaffNumber ? true : false}
                fullWidth
                buttonStyle="primary"
              />

              <button
                className="secondary full-width"
                onClick={() => {
                  setEditStaffNumber(false)
                }}
              >
                Abbrechen
              </button>
            </form>
          </main>
        </div>
      )
    }
  }

  const renderDialogContent = () => {
    const employeeFormLink =
      process.env.NODE_ENV === 'development'
        ? `http://localhost:3000/${form}/employee/${formId}`
        : `https://${getTaxConsultant()}.fastdocs.de/${form}/employee/${formId}`

    const copyToClipboard = () => {
      const link = document.getElementById(
        'employeeFormLink'
      ) as HTMLInputElement
      link?.focus()
      link?.select()
      document.execCommand('copy')
      link?.blur()
    }

    return (
      <Fragment>
        <div className="job-details-container--header">
          <p>Formular-ID: {headline}</p>
          <IconButton onClick={handleIconButtonClick}>
            <CrossIcon />
          </IconButton>
        </div>

        <div className="job-details-container--status">
          <header>
            <p>Status des Vorgangs</p>
          </header>

          <main>
            <label>
              Arbeitgeber:in
              <StatusLabel label="Daten hinterlegt" status="finished" />
            </label>
            {employeeEmail ? (
              <label>
                Arbeitnehmer:in
                <StatusLabel
                  label={
                    employeeDataIsSet
                      ? 'Daten hinterlegt'
                      : 'Daten nicht hinterlegt'
                  }
                  status={employeeDataIsSet ? 'finished' : 'open'}
                />
              </label>
            ) : null}
            <label>
              Sachbearbeiter:in
              <StatusLabel
                label={
                  consultantDataIsSet
                    ? 'Daten hinterlegt'
                    : 'Daten nicht hinterlegt'
                }
                status={consultantDataIsSet ? 'finished' : 'open'}
              />
            </label>
          </main>
        </div>

        <div className="job-details-container--details">
          <main>
            <header>
              <p>Details zum Mandat</p>
            </header>
            <table cellPadding="0" cellSpacing="0">
              <tbody>
                <tr>
                  <th>Unternehmen</th>
                  <td>{company}</td>
                </tr>

                <tr>
                  <th>E-Mail</th>
                  <td>{clientEmail}</td>
                </tr>
              </tbody>
            </table>

            <header>
              <p>Details zur Arbeitskraft</p>
            </header>
            <table cellPadding="0" cellSpacing="0">
              <tbody>
                <tr>
                  <th>Vorname</th>
                  <td>{firstname}</td>
                </tr>

                <tr>
                  <th>Nachname</th>
                  <td>{lastname}</td>
                </tr>

                <tr>
                  <th>Personalnummer</th>
                  <td>
                    <span>
                      {initialStaffNumber || '–'}{' '}
                      {initialStaffNumber ? (
                        <button
                          title="Editieren"
                          className="icon ghost"
                          onClick={() => {
                            setEditStaffNumber(true)
                            setNewStaffNumber(initialStaffNumber)
                          }}
                        >
                          <EditIcon />
                        </button>
                      ) : null}
                    </span>
                  </td>
                </tr>

                <tr>
                  <th>Eintrittsdatum</th>
                  <td>{entrydate}</td>
                </tr>

                <tr>
                  <th>E-Mail</th>
                  <td>
                    <span>
                      {initialEmployeeEmail || '–'}{' '}
                      {initialEmployeeEmail ? (
                        <button
                          title="Editieren"
                          className="icon ghost"
                          onClick={() => {
                            setEditEmployeeEmail(true)
                            setNewEmployeeEmail(initialEmployeeEmail)
                          }}
                        >
                          <EditIcon />
                        </button>
                      ) : null}
                    </span>
                  </td>
                </tr>

                <tr>
                  <th>Formular-Link</th>
                  <td>
                    {initialEmployeeEmail ? (
                      <>
                        <span>
                          <textarea
                            id="employeeFormLink"
                            defaultValue={employeeFormLink}
                            readOnly
                          />
                          <button
                            title="Kopieren"
                            className="icon ghost"
                            onClick={() => copyToClipboard()}
                          >
                            <CopyIcon />
                          </button>
                        </span>
                      </>
                    ) : (
                      '–'
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <header>
              <p>Details zum Vorgang</p>
            </header>
            <table cellPadding="0" cellSpacing="0">
              <tbody>
                <tr>
                  <th>Formular</th>
                  <td>{formularName}</td>
                </tr>

                {selectedClerk && (
                  <tr>
                    <th>Sachbearbeiter:in</th>
                    <td>{selectedClerk}</td>
                  </tr>
                )}

                <tr>
                  <th>Angelegt am</th>
                  <td>{formCreationDate}</td>
                </tr>

                {finishedDate ? (
                  <tr>
                    <th>Fertiggestellt am</th>
                    <td>{finishedDate}</td>
                  </tr>
                ) : null}

                <tr>
                  <th>Wird Gelöscht zum</th>
                  <td>{deletionDay}</td>
                </tr>
              </tbody>
            </table>
          </main>
        </div>

        <div className="job-details-container--options">{renderButtons()}</div>

        {renderAlertContent()}
        {renderEditEmployeeEmailContent()}
        {renderEditStaffNumberContent()}
      </Fragment>
    )
  }

  return (
    <div className={`job-details-container ${containerAnimation}`}>
      <div className="job-details-container--wrapper">
        <div className={`job-details-container--content ${contentAnimation}`}>
          {renderDialogContent()}
        </div>
      </div>
    </div>
  )
}

export default JobDetailsDialog
