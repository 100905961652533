const clientFormValues = {
  entryday: '01',
  entrymonth: (new Date().getMonth() + 2).toString(),
  entryyear: new Date().getFullYear().toString(),
  company: '[TEST] Audi Forum Ingolstadt',
  capitalAccumulation: 'Ja',
  employedAs: 'Veranstaltungskauffrau',
  firstname: 'Andrea',
  friday: '3.95',
  employmentLocation: 'Ingolstadt',
  lastname: 'Miczalski',
  monday: '0',
  monthlyProportion: '40',
  sallaryValue: '410',
  saturday: '4',
  sunday: '2',
  thursday: '0',
  tuesday: '0',
  wednesday: '0',
  contractForm: 'Unbefristet Teilzeit',
  typeOfEmployee: 'Angestellter',
  otherInformation:
    '- Arbeitet in der Abteilung X\n- Wird nach dem 20. ausgezahlt',
}

export default clientFormValues
