export const validateSocialSecurityNumber = inputValue => {
  if (inputValue.length === 12) {
    inputValue.split('')
    const getLetterValue = () => {
      const getLetterFromInputValue = inputValue[8]
      switch (getLetterFromInputValue) {
        case 'A':
          return '01'
        case 'B':
          return '02'
        case 'C':
          return '03'
        case 'D':
          return '04'
        case 'E':
          return '05'
        case 'F':
          return '06'
        case 'G':
          return '07'
        case 'H':
          return '08'
        case 'I':
          return '09'
        case 'J':
          return '10'
        case 'K':
          return '11'
        case 'L':
          return '12'
        case 'M':
          return '13'
        case 'N':
          return '14'
        case 'O':
          return '15'
        case 'P':
          return '16'
        case 'Q':
          return '17'
        case 'R':
          return '18'
        case 'S':
          return '19'
        case 'T':
          return '20'
        case 'U':
          return '21'
        case 'V':
          return '22'
        case 'W':
          return '23'
        case 'X':
          return '24'
        case 'Y':
          return '25'
        case 'Z':
          return '26'
        default:
          return '00'
      }
    }
    const sum =
      (inputValue[0] * 2).toString() +
      (inputValue[1] * 1).toString() +
      (inputValue[2] * 2).toString() +
      (inputValue[3] * 5).toString() +
      (inputValue[4] * 7).toString() +
      (inputValue[5] * 1).toString() +
      (inputValue[6] * 2).toString() +
      (inputValue[7] * 1).toString() +
      (getLetterValue().split('')[0] * 2).toString() +
      (getLetterValue().split('')[1] * 1).toString() +
      (inputValue[9] * 2).toString() +
      (inputValue[10] * 1).toString()

    const newSum = sum.split('').reduce((acc, val) => {
      return Number(acc) + Number(val)
    })

    const proof = newSum % 10

    if (Number(inputValue[11]) === proof) {
      return true
    } else {
      return false
    }
  }
}
