import setMinimumWage from 'components/utility/setMinimumWage'
import getTotalWeekHoursResult from './getTotalWeekHoursResult'

const isUnderMinimumWage = (
  sallary: string,
  employeeAge: string | undefined,
  entrydate: string,
  sallaryValue: string,
  days: {
    monday: string
    tuesday: string
    wednesday: string
    thursday: string
    friday: string
    saturday: string
    sunday: string
  }
) => {
  const monthlyHours = Number((getTotalWeekHoursResult(days).asNumber * 13) / 3)
  const hourlyWage = Number(Number(sallaryValue) / monthlyHours)
  const minimumWage = setMinimumWage(entrydate, employeeAge)

  if (
    sallary === 'Festes monatliches Gehalt' &&
    (employeeAge === '18 Jahre oder älter' || employeeAge === undefined)
  ) {
    return hourlyWage < minimumWage
  }
}

export default isUnderMinimumWage
