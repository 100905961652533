import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { detect } from 'detect-browser'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'

import AppHeader from './components/Headers/AppHeader/AppHeader'
import BrowserErrorState from './components/ErrorStates/BrowserErrorState/BrowserErrorState'
import Overlay from './components/Overlays/Overlay/Overlay'
import LoadingIndicator from './components/ui-components/LoadingIndicator/LoadingIndicator'

import ScrollToTop from './views/utility/ScrollToTop'
import { getTaxConsultant, setStorage } from './views/utility/utility'

import IndexRoutes from './routes/IndexRoutes'
import { DashboardRoutes } from './routes/DashboardRoutes'
import StaffFormRoutes from './routes/StaffFormRoutes'
import ClientFormRoutes from './routes/ClientFormRoutes'

import 'normalize.css'
import './App.scss'
import MaintenanceMode from 'components/Notifications/MaintenanceMode/MaintenanceMode'

const browser = detect()
const db = firebase.database()
const storage = firebase.app().storage(setStorage())

const App = () => {
  const [logo, setLogo] = useState('')
  const [loading, setLoading] = useState(true)
  const [accountIsValid, setAccountIsValid] = useState(true)
  const [accountSetup, setAccountSetup] = useState({
    contracts: {
      staff: {
        contract: false,
        contractOption: '',
      },
      client: {
        contract: false,
        contractOption: '',
      },
    },
    features: {
      sepa: false,
      costCenter: false,
      staffNumber: false,
    },
  })

  useEffect(() => {
    storage
      .ref(`${getTaxConsultant()}/logo`)
      .getDownloadURL()
      .then((downloadURL) => {
        if (downloadURL) {
          setLogo(downloadURL)
        }
      })
      .catch(() => {})

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    db.ref(`taxConsultant/${getTaxConsultant()}`)
      .once('value', (snapshot) => {
        if (snapshot.exists()) {
          setAccountIsValid(true)
        } else {
          console.error('This account maybe not existing in database')
          setAccountIsValid(false)
        }
      })
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val()
          const features = data.features ? data.features : accountSetup.features
          const { contracts } = data

          setAccountSetup({
            ...accountSetup,
            features,
            contracts,
          })
        }
      })
      .then(() => {
        setLoading(false)
      })

    // eslint-disable-next-line
  }, [])

  const renderErrorOverlay = () => {
    if (!accountIsValid) {
      document.documentElement.style.overflow = 'hidden'

      return (
        <Overlay
          headerText="Fehler"
          headlineText="Der Account existiert nicht."
          text="Wir konnten diesen Account leider nicht zuordnen."
          type="error"
        />
      )
    }
  }

  const renderAppViews = () => {
    // if (browser && browser.name === 'ie') return <BrowserErrorState />
    // if (loading) return <LoadingIndicator center />
    // if (!accountIsValid) {
    //   return (
    //     <>
    //       <AppHeader logo={logo} />
    //       {renderErrorOverlay()}
    //     </>
    //   )
    // }

    if (
      getTaxConsultant() === 'test-account' ||
      getTaxConsultant() === 'dev-test-account'
    ) {
      return (
        <>
          <ScrollToTop />
          <AppHeader logo={logo} />
          <IndexRoutes contracts={accountSetup.contracts} />
          <DashboardRoutes logo={logo} contracts={accountSetup.contracts} />
          <StaffFormRoutes />
          <ClientFormRoutes features={accountSetup.features} />
        </>
      )
    }

    return <MaintenanceMode />
  }

  return (
    <>
      <Router>{renderAppViews()}</Router>
    </>
  )
}

export default App
