import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/auth'

import TextInputField from '../../../components/Inputs/TextInputField/TextInputField'
import SelectField from '../../../components/Selects/SelectField/SelectField'

import { getTaxConsultant } from '../../utility/utility'

import image from '../../../static/images/integration-5f+fastdocs.png'
import './FiveFSoftware.scss'
import FormHint from '../../../components/ui-components/hints/FormHint'
import LoadingIndicator from '../../../components/ui-components/LoadingIndicator/LoadingIndicator'
import fiveFapi from '../../../configs/fiveFapi'
import { Link } from 'react-router-dom'

const FiveFSoftware = () => {
  document.title = '5F-Software Integration für Fastdocs'
  const [formStep, setFormStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState({
    taxConsultant: getTaxConsultant(),
    email: '',
    authCode: '',
    errorMessage: false,
    organizations: [],
    userId: '',
    selectedOrganisation: '',
  })

  useEffect(() => {
    firebase
      .database()
      .ref('taxConsultant/' + formValues.taxConsultant)
      .child('integrations')
      .once('value')
      .then((snapshot) => {
        if (snapshot.val()) {
          const { fiveFSoftware } = snapshot.val()

          if (fiveFSoftware) {
            setFormStep(4)
          }
        }
      })

    // eslint-disable-next-line
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    formValues.organizations.map((organization) => {
      const { name, id } = organization

      if (name === formValues.selectedOrganisation) {
        setLoading(true)
        firebase
          .database()
          .ref('taxConsultant/' + formValues.taxConsultant)
          .update({
            integrations: {
              fiveFSoftware: {
                organizationId: id,
                userId: formValues.userId,
              },
            },
          })
          .then(() => {
            setLoading(false)
            setFormStep(formStep + 1)
          })
          .catch((error) => {
            alert(error)
          })
      }

      return null
    })
  }

  const subscribeUser = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    axios({
      method: 'POST',
      url: `${
        fiveFapi(formValues.taxConsultant).url
      }/api/v2/user/services/fastdocs/subscribe`,
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'json',
      data: {
        data: {
          attributes: {
            email: formValues.email,
            password: formValues.authCode,
          },
        },
      },
    })
      .then((response) => {
        setLoading(false)
        setFormValues({
          ...formValues,
          errorMessage: false,
          organizations: response.data.data.attributes.organizations,
          userId: response.data.data.id,
        })
        setFormStep(formStep + 1)
      })
      .catch(() => {
        setLoading(false)
        setFormValues({
          ...formValues,
          errorMessage: true,
        })
      })
  }

  const getOptions = () => {
    return formValues.organizations.map((organization) => {
      const { name } = organization
      return [name]
    })
  }

  const renderFormSteps = () => {
    switch (formStep) {
      case 0:
        return (
          <div className="card-dark content">
            <header>
              <img src={image} alt="" />
            </header>
            <main>
              <h5>5F-Software und Fastdocs Staff verknüpfen.</h5>
              <p>
                Bevor Du mit der Verknüpfung von 5F-Software und Fastdocs Staff
                beginnst, solltest Du sicherstellen, dass Dein 5F-Konto für die
                Integration von Fastdocs vorbereitet ist und die entsprechende
                Rechte vorhanden sind.
              </p>
              <p>
                Wirf am besten einen Blick in unsere{' '}
                <a
                  href="https://fastdocs.freshdesk.com/support/solutions/articles/44002168341-die-5f-collaboration-plattform-integrieren"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Anleitung
                </a>
                , bevor Du mit der Verknüpfung beginnst.
              </p>
              <p>
                Hier wird Schritt für Schritt erklärt, wie die beiden Konten
                verbunden werden.
              </p>
            </main>
            <footer>
              <button
                className="primary full-width"
                onClick={() => {
                  setFormStep(formStep + 1)
                }}
              >
                Mit der Verknüpfung starten
              </button>
            </footer>
          </div>
        )
      case 1:
        return (
          <form
            className="card-dark content"
            onSubmit={(e) => subscribeUser(e)}
          >
            <header>
              <img src={image} alt="" />
            </header>

            {loading ? (
              <LoadingIndicator size="small" />
            ) : (
              <Fragment>
                <main>
                  <h5>
                    Geben Sie Ihre 5F-Authentifizierungs-Daten ein und
                    verknüpfen Sie Ihren Fastdocs Account mit Ihrem 5F-Account.
                  </h5>

                  <TextInputField
                    label="E-Mail"
                    placeholder="info@meine-kanzlei.de"
                    name="email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e)
                    }
                    required
                    defaultValue={formValues.email}
                  />
                  <TextInputField
                    label="Authentifizierungs-Code"
                    placeholder="101530"
                    name="authCode"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(e)
                    }
                    required
                    defaultValue={formValues.authCode}
                    maxLength={6}
                  />

                  {formValues.errorMessage ? (
                    <FormHint hintHeadline="Authentifizierung fehlgeschlagen">
                      <p>Bitte prüfen Sie die Login-Daten.</p>
                    </FormHint>
                  ) : null}
                </main>

                <footer>
                  <button className="primary full-width">
                    Verknüpfung starten
                  </button>
                  <a
                    href="https://fastdocs.freshdesk.com/support/solutions/articles/44002168341-die-5f-collaboration-plattform-integrieren"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Ich brauche Hilfe
                  </a>
                </footer>
              </Fragment>
            )}
          </form>
        )
      case 2:
        return (
          <form className="card-dark content" onSubmit={(e) => handleSubmit(e)}>
            <header>
              <img src={image} alt="" />
            </header>
            {loading ? (
              <LoadingIndicator size="small" />
            ) : (
              <Fragment>
                <main>
                  <h5>Wählen Sie Ihre Organisation aus.</h5>
                  <SelectField
                    label="Organisation"
                    name="selectedOrganisation"
                    noDefault
                    options={getOptions()}
                    defaultValue={formValues.selectedOrganisation}
                    onChange={handleChange}
                  />
                </main>

                <footer>
                  <button className="primary full-width">
                    Mit Organisation verknüpfen
                  </button>
                </footer>
              </Fragment>
            )}
          </form>
        )
      case 3:
        return (
          <form
            className="card-dark content"
            onSubmit={(e) => e.preventDefault()}
          >
            <header>
              <img src={image} alt="" />
            </header>

            <main>
              <h5>Verknüpfung erfolgreich!</h5>

              <p>
                Sie haben Ihren Fastdocs-Account nun erfolgreich mit Ihrem
                5F-Account verknüft.
              </p>
              <p>
                Alle Vorgänge werden Ihnen nun in Ihrem 5F-Dashboard angezeigt.
              </p>
              <br />
              <Link to="/dashboard/settings/integrations">
                <button className="primary full-width">
                  Zurück zum Dashboard
                </button>
              </Link>
            </main>
          </form>
        )
      default:
        return (
          <form
            className="card-dark content"
            onSubmit={(e) => e.preventDefault()}
          >
            <header>
              <img src={image} alt="" />
            </header>

            <main>
              <h5>Bestehende Verknüpfung.</h5>

              <p>
                Sie haben Ihren Fastdocs-Account bereits mit einem bestehenden
                5F-Account verknüft.
              </p>
              <p>
                Alle Vorgänge werden Ihnen nun in Ihrem 5F-Dashboard angezeigt.
              </p>
              <br />
              <Link to="/dashboard/settings/integrations">
                <button className="primary full-width">
                  Zurück zum Dashboard
                </button>
              </Link>
            </main>
          </form>
        )
    }
  }
  return <div className="five-f-software">{renderFormSteps()}</div>
}

export default FiveFSoftware
