import React from 'react'

import FormContainer from '../../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../../ui-components/forms/FormFooter/FormFooter'
import HelperButton from '../../../ui-components/buttons/HelperButton/HelperButton'
import TwoColumns from '../../../ui-components/layouts/TwoColumns/TwoColumns'
import RadioInputField from '../../../Inputs/RadioInputField/RadioInputField'

import classes from './TaxOfficeProxyForm.module.scss'

interface Props {
  values: {
    taxOfficeProxy: string
  }
  onHelperButtonClick: () => void
  onNextButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onBackButtonClick: (e: React.FormEvent<HTMLFormElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const TaxOfficeProxyForm = (props: Props) => {
  const {
    values: { taxOfficeProxy },
    onHelperButtonClick,
    onNextButtonClick,
    onChange,
    onBackButtonClick,
  } = props

  return (
    <FormContainer
      className={classes.taxOfficeProxyForm}
      onSubmit={onNextButtonClick}
    >
      <HelperButton onClick={onHelperButtonClick} />
      <FormHeader headlineText="Vollmacht für das Finanzamt">
        <p>
          Die Steuerberatungskanzlei wird hiermit bevollmächtigt, den/die
          Vollmachtgeber/in in allen steuerlichen und sonstigen Angelegenheiten
          im Sinne des §1 StBerG zu vertreten.
        </p>
        <p>
          Der/Die Bevollmächtigte ist berechtigt, Untervollmachten zu erteilen
          und zu widerrufen.
        </p>
      </FormHeader>
      <FormBody>
        <TwoColumns layout="1-1">
          <RadioInputField
            label="Vollmacht erteilen?"
            name="taxOfficeProxy"
            radioOptions={['Ja', 'Nein']}
            onChange={onChange}
            defaultChecked={taxOfficeProxy === 'Ja' ? 0 : 1}
          />
        </TwoColumns>
      </FormBody>
      <FormFooter
        primaryButtonText="Weiter"
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={onBackButtonClick}
      />
    </FormContainer>
  )
}

export default TaxOfficeProxyForm
