import React from 'react'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import RadioInputField from '../../Inputs/RadioInputField/RadioInputField'
import PrependNumberInput from '../../ui-components/inputs/PrependNumberInput/PrependNumberInput'
import Label from '../../ui-components/labels/Label/Label'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import FormHint from '../../ui-components/hints/FormHint'

import './SallaryForm.scss'
import setMinimumWage from '../../utility/setMinimumWage'
import { STAFF_FORMS } from '../../../constants/Formulars'

interface Props {
  sallary: string
  formularName: string
  payingOption: string
  sallaryValue: string
  employeeAge: string | undefined
  entrydate: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSecondaryButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SallaryForm = (props: Props) => {
  const setLabelText = () => {
    if (
      props.sallary === 'Festes monatliches Gehalt' &&
      props.formularName === STAFF_FORMS.APPRENTICESHIP
    ) {
      return 'Höhe der Ausbildungsvergütung'
    }
    if (props.sallary === 'Festes monatliches Gehalt') {
      return 'Höhe des Monatlichen Gehalts'
    }
    if (props.sallary === 'Stundenlohn') {
      return 'Höhe des Stundenlohns'
    }
    return ''
  }

  const setMaxSallaryValues = () => {
    if (
      props.formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      props.formularName === STAFF_FORMS.FOREIGN_EMPLOYEE ||
      props.formularName === STAFF_FORMS.APPRENTICESHIP
    ) {
      if (props.sallary === 'Festes monatliches Gehalt') {
        return
      }
      return 49.0
    }
    if (
      props.formularName === STAFF_FORMS.MINIJOB ||
      props.formularName === STAFF_FORMS.STUDENT_MINIJOB ||
      props.formularName === 'Minijobber bis 450€' // TODO check and remove legacy
    ) {
      if (props.sallary === 'Festes monatliches Gehalt') {
        return 450.0
      }
      return 49.0
    }
  }

  const setPlaceholderText = () => {
    if (
      props.formularName === STAFF_FORMS.STANDARD_EMPLOYEE ||
      props.formularName === STAFF_FORMS.STUDENT ||
      props.formularName === STAFF_FORMS.FOREIGN_EMPLOYEE ||
      props.formularName === STAFF_FORMS.APPRENTICESHIP
    ) {
      if (props.sallary === 'Festes monatliches Gehalt') {
        return '2500,00'
      }
      return `${setMinimumWage(props.entrydate, props.employeeAge)
        .toFixed(2)
        .toString()
        .replace('.', ',')}`
    }
    if (
      props.formularName === STAFF_FORMS.MINIJOB ||
      props.formularName === 'Minijobber bis 450€' ||
      props.formularName === STAFF_FORMS.STUDENT_MINIJOB
    ) {
      if (props.sallary === 'Festes monatliches Gehalt') {
        return '450,00'
      }
      return `${setMinimumWage(props.entrydate, props.employeeAge)
        .toString()
        .replace('.', ',')}`
    }
    return ''
  }

  const renderSallaryValueHint = () => {
    if (
      props.sallary === 'Stundenlohn' &&
      props.employeeAge === '18 Jahre oder älter'
    ) {
      return (
        <FormHint
          hintHeadline="Wichtig:"
          hintText={`Der gesetzliche Mindestlohn beträgt ${setMinimumWage(
            props.entrydate,
            props.employeeAge
          )
            .toFixed(2)
            .toString()
            .replace('.', ',')}€. Der
          Mindestlohn kann Branchenspezifisch höher sein.`}
        />
      )
    }
    return null
  }

  const renderEmployeeAgeQuestion = () => {
    if (
      props.formularName === STAFF_FORMS.MINIJOB ||
      props.formularName === STAFF_FORMS.STUDENT ||
      props.formularName === STAFF_FORMS.STUDENT_MINIJOB
    ) {
      return (
        <RadioInputField
          label="Alter der Arbeitskraft"
          name="employeeAge"
          radioOptions={['18 Jahre oder älter', 'Unter 18 Jahre alt']}
          autoFocus
          required={true}
          defaultChecked={props.employeeAge === '18 Jahre oder älter' ? 0 : 1}
          onChange={props.onChange}
        />
      )
    }
    return null
  }

  return (
    <FormContainer className={'sallary-form'} onSubmit={props.onSubmit}>
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Gehalt & Vergütung'} />
      <FormBody>
        {renderEmployeeAgeQuestion()}
        <RadioInputField
          label="Vergütung durch"
          name="sallary"
          radioOptions={['Festes monatliches Gehalt', 'Stundenlohn']}
          required={true}
          defaultChecked={props.sallary === 'Festes monatliches Gehalt' ? 0 : 1}
          onChange={props.onChange}
        />
        <div className="preprend-number-input-field">
          <Label>{setLabelText()}</Label>
          <PrependNumberInput
            name="sallaryValue"
            placeholder={setPlaceholderText()}
            defaultValue={props.sallaryValue}
            prependText="€"
            onChange={props.onChange}
            required
            min={setMinimumWage(props.entrydate, props.employeeAge)}
            max={setMaxSallaryValues()}
            step={0.01}
          />
          {renderSallaryValueHint()}
        </div>
        <RadioInputField
          label="Wie wird der Verdienst ausgezahlt?"
          name="payingOption"
          radioOptions={['Überweisung', 'Bar']}
          required={true}
          defaultChecked={props.payingOption === 'Überweisung' ? 0 : 1}
          onChange={props.onChange}
        />
        {props.payingOption === 'Bar' ? (
          <FormHint
            hintHeadline="Wichtiger Hinweis:"
            hintText="Bei Barauszahlung des Gehaltes bzw. der Vergütung, bist Du in
            einer erweiterten Dokumentationspflicht. Das ist aufwändig und
            kann zu Fehlern führen. Wir empfehlen Dir daher immer, die
            Auszahlung per Überweisung vorzunehmen."
          />
        ) : null}
      </FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
      />
    </FormContainer>
  )
}

export default SallaryForm
