import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as CloseIcon } from '../../../static/CrossIcon.svg'

import './NotificationCard.scss'

const NotificationCard = (props) => {
  return (
    <div className="card notification-card">
      <figure>{props.icon}</figure>
      <p>
        <b>{props.title}</b> <br />
        {props.text}
      </p>
      <button className="card" onClick={props.onClick}>
        <CloseIcon />
      </button>
    </div>
  )
}

NotificationCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default NotificationCard
