import React, { Fragment } from 'react'

import FormContainer from '../../ui-components/forms/FormContainer/FormContainer'
import FormHeader from '../../ui-components/forms/FormHeader/FormHeader'
import FormBody from '../../ui-components/forms/FormBody/FormBody'
import FormFooter from '../../ui-components/forms/FormFooter/FormFooter'
import FormHint from '../../ui-components/hints/FormHint'
import HelperButton from '../../ui-components/buttons/HelperButton/HelperButton'
import RadioInputField from '../../Inputs/RadioInputField/RadioInputField'
import CheckboxField from '../../Inputs/CheckboxField/CheckboxField'

import './PensionInsuranceForm.scss'

interface Props {
  pensionInsuranceRequest: string
  request: boolean
  requestDeclaration: boolean
  payingPensionInsurance: string
  typeOfEmployment: string
  furtherEmployments: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSecondaryButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onHelperButtonClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  onPensionRequestChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onPensionRequestDeclarationChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void
}

const PensionInsuranceForm = (props: Props) => {
  const hintText =
    props.furtherEmployments &&
    props.typeOfEmployment === 'Minijob bis 450€' &&
    props.payingPensionInsurance === 'Nein'
      ? 'Die Befreiung wird für alle bestehenden Minijobs bindend. Die Minijobs werden Dir mit dieser Entscheidung nicht auf deine spätere Rente angerechnet.'
      : 'Der Minijob wird Dir mit dieser Entscheidung nicht auf deine spätere Rente angerechnet.'

  const showRequestFields = () => {
    if (props.pensionInsuranceRequest === 'Ja') {
      return (
        <Fragment>
          <CheckboxField
            name="request"
            onClick={props.onPensionRequestChange}
            required
            defaultChecked={props.request}
          >
            <span>
              Hiermit beantrage ich die Befreiung von der Versicherungspflicht
              in der Rentenversicherung im Rahmen meiner geringfügig entlohnten
              Beschäftigung und verzichte damit auf den Erwerb von
              Pflichtbeitragszeiten. Ich habe die Hinweise auf dem{' '}
              <a
                href="https://www.fastdocs.de/merkblatt-zur-befreiung-von-der-rentenversicherung.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                „Merkblatt über die möglichen folgen einer Befreiung von der
                Rentenversicherungspflicht“
              </a>{' '}
              zur Kenntnis genommen.
            </span>
          </CheckboxField>
          <CheckboxField
            label="Mir ist bekannt, dass der Befreiungsantrag für alle von mir zeitlich ausgeübten geringfügig entlohnten Beschäftigungen gilt und für die Dauer der Beschäftigungen bindend ist. Eine Rückname ist nicht möglich. Ich verpflichte mich, alle weiteren Arbeitgeber, bei denen ich eine geringfügig entlohnte Beschäftigung ausübe, über diesen Befreiungsantrag zu informieren."
            name="requestDeclaration"
            onClick={props.onPensionRequestDeclarationChange}
            required
            defaultChecked={props.requestDeclaration}
          />

          {props.requestDeclaration && props.request && (
            <FormHint hintHeadline="Wichtiger Hinweis:" hintText={hintText} />
          )}
        </Fragment>
      )
    }
  }

  const renderFormFields = () => {
    if (
      props.furtherEmployments &&
      props.typeOfEmployment === 'Minijob bis 450€'
    ) {
      if (props.payingPensionInsurance === 'Ja') {
        return (
          <Fragment>
            <h5>Info zur Rentenversicherungspflicht</h5>
            <p>
              Da Du bereits einen Minijob hast, bei dem Du die Befreiung von der
              Rentenversicherungspflicht beantragt hast, ist das auch für diesen
              Minijob bindend.
            </p>
          </Fragment>
        )
      }
    }

    return (
      <Fragment>
        <RadioInputField
          label="Von der Rentenversicherungspflicht befreien lassen?"
          name="pensionInsuranceRequest"
          radioOptions={['Nein', 'Ja']}
          defaultChecked={props.pensionInsuranceRequest === 'Nein' ? 0 : 1}
          onChange={props.onChange}
        />
        {showRequestFields()}
      </Fragment>
    )
  }
  return (
    <FormContainer
      className={'pension-insurance-form'}
      onSubmit={props.onSubmit}
    >
      <HelperButton onClick={props.onHelperButtonClick} />
      <FormHeader headlineText={'Rentenversicherung'} />
      <FormBody>{renderFormFields()}</FormBody>
      <FormFooter
        primaryButtonText={'Weiter'}
        secondaryButtonText="Zurück"
        onSecondaryButtonClick={props.onSecondaryButtonClick}
      />
    </FormContainer>
  )
}

export default PensionInsuranceForm
